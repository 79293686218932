import React from 'react';
import { ActionTypes } from '../../lib/ActionManager/FluxActions';
import { FluxAction } from '../../components/application/FluxAction';
import { FluxPage } from '../../components/page/FluxPage';
import { ConfigureUserGroups } from './ConfigureUserGroups';
import { CasinoProviderSettings } from './CasinoProviderSettings';
import { ConfigureOperators } from './Operators/ConfigureOperators';
import { PaymentProviderSettings } from './PaymentProviderSettings';

export const PlatformSettings = () => {
    return <FluxPage action={ActionTypes.Operators}>
        <FluxAction action={ActionTypes.Operators}><ConfigureOperators /></FluxAction>
        <FluxAction action={ActionTypes.ConfigureUserGroups}><ConfigureUserGroups /></FluxAction>
        <FluxAction action={ActionTypes.CasinoProviders}><CasinoProviderSettings /></FluxAction>
        <FluxAction action={ActionTypes.PaymentProviders}><PaymentProviderSettings /></FluxAction>
    </FluxPage >;
};
