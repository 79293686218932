import React from 'react';
import { ActionTypes } from '../../lib/ActionManager/FluxActions';
import { FluxAction } from '../../components/application/FluxAction';
import { FluxPage } from '../../components/page/FluxPage';
import { RetailCenter } from './RetailCenter/RetailCenter';

export const RetailsPage = () => {
    return <FluxPage action={ActionTypes.Retails}>
        <FluxAction action={ActionTypes.ListRetails}><RetailCenter /></FluxAction>
    </FluxPage >;
};
