import React from 'react';

import { FluxCard } from '../../../../../components/card/FluxCard';
import { FluxForm } from '../../../../../components/form/FluxForm';
import { InputTypes } from '../../../../../components/form/InputTypes';
import { NotificationType } from '../../../../../components/notification/FluxNotification';
import { Popper } from '../../../../../components/notification/FluxPopper';
import { PromotionEndpoints } from '../../../../../lib/API/Endpoints';
import { lang } from '../../../../../lib/Localization/language';
import { CurrencyModalField } from '../../../../../lib/Models/CurrencyModel';
import { PromotionCodeSearch } from './PromotionCodeSearch';


const model = (promoName, promotionId) => {
    return {
        fields: [
            {
                name: 'Code',
                title: 'Code',
                type: InputTypes.Text,
                required: true,
                maxLength: 16,
                placeholder: 'Min. 3 - Max. 16 character'
            },
            {
                name: 'Quantity',
                title: 'Consume Amount',
                type: InputTypes.Number,
                max: 1000,
                min: 1,
                required: true,
                placeholder: 'How many time this code can be consumed'
            },
            {
                name: 'CanConsumeDuringRegister',
                title: 'Can be consumed during registration',
                type: InputTypes.Bool
            },
            {
                name: 'Amount',
                title: 'Amount',
                type: InputTypes.Number,
                required: true
            },
            CurrencyModalField,
            {
                name: 'PromotionId',
                hidden: true,
                value: promotionId,
                type: InputTypes.Number,
                required: true,
            },
            {
                name: 'PromotionName',
                hidden: true,
                value: promoName,
                required: true,
            }
        ]
    };
};


export const PromotionCodeCreateSettings = (props) => {
    const { promotion } = { ...props };

    if (!promotion || promotion.Id == 0) return <></>;
    return <flex className='vertical gap-20'>
        <FluxCard title={'Promotion Code'}>
            <flex className='vertical gap-10'>
                <FluxForm
                    model={model(promotion.Name, promotion.Id)}
                    columns={3}
                    endpoint={PromotionEndpoints.PromotionCodeCreate}
                    confirm={true}
                    confirmTitle={'Please confirm'}
                    confirmMessage={'Confirm creating code'}
                    onSubmitComplete={() => {
                        Popper.pop(NotificationType.Success, lang('Code generated.'));
                    }}
                />
            </flex>
        </FluxCard>
        <PromotionCodeSearch {...props} />
    </flex>;
};