import './_website-configurator.scss';

import React, { useEffect, useRef, useState } from 'react';
import { FluxCard } from '../../../components/card/FluxCard';
import { API } from '../../../lib/API/Api';
import { CMSEndpoints, DataEndpoints, PromotionEndpoints } from '../../../lib/API/Endpoints';
import { FluxList } from '../../../components/list/FluxList';
import { FluxNotice } from '../../../components/notification/FluxNotice';
import { NotificationType } from '../../../components/notification/FluxNotification';
import { useSelector } from 'react-redux';
import { Popper } from '../../../components/notification/FluxPopper';
import { Button } from '../../../components/button/Button';
import { Modal } from '../../../components/application/FluxApp';
import { ConfirmationModal } from '../../../components/modal/ConfirmationModal/ConfirmationModal';
import { ToggleButton } from '../../../components/toggleButton/ToggleButton';
import { Editor } from '@tinymce/tinymce-react';
import { lang } from '../../../lib/Localization/language';
import { FluxTab } from '../../../components/tab/FluxTab';
import { FluxForm } from '../../../components/form/FluxForm';
import { ImageContent } from './Components/Image/ImageContent';

export const PromotionsCMS = () => {
    const user = useSelector((state) => state.user.loginInformation);
    const operator = user.UserPrivileges.Operators.find(x => x.Id == user.UserPrivileges.PointOfView);
    const defaultLanguage = (user.features?.defaultLanguage ?? 'en-En').split('-')[0];

    const [languages, setAvailableLanguages] = useState(null);
    const [language, selectLanguage] = useState(null);

    const [promotions, setPromotions] = useState(null);
    const [promotion, selectPromotion] = useState(null);

    const [updateKey, setUpdateKey] = useState(0);

    const [content, setContent] = useState(null);
    const [backgroundColor, setBackgroundColor] = useState('#FFF');

    const editorRef = useRef(null);
    useEffect(() => {
        API.post(DataEndpoints.AvailableLanguages, {}).then((result) => {
            var sorted = result.result.sort((a, b) => {
                if (a.key.toLowerCase() == defaultLanguage) return -1;
                return 1;
            });

            setAvailableLanguages(sorted);
            selectLanguage(sorted[0]);
        });
    }, []);

    useEffect(() => {
        if (!languages) return;

        API.post(PromotionEndpoints.List, {}).then((result) => {
            var filtered = result.result.filter(x => x.Enabled);

            API.post(CMSEndpoints.Contents, { type: 'promotions' }, 'fetching contents', 'unable to fetch contents').then((c) => {
                c.result.map(content => {
                    if (content.key.toLowerCase().toString() !== 'rules') {
                        var promotion = filtered.find(x => x.Id.toString() == content.key);
                        if (promotion) {
                            promotion.Enabled = content.enabled;
                        }
                    }
                });

                var rules = c.result.find(x => x.key == 'Rules');
                filtered = [{
                    Id: 'rules',
                    Name: 'Promotion Rules',
                    Enabled: rules?.enabled
                }].concat(filtered);
                setPromotions(filtered);
            });
        });
    }, [languages]);

    useEffect(() => {
        if (!promotions) return;
        if (!promotion) {
            selectPromotion(promotions[0]);
        }
    }, [promotions]);

    useEffect(() => {
        if (!promotion) return;
        API.post(CMSEndpoints.Content, { Type: 'promotions', Key: promotion.Id.toString(), OperatorId: promotion.OperatorId }, 'loading promotion content', 'unable to fetch promotion content').then((result) => {
            var c = JSON.parse(result.result);
            setContent(c);
            setUpdateKey(Date.now());
        });
    }, [promotion]);

    const save = () => {
        API.post(CMSEndpoints.CMSSaveContent, {
            Type: 'promotions',
            Key: promotion.Id,
            Content: JSON.stringify(content)
        }, 'saving promotion content', 'unable to save promotion content').then(() => {
            Popper.pop(NotificationType.Information, 'Promotion saved.');
        });
    };

    const renderPromotion = () => {
        if (!content) return;
        if (!language) return;
        if (!promotion) return;
        var colors = ['#000', '#CCC', '#EEE', '#FFF'];


        var languageKey = language.key.toLowerCase();
        var promotionContent = content[languageKey] ? content[languageKey] : {
            description: '',
            content: '',
            image: '',
            title: ''
        };

        if (!promotionContent.description) {
            promotionContent.description = '';
        }
        if (!promotionContent.content) {
            promotionContent.content = '';
        }
        if (!promotionContent.image) {
            promotionContent.image = '';
        }
        if (!promotionContent.title) {
            promotionContent.title = '';
        }
        return <flex className='vertical gap-10 fit-children overflow-hidden overflow-y-auto' key={promotion.Id + language.key}>
            <FluxCard title={'Promotion Configuration' + promotion?.Name}>
                <FluxForm model={{
                    fields: [
                        {
                            name: 'title',
                            title: 'Title'
                        },
                        {
                            name: 'description',
                            title: 'Description'
                        }
                    ]
                }} data={{
                    title: promotionContent.title,
                    description: promotionContent.description
                }} buttons={null} onChange={(data) => {
                    var copy = { ...content };
                    promotionContent.title = data.title;
                    promotionContent.description = data.description;
                    copy[language.key.toLowerCase()] = promotionContent;
                    setContent(copy);
                }} />
            </FluxCard>

            <FluxCard title='Image'>
                <ImageContent key={language} image={promotionContent.image.split('.')[0]} page={'promotions/images'} fileExtension={promotionContent.image.split('.')[1] ?? 'png'} prefix={operator.Prefix} onUpdate={(imageKey) => {
                    var copy = { ...content };
                    promotionContent.image = imageKey;
                    copy[language.key.toLowerCase()] = promotionContent;
                    setContent(copy);
                }} />
            </FluxCard>
            <FluxCard title='Content'>
                <flex className='vertical gap-10'>
                    <Editor
                        apiKey='raizce00jw390yxvftjwrsrahfryr6bb7b89q1o4zl89qxns'
                        key={backgroundColor}
                        onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={promotionContent.content ?? ''}
                        onChange={(context, editor) => {
                            var copy = { ...content };
                            promotionContent.content = editor.getContent();
                            copy[language.key.toLowerCase()] = promotionContent;
                            setContent(copy);

                        }}
                        init={{
                            height: 500,
                            menubar: true,
                            plugins: [
                                'code',
                                'advlist', 'autolink',
                                'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                'fullscreen', 'insertdatetime', 'media', 'table', 'help', 'wordcount'
                            ],
                            toolbar: ['code undo redo formatselect bold italic backcolor alignleft aligncenter alignright alignjustify bullist numlist outdent indent removeformat help'],
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px;background:' + backgroundColor + ';}'
                        }}
                    />
                    <flex className='gap-5 padding fx-borders'>
                        <span>{lang('Background Color')}</span>
                        {colors.map(x => {
                            return <flex className='padding' style={{ backgroundColor: x }} key={x} onClick={() => {
                                setBackgroundColor(x);
                            }}>
                                {x}
                            </flex>;
                        })}
                    </flex>
                    <Button title='Save' className={'align-left success'} onClick={() => {
                        var copy = { ...content };
                        promotionContent.content = editorRef.current.getContent();
                        copy[language.key.toLowerCase()] = promotionContent;
                        setContent(copy);

                        save();
                    }} />
                </flex>
            </FluxCard>
        </flex>
    };


    if (!languages) return <></>;
    if (!promotions) return <></>;



    return <flex className='website-configurator overflow-hidden gap-10 fit-children'>
        <FluxCard title='Promotions' className={'page-list no-padding overflow-hidden'} buttons={<flex className='align-right'>
            <Button title='Publish' className='success' onClick={() => {
                Modal.open(<ConfirmationModal title='Confirm Publish' onConfirm={() => {
                    API.post(CMSEndpoints.PublishPromotions, {}, 'publishing', 'unable to publish').then(() => {
                        Popper.pop(NotificationType.Information, 'Published!');
                    });
                }}>
                    <FluxNotice type={NotificationType.Information} title='Publish changes?' description={'Please confirm publishing changes.'} />
                </ConfirmationModal>)
            }} />
        </flex>}>
            <FluxList data={promotions.map((x, index) => {
                return { title: x.Name, value: x.Id };
            })}
                onSelected={(item) => {
                    if (!item) return;
                    selectPromotion(promotions.find(x => x.Id == item.value));
                }}
                onRender={(x) => {
                    var p = promotions.find(y => y.Id == x.value);
                    return <flex className='gap-5'><span>{x.title}</span><flex className='align-right'><ToggleButton value={p.Enabled} onChange={(v) => {
                        if (p.Enabled === v) return;
                        API.post(CMSEndpoints.CMSSaveContent, { type: 'promotions', key: x.value, enabled: v }, 'updating content', 'unable to update').then(() => {
                            var copy = [...promotions];
                            copy.find(c => c.Id == x.value).Enabled = false;
                            setPromotions(copy);
                        });
                    }} /></flex></flex>;
                }}
            />
        </FluxCard>
        <flex className='vertical gap-10' key={updateKey}>
            {!promotion && <FluxNotice type={NotificationType.Information} title='Promotion not selected' description={'Please select a promotion from the list.'} />}
            {promotion && <FluxTab className='gap-10 rounded-buttons fx-shadow fx-borders fx-radius' buttons={languages.map(x => {
                return { title: x.name }
            })}
                onChange={(index) => {
                    if (index === undefined) return;
                    selectLanguage(languages[index]);
                }} />
            }
            {promotion && renderPromotion()}
        </flex>
    </flex>;
};

