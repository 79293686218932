import './_slider.scss';
import React, { useEffect, useState } from 'react';
import { Slider } from './Slider';
import { Icon } from '../../../../../components/icon/Icon';
import { FluxTab } from '../../../../../components/tab/FluxTab';
import { FluxCard } from '../../../../../components/card/FluxCard';
import { FluxForm } from '../../../../../components/form/FluxForm';
import { InputTypes } from '../../../../../components/form/InputTypes';
import { Button } from '../../../../../components/button/Button';
import { Modal } from '../../../../../components/application/FluxApp';
import { ConfirmationModal } from '../../../../../components/modal/ConfirmationModal/ConfirmationModal';
import { FluxNotice } from '../../../../../components/notification/FluxNotice';
import { NotificationType } from '../../../../../components/notification/FluxNotification';
import { Table } from '../../../../../components/table/Table';
import { lang } from '../../../../../lib/Localization/language';
import { API } from '../../../../../lib/API/Api';
import { CMSEndpoints } from '../../../../../lib/API/Endpoints';




const SortSliderItems = (props) => {
    const { items, language, defaultLanguage, cdn, page, onSort } = { ...props };
    const languageKey = language.key.toLowerCase();
    const [sortedItems, setSortedItems] = useState(items);
    return <Table
        key={sortedItems}
        className={'fx-borders fx-shadow'}
        data={sortedItems}
        options={{
            draggable: {
                enabled: true
            }
        }} onSortEnd={(o, n, v) => {
            var c = [...v];
            setSortedItems(c);
            onSort(c);
        }}
        model={{
            fields: [
                {
                    name: 'Image-Thumb',
                    title: 'Image',
                    render: (val, row) => {
                        var item = row;
                        var languageKey = 'en';
                        const source = `content/pages/${page.key}/sliders`;
                        const fileExtension = item.transparent ? 'png' : 'jpg';
                        var image = item.img[languageKey] ? item.img[languageKey].key : null;
                        if (!image) {
                            var firstKey = Object.keys(item.img);
                            if (firstKey) {
                                languageKey = firstKey[0];
                                image = item.img[languageKey] ? item.img[languageKey].key : null;
                            }
                        }

                        var element = <img key={row.key} className='slider-thumb' src={`//cdn-${cdn}.tentangle.com/${source}/${image}_${languageKey}.${fileExtension}`} onLoad={() => { }} />
                        return element;
                    }
                },
                {
                    name: 'Slider-Name',
                    title: 'Name',
                    render: (val, row) => {
                        var img = null;
                        var copy = {
                            title: row.title[language.key.toLowerCase()] ? row.title[language.key.toLowerCase()] : row.title[defaultLanguage],
                            description: row.description[language.key.toLowerCase()] ? row.description[language.key.toLowerCase()] : row.description[defaultLanguage],
                            transaprent: row.transaprent === true,
                            url: row.url,
                            openNewWindow: row.openNewWindow,
                            launchGameId: row.launchGameId
                        };

                        return copy.title;
                    }
                }
            ]
        }} />;
};


export const SliderConfigurator = (props) => {
    let { configuration, languages, defaultLanguage, page, onUpdate, allowGameSelection, onSaveComplete } = { ...props };
    const [language, selectLanguage] = useState(null);

    const [items, setItems] = useState(null);
    const [slider, setSlider] = useState(null);
    const [sliderIndex, setSliderIndex] = useState(0);

    const [data, setData] = useState({});

    const [sortedItems, setSortedItems] = useState(null);
    useEffect(() => {
        if (!onSaveComplete || !items) return;
        setSlider(items[sliderIndex]);
    }, [onSaveComplete]);

    useEffect(() => {
        setItems(configuration.items ?? []);
    }, []);

    useEffect(() => {
        if (!items) return;
        if (!slider) {
            setSlider(items[0]);
        }

        configuration.items = items;

    }, [items]);

    useEffect(() => {
        if (!slider) return;
        prepareData();
    }, [language]);

    useEffect(() => {
        if (!slider) return;
        if (!language) {
            selectLanguage(languages[0]);
        } else {
            prepareData();
        }
    }, [slider])

    useEffect(() => {

    }, [data]);

    const prepareData = () => {
        var copy = {
            title: slider.title[language.key.toLowerCase()] ? slider.title[language.key.toLowerCase()] : slider.title[defaultLanguage],
            description: slider.description[language.key.toLowerCase()] ? slider.description[language.key.toLowerCase()] : slider.description[defaultLanguage],
            transaprent: slider.transaprent === true,
            url: slider.url,
            openNewWindow: slider.openNewWindow,
            launchGameId: slider.launchGameId
        };
        setData(copy);
    };

    const addNewSlider = () => {
        var copy = [...items];
        var newItem = {
            description: {},
            img: {},
            key: Date.now(),
            openNewWindow: false,
            title: {},
            transaprent: false,
            url: null,
            weight: items.length,
            launchGameId: null
        };

        newItem.description[language.key?.toLowerCase() ?? defaultLanguage] = '';
        newItem.title[language.key?.toLowerCase() ?? defaultLanguage] = '';
        newItem.img[language.key?.toLowerCase() ?? defaultLanguage] = {
            key: Date.now()
        };

        copy.push(newItem);
        setItems(copy);
        setSliderIndex(copy.length - 1);
        setSlider(newItem);
    };

    const getFields = () => {
        if (allowGameSelection) {
            return [
                {
                    name: 'title',
                    title: 'Title'
                },
                {
                    name: 'description',
                    title: 'Description'
                },
                {
                    name: 'transaprent',
                    title: 'Transparent',
                    type: InputTypes.Bool
                },
                {
                    name: 'url',
                    title: 'Link'
                },
                {
                    name: 'launchGameId',
                    title: 'Launch Game',
                    type: InputTypes.Select,
                    nullable: true,
                    data: {
                        source: CMSEndpoints.GetGamesSummary,
                        parameters: {},
                        parser: (result) => {
                            var results = [];
                            result.result.map(x => {
                                results.push({ title: x.providerName + ' - ' + x.name, value: x.gameId });
                            });
                            return results;
                        },
                    }
                },
                {
                    name: 'openNewWindow',
                    title: 'Open in new window',
                    type: InputTypes.Bool
                }
            ];
        }
        return [
            {
                name: 'title',
                title: 'Title'
            },
            {
                name: 'description',
                title: 'Description'
            },
            {
                name: 'transaprent',
                title: 'Transparent',
                type: InputTypes.Bool
            },
            {
                name: 'url',
                title: 'Link'
            },
            {
                name: 'openNewWindow',
                title: 'Open in new window',
                type: InputTypes.Bool
            }
        ];
    }

    if (!items) return <></>;
    if (!language) return <></>;
    if (!slider) return <></>;

    var currentIndex = items.findIndex(x => x == slider);
    return <flex className='vertical gap-10'>
        <flex className='gap-5'>
            <label>{lang('Display number of items')}</label>
            <input type='number' defaultValue={configuration.displayNumberOfItems ?? 0} onChange={(e) => {
                let value = parseFloat(e.target.value);
                if (isNaN(value)) {
                    return;
                }
                var copy = { ...configuration };
                copy.displayNumberOfItems = value;
                onUpdate(copy);
            }} />
        </flex>
        <FluxTab className='center gap-10 rounded-buttons' buttons={languages.map(x => {
            return { title: x.name }
        })}
            onChange={(index) => {
                if (index === undefined) return;
                selectLanguage(languages[index]);
            }} />
        <flex className='gap-5 center'>
            <Button title='Add new slider' className='success' onClick={() => {
                addNewSlider();
            }} />
            <Button title='Remove slider' className='warning' onClick={() => {
                Modal.open(<ConfirmationModal title='Please confirm' onConfirm={() => {
                    var copy = [...items];
                    copy = copy.filter(x => x != slider);
                    setItems(copy);
                    setSlider(copy[0]);
                    setSliderIndex(0);
                }}>
                    <FluxNotice type={NotificationType.Warning} title='Do you want to remove slider?' />
                </ConfirmationModal>)
            }} />
        </flex>
        <sliders key={language.key + currentIndex}>
            <flex className={`padding${currentIndex <= 0 ? ' disabled' : ''}`}>
                <Icon icon='chevron-left' type='fa-light' onClick={() => {
                    setSlider(items[currentIndex - 1]);
                }} />
            </flex>
            <items className='fx-shadow fx-borders'>
                <Slider key={language.key} item={slider} {...props} language={language} onUpdate={(s) => {
                    var copy = [...items];
                    copy[currentIndex] = s;

                    setSlider(s);
                    setItems(copy);

                    configuration.items = copy;
                    onUpdate({ ...configuration });
                }} />
            </items>
            <flex className={`padding${currentIndex == items.length - 1 ? ' disabled' : ''}`}>
                <Icon icon='chevron-right' type='fa-light' onClick={() => {
                    setSlider(items[currentIndex + 1]);
                }} />
            </flex>
        </sliders>
        <flex className='gap-20 center'>
            <flex className='gap-5'>
                {items.map((x, index) => {
                    return <Button title={index + 1} key={x} className={slider == x ? 'success' : ''} onClick={() => {
                        setSliderIndex(index);
                        setSlider(x);
                    }} />;
                })}
            </flex>

            <flex className='align-right'>
                <Button title='Sort slides' onClick={() => {
                    Modal.open(<ConfirmationModal title='Sort slides' onConfirm={() => {
                        if (sortedItems) {
                            setItems([...sortedItems]);
                            setSliderIndex(0);
                            setSlider(sortedItems[0]);
                            setSortedItems(null)
                        }
                    }}>
                        <SortSliderItems items={items} cdn={props.prefix} page={page} language={language} defaultLangue={defaultLanguage} onSort={(sortedItems) => {
                            for (let i = 0; i < sortedItems.length; i++) {
                                const element = sortedItems[i];
                                element.weight = i;
                            }
                            setItems(sortedItems);
                        }} />
                    </ConfirmationModal>)
                }} />
            </flex>
        </flex>
        <FluxCard title='Slider Item Configuration' className={'slider-configuration'}>
            <FluxForm key={language.key + currentIndex} data={data}
                onSubmit={(newData) => {
                    var copy = [...items];

                    var sliderCopy = { ...slider };
                    var key = language.key.toLowerCase();
                    sliderCopy.title[key] = newData.title;
                    sliderCopy.description[key] = newData.description;
                    sliderCopy.url = newData.url;
                    sliderCopy.launchGameId = newData.launchGameId;

                    copy[currentIndex] = sliderCopy;

                    setItems(copy);

                    configuration.items = copy;
                    onUpdate({ ...configuration });
                }}
                model={{ fields: getFields() }} />
        </FluxCard>
    </flex>;
};