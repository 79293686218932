import React from 'react';
import { currency, Currency } from '../../../components/currency/Currency';

export const footerCurrencyValues = (val, isExporting) => {
    if (!val) return;


    if (isExporting) {
        var footerResult = [];
        if (Object.keys(val).length > 0) {
            Object.keys(val).map(key => {
                footerResult.push(currency(val[key], 2, key));
            });

            return footerResult;
        } else {
            return typeof val == 'object' ? '' : <Currency amount={val} showSign={false} />;
        }
    }

    if (Object.keys(val).length > 0) {
        var results = [];
        results = Object.keys(val).map(key => {
            return <p key={key}><Currency amount={val[key]} sign={key} /></p>;
        });
        return results;
    } else {
        return typeof val == 'object' ? '' : val.toString();
    }
};


export const footerValues = (val, isExporting, decimal = 2) => {
    if (!val) return;

    if (isExporting) {
        var footerResult = [];
        if (Object.keys(val).length > 0) {
            Object.keys(val).map(key => {
                footerResult.push(`${key} ${val[key]}`);
            });

            return footerResult;
        } else {
            return typeof val == 'object' ? '' : val.toString();
        }
    }

    if (Object.keys(val).length > 0) {
        var results = [];
        results = Object.keys(val).map(key => {
            return <flex key={key} className='gap-5'>
                <p key={key} className='align-left small bold'>{key} </p>
                <p key={key} className='align-right'>{typeof val[key] === 'number' ? currency(val[key], decimal, '', false) : val[key]}</p>
            </flex>;
        });
        return results;
    } else {
        return typeof val == 'object' ? '' : val.toString();
    }
};
