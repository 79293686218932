/* eslint-disable react/display-name */

import './_pagination.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../../icon/Icon';
import { lang } from '../../../lib/Localization/language';



export const SearchPagination = (props) => {
    const [allPages, setAllPages] = useState([]);

    useEffect(() => {
        var copy = [];
        for (var i = 0; i < totalPages; i++) {
            copy.push(<option value={i}>{i + 1}</option>);
        }

        setAllPages(copy);
    }, [props.total]);


    const currentPage = props.currentPage;
    if (!props.recordsPerPage) return <></>;

    var totalPages = Math.ceil(props.total / props.recordsPerPage);
    var startPage = currentPage - 5;
    if (startPage < 0) startPage = 0;

    let max = startPage + 10;
    if (max > totalPages) max = totalPages;
    let pages = [];
    for (var i = startPage; i < max; i++) {
        pages.push({ index: i });
    }

    const onPageClicked = (current) => {
        props.onPageClicked(current);
    };


    return <flex className='search-pagination gap-10'>
        <flex className={`pagination-button radius fx-shadow clickable${currentPage == 0 ? ' disabled' : ''}`} onClick={() => onPageClicked(currentPage - 1)}>
            <Icon icon='chevron-left' size='xs' type='fa-regular' />
            <span>{lang('Previous')}</span>
        </flex>
        <flex onClick={() => onPageClicked(0)} className={`pagination-button radius fx-shadow${currentPage == 0 ? ' disabled' : ''}`}>
            <Icon icon={'step-backward'} size='xs' type='fa-regular' />
            <span>{lang('First Page')}</span>
        </flex>

        <flex className='gap-5 justify-content-center center'>
            <span>{lang('Goto page')}</span>
            <select onChange={(e) => props.onPageClicked(parseInt(e.target.value))}>
                {allPages}
            </select>
            {
                pages.map(page => {
                    return <flex key={page.index} className={`pagination-button radius fx-shadow${(currentPage === page.index ? ' active' : '')}`} onClick={() => onPageClicked(page.index)}>
                        <span>{page.index + 1}</span>
                    </flex>;
                })
            }
        </flex>


        <flex onClick={() => onPageClicked(totalPages - 1)} className={`pagination-button radius fx-shadow${currentPage == totalPages || totalPages <= 1 ? ' disabled' : ''}`}>
            <span>{lang('Last Page')}</span>
            <Icon icon={'step-forward'} size='xs' type='fa-regular' />
        </flex>
        <flex className={`pagination-button radius fx-shadow clickable${currentPage == totalPages - 1 || totalPages <= 1 ? ' disabled' : ''}`} onClick={() => onPageClicked(currentPage + 1)}>
            <span>{lang('Next')}</span>
            <Icon icon='chevron-right' size='xs' type='fa-regular' />
        </flex>
    </flex>;
};

SearchPagination.propTypes = {
    total: PropTypes.number,
    currentPage: PropTypes.number,
    recordsPerPage: PropTypes.number,
    onPageClicked: PropTypes.func
};
