import './_user-groups.scss';

import React, { useEffect, useState } from 'react';
import { FluxCard } from '../../components/card/FluxCard';
import { Icon } from '../../components/icon/Icon';
import { FluxList } from '../../components/list/FluxList';
import { FluxTab } from '../../components/tab/FluxTab';
import { API } from '../../lib/API/Api';
import { ConfigurationEndpoints } from '../../lib/API/Endpoints';



const userGroupTabButtons = [
    {
        title: 'Operator'
    },
    {
        title: 'Virtual Retails'
    },
    {
        title: 'Retails'
    },
    {
        title: 'API'
    }
];

const userGroupTypeNames = [
    'Operator',
    'Virtual Retails',
    'Retails',
    'API'
];

export const ConfigureUserGroups = () => {
    const [userGroupTypes, setUserGroupTypes] = useState({});
    const [selectedGroupType, selectGroupType] = useState();
    const [userGroup, selectUserGroup] = useState();
    const [bindings, setBindings] = useState();
    useEffect(() => {
        API.post(ConfigurationEndpoints.UserGroups).then((result) => {
            var groups = {};
            result.result.map(x => {
                if (!groups[x.GroupType]) {
                    groups[x.GroupType] = {
                        name: userGroupTypeNames[x.GroupType],
                        id: x.GroupType,
                        groups: []
                    };
                }
                groups[x.GroupType].groups.push(x);
            });
            setUserGroupTypes(groups);
            selectGroupType(groups[0]);
        });

    }, []);


    useEffect(() => {
        if (selectedGroupType === undefined) return;
        selectUserGroup(selectedGroupType.groups[0].Id);
    }, [selectedGroupType]);

    useEffect(() => {
        if (!userGroup) return;
        API.post(`${ConfigurationEndpoints.UserGroupBinding}/${userGroup}`).then((result) => {
            setBindings(result.result);
        });
    }, [userGroup]);

    const renderBindings = () => {
        if (!bindings) return;
        return Object.keys(bindings).map(x => {
            var values = Object.keys(bindings[x]);
            var data = [];
            values.map(y => {
                data.push({
                    title: y,
                    value: bindings[x][y]
                });
            });

            return <FluxCard title={x} key={x}>
                <FluxList data={data} onRender={(item) => {
                    var icon = data.find(y => y.title == item.title).value ? 'check-square' : 'square';
                    return <flex className='gap-5' key={item.title}>
                        <span>{item.title}</span>
                        <Icon icon={icon} type='fa-light' className='align-right' />
                    </flex>;
                }}>
                </FluxList>
            </FluxCard>;
        });
    };



    if (!userGroupTypes) return <></>;
    if (selectedGroupType === undefined) return <></>;
    if (selectedGroupType?.groups === undefined) return <></>;

    var groupButtons = selectedGroupType.groups.map(x => {
        return { title: x.Name, id: x.Id };
    });

    return <flex className='vertical gap-10 user-group-settings overflow-hidden'>
        <FluxTab buttons={userGroupTabButtons} onChange={(index) => {
            selectGroupType(userGroupTypes[index]);
        }} className='gap-10 fx-shadow fx-radius rounded-buttons' />
        <FluxTab buttons={groupButtons} key={selectedGroupType.id} onChange={(index) => {
            selectUserGroup(groupButtons[index].id);
        }} className='gap-10 fx-shadow fx-radius rounded-buttons' />
        <div className='bindings'>
            {renderBindings()}
        </div>
    </flex>;
};