/* eslint-disable react/display-name */

import React from 'react';

import { Modal, Overlay } from '../components/application/FluxApp';
import { FluxForm } from '../components/form/FluxForm';
import { FluxModal } from '../components/modal/FluxModal';
import { InputTypes } from '../components/form/InputTypes';
import { API } from '../lib/API/Api';
import { lang } from '../lib/Localization/language';
import { FluxNotice } from '../components/notification/FluxNotice';
import { NotificationType } from '../components/notification/FluxNotification';
import { TransactionTypes } from '../lib/Enums/TransactionEnums';
import { TransactionEndpoints, RolloverAmountEndpoints } from '../lib/API/Endpoints';
import { currency } from '../components/currency/Currency';
import { ConfirmWithdrawTransactionModal } from '../../components/Modal/Modals/ConfirmWithdrawTransactionModal/ConfirmWithdrawTransactionModal';




const rolloverLeftAmountModel = {
    fields: [
        {
            name: 'Amount',
            title: 'Amount',
            required: true,
            type: InputTypes.Number
        },
        {
            name: 'Notes',
            title: 'Notes',
            type: InputTypes.Textarea
        }
    ]
};


const transactionModel = {
    fields: [
        {
            name: 'Data',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'get/transactionSources',
                parser: (result) => {
                    var sources = {};
                    if (!result.result) result.result = '';
                    result.result.split('|').map(x => { sources[x] = { title: x, value: x }; });

                    return Object.values(sources);
                }
            }
        },
        {
            name: 'AffectDepositRollOver',
            title: 'Update Deposit Rollover',
            type: InputTypes.Bool,
            value: true,
            description: () => <span>{lang('The amount subject to rollover rule for withdrawal.')}</span>
        },
        {
            name: 'Amount',
            title: 'Amount',
            required: true,
            type: InputTypes.Number
        },
        {
            name: 'Notes',
            title: 'Notes',
            type: InputTypes.Textarea
        }
    ]
};


export const depositPlayer = (player, bonus, handler) => {
    const confirmed = (data) => {
        var transactionData = { ...data };
        transactionData.PlayerId = player.id;
        transactionData.TransactionType = 0;
        transactionData.Currency = player.currency;
        transactionData.Bonus = bonus;
        transactionData.BonusWallet = bonus;

        API.post(TransactionEndpoints.Create, transactionData, 'please wait', 'unable to create transaction').then((result) => {
            Modal.close();
            handler(transactionData, result.result);
        });
    };
    Modal.open(
        <FluxModal title={'Deposit'} onConfirm={confirmed}>
            <FluxForm className='tab-next'
                submitButtonTitle='Deposit'
                model={transactionModel}
                onSubmit={(data) => {
                    confirmed(data);
                }}
                onCancel={() => Modal.close()}>
            </FluxForm>
        </FluxModal>
    );
};

export const withdrawPlayer = (player, bonus, handler) => {
    const confirmed = (data) => {
        var transactionData = { ...data };
        transactionData.PlayerId = player.id;
        transactionData.TransactionType = 1;
        transactionData.Currency = player.currency;
        transactionData.Bonus = bonus;
        transactionData.BonusWallet = bonus;

        API.post(TransactionEndpoints.Create, transactionData, 'please wait', 'unable to create transaction').then((result) => {
            Modal.close();
            handler(transactionData, result.result);
        });
    };
    Modal.open(
        <FluxModal title={'Withdraw'} onConfirm={confirmed}>
            <FluxForm className='tab-next'
                submitButtonTitle='Withdraw'
                model={transactionModel}
                onSubmit={(data) => {
                    confirmed(data);
                }}
                onCancel={() => Modal.close()}>
            </FluxForm>
        </FluxModal>
    );
};


export const resetBalance = (player, bonus, handler) => {
    const confirmed = (data) => {
        var transactionData = { ...data };
        transactionData.PlayerId = player.id;
        transactionData.Currency = player.currency;
        transactionData.Bonus = bonus;

        API.post(TransactionEndpoints.ResetBalance, transactionData, 'please wait', 'unable to create transaction').then((result) => {
            Modal.close();
            handler(transactionData, result.result);
        });
    };
    Modal.open(<FluxModal title={'Reset balance'} onConfirm={confirmed}>
        <flex className='vertical gap-10'>
            <FluxNotice type={NotificationType.Warning} title={lang('Please confirm player balance reset.')} description={lang('%1 will be removed from players balance', [currency(bonus ? player.bonusBalance : player.balance, 2, player.currency)])} />
            <FluxForm className='tab-next horizontal-fields'
                submitButtonTitle='Confirm'
                model={{
                    fields: [
                        {
                            name: 'Notes',
                            title: 'Notes',
                            type: InputTypes.Textarea,
                            required: false
                        }
                    ]
                }} onSubmit={(data) => {
                    confirmed(data);
                }} onCancel={() => Modal.close()}>
            </FluxForm>
        </flex>
    </FluxModal>
    );
};


export const cancelTransaction = (transaction, type, handler) => {
    const confirmed = (data) => {
        var transactionData = { ...data };
        transactionData.Id = transaction.id;

        API.post(TransactionEndpoints.CancelTransaction, transactionData, 'please wait', 'unable to cancel transaction').then((result) => {
            Overlay.close();
            handler(transactionData, result.result);
        });
    };

    var description = '';
    switch (type) {
        case 0:
        case 2:
            description = lang('Please confirm, %1 will be removed from players wallet.', [currency(Math.abs(transaction.amount), 2, transaction.currency)]);
            break;
        case 1:
        case 3:
            description = lang('Please confirm, %1 will be deposit back to players wallet.', [currency(Math.abs(transaction.amount), 2, transaction.currency)]);
            break;

    }
    Overlay.open(<FluxModal title={'Cancel Transaction'} onConfirm={confirmed}>
        <flex className='vertical gap-10'>
            <FluxNotice type={NotificationType.Warning} title={lang('%1 transaction will be cancelled.', [TransactionTypes[type]])} description={description} />
            <FluxForm className='tab-next horizontal-fields'
                submitButtonTitle='Approve'
                model={{
                    fields: [
                        {
                            name: 'Notes',
                            title: 'Notes',
                            type: InputTypes.Textarea,
                            required: true
                        }
                    ]
                }} onSubmit={(data) => {
                    confirmed(data);
                }} onCancel={() => Overlay.close()}>
            </FluxForm>
        </flex>
    </FluxModal>
    );
};


export const rollbackDeposit = (transaction, handler) => {
    const confirmed = (data) => {
        var transactionData = { ...data };
        transactionData.Id = transaction.id;

        API.post(TransactionEndpoints.RollbackDeposit, transactionData, 'please wait', 'unable to cancel transaction').then((result) => {
            Overlay.close();
            handler(transactionData, result.result);
        });
    };

    var description = lang('Please confirm, %1 will be removed from players wallet.', [currency(Math.abs(transaction.amount), 2, transaction.currency)]);
    Overlay.open(<FluxModal title={'Cancel Deposit'} onConfirm={confirmed}>
        <flex className='vertical gap-10'>
            <FluxNotice type={NotificationType.Warning} title={lang('Deposit transaction will be cancelled.')} description={description} />
            <FluxForm className='tab-next horizontal-fields'
                submitButtonTitle='Approve'
                model={{
                    fields: [
                        {
                            name: 'Notes',
                            title: 'Notes',
                            type: InputTypes.Textarea,
                            required: true
                        }
                    ]
                }} onSubmit={(data) => {
                    confirmed(data);
                }} onCancel={() => Overlay.close()}>
            </FluxForm>
        </flex>
    </FluxModal>
    );
};



export const rejectTransction = (transaction, type, handler) => {
    const confirmed = (data) => {
        var transactionData = { ...data };
        transactionData.Id = transaction.id;
        transactionData.Amount = Math.abs(transaction.amount);
        transactionData.ConfirmedAmount = Math.abs(transaction.amount);

        API.post(`${TransactionEndpoints.Reject}/${transaction.id}`, transactionData, 'please wait', 'unable to cancel transaction').then((result) => {
            Modal.close();
            handler(transactionData, result.result);
        });
    };

    var description = '';
    switch (type) {
        case 0:
        case 2:
            description = lang('Please confirm, deposit will be cancelled and there will be no change in the player balance.', [currency(Math.abs(transaction.amount), 2, transaction.currency)]);
            break;
        case 1:
        case 3:
            description = lang('Please confirm, %1 will be deposit back to players wallet.', [currency(Math.abs(transaction.amount), 2, transaction.currency)]);
            break;

    }
    Modal.open(<FluxModal title={'Cancel Transaction'} onConfirm={confirmed}>
        <flex className='vertical gap-10'>
            <FluxNotice type={NotificationType.Warning} title={lang('%1 transaction will be rejected.', [TransactionTypes[type]])} description={description} />
            <FluxForm className='tab-next horizontal-fields'
                submitButtonTitle='Reject'
                model={{
                    fields: [
                        {
                            name: 'Notes',
                            title: 'Notes',
                            placeholder: 'Reject note',
                            type: InputTypes.Textarea,
                            maxLength: 255,
                            required: true
                        },
                        {
                            name: 'PlayerMessage',
                            title: 'Player Message',
                            type: InputTypes.Textarea,
                            placeholder: '(optional) Leave a message to the player',
                            maxLength: 255,
                            required: false
                        }
                    ]
                }} onSubmit={(data) => {
                    confirmed(data);
                }} onCancel={() => Modal.close()}>
            </FluxForm>
        </flex>
    </FluxModal>
    );
};



export const confirmTransaction = (transaction, type, handler) => {
    const confirmModal = {
        fields: [
            {
                name: 'ConfirmedAmount',
                title: 'Confirmed Amount',
                type: InputTypes.Number,
                value: Math.abs(transaction.amount),
                required: true,
                hidden: type == 1 || type == 3
            },
            {
                name: 'Notes',
                title: 'Notes',
                type: InputTypes.Textarea,
                required: true
            }
        ]
    };

    const confirmed = (data) => {
        var transactionData = { ...data };
        transactionData.Id = transaction.id;
        transactionData.Amount = Math.abs(transaction.amount);
        transactionData.Data = transaction.data;
        transactionData.DeductBonus = data.DeductBonusAmount;

        API.post(`${TransactionEndpoints.Confirm}/${transaction.id}`, transactionData, 'please wait', 'unable to confirm transaction').then((result) => {
            Modal.close();
            handler(transactionData, result.result);
        });
    };

    var description = '';
    switch (type) {
        case 0:
        case 2:
            description = lang('Please confirm, %1 will be deposited to players wallet.', [currency(Math.abs(transaction.amount), 2, transaction.currency)]);
            break;
        case 1:
        case 3:
            description = lang('Please confirm, players balance will not change.', [currency(Math.abs(transaction.amount), 2, transaction.currency)]);
            break;

    }

    if (type == 1) {
        Modal.open(<ConfirmWithdrawTransactionModal
            title={'Confirm Transaction'} onConfirm={confirmed} transaction={transaction} />
        );
        return;
    }

    Modal.open(<FluxModal title={'Confirm Transaction'} onConfirm={confirmed}>
        <flex className='vertical gap-10'>
            <FluxNotice type={NotificationType.Warning} title={lang('%1 transaction will be confirmed.', [TransactionTypes[type]])} description={description} />
            <FluxForm className='tab-next horizontal-fields'
                submitButtonTitle='Confirm'
                model={confirmModal} onSubmit={(data) => {
                    confirmed(data);
                }} onCancel={() => Modal.close()}>
            </FluxForm>
        </flex>
    </FluxModal>
    );
};



export const modifyRolloverLeftAmount = (player, handler) => {
    const confirmed = (data) => {
        var transactionData = { ...data };
        transactionData.PlayerId = player.id;
        transactionData.TransactionType = 0;
        transactionData.Currency = player.currency;

        API.post(RolloverAmountEndpoints.Modify, transactionData, 'please wait', 'unable to create transaction').then((result) => {
            Modal.close();
            handler(transactionData, result.result);
        });
    };
    Modal.open(<FluxModal title={'Modify left rollover amount'} onConfirm={confirmed}>
        <FluxForm className='tab-next' model={rolloverLeftAmountModel}
            onSubmit={(data) => {
                confirmed(data);
            }}
            onCancel={() => Modal.close()}>
        </FluxForm>
    </FluxModal>
    );
};




export const resetRolloverLeftAmount = (player, handler) => {
    const confirmed = (data) => {
        var transactionData = { ...data };
        transactionData.PlayerId = player.id;
        transactionData.TransactionType = 0;
        transactionData.Currency = player.currency;

        API.post(RolloverAmountEndpoints.Reset, transactionData, 'please wait', 'unable to create transaction').then((result) => {
            Modal.close();
            handler(transactionData, result.result);
        });
    };
    Modal.open(<FluxModal title={'Modify left rollover amount'} onConfirm={confirmed}>
        <FluxForm className='tab-next' model={{
            fields: [
                {
                    name: 'Notes',
                    title: 'Notes',
                    type: InputTypes.Textarea
                }
            ]
        }} onSubmit={(data) => {
            confirmed(data);
        }} onCancel={() => Modal.close()}>
        </FluxForm>
    </FluxModal>
    );
};