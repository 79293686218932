import React from 'react';
import PropTypes from 'prop-types';
import { ActionButton, FluxCard } from '../../../components/card/FluxCard';
import { Button } from '../../../components/button/Button';
import { lang } from '../../../lib/Localization/language';
import { depositPlayer, resetBalance, withdrawPlayer, modifyRolloverLeftAmount, resetRolloverLeftAmount } from '../../../actions/TransactionActions';
import { cashBonus } from '../../../actions/BonusActions';
import { Popper } from '../../../components/notification/FluxPopper';
import { currency } from '../../../components/currency/Currency';
import { useSelector } from 'react-redux';
import { ActionTypes } from '../../../lib/ActionManager/FluxActions';

export const PlayerBalance = (props) => {
    const player = props.player;
    const user = useSelector((state) => state.user.loginInformation);
    const isAffiliate = user.AffiliateId > 0;

    return <FluxCard title={lang('Balance')}
        buttons={isAffiliate ? null : <ActionButton title='Remove Balance' icon='times' onClick={() => resetBalance(player, false, () => props.onTransactionComplete())} />}
        footer={isAffiliate ? null :
            <flex className='gap-10 center'>
                <Button title='Deposit' className='success' onClick={() => {
                    depositPlayer(player, false, (data) => {
                        Popper.pop('information', lang('%1 deposited to the player.', [currency(data.Amount, 2, data.Currency)]));
                        props.onTransactionComplete();
                    });
                }} />
                <Button title='Withdraw' className='warning' onClick={() => {
                    withdrawPlayer(player, false, (data) => {
                        Popper.pop('information', lang('%1 withdraw completed from player account.', [currency(data.Amount, 2, data.Currency)]));
                        props.onTransactionComplete();
                    });
                }} />
                <Button title='Cash Bonus' className='success' onClick={() => {
                    cashBonus(player, (data) => {
                        Popper.pop('information', lang('%1 bonus deposited to player.', [currency(data.Amount, 2, data.Currency)]));
                        props.onTransactionComplete();
                    });
                }} />
            </flex>
        }>
        <h2 className='center'>{currency(player.balance, 2, player.currency)}</h2>
        <flex className='gap-5 center padding-top'>
            <span className='bold'>{lang('Rollover left')}</span>
            <span>{currency(player.pendingRolloverAmount, 2, player.currency)}</span>
            <ActionButton action={ActionTypes.ModifyDepositRollover} icon='fa-pen' title='Modify' onClick={() => {
                modifyRolloverLeftAmount(player, (data) => {
                    Popper.pop('information', lang('Rollover left amount is reduced to %1.', [currency(data.Amount, 2, data.Currency)]));
                    props.onTransactionComplete();
                });
            }} />
            <ActionButton action={ActionTypes.ResetDepositRollover} icon='fa-times' title='Remove' onClick={() => {
                resetRolloverLeftAmount(player, () => {
                    Popper.pop('information', lang('Rollover left amount is set to 0.'));
                    props.onTransactionComplete();
                });
            }} />
        </flex>
    </FluxCard>;
};

PlayerBalance.propTypes = {
    player: PropTypes.object,
    onTransactionComplete: PropTypes.func
};
