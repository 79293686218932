/* eslint-disable react/display-name */
import React from 'react';
import { ReportEndpoints } from '../../../../v2/Lib/Api/Api';
import { Currency } from '../../../components/currency/Currency';
import { InputTypes } from '../../../components/form/InputTypes';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { footerCurrencyValues } from '../../../lib/Helpers/Reports/Footer';
import { CurrencyFilterField } from '../../../lib/Models/CurrencyModel';

export const searchModel = {
    fields: [
        {
            name: 'CompleteDate',
            title: 'Complete Date',
            type: InputTypes.DateTime,
            nullable: true,
            between: true,
            value: {
                start: DateHelpers.yesterday(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'Username'
        },
        {
            name: 'Code'
        },
        {
            name: 'PromotionId',
            title: 'Promotion',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'promotion',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'RetailId',
            title: 'Retail Id',
            nullable: true,
            type: InputTypes.Number
        },
        {
            name: 'RetailCode',
            title: 'Retail Code'
        },
        {
            name: 'Group',
            title: 'Player Group',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'myPlayerGroups',
                key: 'Id',
                title: ['Name']
            }
        },
        CurrencyFilterField,
        {
            name: 'Retails',
            title: 'Only Retails',
            type: InputTypes.Bool
        }
    ]
};

export const resultModel = {
    fields: [
        {
            name: 'Promotion',
            title: 'Promotion'
        },
        {
            name: 'Currency',
            title: 'Currency'
        },
        {
            name: 'Total',
            title: 'Total'
        },
        {
            name: 'TotalBonus',
            title: 'Total Bonus',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'TotalPayout',
            title: 'Total Payout',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        }
    ]
};

export const ReportBonusCompletedPayoutsSummary = () => {
    return <FluxSearch
        model={searchModel}
        endpoint={ReportEndpoints.BonusCompletedPayoutSummaryReport}
        resultModel={resultModel}
    />;
};
