import React from 'react';
import { FluxTab } from '../../../components/tab/FluxTab';
import { BonusCreditOnRegister } from './Components/BonusCreditOnRegister';
import { TurnoverRules } from './Components/TurnoverRules';
import { GeneralSettings } from './Components/GeneralSettings';
import { PayoutSettings } from './Components/PayoutSettings';
import { SportsSettings } from './Components/SportsSettings';
import { HourseGreyhoundSettings } from './Components/HourseGreyhoundSettings';
import { CasinoSettings } from './Components/CasinoSettings';

export const DiscountBonus = (props) => {
    var buttons = [
        {
            title: 'General Settings'
        },
        {
            title: 'Credit'
        },
        {
            title: 'Rules'
        },
        {
            title: 'Payout Settings'
        },
        {
            title: 'Sports'
        },
        {
            title: 'Horse & Greyhound'
        },
        {
            title: 'Game Settings'
        }
    ];

    return <FluxTab buttons={buttons} className='gap-10 fx-shadow fx-radius rounded-buttons'>
        <GeneralSettings {...props} />
        <BonusCreditOnRegister {...props} />
        <TurnoverRules {...props} />
        <PayoutSettings {...props} />
        <SportsSettings {...props} />
        <HourseGreyhoundSettings  {...props} />
        <CasinoSettings  {...props} />
    </FluxTab>;
};