/* eslint-disable react/display-name */

import '../../../../components/sports/RiskManagement/RiskLevels/_riskLevelConfiguration.scss';
import './_liability-event-monitor.scss';

import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { appHistory } from '../../../../..';
import { Modal } from '../../../../components/application/FluxApp';
import { Button } from '../../../../components/button/Button';
import { ActionButton, FluxCard } from '../../../../components/card/FluxCard';
import { FluxForm } from '../../../../components/form/FluxForm';
import { ConfirmationModal } from '../../../../components/modal/ConfirmationModal/ConfirmationModal';
import { FluxModal } from '../../../../components/modal/FluxModal';
import { FluxNotice } from '../../../../components/notification/FluxNotice';
import { FluxCarouselList } from '../../../../components/sports/FluxCarouselList/FluxCarouselList';
import { ShowAddNewTemplateModal, customTemplateModel } from '../../../../components/sports/RiskManagement/RiskLimits/Components/RiskTemplates/RiskTemplates';
import { SportIcon } from '../../../../components/sports/SportIcon/SportIcon';
import { Table } from '../../../../components/table/Table';
import { API } from '../../../../lib/API/Api';
import { RiskLimitEndpoints, SportRiskMonitorEndpoints } from '../../../../lib/API/Endpoints';
import { lang } from '../../../../lib/Localization/language';
import { BetMonitorSocketContext } from '../MonitorTickets/BetmonitorSocket';
import { LiabilityEvent } from './LiabilityEvent';
import { Popper } from '../../../../components/notification/FluxPopper';

const fetchEvents = (setter) => {
    API.post(SportRiskMonitorEndpoints.Liabilities, {
        operatorId: user.UserPrivileges.PointOfView,
        markets: [1, 2, 226, 52]
    }, 'please wait', 'unable to load').then((result) => {
        setter(result.result);
    });
}

const showAddNewCustomTemplate = (row, user, onSaveComplete) => {
    const key = `custom_${row.sport}_${row?.region}_${row.league}_${row.eventName}_${row.id}`
    var customTemplate = {};
    Modal.open(<FluxModal title={'Add New Template'} >
        <FluxCard>
            <FluxForm model={customTemplateModel} data={{
                name: key,
            }} key={key} buttons={<flex className='gap-5 padding'><Button title='Save' className={'success'} onClick={() => {
                Modal.open(<ConfirmationModal title='Please confirm' onConfirm={() => {
                    customTemplate.name = key;
                    var tempDatas = Object.assign({}, customTemplate);
                    if (!tempDatas.stakeLimit) {
                        tempDatas.stakeLimit = 0;
                    }
                    if (!tempDatas.liabilityLimit) {
                        tempDatas.liabilityLimit = 0;
                    }
                    if (!tempDatas.maxPayout) {
                        tempDatas.maxPayout = 0;
                    }
                    if (!tempDatas.investigateTicketOverMultiplier) {
                        tempDatas.investigateTicketOverMultiplier = 0;
                    }
                    tempDatas.sportId = row.sportId;
                    tempDatas.regionId = row.regionId;
                    tempDatas.leagueId = row.leagueId;
                    tempDatas.eventId = row.id;
                    tempDatas.customTemplate = true;
                    API.post(RiskLimitEndpoints.SaveTemplate(user.UserPrivileges.PointOfView), tempDatas).then((result) => {
                        Popper.pop('information', 'Limits saved!', 'Custom limits for entity is saved!');
                        Modal.close();
                        if (onSaveComplete) {
                            onSaveComplete();
                        }
                    });
                }}>
                    <FluxNotice type='information' title='Save custom limits?' description={'This limits will be customized for the selected entity!'} />
                </ConfirmationModal>)
            }} /></flex>} onChange={(data) => {
                customTemplate = data;
            }} />
        </FluxCard>
    </FluxModal >);
};

const liabilityModel = (user, onComplete) => {
    return {
        fields: [
            {
                name: 'id',
                title: 'Id',
                render: (val) => {
                    return <a href='#' onClick={(e) => {
                        appHistory.push(`${window.location.pathname}?&id=${val}`)
                        e.preventDefault();
                    }}>{val}</a>
                }
            },
            {
                name: 'date',
                title: 'Date',
                render: (val) => {
                    var date = new Date(val);
                    return <flex className='vertical'>
                        <span>{date.toLocaleDateString('en-Gb', { hours12: false })}</span>
                        <span>{date.toLocaleTimeString('en-Gb', { hours12: false })}</span>
                    </flex>;
                }
            },
            {
                name: 'eventName',
                title: 'Name',
                render: (val, row) => {
                    return <flex className='gap-5 justify-content-between'>
                        <flex className='vertical'>
                            <span>{row.eventName}</span>
                            <flex className='gap-5'>
                                <span>{row.sport}</span>
                                /
                                <span>{row.region}</span>
                                /
                                <span>{row.league}</span>
                            </flex>
                        </flex>
                        {
                            row.templateData?.custom && row.templateData?.name == `custom_${row.sport}_${row?.region}_${row.league}_${row.eventName}_${row.id}` ?
                                <ActionButton title='Edit Custom Template' icon='plus' onClick={() => {
                                    API.post(RiskLimitEndpoints.GetTemplate(user.UserPrivileges.PointOfView, row.limitTemplateId)).then((result) => {
                                        if (result?.result) {
                                            var data = result?.result;
                                            data.sportId = row.sportId;
                                            data.regionId = row.regionId;
                                            data.leagueId = row.leagueId;
                                            data.eventId = row.id;
                                            ShowAddNewTemplateModal(data, () => onComplete(), true, user, data);
                                        }
                                    });
                                }} />
                                : <ActionButton title='Add Custom Template' icon='plus' onClick={() => {
                                    showAddNewCustomTemplate(row, user, () => {
                                        onComplete();
                                    })
                                }} />
                        }
                    </flex>;
                }
            },
            {
                name: 'totalTickets',
                title: 'Tickets',
                render: (val, row) => {
                    return row.volume.totalSlips;
                }
            },
            {
                name: 'configuredLiability',
                title: 'Liability',
                render: (val, row) => {
                    var data = row.configuredLiability > 0 ? row.configuredLiability : row.defaultLiability.toFixed(2);
                    if (data == 0) {
                        return '∞ | Unlimited';
                    }
                    return data;
                }
            },
            {
                name: 'liability',
                title: 'Volume',
                render: (val, row) => {

                    return -row.volume.liability.toFixed(2);
                }
            },
            {
                name: 'progress',
                title: 'Progress',
                render: (val, row) => {
                    if (!row.defaultLiability) {
                        return <flex className='event-statistics'>
                            <flex className='vertical'>
                                <flex className='gap-10 stat' >
                                    <span className='home-score'>{current}</span>
                                    <flex className='vertical stat-progress'>
                                        <flex className='bar'>∞ | Unlimited</flex>
                                    </flex>
                                    <span className='away-score'>{limit}</span>
                                </flex>
                            </flex>
                        </flex>;
                    }
                    var current = parseInt(row.volume?.liability ?? 0);
                    var limit = parseInt(row.defaultLiability);

                    const currentPercentage = (current / limit) * 100;

                    const limitPercentage = 100 - currentPercentage;
                    return <flex className='event-statistics'>
                        <flex className='vertical'>
                            <flex className='gap-10 stat' >
                                <span className='current-score'>{current}</span>
                                <flex className='vertical stat-progress'>
                                    <flex className='bar'>
                                        <flex className='current' style={{ width: (current > limit ? 100 : currentPercentage) + '%' }} />
                                        <flex className='limit' style={{ width: (current > limit ? 0 : limitPercentage) + '%' }} />
                                    </flex>
                                </flex>
                                <span className='limit-score'>{limit}</span>
                            </flex>
                        </flex>
                    </flex>;
                }
            }

        ]
    }
};

export const LiabilityEventMonitor = () => {
    const user = useSelector((state) => state.user.loginInformation);
    const context = useContext(BetMonitorSocketContext);
    const [connected, setConnected] = useState(context.connected);

    const [event, selectEvent] = useState(null);
    const [events, setEvents] = useState(null);

    const [sports, setSports] = useState([]);
    const [selectedSport, selectSport] = useState(null);

    const [showFiltersMenu, toggleFilters] = useState(false);
    const [filterEventId, setFilterEventId] = useState(null);
    const [filterRegion, setFilterRegion] = useState(null);
    const [filterLeague, setFilterLeague] = useState(null);
    const [filterEvent, setFilterEvent] = useState(null);
    const [filterDateMin, setFilterDateMin] = useState(null);
    const [filterDateMax, setFilterDateMax] = useState(null);
    const [tableSorting, setSorting] = useState({ field: 'date', direction: 0 });

    const sorting = {
        Date: 'Date',
        Volume: 'Volume'
    };

    const [sortType, setSortType] = useState(null);

    useEffect(() => {
        fetchEvents(setEvents);
        context.eventHandlers({
            onConnected: () => setConnected(true)
        });
    }, []);

    useEffect(() => {
        if (!events) return;
        findEventFromQuery();
    }, [window.location.search]);

    useEffect(() => {
        if (!events) return;

        var total = 0;
        var copy = {
        };

        events.map(x => {
            if (!copy[x.sportId]) {
                copy[x.sportId] = {
                    id: x.sportId,
                    name: x.sport,
                    total: 0
                };
            }

            copy[x.sportId].total++;
            total++;
        });

        setSports([{
            id: '-1',
            name: 'All',
            total: total
        }].concat(Object.values(copy)));

        findEventFromQuery();
        if (!sortType) {
            setSortType(sorting.Date);
        }
    }, [events]);

    useEffect(() => {
        if (!sports) return;
        if (!selectedSport) {
            selectSport(sports[0]);
        }
    }, [sports]);

    useEffect(() => {
        if (!events) return;
        switch (sortType) {
            case sorting.Date:
                var sorted = events.sort((a, b) => {
                    return new Date(a.date) - new Date(b.date);
                });

                sorted.key = Date.now();
                setEvents(sorted);
                break;
            case sorting.Volume:
                var sorted = events.sort((a, b) => {
                    return a.volume.liability - b.volume.liability;
                });

                sorted.key = Date.now();
                setEvents(sorted);
                break;
        }
    }, [sortType]);

    const findEventFromQuery = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
        if (!isNaN(parseFloat(id))) {
            var found = events.find(x => x.id == id);
            if (found && !event?.id != found.id) {
                selectEvent(found);
            }
        }
    };

    const filter = (data) => {
        if (filterEventId) {
            data = data.filter(x => x.id.toLowerCase().includes(filterEventId.toLowerCase()));
        }
        if (filterDateMin) {
            var date = new Date(filterDateMin)
            data = data.filter(x => new Date(x.date) >= date);
        }
        if (filterDateMax) {
            var date = new Date(filterDateMax)
            data = data.filter(x => new Date(x.date) <= date);
        }
        if (selectedSport && selectedSport.id != '-1') {
            data = data.filter(x => x.sportId == selectedSport.id);
        }
        if (filterRegion) {
            data = data.filter(x => x.region.toLowerCase().includes(filterRegion.toLowerCase()));
        }
        if (filterLeague) {
            data = data.filter(x => x.league.toLowerCase().includes(filterLeague.toLowerCase()));
        }
        if (filterEvent) {
            data = data.filter(x => x.eventName.toLowerCase().includes(filterEvent.toLowerCase()));
        }
        
        data = data.sort((a, b) => {
            switch (tableSorting.column) {
                case 'id':
                    return tableSorting.direction == 1 ? (a.id > b.id ? 1 : -1) : (a.id > b.id ? -1 : 1);
                case 'eventName':
                    return tableSorting.direction == 1 ? (a.eventName > b.eventName ? 1 : -1) : (a.eventName > b.eventName ? -1 : 1);
                case 'totalTickets':
                    return tableSorting.direction == 1 ? (a.volume.totalSlips > b.volume.totalSlips ? 1 : -1) : (a.volume.totalSlips > b.volume.totalSlips ? -1 : 1);
                case 'configuredLiability':
                    return tableSorting.direction == 1 ? (a.configuredLiability > b.configuredLiability ? 1 : -1) : (a.configuredLiability > b.configuredLiability ? -1 : 1);
                case 'liability':
                    return tableSorting.direction == 1 ? (a.volume.liability > b.volume.liability ? 1 : -1) : (a.volume.liability > b.volume.liability ? -1 : 1);
                case 'progress':
                    {
                        if (tableSorting.direction == 1) {
                            if (!a.defaultLiability) {
                                return 1;
                            } else if (!b.defaultLiability) {
                                return -1;
                            }
                        } else {
                            if (!a.defaultLiability) {
                                return -1;
                            } else if (!b.defaultLiability) {
                                return 1;
                            }
                        }
                        var currenta = parseInt(a.volume?.liability ?? 0);
                        var limita = parseInt(a.defaultLiability);

                        const currentPercentagea = (currenta / limita) * 100;
                        var currentb = parseInt(b.volume?.liability ?? 0);
                        var limitb = parseInt(b.defaultLiability);
                        const currentPercentageb = (currentb / limitb) * 100;
                        return tableSorting.direction == 1 ? (currentPercentagea > currentPercentageb ? 1 : -1) :
                            (currentPercentagea > currentPercentageb ? -1 : 1);
                    }
                case 'date':
                default:
                    return tableSorting.direction == 1 ? (a.date > b.date ? 1 : -1) : (a.date > b.date ? -1 : 1);
            }
        });
        return data;
    };

    return <flex className='vertical event-liabilities-monitor gap-10'>
        <FluxCard className={showFiltersMenu ? '' : 'no-padding'} title='Filters' buttons={
            <ActionButton
                title={showFiltersMenu ? 'Close Filters' : 'Open Filters'}
                icon={showFiltersMenu ? 'minus' : 'plus'}
                onClick={() => toggleFilters(!showFiltersMenu)} />
        }>
            {showFiltersMenu &&
                <flex className='vertical fit fit-children'>
                    <div className='w-100 padding'>
                        <FluxCarouselList selected={selectedSport ?? null} className='carousel-list w-100' items={sports} onItemClick={(sport) => {
                            selectSport(sport);
                        }} onRenderItem={(item) => {
                            return <React.Fragment>
                                <div className='icon-holder'>
                                    <SportIcon sport={item.id == -1 ? 'soccer' : item.name} />
                                </div>
                                <span>{item.name}</span>
                                <span>{item.total}</span>
                            </React.Fragment>;
                        }}>
                        </FluxCarouselList>
                    </div>
                    <flex className='justify-content-start gap-20 padding'>
                        <flex className='gap-5 justify-content-center' >
                            <label className='bold'>{lang('Event Id')}</label>
                            <input type='text' className='w-100' placeholder='Event Id' defaultValue={null}
                                onChange={(e) => setFilterEventId(e.target.value)} />
                        </flex>
                        <flex className='gap-5 justify-content-center'>
                            <label className='bold'>{lang('Date Start')}</label>
                            <input type='date' className='w-100' defaultValue={filterDateMin ? new Date(filterDateMin).toISOString().split('T')[0] : null}
                                onChange={(e) => setFilterDateMin(e.target.value)} />
                        </flex>
                        <flex className='gap-5 justify-content-center'>
                            <label className='bold'>{lang('Date End')}</label>
                            <input type='date' className='w-100' defaultValue={filterDateMax ? new Date(filterDateMax).toISOString().split('T')[0] : null}
                                onChange={(e) => setFilterDateMax(e.target.value)} />
                        </flex>
                    </flex>
                    <flex className='justify-content-start gap-20 padding'>
                        <flex className='gap-5 justify-content-center' >
                            <label className='bold'>{lang('Region')}</label>
                            <input type='text' className='w-100' placeholder='Region' defaultValue={null}
                                onChange={(e) => setFilterRegion(e.target.value)} />
                        </flex>
                        <flex className='gap-5 justify-content-center' >
                            <label className='bold'>{lang('League')}</label>
                            <input type='text' className='w-100' placeholder='League' defaultValue={null}
                                onChange={(e) => setFilterLeague(e.target.value)} />
                        </flex>
                        <flex className='gap-5 justify-content-center' >
                            <label className='bold'>{lang('Event')}</label>
                            <input type='text' className='w-100' placeholder='Event' defaultValue={null}
                                onChange={(e) => setFilterEvent(e.target.value)} />
                        </flex>

                    </flex>
                </flex>
            }
        </FluxCard >

        {!event &&
            <FluxCard title='Events' className={`no-padding overflow-hidden align-self-stretch${connected ? '' : ' disabled'}`}>
                <Table
                    options={{
                        sorting: {
                            enabled: true,
                            field: {
                                column: tableSorting.field,
                                direction: tableSorting.direction
                            },
                            onSortingChange: (s) => {
                                setSorting(s);
                            }
                        }
                    }}
                    model={liabilityModel(user, () => fetchEvents(setEvents))}
                    data={filter(events ?? [])}
                    context={{ scores: null }} className={'border'} />
            </FluxCard>
        }
        {
            event && <flex className='gap-5 liabilities overflow-hidden'>
                <LiabilityEvent id={event.id} key={event.id} onClose={() => {
                    appHistory.push(window.location.pathname);
                    selectEvent(null);
                }} />
            </flex >
        }
    </flex >;
};