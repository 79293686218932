import { InputTypes } from '../../../components/form/InputTypes';
import { toSnakeCase } from '../../../../components/Centrum/helpers';

export const limitKinds = {
    1: 'Each Transaction',
    2: 'Total Transaction'
};

export const limitTypes = {
    2: 'Provider',
    3: 'Player',
    4: 'Retail',
    5: 'Selected Provider',
    6: 'Selected Player',
    7: 'Selected Retail',
    8: 'Selected Player Group',
};

export const resetTypes = {
    1: 'Hourly',
    2: 'Daily',
    3: 'Weekly',
    4: 'Monthly',
    5: 'Yearly',
    6: 'Cron',
};
//FOR DYNAMIC FIELD PURPOSES, all fields must be declared one by one...

//#region General
export const LimitType = {
    name: 'LimitType',
    title: 'Limit Type',
    type: InputTypes.Select,
    required: true,
    value: [1],
    values: limitTypes
};

export const LimitKind = {
    name: 'LimitKind',
    title: 'Limit Kind',
    type: InputTypes.Select,
    required: true,
    values: limitKinds
};

export const AutoConfirm = {
    name: 'AutoConfirm',
    title: 'Auto Confirm',
    type: InputTypes.Bool
};

//#endregion General

//#region Detail

//#region Limit Data variations
export const LimitDataProvider = {
    name: 'LimitData',
    title: 'Provider',
    type: InputTypes.Select,
    nullable: true,
    data: {
        source: 'myProviders',
        key: 'id',
        title: ['display'],
        parser: (result) => {
            var results = [];
            var group = 'Gaming';
            result.result.map(x => {
                x = toSnakeCase(x);
                if (x.type == 128) group = 'Payment Provider';
                results.push({
                    value: x.id, title: x.display, group: {
                        id: group,
                        title: group
                    }
                });
            });
            return results;
        }
    }
};

export const LimitDataPlayer = {
    name: 'LimitData',
    title: 'Player Id'
};

export const LimitDataRetail = {
    name: 'LimitData',
    title: 'Retail',
    type: InputTypes.Select,
    required: true,
    data: {
        source: 'myRetails',
        key: 'Id',
        title: ['Name']
    }
};

export const LimitDataPlayerGroup = {
    name: 'LimitData',
    title: 'Player Group',
    type: InputTypes.Select,
    required: true,
    data: {
        source: 'myPlayerGroups',
        key: 'Id',
        title: ['Name']
    }
};

//#endregion Limit Data variations

export const Reset = {
    name: 'Reset',
    title: 'Reset',
    type: InputTypes.Select,
    required: true,
    multiple: false,
    values: resetTypes
};

export const ResetCron = {
    name: 'ResetCron',
    title: 'Reset Cron',
    type: InputTypes.Text,
    placeholder: '* * * *',
    nullable: true
};

export const Limit = {
    name: 'Limit',
    title: 'Limit',
    type: InputTypes.Number,
    required: true
};

//#endregion Detail
