import './_manage-promotions.scss';

import React, { useEffect, useState } from 'react';
import { appHistory } from '../../../..';
import { Modal } from '../../../components/application/FluxApp';
import { Button } from '../../../components/button/Button';
import { FluxCard } from '../../../components/card/FluxCard';
import { FluxForm } from '../../../components/form/FluxForm';
import { InputTypes } from '../../../components/form/InputTypes';
import { Icon } from '../../../components/icon/Icon';
import { ConfirmationModal } from '../../../components/modal/ConfirmationModal/ConfirmationModal';
import { FluxModal } from '../../../components/modal/FluxModal';
import { FluxNotice } from '../../../components/notification/FluxNotice';
import { NotificationType } from '../../../components/notification/FluxNotification';
import { Popper } from '../../../components/notification/FluxPopper';
import { Table } from '../../../components/table/Table';
import { API } from '../../../lib/API/Api';
import { DataEndpoints, PromotionEndpoints } from '../../../lib/API/Endpoints';
import { BonusType } from '../../../lib/Enums/BonusEnums';
import { lang } from '../../../lib/Localization/language';
import { BetBoosterBonus } from '../Promotions/BetBoosterBonus';
import { CashBonus } from '../Promotions/CashBonus';
import { DepositBonus } from '../Promotions/DepositBonus';
import { DiscountBonus } from '../Promotions/DiscountBonus';
import { FreespinBonus } from '../Promotions/FreespinBonus';
import { InsuranceBonus } from '../Promotions/InsuranceBonus';
import { MultiplierBonus } from '../Promotions/MultiplierBonus';

import { CasinoTournamentBonus } from '../Promotions/CasinoTournamentBonus';
import { Campaign } from '../Promotions/Components/Campaign/Campaign';
import { ReferralProgram } from '../Promotions/Components/ReferralProgram/ReferralProgram';
import { RefillBonusMoney } from '../Promotions/Components/RefillBonusMoney/RefillBonusMoney';
import { SportTournament } from '../Promotions/Components/SportTournament/SportTournament';
import { FreeBetBonus } from '../Promotions/FreeBetBonus';
import { SplitFreeBetBonus } from '../Promotions/SplitFreeBetBonus';
import { WelcomeBonus } from '../Promotions/WelcomeBonus';
import { FirstTimeFreeBet } from '../Promotions/Components/FirstTimeFreeBet/FirstTimeFreeBet';
import { CashbackBonus } from '../Promotions/CashbackBonus';


const Views = {
    Table: 'Table',
    Grid: 'Grid'
};


export const ManagePromotions = () => {

    const [currencies, setCurrencies] = useState(null);
    const [promotions, setPromotions] = useState([]);
    const [promotion, selectPromotion] = useState(undefined);
    const [updatingPromotionData, setUpdatingData] = useState(null);

    const [view, setView] = useState(Views.Grid);

    const [filterType, setFilterType] = useState(null);
    const [filterWord, setFilterWord] = useState(null);

    useEffect(() => {
        API.post(PromotionEndpoints.List, {}).then((result) => {
            API.post(DataEndpoints.EnabledCurrencies).then((currencyResult) => {
                setCurrencies(currencyResult.result);
                result.result.map(x => {
                    if (!x.StartDate || new Date(x.StartDate) == 'Invalid Date') {
                        x.StartDate = '2020-01-01';
                        //
                    }
                    if (!x.EndDate || new Date(x.EndDate) == 'Invalid Date') {
                        x.EndDate = '2042-01-01';
                        //
                    }
                });
                setPromotions(result.result.sort((a, b) => a.Weight > b.Weight));
            });
        });
    }, []);

    useEffect(() => {
        if (promotion) {
            appHistory.push(`/promotions/manage-promotions?&id=${promotion.Id}`);
            var copy = { ...promotion };

            if (!copy.Configuration) copy.Configuration = '{}';
            copy.Configuration = JSON.parse(copy.Configuration);
            setUpdatingData(copy);
        } else {
            if (promotion === undefined) {
                return;
            }
            var dataIndex = promotions.findIndex(x => x.Id == updatingPromotionData.Id);
            if (dataIndex != -1) {
                var temp = Object.assign([], promotions);
                var tempUpdateData = Object.assign({}, updatingPromotionData);
                if (typeof updatingPromotionData.Configuration === 'object') {
                    tempUpdateData.Configuration = JSON.stringify(updatingPromotionData.Configuration);
                }
                temp[dataIndex] = tempUpdateData;
                setPromotions(temp);
            }
            setUpdatingData(null);
            appHistory.push('/promotions/manage-promotions');
        }
    }, [promotion]);

    useEffect(() => {
        if (!promotions) return;
        const urlParams = new URLSearchParams(window.location.search);
        const id = parseFloat(urlParams.get('id'));
        if (isNaN(id)) return;
        selectPromotion(promotions.find(x => x.Id == id));
    }, [promotions]);

    const addNew = () => {
        var typesCopy = JSON.parse(JSON.stringify(BonusType));
        delete typesCopy['14'];
        if (promotions.find(x => x.Type == 9 && x.Enabled == 1)) {
            delete typesCopy['9'];
        }
        if (promotions.find(x => x.Type == 7 && x.Enabled == 1)) {
            delete typesCopy['7'];
        }
        if (promotions.find(x => x.Type == 6 && x.Enabled == 1)) {
            delete typesCopy['11'];
        }
        if (promotions.find(x => x.Type == 19 && x.Enabled == 1)) {
            delete typesCopy['19'];
        }
        Modal.open(<FluxModal title='Create Promotion' className={'no-overflow'}>
            <FluxForm
                model={{
                    fields: [
                        {
                            name: 'Name',
                            required: true
                        },
                        {
                            name: 'Description',
                            required: true
                        },
                        {
                            name: 'Type',
                            type: InputTypes.Select,
                            values: typesCopy
                        }
                    ]
                }}
                onCancel={() => Modal.close()}
                onSubmit={(data) => {
                    API.post(PromotionEndpoints.Save, data).then(result => {
                        var id = result.result;
                        API.post(PromotionEndpoints.List, {}).then((result) => {
                            appHistory.push(`/promotions/manage-promotions?&id=${id}`);
                            setPromotions(result.result.sort((a, b) => a.Weight > b.Weight));
                        });

                        Modal.close();
                    });
                }} />
        </FluxModal>);
    };

    const addNewCampaign = () => {
        Modal.open(<FluxModal title='Create Promotion' className={'no-overflow'}>
            <FluxForm
                model={{
                    fields: [
                        {
                            name: 'Name',
                            required: true
                        },
                        {
                            name: 'Description',
                            required: true
                        },
                        {
                            name: 'Type',
                            type: 'hidden',
                            readonly: true,
                            value: 14,
                            render: () => {
                                return lang('Campaign');
                            }
                        }
                    ]
                }}
                onCancel={() => Modal.close()}
                onSubmit={(data) => {
                    API.post(PromotionEndpoints.Save, data).then(result => {
                        var id = result.result;
                        API.post(PromotionEndpoints.List, {}).then((result) => {
                            appHistory.push(`/promotions/manage-promotions?&id=${id}`);
                            setPromotions(result.result.sort((a, b) => a.Weight > b.Weight));
                        });

                        Modal.close();
                    });
                }} />
        </FluxModal>);
    };

    const save = () => {
        Modal.open(<ConfirmationModal title='Save promotion' onConfirm={() => {
            var copy = { ...updatingPromotionData };
            if (!copy.Configuration) {
                copy.Configuration = '{}';
            }
            if (!copy.StartDate || new Date(copy.StartDate) == 'Invalid Date') {
                copy.StartDate = '2020-01-01';
                //
            }
            if (!copy.EndDate || new Date(copy.EndDate) == 'Invalid Date') {
                copy.EndDate = '2042-01-01';
                //
            }

            var depositLimits = {};

            if (copy.Configuration.deposit?.depositLimits) {
                Object.keys(copy.Configuration.deposit?.depositLimits).map(currency => {
                    depositLimits[currency] = Object.values(copy.Configuration.deposit?.depositLimits[currency]);
                });

                copy.Configuration.deposit.depositLimits = depositLimits;
            }


            copy.Configuration = JSON.stringify(copy.Configuration);

            API.post(PromotionEndpoints.Save, copy).then(() => {
                Popper.pop('information', 'Promotion saved', 'All settings are effective.');
            });
        }}>
            <FluxNotice type={NotificationType.Information} title='Please confirm operation!' description={'Updated settings will be effective after saving the promotions.'} />
        </ConfirmationModal>);
    };

    const saveWeight = () => {
        Modal.open(<ConfirmationModal title='Save Weights' onConfirm={() => {

            API.post(PromotionEndpoints.SaveWeights, promotions.map(x => ({
                Id: x.Id,
                Weight: x.Weight
            }))).then(() => {
                Popper.pop('information', 'weights saved', 'All settings are effective.');
            });
        }}>
            <FluxNotice type={NotificationType.Information} title='Please confirm saving weights!'
                description={'Updated settings will be effective after saving the weights.'} />
        </ConfirmationModal>);
    };

    const filteredPromotions = () => {
        var data = [...promotions];
        if (data && filterWord) {
            return data.filter(x => x.Name?.toLowerCase()?.includes(filterWord?.toLowerCase()) || x.Description?.toLowerCase()?.includes(filterWord?.toLowerCase()));
        }
        return data;
    };

    if (updatingPromotionData) {
        var component = null;
        switch (updatingPromotionData.Type) {
            case  1://Welcome
                component = <WelcomeBonus promotion={updatingPromotionData} currencies={currencies} onChange={(data) => {
                    setUpdatingData(data);
                }} />;
                break;
            case 2://Deposit
                component = <DepositBonus promotion={updatingPromotionData} currencies={currencies} onChange={(data) => {
                    setUpdatingData(data);
                }} />;
                break;
            case 3://Discount
                component = <DiscountBonus promotion={updatingPromotionData} currencies={currencies} onChange={(data) => {
                    setUpdatingData(data);
                }} />;
                break;
            case 5://Free Spin
                component = <FreespinBonus promotion={updatingPromotionData} currencies={currencies} onChange={(data) => {
                    setUpdatingData(data);
                }} />;
                break;
            case 7://Near Miss Bonus
                component = <InsuranceBonus promotion={updatingPromotionData} currencies={currencies} onChange={(data) => {
                    setUpdatingData(data);
                }} />;
                break;
            case 8://Cash Bonus
                component = <CashBonus promotion={updatingPromotionData} currencies={currencies} onChange={(data) => {
                    setUpdatingData(data);
                }} />;
                break;
            case 10://Bet Booster
                component = <BetBoosterBonus promotion={updatingPromotionData} currencies={currencies} onChange={(data) => {
                    setUpdatingData(data);
                }} />;
                break;
            case 11://Multiplier Bonus
                component = <MultiplierBonus promotion={updatingPromotionData} currencies={currencies} onChange={(data) => {
                    setUpdatingData(data);
                }} />;
                break;
            case 12://Casino Tournaments
                component = <CasinoTournamentBonus promotion={updatingPromotionData} currencies={currencies} onChange={(data) => {
                    setUpdatingData(data);
                }} />;
                break;
            case 13://Split Free-Bet Bonus
                component = <SplitFreeBetBonus promotion={updatingPromotionData} currencies={currencies} onChange={(data) => {
                    setUpdatingData(data);
                }} />;
                break;
            case 14://Campaign
                component = <Campaign promotion={updatingPromotionData} currencies={currencies} onChange={(data) => {
                    setUpdatingData(data);
                }} />;
                break;
            case 15://Sport Tournament
                component = <SportTournament promotion={updatingPromotionData} currencies={currencies} onChange={(data) => {
                    setUpdatingData(data);
                }} />;
                break;
            case 16://Referral Program
                component = <ReferralProgram promotion={updatingPromotionData} currencies={currencies} onChange={(data) => {
                    setUpdatingData(data);
                }} />;
                break;
            case 17://Free-Bet Bonus
                component = <FreeBetBonus promotion={updatingPromotionData} currencies={currencies} onChange={(data) => {
                    setUpdatingData(data);
                }} />;
                break;
            case 18://ReFill Bonus
                component = <RefillBonusMoney promotion={updatingPromotionData} currencies={currencies} onChange={(data) => {
                    setUpdatingData(data);
                }} />;
                break;
            case 19://First Time Free Bet
                component = <FirstTimeFreeBet promotion={updatingPromotionData} currencies={currencies} onChange={(data) => {
                    setUpdatingData(data);
                }} />;
                break;
            case 20://First Time Deposit
                component = <DepositBonus promotion={updatingPromotionData} currencies={currencies} onChange={(data) => {
                    setUpdatingData(data);
                }} />;
                break;
            case 21://Cashback Bonus
                component = <CashbackBonus promotion={updatingPromotionData} currencies={currencies} onChange={(data) => {
                    setUpdatingData(data);
                }} />;
                break;
            default:
                console.log(updatingPromotionData.Type);
                return;
        }

        return <flex className='vertical gap-10 manage-promotions'>
            <FluxCard>
                <flex className='gap-20'>
                    <Button className='success' onClick={() => selectPromotion(null)}><flex className='gap-5'><Icon icon='chevrons-left' size='xs' /><span>{lang('Close and Back')}</span></flex></Button>
                    <flex className='gap-5'>
                        <span>{BonusType[updatingPromotionData.Type]}</span>
                        <Icon icon='chevron-right' size='xs' />
                        <span>{updatingPromotionData.Name}</span>
                    </flex>
                    <flex className='align-right'>
                        <Button className='success' title='Save' onClick={() => save()} />
                    </flex>
                </flex>
            </FluxCard>
            {component}
        </flex>;
    }

    return <flex className='vertical gap-10 manage-promotions'>
        <FluxCard>
            <flex className='gap-20'>
                <flex className='gap-10'>
                    <Button className='success' title='Add new' onClick={() => addNew()} />
                    <Button className='success hidden' title='Add new campaign' onClick={() => addNewCampaign()} />
                </flex>
                <flex className='gap-10 align-right'>
                    <input placeholder={lang('Search')} onChange={(e) => setFilterWord(e.target.value)} />
                </flex>
                <Button className='success align-right' title={view == Views.Grid ? 'Table View' : 'Grid View'} onClick={() => setView(view == Views.Grid ? Views.Table : Views.Grid)} />
            </flex>
        </FluxCard>
        {
            view == Views.Grid && <FluxCard title='Filter Bonus Types'>
                <flex className='gap-10 wrap'>
                    <Button title={'All'} className={filterType == null ? 'success' : null} onClick={() => setFilterType(null)} />
                    {Object.keys(BonusType).map(x => {
                        if (!promotions.find(y => y.Type == x)) return;
                        return <Button key={x} title={BonusType[x]} className={filterType == x ? 'success' : null} onClick={() => setFilterType(x)} />;
                    })}
                </flex>
            </FluxCard>
        }
        {
            view == Views.Grid && <flex className='vertical gap-20'>
                <div className='tournament-cards'>
                    {filteredPromotions()
                        .sort((a, b) => {
                            if (a.Enabled === true && b.Enabled !== true) return -1;
                            if (a.Enabled !== true && b.Enabled === true) return 1;

                            if (a.Enabled === b.Enabled) {
                                return a.Type > b.Type ? 1 : -1;
                            }

                        })
                        .map(x => {
                            if (filterType != null && x.Type != filterType) return;
                            return <FluxCard
                                title={x.Name}
                                className={`${x.Enabled ? '' : 'not-enabled'}`} buttons={<span className={'align-right small'}>{BonusType[x.Type]}</span>} key={x.Id} footer={
                                    <flex className='gap-5'>
                                        <Button title='Edit' className={'success'} onClick={() => {
                                            selectPromotion(x);
                                        }} />
                                        {!x.Enabled && <div className='disabled'>({lang('Disabled')})</div>}
                                    </flex>}>
                                <span>{x.Description}</span>
                            </FluxCard>;
                        })}
                </div>
            </flex>
        }
        {
            view == Views.Table && <FluxCard
                title={
                    <React.Fragment>
                        <span>{lang('Promotions')}</span>
                        <Button
                            className={'align-right success'}
                            title={'Save Weights'}
                            onClick={() => saveWeight()}
                        ></Button>
                    </React.Fragment>} className={'no-padding'}>
                <Table
                    className='center manage-promotions-table'
                    model={{
                        fields: [
                            { name: 'Weight' },
                            { name: 'Id' },
                            { name: 'Name' },
                            {
                                name: 'Type',
                                render: (value) => {
                                    return BonusType[value];
                                }
                            },
                            { name: 'Description' },
                            {
                                name: 'Enabled',
                                type: InputTypes.Bool
                            }
                        ]
                    }}
                    options={{
                        draggable: {
                            enabled: true
                        }
                    }}
                    onDataChange={(row, index, value) => {
                        value.map((y, index) => {
                            y.Weight = value.length - index;
                        });
                        setPromotions(Object.assign([], value));
                    }}
                    data={filteredPromotions()}
                    buttons={(row) => <Button title='Edit' onClick={() => selectPromotion(row)} />}
                />
            </FluxCard>
        }
    </flex>;
};