import React from 'react';
import PropTypes from 'prop-types';
import { FluxCard } from '../../../components/card/FluxCard';
import { Button } from '../../../components/button/Button';
import { lang } from '../../../lib/Localization/language';
import { completeBonus, giveBonus, openBonusBets, rejectPendingPromotion, removeBonus } from '../../../actions/BonusActions';
import { Popper } from '../../../components/notification/FluxPopper';
import { useSelector } from 'react-redux';
import { currency } from '../../../components/currency/Currency';

export const PlayerBonusInformation = (props) => {
    const user = useSelector((state) => state.user.loginInformation);
    const isAffiliate = user.AffiliateId > 0;

    const player = props.player;


    var perc = 0;
    if (!player.consumedPromotionId && player.playerStats?.pendingBonusId && !isAffiliate) {
        return <FluxCard title={lang('Player has pending promotion')}>
            <flex className='vertical gap-10'>
                <flex className='columns-2'>
                    <flex className='vertical'>
                        <label className='medium bolder'>{lang('Promotion')}</label><span>{player.playerStats?.pendingBonusName}</span>
                    </flex>
                    <flex className='vertical'>
                        <label className='medium bolder'>{lang('Date')}</label>
                        <span>{new Date(player.playerStats?.pendingBonusRequest).toLocaleString('en-Gb', { hours12: false })}</span>
                    </flex>
                </flex>
            </flex>
            <flex className='center gap-10'>
                <Button title='Confirm Bonus' className='success' onClick={() => {
                    giveBonus({
                        id: player.id,
                        selectedPromotion: { Id: player.playerStats?.pendingBonusId },
                    }, (data) => {
                        Popper.pop('information', lang('Bonus assigned to player.', [currency(data.Amount, 2, data.Currency)]));
                        props.onTransactionComplete();
                    });
                }} />
                <Button title='Reject Bonus' className='warning' onClick={() => {
                    rejectPendingPromotion([{ PlayerId: player.id }], () => {
                        Popper.pop('information', 'Player bonus rejected');
                        props.onTransactionComplete();
                    });
                }} />
            </flex>
        </FluxCard>;
    }
    if (!player.consumedPromotionId) {
        return <FluxCard title={lang('Player has no bonus')}>
            {!isAffiliate &&
                <Button title='Give Bonus' className='success center' onClick={() => {
                    giveBonus(player, (data) => {
                        Popper.pop('information', lang('Bonus assigned to player.', [currency(data.Amount, 2, data.Currency)]));
                        props.onTransactionComplete();
                    });
                }} />
            }
        </FluxCard>;
    }

    if (player.promotion.amount == 0) {
        perc = 0;
    } else {
        var turnoverMultiplier = player.promotion.turnoverMultiplier;
        var totalBet = player.promotion.totalBet;
        var max = turnoverMultiplier * player.promotion.amount;
        perc = (100 * totalBet) / max;
        if (totalBet > max) perc = 100;
        perc = parseFloat(perc.toFixed(2));

        if (isNaN(perc)) perc = 0;
    }

    return <FluxCard title={player.activePromotion} footer={
        <flex className='gap-10 center'>
            {perc >= 100 && !isAffiliate && <Button title='Complete Bonus' className={'success'} onClick={() => completeBonus(
                {
                    playerId: player.id,
                    balance: player.balance,
                    bonusBalance: player.bonusBalance,
                    currency: player.currency,
                    consumedPromotionId: player.consumedPromotionId,
                    promotion: player.promotion,
                    bonusWallet: player.promotion.bonusWallet,
                    amount: player.promotion.amount,
                    totalBet: player.promotion.totalBet
                },
                () => props.onTransactionComplete())} />}
            {isAffiliate ? null : <Button title='Remove Bonus' className='warning' onClick={() => removeBonus(player, () => props.onTransactionComplete())} />}
        </flex>
    }>
        <flex className='gap-10 vertical fit fit-children'>
            <flex className='columns-2'>
                <flex className='vertical'>
                    <label className='medium bolder'>{lang('Bonus Amount')}</label>
                    <span>{currency(player.promotion.amount, 2, player.currency)}</span>
                </flex>
                <flex className='vertical'>
                    <label className='medium bolder'>{lang('Progress')}</label>
                    <flex className='gap-10'>
                        <span>%{perc}</span>
                        <div className='progress-bar blue fx-shadow'>
                            <div className='progress' style={{ width: perc > 100 ? 100 : perc + '%' }} />
                        </div>
                    </flex>

                </flex>
            </flex>
            <flex className='columns-2'>
                <flex className='vertical'>
                    <label className='medium bolder'>{lang('Turnover Multiplier')}</label><span>{player.promotion.turnoverMultiplier}</span>
                </flex>
                <flex className='vertical'>
                    <label className='medium bolder'>{lang('Deduct Bonus')}</label>
                    <span>{player.promotion.deductBonusAmount ? lang('Yes') : 'No'}</span>
                </flex>
            </flex>
            <flex className='columns-2 wrap'>
                <flex className='vertical'>
                    <label className='medium bolder'>{lang('Total Sport Bets')}</label><span><a href='#' onClick={() => openBonusBets(player.consumedPromotionId)}>{player.promotion.sportBets}</a></span>
                </flex>
                <flex className='vertical'>
                    <label className='medium bolder'>{lang('Total Casino Hands')}</label><span>{player.promotion.hands}</span>
                </flex>
            </flex>
            <flex className='columns-2 wrap'>
                <flex className='vertical'>
                    <label className='medium bolder'>{lang('Total Stake')}</label><span>{currency(player.promotion.totalBet, 2, player.currency)}</span>
                </flex>
                <flex className='vertical'>
                    <label className='medium bolder'>{lang('Total Win')}</label><span>{currency(player.promotion.totalWin, 2, player.currency)}</span>
                </flex>
            </flex>
        </flex>
    </FluxCard>;
};

PlayerBonusInformation.propTypes = {
    player: PropTypes.object,
    onTransactionComplete: PropTypes.func
};
