import React, { useEffect, useState } from 'react';
import { lang } from '../../../../lib/Localization/language';
import { Button } from '../../../../components/button/Button';

import { FluxCard } from '../../../../components/card/FluxCard';
import { FluxForm } from '../../../../components/form/FluxForm';
import { InputTypes } from '../../../../components/form/InputTypes';


const model = {

    fields: [
        {
            name: 'automaticPayout',
            title: 'Automatic Payout',
            type: InputTypes.Bool,
            description: (data) => {
                switch (data?.automaticPayout) {
                    case true:
                        return <span>{lang('Player will be paid automatically after the bonus is completed.')}</span>;
                    default:
                        return <span>{lang('Payments will be audited by admins!')}</span>
                }
            }
        },
        {
            name: 'payoutBonusAmount',
            title: 'Payout all winnings',
            type: InputTypes.Bool,
            description: (data) => {
                switch (data?.payoutBonusAmount) {
                    case false:
                        return <span>{lang('The amount of payment will be processed using the bonus amount given at the start of bonus.')}</span>;
                    default:
                        return <span>{lang('The payout will be processed using the amount of actual balance.')}</span>
                }
            }
        }
    ]
};




const CurrencyRow = (props) => {
    const { currency, data, updatePayoutAmount, deleteCurrencySetting } = { ...props };
    const [amount, setAmount] = useState((data && data.amount[currency.CurrencyCode] && data.amount[currency.CurrencyCode][0] ? data.amount[currency.CurrencyCode][0].Amount : 0) ?? 0);


    const update = (e) => {
        updatePayoutAmount(currency, e);
        setAmount(e.target.value);
    };

    useEffect(() => {

    }, [amount]);

    return <flex className='gap-20 border radius fx-shaddow columns-3 fit-children' key={currency}>
        <flex className='vertical padding'>
            <label className='medium'>{currency.CurrencyCode}</label>
            <span>{currency.Name}</span>
        </flex>
        <flex className='padding fx-border-left fx-border-right justify-content-center'>
            <input name={currency.CurrencyCode} defaultValue={data.amount[currency.CurrencyCode]}
                onFocus={(e) => {
                    e.target.value = '';
                }}
                onBlur={(e) => {
                    if (e.target.value === '') e.target.value = amount;
                }}
                value={amount} className='center' onChange={(e) => {
                    update(e);
                }} />
        </flex>
        <flex className='padding align-right justify-content-center'>
            <Button title='Delete' className={'warning'} onClick={() => {
                setAmount(0);
                deleteCurrencySetting(currency.CurrencyCode);
            }} />
        </flex>
    </flex>;
};


export const PayoutSettings = (props) => {
    let { promotion, currencies, onChange } = { ...props };
    const [data, setData] = useState(promotion.Configuration.payout ?? { amount: {}, automaticPayout: false });

    useEffect(() => {
        if (!data) return;
        promotion.Configuration.payout = data;
        onChange({ ...promotion });
    }, [data]);

    const updatePayoutAmount = (currency, e) => {
        var copy = { ...data };
        var value = parseFloat(e.target.value);
        if (isNaN(value)) {
            value = 0;
        }
        copy.amount[currency.CurrencyCode] = [
            {
                Amount: value
            }
        ];
        setData(copy);
    };

    const deleteCurrencySetting = (target) => {
        var copy = { ...data };
        delete copy.amount[target];
        setData(copy);
    };

    return <FluxCard>
        <flex className='vertical gap-10'>
            {
                (promotion.Type != 10 && promotion.Type !== 7 && promotion.Type !== 11) && <FluxForm data={data} model={model} buttons={null} onChange={(d) => {
                    setData(d);
                }} />
            }
            <flex className='vertical gap-5 padding'>
                <label className='medium'>{lang('Maximum Payout')}</label>
                <flex className='vertical gap-10'>
                    {currencies.map(x => {
                        return <CurrencyRow currency={x} updatePayoutAmount={updatePayoutAmount} deleteCurrencySetting={deleteCurrencySetting} data={data} />
                    })}
                </flex>
            </flex>
        </flex>
    </FluxCard>;
};