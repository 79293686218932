/* eslint-disable react/display-name */
import React from 'react';
import { ReportEndpoints } from '../../../../v2/Lib/Api/Api';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { SearchModelHorseBettingReport, ResultModelHorseBettingReport } from '../../../lib/Models/HorseModels';

const Model = () => {
    var fields = Object.assign([], SearchModelHorseBettingReport.fields);
    fields.splice(2, 0, {
        name: 'Venues',
        title: 'Venue',
    });
    return {
        fields: fields
    };
};

const ResultModel = () => {
    var fields = Object.assign([], ResultModelHorseBettingReport.fields);
    fields.splice(2, 0, {
        name: 'Venues',
        title: 'Venue',
    });
    return {
        fields: fields
    };
};

export const ReportVenuesHorseGreyhound = () => {
    return <FluxSearch
        model={Model()}
        endpoint={ReportEndpoints.HorseGreyHoundVenuesReport}
        resultModel={ResultModel()}
    />;
};
