import React from 'react';
import PropTypes from 'prop-types';

import { TableColumn } from './TableRow';

export const TableFooter = (props) => {

    const footer = props.model.fields.map((field, index) => {
        if (field.hidden) return <></>;
        if (!props.data[field.name]) return <td key={index} className={`flux-table-body-column column-${field.name.toLowerCase()}`}></td>;
        return <TableColumn key={index} field={field} row={props.data} footer={true} fieldLength={props.model.fields?.length} />;
    });

    return (
        <tr className='flux-table-body-row flux-table-footer'>
            {props.options?.draggable?.enabled && (
                <td className='table-option-draggable-header'></td>
            )}
            {props.options?.selectable && (
                <td className='table-option-selectable-header'></td>
            )}
            {footer}
            {props.options?.data && props.options.data.delete.enabled && (
                <td className='table-option-delete-header'></td>
            )}
            {props.buttons && (
                <td className='table-option-buttons-header'></td>
            )}
        </tr>
    );
};

TableFooter.propTypes = {
    model: PropTypes.object,
    data: PropTypes.object,
    options: PropTypes.object,

    // rendering
    buttons: PropTypes.func
};