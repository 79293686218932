/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../../icon/Icon';
import { Table } from '../../table/Table';



export const ManageFilters = (props) => {
    const [fields, setFields] = useState(null);
    useEffect(() => {
        setFields(props.fields);
    }, []);

    useEffect(() => {
        if (!fields) return;
        if (!props.onUpdate) return;
        props.onUpdate(fields);
    }, [fields]);

    if (!fields) return <></>;

    return <Table
        key={fields}
        className='sticky fx-borders fx-shadow'
        model={{
            fields: [
                {
                    name: 'name',
                    title: 'Name'
                },
                {
                    name: 'enabled',
                    title: 'Enabled',
                    render: (value, row) => {
                        return <div className='padding' onClick={() => {
                            row.enabled = !value;
                            var copy = [...fields];
                            setFields(copy);
                        }} >
                            <Icon icon={value ? 'square-check' : 'square'} type='fa-regular' size='1x' />
                        </div>;
                    }
                }
            ]
        }} data={fields} />;
};

ManageFilters.propTypes = {
    fields: PropTypes.array,
    onUpdate: PropTypes.func
};
