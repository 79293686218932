import React from 'react';
import { FluxCard } from '../../../../components/card/FluxCard';
import { lang } from '../../../../lib/Localization/language';
import { Currency } from '../../../../components/currency/Currency';

export const PlayerCasinoBetInformation = (props) => {
    const { player, stats } = { ...props };


    return <FluxCard title={'Casino'} buttons={<flex className='align-right gap-5'><span className='bold'>{lang('Total Hands')}</span><span>{stats?.totalCasinoHands}</span></flex>}>
        <flex className='gap-10 columns-2 fit'>
            <span className='medium bold'>{lang('Overall Bet')}</span>
            <Currency amount={Math.abs(stats?.totalCasinoBet)} sign={player.currency} />
        </flex>
        <flex className='gap-10 columns-2 fit'>
            <span className='medium bold'>{lang('Overall Win')}</span>
            <Currency amount={stats?.totalCasinoWin} sign={player.currency} />
        </flex>
        <flex className='gap-10 columns-2 fit'>
            <span className='medium bold'>{lang('Profit')}</span>
            <Currency amount={Math.abs(stats?.totalCasinoBet ?? 0) - stats?.totalCasinoWin ?? 0} sign={player.currency} />
        </flex>
    </FluxCard>;
};
