import './_kpi-card.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FluxCard } from '../../../../components/card/FluxCard';
import { Icon } from '../../../../components/icon/Icon';
import { KPIRanges } from '../KPISelector/KPISelector';
import { lang } from '../../../../lib/Localization/language';
import { Currency } from '../../../../components/currency/Currency';
import { API } from '../../../../lib/API/Api';
import { useSelector } from 'react-redux';

export const KPICard = (props) => {
    const user = useSelector((state) => state.user.loginInformation);

    const [data, setData] = useState(null);
    const [icon, setIcon] = useState(null);

    const [startTitle, setStartTitle] = useState(null);
    const [endTitle, setEndTitle] = useState(null);

    useEffect(() => {
        if (!props.endpoint) return;
        API.post(props.endpoint, {
            rangeType: props.range,
            selectedCurrency: props.selectedCurrency,
            convertCurrency: props.convertCurrency,
            dateStart: null,
            dateEnd: null,
            timeZoneOffset: window.user.timeZoneConverted * 60,
        }, null, null, props.name).then((result) => {
            setData(result.result);
        });
    }, [props.range, props.selectedCurrency, props.convertCurrency]);

    useEffect(() => {
        if (!data) return;
        switch (data.change) {
            case 'down':
                if (data.trend == 0) {
                    setIcon(null);
                } else {
                    setIcon('circle-down');
                }
                break;
            case 'up':
                setIcon('circle-up');
                break;
        }


        switch (props.range) {
            case KPIRanges.Today:
                setStartTitle(lang('Today'));
                setEndTitle('Yesterday');
                break;
            case KPIRanges.WTD:
                setStartTitle(lang('This Week'));
                setEndTitle('Last Week');
                break;
            case KPIRanges.MTD:
                setStartTitle(lang('This Month'));
                setEndTitle('Last Month');
                break;
        }
    }, [data]);


    const renderData = (index) => {
        var title = null;
        switch (index) {
            case 0:
                title = endTitle;
                break;
            case 1:
                title = startTitle;
                break;
        }

        if (!title) return;
        return <flex className='vertical gap-5'>
            <label className='medium bold'>{lang(title)}</label>
            <flex className={`gap-5 ${props.indicators.length > 1 ? 'columns-2 wrap' : 'fit-children'}`}>
                {props.indicators.map((x, i) => {
                    return <flex className='gap-5' key={x}>
                        <label>{lang(x)}</label>
                        <span className='align-right'>{parseValue(data.details[index][i], i)}</span>
                    </flex>;
                })}
            </flex>
        </flex>;
    };

    const parseValue = (value, index) => {
        if (!props.indicatorTypes) return value;
        switch (props.indicatorTypes[index]) {
            case 'currency':
                return <Currency amount={value} sign={user.features.currency} showSign={false} />;
        }

        return value;
    };

    return <FluxCard {...props} title={<React.Fragment>
        {props.icon}
        <span>{lang(props.title)}</span>
        {
            data?.trend !== undefined && <flex className='gap-5 align-right percentage'>
                <label>%{data?.trend.toFixed(2)}</label>
                <Icon icon={icon} />
            </flex>
        }
    </React.Fragment>} className={`kpi-card ${data?.change == 'down' ? 'kpi-down' : 'kpi-up'}`}>
        <flex className='vertical gap-20'>
            {renderData(1)}
            <div className='border-top'></div>
            {renderData(0)}
        </flex>
    </FluxCard>;
};

KPICard.propTypes = {
    name: PropTypes.string,
    icon: PropTypes.node,
    title: PropTypes.string,
    range: PropTypes.number,
    endpoint: PropTypes.string,
    convertCurrency: PropTypes.string,
    selectedCurrency: PropTypes.string,
    indicators: PropTypes.array,
    indicatorTypes: PropTypes.array,
};