import React from 'react';
import { FluxTab } from '../../../components/tab/FluxTab';
import { GeneralSettings } from './Components/GeneralSettings';

export const CashBonus = (props) => {
    var buttons = [
        {
            title: 'General Settings'
        }
    ];

    return <FluxTab buttons={buttons} className='gap-10 fx-shadow fx-radius rounded-buttons'>
        <GeneralSettings {...props} />
    </FluxTab>;
};