import { Endpoints } from '../../../../components/Application/Application';
import { API } from '../../../../v2/Lib/Api/Api';

export const logout = () => {
    return API.post(Endpoints.Logout, {}, 'sign off', '').then(() => {
        document.cookie = 'backend-portal-auth=;expires=Fri, 31 Dec 2000 23:59:59 GMT;path=/';
        document.location.href = '/';
    }).catch(() => {
        document.cookie = 'backend-portal-auth=;expires=Fri, 31 Dec 2000 23:59:59 GMT;path=/';
        document.location.href = '/';
    });
};
