/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';

import { FluxSearch } from '../../../components/search/FluxSearch';
import { InputTypes } from '../../../components/form/InputTypes';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { HistoryEndpoints } from '../../../../v2/Lib/Api/Api';
import { openPlayerHandDetails } from '../../../actions/PlayerActions';
import { ProviderGameTypes } from '../../../lib/Enums/GameEnums';
import { ActionTypes } from '../../../lib/ActionManager/FluxActions';
import { footerCurrencyValues } from '../../../lib/Helpers/Reports/Footer';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { lang } from '../../../lib/Localization/language';
import { Icon } from '../../../components/icon/Icon';
import { currency } from '../../../components/currency/Currency';
import { GameTransactionTypes } from '../../../lib/Enums/TransactionEnums';


const model = {
    fields: [
        {
            name: 'OperatorId',
            title: 'Operator',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'operators',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'PlayerId',
            title: 'Player Id'
        },
        {
            name: 'EventTime',
            title: 'Date',
            type: InputTypes.DateTime,
            between: true,
            value: {
                start: DateHelpers.yesterday(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'SessionId',
            title: 'Session Id'
        },
        {
            name: 'RoundId',
            title: 'Round Id'
        },
        {
            name: 'ProviderId',
            title: 'Provider',
            type: InputTypes.Select,
            nullable: true,
            multiple: true,
            //value: [191341, 191327],
            data: {
                source: 'providers/gaming',
                key: 'id',
                title: ['display']
            }
        },
        {
            name: 'Closed',
            title: 'Status',
            type: InputTypes.Select,
            nullable: true,
            values: {
                0: 'Open',
                1: 'Closed'
            }
        }
    ]
};

const resultPlayerGameHistory = {
    fields: [
        {
            name: 'id',
            title: 'Id'
        },
        {
            name: 'eventTime',
            title: 'Time',
            type: InputTypes.DateTime
        },
        {
            name: 'username',
            title: 'Username',
            render: (val, row) => {
                return <NavLink to={`/players/search?&id=${row.playerId}`}>{val}</NavLink>;
            }
        },
        {
            name: 'playerGroup',
            title: 'Group'
        },
        {
            name: 'sessionId',
            title: 'Session Id'
        },
        {
            name: 'provider',
            title: 'Provider'
        },
        {
            name: 'gameType',
            title: 'Game Type',
            type: InputTypes.Select,
            values: ProviderGameTypes
        },
        {
            name: 'gameName',
            title: 'Game'
        },
        {
            name: 'roundId',
            title: 'Round'
        },
        {
            name: 'type',
            title: 'Type',
            render: (val) => {
                return GameTransactionTypes[val];
            },
        },
        {
            name: 'amount',
            title: 'Amount',
            type: InputTypes.Number,
            render: (val, row) => {
                return currency(val, 2, row.currency);
            },
            renderFooter: (val) => {
                return <flex className='vertical'>
                    {
                        Object.keys(val).map(x => {
                            return <flex className='gap-5 fit' key={x}>
                                <label>{lang(x)}</label>
                                <span className='align-right'>{currency(val[x], 2, '', false)}</span>
                            </flex>;
                        })
                    }
                </flex>;
            },
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'closed',
            title: 'Closed',
            render: (val) => {
                if (val) return lang('Yes');
                return lang('No');
            }
        },  
        {
            name: 'bonus',
            title: 'Bonus',
            render: (val) => {
                if (val) return lang('Yes');
                return lang('No');
            }
        },
        {
            name: 'balanceAfter',
            title: 'Balance',
            type: InputTypes.Number,
            render: (val, row) => {
                return currency(val, 2, row.currency);
            },
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        }

    ],
    onRenderRow: (rowObject) => {
        if (rowObject.data.win > Math.abs(rowObject.data.bet)) {
            rowObject.className = 'player-win';
        } else {
            if (rowObject.data.Type == 1) {
                rowObject.className = 'player-lost';
            }
        }
        return rowObject;
    }
};




export const SearchPlayerGameTransactions = (props) => {
    const user = useSelector((state) => state.user.loginInformation);
    const title = () => {
        if (props.retail) {
            return <React.Fragment><span>{lang('Retail')}</span><Icon icon='chevron-right' type='fa-light' /><span>{props.retail.Name}</span><Icon icon='chevron-right' type='fa-light' /><span>{lang('Search in Player Game History')}</span></React.Fragment>;
        }
        if (props.affiliate) {
            return <React.Fragment><span>{lang('Affiliate')}</span><Icon icon='chevron-right' type='fa-light' /><span>{props.affiliate.Name}</span><Icon icon='chevron-right' type='fa-light' /><span>{lang('Search in Player Game History')}</span></React.Fragment>;
        }
        return 'Search in Player Game History';
    };

    return <FluxSearch
        title={title()}
        model={model}
        resultModel={resultPlayerGameHistory}
        actions={ActionTypes.PlayerGameHistory}
        endpoint={HistoryEndpoints.PlayerGameTransactionsHistory}
        onActionClicked={(action, selected) => {
            switch (action.type) {
                case ActionTypes.GameHandDetails:
                    openPlayerHandDetails(selected.id);
                    break;
            }
        }}
        onPrepareModel={(model) => {
            var copy = { ...model };
            copy.name = 'player-game-history';

            if (props.sessionId) {
                copy.name = 'player-game-history-session-id';
                copy.fields.find(x => x.name == 'SessionId').value = props.sessionId;
            }
            if (props.affiliate) {
                copy.name = 'player-game-history-affiliate';
                copy.fields = copy.fields.filter(x => x.name != 'OperatorId');
                copy.fields.push({ name: 'AffiliateId', value: props.affiliate.Id, exact: true, and: true, hidden: true });
                return copy;
            }

            if (props.retail) {
                copy.name = 'player-game-history-retail';
                copy.fields = copy.fields.filter(x => x.name != 'OperatorId');
                copy.fields = copy.fields.filter(x => x.name != 'RetailId');
                copy.fields.push({ name: 'RetailId', value: props.retail.Id, exact: true, and: true, hidden: true });
                return copy;
            }

            if (props.playerId) {
                copy.name = 'player-game-history-player-id';
                var operatorField = copy.fields.find(x => x.name == 'OperatorId');
                operatorField.hidden = true;

                var playerField = copy.fields.find(x => x.name == 'PlayerId');
                playerField.hidden = true;
                playerField.value = props.playerId;
            } else {
                copy.name = 'player-game-history-operator';
                copy.fields.find(x => x.name == 'OperatorId').nullable = false;
                copy.fields.find(x => x.name == 'OperatorId').value = user.UserPrivileges.PointOfView;
                if (user.UserPrivileges.AvailableOperators.length == 1) copy.fields.find(x => x.name == 'OperatorId').hidden = true;
            }
            return copy;
        }}
        onPrepareResultModel={(model) => {
            var copy = { ...model };
            if (props.affiliate) {
                return copy;
            }

            if (props.retail) {
                copy.fields = copy.fields.filter(x => x.name != 'operator');
                copy.fields = copy.fields.filter(x => x.name != 'retail');
                return copy;
            }
            if (props.playerId) {
                copy.fields = copy.fields.filter(x => x.name != 'group');
                copy.fields = copy.fields.filter(x => x.name != 'playerGroup');
                copy.fields = copy.fields.filter(x => x.name != 'username');
                copy.fields = copy.fields.filter(x => x.name != 'playerId');
            }

            return copy;
        }}
        {...props}
    />;
};


SearchPlayerGameTransactions.propTypes = {
    playerId: PropTypes.number,
    sessionId: PropTypes.number,
    affiliate: PropTypes.object,
    retail: PropTypes.object
};