import React from 'react';
import { FluxCard } from '../../../../components/card/FluxCard';
import { lang } from '../../../../lib/Localization/language';
import { Currency } from '../../../../components/currency/Currency';

export const PlayerTransactionsMainWalletInformation = (props) => {
    const { player } = { ...props };

    return <FluxCard title={'Transactions / Main Wallet'}>
        <flex className='gap-10 fit'>
            <flex className='gap-10 columns-2 fit'>
                <span className='medium bold'>{lang('Deposit')}</span>
                <Currency amount={player?.totalDeposit} sign={player.currency} />
            </flex>
            <flex className='gap-10 columns-2 fit col-med'>
                <span className='medium bold'>{lang('Total')}</span>
                <span>{player?.depositCount}</span>
            </flex>
        </flex>
        <flex className='gap-10 fit'>
            <flex className='gap-10 columns-2 fit'>
                <span className='medium bold'>{lang('Withdraw')}</span>
                <Currency amount={player?.totalWithdraw} sign={player.currency} />
            </flex>
            <flex className='gap-10 columns-2 fit col-med'>
                <span className='medium bold'>{lang('Total')}</span>
                <span>{player?.withdrawCount}</span>
            </flex>
        </flex>
    </FluxCard>;
};
