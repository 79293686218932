/* eslint-disable react/display-name */
import React from 'react';

import { FluxSearch } from '../../../components/search/FluxSearch';
import { InputTypes } from '../../../components/form/InputTypes';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { TicketEndpoints } from '../../../lib/API/Endpoints';
import { gotoProfile } from '../../../lib/Helpers/NavigationHelper';
import { Currency } from '../../../components/currency/Currency';
import { TicketId } from '../../../lib/Models/TicketModels';
import { SportsTicketStatus } from '../../../lib/Enums/BettingEnums';



const model = {
    fields: [
        {
            name: 'EntryDate',
            title: 'Date',
            type: InputTypes.DateTime,
            between: true,
            value: {
                start: DateHelpers.yesterday(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'Group',
            title: 'Player Group',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'myPlayerGroups',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'PlayerId',
            title: 'Player Id',
            type: InputTypes.Number
        },
        {
            name: 'Type',
            title: 'Type',
            type: InputTypes.Select,
            nullable: true,
            values: {
                0: 'Prematch',
                1: 'Live',
                2: 'Prematch & Live'
            }
        },
        {
            name: 'TicketType',
            title: 'Ticket Type',
            type: InputTypes.Select,
            nullable: true,
            values: {
                0: 'Single',
                1: 'Combined'
            }
        },
        {
            name: 'Username',
            exact: false
        }
    ]
};




export const ResultModel = {
    fields: [
        {
            name: 'entryDate',
            title: 'Date',
            type: InputTypes.DateTime
        },
        {
            name: 'externalTicketId',
            title: 'Ticket Id',
            render: (val) => {
                return <TicketId id={val} onChange={() => {

                }} />;
            }
        },
        {
            name: 'playerId',
            title: 'Player Id',
            render: (val, row) => {
                return <a style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
                    gotoProfile(row.playerId);
                }}>{val}</a>;
            }
        },
        {
            name: 'username',
            title: 'Player',
            render: (val, row) => {
                return <a style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
                    gotoProfile(row.playerId);
                }}>{val}</a>;
            }
        },
        {
            name: 'currency',
        },
        {
            name: 'bet',
            title: 'Stake',
            type: InputTypes.Number,
            render: (val, row) => <Currency amount={val} sign={row.currency} />
        },
        {
            name: 'odds',
            title: 'Odds',
            type: InputTypes.Number
        },
        {
            name: 'win',
            title: 'Win',
            type: InputTypes.Number,
            render: (val, row) => <Currency amount={val} sign={row.currency} />
        },
        {
            name: 'oldStatus',
            title: 'Old Status',
            type: InputTypes.Select,
            values: SportsTicketStatus
        },
        {
            name: '#',
            render: () => <span>➜</span>
        },
        {
            name: 'lastResettleStatus',
            title: 'Last Resettle Status',
            type: InputTypes.Select,
            values: SportsTicketStatus
        }
    ]
};



export const SearchSportSettlementReport = (props) => {

    const title = () => {
        return 'Resettlement Reports';
    };


    return <FluxSearch
        {...props}
        title={title()}
        model={model}
        resultModel={ResultModel}
        endpoint={TicketEndpoints.SportResettlementReports}
        searchOnStart
        onPrepareModel={(model) => {
            var copy = { ...model };
            copy.name = 'resettlement-reports';
            return copy;
        }}
        onPrepareResultModel={(copy) => {
            copy.name = 'resettlement-reports';
            return copy;
        }}
    />;
};


