/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { Modal } from '../../../../components/application/FluxApp';
import { Button } from '../../../../components/button/Button';
import { ActionButton, FluxCard } from '../../../../components/card/FluxCard';
import { SelectGameProviders } from '../../../../components/dataControls/SelectGameProviders';
import { SelectGames } from '../../../../components/dataControls/SelectGames';
import { FluxForm } from '../../../../components/form/FluxForm';
import { InputTypes } from '../../../../components/form/InputTypes';
import { FluxList } from '../../../../components/list/FluxList';
import { FluxModal } from '../../../../components/modal/FluxModal';
import { Table } from '../../../../components/table/Table';
import { ProviderGameTypes } from '../../../../lib/Enums/GameEnums';






export const CasinoSettings = (props) => {
    let { promotion, currencies, slotsEnabled, liveCasinoDisabled, onChange, disabled, disabledReason } = { ...props };
    const [data, setData] = useState(promotion.Configuration.games ?? { rng: false, live: false, gameTypes: {}, providers: {}, disabledGames: {} });


    useEffect(() => {
        var copy = { ...data };

        if (!copy.gameTypes) {
            copy.gameTypes = {};
        }

        if (!copy.providers) {
            copy.providers = {};
        }

        if (!copy.disabledGames) {
            copy.disabledGames = {};
        }

        if (slotsEnabled) {
            copy.rng = true;
        }

        if (liveCasinoDisabled) {
            copy.casino = false;
        }

        setData(copy);

    }, []);

    useEffect(() => {
        if (!data) return;
        promotion.Configuration.games = data;
        onChange({ ...promotion });
    }, [data]);



    const addProvider = () => {
        var provider = null;
        Modal.open(<FluxModal title='Select Provider' footer={<flex className='gap-10 center'>
            <Button className='success' title='Add' onClick={() => {
                Modal.close();
                var copy = { ...data };
                copy.providers[provider.id] = {
                    id: provider.id,
                    name: provider.name
                };
                setData(copy);
            }} />
            <Button title='Cancel' onClick={() => Modal.close()} />
        </flex>}>
            <SelectGameProviders filter={(x) => {
                if (data.providers[x.id]) {
                    return false;
                }
                return true;
            }} onChange={(data) => provider = data} />
        </FluxModal>);
    };


    const removeProvider = (provider) => {
        var copy = { ...data };
        delete copy.providers[provider.id];
        setData(copy);
    };


    const addGameType = () => {
        var types = [];
        Object.keys(ProviderGameTypes).map(x => {
            if (!data?.gameTypes[x]) {
                types.push({ title: ProviderGameTypes[x], value: x });
            }
        });

        var type = null;
        Modal.open(<FluxModal title='Select Type' footer={<flex className='gap-10 center'>
            <Button className='success' title='Add' onClick={() => {
                Modal.close();
                var copy = { ...data };
                copy.gameTypes[type.value] = {
                    type: type.value
                }
                setData(copy);
            }} />
            <Button title='Cancel' onClick={() => Modal.close()} />
        </flex>}>
            <FluxList type='select' data={types} onSelected={(x) => type = x} />
        </FluxModal>);
    };


    const removeType = (type) => {
        var copy = { ...data };
        delete copy.gameTypes[type];
        setData(copy);
    };


    const addDisabledGame = () => {
        var selectedItems = null;
        var selectedGames = data.disabledGames;
        Modal.open(<FluxModal title='Select Provider' footer={<flex className='gap-10 center'>
            <Button className='success' title={'Save'} onClick={() => {
                Modal.close();
                var copy = { ...data };
                if (Object.values(selectedItems).length == 0) {
                    copy.disabledGames = {};
                } else {
                    Object.values(selectedItems).map(x => {
                        copy.disabledGames[x.id] = {
                            name: x.name,
                            id: x.id,
                            providerId: x.providerId,
                            providerName: x.providerName
                        };
                    });
                }
                setData(copy);
            }} />
            <Button title='Cancel' onClick={() => Modal.close()} />
        </flex>}>
            <SelectGames selectedGames={selectedGames} onChange={(selected) => {
                selectedItems = selected;
            }} />
        </FluxModal>);
    };

    const removeDisabledGame = (id) => {
        var copy = { ...data };
        delete copy.disabledGames[id];
        setData(copy);
    };


    if (disabled) {
        return <flex className='vertical gap-10'>
            {disabledReason}
        </flex>;
    }

    return <flex className='vertical gap-10'>
        <FluxCard>
            <FluxForm data={data} model={{
                fields: [
                    {
                        name: 'rng',
                        title: 'Slot Games',
                        type: InputTypes.Bool,
                        readonly: slotsEnabled
                    },
                    {
                        name: 'live',
                        title: 'Live Casino Games',
                        type: InputTypes.Bool,
                        readonly: liveCasinoDisabled
                    }
                ]
            }} columns={2} buttons={null} onChange={(d) => setData(d)} />
        </FluxCard>
        <FluxCard title={'Game Types'} buttons={<ActionButton title='Add' icon='plus-square' onClick={() => addGameType()} />}>
            <Table className='fx-borders fx-shadow' model={{
                fields: [
                    {
                        name: 'type',
                        title: 'Type',
                        render: (val) => ProviderGameTypes[val]
                    }
                ]
            }} data={data?.gameTypes ? Object.values(data.gameTypes) : []} buttons={(row) => {
                return <Button title='Remove' className={'warning'} onClick={() => removeType(row.type)} />;
            }} />
        </FluxCard>
        <FluxCard title='Providers' buttons={<ActionButton title='Add' icon='plus-square' onClick={() => addProvider()} />}>
            <Table className='fx-borders fx-shadow' model={{
                fields: [
                    {
                        name: 'id',
                        title: 'Id'
                    },
                    {
                        name: 'name',
                        title: 'Name',
                    }
                ]
            }} data={data?.providers ? Object.values(data.providers) : []} buttons={(row) => {
                return <Button title='Remove' className={'warning'} onClick={() => removeProvider(row)} />;
            }} />
        </FluxCard>
        <FluxCard title='Disabled Games' buttons={<ActionButton title='Add' icon='plus-square' onClick={() => addDisabledGame()} />}>
            <Table className='fx-borders fx-shadow' model={{
                fields: [
                    {
                        name: 'id',
                        title: 'Id'
                    },
                    {
                        name: 'providerName',
                        title: 'Provider'
                    },
                    {
                        name: 'name',
                        title: 'Game'
                    }
                ]
            }} data={data?.disabledGames ? Object.values(data.disabledGames) : []} buttons={(row) => {
                return <Button title='Remove' className={'warning'} onClick={() => removeDisabledGame(row)} />;
            }} />
        </FluxCard>
    </flex>;
};