import React from 'react';
import { lang } from '../lib/Localization/language';
import { Modal, Overlay } from '../components/application/FluxApp';
import { Button } from '../components/button/Button';
import { FluxForm } from '../components/form/FluxForm';
import { ConfirmationModal } from '../components/modal/ConfirmationModal/ConfirmationModal';
import { ErrorModal } from '../components/modal/ErrorModal/ErrorModal';
import { FluxModal } from '../components/modal/FluxModal';
import { FluxNotice } from '../components/notification/FluxNotice';
import { Popper } from '../components/notification/FluxPopper';
import { HorseTicket } from '../components/sports/Ticket/HorseTicket';
import { SportTicket } from '../components/sports/Ticket/SportTicket';
import { API } from '../lib/API/Api';
import { TicketEndpoints } from '../lib/API/Endpoints';




export const openSportTicketDetails = (id, system = false, onChange = null) => {
    API.post(`${TicketEndpoints.TicketDetails}/${id}/${system}`, null, 'Loading ticket please wait', 'Unable to load ticket.').then((result) => {
        var events = {};
        result.result.details.map(x => {
            var selections = JSON.parse(x.bets);
            selections.map(y => events[y.EventExternalId] = true);
        });

        var complete = (scores) => {
            Modal.open(<FluxModal title={lang('Ticket #%1', [id])} className='modal-large' footer={<Button title='Close' onClick={() => Modal.close()} />}>
                <SportTicket ticket={result.result.details[0]} history={result.result.history} selections={result.result.selections} scores={scores} onChange={onChange} />
            </FluxModal>);
        };
        API.post(TicketEndpoints.SportEventResults, Object.keys(events)).then((scores) => {
            complete(scores.result);
        }).catch(() => complete());
    });
};



export const openHorseTicketDetails = (id, system = false, onChange = null) => {


    API.post(`${TicketEndpoints.HorseTicket}/${id}/${system}`, null, 'Loading ticket please wait', 'Unable to load ticket.').then((result) => {
        var eventIds = [];
        var selections = [];
        result.result.details.map(x => {
            var bets = JSON.parse(x.bets);
            var events = bets.Summary.split('~');
            selections = selections.concat(bets.Selection);
            events.map(x => {
                var data = x.split('|');
                eventIds.push(data[0]);
            });
        });



        var complete = (events) => {
            Modal.open(<FluxModal title={lang('Ticket #%1', [id])} className='modal-large' footer={<Button title='Close' onClick={() => Modal.close()} />}>
                <HorseTicket ticket={result.result.details[0]} history={result.result.history} selections={selections} events={events} onChange={onChange} />
            </FluxModal>);
        };
        API.post(`${TicketEndpoints.HorseTicketDetails}/0`, eventIds).then((events) => {
            complete(events?.result);
        }).catch(() => complete());
    });
};


export const ManualWin = (ticket, onComplete) => {
    var data = null;
    var bets = JSON.parse(ticket.bets);
    bets.map(x => {
        if (x.Status == 0) {
            x.Status = 4;
        }
    });
    Overlay.open(<ConfirmationModal
        onCancel={() => Overlay.close()}
        onConfirm={() => {
            var copy = {
                ExternalTicketId: ticket.externalTicketId,
                Tax: ticket.tax,
                Status: 1,
                Bets: bets,
                Win: data.Amount
            };
            API.post(TicketEndpoints.ManualTicketUpdate, copy).then((result) => {
                if (result.result) {
                    var newTicket = { ...ticket };
                    newTicket.status = 1;
                    newTicket.bets = bets;
                    newTicket.amount = data.Amount;
                    onComplete(newTicket);
                    Popper.pop('information', 'Ticket Updated');
                } else {
                    Overlay.open(<ErrorModal onClose={() => Overlay.close()} title='Unable to set ticket status to win!' >
                        <FluxNotice type='error' title={result?.error?.message ?? 'Unknown error!'} />
                    </ErrorModal>);
                }
            }).catch((err) => {
                Overlay.open(<ErrorModal onClose={() => Overlay.close()} title='Unable to set ticket status to win!' >
                    <FluxNotice type='error' title={err.error?.message ?? 'Service not available, please try later'} />
                </ErrorModal>);
            });
        }}
        title='Confirm' confirmTitle='Update'>
        <FluxNotice type='warning' title='Confirm ticket win' description='Ticket will be set as win and all selections will be updated' />
        <FluxForm buttons={null} model={{
            fields: [
                {
                    name: 'Amount',
                    title: 'Amount',
                    value: ticket.possibleWin
                }
            ]
        }} onChange={(d) => data = d} />
    </ConfirmationModal>);
};



export const ManualLost = (ticket, onComplete) => {
    var bets = JSON.parse(ticket.bets);
    bets.map(x => {
        if (x.Status == 0) {
            x.Status = 3;
        }
    });
    Overlay.open(<ConfirmationModal
        onCancel={() => Overlay.close()}
        onConfirm={() => {
            var copy = {
                ExternalTicketId: ticket.externalTicketId,
                Tax: ticket.tax,
                Status: 2,
                Bets: bets,
                Win: 0
            };
            API.post(TicketEndpoints.ManualTicketUpdate, copy).then((result) => {
                if (result.result) {
                    var newTicket = { ...ticket };
                    newTicket.status = 2;
                    newTicket.bets = bets;
                    newTicket.amount = 0;
                    onComplete(newTicket);
                    Popper.pop('information', 'Ticket Updated');
                } else {
                    Overlay.open(<ErrorModal onClose={() => Overlay.close()} title='Unable to set ticket status to lost!' >
                        <FluxNotice type='error' title={result?.error?.message ?? 'Unknown error!'} />
                    </ErrorModal>);
                }
            }).catch((err) => {
                Overlay.open(<ErrorModal onClose={() => Overlay.close()} title='Unable to set ticket status to lost!' >
                    <FluxNotice type='error' title={err.error?.message ?? 'Service not available, please try later'} />
                </ErrorModal>);
            });
        }}
        title='Confirm' confirmTitle='Update'>
        <FluxNotice type='warning' title='Confirm ticket lost' description='Ticket will be set as lost and all selections will be updated' />
    </ConfirmationModal>);
};




export const ManualCancel = (ticket, onComplete) => {
    var bets = JSON.parse(ticket.bets);
    bets.map(x => {
        if (x.Status == 0) {
            x.Status = 9;
        }
    });
    Overlay.open(<ConfirmationModal
        onCancel={() => Overlay.close()}
        onConfirm={() => {
            var copy = {
                TicketId: ticket.externalTicketId,
                Tax: ticket.tax,
                Status: 4
            };
            API.post(`${TicketEndpoints.ManualTicketCancel}/${window.user.UserPrivileges.PointOfView}`, copy).then((result) => {
                if (result.result) {
                    var newTicket = { ...ticket };
                    newTicket.status = 4;
                    newTicket.bets = bets;
                    newTicket.amount = 0;
                    onComplete(newTicket);
                    Popper.pop('information', 'Ticket Cancelled!');
                } else {
                    Overlay.open(<ErrorModal onClose={() => Overlay.close()} title='Unable to cancel ticket!' >
                        <FluxNotice type='error' title={result?.error?.message ?? 'Unknown error!'} />
                    </ErrorModal>);
                }
            }).catch((err) => {
                Overlay.open(<ErrorModal onClose={() => Overlay.close()} title='Unable to cancel ticket!' >
                    <FluxNotice type='error' title={err.error?.message ?? 'Service not available, please try later'} />
                </ErrorModal>);
            });
        }}
        title='Confirm' confirmTitle='Update'>
        <FluxNotice type='warning' title='Confirm ticket cancel' description='Ticket will be set as cancel' />
    </ConfirmationModal>);
};