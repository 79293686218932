import React, { useEffect, useState } from 'react';
import { lang } from '../../../lib/Localization/language';
import { Modal } from '../../../components/application/FluxApp';
import { Button } from '../../../components/button/Button';
import { ConfirmationModal } from '../../../components/modal/ConfirmationModal/ConfirmationModal';
import { ErrorModal } from '../../../components/modal/ErrorModal/ErrorModal';
import { FluxNotice } from '../../../components/notification/FluxNotice';
import { NotificationType } from '../../../components/notification/FluxNotification';
import { Popper } from '../../../components/notification/FluxPopper';
import { FluxTab } from '../../../components/tab/FluxTab';
import { API } from '../../../lib/API/Api';
import { CasinoLimitsEndpoints, DataEndpoints } from '../../../lib/API/Endpoints';
import { ProviderGameTypes } from '../../../lib/Enums/GameEnums';
import { CasinoStakeLimit } from '../Configurator/CasinoLimitConfigurator';



export const CasinoGameLimits = (props) => {
    const { group } = { ...props };
    const { currencies } = { ...props };
    const [limits, setLimits] = useState(null);

    const [providers, setProviders] = useState([]);
    const [selectedProvider, selectProvider] = useState(null);
    const [games, setGames] = useState([]);

    const [currency, setCurrency] = useState(null);

    useEffect(() => {
        API.post(DataEndpoints.GameProviders).then((result) => {
            var sorted = result.result.sort((a, b) => a.display > b.display ? 1 : -1);
            setCurrency(currencies[0]);
            setProviders(sorted);
            selectProvider(sorted[0]);
        });
    }, []);

    useEffect(() => {
        if (!selectedProvider) return;
        API.post(`${CasinoLimitsEndpoints.GameLimits}/${selectedProvider.id}`).then((result) => {
            var converted = null;
            try {
                converted = JSON.parse(result.result);
            } catch (err) {
                //
                converted = {
                    general: {},
                    groups: {}
                };
            }
            API.post(`${DataEndpoints.Games}/${selectedProvider.id}`).then((gameList) => {
                var sorted = gameList.result.sort((a, b) => a.name > b.name ? 1 : -1);
                setGames(sorted);
                setLimits(converted ?? {
                    general: {},
                    groups: {}
                });
            });
        });
    }, [selectedProvider]);


    useEffect(() => {
        console.log(limits);
    }, [limits]);

    const tabButtons = currencies.map(x => { return { title: x.CurrencyCode, value: x.Id }; });



    const updateLimits = (game, currency, field, value) => {
        var copy = { ...limits };
        var target = null;

        if (group) {
            if (!copy.groups[group.value]) {
                copy.groups[group.value] = {};
            }

            if (!copy.groups[group.value][currency]) {
                copy.groups[group.value][currency] = []
            }

            target = copy.groups[group.value][currency];
            target = target.find(x => x.Id == game.id);
            if (!target) {
                target = Object.assign({ Id: game.id, GameType: game.type }, { ...{ MinBet: 0, MaxBet: 0, MaxPayout: 0, BetStop: false } });
                copy.groups[group.value][currency].push(target);
            }
        } else {
            if (!copy.general[currency]) {
                copy.general[currency] = [];
            }
            target = copy.general[currency];
            target = target.find(x => x.Id == game.id);
            if (!target) {
                target = Object.assign({ Id: game.id, GameType: game.type }, { ...{ MinBet: 0, MaxBet: 0, MaxPayout: 0, BetStop: false } });
                copy.general[currency].push(target);
            }
        }

        target[field] = value;
        setLimits(copy);
    };

    const toggleBetStop = (gameType, currency) => {
        var copy = { ...limits };
        var target = getLimit(copy, gameType, currency, true);
        target.BetStop = !target.BetStop;
        setLimits(copy);
    };

    const clearConfig = (game, currency) => {
        var copy = { ...limits };
        if (group) {
            copy.groups[group.value][currency] = copy.groups[group.value][currency].filter(x => x.Id !== game.id);
            if (Object.values(copy.groups[group.value][currency]).length == 0) {
                delete copy.groups[group.value][currency];
            }
        } else {
            copy.general[currency] = copy.general[currency].filter(x => x.Id !== game.id);
            if (copy.general[currency].length == 0) {
                delete copy.general[currency];
            }
        }
        setLimits(copy);
    };

    const getLimit = (target, game, currency, createNew) => {
        var limit = null;
        if (!group) {
            limit = target.general[currency.CurrencyCode];
            if (limit) {
                limit = limit.find(x => x.Id == game.id);
            }

            if (!limit && createNew) {
                if (!target.general[currency.CurrencyCode]) {
                    target.general[currency.CurrencyCode] = [];
                }
                limit = Object.assign({ Id: game.id, GameType: game.type }, { ...{ MinBet: 0, MaxBet: 0, MaxPayout: 0, BetStop: false } });
                target.general[currency.CurrencyCode].push(limit);
                return limit;
            }
        } else {
            limit = target.groups[group.value] ? target.groups[group.value][currency.CurrencyCode] : null;
            if (limit) {
                limit = limit.find(x => x.Id == game.id);
            }
            if (!limit && createNew) {
                if (!target.groups[group.value]) {
                    target.groups[group.value] = {};
                }
                if (!target.groups[group.value][currency.CurrencyCode]) {
                    target.groups[group.value][currency.CurrencyCode] = [];
                }
                limit = Object.assign({ Id: game.id, GameType: game.type }, { ...{ MinBet: 0, MaxBet: 0, MaxPayout: 0, BetStop: false } });
                target.groups[group.value][currency.CurrencyCode].push(limit);
                return limit;
            }
        }

        return limit ?? Object.assign({ Id: game.id, GameType: game.type }, { ...{ MinBet: 0, MaxBet: 0, MaxPayout: 0, BetStop: false } });
    };

    const renderGame = (game) => {
        if (!limits) return;

        var limit = getLimit(limits, game, currency);

        return <CasinoStakeLimit
            key={game}
            limit={limit ?? { ...{ MinBet: 0, MaxBet: 0, MaxPayout: 0, BetStop: false } }}
            group={group}

            onChange={(e) => {
                var value = parseFloat(e.target.value);
                if (isNaN(value)) value = 0;
                if (value < 0) value = 0;
                updateLimits(game, currency.CurrencyCode, e.target.name, value);
            }}
            onBetStop={() => toggleBetStop(game, currency)}
            onClear={() => clearConfig(game, currency.CurrencyCode)}

            title={<flex className='configuration-title vertical'>
                <label className='medium '>{game.name}</label>
                <label>{ProviderGameTypes[game.type]}</label>
            </flex>} />;
    };



    const save = () => {
        var title = lang('Updating Game limits for provider %1.', [selectedProvider.display]);
        var description = lang('Please confirm updating provider %1 casino game limits, the settings will apply default & group configurations and will override General & Game Type settings.', [selectedProvider.display]);
        Modal.open(<ConfirmationModal title='Save game limits!' onConfirm={() => {
            API.post(`${CasinoLimitsEndpoints.SaveGameLimits}/${selectedProvider.id}`, limits).then(() => {
                Popper.pop('information', 'Saved', 'Game limits saved.')
            }).catch((error) => {
                var message = error?.error?.message ?? 'Service error. Please try later!';
                Modal.open(<ErrorModal title='Unable to save limits'><FluxNotice type={NotificationType.Error} title='Operation failed!' description={message} /></ErrorModal>);
            });
        }}>
            <FluxNotice type={NotificationType.Information} title={title} description={description} />
        </ConfirmationModal>)
    };

    if (!currency) return <></>;

    return <flex className='vertical gap-5 overflow-hidden'>
        {
            group && <flex className='border radius fx-shadow padding surface'>
                <label className='medium bold'>{lang('%1 group configuration', [group.title])}</label>
                <Button title='Save' className={'align-right success'} onClick={() => save()} />
            </flex>
        }
        {
            !group && <flex className='border radius fx-shadow padding surface'>
                <label className='medium bold'>{lang('No group selected, settings will apply to all groups without specific configuration.')}</label>
                <Button title='Save' className={'align-right success'} onClick={() => save()} />
            </flex>
        }

        <flex className='border radius fx-shadow padding surface gap-5 fit-children'>
            <label className='medium bold'>{lang('Select provider')}</label>
            <select onChange={(e) => selectProvider(providers.find(x => x.id == parseFloat(e.target.value)))}>
                {providers.map(x => {
                    return <option value={x.id} key={x.id}>{x.display}</option>;
                })}
            </select>
        </flex>
        <FluxTab buttons={tabButtons} className='gap-10 fx-shadow fx-radius rounded-buttons radius justify-content-center' onChange={(index) => setCurrency(currencies[index])} />
        <flex className='vertical gap-5 overflow-hidden overflow-y-auto casino-limits-configurator radius'>
            {games.map(x => renderGame(x))}
        </flex>
    </flex>;
};
