/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ActionTypes } from '../../../lib/ActionManager/FluxActions';
import { API } from '../../../../v2/Lib/Api/Api';
import { useSelector } from 'react-redux';
import { Modal } from '../../../components/application/FluxApp';
import { FluxForm } from '../../../components/form/FluxForm';
import { FluxModal } from '../../../components/modal/FluxModal';
import { Popper } from '../../../components/notification/FluxPopper';
import { lang } from '../../../lib/Localization/language';
import { NotificationType } from '../../../components/notification/FluxNotification';
import { ConfirmationModal } from '../../../components/modal/ConfirmationModal/ConfirmationModal';
import { movePlayerToAffiliate, removePlayerFromAffiliate } from '../../../actions/AffiliateActions';
import { movePlayerToRetail, removePlayerFromRetail } from '../../../actions/RetailActions';
import { getHistory } from '../../../../routers/AppRouterFlux';
import { FluxAction } from '../../../components/application/FluxAction';
import { editPlayer } from '../../../actions/PlayerActions';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { ModelAffiliatePlayer, ModelRetailPlayer } from '../../../lib/Models/PlayerModels';
import { Icon } from '../../../components/icon/Icon';
import { PlayerEndpoints, RetailEndpoints } from '../../../lib/API/Endpoints';
import { InputTypes } from '../../../components/form/InputTypes';
import { Button } from '../../../components/button/Button';
import { Currency } from '../../../components/currency/Currency';


export const SearchPlayersForFreeSpin = (props) => {
    const currency = props.currency;
    const [title, setTitle] = useState('Search players');
    const [endpoint, setEndpoint] = useState(PlayerEndpoints.Search);
    const [actions, setActions] = useState(ActionTypes.AssignFreeSpinPromotion);
    const user = useSelector((state) => state.user).loginInformation;
    const [selectedPlayers, selectPlayers] = useState(props.selectedPlayers);



    const Model = {
        fields: [
            {
                name: 'Id',
                type: InputTypes.Number,
                identity: true,
            },
            {
                name: 'PlayerGroup',
                title: 'Player Group'
            },
            {
                name: 'Code',
                type: InputTypes.Text,
            },
            {
                name: 'Created',
                title: 'Register Date',
                type: InputTypes.DateTime,
                hidden: true
            },
            {
                name: 'RetailName',
                title: 'Retail Name'
            },
            {
                name: 'Affiliate',
                title: 'Affiliate'
            },
            {
                name: 'Username',
                type: InputTypes.Text
            },
            {
                name: 'Name',
                type: InputTypes.Text
            },
            {
                name: 'Surname',
                type: InputTypes.Text
            },
            {
                name: 'Country',
                type: InputTypes.Text,
                hidden: true
            },
            {
                name: 'City',
                type: InputTypes.Text,
                hidden: true
            },
            {
                name: 'Tag',
                title: 'Tags',
                hidden: true
            },
            {
                name: 'LastLogin',
                title: 'Last Login Date',
                type: InputTypes.DateTime
            },
            {

                name: 'Balance',
                type: InputTypes.Number,
                render: (val, data) => {
                    return <Currency amount={val} sign={data.Currency} />;
                }
            }
        ]
    };

    const ModelSearchPlayer = {
        name: 'search-players-for-freespin' + props.currency,
        fields: [
            {
                name: 'Group',
                title: 'Player Group',
                type: InputTypes.Select,
                nullable: true,
                data: {
                    source: 'myPlayerGroups',
                    key: 'Id',
                    title: ['Name']
                }
            },
            {
                name: 'Id',
                max: 5,
                type: InputTypes.Number
            },
            {
                name: 'Code',
                type: InputTypes.Text,
                exact: true,
            },
            {
                name: 'Username',
                type: InputTypes.Text,
                exact: false
            },
            {
                name: 'Name',
                type: InputTypes.Text,
                exact: false
            },
            {
                name: 'Surname',
                type: InputTypes.Text,
                exact: false
            },
            {
                name: 'IdNumber',
                title: 'Id / Passport',
                type: InputTypes.Text,
                hidden: true,
                exact: true
            },
            {
                name: 'Email',
                title: 'Email',
                type: InputTypes.Text,
                hidden: true,
                exact: true
            },
            {
                name: 'Phone',
                title: 'Phone',
                type: InputTypes.Text,
                hidden: true
            },
            {
                name: 'Tag',
                title: 'Tags',
                type: InputTypes.Text,
                hidden: true
            },
            {
                name: 'Currency',
                title: 'Currency',
                type: InputTypes.Text,
                value: currency,
                readonly: true
            },
            {
                name: 'LastLoginIp',
                title: 'Last Login Ip',
                type: InputTypes.Text,
                hidden: true,
            },
            {
                name: 'Enabled',
                title: 'Enabled',
                type: InputTypes.MultipleSelect,
                exact: true,
                nullable: true,
                value: null,
                hidden: true,
                values: {
                    'All Players': null,
                    'Enabled Players': true,
                    'Disabled Players': false
                }
            },
        ]
    };



    useEffect(() => {
        if (props.affiliate) {
            setTitle(<React.Fragment><span>{lang('Affiliate')}</span><Icon icon='chevron-right' type='fa-light' /><span>{props.affiliate.Name}</span><Icon icon='chevron-right' type='fa-light' /><span>{lang('Search Players')}</span></React.Fragment>);
            return;
        }
        if (props.retail) {
            setTitle(<React.Fragment><span>{lang('Retail')}</span><Icon icon='chevron-right' type='fa-light' /><span>{props.retail.Name}</span><Icon icon='chevron-right' type='fa-light' /><span>{lang('Search Players')}</span></React.Fragment>);
            setEndpoint(`${RetailEndpoints.ListRetailPlayers}/${props.retail.Id}`);
            setActions(ActionTypes.ListRetailPlayers);
            return;
        }
    }, []);

    useEffect(() => {
        selectPlayers(props.selectedPlayers);
    }, [props.selectedPlayers]);

    const prepareModel = () => {
        var copy = { ...ModelSearchPlayer };
        return copy;
    };


    const prepareResultModel = () => {
        var copy = { ...Model };
        if (props.affiliate) {
            copy = { ...ModelAffiliatePlayer };
            copy.fields = copy.fields.filter(x => x.name != 'Affiliate');
        }

        if (props.retail) {
            copy = { ...ModelRetailPlayer };
        }

        return copy;
    };

    const isExportEnabled = (target) => {
        var actions = user.UserPrivileges?.SpecialActions;
        if (!actions) return false;
        if (actions.find(x => x == target)) {
            return true;
        }
        return false;
    };

    const onActionClicked = (action, player) => {
        switch (action.type) {
            case ActionTypes.AssignFreeSpinPromotion:
                var copy = { ...selectedPlayers };
                if (copy[player.Id]) {
                    delete copy[player.Id];
                } else {
                    copy[player.Id] = player;
                }

                selectPlayers(copy);
                if (props.onSelectPlayers) {
                    props.onSelectPlayers(copy);
                }
                break;
            case ActionTypes.PlayerProfile:
                getHistory().push(`/players/search?&id=${player.Id}`);
                break;
            case ActionTypes.UpdatePlayer:
                editPlayer(player.Id);
                break;
            case ActionTypes.ChangePlayerPassword:
                Modal.open(<FluxModal title='Update password'>
                    <FluxForm
                        data={player}
                        endpoint={PlayerEndpoints.ChangePassword}
                        model={{
                            fields: [
                                { name: 'Password', title: 'Password', type: 'password' },
                                { name: 'PasswordConfirm', title: 'Confirm Password', type: 'password' },
                            ]
                        }}
                        onCancel={() => Modal.close()}
                        onSubmitComplete={() => {
                            Modal.close();
                            Popper.pop(NotificationType.Success, lang('Password changed.'));
                        }}
                    />
                </FluxModal>);
                break;
            case ActionTypes.ToggleEnablePlayer:
                Modal.open(<ConfirmationModal title={'Please confirm'} onConfirm={() => {
                    API.post(`${PlayerEndpoints.ToggleEnablePlayer}/${player.Id}`, player).then(() => {
                        Popper.pop('information', player.Enabled ? 'Player disabled' : 'Player enabled');
                    });
                }}>
                    <p>{lang('Do you want to %1?', [player.Enabled ? 'Deactivate player' : 'Activate player'])}</p>
                </ConfirmationModal>);
                break;
            case ActionTypes.MovePlayerToRetail:
                if (player.RetailName != null) {
                    removePlayerFromRetail(player);
                } else {
                    movePlayerToRetail(player);
                }
                break;
            case ActionTypes.AddPlayerToAffiliate:
                if (player.Affiliate != null) {
                    removePlayerFromAffiliate(player, () => {
                        player.Affiliate = '';
                    });
                } else {
                    movePlayerToAffiliate(player, () => {
                        player.Affiliate = '--';
                    });
                }
                break;
            default:
                console.log(action.type);
                break;
        }
    };
    const onRenderPlayerSearchActions = (action, selection) => {
        if (selection.length == 0) action;
        var item = selection[0];
        if (item === undefined) return action;
        switch (action.type) {
            case ActionTypes.AssignFreeSpinPromotion:
                return selectedPlayers && selectedPlayers[item.Id] ? { title: lang('Remove'), icon: 'times', enabled: true } : { title: lang('Select Player'), icon: 'plus', enabled: true };
        }
        return action;
    };


    return <FluxAction key={props.currency} action={ActionTypes.SearchPlayer} checkActive={false}>
        <FluxSearch
            model={prepareModel()}
            resultModel={prepareResultModel()}
            endpoint={endpoint}
            actions={actions}
            title={title}
            buttons={(player) => {
                var copy = { ...selectedPlayers };
                if (selectedPlayers && selectedPlayers[player.Id]) {
                    return <Button className='warning' title='Remove' onClick={() => {
                        if (copy[player.Id]) {
                            delete copy[player.Id];
                        }

                        selectPlayers(copy);
                        if (props.onSelectPlayers) {
                            props.onSelectPlayers(copy);
                        }
                    }} />;
                }
                return <Button className='success' title='Add' onClick={() => {
                    copy[player.Id] = player;

                    selectPlayers(copy);
                    if (props.onSelectPlayers) {
                        props.onSelectPlayers(copy);
                    }
                }} />;
            }}
            exportToExcel={isExportEnabled('ExportPlayers')}
            exportAllData={isExportEnabled('ExportPlayers')}
            onRenderAction={onRenderPlayerSearchActions.bind(this)}
            onActionClicked={onActionClicked.bind(this)} />
    </FluxAction>;
};

SearchPlayersForFreeSpin.propTypes = {
    affiliate: PropTypes.object,
    currency: PropTypes.string,
    operator: PropTypes.number,
    retail: PropTypes.object,
    selectedPlayers: PropTypes.object,
    onSelectPlayers: PropTypes.func
};