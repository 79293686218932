import React from 'react';
import { Overlay } from '../components/application/FluxApp';
import { FluxForm } from '../components/form/FluxForm';
import { ConfirmationModal } from '../components/modal/ConfirmationModal/ConfirmationModal';
import { ErrorModal } from '../components/modal/ErrorModal/ErrorModal';
import { FluxNotice } from '../components/notification/FluxNotice';
import { Popper } from '../components/notification/FluxPopper';
import { API } from '../lib/API/Api';
import { HorseTicketEndpoints } from '../lib/API/Endpoints';



export const ManualWin = (ticket, onComplete) => {
    var data = null;
    var bets = JSON.parse(ticket.bets);
    bets.map(x => {
        if (x.Status == 0) {
            x.Status = 4;
        }
    });
    Overlay.open(<ConfirmationModal
        onCancel={() => Overlay.close()}
        onConfirm={() => {
            var copy = {
                ExternalTicketId: ticket.externalTicketId,
                Tax: ticket.tax,
                Status: 1,
                Bets: bets,
                Win: data.Amount
            };
            API.post(HorseTicketEndpoints.ManualTicketUpdate, copy).then((result) => {
                if (result.result) {
                    var newTicket = { ...ticket };
                    newTicket.status = 1;
                    newTicket.bets = bets;
                    newTicket.amount = data.Amount;
                    onComplete(newTicket);
                    Popper.pop('information', 'Ticket Updated');
                } else {
                    Overlay.open(<ErrorModal onClose={() => Overlay.close()} title='Unable to set ticket status to win!' >
                        <FluxNotice type='error' title={result?.error?.message ?? 'Unknown error!'} />
                    </ErrorModal>);
                }
            }).catch((err) => {
                Overlay.open(<ErrorModal onClose={() => Overlay.close()} title='Unable to set ticket status to win!' >
                    <FluxNotice type='error' title={err.error?.message ?? 'Service not available, please try later'} />
                </ErrorModal>);
            });
        }}
        title='Confirm' confirmTitle='Update'>
        <FluxNotice type='warning' title='Confirm ticket win' description='Ticket will be set as win and all selections will be updated' />
        <FluxForm buttons={null} model={{
            fields: [
                {
                    name: 'Amount',
                    title: 'Amount',
                    value: ticket.possibleWin
                }
            ]
        }} onChange={(d) => data = d} />
    </ConfirmationModal>);
};



export const ManualLost = (ticket, onComplete) => {
    var bets = JSON.parse(ticket.bets);
    bets.map(x => {
        if (x.Status == 0) {
            x.Status = 3;
        }
    });
    Overlay.open(<ConfirmationModal
        onCancel={() => Overlay.close()}
        onConfirm={() => {
            var copy = {
                ExternalTicketId: ticket.externalTicketId,
                Tax: ticket.tax,
                Status: 2,
                Bets: bets,
                Win: 0
            };
            API.post(HorseTicketEndpoints.ManualTicketUpdate, copy).then((result) => {
                if (result.result) {
                    var newTicket = { ...ticket };
                    newTicket.status = 2;
                    newTicket.bets = bets;
                    newTicket.amount = 0;
                    onComplete(newTicket);
                    Popper.pop('information', 'Ticket Updated');
                } else {
                    Overlay.open(<ErrorModal onClose={() => Overlay.close()} title='Unable to set ticket status to lost!' >
                        <FluxNotice type='error' title={result?.error?.message ?? 'Unknown error!'} />
                    </ErrorModal>);
                }
            }).catch((err) => {
                Overlay.open(<ErrorModal onClose={() => Overlay.close()} title='Unable to set ticket status to lost!' >
                    <FluxNotice type='error' title={err.error?.message ?? 'Service not available, please try later'} />
                </ErrorModal>);
            });
        }}
        title='Confirm' confirmTitle='Update'>
        <FluxNotice type='warning' title='Confirm ticket lost' description='Ticket will be set as lost and all selections will be updated' />
    </ConfirmationModal>);
};




export const ManualCancel = (ticket, onComplete) => {
    var bets = JSON.parse(ticket.bets);
    bets.map(x => {
        if (x.Status == 0) {
            x.Status = 9;
        }
    });
    Overlay.open(<ConfirmationModal
        onCancel={() => Overlay.close()}
        onConfirm={() => {
            var copy = {
                TicketId: ticket.externalTicketId,
                Tax: ticket.tax,
                Status: 4
            };
            API.post(`${HorseTicketEndpoints.ManualTicketCancel}/${window.user.UserPrivileges.PointOfView}`, copy).then((result) => {
                if (result.result) {
                    var newTicket = { ...ticket };
                    newTicket.status = 4;
                    newTicket.bets = bets;
                    newTicket.amount = 0;
                    onComplete(newTicket);
                    Popper.pop('information', 'Ticket Cancelled!');
                } else {
                    Overlay.open(<ErrorModal onClose={() => Overlay.close()} title='Unable to cancel ticket!' >
                        <FluxNotice type='error' title={result?.error?.message ?? 'Unknown error!'} />
                    </ErrorModal>);
                }
            }).catch((err) => {
                Overlay.open(<ErrorModal onClose={() => Overlay.close()} title='Unable to cancel ticket!' >
                    <FluxNotice type='error' title={err.error?.message ?? 'Service not available, please try later'} />
                </ErrorModal>);
            });
        }}
        title='Confirm' confirmTitle='Update'>
        <FluxNotice type='warning' title='Confirm ticket cancel' description='Ticket will be set as cancel' />
    </ConfirmationModal>);
};