import './_sport-player-limits.scss';

import React, { useEffect, useState } from 'react';
import { API } from '../../../../lib/API/Api';
import { SportPlayerLimitsEndpoints } from '../../../../lib/API/Endpoints';
import { FluxCard } from '../../../card/FluxCard';
import { SportPlayerLimitConfigurator } from './SportPlayerLimitConfigurator';
import { PlayerGroupList } from '../../../player/player-groups/PlayerGroupList';

export const SportPlayerLimitsWithRiskLevels = (props) => {
    const [group, selectGroup] = useState(null);
    const [limits, setLimits] = useState(null);

    useEffect(() => {
        API.post(SportPlayerLimitsEndpoints.SportPlayerLimits).then((result) => {
            var converted = null;
            try {
                converted = JSON.parse(result.result);
            } catch (err) {
                //
                converted = {
                    general: {},
                    groups: {}
                };
            }

            setLimits(converted ?? {
                general: {},
                groups: {}
            });
        });

    }, []);

    if (!limits) return <></>;

    return <flex className='vertical gap-10 fit-children overflow-hidden'>
        <flex className='gap-10 overflow-hidden fit-children'>
            <FluxCard title='Player Configuration' className={'overflow-hidden align-self-stretch no-padding sport-limits-configuration-type'}>
                <PlayerGroupList
                    onSelectGroup={(data) => selectGroup(data)}
                    disableDefaultGroup
                ></PlayerGroupList>
            </FluxCard>
            <SportPlayerLimitConfigurator limits={limits} group={group} key={group?.value}
                onUpdate={(riskLevel, s) => {
                    var copy = { ...limits };
                    if (group) {
                        if (!copy.groups[group.value]) {
                            copy.groups[group.value] = {};
                        }
                        copy.groups[group.value][riskLevel] = s;
                    } else {
                        copy.general[riskLevel] = s;
                    }
                    setLimits(copy);
                }}
                onSave={(data) => setLimits(data)} />
        </flex>
    </flex>;
};