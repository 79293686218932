/* eslint-disable react/display-name */
import React from 'react';

import { lang } from '../Localization/language';
import { openHorseTicketDetails, openSportTicketDetails } from '../../actions/TicketActions';
import { InputTypes } from '../../components/form/InputTypes';
import { SportsBetTypes, SportsTicketStatus, SportsTicketTypes, BettingFlags, RacingTicketTypes } from '../../lib/Enums/BettingEnums';
import { footerCurrencyValues } from '../Helpers/Reports/Footer';
import { gotoProfile } from '../Helpers/NavigationHelper';
import { currency, Currency } from '../../components/currency/Currency';
import { appHistory } from '../../..';

export const TicketId = (props) => {
    const { id, onChange } = { ...props };
    return <a style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
        openSportTicketDetails(id, false, onChange);
    }}>{id}</a>;
};

export const HorseTicketId = (props) => {
    const { id, onChange } = { ...props };
    return <a style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
        openHorseTicketDetails(id, false, onChange);
    }}>{id}</a>;
};

export const EventId = (props) => {
    const { id } = { ...props };
    return <a style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
        appHistory.push(`/sports/events?&id=${id}`);
    }}>{id}</a>;
};

export const renderBetSlipStatus = (row, isExporting) => {
    var manual = row.Flags & 0x02000000;
    if (isExporting) {
        return manual > 0 ? 'manual' : SportsTicketStatus[row.Status];
    }
    return <flex className='centrum-flex vertical'>
        {manual > 0 && <label>{lang('Manual')}</label>}<span>{SportsTicketStatus[row.Status]}</span>
    </flex>;
};

export const ModelSportTicketResult = {
    fields: [
        {
            name: 'ExternalTicketId',
            title: 'Ticket Id',
            render: (val, row, field, context, tableContext) => {
                row.Product = 0;
                return <TicketId id={val} onChange={(ticket) => {

                    var data = tableContext.getData();
                    var found = data.find(x => x.Id == ticket.id);
                    found.Status = ticket.status;
                    found.Win = ticket.win;
                    tableContext.setData(data);
                }} />;
            }
        },
        {
            name: 'EntryDate',
            title: 'Date',
            type: InputTypes.DateTime
        },
        {
            name: 'Updated',
            title: 'Update',
            type: InputTypes.DateTime
        },
        {
            name: 'Operator',
            title: 'Operator'
        },
        {
            name: 'RetailName',
            title: 'Retail'
        },
        {
            name: 'Group',
            title: 'Group',
            hidden: true
        },
        {
            name: 'PlayerId',
            title: 'Player Id',
            hidden: true
        },
        {
            name: 'Username',
            title: 'Username',
            render: (val, row) => {
                return <a style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
                    gotoProfile(row.PlayerId);
                }}>{val}</a>;
            }
        },
        {
            name: 'Type',
            title: 'Betting Type',
            type: InputTypes.Select,
            values: SportsBetTypes
        },
        {
            name: 'TicketType',
            title: 'Ticket Type',
            type: InputTypes.Select,
            values: SportsTicketTypes
        },
        {
            name: 'Status',
            title: 'Status',
            render: (val, row) => {
                if (row.TicketType == 2 && row.ChildTickets > 1) {
                    if (row.OpenTickets > 0) {
                        return `${row.ChildTickets} / ${row.OpenTickets}`;
                    }
                    return 'closed';
                }
                return renderBetSlipStatus(row);
            }
        },
        {
            name: 'BonusWallet',
            title: 'Bonus Wallet',
            hidden: true
        },
        {
            name: 'BonusName',
            title: 'Bonus Name'
        },
        {
            name: 'Currency',
        },
        {
            name: 'Bet',
            title: 'Stake',
            type: InputTypes.Number,
            export: (val, row) => currency(val, 2, row.Currency),
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            exportFooter: (val) => footerCurrencyValues(val, true),
            renderFooter: (val) => footerCurrencyValues(val)
        },
        {
            name: 'Odds',
            title: 'Odds',
            type: InputTypes.Number
        },
        {
            name: 'PossibleWin',
            title: 'Possible Win',
            type: InputTypes.Number,
            export: (val, row) => currency(val, 2, row.Currency),
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            exportFooter: (val) => footerCurrencyValues(val, true),
            renderFooter: (val) => footerCurrencyValues(val)
        },
        {
            name: 'TicketBonusName',
            title: 'Ticket Bonus Name',
            hidden: true,
        },
        {
            name: 'TicketBonusPercentage',
            title: 'Ticket Bonus %',
            type: InputTypes.Number,
            hidden: true,
            render: (val) => {
                if (!val) return;
                return `%${val}`;
            }
        },
        {
            name: 'TicketBonusAmount',
            title: 'Ticket Bonus Amount',
            type: InputTypes.Number,
            hidden: true,
            export: (val) => val,
            render: (val, row) => {
                if (row.TicketBonusPercentage) {
                    return <flex className='vertical'>
                        <span>{val}</span>
                        <flex className='gap-5'>
                            <span>{`%${row.TicketBonusPercentage}`}</span>
                            <Currency amount={(row.TicketBonusLimit > 0 && row.TicketBonusLimit < row.TicketBonusAmount) ? row.TicketBonusLimit : row.TicketBonusAmount} sign={row.Currency} />
                        </flex>
                    </flex>;
                }

                return val;
            }
        },
        {
            name: 'Win',
            title: 'Win',
            type: InputTypes.Number,
            export: (val, row) => currency(val, 2, row.Currency),
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            exportFooter: (val) => footerCurrencyValues(val, true),
            renderFooter: (val) => footerCurrencyValues(val)
        },
        {
            name: 'TaxAmount',
            title: 'Tax',
            type: InputTypes.Number,
            hidden: true,
            export: (val, row) => currency(val, 2, row.Currency),
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
        },
        {
            name: 'GGR',
            title: 'GGR',
            type: InputTypes.Number,
            export: (val, row) => currency(val, 2, row.Currency),
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            exportFooter: (val) => footerCurrencyValues(val, true),
            renderFooter: (val) => footerCurrencyValues(val)
        }
    ],
    onRenderClassName: (row, className) => {
        if (row.Status == 2) {
            if (row.TicketBonusConsumed) {
                className = 'player-row-bonus';
            } else {
                className = 'player-row-lost';
            }

        }
        if (row.Status == 1) {
            className = 'player-row-win';
        }
        if (row.Status == 3) {
            className = 'player-row-cashout';
        }

        if (row.Status == 5) {
            className = 'player-row-cashback';
        }


        return className;
    }
};



export const ModelInvestigatedTickets = {
    fields: [
        {
            name: 'ExternalTicketId',
            title: 'Ticket Id',
            render: (val, row, field, context, tableContext) => {
                row.Product = 0;
                return <TicketId id={val} onChange={(ticket) => {

                    var data = tableContext.getData();
                    var found = data.find(x => x.Id == ticket.id);
                    found.Status = ticket.status;
                    found.Win = ticket.win;
                    tableContext.setData(data);
                }} />;
            }
        },
        {
            name: 'EntryDate',
            title: 'Date',
            type: InputTypes.DateTime
        },
        {
            name: 'Updated',
            title: 'Update',
            type: InputTypes.DateTime
        },
        {
            name: 'Operator',
            title: 'Operator'
        },
        {
            name: 'RetailName',
            title: 'Retail'
        },
        {
            name: 'Group',
            title: 'Group',
            hidden: true
        },
        {
            name: 'PlayerId',
            title: 'Player Id',
            hidden: true
        },
        {
            name: 'Username',
            title: 'Username',
            render: (val, row) => {
                return <a style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
                    gotoProfile(row.PlayerId);
                }}>{val}</a>;
            }
        },
        {
            name: 'Type',
            title: 'Betting Type',
            type: InputTypes.Select,
            values: SportsBetTypes
        },
        {
            name: 'TicketType',
            title: 'Ticket Type',
            type: InputTypes.Select,
            values: SportsTicketTypes
        },
        {
            name: 'Status',
            title: 'Status',
            render: (val, row) => {
                if (row.TicketType == 2 && row.ChildTickets > 1) {
                    if (row.OpenTickets > 0) {
                        return `${row.ChildTickets} / ${row.OpenTickets}`;
                    }
                    return 'closed';
                }
                return renderBetSlipStatus(row);
            }
        },
        {
            name: 'BonusWallet',
            title: 'Bonus Wallet',
            hidden: true
        },
        {
            name: 'BonusName',
            title: 'Bonus Name'
        },
        {
            name: 'Currency',
        },
        {
            name: 'Bets',
            title: 'Bets',
            render: (val) => {
                var elements = [];
                try {
                    var bets = JSON.parse(val);
                    bets.map(x => {
                        elements.push(<flex className='gap-5' key={x.EventExternalId}>
                            <span>{x.Sport}</span>
                            /
                            <span>{x.League}</span>
                            /
                            <span>{x.EventName}</span>
                        </flex>);
                    });
                } catch (err) {
                    //
                }
                return <flex className='vertical gap-5'>
                    {elements}
                </flex>;
            }
        },
        {
            name: 'Bet',
            title: 'Stake',
            type: InputTypes.Number,
            export: (val, row) => currency(val, 2, row.Currency),
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            exportFooter: (val) => footerCurrencyValues(val, true),
            renderFooter: (val) => footerCurrencyValues(val)
        },
        {
            name: 'Odds',
            title: 'Odds',
            type: InputTypes.Number
        },
        {
            name: 'PossibleWin',
            title: 'Possible Win',
            type: InputTypes.Number,
            export: (val, row) => currency(val, 2, row.Currency),
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            exportFooter: (val) => footerCurrencyValues(val, true),
            renderFooter: (val) => footerCurrencyValues(val)
        },
        {
            name: 'Win',
            title: 'Win',
            type: InputTypes.Number,
            export: (val, row) => currency(val, 2, row.Currency),
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            exportFooter: (val) => footerCurrencyValues(val, true),
            renderFooter: (val) => footerCurrencyValues(val)
        },
        {
            name: 'GGR',
            title: 'GGR',
            type: InputTypes.Number,
            export: (val, row) => currency(val, 2, row.Currency),
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            exportFooter: (val) => footerCurrencyValues(val, true),
            renderFooter: (val) => footerCurrencyValues(val)
        }
    ],
    onRenderClassName: (row, className) => {
        if (row.Status == 2) {
            if (row.TicketBonusConsumed) {
                className = 'player-row-bonus';
            } else {
                className = 'player-row-lost';
            }

        }
        if (row.Status == 1) {
            className = 'player-row-win';
        }
        if (row.Status == 3) {
            className = 'player-row-cashout';
        }

        if (row.Status == 5) {
            className = 'player-row-cashback';
        }


        return className;
    }
};


export const ModelHorseTicketResult = {
    fields: [
        {
            name: 'ExternalTicketId',
            title: 'Ticket Id',
            render: (val, row, field, context, tableContext) => {
                row.Product = 0;
                return <HorseTicketId id={val} onChange={(ticket) => {
                    var data = tableContext.getData();
                    var found = data.find(x => x.Id == ticket.id);
                    found.Status = ticket.status;
                    found.Win = ticket.win;
                    tableContext.setData(data);
                }} />;
            }
        },
        {
            name: 'EntryDate',
            title: 'Date',
            type: InputTypes.DateTime
        },
        {
            name: 'Updated',
            title: 'Update',
            type: InputTypes.DateTime
        },
        {
            name: 'Operator',
            title: 'Operator'
        },
        {
            name: 'RetailName',
            title: 'Retail'
        },
        {
            name: 'Group',
            title: 'Group',
            hidden: true
        },
        {
            name: 'PlayerId',
            title: 'Player Id',
            hidden: true
        },
        {
            name: 'Username',
            title: 'Username',
            render: (val, row) => {
                return <a style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
                    gotoProfile(row.PlayerId);
                }}>{val}</a>;
            }
        },
        {
            name: 'Type',
            title: 'Betting Type',
            render: (val, row) => {
                if (row.System) return row.System;
                var single = row.Flags & BettingFlags.Single;
                var forecast = row.Flags & BettingFlags.Forecast;
                var tricast = row.Flags & BettingFlags.Tricast;
                var eachWay = row.Flags & BettingFlags.EachWay;


                if ((row.System == '' || !row.System) && row.Flags == 1) {
                    var result = RacingTicketTypes[row.TicketType];
                    return <span>{lang(result)}</span>;
                }
                if (single) {
                    return <flex className='gap-5'>
                        <span>{lang('Single')}</span>
                        {eachWay > 0 && <span>{lang('Eachway')}</span>}
                    </flex>;
                }
                if (forecast) {
                    return <span>{lang('Forecast')}</span>;
                }
                if (tricast) {
                    return <span>{lang('Tricast')}</span>;
                }
            }
        },
        {
            name: 'Status',
            title: 'Status',
            render: (val, row) => {
                if (row.TicketType == 2 && row.ChildTickets > 1) {
                    if (row.OpenTickets > 0) {
                        return `${row.ChildTickets} / ${row.OpenTickets}`;
                    }
                    return 'closed';
                }
                return renderBetSlipStatus(row);
            }
        },
        {
            name: 'BonusWallet',
            title: 'Bonus Wallet',
            hidden: true
        },
        {
            name: 'BonusName',
            title: 'Bonus Name',
            hidden: true
        },
        {
            name: 'Bet',
            title: 'Stake',
            type: InputTypes.Number,
            export: (val, row) => currency(val, 2, row.Currency),
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={true} />,
            exportFooter: (val) => footerCurrencyValues(val, true),
            renderFooter: (val) => footerCurrencyValues(val)
        },
        {
            name: 'Odds',
            title: 'Odds',
            type: InputTypes.Number
        },
        {
            name: 'PossibleWin',
            title: 'Possible Win',
            type: InputTypes.Number,
            export: (val, row) => currency(val, 2, row.Currency),
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            exportFooter: (val) => footerCurrencyValues(val, true),
            renderFooter: (val) => footerCurrencyValues(val)
        },
        {
            name: 'TicketBonusName',
            title: 'Ticket Bonus Name',
            hidden: true,
        },
        {
            name: 'TicketBonusPercentage',
            title: 'Ticket Bonus %',
            type: InputTypes.Number,
            hidden: true,
            render: (val) => {
                if (!val) return;
                return `%${val}`;
            }
        },
        {
            name: 'TicketBonusAmount',
            title: 'Ticket Bonus Amount',
            type: InputTypes.Number,
            hidden: true,
            export: (val) => val,
            render: (val, row) => {
                if (row.TicketBonusPercentage) {
                    return <flex className='vertical'>
                        <span>{val}</span>
                        <flex className='gap-5'>
                            <span>{`%${row.TicketBonusPercentage}`}</span>
                            <Currency amount={(row.TicketBonusLimit > 0 && row.TicketBonusLimit < row.TicketBonusAmount) ? row.TicketBonusLimit : row.TicketBonusAmount} sign={row.Currency} />
                        </flex>
                    </flex>;
                }

                return val;
            }
        },

        {
            name: 'Horse-Events',
            title: 'Events',
            render: (val, row) => {
                try {
                    var bets = JSON.parse(row.Bets);
                    var summary = bets.Summary.split('~');
                    var events = {};
                    summary.map(x => {
                        var event = x.split('|');
                        var eventId = event[0];
                        events[eventId] = <React.Fragment>
                            <flex className='vertical'>
                                <flex className='gap-5'>
                                    <span className='bold'>{eventId}</span><span>{event[2]}</span>
                                </flex>
                                <span>{event[4]}</span>
                            </flex>
                        </React.Fragment>;
                    });

                    return <flex className='vertical gap-5'>
                        {
                            Object.values(events).map((x) => x)
                        }
                    </flex>;
                } catch (err) {
                    //
                }
            }
        },
        {
            name: 'Win',
            title: 'Win',
            type: InputTypes.Number,
            export: (val, row) => currency(val, 2, row.Currency),
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            exportFooter: (val) => footerCurrencyValues(val, true),
            renderFooter: (val) => footerCurrencyValues(val)
        },
        {
            name: 'TaxAmount',
            title: 'Tax',
            type: InputTypes.Number,
            hidden: true,
            export: (val, row) => currency(val, 2, row.Currency),
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
        },
        {
            name: 'GGR',
            title: 'GGR',
            type: InputTypes.Number,
            export: (val, row) => currency(val, 2, row.Currency),
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            exportFooter: (val) => footerCurrencyValues(val, true),
            renderFooter: (val) => footerCurrencyValues(val)
        }
    ],
    onRenderClassName: (row, className) => {
        if (row.Status == 2) {
            if (row.TicketBonusConsumed) {
                className = 'player-row-bonus';
            } else {
                className = 'player-row-lost';
            }

        }
        if (row.Status == 1) {
            className = 'player-row-win';
        }
        if (row.Status == 3) {
            className = 'player-row-cashout';
        }

        if (row.Status == 5) {
            className = 'player-row-cashback';
        }


        return className;
    }
};
