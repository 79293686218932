/* eslint-disable react/display-name */

import './_payment-provider-white-list.scss';

import React, { useEffect, useState } from 'react';
import { CheckBox } from '../../../../../v2/Components/Controls/Inputs/CheckBox/CheckBox';
import { Button } from '../../../../components/button/Button';
import { FluxCard } from '../../../../components/card/FluxCard';
import { FluxInput } from '../../../../components/form/Components/FluxInput';
import { MultipleSelectInput } from '../../../../components/form/Components/MultipleSelectInput';
import { InputTypes } from '../../../../components/form/InputTypes';
import { FluxNotice } from '../../../../components/notification/FluxNotice';
import { NotificationType } from '../../../../components/notification/FluxNotification';
import { Table } from '../../../../components/table/Table';
import { API } from '../../../../lib/API/Api';
import { PaymentProviders } from '../../../../lib/API/Endpoints';
import { lang } from '../../../../lib/Localization/language';
import { fetchGroups, showAddNewGroupModal } from '../../../Players/PlayerGroups/PlayerGroups';
import { Popper } from '../../../../components/notification/FluxPopper';
import { useSelector } from 'react-redux';

const field = {
    values: {
        'All': 1,
        'Online': 2,
        'Retail': 3
    },
    value: 1
};

export const PaymentProviderWhiteListGroups = () => {
    const user = useSelector((state) => state.user);

    const [selectedProvider, selectProvider] = useState(null);
    const [groups, setGroups] = useState([]);
    const [showFiltersMenu, toggleFilters] = useState(false);
    const [searchFilter, setSearchFilter] = useState(null);
    const [onlineFilter, setOnlineFilter] = useState(1);

    useEffect(() => {
        fetchAllDatas();
    }, []);

    const getData = () => {
        if (!selectedProvider) return [];
        var res = groups.filter(x => x.ProviderId == selectedProvider);

        if (searchFilter) {
            var text = searchFilter.toLowerCase();
            res = res.filter(x => x.GroupName.toLowerCase().includes(text));
        }

        if (onlineFilter == 2) {
            res = res.filter(x => x.GroupRetail == false);
        } else if (onlineFilter == 3) {
            res = res.filter(x => x.GroupRetail == true);
        }
        return res;
    };

    const fetchAllDatas = () => {
        var groups = [];
        API.post(PaymentProviders.Get).then((providers) => {
            fetchGroups().then((result) => {
                result.result = result.result.filter(x => x.OperatorId == user.loginInformation.UserPrivileges.PointOfView);
                API.post(PaymentProviders.WhiteListsGroupsGet).then((res) => {
                    providers.result.forEach(provider => {
                        result.result.map(groupRes => {
                            const data = res.result.find(y => y.GroupId == groupRes.Id && provider.id == y.ProviderId);
                            const group = {
                                GroupId: groupRes.Id,
                                GroupName: groupRes.Name,
                                GroupDescription: groupRes.Description,
                                GroupRetail: groupRes.Retail,
                                ProviderId: provider.id
                            };
                            if (data) {
                                group['Disabled'] = data.Disabled;
                            } else {
                                group['Disabled'] = false;
                            }
                            groups.push(group);
                        });
                    });
                });
            });
            setGroups(groups);
        });
    };

    const save = () => {
        //reduce cost of payload
        const data = groups.map(x => { return { groupId: x.GroupId, providerId: x.ProviderId, disabled: x.Disabled }; });

        API.post(PaymentProviders.WhiteListsGroupsSave, data)
            .then(() => {
                Popper.pop('information', 'Player Groups Whitelist saved!');
            });
    };

    const toggleDisabled = (row) => {
        var copy = groups;
        var field = copy.find(x => x.ProviderId == row.ProviderId && x.GroupId == row.GroupId);

        field['Disabled'] = !row.Disabled;
        setGroups(copy);
    };

    return <FluxCard className='scroll-enable payment-provider-white-list'
        title={<flex className='gap-10 fit'>
            <div></div>
            <flex className='center provider-selector'>
                <FluxInput
                    field={{
                        name: 'Provider',
                        title: 'Provider',
                        type: InputTypes.Select,
                        required: false,
                        nullable: true,
                        data: {
                            source: PaymentProviders.Get,
                            parameters: {},
                            parser: (result) => {
                                var results = [];
                                result.result.sort((a, b) => a.weight - b.weight).map(x => {
                                    results.push({ title: `${x.id} - ${x.display}`, value: x.id });
                                });
                                return results;
                            }
                        }
                    }}
                    onChange={(field, value) => {
                        selectProvider(value);
                    }}
                    data={{}}
                ></FluxInput>
            </flex>
        </flex>
        }>
        {!selectedProvider && < FluxNotice type={NotificationType.Information}
            title={'Choose Provider from above'}
            description='Please choose a provider from above to continue' />
        }
        {selectedProvider && <FluxCard
            buttons={<flex className='gap-5 align-right'>
                <Button title={showFiltersMenu ? 'Close Filters' : 'Open Filters'} onClick={() => toggleFilters(!showFiltersMenu)} />
                <Button className={'marginRight'} title='Add Group' onClick={() => showAddNewGroupModal(() => fetchAllDatas())} />
                <Button className={'success marginRight'} title='Save' onClick={() => save()} />
            </flex>}>
            {
                showFiltersMenu &&
                <flex className='padding gap-10 border'>
                    <flex className='padding border-bottom gap-10 fx-shadow' >
                        <span className='bold margin-end no-wrap'>{lang('Search in tree')}</span>
                        <input type='text' className='w-100' placeholder='Search' defaultValue={null}
                            onChange={(e) => setSearchFilter(e.target.value)} />
                    </flex>
                    <flex className={'padding border-bottom gap-10 fx-shadow'}>
                        <span className='bold'>{lang('Type')}</span>
                        <MultipleSelectInput
                            data={1}
                            field={field}
                            onChange={(a, b) => setOnlineFilter(b)} />
                    </flex>
                    <hr></hr>
                </flex>
            }
            <Table
                className={'fx-borders fx-shadow limits-table'}
                model={{
                    fields: [
                        { name: 'GroupId', },
                        { name: 'GroupName', title: 'Group Name' },
                        { name: 'GroupDescription', title: 'Group Description' },
                        {
                            name: 'GroupRetail',
                            title: 'Group Online/Retail',
                            type: InputTypes.Bool,
                            render: (val) => {
                                return <flex>
                                    <span>{lang(val ? 'Retail' : 'Online')}</span>
                                </flex>;
                            }
                        },
                        {
                            name: 'Disabled',
                            title: 'Disabled',
                            render: (val, row) => {
                                return <CheckBox
                                    key={row.ProviderId + '_' + row.GroupId}
                                    checked={val}
                                    onClick={() => toggleDisabled(row)}
                                />;
                            }
                        },
                    ]
                }}
                data={getData()} />
        </FluxCard>}
    </FluxCard>;
};