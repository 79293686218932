import React from 'react';
import { FluxCard } from '../../../../components/card/FluxCard';
import { lang } from '../../../../lib/Localization/language';
import { Currency } from '../../../../components/currency/Currency';

export const PlayerTransactionsPaymentProviders = (props) => {
    const { player } = { ...props };


    return <FluxCard title={'Payment Providers'}>
        <flex className='gap-10 fit'>
            <flex className='gap-10 columns-2 fit'>
                <span className='medium bold'>{lang('Deposit')}</span>
                <Currency amount={player?.totalProviderDeposit} sign={player.currency} />
            </flex>
            <flex className='gap-10 columns-2 fit col-med'>
                <span className='medium bold'>{lang('Total')}</span>
                <span>{player?.providerDepositCount}</span>
            </flex>
        </flex>
        <flex className='gap-10 fit'>
            <flex className='gap-10 columns-2 fit'>
                <span className='medium bold'>{lang('Withdraw')}</span>
                <Currency amount={player?.totalProviderWithdraw} sign={player.currency} />
            </flex>
            <flex className='gap-10 columns-2 fit col-med'>
                <span className='medium bold'>{lang('Total')}</span>
                <span>{player?.providerWithdrawCount}</span>
            </flex>
        </flex>
    </FluxCard>;
};
