export const InputTypes = {
    Text: 'text',
    Hidden: 'hidden',
    Email: 'email',
    RandomNumber: 'randomNumber',
    Textarea: 'textarea',
    Number: 'number',
    Select: 'select',
    Date: 'date',
    Bool: 'bool',
    MultipleSelect: 'multipleSelect',
    DateTime: 'datetime',
    Password: 'password',
    Tree: 'Tree',
    JSON: 'JSON',
    Currency: 'Currency',
    Custom: 'Custom'
};
