import './_riskLevelConfiguration.scss';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card } from '../../../../../v2/Components/Card/Card';
import { Icon } from '../../../icon/Icon';
import { HorseRiskLevelLimitsConfiguration } from './Components/HorseRiskLevelLimitsConfiguration';
import { HorseRiskLevelsCategories } from './Components/HorseRiskLevelsCategories';
import { HorseRiskLevelVenues } from './Components/HorseRiskLevelVenues';
import { HorseRiskLevelMarkets } from './Components/HorseRiskLevelMarkets';
import { lang } from '../../../../lib/Localization/language';
import { FluxTab } from '../../../tab/FluxTab';


export const convertToLevels = (level) => {
    var levels = {

    };
    levels['00'] = 'Low';
    levels['01'] = 'Low2';
    levels['02'] = 'Medium';
    levels['03'] = 'Medium2';
    levels['04'] = 'High';
    levels['05'] = 'High2';
    levels['06'] = 'High3';
    levels['07'] = 'High4';

    var result = [];
    for (var i = 0; i < level.length; i += 2) {
        result.push(levels[level.substr(i, 2)]);
    }

    return result;
};


export const SportRiskLevels = {
    'Low': 'Low',
    'Medium': 'Medium',
    'High': 'High',
};

export const LeagueLevels = {
    'Low': 'Low',
    'Low2': 'Low 2',
    'Medium': 'Medium',
    'Medium2': 'Medium2',
    'High': 'High',
    'High2': 'High2',
    'High3': 'High3',
    'High4': 'High4'
};

export const MarketLevels = {
    'Low': 'Low',
    'Medium': 'Medium',
    'High': 'High'
};

export const PlayerLevels = {
    'Low': 'Low',
    'Medium': 'Medium',
    'High': 'High',
};

export const RiskLevels = {
    Low: 'Low',
    Medium: 'Medium',
    High: 'High'
};


export const HorseRiskLevelsConfiguration = () => {
    const [sports, setSports] = useState(null);

    const user = useSelector((state) => state.user).loginInformation;
    const enabled = user.Configuration == undefined;

    useEffect(() => {
        setSports(
            [
                {
                    id: 0,
                    name: 'Horse'
                },
                {
                    id: 1,
                    name: 'Harness'
                },
                {
                    id: 2,
                    name: 'Greyhounds'
                }
            ]
        );
    }, []);


    if (!enabled) {
        return <Card title='Risk Levels'>
            <div className='flex padding gap-5'>
                <Icon icon='info-circle' /><p>{lang('Risk configuration is not enabled')}</p>
            </div>
        </Card>;
    }

    let tabButtons = [
        {
            name: 'limits',
            title: 'Limits'
        },
        {
            name: 'sport',
            title: 'Categories'
        },
        {
            name: 'league',
            title: 'Venues'
        },
        {
            name: 'bet',
            title: 'Bet Types'
        }
    ];

    return <flex className={`vertical gap-10 overflow-hidden ${!sports ? 'disabled' : ''}`}>
        {sports && <FluxTab buttons={tabButtons} className='overflow-hidden gap-10 fx-shadow fx-radius rounded-buttons'>
            <HorseRiskLevelLimitsConfiguration />
            {sports && <HorseRiskLevelsCategories sports={sports} />}
            {sports && <HorseRiskLevelVenues sports={sports} />}
            {sports && <HorseRiskLevelMarkets sports={sports} />}
        </FluxTab>
        }
    </flex>;
};