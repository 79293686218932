import React from 'react';
import { useSelector } from 'react-redux';
import { PlayerEndpoints } from '../../../lib/API/Endpoints';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { InputTypes } from '../../../components/form/InputTypes';


const model = {
    fields: [
        {
            name: 'PlayerId',
            hidden: true
        },
        {
            name: 'Activity',
            display: 'Activity',
            max: 255,
            type: InputTypes.Select,
            nullable: true,
            values: {
                'Login': 'Login',
                'Open Game': 'Open Game',
                'Open Crash Game': 'Open Crash Game',
                'Game': 'Game',
                'Sign out & login': 'Signed out due to new login',
                'Bet': 'External Withdraw',
                'Favourite Game': 'Toggle Favourite Game',
                'Logout': 'Logout',
                'Win': 'Bet',
                'Cancel Bet': 'Cancel Bet',
                'Launch Game External Api': 'Launch Game External Api',
                'RemoteGamingApi Game Launch': 'RemoteGamingApi Game Launch'
            }
        },
        {
            name: 'Ip',
            max: 255
        },
        {
            name: 'Token',
            max: 255
        }
    ]
};


const resultModel = {
    fields: [
        {
            name: 'Username',
        },
        {
            name: 'Created',
            type: InputTypes.DateTime
        },
        {
            name: 'Activity',
        },
        {
            name: 'Ip',
        },
        {
            name: 'Agent',
            display: 'Device',
            max: 255,
            render: (val) => {
                if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(val)) {
                    return 'tablet';
                }
                if (
                    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(val)
                ) {
                    return 'mobile';
                }
                return 'desktop';
            }
        },
        {
            name: 'Data'
        }
    ]
};
export const SearchPlayerActivity = (props) => {
    const { playerId } = { ...props };
    const playerActivity = useSelector((state) => state.data.playerActivity);


    if (!playerId) {
        return <></>;
    }


    return <FluxSearch key='activity'
        model={model}
        exportToExcel={false}
        resultModel={resultModel}
        data={playerActivity}
        actions={{ items: [] }}
        onPrepareModel={(model) => {
            var copy = { ...model };

            var playerField = copy.fields.find(x => x.name == 'PlayerId');
            playerField.hidden = true;
            playerField.value = playerId;
            copy.fields.find(x => x.name == 'PlayerId').hidden = true;
            copy.name = 'transactions-with-player-id';

            return copy;
        }}
        endpoint={PlayerEndpoints.SearchActivity}
    />;
};
