import './_configurator.scss';

import React, { useEffect, useState } from 'react';
import { lang } from '../../../../lib/Localization/language';
import { API } from '../../../../lib/API/Api';
import { HorseLimitsEndpoints } from '../../../../lib/API/Endpoints';
import { SportsBetTypes, SportsTicketTypes } from '../../../../lib/Enums/BettingEnums';

import { Button } from '../../../../components/button/Button';
import { Modal } from '../../../../components/application/FluxApp';
import { ConfirmationModal } from '../../../../components/modal/ConfirmationModal/ConfirmationModal';
import { FluxNotice } from '../../../../components/notification/FluxNotice';
import { NotificationType } from '../../../../components/notification/FluxNotification';
import { Popper } from '../../../../components/notification/FluxPopper';
import { ErrorModal } from '../../../../components/modal/ErrorModal/ErrorModal';
import { Table } from '../../../../components/table/Table';
import { ToggleButton } from '../../../../components/toggleButton/ToggleButton';
import { FluxCard } from '../../../../components/card/FluxCard';
import { FluxTab } from '../../../../components/tab/FluxTab';
import { FluxForm } from '../../../../components/form/FluxForm';
import { InputTypes } from '../../../../components/form/InputTypes';
import { useSelector } from 'react-redux';


const defaultFocus = (e) => {
    e.target.setAttribute('data-value', e.target.value);
    e.target.value = '';
};

const defaultBlur = (e) => {
    if (e.target.value == '') {
        e.target.value = e.target.getAttribute('data-value') ?? '';
    }
};

export const HorseLimitConfigurator = (props) => {
    const defaultCurrency = useSelector((state) => state.user.loginInformation.features.currency);

    const { limits, currencies, group, onUpdate, onUpdateBetSettings } = { ...props };

    const [selectedRiskLevel, selectRiskLevel] = useState(null);

    const [settings, setSettings] = useState(null);

    var riskLevels = [
        'low', 'medium', 'high'
    ];

    useEffect(() => {
        if (!selectedRiskLevel) {
            selectRiskLevel('low');
        }
    }, []);

    useEffect(() => {
        if (!selectedRiskLevel) return;
        prepareLimits();
    }, [group]);

    useEffect(() => {
        if (!selectedRiskLevel) return;
        prepareLimits();
    }, [selectedRiskLevel]);

    useEffect(() => {
        if (!selectedRiskLevel) return;
        onUpdate(selectedRiskLevel.toLowerCase(), settings);
    }, [settings]);

    const prepareLimits = () => {
        var limit = getLimit(selectedRiskLevel.toLocaleLowerCase());
        if (!limit) {
            limit = {
                limits: null,
                stopPrematchBetting: false,
                stopLiveBetting: false
            };
        }

        if (!limit.limits || limit.limits.length == 0) {
            limit.limits = [
                {
                    SlipType: 0,
                    TicketType: 0,
                    MinimumSelection: 1,
                    MaximumSelection: 0,
                    MinBet: 0,
                    MaxBet: 0,
                    MaxMultiplier: 0,
                    MaxPayout: 0,
                    Enabled: true
                },
                {
                    SlipType: 0,
                    TicketType: 1,
                    MinimumSelection: 2,
                    MaximumSelection: 0,
                    MinBet: 0,
                    MaxBet: 0,
                    MaxMultiplier: 0,
                    MaxPayout: 0,
                    Enabled: true
                },
                {
                    SlipType: 1,
                    TicketType: 0,
                    MinimumSelection: 1,
                    MaximumSelection: 0,
                    MinBet: 0,
                    MaxBet: 0,
                    MaxMultiplier: 0,
                    MaxPayout: 0,
                    Enabled: true
                },
                {
                    SlipType: 1,
                    TicketType: 1,
                    MinimumSelection: 2,
                    MaximumSelection: 0,
                    MinBet: 0,
                    MaxBet: 0,
                    MaxMultiplier: 0,
                    MaxPayout: 0,
                    Enabled: true
                },
                {
                    SlipType: 2,
                    TicketType: 0,
                    MinimumSelection: 2,
                    MaximumSelection: 0,
                    MinBet: 0,
                    MaxBet: 0,
                    MaxMultiplier: 0,
                    MaxPayout: 0,
                    Enabled: true
                },
                {
                    SlipType: 2,
                    TicketType: 1,
                    MinimumSelection: 2,
                    MaximumSelection: 0,
                    MinBet: 0,
                    MaxBet: 0,
                    MaxMultiplier: 0,
                    MaxPayout: 0,
                    Enabled: true
                }
            ];
        }

        limit.limits.map(x => {
            x.TicketType = parseInt(x.TicketType);
            x.SlipType = parseInt(x.SlipType);
            x.MinimumSelection = x.TicketType == 0 ? 1 : parseInt(x.MinimumSelection);
            x.MaximumSelection = parseFloat(x.MaximumSelection);
            x.MinBet = parseFloat(x.MinBet);
            x.MaxBet = parseFloat(x.MaxBet);
            x.MaxMultiplier = parseFloat(x.MaxMultiplier);
            x.MaxPayout = parseFloat(x.MaxPayout);
            x.Enabled = x.Enabled === undefined ? true : x.Enabled;
        })

        limit.key = Date.now();
        if (limit.disabled === undefined) limit.disabled = false;

        setSettings(limit);
    };

    const getLimit = (level) => {
        var limit = null;

        if (!group) {
            limit = limits.general[level.toLowerCase()] ? limits.general[level.toLowerCase()] : null;
        } else {
            limit = limits.groups[group.value] ? (limits.groups[group.value][level.toLowerCase()]) : null;
        }

        return limit;
    };


    const context = {
        update: (e, row) => {
            var copy = { ...settings };
            var field = copy.limits.find(x => x.SlipType == row.SlipType && x.TicketType == row.TicketType);
            var value = e.target.value;
            if (e.target.value == field[e.target.name]) return;
            if (e.target.type == 'number') {
                value = parseFloat(value);
            }
            field[e.target.name] = value;
            setSettings(copy);
        },
        deleteRow: (row) => {
            var copy = { ...settings };
            var r = copy.limits.find(x => x.SlipType == row.SlipType && x.TicketType == row.TicketType);
            r.MinBet = 0;
            r.MaxBet = 0;
            r.MaxMultiplier = 0;
            r.MaximumSelection = 0;
            r.MinimumSelection = 0;
            r.MaxPayout = 0;
            r.Enabled = true;
            copy.key = Date.now();
            setSettings(copy);
        }
    };


    const save = () => {
        var title = 'Updating Sports General limits.';
        var description = 'Please confirm updating sports general limits, the settings will apply default & group configurations.'
        var copy = { ...limits };
        var limitLookup = {};
        riskLevels.map(x => limitLookup[x] = true);
        Object.keys(limits.general).map(x => {
            if (!limitLookup[x]) {
                delete copy.general[x];
            }
        });

        Object.keys(limits.groups).map(gId => {
            var g = limits.groups[gId];
            Object.keys(g).map(x => {
                if (!limitLookup[x]) {
                    delete limits.groups[gId][x];
                }
            });
        });


        const deleteEmpty = (target) => {

            Object.keys(target).map(x => {

                var limits = target[x].limits;
                var allClear = true;


                limits.map(r => {
                    if (r.MinBet === 0 &&
                        r.MaxBet === 0 &&
                        r.MaxMultiplier === 0 &&
                        r.MaximumSelection === 0 &&
                        r.MinimumSelection === 0 &&
                        r.MaxPayout === 0 && r.Enabled) {
                        //
                    } else {
                        allClear = false;
                    }
                });

                if (allClear) delete target[x];
            });
        };
        deleteEmpty(copy.general);


        Object.keys(copy.groups).map(groupId => {
            var group = copy.groups[groupId];
            deleteEmpty(group, true);
            if (Object.keys(group).length == 0) {
                delete copy.groups[groupId];
            }
        });


        Modal.open(<ConfirmationModal title='Save limits' onConfirm={() => {
            API.post(HorseLimitsEndpoints.Save, copy).then(() => {
                Popper.pop('information', 'Saved', 'General sport limits saved.')
            }).catch((error) => {
                var message = error?.error?.message ?? 'Service error. Please try later!';
                Modal.open(<ErrorModal title='Unable to save limits'><FluxNotice type={NotificationType.Error} title='Operation failed!' description={message} /></ErrorModal>);
            });
        }}>
            <FluxNotice type={NotificationType.Information} title={title} description={description} />
        </ConfirmationModal>)
    };

    if (!limits) return <></>;
    if (!selectedRiskLevel) return <></>;
    if (!settings) return <></>;



    var tabButtons = [];

    riskLevels.map(x => tabButtons.push({ title: x.toUpperCase() }));
    return <flex className='vertical gap-5 sport-limits-configurator' key={limits}>
        {
            group && <flex className='border radius fx-shadow padding surface'>
                <label className='medium bold'>{lang('%1 group configuration', [group.title])}</label>
                <Button title='Save' className={'align-right success'} onClick={() => save()} />
            </flex>
        }
        {
            !group && <flex className='border radius fx-shadow padding surface'>
                <label className='medium bold'>{lang('No group selected, settings will apply to all groups without specific configuration.')}</label>
                <Button title='Save' className={'align-right success'} onClick={() => save()} />
            </flex>
        }

        <FluxCard title='Bet Configuration' className={'no-padding overflow-y-scroll'}
            buttons={<flex className='align-right'><span className='bold'>{lang('Default currency for limits %1', [defaultCurrency])}</span></flex>}>
            <FluxTab buttons={tabButtons} className='gap-10 rounded-buttons' onChange={(index) => {
                selectRiskLevel(riskLevels[index]);
            }} />
            <flex className='padding gap-10 border fit'>
                {(settings.disabled === undefined || settings.disabled === false) && <Button title='Disable' className={'warning'} onClick={() => {
                    var copy = { ...settings };
                    copy.disabled = true;
                    setSettings(copy);

                }} />}

                {(settings.disabled === true) && <Button title='Enable' className={'success'} onClick={() => {
                    var copy = { ...settings };
                    copy.disabled = false;
                    setSettings(copy);

                }} />}

                <Button title='Reset' className={'warning'} onClick={() => {
                    var copy = { ...settings };
                    Object.values(copy.limits).map(r => {
                        r.MinBet = 0;
                        r.MaxBet = 0;
                        r.MaxMultiplier = 0;
                        r.MaximumSelection = 0;
                        r.MinimumSelection = 0;
                        r.MaxPayout = 0;
                        r.Enabled = true;
                    });

                    copy.disabled = false;
                    setSettings(copy);

                }} />
            </flex>
            <Table
                className={'fx-borders fx-shadow limits-table'}
                key={settings.key}
                context={context}
                buttons={(row, c) => <flex><Button title='Delete' className={'warning'} onClick={() => {
                    c.deleteRow(row);
                }} /></flex>}
                model={{
                    fields:
                        [
                            {
                                name: 'SlipType',
                                title: 'Slip Type',
                                render: (val) => SportsBetTypes[parseInt(val)]
                            },
                            {
                                name: 'TicketType',
                                title: 'Ticket Type',
                                render: (val) => SportsTicketTypes[val]
                            },
                            {
                                name: 'MinimumSelection',
                                title: 'Minimum Selection',
                                render: (val, row, field, context) => row.TicketType == 0 ? <span className='white-space-break-spaces'>{lang('Minimum selection for singles is 1.')}</span> : <input type='number' name={field.name} defaultValue={val} onBlur={defaultBlur} onFocus={defaultFocus} onChange={(e) => context.update(e, row)} />
                            },
                            {
                                name: 'MaximumSelection',
                                title: 'Maximum Selection',
                                render: (val, row, field, context) => row.TicketType == 0 ? <span className='white-space-break-spaces'>{lang('Maximum selection for singles is 1.')}</span> : <input type='number' name={field.name} defaultValue={val} onBlur={defaultBlur} onFocus={defaultFocus} onChange={(e) => context.update(e, row)} />
                            },
                            {
                                name: 'MinBet',
                                title: 'Min Bet',
                                render: (val, row, field, context) => <input type='number' name={field.name} defaultValue={val} value={val} onBlur={defaultBlur} onFocus={defaultFocus} onChange={(e) => context.update(e, row)} />
                            },
                            {
                                name: 'MaxBet',
                                title: 'Max Bet',
                                render: (val, row, field, context) => <input type='number' name={field.name} defaultValue={val} value={val} onBlur={defaultBlur} onFocus={defaultFocus} onChange={(e) => context.update(e, row)} />
                            },
                            {
                                name: 'MaxMultiplier',
                                title: 'Max Multiplier',
                                render: (val, row, field, context) => <input type='number' name={field.name} defaultValue={val} value={val} onBlur={defaultBlur} onFocus={defaultFocus} onChange={(e) => context.update(e, row)} />
                            },
                            {
                                name: 'MaxPayout',
                                title: 'Max Payout',
                                render: (val, row, field, context) => <input type='number' name={field.name} defaultValue={val} value={val} onBlur={defaultBlur} onFocus={defaultFocus} onChange={(e) => context.update(e, row)} />
                            },
                            {
                                name: 'Enabled',
                                title: 'Enabled',
                                render: (val, row, field, context) => <ToggleButton value={val === undefined ? true : val} onBlur={defaultBlur} onFocus={defaultFocus} onChange={(v) => {
                                    context.update({ target: { name: field.name, value: v } }, row);
                                }} />
                            }
                        ]
                }} data={settings.limits} />
        </FluxCard>
        <FluxCard
            key={selectedRiskLevel + '_' + (group?.value ?? '_groups')}
            title={lang('Bet Settings for players inside %1 with %2', [group?.title ? `${group.title} group` : 'all groups', `${selectedRiskLevel} level of risk`])}>
            <FluxForm data={{
                stopPrematchBetting: settings.stopPrematchBetting ?? false,
                stopLiveBetting: settings.stopLiveBetting ?? false
            }} model={{
                fields: [
                    {
                        name: 'stopPrematchBetting',
                        title: 'Stop Prematch',
                        type: InputTypes.Bool
                    },
                    {
                        name: 'stopLiveBetting',
                        title: 'Stop Live',
                        type: InputTypes.Bool
                    }
                ]
            }} columns={3} buttons={null} onChange={(d) => {
                var copy = { ...settings };
                copy.stopPrematchBetting = d.stopPrematchBetting;
                copy.stopLiveBetting = d.stopLiveBetting;
                setSettings(copy);
            }} />
        </FluxCard>
    </flex >;
};