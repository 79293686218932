import './_notification.scss';

import React, { useEffect, useState } from 'react';
import { FluxNotice } from './FluxNotice';

export var Popper = {
    pop: (type, title, message) => {
        console.log(message);
    }
};

var popperIndex = 0;

export const FluxPopper = () => {
    const [notifications, setNotifications] = useState([]);

    const popperRemoveItem = (x) => {
        var copy = notifications?.filter((item) => item.index != x.index);
        copy.pop();
        setNotifications(copy);
    };

    const notificationContext = {
        pop: (type, title, message) => {
            var copy = [...notifications];
            popperIndex++;
            copy.push({ type: type, title: title, message: message, index: popperIndex });
            setNotifications(copy);
            setTimeout(() => {
                try {
                    var copy = [...notifications];
                    copy.pop();
                    setNotifications(copy);
                } catch (err) {
                    console.log(err);
                }
            }, 5000);
        }
    };

    useEffect(() => {
        Popper = notificationContext;
    }, []);

    return <popper>
        {notifications.map(x => {
            return <flex key={x} className='fit-children fx-shadow popper-box' onClick={() => popperRemoveItem(x)}>
                <FluxNotice type={x.type} title={x.title} description={x.message} small />
            </flex>;
        })}
    </popper>;
};
