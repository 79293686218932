import './_tab.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { hasAccess } from '../../lib/ActionManager/FluxActions';
import { lang } from '../../lib/Localization/language';

export const FluxTab = (props) => {
    const [selectedIndex, setIndex] = useState(props.selected ?? 0);

    useEffect(() => {
        setIndex(props.selected ?? 0);
    }, [props.selected])

    useEffect(() => {
        if (selectedIndex === null) return;
        if (props.onChange) {
            props.onChange(selectedIndex);
        }
    }, [selectedIndex]);

    return <tab className={props.className ?? ''}>
        <buttons>
            {props.buttons.map((x, index) => {
                if (x.hidden) return;
                var disabled = x.disabled || (x.action && !hasAccess(x.action));
                return <tabbutton className={`${selectedIndex == index ? 'active' : ''}${disabled ? ' disabled' : ''}`} key={x} onClick={() => setIndex(index)}><span>{lang(x.title)}</span></tabbutton>;
            })}
            {props.action ? props.action() : null}
        </buttons>
        {props.children?.length ? <content>{props.children[selectedIndex]}</content> : props.children}
    </tab>;
};

FluxTab.propTypes = {
    className: PropTypes.string,
    action: PropTypes.any,
    selected: PropTypes.number,
    buttons: PropTypes.array,
    children: PropTypes.node,
    onChange: PropTypes.func
};
