import React from 'react';
import { FluxTab } from '../../../components/tab/FluxTab';
import { GeneralSettings } from './Components/GeneralSettings';
import { SportsSettings } from './Components/SportsSettings';
import { PromotionCodeSettings } from './Components/PromotionCodeSettings';
import { FreeBetPrizeSettings } from './Components/FreeBetPrizeSettings';

export const FreeBetBonus = (props) => {
    var buttons = [
        {
            title: 'General Settings'
        },
        {
            title: 'Code'
        },
        {
            title: 'Prize Settings'
        },
        {
            title: 'Sports'
        }
    ];


    return <FluxTab buttons={buttons} className='gap-10 fx-shadow fx-radius rounded-buttons'>
        <GeneralSettings {...props} description='A FreeBet bonus allows players to place bets without using their own money. The platform assigns a specific amount as a FreeBet, which players can use under defined rules.' />
        <PromotionCodeSettings {...props} />
        <FreeBetPrizeSettings {...props}/>
        <SportsSettings {...props} enabled/>
    </FluxTab>;
};