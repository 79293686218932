import './_dateTime.scss';
import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { DateHelpers } from '../../../flux/lib/Helpers/DateHelpers';

export class DateTime extends Component {
    constructor(props) {
        super(props);
        this.state = Object.assign({}, props);
    }

    dateFormatter = (value) => {
        if (value && !value.endsWith('Z')) {
            value += 'Z';
        }
        var date = new Date(value);

        if (this.props.convert) {
            var converted = DateHelpers.addHours(date, window.user.timeZoneConverted + (-1 * DateHelpers.offset()));
            return converted;
        }
        return date;
    };

    render() {
        var classNames = ['dateTime', this.props.size];
        classNames.push(this.props.className);
        var date = this.props.date;
        var converted = null;
        if (!date || date == '0001-01-01T00:00:00') {
            converted = '-';
        } else {
            //1/1/1, 24:00:00
            converted = this.dateFormatter(this.props.date);
            if (converted.getFullYear() == 1) {
                converted = '-';
            } else {
                if (this.props.includeTime) {
                    converted = converted.toLocaleString('en-Gb', { hour12: false });
                } else {
                    converted = converted.toLocaleString('en-Gb', { year: 'numeric', month: '2-digit', day: '2-digit' });
                }
            }
        }
        return <div className={classNames.join(' ')}>
            <span>{converted}</span>
        </div>;
    }
}

DateTime.defaultProps = {
    className: null,
    size: '',
    date: new Date(),
    convert: true,
    includeTime: true
};

DateTime.propTypes = {
    className: PropTypes.string,
    size: PropTypes.string,
    date: PropTypes.string.isRequired,
    convert: PropTypes.bool,
    includeTime: PropTypes.bool,
};
