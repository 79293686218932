import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Icon } from '../../icon/Icon';
import { lang } from '../../../lib/Localization/language';
import { FluxNotice } from '../../notification/FluxNotice';
import { NotificationType } from '../../notification/FluxNotification';
import { Button } from '../../button/Button';
import { MultipleSelectInput } from '../../form/Components/MultipleSelectInput';
import { API } from '../../../lib/API/Api';
import { Modal } from '../../application/FluxApp';
import { FluxModal } from '../../modal/FluxModal';
import { FluxCard } from '../../card/FluxCard';
import { FluxForm } from '../../form/FluxForm';
import { PlayerEndpoints } from '../../../lib/API/Endpoints';
import { toCamelCase } from '../../../../components/Centrum/helpers';


const sortGroups = (a, b) => a.Name > b.Name ? 1 : -1;
const field = {
    values: {
        All: 1,
        Online: 2,
        Retail: 3
    },
    value: 1
};

const filterByText = (text, group) => {
    const matchesGroup = group.Name.toLowerCase().includes(text) || group.Id == text;
    const matchesAnyChild = group.childs && group.childs.some(child => filterByText(text, child));
    return matchesGroup || matchesAnyChild;
};

const filterGroups = (groups, text) => {
    return groups.filter(group => filterByText(text, group)).map(group => ({
        ...group,
        childs: group.childs ? filterGroups(group.childs, text) : []
    }));
};

export const PlayerGroupList = (props) => {
    const { disableDefaultGroup } = props;
    const [group, selectGroup] = useState(null);
    const [groups, setGroups] = useState([]);
    const [filteredGroups, setFilteredGroups] = useState([]);
    const [showFiltersMenu, toggleFilters] = useState(false);
    const [searchFilter, setSearchFilter] = useState('');
    const [onlineFilter, setOnlineFilter] = useState(1);
    const [expandedItems, setExpandedItems] = useState({});

    useEffect(() => {
        if (!groups || groups.length === 0) {
            fetchGroups();
        }
    }, [window.location]);

    useEffect(() => {
        if (!groups || groups.length === 0) {
            return;
        }
        var res = groups;
        if (onlineFilter === 2) {
            res = res.filter(x => x.Retail == false);
        } else if (onlineFilter === 3) {
            res = res.filter(x => x.Retail == true);
        }

        if (searchFilter) {
            var text = searchFilter.toLowerCase();
            res = filterGroups(res, text);
        }

        setFilteredGroups(res);
    }, [searchFilter, onlineFilter, groups]);

    const fetchGroups = () => {
        API.post(PlayerEndpoints.PlayerGroupsWithRelation, {}, null, null, false, null, false).then((result) => {
            var res = convertToParentChildList(result.result);

            var sorted = res.sort((a, b) => sortGroups(a, b));
            setGroups(sorted);
        });
    };

    const toggleOpen = (item) => {
        var copy = { ...expandedItems };
        if (copy[item.Id]) {
            delete copy[item.Id];
        } else {
            copy[item.Id] = true;
        }
        setExpandedItems(copy);
    };


    const renderItem = (item) => {
        var found = expandedItems[item.value];
        return <React.Fragment key={item}>
            <item className={`${found ? 'found' : ''} ${group == item ? ' selected' : ''}`} onClick={() => {
                if (item) {
                    selectGroup(item);
                    props.onSelectGroup(item);
                }
            }}>
                <flex className='gap-10'>
                    <label>{item.value}</label>
                    {item.Retail ?
                        <flex className='gap-5'><span className='bold'>{lang('Retail')}</span><Icon icon='chevron-right' size='xs' /><span>{item.Name}</span></flex> : <span>{item.Name}</span>
                    }

                    {item.childs && item.childs.length > 0 && <flex onClick={() => toggleOpen(item)} className='align-right'><Icon icon={expandedItems[item.value] ? 'square-minus' : 'square-plus'} type='fa-light' /></flex>}
                </flex>
            </item>
            {
                item.childs && expandedItems[item.value] && item.childs.length > 0 && <items>{item.childs.map(childItem => {
                    return renderItem(childItem);
                })
                }</items>
            }
        </React.Fragment>;
    };

    const convertToParentChildList = (flatList) => {
        const map = new Map();
        flatList.forEach(item => {
            var res = toCamelCase(item);
            res['value'] = res.Id;
            res['title'] = res.Name;
            const parentId = res.ParentGroupId;
            if (parentId === null) {
                res.childs = [];
                map.set(res.Id, res);
            } else {
                const parent = map.get(parentId);
                if (parent) {
                    if (!parent.childs) {
                        parent.childs = [];
                    }
                    res.childs = [];
                    parent.childs.push(res);
                    map.set(res.Id, res);
                } else {
                    const newres = {
                        id: parentId,
                        childs: [res]
                    };
                    res.childs = [];
                    map.set(parentId, newres);
                    map.set(res.Id, res);
                }
            }
        });
        return Array.from(map.values()).filter(item => item.ParentGroupId === null);
    };

    const showAddNewGroupModal = (onSaveComplete) => {
        Modal.open(<FluxModal title={'Add New group'} >
            <FluxCard>
                <FluxForm
                    model={{
                        fields: [
                            {
                                name: 'Name',
                                title: 'Name',
                                required: true
                            },
                            {
                                name: 'Description',
                                title: 'Description',
                                required: true
                            }
                        ]
                    }}
                    endpoint={PlayerEndpoints.SavePlayerGroup}
                    confirm={true}
                    confirmTitle='Create new group'
                    confirmMessage='Please confirm before creating new group'
                    onCancel={() => {
                        onSaveComplete();
                    }}
                    onSubmitComplete={() => {
                        onSaveComplete();
                    }} />
            </FluxCard>
        </FluxModal >);
    };

    const getTitle = () => {
        if (!disableDefaultGroup) {
            return !group ? 'Default settings selected!' : 'Group settings selected!';
        } else {
            return !group ? 'No group selected' : 'Group settings selected!';
        }
    };
    const getDescription = () => {
        if (!disableDefaultGroup) {
            return !group ? 'Settings used if no specific group has configuration.' : 'Overrides default configuration.';
        } else {
            return !group ? 'Please choose a group.' : '';
        }

    };
    return <div>
        {
            (!disableDefaultGroup || !group) && <flex className='padding vertical gap-10'>
                <FluxNotice type={NotificationType.Information}
                    title={getTitle()}
                    description={getDescription()} />
                {!disableDefaultGroup && group != null && <Button title='Select default settings' onClick={() => { selectGroup(null); props.onSelectGroup(null); }} className='center success' />}
            </flex>
        }
        
        <flex className='gap-5 align-items-center justify-content-between'>
            <label className='padding medium border-bottom'>{lang('Groups')}</label>
            <flex className='gap-5'>
                <Button className={'success'} title={showFiltersMenu ? 'Close Filters' : 'Open Filters'} onClick={() => toggleFilters(!showFiltersMenu)} />
                <Button className={'success marginRight'} title='Add' onClick={() => showAddNewGroupModal(() => fetchGroups())} />
            </flex>
        </flex>
        {
            showFiltersMenu &&
            <flex className='vertical padding gap-5 border'>
                <flex className='padding border-bottom fit-children fx-shadow' >
                    <span className='bold'>{lang('Search')}</span>
                    <input type='text' className='w-100' placeholder='Search' defaultValue={null}
                        onChange={(e) => setSearchFilter(e.target.value)} />
                </flex>
                <flex className={'padding border-bottom fit-children fx-shadow'}>
                    <span className='bold'>{lang('Type')}</span>
                    <MultipleSelectInput
                        data={1}
                        field={field}
                        onChange={(a, b) => setOnlineFilter(b)} />
                </flex>
                <hr></hr>
            </flex>
        }
        <fluxList className='flux-tree'>
            <items>
                {
                    filteredGroups.map(x => {
                        return renderItem(x);
                    })
                }
            </items>
        </fluxList>
    </div >;
};

PlayerGroupList.defaultProps = {
    disableDefaultGroup: false
};

PlayerGroupList.propTypes = {
    onSelectGroup: PropTypes.func,
    disableDefaultGroup: PropTypes.bool,
};