import React from 'react';
import { appHistory, getStore } from '../../..';
import { Modal, Overlay } from '../../components/application/FluxApp';
import { ErrorModal } from '../../components/modal/ErrorModal/ErrorModal';
import { BusyOverlay } from '../../components/modal/Overlays/BusyOverlay';
import { FluxNotice } from '../../components/notification/FluxNotice';
import { NotificationType } from '../../components/notification/FluxNotification';


const prepareRequestParameters = (...props) => {
    const url = props[0];
    const data = props[1];
    const title = props[2];
    const error = props[3];
    const type = props[4];
    const dataName = props[5];
    const cache = props[6];
    const background = props[7];

    return {
        url: url,
        data: data,
        title: title,
        error: error,
        type: type,
        dataName: dataName,
        cache: cache,
        background: background
    };
};



var cachedData = {

};


export const ChainRequest = (requests, onComplete) => {
    var left = [...requests];
    var data = {};
    const start = () => {
        if (left.length == 0) {
            onComplete(data);
            return;
        }
        var req = left.pop();
        API.post(req.endpoint, req.payload ?? null).then((result) => {
            data[req.key] = result.result;
            start();
        });
    };
    start();
};

export const API = {
    get: (...props) => {
        var p = prepareRequestParameters(...props);
        return API.request(p.url, p.data, p.title, p.error, 'GET', false, p.dataName);
    },
    post: (...props) => {
        var p = prepareRequestParameters(...props);
        return API.request(p.url, p.data, p.title, p.error, 'POST', false, p.dataName, p.cache, p.background);
    },
    raw: (...props) => {
        var p = prepareRequestParameters(...props);
        return API.request(p.url, p.data, p.title, p.error, 'GET', true);
    },
    request: (url, data, title, error, type, raw, dataName, cache, background) => {
        if (cache) {
            if (cachedData[url]) {
                return new Promise((resolve) => {
                    resolve(cachedData[url].data);
                });
            }
        }
        if (!background) {
            Overlay.open(<BusyOverlay title={title ?? 'loading'} />);
        }
        getStore().dispatch({
            type: 'REQUEST',
            payload: url
        });
        if (dataName) {
            getStore().dispatch({
                type: 'LOAD_DATA',
                payload: {
                    type: `${dataName}`,
                    title: title
                }
            });
        }
        return new Promise((resolve, reject) => {
            try {
                var options = {
                    method: type,
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Timezone-Offset': new Date().getTimezoneOffset(),
                        'X-Selected-TimeZone-Offset': window.user?.timeZoneConverted
                    },
                    credentials: 'same-origin'
                };

                if (!raw) options.headers['Accept'] = 'application/json';
                if (data) options.body = JSON.stringify(data);

                fetch(url, options)
                    .then((res) => {
                        if (res.status == 204) {
                            return {
                                status: 0,
                                error: {
                                    message: 'Service is not responding / timeout.'
                                }
                            };
                        }
                        if (res.status == 400) {
                            return {
                                status: 0,
                                error: {
                                    message: 'Bad request.'
                                }
                            };
                        }
                        if (res.status == 415) {
                            return {
                                status: 0,
                                error: {
                                    message: 'Unsupported request.'
                                }
                            };
                        }
                        if (res.status == 500) {
                            return {
                                status: 0,
                                error: {
                                    message: 'API Error.'
                                }
                            };
                        }

                        if (res.status == 404) {
                            return {
                                status: 0,
                                error: {
                                    message: 'Service not found.'
                                }
                            };
                        }
                        try {
                            if (raw) return res.text();
                            var converted = res.json();
                            return converted;
                        } catch (err) {
                            return {
                                status: 0,
                                result: res,
                                error: {
                                    message: 'Corrupted data.'
                                }
                            };
                        }
                    })
                    .then(
                        (result) => {
                            if (!background) {
                                Overlay.close();
                            }
                            try {
                                if (result.toString().indexOf('Unauthorized Player Access') >= 0) {
                                    window.location.href = '/';
                                    return;
                                }
                            } catch (err) {
                                //
                            }

                            if (result && !result.status) {
                                throw result;
                            }
                            if (!result || !result.status) {
                                throw result;
                            }
                            if (result.status != 0 && result.staus != 1) {
                                if (result.status == 204) {
                                    throw 'Service is not responding / timeout.';
                                }
                                if (result.status == 400) {
                                    throw 'Bad request.';
                                }
                                if (result.status == 415) {
                                    throw 'Unsupported request.';
                                }
                                if (result.status == 500) {
                                    throw 'API Error.';
                                }
                                if (result.status == 404) {
                                    throw 'Service not found.';
                                }
                            }

                            if (dataName) {
                                getStore().dispatch({
                                    type: 'DATA_LOADED',
                                    payload: {
                                        type: dataName,
                                        payload: result
                                    }
                                });
                            }
                            if (cache) {
                                cachedData[url] = {
                                    data: result
                                };
                            }
                            resolve(result);
                        }
                    ).catch(e => {
                        if (e?.error?.message == 'Invalid token') {
                            appHistory.push('/login');
                        }
                        var errorMessage = null;
                        if (e.error) {
                            //
                            if (e.error.message) {
                                errorMessage = e.error.message;
                            }
                        } else if (typeof e === 'object' && e !== null) {
                            errorMessage = e.toString();
                        }

                        if (dataName) {
                            getStore().dispatch({
                                type: 'LOAD_DATA',
                                payload: {
                                    type: `${dataName}_loading`,
                                    payload: false
                                }
                            });
                        }
                        if (error) {
                            if (!background) {
                                Overlay.close();
                            }
                            Modal.open(<ErrorModal title='Error'>
                                <FluxNotice type={NotificationType.Error} title={error} description={errorMessage ?? 'Service error please try later.'} />
                            </ErrorModal>);
                            return;
                        }
                        reject(e);
                    });
            } catch (err) {
                reject('error');
            }
        });
    }
};

