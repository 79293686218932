/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import { FluxCard } from '../../card/FluxCard';
import { SportsTicketStatus } from '../../../lib/Enums/BettingEnums';
import { lang } from '../../../lib/Localization/language';
import { Icon } from '../../icon/Icon';
import { Table } from '../../table/Table';
import { cancelTransaction } from '../../../actions/TransactionActions';
import { Currency } from '../../currency/Currency';
import { Button } from '../../button/Button';
import { DateTime } from '../../datetime/DateTime';
import { openSportTicketDetails } from '../../../actions/TicketActions';
import './_sport-ticket-history.scss';

export const SportTicketHistory = (props) => {
    const { ticket } = { ...props };

    return <FluxCard title='Ticket History' className='no-padding' toggleBody>
        <Table
            className='no-borders sport-ticket-history-table'
            context={{
                currency: props.currency
            }}
            model={{
                fields: [
                    {
                        name: 'entryDate',
                        title: 'Date',
                        render: (val) => {
                            return <DateTime date={val} time vertical seconds />;
                        }
                    },
                    {
                        name: 'transactionId',
                        title: 'Transaction Id'
                    },
                    {
                        name: 'type',
                        title: 'Action',
                        render: (val, row) => {
                            var before = SportsTicketStatus[row.typeBefore];
                            var now = SportsTicketStatus[row.type];
                            if (row.typeBefore == 0 && row.type == 0) {
                                if (row.reopen === true) {
                                    return <span>{lang('Ticket Re-Open')}</span>;
                                }
                                return <span>{lang('Ticket Created')}</span>;
                            }
                            return <flex className='gap-5'>
                                <span>{before}{row.resettlement && ' (Resettle)'}</span>
                                <Icon icon='arrow-right-long' type='fa-solid' size='xs' />
                                <span>{now}</span>
                            </flex>;
                        }
                    },
                    {
                        name: 'amount',
                        title: 'Amount',
                        render: (val, row, field, context) => {
                            if (val == 0) return;
                            return <Currency amount={val} sign={context.currency} />;
                        }
                    },
                    {
                        name: 'admin',
                        title: 'Admin'
                    },
                    {
                        name: 'notes',
                        title: 'Notes'
                    },
                    {
                        name: 'ticket-transaction-operations',
                        title: 'Status',
                        render: (val, row) => {
                            if (row.transactionStatus == 2) {
                                return 'Cancelled';
                            }
                            if (!row.transactionId) return;
                            return <Button title='Cancel' className='warning hidden' onClick={() => {
                                var type = 3;
                                cancelTransaction({ id: row.transactionId, amount: row.amount, currency: props.currency }, type, () => {

                                    openSportTicketDetails(ticket.externalTicketId, false, props.onChange);
                                });
                            }} />;
                        }
                    }
                ]
            }} data={props.history.sort((a, b) => new Date(a.entryDate) - new Date(b.entryDate))}
        />
    </FluxCard>;
};

SportTicketHistory.propTypes = {
    ticket: PropTypes.object,
    history: PropTypes.object,
    currency: PropTypes.string,
    onChange: PropTypes.func
};