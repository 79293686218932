import React from 'react';
import { FluxCard } from '../../../../components/card/FluxCard';
import { lang } from '../../../../lib/Localization/language';
import { Currency } from '../../../../components/currency/Currency';

export const PlayerSportBetInformation = (props) => {
    const { player, stats } = { ...props };

    return <FluxCard title={'Sports'} buttons={<flex className='align-right gap-5'><span className='bold'>{lang('Total Tickets')}</span><span>{stats?.totalSportTickets}</span></flex>}>
        <flex className='gap-10 columns-2 fit'>
            <span className='medium bold'>{lang('Overall Bet')}</span>
            <Currency amount={stats?.totalSportBet} sign={player.currency} />
        </flex>
        <flex className='gap-10 columns-2 fit'>
            <span className='medium bold'>{lang('Overall Win')}</span>
            <Currency amount={stats?.totalSportWin} sign={player.currency} />
        </flex>
        <flex className='gap-10 columns-2 fit'>
            <span className='medium bold'>{lang('Profit')}</span>
            <Currency amount={stats?.totalSportBet - stats?.totalSportWin} sign={player.currency} />
        </flex>
    </FluxCard>;
};
