import React from 'react';
import { FluxTab } from '../../../../../components/tab/FluxTab';
import { PromotionGeneralSettings } from '../PromotionGeneralSettings';
import { PromotionGroupSettings } from '../PromotionGroupSettings';
import { PromotionCodeSettings } from '../PromotionCodeSettings';
import { SportsSettings } from '../SportsSettings';
import { SportsTournamentRules } from '../SportsTournamentRules';



export const SportTournament = (props) => {
    var buttons = [
        {
            title: 'General Settings'
        },
        {
            title: 'Group Settings'
        },
        {
            title: 'Code'
        },
        {
            title: 'Betting Rules'
        },
        {
            title: 'Tournament Rules'
        }
    ];

    return <FluxTab buttons={buttons} className='gap-10 fx-shadow fx-radius rounded-buttons campaign-settings'>
        <PromotionGeneralSettings {...props} />
        <PromotionGroupSettings {...props} />
        <PromotionCodeSettings {...props} />
        <SportsSettings {...props} enableMinimumStake/>
        <SportsTournamentRules {...props} />
    </FluxTab>;
};