import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { InputTypes } from '../../../../v2/Components/Controls/Form/FormInput';
import { AnnouncementEndpoints, API } from '../../../../v2/Lib/Api/Api';
import { Modal } from '../../../components/application/FluxApp';
import { Button } from '../../../components/button/Button';
import { FluxCard } from '../../../components/card/FluxCard';
import { Icon } from '../../../components/icon/Icon';
import { ConfirmationModal } from '../../../components/modal/ConfirmationModal/ConfirmationModal';
import { Table } from '../../../components/table/Table';
import { PlayerEndpoints } from '../../../lib/API/Endpoints';
import { lang } from '../../../lib/Localization/language';


const notificationsModel = {
    fields: [
        {
            name: 'Id',
            title: 'Id'
        },
        {
            name: 'EntryDate',
            title: 'Entry Date',
            type: InputTypes.DateTime
        },
        {
            name: 'Groups',
            title: 'Groups',
            render: (value, row, field, context) => {

                if (row.Groups) {

                    var elements = row.Groups.split(',').map(x => {
                        var group = context.groups.find(y => y.Id == parseInt(x));
                        if (group) return <span key={x}>{group.Name}</span>
                    });
                    return <div className='flex gap-5'>{elements}</div>
                }
            }
        },
        {
            name: 'Admin',
            title: 'Admin'
        },
        {
            name: 'Title',
            title: 'Title'
        },
        {
            name: 'Message',
            title: 'Message'
        },
        {
            name: 'PublishDate',
            title: 'Published',
            type: InputTypes.DateTime
        }
    ]
};


const Notification = (props) => {
    const [notification, setNotification] = useState(props.notification ? { Groups: props.notification.Groups ? props.notification.Groups.split(',') : null, Title: props.notification.Title, Message: props.notification.Message, Id: props.notification.Id } : { Groups: null, Title: '', Message: '' });
    const [groups, setGroups] = useState(null);
    const [availableGroups, setAvailableGroups] = useState([]);

    const selectRef = React.createRef();

    useEffect(() => {
        API.post(PlayerEndpoints.GetPlayerGroups, null).then((result) => {
            setGroups(result.result);
            setAvailableGroups(result.result);
        });
    }, []);


    const change = (target, value) => {
        var copy = { ...notification };
        copy[target] = value;
        if (copy.groups == null) {
            setAvailableGroups([...groups]);
        }
        setNotification(copy);
    };


    const addGroup = (groupId) => {
        var copy = { ...notification };
        if (!copy.Groups) {
            copy.Groups = [];
        }
        copy.Groups.push(groupId);

        var groupsLeft = [];
        groups.map(x => {
            if (!copy.Groups?.find(y => y == x.Id)) {
                groupsLeft.push(x);
            }
        });

        setAvailableGroups(groupsLeft);
        setNotification(copy);
    };

    const removeGroup = (groupId) => {
        var copy = { ...notification };
        copy.Groups = copy.Groups.filter(x => x != groupId);
        var groupsLeft = [];
        groups.map(x => {
            if (!copy.Groups?.find(y => y == x.Id)) {
                groupsLeft.push(x);
            }
        });

        if (!copy.Groups.length) {
            copy.Groups = null;
        }
        setAvailableGroups(groupsLeft);
        setNotification(copy);
    };


    if (!groups) return <></>;

    return <ConfirmationModal title={props.notification ? 'Edit' : 'New'} onConfirm={() => {
        notification.Groups = notification.Groups ? notification.Groups.join(',') : null;
        props.onSave(notification);
    }}>
        <div className='flex vertical gap-10 padding'>
            <div className='flex vertical gap-5'>
                <label>{lang('Group')}</label>
                <div className='flex gap-5 stretch'>
                    {
                        availableGroups.length > 0 && <select defaultValue={notification.Groups} ref={selectRef} key={notification.Groups}>
                            {
                                availableGroups.map(x => {
                                    return <option key={x.Id} value={x.Id}>{x.Name}</option>
                                })
                            }
                        </select>
                    }
                    {
                        availableGroups.length > 0 && <Button title={'Add'} onClick={() => {
                            addGroup(parseInt(selectRef.current.value))
                        }} />
                    }
                    {
                        availableGroups.length == 0 && <label>{lang('Sending to all groups')}</label>
                    }
                    <div className='flex gap-5 align-right'>
                        <Button title='Clear All' onClick={() => change('Groups', null)} />
                    </div>
                </div>
                <div className='flex gap-5'>
                    {
                        notification.Groups?.map(x => {
                            return <div className='flex gap-5' key={x} onClick={() => removeGroup(x)}>
                                <label>{groups.find(y => y.Id == x)?.Name}</label>
                                <Icon icon='times' />
                            </div>;
                        })
                    }
                </div>
            </div>
            <div className='flex vertical gap-5'>
                <label>{lang('Title')}</label>
                <input defaultValue={notification.Title ?? ''} onChange={(e) => change('Title', e.target.value)} />
            </div>
            <div className='flex vertical gap-5'>
                <label>{lang('Message')}</label>
                <textarea defaultValue={notification.Message ?? ''} onChange={(e) => change('Message', e.target.value)} >
                </textarea>
            </div>
        </div>
    </ConfirmationModal>;
};

Notification.propTypes = {
    notification: PropTypes.object,
    onSave: PropTypes.func
};

export const PlayerNotifications = () => {
    const [notifications, setNotifications] = useState([]);
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        API.post(PlayerEndpoints.GetPlayerGroups, null).then((result) => {
            setGroups(result.result);
            listAnnouncements();
        });
    }, []);


    const listAnnouncements = () => {
        API.post(AnnouncementEndpoints.List, {}, 'please wait', 'unable to fecth announcements').then((result) => {
            setNotifications(result.result);
        });
    };

    const createOrEdit = (notification) => {
        Modal.open(<Notification notification={notification} onSave={(notification) => {
            API.post(AnnouncementEndpoints.Save, notification, 'Please wait', 'Unable to save announcement').then((result) => {
                var copy = [...notifications];
                var notificationCopy = { ...notification };

                if (notification.Id) {
                    notificationCopy = copy.find(x => x.Id == notification.Id);
                    notificationCopy.Message = notification.Message;
                    notificationCopy.Title = notification.Title;
                    notificationCopy.Groups = notification.Groups;
                } else {
                    listAnnouncements();
                }
            });
        }} />)
    };


    const publish = (row, republish) => {
        Modal.open(<ConfirmationModal title='Confirm' onConfirm={() => {
            API.post(`${AnnouncementEndpoints.Publish}/${row.Id}`, null, 'Please wait', `Unable to ${republish ? "re " : ""}publish announcement`).then(() => {
                listAnnouncements();
                application().snackbar.open(`Announcement ${republish ? "Re " : ""}Published`);
            });
        }}>
            <div className='padding'>
                {lang(`Do you want to ${republish ? "re " : ""}publish announcement.`)}
            </div>
        </ConfirmationModal>)
    };

    return <flex className='vertical gap-10'>
        <FluxCard>
            <Button className='align-left' title='New Announcement' onClick={() => createOrEdit(null)} />
        </FluxCard>
        <FluxCard title='Past Announcements'>
            <Table data={notifications} model={notificationsModel}
                context={{ groups: groups }}
                buttons={(row) => {
                    return <flex className='gap-5'>
                        {!row.PublishDate && <Button title='Edit' onClick={() => createOrEdit(row)} />}
                        {!row.PublishDate && <Button title='Publish' onClick={() => publish(row, false)} />}
                        {!!row.PublishDate && <Button title='Re Publish' onClick={() => publish(row, true)} />}
                    </flex>;
                }} />
        </FluxCard>
    </flex>;
};

