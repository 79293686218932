/* eslint-disable react/display-name */
import React from 'react';
import { appHistory } from '../../../..';
import { toSnakeCase } from '../../../../components/Centrum/helpers';
import { Button } from '../../../components/button/Button';
import { InputTypes } from '../../../components/form/InputTypes';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { TransactionEndpoints } from '../../../lib/API/Endpoints';
import { limitKinds, limitTypes, resetTypes } from './TransactionLimit.Model';
import { CurrencyFilterField } from '../../../lib/Models/CurrencyModel';

const model = {
    name: 'transaction-limits',
    fields: [
        {
            name: 'OperatorId',
            title: 'Operator',
            type: InputTypes.Select,
            nullable: false,
            required: true,
            data: {
                source: 'operators',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'Retail',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'myRetails',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'Group',
            title: 'Player Group',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'myPlayerGroups',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'PlayerId',
            title: 'Player Id'
        },
        {
            name: 'ProviderId',
            title: 'Provider',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'myProviders',
                key: 'id',
                title: ['display'],
                parser: (result) => {
                    var results = [];
                    var group = 'Gaming';
                    result.result.map(x => {
                        x = toSnakeCase(x);
                        if (x.type == 128) group = 'Payment Provider';
                        results.push({
                            value: x.id, title: x.display, group: {
                                id: group,
                                title: group
                            }
                        });
                    });
                    return results;
                }
            }
        },
        {
            name: 'LimitKind',
            title: 'Limit Kind',
            type: InputTypes.Select,
            nullable: true,
            multiple: true,
            exact: true,
            value: [1, 2],
            values: limitKinds
        },
        {
            name: 'LimitType',
            title: 'Limit Type',
            type: InputTypes.Select,
            nullable: true,
            multiple: true,
            exact: true,
            value: [],
            values: limitTypes
        },
        {
            name: 'LimitData',
            title: 'Limit Data',
            type: InputTypes.Text,
            nullable: true
        },
        CurrencyFilterField,
        {
            name: 'Reset',
            title: 'Reset',
            type: InputTypes.Select,
            nullable: true,
            multiple: true,
            exact: true,
            value: [],
            values: resetTypes
        },
        {
            name: 'ResetCron',
            title: 'Reset Cron',
            type: InputTypes.Text,
            placeholder: '* * * *',
            nullable: true
        },
        {
            name: 'AutoConfirm',
            title: 'Auto Confirm',
            type: InputTypes.Bool,
            nullable: true,
            value: null,
        },
        {
            name: 'Disabled',
            title: 'Disabled',
            type: InputTypes.MultipleSelect,
            exact: true,
            nullable: true,
            value: null,
            values: {
                'All': null,
                'Enabled': true,
                'Disabled': false
            }
        },
    ]
};

const resultTransactionInformation = {
    fields: [
        { name: 'Id', title: 'Id' },
        { name: 'EntryDate', title: 'Entry Date', type: InputTypes.DateTime },
        { name: 'LimitKind', title: 'Limit Kind', type: InputTypes.Select, values: limitKinds },
        { name: 'LimitType', title: 'Limit Type', type: InputTypes.Select, values: limitTypes },
        { name: 'LimitData', title: 'Limit Data' },
        { name: 'Currency', title: 'Currency' },
        { name: 'Limit', title: 'Limit' },
        { name: 'Reset', title: 'Reset', type: InputTypes.Select, values: resetTypes },
        { name: 'ResetCron', title: 'Reset Cron' },
        { name: 'AutoConfirm', title: 'Auto Confirm', type: InputTypes.Bool },
        { name: 'Disabled', title: 'Disabled', type: InputTypes.Bool },
    ]
};

export const SearchTransactionLimits = () => {
    return <FluxSearch
        title={
            <React.Fragment>
                <span>Search Transaction Limits</span>
                <Button className='align-right success' title='Add new limit' onClick={() => {
                    appHistory.push('/setup/transaction-limit-modify');
                }}></Button>
            </React.Fragment>}
        model={model}
        resultModel={resultTransactionInformation}
        endpoint={TransactionEndpoints.SearchTransactionLimits}
        buttons={(row) => {
            return <React.Fragment>
                <flex className='gap-5'>
                    <Button className='success' title='Edit' onClick={() => {
                        appHistory.push(`/setup/transaction-limit-modify?id=${row.Id}`);
                    }} />
                </flex>
            </React.Fragment>;
        }} />;
};
