import React from 'react';

function getDevice(agent) {
    if (agent === null || agent === undefined) return '';
    if (agent.match(/Android/i)) return 'Android';
    if (agent.match(/webOS/i)) return 'Smart TV';
    if (agent.match(/iPhone/i)) return 'Iphone';
    if (agent.match(/iPad/i)) return 'Ipad';
    if (agent.match(/iPod/i)) return 'Ipod';
    if (agent.match(/BlackBerry/i)) return 'BlackBerry';
    if (agent.match(/Windows Phone/i)) return 'Windows Phone';
    if (agent.match(/Windows/i)) return 'Windows';
    return 'Other';
}

export const isMobile = (agent) => {
    const device = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(agent) == true ? 'Mobile' : 'Desktop';

    const iphone = /iPhone|iPad|iPod/i.test(agent) == true ? 'Apple' : 'Android';
    return device + (device == 'Mobile' ? ` ${iphone}` : '');
};


export const deviceInformation = (val) => {
    var device = getDevice(val);
    return (
        <div><b>{device}</b><br />{val}</div>
    );
};

