/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { lang } from '../../../../lib/Localization/language';
import { Button } from '../../../../components/button/Button';
import { ActionButton, FluxCard } from '../../../../components/card/FluxCard';
import { FluxForm } from '../../../../components/form/FluxForm';
import { InputTypes } from '../../../../components/form/InputTypes';
import { FluxTab } from '../../../../components/tab/FluxTab';
import { Table } from '../../../../components/table/Table';
import { CurrencyMinMaxRow } from './CurrencyMinMax/CurrencyMinMaxRow';
import { EventSelection } from './Events/EventSelection';
import { LeagueSelection } from './Leagues/LeagueSelection';
import { MarketSelection } from './MarketSelection/MarketSelection';
import { SportSelection } from './Sports/SportSelection';


const sportsSettingsModel = {
    fields: [
        {
            name: 'prematch',
            title: 'Enable Prematch',
            type: InputTypes.Bool
        },
        {
            name: 'live',
            title: 'Enable Live',
            type: InputTypes.Bool
        },
        {
            name: 'cashout',
            title: 'Enable Cashout',
            type: InputTypes.Bool
        },
        {
            name: 'maxSelections',
            title: 'Maximum Selections',
            type: InputTypes.Number
        }
    ]
};


export const MultiplierBonusSettings = (props) => {
    let { promotion, currencies, onChange } = { ...props };
    const [data, setData] = useState(promotion?.Configuration?.sport ?? { enabled: false, live: false, prematch: false, maxSelections: 0, cashout: false, slip: [], stake: {} });

    useEffect(() => {
        var copy = { ...data };
        if (!copy.slip) copy.slip = [];
        if (!copy.stake) copy.stake = {};
        setData(copy);
    }, []);

    useEffect(() => {
        if (!data) return;
        promotion.Configuration.sport = data;
        onChange({ ...promotion });
    }, [data]);

    const addNewLimit = () => {
        var copy = { ...data };
        if (!copy.slip) copy.slip = [];
        copy.slip.push({
            minimumSelection: 0,
            from: 0,
            to: 0,
            bonus: 0
        });
        setData(copy);
    };

    const removeLimit = (row) => {
        var copy = { ...data };
        copy.slip = copy.slip.filter(x => x !== row);
        setData(copy);
    };

    const updateLimit = (row, e) => {
        var copy = { ...data };
        var limit = copy.slip.find(x => x === row);
        var value = parseFloat(e.target.value);
        if (isNaN(value)) value = 0;
        if (e.target.name == 'bonus' && value > 100) {
            value = 100;
            e.target.value = value;
        }
        limit[e.target.name] = value;
        setData(copy);
    };


    const updateStakeSettings = (currency, value) => {
        var copy = { ...data };
        if (!copy.stake) copy.stake = {};
        if (!copy.stake[currency]) {
            copy.stake[currency] = [
                {
                    Min: 0,
                    Max: 0
                }
            ];
        }

        copy.stake[currency][0] = value;
        setData(copy);
    };


    const deleteCurrencySetting = (target) => {
        var copy = { ...data };
        delete copy.stake[target];
        setStakeData(copy);
    };



    var tabButtons = [
        {
            title: 'Sports'
        },
        {
            title: 'Leagues'
        },
        {
            title: 'Events'
        },
        {
            title: 'Markets'
        }
    ];

    return <flex className='vertical gap-10'>
        <FluxCard title='Bet slip configuration' buttons={<ActionButton title='Add' icon='plus-square' onClick={() => addNewLimit()} />}>
            <flex className='vertical gap-10'>
                <FluxForm data={{ prematch: data.prematch, live: data.live, cashout: data.cashout, maxSelections: data.maxSelections }} model={sportsSettingsModel} columns={2} buttons={null} onChange={(d) => setData(Object.assign(data, d))} />
                <Table className='fx-borders fx-shadow' model={{
                    fields: [
                        {
                            name: 'minimumSelection',
                            title: 'Minimum Selection',
                            render: (val, row, field) => {
                                return <input type='number' name={field.name}
                                    defaultValue={val}
                                    onFocus={(e) => {
                                        e.target.setAttribute('data', e.target.value);
                                        e.target.value = '';
                                    }}
                                    onBlur={(e) => {
                                        if (e.target.value == '') {
                                            e.target.value = e.target.getAttribute('data') ?? 0;
                                        }
                                    }}
                                    onChangeCapture={(e) => {
                                        updateLimit(row, e);
                                    }} />
                            }
                        },
                        {
                            name: 'from',
                            title: 'Min Odds',
                            render: (val, row, field) => {
                                return <input type='number' name={field.name}
                                    defaultValue={val}
                                    onFocus={(e) => {
                                        e.target.setAttribute('data', e.target.value);
                                        e.target.value = '';
                                    }}
                                    onBlur={(e) => {
                                        if (e.target.value == '') {
                                            e.target.value = e.target.getAttribute('data') ?? 0;
                                        }
                                    }}
                                    onChangeCapture={(e) => {
                                        updateLimit(row, e);
                                    }} />
                            }
                        },
                        {
                            name: 'to',
                            title: 'Max Odds',
                            render: (val, row, field) => {
                                return <input type='number' name={field.name}
                                    defaultValue={val}
                                    onFocus={(e) => {
                                        e.target.setAttribute('data', e.target.value);
                                        e.target.value = '';
                                    }}
                                    onBlur={(e) => {
                                        if (e.target.value == '') {
                                            e.target.value = e.target.getAttribute('data') ?? 0;
                                        }
                                    }}
                                    onChangeCapture={(e) => {
                                        updateLimit(row, e);
                                    }} />
                            }
                        },
                        {
                            name: 'bonus',
                            title: 'Percentage',
                            render: (val, row, field) => {
                                return <input type='number' name={field.name}
                                    defaultValue={val}
                                    max={100}
                                    onFocus={(e) => {
                                        e.target.setAttribute('data', e.target.value);
                                        e.target.value = '';
                                    }}
                                    onBlur={(e) => {
                                        if (e.target.value == '') {
                                            e.target.value = e.target.getAttribute('data') ?? 0;
                                        }
                                    }}
                                    onChangeCapture={(e) => {
                                        updateLimit(row, e);
                                    }} />
                            }
                        }
                    ]
                }} data={data.slip} buttons={(row) => {
                    return <Button title='Remove' className={'warning'} onClick={() => removeLimit(row)} />;
                }} />
            </flex>
        </FluxCard>
        <FluxCard title='Stake Settings'>
            <flex className='vertical gap-5 padding'>
                <label className='medium'>{lang('Minimum / Maximum Stake Amounts')}</label>
                <flex className='vertical gap-10'>
                    {currencies.map(x => <CurrencyMinMaxRow key={x} currency={x} updateStakeSettings={updateStakeSettings} deleteCurrencySetting={deleteCurrencySetting} data={data.stake} />)}
                </flex>
            </flex>
        </FluxCard>
        <FluxTab buttons={tabButtons} className='gap-10 fx-shadow fx-radius rounded-buttons'>
            <SportSelection configuration={promotion} onChange={(c) => {
                var copy = { ...data };
                copy.enabledSports = c.Configuration.sport.enabledSports;
                setData(copy);
            }} />
            <LeagueSelection configuration={promotion} onChange={(c) => {
                var copy = { ...data };
                copy.enabledLeagues = c.Configuration.sport.enabledLeagues;
                setData(copy);
            }} />
            <EventSelection configuration={promotion} onChange={(c) => {
                var copy = { ...data };
                copy.enabledEvents = c.Configuration.sport.enabledEvents;
                setData(copy);
            }} />
            <MarketSelection configuration={promotion} onChange={(c) => {
                var copy = { ...data };
                copy.enabledMarkets = c.Configuration.sport.enabledMarkets;
                setData(copy);
            }} />
        </FluxTab>
    </flex>;
};