import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { API } from '../../lib/API/Api';
import { DataEndpoints } from '../../lib/API/Endpoints';
import { Button } from '../button/Button';
import { FluxForm } from '../form/FluxForm';
import { InputTypes } from '../form/InputTypes';
import { Table } from '../table/Table';

export const SelectGames = (props) => {
    const { filter, onChange } = { ...props };

    const [providers, setProviders] = useState(null);
    const [games, setGames] = useState(null);
    const [provider, setProvider] = useState(null);

    const [selectedGames, setSelectedGames] = useState(props.selectedGames);

    const model = {
        fields: [
            {
                name: 'ProviderId',
                title: 'Provider',
                type: InputTypes.Select,
                data: {
                    source: DataEndpoints.GameProviders,
                    key: 'id',
                    title: ['name'],
                    filter: filter,
                    sort: (a, b) => a.display.toLowerCase() > b.display.toLowerCase() ? 1 : -1,
                    onParseComplete: (data) => {
                        setProviders(data);
                    }
                }
            }
        ]
    };


    useEffect(() => {
        if (provider) {
            API.post(`${DataEndpoints.Games}/${provider.id}`).then((result) => {
                setGames(result.result);
            });
        }
    }, [provider]);


    useEffect(() => {
        if (!selectedGames) return;
        onChange(selectedGames);
    }, [selectedGames]);

    return <flex className='vertical'>
        <FluxForm
            model={model}
            buttons={null}
            onChange={(data) => {
                //onChange(providers?.find(x => x.id == data.ProviderId));
                var provider = providers?.find(x => x.id == data.ProviderId);
                if (provider) {
                    setProvider(provider);
                }
            }} />
        {games && <Table
            className={'fx-shadow fx-borders'}
            model={{
                fields: [
                    {
                        name: 'name',
                        title: 'Name'
                    }
                ]
            }}
            data={games}
            buttons={(row) => {
                if (selectedGames[row.id]) {
                    return <Button title='Remove' className={'warning'} onClick={() => {
                        var copy = { ...selectedGames };
                        delete copy[row.id];
                        setSelectedGames(copy);

                    }} />;
                }
                return <Button title='Add' className='success' onClick={() => {
                    var copy = { ...selectedGames };
                    copy[row.id] = {
                        calculatedPercentage: 0,
                        id: row.id,
                        maxBet: 0,
                        minBet: 0,
                        name: row.name,
                        percentage: 0,
                        providerId: provider.id,
                        providerName: provider.name
                    };
                    setSelectedGames(copy);
                }} />;
            }} />
        }
    </flex>;
};

SelectGames.defaultProps = {
    multiple: true
};

SelectGames.propTypes = {
    filter: PropTypes.func,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    selectedGames: PropTypes.array
};