import React, { useEffect, useState } from 'react';
import { lang } from '../../../../../lib/Localization/language';
import { Modal } from '../../../../../components/application/FluxApp';
import { Button } from '../../../../../components/button/Button';
import { ConfirmationModal } from '../../../../../components/modal/ConfirmationModal/ConfirmationModal';
import { ErrorModal } from '../../../../../components/modal/ErrorModal/ErrorModal';
import { FluxNotice } from '../../../../../components/notification/FluxNotice';
import { NotificationType } from '../../../../../components/notification/FluxNotification';
import { CMSEndpoints } from '../../../../../lib/API/Endpoints';
import { ImageUpload } from '../ImageUpload/ImageUpload';



export const Slider = (props) => {
    let { item, prefix, languages, language, defaultLanguage, page, onUpdate } = { ...props };
    const [languageKey, setLanguageKey] = useState(null);

    useEffect(() => {
        if (!item.img) {
            item.img = {};
            item.img[language.key.toLowerCase()] = {
                key: Date.now()
            };
        }
        const key = item.img[language.key.toLowerCase()] ? language.key.toLowerCase() : defaultLanguage;
        setLanguageKey(key);
    }, []);


    if (!languageKey) return <></>;
    //https://cdn-elb.tentangle.com/content/pages/home/sliders/1664848701677_en.jpg
    const source = `content/pages/${page.key}/sliders`;
    const fileExtension = item.transparent ? 'png' : 'jpg';
    const image = item.img[languageKey]?.key ?? 'error';

    const prepareUpload = (mobile) => {
        var imageData = null;
        Modal.open(<ConfirmationModal title='Upload Image' confirmTitle='Upload' onConfirm={() => {
            let name = `${mobile ? 'm_' : ''}${imageData.key}_${language.key.toLowerCase()}.${fileExtension}`;
            let payload = {
                image: imageData.base64,
                path: `/content/pages/${page.key}/sliders`,
                name: name
            };

            var copy = { ...item };
            copy.img[language.key.toLowerCase()] = {
                key: imageData.key,
                mobile: imageData.mobile
            };


            return new Promise((resolve, reject) => {
                fetch(CMSEndpoints.CMSUploadImage, {
                    method: 'POST',
                    credentials: 'same-origin',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    processData: false,
                    body: JSON.stringify(payload)
                }).then(res => res.json())
                    .then((result) => {
                        if (result && result.result) {
                            Modal.close();
                            onUpdate(copy);
                            resolve(result);
                        } else {
                            if (result && result.result?.error) {
                                throw result.result?.error?.message ?? 'Unable to upload image, service error. Please check the size of uploaded image and try again later.';
                            }
                            throw 'Unable to upload image, service error. Please check the size of uploaded image and try again later.';
                        }
                    }).catch(e => {
                        Modal.open(<ErrorModal title={'Image save failed'}><p>{lang(e)}</p></ErrorModal>);
                        reject(e);
                    });
            });
        }}>
            <ImageUpload onImageData={(data) => {
                try {
                    if (mobile) {
                        data.mobile = data.key;
                        data.key = item.img[language.key.toLowerCase()].key;

                    }
                    imageData = data;
                } catch (err) {
                    //
                    console.log(err);
                }
            }} />
        </ConfirmationModal>);
    };

    const removeImage = () => {
        Modal.open(<ConfirmationModal title='Remove Image?' onConfirm={() => {
            var copy = { ...item };
            delete copy.img[language.key];
            onUpdate(copy);
        }}>
            <FluxNotice type={NotificationType.Warning} title='Please confirm removing image.' description={'Removed images can not be reverted and will be deleted from the website.'} />
        </ConfirmationModal>)
    };

    var title = languages.find(x => x.key.toLowerCase() == defaultLanguage)?.name ?? 'untitled';
    var mobile = item.img[language.key.toLowerCase()]?.mobile;
    return <slider>
        <flex className='vertical gap-10'>
            <img src={`//cdn-${prefix}.tentangle.com/${source}/${image}_${languageKey}.${fileExtension}`} onLoad={() => { }} />
            {mobile && <img src={`//cdn-${prefix}.tentangle.com/${source}/m_${image}_${languageKey}.${fileExtension}`} style={{ maxWidth: '375px', margin: 'auto' }} onLoad={() => { }} />}
        </flex>
        {languageKey !== language.key.toLowerCase() && <flex className='language-error'>
            <label>{lang('Image not found, using default image from %1 language.', [title])}</label>
        </flex>}
        <flex className='gap-5 padding margin-top border-top'>
            <flex className='center gap-5'>
                <Button className='success' title='Upload Image' onClick={() => prepareUpload()} />
                <Button className='success' title='Upload Mobile Image' onClick={() => prepareUpload(true)} />
                {languageKey == language.key.toLowerCase() && <Button className='warning' title='Remove Image' onClick={() => removeImage()} />}
            </flex>
        </flex>
    </slider>;
};