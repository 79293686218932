import React from 'react';
import { FluxAction } from '../../components/application/FluxAction';
import { FluxPage } from '../../components/page/FluxPage';
import { ActionTypes } from '../../lib/ActionManager/FluxActions';
import { SearchPlayerHorseGreyhoundHistory } from '../Players/SearchPlayerHorseGreyhoundHistory/SearchPlayerHorseGreyhoundHistory';
import { HorseLimitsWithRiskLevels } from '../../components/sports/RiskManagement/SportsLimits/HorseLimitsWithRiskLevels';
import { HorseRiskLevelsConfiguration } from '../../components/sports/RiskManagement/HorseRiskLevels/HorseRiskLevelsConfiguration';
import { HorseGreyhoundMonitor } from './HorseGreyhoundMonitor/HorseGreyhoundMonitor';
import { SearchRejectedTickets } from '../Players/SearchRejectedTickets/SearchRejectedTickets';

export const HorseGreyhoundPage = () => {
    return <FluxPage action={ActionTypes.HorseGreyHound}>
        <FluxAction action={ActionTypes.HorseGreyHoundTickets}><SearchPlayerHorseGreyhoundHistory searchOnStart /></FluxAction>
        <FluxAction action={ActionTypes.RiskManagementConfigureSportLimits}><HorseLimitsWithRiskLevels /></FluxAction>
        <FluxAction action={ActionTypes.RiskLevelsConfiguration}><HorseRiskLevelsConfiguration /></FluxAction>
        <FluxAction action={ActionTypes.HorseGreyHoundPendingTickets}><SearchPlayerHorseGreyhoundHistory searchOnStart pending /></FluxAction>
        <FluxAction action={ActionTypes.RiskManagementBetMonitor}><HorseGreyhoundMonitor /></FluxAction>
        <FluxAction action={ActionTypes.HorseGreyHoundRejectedTickets}><SearchRejectedTickets providerId={9999} /></FluxAction>
    </FluxPage>;
};
