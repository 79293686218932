import React from 'react';
import { ActionTypes } from '../../lib/ActionManager/FluxActions';
import { FluxAction } from '../../components/application/FluxAction';
import { FluxPage } from '../../components/page/FluxPage';
import { SearchPlayerTransactions } from '../Players/SearchPlayerTransactions/SearchPlayerTransactions';
import { SearchPendingPSPTransactions } from '../Players/SearchPlayerTransactions/SearchPendingPSPTransactions';

export const TransactionsPage = () => {
    return <FluxPage action={ActionTypes.Transactions}>
        <FluxAction action={ActionTypes.SearchPlayerTransactions}><SearchPlayerTransactions /></FluxAction>
        <FluxAction action={ActionTypes.SearchTransactions}><SearchPlayerTransactions payment /></FluxAction>
        <FluxAction action={ActionTypes.SearchPSPTransactions}><SearchPendingPSPTransactions payment /></FluxAction>
        <FluxAction action={ActionTypes.SearchPendingDeposits}><SearchPlayerTransactions payment pending deposits /></FluxAction>
        <FluxAction action={ActionTypes.SearchPendingWithdraws}><SearchPlayerTransactions payment pending withdraws /></FluxAction>
    </FluxPage >;
};
