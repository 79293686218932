/* eslint-disable react/display-name */
import React, { useState } from 'react';
import { LiabilityEventMonitor } from './LiabilityEventMonitor/LiabilityEventMonitor';
import { BetMonitorSocket } from './MonitorTickets/BetmonitorSocket';
import './_betmonitor-risk-levels.scss';

export const EventLiabilitiesMonitorRiskLevels = () => {
    const [newTickets, setNewTickets] = useState(0);
    const [overAskTickets, setOverAskTickets] = useState(0);

    return <React.Fragment>
        <BetMonitorSocket endpoint2='t{operatorId}ws.appslnd.net/ws' endpoint3='10.10.5.247:2121/ws' type='sport'
            onNewTicket={() => {
                setNewTickets(newTickets + 1);
            }}
            onCloseTicket={() => {
                setNewTickets(newTickets > 0 ? newTickets - 1 : 0);
            }}
            onRemoveTicket={() => {
                setNewTickets(newTickets > 0 ? newTickets - 1 : 0);
            }}
            onOverAsk={() => {
                setOverAskTickets(overAskTickets + 1);
            }} onAutoReject={() => {
                setOverAskTickets(overAskTickets > 0 ? overAskTickets - 1 : 0);
            }} onOfferAccepted={() => {
                setOverAskTickets(overAskTickets > 0 ? overAskTickets - 1 : 0);
            }} onOfferRejected={() => {
                setOverAskTickets(overAskTickets > 0 ? overAskTickets - 1 : 0);
            }}>
            <LiabilityEventMonitor />
        </BetMonitorSocket>
    </React.Fragment>;
};