/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { LeagueLevels } from '../HorseRiskLevelsConfiguration';
import { API } from '../../../../../lib/API/Api';
import { HorseRiskLevelEndpoints, SportEndpoints } from '../../../../../lib/API/Endpoints';
import { Popper } from '../../../../notification/FluxPopper';
import { NotificationType } from '../../../../notification/FluxNotification';
import { Button } from '../../../../button/Button';
import { lang } from '../../../../../lib/Localization/language';
import { Table } from '../../../../table/Table';
import { FluxCard } from '../../../../card/FluxCard';

const renderRow = (row, field, context, level) => {
    const selected = row.level == level;
    return <div className={`${level}${selected ? ' selected' : ' passive'}`} onClick={() => context.update(row.id, level)}>{LeagueLevels[level]}</div>;
};


const levelValues = {
    'Low': 0,
    'Low2': 1,
    'Medium': 2,
    'Medium2': 3,
    'High': 4,
    'High2': 5,
    'High3': 6,
    'High4': 7,
};

const model = {
    fields: [
        {
            name: 'id',
            title: 'Id'
        },
        {
            name: 'sport',
            title: 'Sport'
        },
        {
            name: 'region',
            title: 'Region'
        },
        {
            name: 'league',
            title: 'League',
            render: (val, row) => {
                return <span>{row.name}</span>;
            }
        },
        {
            name: 'low',
            title: '',
            render: (val, row, field, context) => {
                return renderRow(row, field, context, 'Low');
            }
        },
        {
            name: 'low2',
            title: '',
            render: (val, row, field, context) => {
                return renderRow(row, field, context, 'Low2');
            }
        },
        {
            name: 'medium',
            title: '',
            render: (val, row, field, context) => {
                return renderRow(row, field, context, 'Medium');
            }
        },
        {
            name: 'medium2',
            title: '',
            render: (val, row, field, context) => {
                return renderRow(row, field, context, 'Medium2');
            }
        },
        {
            name: 'high',
            title: '',
            render: (val, row, field, context) => {
                return renderRow(row, field, context, 'High');
            }
        },
        {
            name: 'high2',
            title: '',
            render: (val, row, field, context) => {
                return renderRow(row, field, context, 'High2');
            }
        },
        {
            name: 'high3',
            title: '',
            render: (val, row, field, context) => {
                return renderRow(row, field, context, 'High3');
            }
        },
        {
            name: 'high4',
            title: '',
            render: (val, row, field, context) => {
                return renderRow(row, field, context, 'High4');
            }
        }
    ]
};


export const HorseRiskLevelVenues = (props) => {
    const [selectedSport, selectSport] = useState(null);
    const [selectedRegion, selectRegion] = useState(null);
    const [selectedLeague, selectLeague] = useState(null);
    const [regions, setRegions] = useState([]);
    const [leagues, setLeagues] = useState([]);


    const [levels, setLevels] = useState({ default: LeagueLevels.High, leagues: {} });
    const [saving, setSaving] = useState(false);

    const [tableSorting, setSorting] = useState({ field: 'league', direction: 1 });

    const context = {
        levels: levels,
        update: (id, level) => {
            var copy = { ...levels };
            copy.leagues[id].level = level;
            setLevels(copy);
        }
    };

    useEffect(() => {
        API.post(HorseRiskLevelEndpoints.Venues).then((league) => {
            let config = league.result ? JSON.parse(league.result) : { default: LeagueLevels.High, leagues: {} };
            setLevels(config);
        });
        selectSport(props.sports[0]);
    }, []);

    useEffect(() => {
        if (!selectedSport) return;
        API.post(`${SportEndpoints.Regions}/${selectedSport.id}`).then((result) => {
            result.result = result.result.sort((a, b) => a.name > b.name ? 1 : -1);
            result.result = result.result.filter((x) => x.name !== '');
            setRegions(result.result);
        }).catch((e) => {
            console.log(e);
        });
    }, [selectedSport]);

    useEffect(() => {
        if (!selectedRegion) return;
        API.post(`${SportEndpoints.Leagues}/${selectedSport.id}/${selectedRegion.id}`).then((result) => {
            result.result = result.result.sort((a, b) => a.name > b.name ? 1 : -1);
            result.result = result.result.filter((x) => x.name !== '');
            setLeagues(result.result);
        }).catch((e) => {
            console.log(e);
        });
    }, [selectedRegion]);

    useEffect(() => {
        if (!regions) return;
        selectRegion(regions[0]);
    }, [regions]);

    useEffect(() => {
        if (!leagues) return;
        selectLeague(leagues[0]);
    }, [leagues]);


    const save = () => {
        setSaving(true);
        API.post(HorseRiskLevelEndpoints.SaveVenues, levels).then(() => {
            Popper.pop(NotificationType.Information, 'Updated', 'Risk levels updated.');
            setSaving(false);
        });
    };

    const setDefault = (level) => {
        var copy = { ...levels };
        copy.default = level;
        setLevels(copy);
    };

    const remove = (row) => {
        var copy = { ...levels };
        delete copy.leagues[row.id];
        setLevels(copy);
    };

    const add = () => {
        var copy = { ...levels };
        copy.leagues[selectedLeague.id] = {
            id: selectedLeague.id,
            name: selectedLeague.name,
            sportId: selectedSport.id,
            sport: selectedSport.name,
            regionId: selectedRegion.id,
            region: selectedRegion.name,
            level: LeagueLevels.High
        };
        setLevels(copy);
    };

    return <flex className={`risk-level-limits${saving ? ' disabled' : ''} vertical gap-10`}>
        <FluxCard>
            <Button title='Save' className='success align-left' onClick={() => save()} />
        </FluxCard>
        <FluxCard>
            <flex className='gap-10'>
                <flex className='gap-10'>
                    <select defaultValue={selectedSport?.id} onChangeCapture={(e) => {
                        selectSport(props.sports.find(x => x.id == parseInt(e.target.value)));
                    }}>

                        {props.sports.map(x => {
                            return <option key={x.id} value={x.id}>{x.name}</option>;
                        })}
                    </select>
                    <select defaultValue={selectedRegion?.id} onChangeCapture={(e) => {
                        selectRegion(regions.find(x => x.id == parseInt(e.target.value)));
                    }}>

                        {regions.map(x => {
                            return <option key={x.id} value={x.id}>{x.name}</option>;
                        })}
                    </select>
                    <select defaultValue={selectedLeague?.id} onChangeCapture={(e) => {
                        selectLeague(leagues.find(x => x.id == parseInt(e.target.value)));
                    }}>

                        {leagues.map(x => {
                            return <option key={x.id} value={x.id}>{x.name}</option>;
                        })}
                    </select>
                    {leagues.length > 0 && <Button title='Add' className='success' onClick={() => add()} />}
                </flex>
                <label className='align-right'>{lang('Default level for leagues')}</label>
                <flex className='gap-5'>
                    <div className={`clickable Low${levels.default == LeagueLevels.Low ? ' selected' : ' passive'}`} onClick={() => setDefault(LeagueLevels.Low)}>{LeagueLevels.Low}</div>
                    <div className={`clickable Low2${levels.default == LeagueLevels.Low2 ? ' selected' : ' passive'}`} onClick={() => setDefault(LeagueLevels.Low2)}>{LeagueLevels.Low2}</div>
                    <div className={`clickable Medium${levels.default == LeagueLevels.Medium ? ' selected' : ' passive'}`} onClick={() => setDefault(LeagueLevels.Medium)}>{LeagueLevels.Medium}</div>
                    <div className={`clickable Medium2${levels.default == LeagueLevels.Medium2 ? ' selected' : ' passive'}`} onClick={() => setDefault(LeagueLevels.Medium2)}>{LeagueLevels.Medium2}</div>
                    <div className={`clickable High${levels.default == LeagueLevels.High ? ' selected' : ' passive'}`} onClick={() => setDefault(LeagueLevels.High)}>{LeagueLevels.High}</div>
                    <div className={`clickable High2${levels.default == LeagueLevels.High2 ? ' selected' : ' passive'}`} onClick={() => setDefault(LeagueLevels.High2)}>{LeagueLevels.High2}</div>
                    <div className={`clickable High3${levels.default == LeagueLevels.High3 ? ' selected' : ' passive'}`} onClick={() => setDefault(LeagueLevels.High3)}>{LeagueLevels.High3}</div>
                    <div className={`clickable High4${levels.default == LeagueLevels.High4 ? ' selected' : ' passive'}`} onClick={() => setDefault(LeagueLevels.High4)}>{LeagueLevels.High4}</div>
                </flex>
            </flex>
        </FluxCard>
        <FluxCard className='overflow-hidden no-padding'>
            <Table model={model} data={Object.values(levels.leagues).sort((a, b) => {
                switch (tableSorting.field) {
                    case 'league':
                        return tableSorting.direction == 1 ? (a.name > b.name ? 1 : - 1) : (a.name > b.name ? -1 : 1);
                    case 'region':
                        return tableSorting.direction == 1 ? (a.region > b.region ? 1 : - 1) : (a.region > b.region ? -1 : 1);
                    case 'sport':
                        return tableSorting.direction == 1 ? (a.sport > b.sport ? 1 : - 1) : (a.sport > b.sport ? -1 : 1);
                    case 'id':
                        return tableSorting.direction == 1 ? (a.id > b.id ? 1 : - 1) : (a.id > b.id ? -1 : 1);
                    default:
                        return tableSorting.direction == 1 ? (levelValues[a.level] > levelValues[b.level] ? 1 : - 1) : (levelValues[a.level] > levelValues[b.level] ? -1 : 1);
                }
            })} options={{
                sorting: {
                    enabled: true,
                    field: {
                        column: tableSorting.field,
                        direction: tableSorting.direction
                    },
                    onSortingChange: (sorting) => {
                        setSorting({ field: sorting.column, direction: sorting.direction });
                    }
                }
            }} context={context} buttons={(row) => {
                return <Button title='Remove' className='warning' onClick={() => remove(row)} />;
            }} />
        </FluxCard>
    </flex>;
};

HorseRiskLevelVenues.propTypes = {
    sports: PropTypes.array
};