import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Icon } from '../../../../components/icon/Icon';
import { API } from '../../../../lib/API/Api';
import { DataEndpoints } from '../../../../lib/API/Endpoints';
import { lang } from '../../../../lib/Localization/language';


export const KPIRanges = {
    'Today': 0,
    'WTD': 1,
    'MTD': 2
};

export const KPITitle = {
    0: 'Today',
    1: 'This Week',
    2: 'This Month'
};

export const KPISelector = (props) => {
    const [currencies, setCurrencies] = useState([]);
    const [convertCurrency, toggleConvertCurrency] = useState(false);

    useEffect(() => {
        API.post(DataEndpoints.EnabledCurrencies).then((result) => {
            setCurrencies(result.result);
        });
    }, []);

    const selectedKPI = props.range;
    return <React.Fragment>
        <flex className='surface gap-5 border radius shadow padding'>
            <Icon icon='bars-progress' type='fa-regular' />
            <label>{lang(KPITitle[selectedKPI])}</label>
            <flex className='align-right gap-5'>
                <span>{lang('Convert Currency')}</span>
                <input defaultValue={convertCurrency} type='checkbox' onClick={() => {
                    if (props.onToggleConvertCurrency) {
                        props.onToggleConvertCurrency(!convertCurrency);
                    }
                    toggleConvertCurrency(!convertCurrency);
                }}></input>
            </flex>
            <select className='align-right' onChange={(e) => {
                props.onSelectedCurrency(e.target.value);
            }}>
                {currencies.map(x => {
                    return <option key={x} value={x.CurrencyCode}
                        selected={x.CurrencyCode == props.defaultCurrency}>{lang(x.Name)}</option>;
                })}
            </select>
            <select className='align-right' onChange={(e) => {
                props.onSelectedKPI(parseInt(e.target.value));
            }}>
                {Object.keys(KPIRanges).map(x => {
                    var title = KPITitle[KPIRanges[x]];
                    return <option key={x} value={KPIRanges[x]}>{lang(title)}</option>;
                })}
            </select>
        </flex>
        {props.children}
    </React.Fragment>;
};

KPISelector.propTypes = {
    children: PropTypes.node,
    range: PropTypes.number,
    defaultCurrency: PropTypes.string,
    onSelectedKPI: PropTypes.func,
    onSelectedCurrency: PropTypes.func,
    onToggleConvertCurrency: PropTypes.func,
};