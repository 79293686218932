import './_retail-tree.scss';

import React, { useEffect, useState } from 'react';
import { Icon } from '../../../components/icon/Icon';
import { API } from '../../../lib/API/Api';
import { RetailEndpoints } from '../../../lib/API/Endpoints';
import { lang } from '../../../lib/Localization/language';
import { MultipleSelectInput } from '../../../components/form/Components/MultipleSelectInput';

export const CreateTree = (retails) => {
    var data = retails;
    const idMapping = data.reduce((acc, el, i) => {
        acc[el.Id] = i;
        return acc;
    }, {});


    let roots = {};
    data.forEach(el => {
        // Handle the root element
        if (el.ParentRetailId === null) {
            roots[el.Id] = el;
            return;
        }
        // Use our mapping to locate the parent element in our data array
        const parentEl = data[idMapping[el.ParentRetailId]];
        // Add our current el to its parent's `children` array
        parentEl.children = [...(parentEl.children || []), el];
    });


    return Object.values(roots).sort((a, b) => a.Id > b.Id ? -1 : 1);
};


export const RetailTree = (props) => {
    const { onReady, onSelected } = { ...props };

    const [selected, select] = useState(null);

    const [retails, setRetails] = useState([]);
    const [retailsFiltered, setRetailsFiltered] = useState([]);
    const [expandedItems, setExpandedItems] = useState({});

    const [search, setSearch] = useState(null);
    const [enabledFilter, setEnabledFilter] = useState(null);

    useEffect(() => {
        API.post(RetailEndpoints.MyRetails, {}).then((result) => {
            var data = CreateTree(result.result);
            setRetails(data);
            setRetailsFiltered(data);
        });
    }, []);

    useEffect(() => {
        if (enabledFilter == null) {
            setRetailsFiltered(retails);
            return;
        }
        setRetailsFiltered(retails.filter(x => x.Enabled == enabledFilter));
    }, [enabledFilter]);

    useEffect(() => {
        if (!search) {
            setRetailsFiltered(retails);
            return;
        }
        if (search.length < 3) {
            setRetailsFiltered(retails);
            return;
        }

        var foundItems = [];
        setRetailsFiltered([]);
        var foundItemsAll = [];
        retails.map(item => {
            if (item.Name.toLowerCase().indexOf(search.toLowerCase()) >= 0) {
                foundItems.push(item.Id);
                foundItemsAll.push(item);
            }
            if (item.children && item.children.length > 0) {
                item.children.map(x => {
                    if (x.Name.toLowerCase().indexOf(search.toLowerCase()) >= 0) {
                        foundItems.push(x.Id);
                        foundItems.push(item.Id);
                        foundItemsAll.push(x);
                    }

                    if (x.children && x.children.lengh > 0) {
                        x.children.map(y => {
                            if (y.Name.toLowerCase().indexOf(search.toLowerCase()) >= 0) {
                                foundItems.push(y.Id);
                                foundItems.push(x.Id);
                                foundItems.push(item.Id);
                                foundItemsAll.push(y);
                            }
                        });
                    }
                });
            }
        });
        setRetailsFiltered(foundItemsAll);

        if (foundItems.length > 0) {
            var copy = { ...expandedItems };
            foundItems.map(x => {
                copy[x] = true;
            });

            setExpandedItems(copy);
        }
    }, [search]);

    useEffect(() => {
    }, [expandedItems]);

    useEffect(() => {
        if (!retails) return;
        if (onReady) {
            onReady(retails);
        }
    }, [retails]);

    useEffect(() => {
        if (!selected) return;
        if (onSelected) {
            onSelected({
                id: selected.Id,
                name: selected.Name
            });
        }
    }, [selected]);

    const toggleOpen = (item) => {
        var copy = { ...expandedItems };
        if (copy[item.Id]) {
            delete copy[item.Id];
        } else {
            copy[item.Id] = true;
        }
        setExpandedItems(copy);
    };


    const renderItem = (item) => {
        var found = expandedItems[item.Id];
        return <React.Fragment key={item}>
            <item className={`${found ? 'found' : ''} ${!item.Enabled ? 'passive' : null}${selected == item ? ' selected' : ''}`} onClick={() => {
                select(item);
            }}>
                <flex className='gap-10'>
                    <label>{item.Id}</label>
                    <span>{item.Name}</span>
                    {item.children && item.children.length > 0 && <flex onClick={() => toggleOpen(item)} className='align-right'><Icon icon={expandedItems[item.Id] ? 'square-minus' : 'square-plus'} type='fa-light' /></flex>}
                </flex>
            </item>
            {
                item.children && expandedItems[item.Id] && item.children.length > 0 && <items>{item.children.map(childItem => {
                    return renderItem(childItem);
                })
                }</items>
            }
        </React.Fragment>;
    };

    return <flex className='vertical gap-10 overflow-hidden'>
        <fluxList className='retail-tree flux-tree'>
            <flex className='padding border-bottom fit-children search-in-tree fx-shadow'>
                <span className='bold'>{lang('Search in tree')}</span>
                <input className='' onChange={(e) => {
                    setSearch(e.target.value);
                }} />
            </flex>
            <flex className='padding border-bottom fit-children search-in-tree fx-shadow'>
                <span className='bold'>{lang('Enabled')}</span>
                <MultipleSelectInput
                    field={
                        {
                            values:
                            {
                                'All': null,
                                'Enabled': true,
                                'Disabled': false
                            },
                            exact: true,
                            nullable: true,
                            value: enabledFilter,
                            checked: null
                        }
                    }
                    title='Enabled'
                    name='Enabled' onChange={(e, a) => setEnabledFilter(a)} />
            </flex>
            <items>
                {
                    retailsFiltered.map(x => {
                        return renderItem(x);
                    })
                }
            </items>
        </fluxList>
    </flex>;
};