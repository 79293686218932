import React, { useEffect, useState } from 'react';
import { Button } from '../../../../button/Button';
import { FluxCard } from '../../../../card/FluxCard';
import { NotificationType } from '../../../../notification/FluxNotification';
import { Popper } from '../../../../notification/FluxPopper';
import { Table } from '../../../../table/Table';
import { API } from '../../../../../lib/API/Api';
import { HorseRiskLevelEndpoints } from '../../../../../lib/API/Endpoints';
import { SportRiskLevels } from '../HorseRiskLevelsConfiguration';

const model = {
    fields: [
        {
            name: 'id',
            title: 'Id'
        },
        {
            name: 'name',
            title: 'Name'
        },
        {
            name: 'low',
            title: '',
            render: (val, row, field, context) => {
                const selected = context.levels[row.id] == SportRiskLevels.Low;
                return <div className={`Low${selected ? ' selected' : ' passive'}`} onClick={() => context.update(row.id, SportRiskLevels.Low)}>Low</div>;
            }
        },
        {
            name: 'medium',
            title: '',
            render: (val, row, field, context) => {
                const selected = context.levels[row.id] == SportRiskLevels.Medium;
                return <div className={`Medium${selected ? ' selected' : ' passive'}`} onClick={() => context.update(row.id, SportRiskLevels.Medium)}>Medium</div>;
            }
        },
        {
            name: 'high',
            title: '',
            render: (val, row, field, context) => {
                const selected = context.levels[row.id] == SportRiskLevels.High;
                return <div className={`High${selected ? ' selected' : ' passive'}`} onClick={() => context.update(row.id, SportRiskLevels.High)}>High</div>;
            }
        }
    ]
};


export const HorseRiskLevelsCategories = (props) => {
    const [levels, setLevels] = useState({});
    const [saving, setSaving] = useState(false);

    const context = {
        levels: levels,
        update: (id, level) => {
            var copy = structuredClone(levels);
            copy[id] = level;
            setLevels(copy);
        }
    };

    useEffect(() => {
        var config = {};
        API.post(HorseRiskLevelEndpoints.Categories).then((sport) => {
            let sportConfig = sport.result ? JSON.parse(sport.result) : {};
            props.sports.map(x => {
                config[x.id] = sportConfig[x.id] ?? SportRiskLevels.Low;
            });

            setLevels(config);
        });
    }, []);

    const save = () => {
        setSaving(true);
        API.post(HorseRiskLevelEndpoints.SaveCategories, levels).then(() => {
            Popper.pop(NotificationType.Information, 'Levels Saved.');
            setSaving(false);
        });
    };

    return <flex className={`risk-level-limits${saving ? ' passive' : ''} vertical gap-10`}>
        <FluxCard>
            <Button title='Save' onClick={() => save()} className='align-left success'/>
        </FluxCard>
        <FluxCard className='overflow-hidden no-padding'>
            {props.sports.length && <Table model={model} data={props.sports} context={context} />}
        </FluxCard>
    </flex>;
};