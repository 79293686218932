import './_confirmation-modal.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FluxNotice } from '../../../../flux/components/notification/FluxNotice';
import { NotificationType } from '../../../../flux/components/notification/FluxNotification';
import { FluxForm } from '../../../../flux/components/form/FluxForm';
import { FluxModal } from '../../../../flux/components/modal/FluxModal';
import { Button } from '../../../../flux/components/button/Button';
import { lang } from '../../../../flux/lib/Localization/language';
import { currency } from '../../../../flux/components/currency/Currency';
import { Modal } from '../../../../flux/components/application/FluxApp';
import { InputTypes } from '../../../../flux/components/form/InputTypes';
import { FluxCard } from '../../../../flux/components/card/FluxCard';
import { openBonusBets } from '../../../../flux/actions/BonusActions';

export const ConfirmWithdrawTransactionModal = (props) => {
    const transaction = props.transaction;
    const close = (e) => {
        if (e.code == 'Escape') Modal.close();
    };

    const [model, setModel] = useState(null);
    const [ready, setReady] = useState(null);
    const [configuration, setConfiguration] = useState(null);
    const [data, setData] = useState({ Amount: transaction.amount, Notes: '', DeductBonusAmount: false });

    useEffect(() => {
        var copy = {
            fields: [
                {
                    name: 'Amount',
                    title: 'Amount',
                    render: (val, row) => {
                        return <span>{currency(Math.abs(transaction.amount), 2, transaction.currency)}</span>;
                    },
                    readonly: true
                },
                {
                    name: 'Notes',
                    title: 'Notes',
                    type: InputTypes.Textarea
                }
            ]
        };

        if (!transaction.promotion) {
            setModel(copy);
        } else {
            try {
                setConfiguration(JSON.parse(transaction.promotion.configuration));
            } catch (err) {
                console.log(err);
            }

            copy = {
                fields: [
                    {
                        name: 'DeductBonusAmount',
                        title: 'Deduct Bonus Amount',
                        value: false,
                        type: InputTypes.Bool
                    },
                    {
                        name: 'Amount',
                        title: 'Amount',
                        render: (val, row) => {
                            return <span>{currency(Math.abs(transaction.amount) - (row.DeductBonusAmount ? transaction.promotion.amount : 0), 2, transaction.currency)}</span>;
                        },
                        readonly: true
                    },
                    {
                        name: 'Notes',
                        title: 'Notes',
                        type: InputTypes.Textarea
                    }
                ]
            };
            setModel(copy);
        }
        window.addEventListener('keyup', close);
        return () => window.removeEventListener('keyup', close);
    }, []);


    useEffect(() => {
        if (!model) return;
        setReady(Date.now());
    }, [model]);

    useEffect(() => {
        if (!configuration) return;
    }, [configuration]);


    useEffect(() => {
        if (!data) return;
    }, [data]);

    const renderConsumedPromotion = () => {
        var promotion = transaction.promotion;
        var turnoverMultiplier = promotion.turnoverMultiplier;
        var totalBet = promotion.totalBet;
        var max = turnoverMultiplier * promotion.amount;
        var perc = (100 * totalBet) / max;
        if (totalBet > max) perc = 100;
        perc = parseFloat(perc.toFixed(2));

        if (isNaN(perc)) perc = 0;

        return <flex className='gap-10 vertical fit fit-children'>
            <flex className='columns-2'>
                <flex className='vertical'>
                    <label className='medium bolder'>{lang('Bonus Amount')}</label>
                    <span>{currency(promotion.amount, 2, transaction.currency)}</span>
                </flex>
                <flex className='vertical'>
                    <label className='medium bolder'>{lang('Progress')}</label>
                    <flex className='gap-10'>
                        <span>%{perc}</span>
                        <div className='progress-bar blue fx-shadow'>
                            <div className='progress' style={{ width: perc > 100 ? 100 : perc + '%' }} />
                        </div>
                    </flex>

                </flex>
            </flex>
            <flex className='columns-2'>
                <flex className='vertical'>
                    <label className='medium bolder'>{lang('Turnover Multiplier')}</label><span>{promotion.turnoverMultiplier}</span>
                </flex>
                <flex className='vertical'>
                    <label className='medium bolder'>{lang('Deduct Bonus')}</label>
                    <span>{promotion.deductBonusAmount ? lang('Yes') : 'No'}</span>
                </flex>
            </flex>
            <flex className='vertical'>
                <label className='medium bolder'>{lang('Total Sport Bets')}</label><span><a href='#' onClick={() => openBonusBets(consumedPromotionId)}>{promotion.sportBets}</a></span>
            </flex>
            <flex className='columns-2 wrap'>
                <flex className='vertical'>
                    <label className='medium bolder'>{lang('Total Stake')}</label><span>{currency(promotion.totalBet, 2, transaction.currency)}</span>
                </flex>
                <flex className='vertical'>
                    <label className='medium bolder'>{lang('Total Win')}</label><span>{currency(promotion.totalWin, 2, transaction.currency)}</span>
                </flex>
            </flex>
        </flex>;
    };

    return <FluxModal title={props.title} className={`confirm-transaction-modal ${props.className}`} data-has-promotion={transaction.promotion ? 'true' : 'false'} footer={<buttons>
        <Button title={props.confirmTitle ?? 'Confirm'} className='success' onClick={() => {
            props.onConfirm({
                DeductBonusAmount: data.DeductBonusAmount,
                Notes: data.Notes
            });
            Modal.close();
        }} />
        <Button title='Cancel' onClick={() => {
            if (props.onCancel) {
                props.onCancel();
                return;
            }
            Modal.close();
        }} />

    </buttons>}>
        <flex className='vertical gap-10'>
            {!transaction.promotion && <FluxNotice type={NotificationType.Warning} title={lang('Withdraw transaction will be confirmed.')} description={lang('Please confirm, players balance will not change.', [currency(Math.abs(transaction.amount), 2, transaction.currency)])} />}
            {configuration && <FluxCard title='Bonus'>
                {renderConsumedPromotion()}
            </FluxCard>}
            <FluxForm className='tab-next horizontal-fields' key={ready}
                buttons={null}
                onChange={(data) => setData(data)}
                model={model} onSubmit={(data) => {
                    confirmed(data);
                }} onCancel={() => Modal.close()}>
            </FluxForm>
        </flex>
    </FluxModal>;
};

ConfirmWithdrawTransactionModal.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    transaction: PropTypes.object,
    footer: PropTypes.node,
    className: PropTypes.string,
    confirmTitle: PropTypes.string,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
};
