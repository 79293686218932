import './_risk-template.scss';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ConfirmationModal } from '../../../../../../components/modal/ConfirmationModal/ConfirmationModal';
import { API } from '../../../../../../lib/API/Api';
import { RiskLimitEndpoints } from '../../../../../../lib/API/Endpoints';
import { isNullOrEmpty } from '../../../../../../lib/Helpers/StringHelper';
import { lang } from '../../../../../../lib/Localization/language';
import { Modal } from '../../../../../application/FluxApp';
import { Button } from '../../../../../button/Button';
import { ActionButton, FluxCard } from '../../../../../card/FluxCard';
import { currency } from '../../../../../currency/Currency';
import { InputTypes } from '../../../../../form/InputTypes';
import { Icon } from '../../../../../icon/Icon';
import { ErrorModal } from '../../../../../modal/ErrorModal/ErrorModal';
import { FluxModal } from '../../../../../modal/FluxModal';
import { FluxNotice } from '../../../../../notification/FluxNotice';
import { Popper } from '../../../../../notification/FluxPopper';
import { Table } from '../../../../../table/Table';
import { AddNewTemplate } from './AddNewTemplate';

/*

{name:"Id",title:"Id"},
{name:"Key",title:"Key"},
{name:"ParentKey",title:"ParentKey"},
{name:"DataType",title:"DataType"},
{name:"BetType",title:"BetType"},
{name:"OperatorId",title:"OperatorId"},
{name:"Name",title:"Name"},
{name:"LiabilityLimit",title:"LiabilityLimit"},
{name:"StakeLimit",title:"StakeLimit"},
{name:"MaxPayout",title:"MaxPayout"},
{name:"CurrentLiability",title:"CurrentLiability"},
{name:"CurrentStake",title:"CurrentStake"},
{name:"CurrenyPayout",title:"CurrenyPayout"},
{name:"Active",title:"Active"},
*/

const tableModel = {
    fields: [
        { name: 'name', title: 'Name' },
        { name: 'liabilityLimit', title: 'Liability Limit' },
        { name: 'stakeLimit', title: 'Stake Limit' },
        { name: 'maxPayout', title: 'Max Payout' },
        { name: 'investigateTicketOverMultiplier', title: 'Multiplier Limit For Investigation' },
        { name: 'overAsk', title: 'Over Ask', type: InputTypes.Bool },
        {
            name: 'active',
            title: 'Active',
            type: InputTypes.Bool,
            render: (val, row, field, context) => {
                if (val) {
                    return <a href='#' onClick={() => {
                        context.selectTemplate(row);
                    }}>
                        <span>{lang('Used in %1 entities', [row.activeCount])}</span>
                    </a>;
                }
                return <flex>
                    <span>{lang('not used')}</span>
                </flex>;
            }
        }
    ]
};


const tempalteUsageModel = {
    fields: [
        {
            name: 'date',
            title: 'Date',
            type: InputTypes.DateTime
        },
        {
            name: 'id',
            title: 'Id'
        },
        {
            name: 'event-name',
            title: 'Event',
            render: (val, row) => {
                return <flex>{row.name}</flex>;
            }
        },
        {
            name: 'liability',
            title: 'Liability',
            render: (val, row, field, context) => {
                return currency(val, 2, context.defaultCurrency);
            }
        }
    ]
};


export const templateModel = {
    sections: {
        'General': {
            icon: <Icon icon='cogs' type='fa-light' />,
            fields: [
                {
                    name: 'name',
                    title: 'Name',
                    required: true
                }
            ]
        },
        'Limits': {
            icon: <Icon icon='gauge-high' type='fa-light' />,
            fields: [
                {
                    name: 'stakeLimit',
                    type: InputTypes.Text,
                    title: 'Stake Limit',
                    required: true
                },
                {
                    name: 'liabilityLimit',
                    type: InputTypes.Text,
                    title: 'Liability Limit',
                    required: true
                },
                {
                    name: 'maxPayout',
                    type: InputTypes.Text,
                    title: 'Max Payout',
                    required: true
                }
            ]
        },
        'Automation': {
            icon: <Icon icon='octagon-exclamation' type='fa-light' />,
            fields: [
                {
                    name: 'investigateTicketOverMultiplier',
                    type: InputTypes.Text,
                    title: 'Investigate Ticket over Multiplier'
                },
                {
                    name: 'overAsk',
                    type: InputTypes.Bool,
                    title: 'Over Ask'
                }
            ]
        }
    }
};

export const customTemplateModel = {
    sections: {
        'General': {
            icon: <Icon icon='cogs' type='fa-light' />,
            fields: [
                {
                    name: 'name',
                    title: 'Name',
                    readonly: true
                }
            ]
        },
        'Limits': {
            icon: <Icon icon='user-gauge-high' type='fa-light' />,
            fields: [
                {
                    name: 'stakeLimit',
                    type: InputTypes.Text,
                    title: 'Stake Limit'
                },
                {
                    name: 'liabilityLimit',
                    type: InputTypes.Text,
                    title: 'Liability Limit'
                },
                {
                    name: 'maxPayout',
                    type: InputTypes.Text,
                    title: 'Max Payout'
                }
            ]
        },
        'Automation': {
            icon: <Icon icon='octagon-exclamation' type='fa-light' />,
            fields: [
                {
                    name: 'investigateTicketOverMultiplier',
                    type: InputTypes.Text,
                    title: 'Investigate Ticket over Multiplier'
                },
                {
                    name: 'overAsk',
                    type: InputTypes.Bool,
                    title: 'Over Ask'
                }
            ]
        }
    }
};


export const templateViewModel = {
    sections: {
        'Template Information': {
            icon: <Icon icon='cogs' type='fa-light' />,
            fields: [
                {
                    name: 'name',
                    title: 'Name',
                    readonly: true
                }
            ]
        },
        'Limits': {
            icon: <Icon icon='user-gauge-high' type='fa-light' />,
            fields: [
                {
                    name: 'stakeLimit',
                    type: InputTypes.Text,
                    title: 'Stake Limit',
                    readonly: true
                },
                {
                    name: 'liabilityLimit',
                    type: InputTypes.Text,
                    title: 'Liability Limit',
                    readonly: true
                },
                {
                    name: 'maxPayout',
                    type: InputTypes.Text,
                    title: 'Max Payout',
                    readonly: true
                }
            ]
        },
        'Automation': {
            icon: <Icon icon='octagon-exclamation' type='fa-light' />,
            fields: [
                {
                    name: 'investigateTicketOverMultiplier',
                    type: InputTypes.Text,
                    title: 'Investigate Ticket over Multiplier',
                    readonly: true
                },
                {
                    name: 'overAsk',
                    type: InputTypes.Text,
                    title: 'Over Ask',
                    readonly: true
                }
            ]
        }
    }
};

export const ShowAddNewTemplateModal = (template, onSaveComplete, editPopup = false, user = null, data = null) => {
    Modal.open(<FluxModal title={template ? 'Edit Template' : 'Add New Template'} className='add-new-risk-template-modal' footer={<flex className='fit gap-10'>
        <Button title='Save' className={'success'} onClick={() => {
            if (isNullOrEmpty(data.name)) return;
            data.id = template?.id ?? 0;
            
            Object.keys(data).map(x => {
                if (x == 'liabilityLimit' || x == 'maxPayout' || x == 'stakeLimit') {
                    data[x] = parseFloat(data[x]);
                } else if (x == 'id' || x == 'investigateTicketOverMultiplier' || x == 'operatorId') {
                    data[x] = parseInt(data[x]);
                }
            });

            if (editPopup) {
                data.ignore = true;
            }
            if (!data.stakeLimit) {
                data.stakeLimit = 0;
            }
            if (!data.liabilityLimit) {
                data.liabilityLimit = 0;
            }
            if (!data.maxPayout) {
                data.maxPayout = 0;
            }
            if (!data.investigateTicketOverMultiplier) {
                data.investigateTicketOverMultiplier = 0;
            }
            API.post(RiskLimitEndpoints.SaveTemplate(user.loginInformation.UserPrivileges.PointOfView), data).then(() => {
                Popper.pop('information', 'Risk Limit Template', template ? 'Risk templated updated!' : 'A new templated saved!');
                Modal.close();
                onSaveComplete(data);
            }).catch((err) => {
                Modal.open(<ErrorModal title='Unable to save template'>
                    <FluxNotice title='Unable to save template' type='error' description={err?.error?.message} />
                </ErrorModal>)
            });
        }} />
        <Button title='Cancel' onClick={() => {
            Modal.close();
        }} />
    </flex>}>
        <AddNewTemplate id={template?.id ?? 0} template={template ?? null} onUpdateData={(d, u) => {
            data = d;
            user = u;
        }} />
    </FluxModal>);
};

export const ShowRemoveTemplateConfirmModal = (id, user, onSaveComplete) => {
    Modal.open(<ConfirmationModal title='Confirm Removing The Template'
        confirmTitle='Remove Template'
        onConfirm={
            () => {
                API.post(RiskLimitEndpoints.DisableTemplate(user.loginInformation.UserPrivileges.PointOfView, id), {}).then(() => {
                    Popper.pop('information', 'Risk Limit Template', 'Risk template removed!');
                    Modal.close();
                    onSaveComplete();
                }).catch((err) => {
                    Modal.open(<ErrorModal title='Unable to remove template'>
                        <FluxNotice title='Unable to remove template' type='error' description={err?.error?.message} />
                    </ErrorModal>)
                });
            }
        }>
        <p>{lang('Do you want to remove template?')}</p>
    </ConfirmationModal>);
}

export const RiskTemplates = () => {
    const user = useSelector((state) => state.user);
    const [templates, setTemplates] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [customFilter, setCustomFilter] = useState('2');
    const [levelFilter, setLevelFilter] = useState('0');

    const [templateUsage, fetchTemplateUsage] = useState(null);
    const [templateUsageData, setTemplateUsageData] = useState(null);

    useEffect(() => fetchTemplates(), customFilter);
    useEffect(() => fetchTemplates(), levelFilter);

    useEffect(() => {
        fetchTemplates();
    }, []);

    useEffect(() => {
        if (!templates) return;
        setFiltered(templates);
    }, [templates]);


    useEffect(() => {
        if (!templateUsage) return;
        API.post(RiskLimitEndpoints.GetTemplateUsage(user.loginInformation.UserPrivileges.PointOfView, templateUsage.id)).then((result) => {
            setTemplateUsageData(result.result);
        })
    }, [templateUsage]);

    const fetchTemplates = () => {
        API.post(RiskLimitEndpoints.GetAllTemplatesWithFilter(user.loginInformation.UserPrivileges.PointOfView),
            {
                custom: customFilter,
                level: levelFilter,
            }).then((result) => {
                setTemplates(result.result);
            });
    };

    if (templateUsageData) {
        return <FluxCard title={templateUsage.name} className='vertical gap-10' buttons={<flex className='align-right'>
            <ActionButton title='Close' icon='times' onClick={() => {
                setTemplateUsageData(null);
                fetchTemplateUsage(null);
            }} />
        </flex>
        }>
            <Table key={tempalteUsageModel} model={tempalteUsageModel} context={
                {
                    gotoEvent: (e) => {
                        console.log(e);
                    },
                    defaultCurrency: user.loginInformation.features.currency
                }
            } data={templateUsageData.competetions} className={'border radius'} />
        </FluxCard>;
    }

    return <FluxCard title='Templates' className='vertical gap-10' buttons={<flex className='align-right gap-5'>
        <div className='flex gap-5'>
            <label className='light-color'>{lang('Custom')}</label>
            <select defaultValue={customFilter}
                onChange={(e) => {
                    setCustomFilter(e.target.value);
                }}>
                <option value={1}>{lang('All')}</option>
                <option value={2}>{lang('Only Non-Custom')}</option>
                <option value={3}>{lang('Only Custom')}</option>
            </select>
        </div>
        <div className='flex gap-5'>
            <label className='light-color'>{lang('Level')}</label>
            <select defaultValue={levelFilter}
                onChange={(e) => {
                    setLevelFilter(e.target.value);
                }}>
                <option value={0}>{lang('All')}</option>
                <option value={1}>{lang('Sports')}</option>
                <option value={2}>{lang('Regions')}</option>
                <option value={3}>{lang('Leagues')}</option>
                <option value={4}>{lang('Events')}</option>
            </select>
        </div>
        <ActionButton title='Add' icon='plus' onClick={() => {
            ShowAddNewTemplateModal(null, () => fetchTemplates());
        }} />
    </flex>
    }>
        <Table model={tableModel} context={
            {
                selectTemplate: (t) => {
                    fetchTemplateUsage(t);
                }
            }
        } data={filtered ?? []} className={'border radius'}
            buttons={(row) => {
                return <flex className='gap-10'>
                    <Button title='Remove' className='warning' onClick={() => {
                        ShowRemoveTemplateConfirmModal(row.id, user, () => fetchTemplates());
                    }} />
                    <Button title='Edit' className='success' onClick={() => {
                        ShowAddNewTemplateModal(row, () => fetchTemplates());
                    }} />
                </flex>;
            }} />
    </FluxCard>;
};