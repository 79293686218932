/* eslint-disable react/display-name */
import React, { useEffect } from 'react';
import { InputTypes } from '../../components/form/InputTypes';
import { FluxSearch } from '../../components/search/FluxSearch';
import { ManagementEndpoints } from '../../lib/API/Endpoints';
import { UserActivities } from '../../lib/Enums/UserEnums';
import { DateHelpers } from '../../lib/Helpers/DateHelpers';

export const MyUserActivities = (props) => {
    const { user } = { ...props };

    const model = {
        fields: [
            {
                name: 'Created',
                title: 'Entry Date',
                type: InputTypes.DateTime,
                between: true,
                value: {
                    start: DateHelpers.today(),
                    end: DateHelpers.tonight()
                }
            },
            {
                name: 'Username',
                title: 'Username'
            },
            {
                name: 'Email',
                type: InputTypes.Text,
            },
            {
                name: 'Activity',
                title: 'Activity',
                nullable: true,
                type: InputTypes.Select,
                values: UserActivities.sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1).reduce((acc, curr) => (acc[curr] = curr, acc), {})
            }
        ]
    };

    const resultModel = {
        fields: [
            {
                name: 'Id'
            },
            {
                name: 'Created',
                title: 'Date',
                type: InputTypes.DateTime
            },
            {
                name: 'Username',
                title: 'Username'
            },
            {
                name: 'Email',
                type: InputTypes.Text
            },
            {
                name: 'Data',
                title: 'Data',
                render: (val) => {
                    if (val?.indexOf('{') == 0) {
                        return <flex className='vertical code-viewer'>
                            <flex className='code-viewer-header'><span>JSON</span></flex>
                            <flex className='code-viewer-body'>
                                <flex className='code-viewer-content'>
                                    {val}
                                </flex>
                            </flex>
                        </flex>;
                    }
                    return <p className='data-column'>{val}</p>;
                }
            },
            {
                name: 'Activity',
                type: InputTypes.Text
            }
        ]
    };


    useEffect(() => {

    }, []);


    return <FluxSearch
        model={model}
        onPrepareModel={(m) => {
            if (!user) return m;
            m.fields = m.fields.filter(x => x.name != 'Username');
            m.fields = m.fields.filter(x => x.name != 'Email');
            m.fields.push({
                name: 'OperatorUserId',
                value: user.Id,
                hidden: true
            });

            return m;
        }}
        onPrepareResultModel={(m) => {
            if (!user) return m;
            m.fields = m.fields.filter(x => x.name != 'Username');
            m.fields = m.fields.filter(x => x.name != 'Email');
            return m;
        }}
        resultModel={resultModel}
        endpoint={ManagementEndpoints.SearchUserActivity} {...props} />;
};