/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { Modal } from '../../../components/application/FluxApp';
import { FluxModal } from '../../../components/modal/FluxModal';
import { FluxCard } from '../../../components/card/FluxCard';
import { lang } from '../../../lib/Localization/language';
import { FluxForm } from '../../../components/form/FluxForm';

import { Button } from '../../../components/button/Button';
import { FluxTab } from '../../../components/tab/FluxTab';
import { PlayerInformation } from './PlayerInformation';
import { PlayerBalance } from './PlayerBalance';
import { PlayerBonusBalance } from './PlayerBonusBalance';
import { PlayerBonusInformation } from './PlayerBonusInformation';
import { PlayerLastDeposits } from './PlayerLastDeposits';
import { PlayerLastWithdraws } from './PlayerLastWithdraws';
//import { PlayerActivityAfterDeposit } from './PlayerActivityAfterDeposit';
import { PlayerNotes } from './PlayerNotes';
import { API } from '../../../lib/API/Api';
import { SearchPlayerGameHistory } from '../SearchPlayerGameHistory/SearchPlayerGameHistory';
import { isNullOrEmpty } from '../../../lib/Helpers/StringHelper';
import { FluxNotice } from '../../../components/notification/FluxNotice';
import { NotificationType } from '../../../components/notification/FluxNotification';
import { SearchPlayerTransactions } from '../SearchPlayerTransactions/SearchPlayerTransactions';
import { SearchPlayerBetHistory } from '../SearchPlayerBetHistory/SearchPlayerBetHistory';
import { getHistory } from '../../../../routers/AppRouterFlux';
import { PlayerEndpoints } from '../../../lib/API/Endpoints';
import { useSelector } from 'react-redux';
import { SearchPlayersWithFreeSpin } from '../../Promotions/SearchPlayersWithFreeSpin/SearchPlayersWithFreeSpin';
import { LocalStorage } from '../../../lib/Helpers/LocalStorage/LocalStorageHelper';
import { Table } from '../../../components/table/Table';
import { InputTypes } from '../../../components/form/InputTypes';
import { SearchPlayerHorseGreyhoundHistory } from '../SearchPlayerHorseGreyhoundHistory/SearchPlayerHorseGreyhoundHistory';
import { PlayerCasinoBetInformation } from './Statistics/PlayerCasinoBetInformation';
import { PlayerTransactionsMainWalletInformation } from './Statistics/PlayerTransactionsMainWalletInformation';
import { PlayerTransactionsPaymentProviders } from './Statistics/PlayerTransactionsPaymentProviders';
import { PlayerSportBetInformation } from './Statistics/PlayerSportBetInformation';
import { PlayerConsumedBonusInformation } from './Statistics/PlayerConsumedBonusInformation';
import { PlayerBonusTransactionsInformation } from './Statistics/PlayerBonusTransactionsInformation';
import { PlayerPendingDeposits } from './Statistics/PlayerPendingDeposits';
import { PlayerPendingWithdraws } from './Statistics/PlayerPendingWithdraw';
import { appHistory } from '../../../..';
import { SearchPlayerActivity } from '../PlayerActivity/SearchPlayerActivity';
import { SearchPlayerGameTransactions } from '../SearchPlayerGameHistory/SearchPlayerGameTransactions';
import { PlayerInventory } from './PlayerInventory';



export const searchPlayer = (fields, user, onComplete) => {
    const isAffiliate = user.AffiliateId > 0;
    if (isNullOrEmpty(fields.Code)
        && isNullOrEmpty(fields.Id)
        && isNullOrEmpty(fields.Username)
        && isNullOrEmpty(fields.Email)
        && isNullOrEmpty(fields.Phone)) {
        return;
    }

    API.post(PlayerEndpoints.Summary, fields, 'please wait', 'unable to find player', false, 'playersForShortcuts').then((result) => {
        if (Array.isArray(result.result)) {
            Modal.open(<FluxModal title={'Multiple players found'}>
                <p>{lang('Please select one of the players')}</p>
                <table>
                    <tbody>
                        {
                            result.result.map(x => {
                                return <tr key={x.id}>
                                    <td>{x.operator}</td>
                                    <td>{x.id}</td>
                                    <td>{x.username}</td>
                                    <td><Button title='Select' className='success' onClick={() => {
                                        searchPlayer({ Id: x.id }, user, onComplete);
                                        Modal.close();
                                    }} /></td>
                                </tr>;
                            })}
                    </tbody>
                </table>
            </FluxModal>);
            return;
        }

        if (!result.result) {
            onComplete({ error: lang('Player not found') });
            return;
        }
        const profile = window.location.pathname.indexOf('profile') > 0;
        var url = `/players/search?&id=${profile ? '&profile=true' : ''}${result.result.id}`;
        if (isAffiliate) {
            url = `/affiliate/players/search?&id=${profile ? '&profile=true' : ''}${result.result.id}`;
        }

        LocalStorage.saveShortcut('player-search-history', result.result.id, {
            date: new Date(),
            payload: result.result
        });
        getHistory().push(url);
        result.result.updateTime = Date.now();
        if (isNullOrEmpty(result.result.username)) {
            onComplete({ error: lang('Player not found') });
            return;
        }
        onComplete(result.result);
    });
};


export const PlayerCenter = () => {
    const [player, showPlayer] = useState(null);
    const [activityAfterDeposit, setActivityAfterDeposit] = useState(null);
    const [location, setLocation] = useState(null);

    const user = useSelector((state) => state.user.loginInformation);
    const isAffiliate = user.AffiliateId > 0;

    const [targetFilter, setTargetFilter] = useState({
        title: 'Open',
        type: null,
        value: null
    });

    const mainRef = React.createRef();




    useEffect(() => {
        setLocation(window.location.search);
    }, []);

    useEffect(() => {
        if (!player) return;
        try {
            mainRef.current.scrollIntoView({ behavior: "smooth", inline: "nearest" });
        } catch (err) {
            // 
        }
    }, [player]);

    useEffect(() => {
        if (location !== window.location.search) {

            setLocation(window.location.search);

            const urlParams = new URLSearchParams(window.location.search);
            const id = parseFloat(urlParams.get('id'));
            if (isNaN(id)) return;
            if (player == null || player.id !== id) {
                searchPlayer({ Id: id }, user, showPlayer.bind(this));
            }

        }
    })


    const context = {
        showTransactions: (previousRow, transactionId) => {
            var endTransactionId = Number.MAX_SAFE_INTEGER;
            if (previousRow != null) endTransactionId = previousRow.id;
            API.post(`${PlayerEndpoints.ShowActivityAfterDeposit}/${transactionId}/${endTransactionId}/${player.id}`, 'please wait', 'unable to fetch information').then((result) => {
                var data = result.result;
                data.transactionId = transactionId;
                data.endTransactionId = endTransactionId;
                setActivityAfterDeposit(data);
            });
        }
    };


    const playerSummary = () => {
        if (!player) return;
        if (player.error) {
            return <FluxNotice type={NotificationType.Warning} title='Player not found' description={'Username or id not found.'} />;
        }


        var tabButtons = [
            {
                title: 'Transactions'
            },
            {
                title: 'Game Hand History'
            },
            {
                title: 'Game Transaction History'
            },
            {
                title: 'Betting History'
            },
            {
                title: 'Racing History'
            },
            {
                title: 'Resolved Tickets'
            },
            {
                title: 'Free Spin History'
            },
            {
                title: 'Inventory'
            },
            {
                title: 'Activities'
            },
            {
                title: 'Notes'
            }
        ];

        if (user.AffiliateId) {
            tabButtons = [
                {
                    title: 'Game Hand History'
                }
            ];

            return <flex className='vertical gap-10' ref={mainRef} key={player.id}>
                <flex className='borders vertical gap-10'>
                    <flex className='gap-10 columns-3'>
                        <PlayerInformation player={player} />
                        <flex className='vertical gap-10 align-self-stretch align-items-stretch fit-children'>
                            <PlayerBalance player={player} onTransactionComplete={() => searchPlayer({ Id: player.id }, user, showPlayer.bind(this))} />
                            <PlayerBonusBalance player={player} onTransactionComplete={() => searchPlayer({ Id: player.id }, user, showPlayer.bind(this))} />
                        </flex>
                        <PlayerBonusInformation player={player} onTransactionComplete={() => searchPlayer({ Id: player.id }, user, showPlayer.bind(this))} />
                    </flex>
                </flex>
                <flex className='gap-10 columns-4'>
                    <PlayerSportBetInformation player={player} stats={player?.playerStats} />
                    <PlayerCasinoBetInformation player={player} stats={player?.playerStats} />
                    <PlayerTransactionsMainWalletInformation player={player} stats={player?.playerStats} />
                    <PlayerTransactionsPaymentProviders player={player} stats={player?.playerStats} />
                </flex>
                <flex className='gap-10 columns-4'>
                    <PlayerBonusTransactionsInformation player={player} stats={player?.playerStats} />
                    <PlayerConsumedBonusInformation player={player} stats={player?.playerStats} />
                    <PlayerPendingDeposits player={player} stats={player?.playerStats} />
                    <PlayerPendingWithdraws player={player} stats={player?.playerStats} />
                </flex>
                <FluxTab buttons={tabButtons} className='gap-10 fx-shadow fx-radius rounded-buttons'>
                    <SearchPlayerGameTransactions playerId={player.id} />
                    <SearchPlayerGameHistory playerId={player.id} />
                </FluxTab>
            </flex>;
        }

        return <flex className='vertical gap-10' ref={mainRef} key={player.id}>
            <flex className='borders vertical gap-10'>
                <flex className='gap-10 columns-3'>
                    <PlayerInformation player={player} showNotes />
                    <flex className='vertical gap-10 align-self-stretch align-items-stretch fit-children'>
                        <PlayerBalance player={player} onTransactionComplete={() => searchPlayer({ Id: player.id }, user, showPlayer.bind(this))} />
                        <PlayerBonusBalance player={player} onTransactionComplete={() => searchPlayer({ Id: player.id }, user, showPlayer.bind(this))} />
                    </flex>
                    <PlayerBonusInformation player={player} onTransactionComplete={() => searchPlayer({ Id: player.id }, user, showPlayer.bind(this))} />
                </flex>
            </flex>
            <flex className='gap-10 columns-4'>
                <PlayerSportBetInformation player={player} stats={player?.playerStats} />
                <PlayerCasinoBetInformation player={player} stats={player?.playerStats} />
                <PlayerTransactionsMainWalletInformation player={player} />
                <PlayerTransactionsPaymentProviders player={player} />
            </flex>
            <flex className='gap-10 columns-4'>
                <PlayerBonusTransactionsInformation player={player} stats={player?.playerStats} />
                <PlayerConsumedBonusInformation player={player} stats={player?.playerStats} />
                <PlayerPendingDeposits player={player} stats={player?.playerStats} />
                <PlayerPendingWithdraws player={player} stats={player?.playerStats} />
            </flex>
            <FluxTab buttons={tabButtons} className='gap-10 fx-shadow fx-radius rounded-buttons'>
                <flex className='gap-10 align-items-start fit-children'>
                    <FluxTab
                        className={'buttons-centered gap-10 fx-shadow fx-radius rounded-buttons'}
                        buttons={[
                            {
                                title: 'Summary'
                            },
                            {
                                title: 'Search In Transactions'
                            }
                        ]}>
                        <flex className='gap-10 align-items-start columns-2'>
                            <PlayerLastDeposits player={player} context={context} onTransactionComplete={() => searchPlayer({ Id: player.id }, user, showPlayer.bind(this))} />
                            <PlayerLastWithdraws player={player} context={context} onTransactionComplete={() => searchPlayer({ Id: player.id }, user, showPlayer.bind(this))} />
                        </flex>
                        <SearchPlayerTransactions playerId={player.id} />
                    </FluxTab>
                </flex>
                <SearchPlayerGameTransactions playerId={player.id} />
                <SearchPlayerGameHistory playerId={player.id} />
                <SearchPlayerBetHistory playerId={player.id} />
                <SearchPlayerHorseGreyhoundHistory searchOnStart playerId={player.id} />
                <SearchPlayerBetHistory playerId={player.id} resolved />
                <SearchPlayersWithFreeSpin player={player} />
                <PlayerInventory player={player} />
                <SearchPlayerActivity playerId={player.id} />
                <PlayerNotes player={player} />
            </FluxTab>
        </flex>;
    };

    const renderSearchHistory = () => {
        var searchHistory = LocalStorage.getShortcut('player-search-history');
        if (!searchHistory) return;
        if (Object.keys(searchHistory).length == 0) return;
        return <FluxCard title='Search History' toggleBody hideBody>
            <Table
                model={{
                    fields: [
                        {
                            name: 'Date',
                            title: 'Date',
                            type: InputTypes.DateTime
                        },
                        {
                            name: 'PlayerId',
                            title: 'Id'
                        },
                        {
                            name: 'Username',
                            title: 'Username'
                        }
                    ]
                }}
                data={Object.values(searchHistory).sort((a, b) => a.date - b.date).slice(0, 10).map(x => {
                    return {
                        Date: x.date,
                        PlayerId: x.payload.id,
                        Username: x.payload.username
                    };
                })}
                buttons={(row) => {
                    return <Button className='success' title='Open profile' onClick={() => {
                        const profile = window.location.pathname.indexOf('profile') > 0;
                        var url = `/players/search?&id=${row.PlayerId}${profile ? '&profile=true' : ''}`;
                        if (isAffiliate) {
                            url = `/affiliate/players/search?&id=${row.PlayerId}${profile ? '&profile=true' : ''}`;
                        }
                        getHistory().push(url);
                    }} />;
                }} />
        </FluxCard>;
    };

    return <flex className='vertical gap-10'>
        {isAffiliate && <FluxCard>
            <flex className='gap-5'>
                <Button title='Back' className={'success'} onClick={() => {
                    getHistory().push('/affiliate/players');
                }} />
            </flex>
        </FluxCard>}
        <FluxCard title='Open player center' className={isAffiliate ? 'hidden' : ''}>
            <FluxForm
                key={player}
                data={{ Id: player?.id ?? '' }}
                className={'horizontal'}
                buttons={null}
                model={{
                    fields: [
                        {
                            name: 'Id',
                            title: 'Id',
                            onKeyUp: (e) => {
                                setTargetFilter({
                                    type: 'Id',
                                    title: lang('Open profile of player', [e.target.value]),
                                    value: e.target.value
                                });
                                if (e.key == 'Enter') {
                                    searchPlayer({ 'Id': parseFloat(e.target.value) }, user, showPlayer.bind(this));
                                }
                            }
                        },
                        {
                            name: 'Code',
                            title: 'Code',
                            onKeyUp: (e) => {
                                setTargetFilter({
                                    type: 'Code',
                                    title: lang('Open profile of player', [e.target.value]),
                                    value: e.target.value
                                });
                                if (e.key == 'Enter') {
                                    searchPlayer({ 'Code': parseFloat(e.target.value) }, user, showPlayer.bind(this));
                                }
                            }
                        },
                        {
                            name: 'Username',
                            title: 'Username',
                            onKeyUp: (e) => {
                                setTargetFilter({
                                    type: 'Username',
                                    title: lang('Open profile of player', [e.target.value]),
                                    value: e.target.value
                                });
                                if (e.key == 'Enter') {
                                    searchPlayer({ 'Username': e.target.value }, user, showPlayer.bind(this));
                                }
                            }
                        },
                        {
                            name: 'Email',
                            title: 'Email',
                            onKeyUp: (e) => {
                                setTargetFilter({
                                    type: 'Email',
                                    title: lang('Open profile of player', [e.target.value]),
                                    value: e.target.value
                                });
                                if (e.key == 'Enter') {
                                    searchPlayer({ 'Email': e.target.value }, user, showPlayer.bind(this));
                                }
                            }
                        },
                        {
                            name: 'Phone',
                            title: 'Phone',
                            onKeyUp: (e) => {
                                setTargetFilter({
                                    type: 'Phone',
                                    title: lang('Open profile of player', [e.target.value]),
                                    value: e.target.value
                                });
                                if (e.key == 'Enter') {
                                    searchPlayer({ 'Phone': e.target.value }, user, showPlayer.bind(this));
                                }
                            }
                        }
                    ]
                }}>
                <flex className='gap-10'>
                    <Button title={targetFilter.title} className={!targetFilter.value ? 'disabled' : 'success'} onClick={() => {
                        var field = {

                        };
                        field[targetFilter.type] = targetFilter.value;
                        searchPlayer(field, user, showPlayer.bind(this));
                    }} />
                    <Button title={'Clear'} className={'success'} onClick={() => {
                        appHistory.push('/players/search');
                        showPlayer(null);
                    }} />
                </flex>
            </FluxForm>
        </FluxCard>
        {renderSearchHistory()}
        {playerSummary()}
    </flex>;
};

