import React from 'react';
import { FluxAction } from '../../components/application/FluxAction';
import { FluxPage } from '../../components/page/FluxPage';
import { ActionTypes } from '../../lib/ActionManager/FluxActions';
import { AssignFreeSpin } from './AssignFreeSpin/AssignFreeSpin';
import { ManagePromotions } from './ManagePromotions/ManagePromotions';
import {  SearchPlayersWithFreeSpin } from './SearchPlayersWithFreeSpin/SearchPlayersWithFreeSpin';
import { SearchFreespinCampaigns } from './SearchFreespinCampaigns/SearchFreespinCampaigns';
import { SearchPlayersWithBonus } from './SearchPlayersWithBonus/SearchPlayersWithBonus';
import { SearchPendingPromotions } from './SearchPendingPromotions/SearchPendingPromotions';
import { TournamentCenter } from './TournamentCenter/TournamentCenter';

export const PromotionsPage = () => {
    return <FluxPage action={ActionTypes.Promotions}>
        <FluxAction action={ActionTypes.ManagePromotions}><ManagePromotions /></FluxAction>
        <FluxAction action={ActionTypes.FreeSpinCampaigns}><SearchFreespinCampaigns /></FluxAction>
        <FluxAction action={ActionTypes.AssignFreeSpinPromotion}><AssignFreeSpin /></FluxAction>
        <FluxAction action={ActionTypes.SearchPlayersWithFreeSpin}><SearchPlayersWithFreeSpin /></FluxAction>
        <FluxAction action={ActionTypes.TournamentCenter}><TournamentCenter /></FluxAction>
        <FluxAction action={ActionTypes.SearchPlayersWithBonus}><SearchPlayersWithBonus /></FluxAction>
        <FluxAction action={ActionTypes.SearchPlayersPendingPromotions}><SearchPendingPromotions /></FluxAction>
    </FluxPage>;
};