/* eslint-disable quotes */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { Modal } from '../../../../components/application/FluxApp';
import { Button } from '../../../../components/button/Button';
import { FluxCard } from '../../../../components/card/FluxCard';
import { FluxInput } from '../../../../components/form/Components/FluxInput';
import { InputTypes } from '../../../../components/form/InputTypes';
import { Icon } from '../../../../components/icon/Icon';
import { ConfirmationModal } from '../../../../components/modal/ConfirmationModal/ConfirmationModal';
import { ErrorModal } from '../../../../components/modal/ErrorModal/ErrorModal';
import { FluxNotice } from '../../../../components/notification/FluxNotice';
import { NotificationType } from '../../../../components/notification/FluxNotification';
import { Popper } from '../../../../components/notification/FluxPopper';
import { API } from '../../../../lib/API/Api';
import { CMSEndpoints, SportEndpoints } from '../../../../lib/API/Endpoints';
import { convertLSportIdToBetradarSportId } from '../../../../lib/Helpers/LsportConversationTools';
import { lang } from '../../../../lib/Localization/language';
import './_setup-overview-markets.scss';

export const SetupOverviewMarkets = () => {
    const [overviewMarkets, setOverviewMarkets] = useState(null);
    const [orgOverviewMarkets, setOrgOverviewMarkets] = useState(null);
    const [markets, setMarkets] = useState(null);
    const [selectedSportId, setSelectedSportId] = useState(null);
    const [acknowledge, setAcknowledge] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        API.post(CMSEndpoints.GetOverviewMarkets, null, 'please wait', 'unable to load').then((overview) => {
            setOverviewMarkets(JSON.parse(overview.result));
            setOrgOverviewMarkets(overview.result);
        });
    }, []);

    useEffect(() => {
        if (!selectedSportId) return;

        API.post(`${SportEndpoints.Markets}/${selectedSportId}`).then((result) => {
            setMarkets(result.result);
        });
    }, [selectedSportId]);


    const save = () => {
        var res = true;
        const post = () => {
            API.post(CMSEndpoints.SaveOverviewMarkets, overviewMarkets, 'please wait').then(() => {
                Popper.pop('information', 'Overview markets updated!', 'Overview markets updated!');
            }).catch((error) => {
                Modal.open(<ErrorModal title={'Error'}>{lang('API Error.')}</ErrorModal>);
            });
        };

        const validateData = () => {
            const checkMarkets = (isLive, type, sportId, sport) => {
                type.forEach(liveData => {
                    Object.keys(liveData).forEach(dataKey => {
                        const data = liveData[dataKey];
                        if (!data.typeId) {
                            setError(lang('Please contact with our support.'));
                            res = false;
                        }

                        if ('title' in data) {
                            if (!data['title'] || data['title'].trim() == '') {
                                setError(lang(`Sport: ${sport.name} | TypeId: ${data.typeId} => title cannot be empty`));
                                res = false;
                            }
                        }
                        if ('selectionTitle' in data) {
                            const split = data['selectionTitle'].split(',');
                            const org = JSON.parse(orgOverviewMarkets);
                            const orgMarkets = org[sportId][isLive ? 'live' : 'preMatch'];
                            const orgMarket = orgMarkets?.find(x => x?.hasOwnProperty(dataKey))[dataKey];
                            const orgMarketSplit = orgMarket['selectionTitle'].split(',');
                            if (split.length != orgMarketSplit.length) {
                                setError(lang(`Sport: ${sport.name} | TypeId: ${data.typeId} => DO NOT TYPE , (comma) in selection title`));
                                res = false;
                            }
                            if (split.length > 0) {
                                split.forEach(element => {
                                    if (!element || element.trim() == '') {
                                        setError(lang(`Sport: ${sport.name} | TypeId: ${data.typeId} => none of the selection titles cannot be empty`));
                                        res = false;
                                    }
                                });
                            } else {
                                if (!split[0] || split[0] == '') {
                                    setError(lang(`Sport: ${sport.name} | TypeId: ${data.typeId} => selection title cannot be empty`));
                                    res = false;
                                }
                            }
                        }
                        if ('selections' in data) {
                            data.selections.forEach(element => {
                                if (!element || element.trim() == '') {
                                    setError(lang(`Sport: ${sport.name} | TypeId: ${data.typeId} => selections cannot be empty`));
                                    res = false;
                                }
                            });
                        }
                    })
                });
            }
            Object.keys(overviewMarkets).map(sportId => {
                const sport = overviewMarkets[sportId];
                const live = sport['live'];
                const preMatch = sport['preMatch'];
                checkMarkets(false, preMatch, sportId, sport)
                checkMarkets(true, live, sportId, sport)
            });
        }
        validateData();
        if (!res) {
            return;
        }
        setError(null);
        Modal.open(<ConfirmationModal title={'Please confirm'} onConfirm={() => {
            post();
        }}>{lang('Please confirm the operation.')}</ConfirmationModal>);
    };

    const updateMarket = (marketId, isLive, field, value, selectionIndex = null) => {
        if (!selectedSportId) return;
        var copy = { ...overviewMarkets };
        if (field.type == InputTypes.Number) {
            value = parseFloat(value);
        }
        const betRadarId = convertLSportIdToBetradarSportId(selectedSportId);
        var markets = copy[betRadarId]?.[isLive ? 'live' : 'preMatch'];
        if (!markets) return;
        var marketField = markets?.find(x => x?.hasOwnProperty(marketId));
        if (!marketField) return;
        var market = marketField?.[marketId];

        if (!market) return;
        if (selectionIndex === 0 || selectionIndex > 0) {
            const data = market[field.name];
            if (typeof data === 'string') {
                if (value.includes(',')) return;
                let parts = data.split(',');

                parts[selectionIndex] = value;

                let updatedString = parts.join(',');
                market[field.name] = updatedString;
            } else if (Array.isArray(data)) {
                if (market[field.name][selectionIndex] == value) {
                    return;
                }
                market[field.name][selectionIndex] = value;
            } else {
                return;
            }

        } else {
            if (market[field.name] == value) {
                return;
            }
            market[field.name] = value;
        }

        setOverviewMarkets(copy);
    };

    const getMarketConfig = (lsportId, live) => {
        const betRadarId = convertLSportIdToBetradarSportId(lsportId);
        if (overviewMarkets && betRadarId) {
            const sport = overviewMarkets[betRadarId];
            if (sport) {
                if (live) {
                    return sport['live'];
                }
                return sport['preMatch'];
            }
        }
        return [];
    };

    const getMarketName = (marketId) => {
        return (markets ?? []).find(x => x.id == marketId)?.name;
    };

    const renderSelectionTitle = (market, marketId, isLive) => {
        const splitted = market.selectionTitle.split(',');
        if (splitted.length == 1) {
            return <FluxInput
                field={{
                    name: 'selectionTitle',
                    title: 'selectionTitle',
                    type: InputTypes.Text,
                    value: market.selectionTitle
                }}
                onChange={(field, value) => updateMarket(marketId, isLive, field, value)} />;
        }

        return <FluxCard>
            {
                splitted.map((row, index) => {
                    return (<FluxInput
                        key={index}
                        field={{
                            name: 'selectionTitle',
                            title: 'selectionTitle',
                            type: InputTypes.Text,
                            value: row,
                        }}
                        onChange={(field, value) => updateMarket(marketId, isLive, field, value, index)} />);
                })
            }
        </FluxCard>;
    }

    const renderMarket = (market, marketId, isLive) => {
        if (!market) return <></>;

        return <forminput className='gap-5 market-render'>
            {'title' in market &&
                <flex className='input-title fit-children'>
                    <span>{lang('Title')}</span>
                    <FluxInput
                        field={{
                            name: 'title',
                            title: 'title',
                            type: InputTypes.Text,
                            value: market.title
                        }}
                        onChange={(field, value) => updateMarket(marketId, isLive, field, value)}
                    />
                </flex>
            }
            {'selectionTitle' in market &&
                <flex className='input-title fit-children'>
                    <span>{lang('Selection Title/s')}</span>
                    {renderSelectionTitle(market, marketId, isLive)}
                </flex>
            }
            {'selections' in market &&
                <flex className='input-title fit-children'>
                    <span>{lang('Selections')}</span>
                    <FluxCard>
                        {
                            market.selections.map((row, index) => {
                                return (<FluxInput
                                    key={index}
                                    field={{
                                        name: 'selections',
                                        title: 'selections',
                                        type: InputTypes.Text,
                                        value: row,
                                    }}
                                    onChange={(field, value) => updateMarket(marketId, isLive, field, value, index)} />);
                            })
                        }
                    </FluxCard>
                </flex>
            }
            {'typeId' in market &&
                <flex className='input-title fit-children'>
                    <span>{lang('Type Id')}</span>
                    <FluxInput
                        field={{
                            name: 'typeId',
                            title: 'typeId',
                            type: InputTypes.Number,
                            value: market.typeId,
                            readonly: true
                        }}
                        onChange={(field, value) => updateMarket(marketId, isLive, field, value)} />
                </flex>
            }
            {'weight' in market &&
                <flex className='input-title fit-children'>
                    <span>{lang('Weight')}</span>
                    <FluxInput
                        field={{
                            name: 'weight',
                            title: 'weight',
                            type: InputTypes.Number,
                            value: market.weight
                        }}
                        onChange={(field, value) => updateMarket(marketId, isLive, field, value)} />
                </flex>
            }
            {'way' in market &&
                <flex className='input-title fit-children'>
                    <span>{lang('Way')}</span>
                    <FluxInput
                        field={{
                            name: 'way',
                            title: 'way',
                            type: InputTypes.Number,
                            value: market.way,
                            readonly: true
                        }}
                        onChange={(field, value) => updateMarket(marketId, isLive, field, value)} />
                </flex>
            }
            {'mainGoalLine' in market &&
                <flex className='input-title fit-children'>
                    <span>{lang('Main Goal Line')}</span>
                    <FluxInput
                        field={{
                            name: 'mainGoalLine',
                            title: 'mainGoalLine',
                            type: InputTypes.Bool,
                            value: market.mainGoalLine === true
                        }}
                        onChange={(field, value) => updateMarket(marketId, isLive, field, value)} />
                </flex>
            }
            {'specifierOptions' in market &&
                <flex className='input-title fit-children'>
                    <span>{lang('Specifier Options')}</span>
                    <FluxInput
                        field={{
                            name: 'specifierOptions',
                            title: 'specifierOptions',
                            type: InputTypes.Bool,
                            value: market.specifierOptions === true
                        }}
                        onChange={(field, value) => updateMarket(marketId, isLive, field, value)} />
                </flex>
            }
            {'displaySpecifierTitles' in market &&
                <flex className='input-title fit-children'>
                    <span>{lang('Display Specifier Titles')}</span>
                    <FluxInput
                        field={{
                            name: 'displaySpecifierTitles',
                            title: 'displaySpecifierTitles',
                            type: InputTypes.Bool,
                            value: market.displaySpecifierTitles === true
                        }}
                        onChange={(field, value) => updateMarket(marketId, isLive, field, value)} />
                </flex>
            }
            {'outright' in market &&
                <flex className='input-title fit-children'>
                    <span>{lang('Outright')}</span>
                    <FluxInput
                        field={{
                            name: 'outright',
                            title: 'outright',
                            type: InputTypes.Bool,
                            value: market.outright === true
                        }}
                        onChange={(field, value) => updateMarket(marketId, isLive, field, value)} />
                </flex>
            }
        </forminput>
    }

    const renderType = (isLive) => {
        return <rows className='gap-5'>
            <section>
                <header>
                    <Icon icon={isLive ? 'stopwatch' : 'calendar-alt'} type='fa-light' /><span>{lang(isLive ? 'Live' : 'Prematch')}</span>
                </header>
                <fields className='gap-20 vertical'>
                    {
                        getMarketConfig(selectedSportId, isLive).map(row => {
                            return Object.keys(row).map((marketId, index) => {
                                const market = row[marketId];
                                if (!market) return <></>;
                                return (<FluxCard key={marketId + '-' + index}
                                    title={<flex className='input-title fit-children gap-5 justify-content-center center'>
                                        <b>{lang(getMarketName(marketId))}</b>
                                    </flex>}>
                                    {renderMarket(market, marketId, isLive)}
                                </FluxCard>);
                            })
                        })
                    }
                </fields>
            </section>
        </rows>
    }

    return <flex className='vertical gap-10 setup-overview-markets'>
        <FluxCard title={<flex className='gap-10 fit'>
            <span className='bold title'>{lang('Setup Overview Markets')}</span>
            <flex className='center sport-selector'>
                {acknowledge && <FluxInput
                    field={{
                        name: 'Sport',
                        title: 'Sport',
                        type: InputTypes.Select,
                        required: false,
                        nullable: true,
                        data: {
                            source: SportEndpoints.Categories,
                            parameters: {},
                            parser: (result) => {
                                var results = [];
                                result.result.sort((a, b) => a.weight - b.weight).filter(x => x.enabled).map(x => {
                                    results.push({ title: `${x.id} - ${x.name}`, value: x.id });
                                });
                                return results;
                            }
                        }
                    }}
                    onChange={(field, value) => {
                        setSelectedSportId(value);
                    }}
                    data={{}}
                ></FluxInput>}
            </flex>
            <flex className='align-right'>
                {acknowledge && selectedSportId && < Button title={'Save'} className={'success'} onClick={() => save()} />}
            </flex>
        </flex>
        }>
            {!acknowledge && <FluxNotice type={NotificationType.Warning}
                title={'User Responsibility Disclaimer'}
                description='By using this feature, you acknowledge that any actions taken are your responsibility. We are not liable for any issues or data loss resulting from improper use. Please ensure you understand the functionality before proceeding.'>
                <Button title={'I Acknowledge.'} className={'success'} onClick={() => setAcknowledge(true)} />
            </FluxNotice>}
            {acknowledge && error && < FluxNotice type={NotificationType.Error}
                title={'ERROR!'}
                description={error} />}
            {acknowledge && !selectedSportId && < FluxNotice type={NotificationType.Information}
                title={'Choose Sport from above'}
                description='Please choose a sport from above to continue' />
            }
            {
                acknowledge && selectedSportId && <flex className='vertical gap-20'>
                    <fluxform className='gap-20 grid form-columns-2'>
                        {renderType(false)}
                        {renderType(true)}
                    </fluxform>
                </flex>
            }
        </FluxCard >
    </flex >;
};