import React from 'react';
import { Icon } from '../../../components/icon/Icon';
import { ActionButton, FluxCard } from '../../../components/card/FluxCard';
import { lang } from '../../../lib/Localization/language';
import { FluxForm } from '../../../components/form/FluxForm';
import { CampaignResultModel } from '../SearchFreespinCampaigns/SearchFreespinCampaigns';

export const CreateFreespinsWithCampaign = (props) => {
    const { campaign, children, onCancel } = { ...props };

    return <flex className='vertical gap-10'>
        <FluxCard title={<React.Fragment>
            <span>{lang('Campaign')}</span>
            <Icon icon='angle-right' type='fa-light' />
            <span>{campaign.Name}</span>
        </React.Fragment>} className='no-padding' buttons={<ActionButton title='Cancel' icon='times' onClick={() => {
            onCancel();
        }} />}>
            <FluxForm model={CampaignResultModel} readonly data={campaign} buttons={null} columns={3} />
        </FluxCard>
        {children}
    </flex>;
};