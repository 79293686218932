import React from 'react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button } from '../../../v2/Components/Controls/Button/Button';
import { API } from '../../../v2/Lib/Api/Api';
import { Modal } from '../application/FluxApp';
import { ConfirmationModal } from '../modal/ConfirmationModal/ConfirmationModal';
import { Table } from '../table/Table';
import { Endpoints } from '../../../components/Application/Application';
import { lang } from '../../lib/Localization/language';
import { FluxCard } from '../card/FluxCard';
import { PlayerVerificationType } from '../../../components/Centrum/_models/ModelEnums';
import { InputTypes } from '../../../v2/Components/Controls/Form/FormInput';

const downloadDocument = (val) => {
    if (val) {
        return <span onClick={() => onColumnClicked(val)}><a href='javascript:void()'>{lang('Download Document')}</a></span>;
    }

};

const onColumnClicked = (val) => {
    const newWindow = window.open(val, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
};

const uploadedDocumentsModel = {
    fields: [
        { name: 'name', title: 'Name', type: InputTypes.Text },
        {
            name: 'mediaLink',
            title: 'Media Link',
            render: (val, row) => {
                return downloadDocument(val, row);
            }
        },
        { name: 'timeCreated', title: 'Time Created', type: InputTypes.DateTime, render: (val) => new Date(val).toLocaleString() },
        { name: 'contentType', title: 'Content Type', type: InputTypes.Text },

    ]
};




export const UploadedDocument = (props) => {
    const [documents, setDocuments] = useState([]);
    const [status, setStatus] = useState(props.Status);

    useEffect(() => {
        API.post(Endpoints.UploadedDocuments + props.PlayerId + '/' + props.DocumentKey, {}).then((result) => {
            try {
                if (result.result) {
                    var data = {
                        result: result.result
                    };

                    console.log(data);

                    setDocuments(data);
                }
            } catch (err) {
                setDocuments([]);
            }
        });
    }, []);


    const updateStatus = (newStatus) => {
        var docKey = props.DocumentKey;

        let notes = '';
        var message = 'Do you want to set status to unverified?';
        switch (newStatus) {
            case 1:
                message = 'Do you want to set status to pending?';
                break;
            case 2:
                message = 'Do you want to reject?';
                break;
            case 3:
                message = 'Do you want to verify?';
                break;
        }
        Modal.open(<ConfirmationModal title={lang('Please confirm')}
            onConfirm={
                () => {
                    API.post(Endpoints.UploadedDocuments + props.PlayerId + '/' + (newStatus) + '/' + docKey, {
                        notes: notes
                    }, 'Please wait', 'Operation failed').then(() => {
                        setStatus(newStatus);
                    }).catch(() => {

                    });
                }
            }>
            {lang(message)}
            <div className='flex vertical gap-5'>
                <label>{lang('Notes')}</label>
                <textarea defaultValue={notes}
                    onChange={(e) => {
                        notes = e.target.value;
                    }}></textarea>
            </div>
        </ConfirmationModal>);
    };

    return <FluxCard>
        <div className='flex fit'>
            <div className='flex gap-5'>
                <label>Status</label><span>{PlayerVerificationType[status]}</span>
                <div className='align-right'>
                    {status !== 1 && <Button title='Pending' className={''} onClick={() => updateStatus(1)} />}
                    {status !== 2 && <Button title='Reject' className={''} onClick={() => updateStatus(2)} />}
                    {status != 3 && <Button title='Confirm' className={''} onClick={() => updateStatus(3)} />}
                    {status == 3 && <Button title='Set to unverified' className={''} onClick={() => updateStatus(0)} />}
                </div>
            </div>
        </div>
        <div className='seperator'></div>
        {documents.result &&
            <Table key={props.DocumentKey} data={documents?.result ?? []}
                model={uploadedDocumentsModel}
                selectable={false}
                multipleSelect={false}
                showResultsSummary={false}
                showActions={false}
            />
        }
    </FluxCard>;
};

UploadedDocument.propTypes = {
    DocumentKey: PropTypes.string,
    PlayerId: PropTypes.number,
    Verified: PropTypes.bool,
    Status: PropTypes.int,
    onVerified: PropTypes.func,
    onUnverified: PropTypes.func,
    onColumnClicked: PropTypes.func,
    toggleDocumentVerified: PropTypes.func
};