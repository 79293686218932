import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export const readFromFile = (filename) => {
    var url = filename;

    var promise = new Promise((resolve, reject) => {
        try {
            /* set up async GET request */
            var req = new XMLHttpRequest();
            req.open('GET', url, true);
            req.responseType = 'arraybuffer';

            req.onload = async function () {
                var d = new Uint8Array(req.response);
                const workbook = new ExcelJS.Workbook();
                await workbook.xlsx.load(d);
                resolve(workbook);
            };
            req.send();
        } catch (err) {
            reject(err);
        }
    });
    return promise;
};


export const saveXLSX = async (workbook, filename) => {
    const buffer = await workbook.xlsx.writeBuffer();

    saveAs(
        new Blob([buffer], {
            type: 'application/octet-stream',
        }),
        filename
    );
};

