import './_notification.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { NotificationType } from './FluxNotification';
import { Icon } from '../icon/Icon';
import { lang } from '../../lib/Localization/language';

export const FluxNotice = (props) => {
    const renderIcon = () => {
        switch (props.type) {
            case NotificationType.Information:
                return <Icon icon='square-info' type='fa-regular' size='2x' />;
            case NotificationType.Success:
                return <Icon icon='thumbs-up' type='fa-regular' size='2x' />;
            case NotificationType.Warning:
                return <Icon icon='square-exclamation' type='fa-regular' size='2x' />;
            case NotificationType.Error:
                return <Icon icon='triangle-exclamation' type='fa-regular' size='2x' />;

        }
    };

    return <notice type={props.type} className={props.small ? 'notice-small fx-shadow' : 'fx-shadow'}>
        {renderIcon()}
        <content>
            <header>{lang(props.title)}</header>
            <description>{lang(props.description)}</description>
        </content>
        {props.children}
    </notice>;
};

FluxNotice.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    small: PropTypes.bool,
    children: PropTypes.node
};