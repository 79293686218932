import React from 'react';
import { FluxTab } from '../../../../../components/tab/FluxTab';
import { PromotionGeneralSettings } from '../PromotionGeneralSettings';
import { PromotionGroupSettings } from '../PromotionGroupSettings';


export const ReferralProgram = (props) => {
    var buttons = [
        {
            title: 'General Settings'
        },
        {
            title: 'Group Settings'
        }
    ];

    return <FluxTab buttons={buttons} className='gap-10 fx-shadow fx-radius rounded-buttons campaign-settings'>
        <PromotionGeneralSettings {...props} />
        <PromotionGroupSettings {...props} />
    </FluxTab>;
};