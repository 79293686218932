/* eslint-disable react/display-name */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FluxSearch } from '../../../components/search/FluxSearch';
import { InputTypes } from '../../../components/form/InputTypes';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { HistoryEndpoints } from '../../../../v2/Lib/Api/Api';
import { ProviderGameTypes } from '../../../lib/Enums/GameEnums';
import { calculateRTP } from '../../../lib/Helpers/RTPHelper';
import { ActionTypes } from '../../../lib/ActionManager/FluxActions';
import { footerCurrencyValues } from '../../../lib/Helpers/Reports/Footer';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { lang } from '../../../lib/Localization/language';
import { Icon } from '../../../components/icon/Icon';
import { SearchPlayerGameHistory } from '../SearchPlayerGameHistory/SearchPlayerGameHistory';
import { FluxCard } from '../../../components/card/FluxCard';
import { Button } from '../../../components/button/Button';
import { currency } from '../../../components/currency/Currency';


const model = {
    fields: [
        {
            name: 'OperatorId',
            title: 'Operator',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'operators',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'PlayerId',
            title: 'Player Id'
        },
        {
            name: 'EntryDate',
            title: 'Date',
            type: InputTypes.DateTime,
            between: true,
            value: {
                start: DateHelpers.yesterday(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'Id',
            title: 'Session Id'
        },
        {
            name: 'ProviderId',
            title: 'Provider',
            type: InputTypes.Select,
            nullable: true,
            multiple: true,
            //value: [191341, 191327],
            data: {
                source: 'providers/gaming',
                key: 'id',
                title: ['display']
            }
        },
        {
            name: 'GameType',
            title: 'GameType',
            type: InputTypes.Select,
            nullable: true,
            multiple: true,
            values: ProviderGameTypes
        },
    ]
};


const resultPlayerGameHistory = {
    fields: [
        {
            name: 'id',
            title: 'Id'
        },
        {
            name: 'entryDate',
            title: 'Time',
            type: InputTypes.DateTime
        },
        {
            name: 'username',
            title: 'Username',
            render: (val, row) => {
                return <NavLink to={`/players/search?&id=${row.playerId}`}>{val}</NavLink>;
            }
        },
        {
            name: 'playerGroup',
            title: 'Group'
        },
        {
            name: 'provider',
            title: 'Provider'
        },
        {
            name: 'gameType',
            title: 'Game Type',
            type: InputTypes.Select,
            values: ProviderGameTypes
        },
        {
            name: 'gameName',
            title: 'Game'
        },
        {
            name: 'totalHands',
            title: 'Total Hands'
        },
        {
            name: 'totalBet',
            title: 'Bet',
            type: InputTypes.Number,
            render: (val, row) => {
                return currency(val, 2, row.currency);
            },
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'totalWin',
            title: 'Win',
            type: InputTypes.Number,
            render: (val, row) => {
                return currency(val, 2, row.currency);
            },
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'ggr',
            title: 'GGR',
            type: InputTypes.Number,
            render: (val, row) => {
                return currency(val, 2, row.currency);
            },
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'rtp',
            title: 'RTP',
            type: InputTypes.Number,
            render: (val, row) => calculateRTP({ Bet: row.bet, Win: row.win })
        },
    ],
    onRenderRow: (rowObject) => {
        if (rowObject.data.win > Math.abs(rowObject.data.bet)) {
            rowObject.className = 'player-win';
        } else {
            if (rowObject.data.Type == 1) {
                rowObject.className = 'player-lost';
            }
        }
        return rowObject;
    }
};




export const SearchPlayerGameSessions = (props) => {
    const [session, setSession] = useState(null);
    const user = useSelector((state) => state.user.loginInformation);
    const title = () => {
        if (props.retail) {
            return <React.Fragment><span>{lang('Retail')}</span><Icon icon='chevron-right' type='fa-light' /><span>{props.retail.Name}</span><Icon icon='chevron-right' type='fa-light' /><span>{lang('Search in Player Game Sessions')}</span></React.Fragment>;
        }
        if (props.affiliate) {
            return <React.Fragment><span>{lang('Affiliate')}</span><Icon icon='chevron-right' type='fa-light' /><span>{props.affiliate.Name}</span><Icon icon='chevron-right' type='fa-light' /><span>{lang('Search in Player Game Sessions')}</span></React.Fragment>;
        }
        return 'Search in Player Game Sessions';
    };

    return <flex className='vertical gap-10'>
        {session &&
            <FluxCard>
                <flex className='gap-5'>
                    <span>{lang('Player hands on session id %1', [session.id])}</span>
                    <Button title='Close' className='success align-right' onClick={() => setSession(null)} />
                </flex>
            </FluxCard>
        }
        {session && <SearchPlayerGameHistory playerId={session.playerId} sessionId={session.id} searchOnStart />}
        <FluxSearch
            className={session ? 'hidden' : ''}
            title={title()}
            model={model}
            resultModel={resultPlayerGameHistory}
            actions={ActionTypes.SearchPlayerGameHistory}
            endpoint={HistoryEndpoints.PlayerSessionHistory}
            onActionClicked={(action, selected) => {
                switch (action.type) {
                    case ActionTypes.GameHandDetails:
                        setSession(selected);
                        break;
                }
            }}
            onPrepareModel={(model) => {
                var copy = { ...model };
                copy.name = 'player-game-session-history';
                if (props.affiliate) {
                    copy.name = 'player-game-session-history-affiliate';
                    copy.fields = copy.fields.filter(x => x.name != 'OperatorId');
                    copy.fields.push({ name: 'AffiliateId', value: props.affiliate.Id, exact: true, and: true, hidden: true });
                    return copy;
                }

                if (props.retail) {
                    copy.name = 'player-game-session-history-retail';
                    copy.fields = copy.fields.filter(x => x.name != 'OperatorId');
                    copy.fields = copy.fields.filter(x => x.name != 'RetailId');
                    copy.fields.push({ name: 'RetailId', value: props.retail.Id, exact: true, and: true, hidden: true });
                    return copy;
                }

                if (props.playerId) {
                    copy.name = 'player-game-session-history-player-id';
                    var operatorField = copy.fields.find(x => x.name == 'OperatorId');
                    operatorField.hidden = true;

                    var playerField = copy.fields.find(x => x.name == 'PlayerId');
                    playerField.hidden = true;
                    playerField.value = props.playerId;
                } else {
                    copy.name = 'player-game-session-history-operator';
                    copy.fields.find(x => x.name == 'OperatorId').nullable = false;
                    copy.fields.find(x => x.name == 'OperatorId').value = user.UserPrivileges.PointOfView;
                    if (user.UserPrivileges.AvailableOperators.length == 1) copy.fields.find(x => x.name == 'OperatorId').hidden = true;
                }
                return copy;
            }}
            onPrepareResultModel={(model) => {
                var copy = { ...model };
                if (props.affiliate) {
                    return copy;
                }

                if (props.retail) {
                    copy.fields = copy.fields.filter(x => x.name != 'operator');
                    copy.fields = copy.fields.filter(x => x.name != 'retail');
                    return copy;
                }
                if (props.playerId) {
                    copy.fields = copy.fields.filter(x => x.name != 'group');
                    copy.fields = copy.fields.filter(x => x.name != 'playerGroup');
                    copy.fields = copy.fields.filter(x => x.name != 'username');
                    copy.fields = copy.fields.filter(x => x.name != 'playerId');
                }

                return copy;
            }}
        />
    </flex>;
};


SearchPlayerGameSessions.propTypes = {
    playerId: PropTypes.number,
    affiliate: PropTypes.object,
    retail: PropTypes.object
};