import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../icon/Icon';
import { lang } from '../../lib/Localization/language';


const renderHeader = (field, props) => {
    if (field.renderHeader) {
        return field.renderHeader(field, props.context);
    }
    if (props.options?.sorting?.enabled) {

        var options = props.options;
        const sorting = options ?? options?.sorting.enabled;
        const isSortingField = sorting && options.sorting && options.sorting.field && options.sorting.field.column == field.name;
        const up = isSortingField && options.sorting.field.direction == 1;
        const down = isSortingField && options.sorting.field.direction == 0;

        return (
            <div className='table-sorting-container'>
                <div className='flex vertical auto-width toggle-sorting'>
                    <Icon icon='sort-up' size='xs' className={up ? '' : 'passive'} onClick={() => { options.sorting.onSortingChange({ column: field.name, direction: 1 }); }} />
                    {field.renderHeaderWithSorting ? field.renderHeaderWithSorting(field, props.context) : <span>{lang(field.title) ?? lang(field.name)}</span>}
                    <Icon icon='sort-down' size='xs' className={down ? '' : 'passive'} onClick={() => { options.sorting.onSortingChange({ column: field.name, direction: 0 }); }} />
                </div>
            </div>
        );
    }

    return (
        <span>{lang(field.title) ?? lang(field.name)}</span>
    );
};

export const TableHead = (props) => {
    const head = props.model.fields.filter(x => !x.hidden).map((field, index) => {
        return (
            <th
                key={index}
                className={'flux-table-header-column column-' + field.name.toLowerCase()}
            >
                {renderHeader(field, props)}
            </th>
        );
    });

    const allSelected = props.data.filter(x => !x.__selected).length == 0 && props.data.length > 0;
    return (
        <thead className={`flux-table-head ${props.options?.sorting ? 'sorting' : ''}`}>
            <tr className={'flux-table-header-row'}>
                {props.options.draggable?.enabled && (
                    <th className='flux-table-header-column table-option-draggable-header'> </th>
                )}
                {props.options.selectable && (
                    <th className='flux-table-header-column table-option-selectable-header'>
                        <Icon icon={allSelected ? 'check-square' : 'square'} type='far' onClick={() => props.onSelectAll()} />
                    </th>
                )}
                {head}
                {props.options.data && props.options.data.delete.enabled && (
                    <th className='flux-table-header-column table-option-delete-header'>
                        {props.options.data.delete.header && (
                            <Icon icon='trash' onClick={() => props.onDeleteAll()} />
                        )}
                    </th>
                )}
                {props.buttons && (
                    <th className='flux-table-header-column flux-table-buttons'></th>
                )}
            </tr>
        </thead>
    );
};

TableHead.propTypes = {
    model: PropTypes.object,
    data: PropTypes.array,
    options: PropTypes.object,
    buttons: PropTypes.func,
    onSelectAll: PropTypes.func,
    onDeleteAll: PropTypes.func
};