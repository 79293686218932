import './_country-icons.scss';

import React from 'react';
import PropTypes from 'prop-types';

export const replaceSpace = (name) => {
    if (!name) return name;
    name = name.replace(/ /g, '-');
    name = name.replace(/\//g, '-');
    name = name.toLowerCase();
    return name;
};

export const Country = (props) => {
    var classList = [`country-icon${props.circular ? ' circle' : ''}`];
    classList.push(props.className);
    classList.push(props.size);
    if (!props.name) return <span className={classList.join(' ') + ' country-international'}></span>;
    return <span className={classList.join(' ') + ' country-' + replaceSpace(props.name.toLowerCase()).replace('&', 'and').replace(',', '').replace('-amateur', '')}></span>;
};

Country.defaultProps = {
    size: '',
    className: ''
};

Country.propTypes = {
    className: PropTypes.string,
    circular: PropTypes.bool,
    name: PropTypes.string,
    size: PropTypes.string
};
