import './_configurator.scss';

import React, { useEffect, useState } from 'react';
import { lang } from '../../../lib/Localization/language';
import { Button } from '../../../components/button/Button';
import { API } from '../../../lib/API/Api';
import { CasinoLimitsEndpoints } from '../../../lib/API/Endpoints';
import { Modal } from '../../../components/application/FluxApp';
import { ConfirmationModal } from '../../../components/modal/ConfirmationModal/ConfirmationModal';
import { FluxNotice } from '../../../components/notification/FluxNotice';
import { NotificationType } from '../../../components/notification/FluxNotification';
import { Popper } from '../../../components/notification/FluxPopper';
import { ErrorModal } from '../../../components/modal/ErrorModal/ErrorModal';


const defaultFocus = (/*e*/) => {
    //e.target.value = '';
};


export const CasinoStakeLimit = (props) => {
    const { limit, title, onChange, onBetStop, onClear } = { ...props };

    useEffect(() => {
    }, [limit]);

    return <flex className={`fit-children gap-10 border radius fx-shadow limits${limit.BetStop ? ' warning' : ''}`}>
        {title}
        <flex className='gap-5 border-left justify-content-center'>
            <flex className='vertical padding'>
                <label className='medium'>{lang('Min Stake')}</label>
                <input name='MinBet'
                    defaultValue={limit.MinBet}
                    value={limit.MinBet}
                    onFocus={(e) => defaultFocus(e)}
                    onChange={(e) => onChange(e)} />
            </flex>
            <flex className='vertical padding'>
                <label className='medium'>{lang('Max Stake')}</label>
                <input name='MaxBet'
                    defaultValue={limit.MaxBet}
                    value={limit.MaxBet}
                    onFocus={(e) => defaultFocus(e)}
                    onChange={(e) => onChange(e)} />
            </flex>
            <flex className='vertical padding hidden'>
                <label className='medium'>{lang('Max Payout')}</label>
                <input name='MaxPayout'
                    defaultValue={limit.MaxPayout}
                    value={limit.MaxPayout}
                    onFocus={(e) => defaultFocus(e)}
                    onChange={(e) => onChange(e)} />
            </flex>
        </flex>
        <flex className='gap-10 align-right justify-content-center padding border-left actions'>
            <Button title={limit.BetStop ? 'Bet Start' : 'Bet Stop'} className={'warning'} onClick={() => onBetStop()} />
            <Button title='Clear Config' onClick={() => onClear()} />
        </flex>
    </flex>;
};


export const emptyLimits = {
    general: {},
    groups: {}
};


export const CasinoLimitConfigurator = (props) => {
    const { group, currencies } = { ...props };
    const [limits, setLimits] = useState(null);

    useEffect(() => {
        API.post(CasinoLimitsEndpoints.CasinoLimits).then((result) => {
            var converted = null;
            try {
                converted = JSON.parse(result.result);
            } catch (err) {
                //
                converted = {
                    general: {},
                    groups: {}
                };
            }
            setLimits(converted ?? {
                general: {},
                groups: {}
            });
        });
    }, []);

    useEffect(() => {
    }, [limits]);



    const updateLimits = (currency, field, value) => {
        var copy = { ...limits };
        if (group) {
            if (!copy.groups[group.value]) {
                copy.groups[group.value] = {};
            }

            if (!copy.groups[group.value][currency]) {
                copy.groups[group.value][currency] = [];
                copy.groups[group.value][currency].push({ ...{ MinBet: 0, MaxBet: 0, MaxPayout: 0, BetStop: false } });
            }

            copy.groups[group.value][currency][0][field] = value;
        } else {
            if (!copy.general[currency]) {
                copy.general[currency] = []
                copy.general[currency].push({ ...{ MinBet: 0, MaxBet: 0, MaxPayout: 0, BetStop: false } });
            }
            copy.general[currency][0][field] = value;
        }
        setLimits(copy);
    };

    const toggleBetStop = (currency) => {
        var copy = { ...limits };
        if (group) {
            if (!copy.groups[group.value]) {
                copy.groups[group.value] = {};
            }
            if (!copy.groups[group.value][currency]) {
                copy.groups[group.value][currency] = [{ ...{ MinBet: 0, MaxBet: 0, MaxPayout: 0, BetStop: false } }];
            }
            copy.groups[group.value][currency][0].BetStop = !copy.groups[group.value][currency][0].BetStop;
        } else {
            if (!copy.general[currency]) copy.general[currency] = [{ ...{ MinBet: 0, MaxBet: 0, MaxPayout: 0, BetStop: false } }];
            copy.general[currency][0].BetStop = !copy.general[currency][0].BetStop;
        }
        setLimits(copy);
    };

    const clearConfig = (currency) => {
        var copy = { ...limits };
        if (group) {
            delete copy.groups[group.value][currency];
            if (Object.values(copy.groups[group.value]).length == 0) {
                delete copy.groups[group.value];
            }
        } else {
            delete copy.general[currency];
        }
        setLimits(copy);
    };

    const getLimit = (currency) => {
        var limit = { ...{ MinBet: 0, MaxBet: 0, MaxPayout: 0, BetStop: false } };
        if (!group) {
            limit = limits.general[currency.CurrencyCode] ? limits.general[currency.CurrencyCode][0] : null;
        } else {
            limit = limits.groups[group.value] ? (limits.groups[group.value][currency.CurrencyCode] ? limits.groups[group.value][currency.CurrencyCode][0] : null) : null;
        }
        return limit;
    };

    const renderConfig = (currency) => {
        var limit = getLimit(currency);

        return <CasinoStakeLimit
            limit={limit ?? { ...{ MinBet: 0, MaxBet: 0, MaxPayout: 0, BetStop: false } }}
            title={<flex className='configuration-title vertical'>
                <label className='medium '>{currency.Name}</label>
                <span>{currency.CurrencyCode}</span>
            </flex>}
            onChange={(e) => {
                var value = parseFloat(e.target.value);
                if (isNaN(value)) value = 0;
                if (value < 0) value = 0;
                updateLimits(currency.CurrencyCode, e.target.name, value);
            }}
            onBetStop={() => toggleBetStop(currency.CurrencyCode)}
            onClear={() => clearConfig(currency.CurrencyCode)} />;
    };


    const save = () => {
        var title = 'Updating Casino General limits.';
        var description = 'Please confirm updating casino general limits, the settings will apply default & group configurations.'
        Modal.open(<ConfirmationModal title='Save limits' onConfirm={() => {
            API.post(CasinoLimitsEndpoints.SaveCasinoLimits, limits).then(() => {
                Popper.pop('information', 'Saved', 'General casino limits saved.')
            }).catch((error) => {
                var message = error?.error?.message ?? 'Service error. Please try later!';
                Modal.open(<ErrorModal title='Unable to save limits'><FluxNotice type={NotificationType.Error} title='Operation failed!' description={message} /></ErrorModal>);
            });
        }}>
            <FluxNotice type={NotificationType.Information} title={title} description={description} />
        </ConfirmationModal>)
    };

    if (!limits) return <></>;

    return <flex className='vertical gap-5 casino-limits-configurator' key={limits}>
        {
            group && <flex className='border radius fx-shadow padding surface'>
                <label className='medium bold'>{lang('%1 group configuration', [group.title])}</label>
                <Button title='Save' className={'align-right success'} onClick={() => save()} />
            </flex>
        }
        {
            !group && <flex className='border radius fx-shadow padding surface'>
                <label className='medium bold'>{lang('No group selected, settings will apply to all groups without specific configuration.')}</label>
                <Button title='Save' className={'align-right success'} onClick={() => save()} />
            </flex>
        }
        <flex className='vertical gap-5 overflow-hidden overflow-y-auto casino-limits-configurator radius'>
            {
                currencies.map(x => {
                    return renderConfig(x);
                })
            }
        </flex>

    </flex>;
};