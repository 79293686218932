import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { FluxSearch } from '../../../components/search/FluxSearch';
import { InputTypes } from '../../../components/form/InputTypes';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { TransactionTypes } from '../../../lib/Enums/TransactionEnums';
import { lang } from '../../../lib/Localization/language';
import { Icon } from '../../../components/icon/Icon';
import { AffiliateEndpoints, TransactionEndpoints } from '../../../lib/API/Endpoints';
import { ActionTypes } from '../../../lib/ActionManager/FluxActions';
import { appHistory } from '../../../..';
import { Modal } from '../../../components/application/FluxApp';
import { FluxModal } from '../../../components/modal/FluxModal';
import { Button } from '../../../components/button/Button';
import { copyDataToClipboard } from '../../../lib/Helpers/StringHelper';
import { confirmTransaction, rejectTransction } from '../../../actions/TransactionActions';
import { FluxNotice } from '../../../components/notification/FluxNotice';
import { currency } from '../../../components/currency/Currency';


const model = {
    fields: [
        {
            name: 'UserId',
            title: 'Player Id'
        },
        {
            name: 'Username',
            title: 'Username'
        },
        {
            name: 'TransactionDate',
            title: 'Date',
            type: InputTypes.DateTime,
            between: true,
            value: {
                start: DateHelpers.yesterday(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'Id',
            title: 'Transaction Id',
            information: 'Our record'
        },
        {
            name: 'ExternalTransactionId',
            title: 'External Transaction Id',
            information: 'Transaction id recorded by provider'
        },
        {
            name: 'ProviderId',
            title: 'Provider',
            type: InputTypes.Select,
            nullable: true,
            //value: [191341, 191327],
            data: {
                source: 'providers',
                key: 'Id',
                title: ['Display'],
                parser: (result) => {
                    var results = [];
                    result.result.map(x => {
                        if (!x.Enabled) return;
                        var group = 'Gaming';
                        if (x.Type == 128) group = 'Payment Provider';
                        results.push({
                            value: x.Id, title: x.Display, group: {
                                id: group,
                                title: group
                            }
                        });
                    });

                    return results;
                }
            }
        },
        {
            name: 'Type',
            title: 'Type',
            type: InputTypes.Select,
            nullable: true,
            multiple: true,
            exact: true,
            value: [0, 1],
            values: {
                0: 'Deposit',
                1: 'Withdraw',
                3: 'Bet',
                2: 'Win'
            }
        }
    ]
};

const resultTransactionInformation = {
    fields: [
        {
            name: 'Type',
            title: 'Type',
            type: InputTypes.Select,
            values: TransactionTypes
        },
        {
            name: 'ProviderName',
            title: 'Provider'
        },
        {
            name: 'Username'
        },
        {
            name: 'Currency'
        },
        {
            name: 'TotalAmount',
            title: 'Amount',
            type: InputTypes.Number,
            render: (val, row) => {
                return currency(val, 2, row.Currency);
            },
            renderFooter: (val) => {
                return currency(val, 2, '');
            }
        },
    ]
};

export const SearchPlayerTransactionsSummary = (props) => {
    const [title, setTitle] = useState('Search player transactions');
    const [actions, setActions] = useState(null);
    useEffect(() => {
        if (props.pending && props.deposits) {
            setActions(ActionTypes.SearchPendingDeposits);
            return;
        }
        if (props.pending && props.withdraws) {
            setActions(ActionTypes.SearchPendingWithdraws);
            return;
        }
        if (props.affiliate) {
            setActions([]);
            setTitle(<React.Fragment><span>{lang('Affiliate')}</span><Icon icon='chevron-right' type='fa-light' /><span>{props.affiliate.Name}</span><Icon icon='chevron-right' type='fa-light' /><span>{lang('Search player transactions')}</span></React.Fragment>);
            return;
        }

        setActions(ActionTypes.SearchTransactions);
    }, []);


    const endpoint = () => {
        if (props.affiliate) {
            return `${AffiliateEndpoints.TransactionsSummary}/${props.affiliate.Id}`;
        }
        return TransactionEndpoints.Search;
    };



    const onActionClicked = (action, transaction, selection) => {
        var data = selection.tableContext.getData();
        switch (action.type) {
            case ActionTypes.ShowTransactionsData:
                var transactionData = <pre>{transaction.Data}</pre>;
                if (transaction.Data && transaction.Data.indexOf('{') == 0) {
                    try {
                        var converted = JSON.parse(transaction.Data);
                        var elements = Object.keys(converted).map(x => {
                            return <flex className='vertical' key={x}>
                                <span className='bold'>{x}</span>
                                <span className=''>{converted[x].toString()}</span>
                            </flex>;
                        });
                        transactionData = <flex className='vertical gap-5'>
                            {elements}
                        </flex>;
                    } catch (err) {
                        //
                    }
                }

                if (!transaction.Data) {
                    transactionData = <FluxNotice type='information' title='No information' description='There is no information for this transaction' />;
                }
                Modal.open(<FluxModal title='Transaction information' footer={<flex className='center gap-10'>
                    {transaction.Data && <Button title='Copy to clipboard' onClick={() => copyDataToClipboard(transaction.Data)} />}
                    <Button title='Close' className={'success'} onClick={() => Modal.close()} />
                </flex>}>
                    {transactionData}
                </FluxModal>);
                break;
            case ActionTypes.PlayerProfile:
                appHistory.push(`/players/search?&id=${transaction.UserId}`);
                break;
            case ActionTypes.ConfirmTransaction:
                confirmTransaction({ id: transaction.Id, amount: transaction.Amount, data: transaction.Data, currency: transaction.Currency }, transaction.Type, () => {
                    data = data.filter(x => x.Id != transaction.Id);
                    selection.tableContext.setData(data);
                });
                break;
            case ActionTypes.RejectTransaction:
                rejectTransction({ id: transaction.Id, amount: transaction.Amount, data: transaction.Data }, transaction.Type, () => {
                    data = data.filter(x => x.Id != transaction.Id);
                    selection.tableContext.setData(data);
                });
                break;
            default:
                console.log(action.type);
                break;
        }
    };


    if (!actions) return <></>;

    return <FluxSearch
        title={title}
        model={model}
        resultModel={resultTransactionInformation}
        endpoint={endpoint()}
        searchOnStart={props.pending}
        actions={actions}
        onActionClicked={onActionClicked.bind(this)}
        onPrepareModel={(model) => {
            var copy = { ...model };
            if (props.playerId) {
                var playerField = copy.fields.find(x => x.name == 'UserId');
                playerField.hidden = true;
                playerField.value = props.playerId;
                copy.fields.find(x => x.name == 'Username').hidden = true;
                copy.name = 'transactions-with-player-id';
            }

            if (props.affiliate) {
                copy.name = 'transactions-of-affiliate';
                return copy;
            }

            if (props.pending) {
                copy.fields.push({
                    name: 'Status',
                    value: '0',
                    exact: true,
                    and: true,
                    hidden: true
                });
            }

            if (props.deposits) {
                copy.fields.find(x => x.name == 'Type').value = '0';
                copy.fields.find(x => x.name == 'Type').hidden = true;
                copy.fields = copy.fields.filter(x => x.name != 'TransactionDate');

                copy.name = `${props.pending ? 'pending' : ''}deposit-transactions`;
            }
            if (props.withdraws) {
                copy.fields.find(x => x.name == 'Type').value = 1;
                copy.fields.find(x => x.name == 'Type').hidden = true;
                copy.fields = copy.fields.filter(x => x.name != 'TransactionDate');

                copy.name = `${props.pending ? 'pending' : ''}withdraw-transactions`;
            }
            return copy;
        }}
    />;
};

SearchPlayerTransactionsSummary.propTypes = {
    affiliate: PropTypes.object,
    playerId: PropTypes.number,
    pending: PropTypes.bool,
    deposits: PropTypes.bool,
    withdraws: PropTypes.bool
};