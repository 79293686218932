/* eslint-disable react/display-name */

import './_monitor.scss';
import '../../../../components/sports/RiskManagement/RiskLevels/_riskLevelConfiguration.scss';

import React, { useContext, useEffect, useState } from 'react';
import { currency } from '../../../../components/currency/Currency';
import { Table } from '../../../../components/table/Table';
import { useSelector } from 'react-redux';
import { Icon } from '../../../../components/icon/Icon';
import { getLookupKey } from '../../../../components/sports/RiskManagement/RiskLevels/RiskLevelLimitsConfiguration';
import { FluxCard } from '../../../../components/card/FluxCard';
import { Button } from '../../../../components/button/Button';
import { lang } from '../../../../lib/Localization/language';
import { NotifyWithSound } from '../../../../lib/Helpers/Sound/SoundHelper';
import { FluxNotice } from '../../../../components/notification/FluxNotice';
import { BetMonitorSocketContext } from '../MonitorTickets/BetmonitorSocket';
import { InputTypes } from '../../../../components/form/InputTypes';
import { API } from '../../../../lib/API/Api';
import { RiskLimitEndpoints, SportOveraskEndpoints } from '../../../../lib/API/Endpoints';
import { CountdownSeconds } from '../../../../components/countdown/CountdownTimer';
import { ShowAddNewTemplateModal } from '../../../../components/sports/RiskManagement/RiskLimits/Components/RiskTemplates/RiskTemplates';


const SelectionStatus = {
    0: <Icon icon='square' type='fa-regular' />,
    2: 'Refund',
    3: <Icon icon='times-square' type='fa-regular' />,
    4: <Icon icon='check-square' type='fa-regular' />,
    5: 'Cashout',
    6: 'Cancel'
};


export const getMatchTime = (time) => {
    const minutes = Math.floor(time / 60);
    return minutes;
};





const EditTemplate = (props) => {
    const user = useSelector((state) => state.user).loginInformation;
    const operatorId = user.UserPrivileges.PointOfView;

    const { templateId, templates } = { ...props };
    var template = templates.find(x => x.id == templateId);

    if (!template) return <></>;
    return <flex className='gap-10'>
        <span className='bold'>{lang('Template')}</span>
        <span className='template-name-cropped'>{templates.find(x => x.id == templateId)?.name}</span>
        <Button className='align-right clickable' onClick={() => {
            API.post(RiskLimitEndpoints.GetAllTemplates(operatorId)).then((r) => {
                try {
                    ShowAddNewTemplateModal(r.result.find(x => x.id == templateId), () => {

                    });
                } catch (err) {
                    //
                }
            });
        }}>
            <span>{lang('Edit template')}</span>
        </Button>
    </flex >;
};

const tickerModel = {
    fields: [
        {
            name: 'expireDate',
            title: 'Expires',
            render: (val, row, field, context, tableContext) => {
                return <CountdownSeconds targetDate={row.expireDate} onComplete={() => {
                    var data = tableContext.getData();
                    data = data.filter(x => x.offerId !== row.offerId);
                    tableContext.setData(data);
                }} />;
            }
        },
        {
            name: 'entryDate',
            title: 'Entry Date',
            type: InputTypes.DateTime
        },
        {
            name: 'username',
            title: 'Player',
            render: (val, row) => {
                return <flex className='vertical clickable' onClick={() => {
                    window.open(`/players/search?&id=${row.playerId}`, 'new');
                }}>
                    <span>{row.username}</span>
                    <span className={`risk-level-${row.playerRiskLevel}`}>{row.playerRiskLevel}</span>
                </flex>;
            }
        },
        {
            name: 'betType',
            title: 'Ticket Type',
            render: (val, row) => {
                if (row.betSlips.length == 1) return 'Single';
                if (row.betSlips.length == 2) return 'Multi';
                return 'System';
            }
        },
        {
            name: 'selection',
            title: 'Selection',
            renderHeader: () => {
                return <flex className='vertical gap-5'>
                    <span>Selections</span>
                    <flex className='gap-5 border-top'>
                        <span className='selection-level hidden'>Risk Levels</span>
                        <span className='selection-live-pre'>Live/Pre</span>
                        <span className='selection-event'>Event</span>
                        <span className='selection-event-date'>Date</span>
                        <span className=''>Market, Selection</span>
                        <span className='align-right selection-price'>Price</span>
                        <span className='selection-score'>Score</span>
                        <span className='selection-time'>Time</span>
                    </flex>
                </flex>;
            },
            render: (val, row, field, context) => {
                var ticketSelections = {};
                row.betSlips.map(x => {
                    ticketSelections = Object.assign(ticketSelections, x.selections);
                });

                return <flex className='vertical gap-10'>
                    {
                        Object.values(ticketSelections).map(x => {
                            var status = x.status;
                            var converted = x.riskLevel ? getLookupKey(x.riskLevel) : [];
                            const result = converted.map((x, index) => {
                                return <flex key={index} className={`risk-level-${x}`}>{x}</flex>;
                            });

                            var level = <flex className='gap-1 selection-level hidden'>
                                {result}
                            </flex>;

                            var score = context.scores ? context.scores[x.eventExternalId] : '{}';
                            if (score) {
                                score = JSON.parse(score);
                            }
                            var homeScore = '';
                            var awayScore = '';

                            var time = '';
                            if (score?.Results) {
                                homeScore = score.Results.find(x => x.Position == '1').Value;
                                awayScore = score.Results.find(x => x.Position == '2').Value;
                                time = getMatchTime(score.Time);
                            }

                            var sportLevel = converted[0];
                            var regionLevel = converted[1];
                            var leagueLevel = x.leagueLevel;
                            var date = new Date(x.date);
                            return <flex className='vertical' key={x.id}>
                                <flex className='gap-5'>
                                    <flex className='vertical'>
                                        <flex className='gap-5'>
                                            {level}
                                            <span className='selection-live-pre bold'>{x.live ? 'live' : 'prematch'}</span>
                                            <flex className='selection-event gap-5'>
                                                {SelectionStatus[status]}
                                                <span>{x.eventName}</span>
                                            </flex>
                                        </flex>
                                        <flex className='gap-5'>
                                            <span className={`small meta-level-${sportLevel}`}>{x.sport}</span>
                                            /
                                            <span className={`small meta-level-${regionLevel}`}>{x.region}</span>
                                            /
                                            <span className={`small meta-level-${leagueLevel}`}>{x.league}</span>
                                        </flex>
                                    </flex>
                                    <flex className='selection-event-date vertical'>
                                        <span>{date.toLocaleDateString('en-Gb', { hours12: false })}</span>
                                        <span>{date.toLocaleTimeString('en-Gb', { hours12: false })}</span>
                                    </flex>
                                    <flex className='vertical'>
                                        <span className='bold'>{x.marketName}</span>
                                        <span>{x.selectionName}{x.specifiers ? ` (${x.specifiers})` : ''}</span>
                                    </flex>
                                    <span className='align-right selection-price'>{x.price.toFixed(2)}</span>
                                    <flex className='selection-score'>
                                        <span>{homeScore}</span>
                                        -
                                        <span>{awayScore}</span>
                                    </flex>
                                    <flex className='selection-time'>
                                        <span>{time}</span>
                                    </flex>
                                </flex>
                            </flex>;
                        })
                    }

                    <flex className='vertical failed-template-information'>
                        <flex className='gap-5'>
                            <i className='fa-solid fa-square-exclamation'></i>
                            <span>{row.reason}</span>
                        </flex>
                        {row.templateId ? <EditTemplate templateId={row.templateId} templates={context.templates} /> : <span>{lang('Failed over bet limits')}</span>}
                    </flex>
                </flex>;
            }
        },
        {
            name: 'totalAmount',
            title: 'Total Stake',
            render: (val, row) => {
                return currency(row.totalAmount, 2, row.currency);
            }
        },
        {
            name: 'totalMaxOdds',
            title: 'Odds',
            render: (val, row) => {
                return row.totalMaxOdds.toFixed(2);
            }
        },
        {
            name: 'totalMaxWinAmount',
            title: 'Possible Win',
            render: (val, row) => {
                return currency(row.totalMaxWinAmount, 2, row.currency);
            }
        },
        {
            name: 'ipAddress',
            title: 'IP'
        }
    ]
};

export const BetMonitor = () => {
    const context = useContext(BetMonitorSocketContext);


    const [connected, setConnected] = useState(context.connected);

    const user = useSelector((state) => state.user).loginInformation;
    const operatorId = user.UserPrivileges.PointOfView;

    const [scores, setScores] = useState({});
    const [overAskTickets, setOverAskTickets] = useState([]);



    const [autoRejectedOfferId, setAutoRejectedOfferId] = useState(null);
    const [newOverAskTickets, setNewOverAskTickets] = useState(null);
    const [newScores, setNewScores] = useState(null);

    const [ready, setReady] = useState(false);

    const [soundError, setSoundError] = useState(null);

    const [timeoutConfiguration, setTimeoutConfiguration] = useState(40);

    const [templates, setTemplates] = useState([]);


    useEffect(() => {
        context.eventHandlers({
            onConnected: () => setConnected(true),
            onOverAsk: (t) => {
                console.log(t);
                setNewOverAskTickets(t);
            },
            onCashoutTicket: () => { },
            onCloseTicket: () => { },
            onRemoveTicket: () => { },
            onManualSettlement: () => {

            },
            onAutoReject: (t) => {
                setAutoRejectedOfferId(t);
            },
            onScore: (scoreData) => {
                setNewScores(scoreData);
            }
        });



        API.post(RiskLimitEndpoints.GetAllTemplates(user.UserPrivileges.PointOfView)).then((tRes) => {
            setTemplates(tRes.result);
            API.post(SportOveraskEndpoints.GetConfiguration(operatorId)).then((result) => {
                setTimeoutConfiguration(result.result.timeout);
            });
        });
    }, []);

    useEffect(() => {
        if (!connected) return;

        NotifyWithSound('https://cdn-d.tentangle.com/bo/sounds/notification1.mp3', 100, false).catch(() => {
            setSoundError(<flex className='vertical gap-5'>
                <span>{lang('Playing sounds after window refresh requires user gesture to click!')}</span>
                <flex>
                    <Button title='Enable sounds' className='success' onClick={() => {
                        setSoundError(false);
                        NotifyWithSound('https://cdn-d.tentangle.com/bo/sounds/notification1.mp3', 100, false).catch(() => {

                        });

                    }} />
                </flex>
            </flex>);
        });
        loadTickets();

    }, [connected]);

    useEffect(() => {
        if (!newOverAskTickets) return;
        var copy = newOverAskTickets.concat(overAskTickets);
        setOverAskTickets(copy.sort((a, b) => new Date(b.entryDate) - new Date(a.entryDate)));
        NotifyWithSound('https://cdn-d.tentangle.com/bo/sounds/notification1.mp3', 100, false);

    }, [newOverAskTickets]);

    useEffect(() => {
        if (!autoRejectedOfferId) return;
        var copy = [...overAskTickets];

        copy = copy.filter(x => x.offerId != autoRejectedOfferId);
        setOverAskTickets(copy);

    }, [autoRejectedOfferId]);

    useEffect(() => {
        if (!newScores) return;
        var copy = { ...scores };
        copy[newScores.id] = newScores.score;
        setScores(copy);
    }, [newScores]);


    const loadTickets = () => {
        API.post(SportOveraskEndpoints.Offers(operatorId)).then((result) => {
            setOverAskTickets(result.result.sort((a, b) => new Date(b.entryDate) - new Date(a.entryDate)));
            setReady(true);
        });
    };

    const renderTicker = () => {
        return <flex className='vertical gap-5' >
            {soundError && <FluxNotice type='warning' title='Sound error' description={soundError} />}
            <Table model={tickerModel} data={overAskTickets} context={{ scores: scores, templates: templates }}
                onRenderRow={(row, elements) => {
                    if (new Date() > new Date(row.expireDate)) {
                        return <tr className='disabled'>{elements}</tr>;
                    }
                    return <tr className='flux-table-body-row'>{elements}</tr>;
                }}
                className={'border'}
                buttons={(row) => <flex className='gap-10'>
                    <Button title='Accept' className={'success'} onClick={() => {
                        if (new Date() > new Date(row.expireDate)) {
                            return;
                        }
                        API.post(SportOveraskEndpoints.Accept(operatorId, row.offerId)).then(() => {
                            var copy = overAskTickets.filter(x => x.offerId != row.offerId);
                            setOverAskTickets(copy);
                        });
                    }} />
                    <Button title='Reject' className={'warning'} onClick={() => {
                        if (new Date() > new Date(row.expireDate)) {
                            return;
                        }
                        API.post(SportOveraskEndpoints.Reject(operatorId, row.offerId)).then(() => {
                            var copy = overAskTickets.filter(x => x.offerId != row.offerId);
                            setOverAskTickets(copy);
                        });
                    }} />
                </flex>} />

        </flex>;
    };


    const updateTimeOut = (t) => {
        API.post(SportOveraskEndpoints.UpdateOverAskTimeout(operatorId), {
            overAskCountdown: parseInt(t)
        }).then(() => {
            setTimeoutConfiguration(parseInt(t));
        });
    };

    if (!ready) return <></>;

    return <FluxCard title='Monitor Over Limit Tickets' className={`no-padding content${connected ? '' : ' disabled'}`} buttons={<flex className='align-right gap-10'>
        <span>{lang('Set time out')}</span>
        <Button title={'15'} className={timeoutConfiguration == 15 ? 'success' : ''} onClick={() => updateTimeOut(15)} />
        <Button title={'30'} className={timeoutConfiguration == 30 ? 'success' : ''} onClick={() => updateTimeOut(30)} />
        <Button title={'40'} className={timeoutConfiguration == 40 ? 'success' : ''} onClick={() => updateTimeOut(40)} />
    </flex>}>
        {renderTicker()}
    </FluxCard>;
};