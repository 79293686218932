import React from 'react';
import { FluxTab } from '../../../components/tab/FluxTab';
import { GeneralSettings } from './Components/GeneralSettings';
import { SportsSettings } from './Components/SportsSettings';
import { PromotionCodeSettings } from './Components/PromotionCodeSettings';
import { SplitFreeBetSettings } from './Components/SplitFreeBetSettings';

export const SplitFreeBetBonus = (props) => {
    var buttons = [
        {
            title: 'General Settings'
        },
        {
            title: 'Code'
        },
        {
            title: 'Prize Settings'
        },
        {
            title: 'Sports'
        }
    ];


    return <FluxTab buttons={buttons} className='gap-10 fx-shadow fx-radius rounded-buttons'>
        <GeneralSettings {...props} description='The Split Free Bet bonus allows players to divide their FreeBet amount into multiple smaller bets instead of using the entire amount on a single bet. This provides flexibility in how they utilize their bonus.' />
        <PromotionCodeSettings {...props} />
        <SplitFreeBetSettings {...props}/>
        <SportsSettings {...props} enabled/>
    </FluxTab>;
};