export const UserGroups = {
    2: 'Owner',
    3: 'Manager',
    4: 'Setup',
    5: 'Call Center',
    6: 'Finance',
    7: 'Marketing',
    20: 'Support',
    23: 'POS User'
};


export const UserActivities = [
    'Player verified',
    'Retail Player Updated',
    'Player Enabled',
    'Player email updated',
    'Credit Added to Retail Player',
    'Player balance reset',
    'Event Selections Update',
    'Withdraw Rejected',
    'Promotions published',
    'Manual Ticket Lost',
    'Player group changed',
    'Manual Ticket Update Win',
    'Language published',
    'Player Created',
    'Operator casino configuration updated',
    'New Operator User',
    'Operator currencies updated',
    'Deposit Confirmed',
    'Game information created',
    'Affiliate Group created',
    'Login',
    'Player tag changed',
    'New Announcement',
    'Publish Announcement',
    'Casino Provider Game Limits',
    'Player verification revoked',
    'Update Operator User',
    'Add player to affiliate',
    'Sport Limits',
    'Create Operator',
    'Manual Booked Bet Result',
    'Retail Created',
    'Changed password',
    'Transaction set to processing',
    'League Limits',
    'Sport Events',
    'Default Image binded',
    'Player test account revoked',
    'Operator User Disabled',
    'Disable Player 2FA',
    'Player note updated',
    'Withdraw From Player',
    'Provider Disabled',
    'Casino Provider Limits',
    'Player password changed',
    'Player removed from retail',
    'Manual Ticket Cancel',
    'Bonus Assigned To Player',
    'Affiliate User Added',
    'Credit Added',
    'Bulk remove promotions from players',
    'Sport Margins',
    'Player group updated',
    'Update Operator Features',
    'Player note deleted',
    'Signed out due to new login',
    'Promotion updated',
    'Content inserted',
    'Game information changed',
    'Promotion disabled',
    'Player group created',
    'Event Open Selections Status Update',
    'Affiliate Active Promotion Add',
    'Casino Limits',
    'Player note created',
    'Promotion bonus created',
    'Remove player from affiliate',
    'Child Retail Credit Removed',
    'Manual Booked Bet',
    'Operator User Enabled',
    'Event Limits',
    'Game information updated',
    'Withdraw Confirmed',
    'Offer confirmed',
    'Manual Ticket Win',
    'Child Retail Credit Added',
    'Player data changed',
    'Rollback Transaction',
    'Affiliate Group information updated',
    'Retail Player Created',
    'Update Operator Information',
    'Events assigned to admins',
    'Operator risk updated',
    'Sport Leagues',
    'Operator payment gateway configuration updated',
    'Player Disabled',
    'Player Limits',
    'Exchange rate changed',
    'Run Periodic Bonus',
    'Logout',
    'Player moved to retail',
    'Deposit Rejected',
    'Active Bonus Removed and Balance Changed',
    'Sport Categories',
    'Risk Levels',
    'Promotion enabled',
    'Promotion deleted',
    'Affiliate User Changed',
    'Operator countries updated',
    'Deposit From Player',
    'Content updated',
    'Withdraw Retail Player',
    'Active Bonus Removed',
    'Offer rejected',
    'Sport Regions',
    'Player test account',
    'Retail Updated',
    'Credit Removed',
    'Category Limits',
    'Update Announcement'
];