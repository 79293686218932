const defaultStorage = {
    settings: {
        components: {
            fluxSearch: {
                models: {

                }
            }
        },
        favouriteActions:
        {

        },
        shortcuts: {

        }
    }
};

export const LocalStorage = {
    get: () => {
        if (!window.localStorage) return { ...defaultStorage };
        var copy = localStorage.getItem('flux-settings');
        if (!copy) return { ...defaultStorage };

        return JSON.parse(copy);
    },
    save: (settings) => {
        if (!window.localStorage) return;
        localStorage.setItem('flux-settings', JSON.stringify(settings));
    },
    getModel: (modelName) => {
        var settings = LocalStorage.get().settings;
        if (settings.components.fluxSearch.models[modelName]) {
            return settings.components.fluxSearch.models[modelName];
        }

        return null;
    },
    saveModel: (modelName, model) => {
        var settings = LocalStorage.get().settings;
        if (!settings.components.fluxSearch) {
            settings.components.fluxSearch = { models: {} };
        }
        if (!settings.components.fluxSearch.models) {
            settings.components.fluxSearch.models = {};
        }
        settings.components.fluxSearch.models[modelName] = model;
        LocalStorage.save({
            settings: settings
        });
    },
    getShortcut: (group) => {
        var settings = LocalStorage.get().settings;
        if (settings.shortcuts && settings.shortcuts[group]) {
            return settings.shortcuts[group];
        }
        return null;
    },
    saveShortcut: (group, key, value) => {
        var settings = LocalStorage.get().settings;
        if (!settings.shortcuts) {
            settings.shortcuts = {};
        }
        if (!settings.shortcuts[group]) {
            settings.shortcuts[group] = {};
        }
        settings.shortcuts[group][key] = value;
        LocalStorage.save({
            settings: settings
        });
    }
};