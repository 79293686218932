import './_countdown.scss';

import React from 'react';
import { useCountdown } from './useCountdown';




const DateTimeDisplay = (props) => {
    const { value, type, isDanger } = { ...props };
    return (
        <flex className={`vertical align-items-center ${isDanger ? 'countdown danger' : 'countdown'}`}>
            <span className='countdown-item'>{value.toString().padStart(2, '0')}</span>
            <span className='hidden'>{type}</span>
        </flex>
    );
};

const ExpiredNotice = () => {
    return <></>;
};

const ShowCounter = (props) => {
    const { days, hours, minutes, seconds } = { ...props };
    return (
        <flex className='countdown-widget gap-5 align-items-center fx-surface'>
            {days && <React.Fragment><DateTimeDisplay value={days} type={'Days'} isDanger={days <= 3} />
                <span>:</span>
            </React.Fragment>}
            {hours && <React.Fragment><DateTimeDisplay value={hours} type={'Hours'} isDanger={false} />
                <span>:</span>
            </React.Fragment>}
            {minutes && <React.Fragment>
                <DateTimeDisplay value={minutes} type={'Mins'} isDanger={false} />
                <span>:</span>
            </React.Fragment>}
            {seconds && <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={false} />}
        </flex>
    );
};

export const CountdownTimer = (props) => {
    const { targetDate } = { ...props };
    const [days, hours, minutes, seconds] = useCountdown(targetDate);

    if (days + hours + minutes + seconds <= 0) {
        return <ExpiredNotice />;
    } else {
        return (
            <ShowCounter
                days={days}
                hours={hours}
                minutes={minutes}
                seconds={seconds}
            />
        );
    }
};



export const CountdownSeconds = (props) => {
    const { targetDate } = { ...props };
    const [days, hours, minutes, seconds] = useCountdown(targetDate);

    if (days + hours + minutes + seconds <= 0) {
        return <ExpiredNotice />;
    } else {
        return (
            <ShowCounter
                seconds={seconds}
            />
        );
    }
};
