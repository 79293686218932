import React from 'react';
import { ActionTypes } from '../../lib/ActionManager/FluxActions';
import { FluxPage } from '../../components/page/FluxPage';
import { MyProfile } from './MyProfile';


export const ProfilePage = () => {
    return <FluxPage action={ActionTypes.MyProfile}>
        <MyProfile />
    </FluxPage >;
};
