import React from 'react';
import { FluxTab } from '../../../components/tab/FluxTab';
import { BonusCreditOnRegister } from './Components/BonusCreditOnRegister';
import { TurnoverRules } from './Components/TurnoverRules';
import { GeneralSettings } from './Components/GeneralSettings';
import { PayoutSettings } from './Components/PayoutSettings';
import { SportsSettings } from './Components/SportsSettings';
import { PromotionCodeSettings } from './Components/PromotionCodeSettings';
import { WelcomeFreeBetSettings } from './Components/WelcomeFreeBetSettings';

export const WelcomeBonus = (props) => {
    var buttons = [
        {
            title: 'General Settings'
        },
        {
            title: 'Codes'
        },
        {
            title: 'Credit'
        },
        {
            title: 'Rules'
        },
        {
            title: 'Payout Settings'
        },
        {
            title: 'Free-Bet Settings'
        },
        {
            title: 'Sports'
        }
    ];

    return <FluxTab buttons={buttons} className='gap-10 fx-shadow fx-radius rounded-buttons'>
        <GeneralSettings {...props} />
        <PromotionCodeSettings {...props} />
        <BonusCreditOnRegister {...props} />
        <TurnoverRules {...props} />
        <PayoutSettings {...props} />
        <WelcomeFreeBetSettings  {...props}  />
        <SportsSettings {...props} />
    </FluxTab>;
};