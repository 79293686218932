import html2canvas from 'html2canvas';

import './_casino-image-composer.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FluxCard } from '../../../../components/card/FluxCard';
import { Button } from '../../../../components/button/Button';
import { lang } from '../../../../lib/Localization/language';
import { API } from '../../../../lib/API/Api';
import { CMSEndpoints } from '../../../../lib/API/Endpoints';
import { copyDataToClipboard, replaceSpaceAndNonAlphaNumerics } from '../../../../lib/Helpers/StringHelper';
import { Popper } from '../../../../components/notification/FluxPopper';
import ReactSelect from 'react-select';
import { Modal } from '../../../../components/application/FluxApp';
import { ConfirmationModal } from '../../../../components/modal/ConfirmationModal/ConfirmationModal';
import { ImageUpload } from '../../WebConfiguration/Components/ImageUpload/ImageUpload';
import { appHistory } from '../../../../..';


const imageWidth = 408;
const imageHeight = 546;

const textWidthConstant = 650;
const lineHeightConstant = 500;

const textWidthConstantHalf = 650 / 2;
const lineHeightConstantHalf = 500 / 2;


const thumbSource = 'https://cdn-d.tentangle.com/game-thumbs/source/';

const TextStyles = {
    Auto: 'Auto',
    Style1: 'Style1',
    Style101: 'Style101',
    Style2: 'Style2',
    Style201: 'Style201',
    Style3: 'Style3',
    Style4: 'Style4',
    Style5: 'Style5'
};



const TextStylesDescriptions = {
    Auto: 'Auto',
    Style1: '1 Line',
    Style101: '1 Line Big',
    Style2: '2 Line',
    Style201: '2 Line but smaller 2nd line',
    Style3: '2 Line starting with 2 words',
    Style4: '3 Line',
    Style5: '2 Lines long text'
};



const Actions = {
    'Move': 'Move',
    'Scale': 'Scale'
};
const Layers = {
    'Background': 'Background',
    'Foreground': 'Foreground'
};

const render3LinesWithThe = (elements, word1, word2, word3) => {

    var rest = word2 + word3;
    var txt1Style = {
        fontSize: `calc(${textWidthConstant}px / ${rest.length})`,
        lineHeight: `calc(${lineHeightConstant}px / ${rest.length})`
    };

    elements.push(<flex className=''>
        <span className={'txt-med'}>{word1}</span>
        <span style={txt1Style}>{rest}</span>
    </flex>);
};

const render3LinesWithOfInTheMiddle = (elements, word1, word2, word3) => {
    var txt1Style = {
        fontSize: `calc(${textWidthConstant}px / ${word1.length + 4})`,
        lineHeight: `calc(${lineHeightConstant}px / ${word1.length + 4})`
    };

    var txt2Style = {
        fontSize: `calc(${textWidthConstant}px / ${word3.length})`,
        lineHeight: `calc(${lineHeightConstant}px / ${word3.length})`
    };

    elements.push(<flex className=''>
        <span style={txt1Style}>{word1}</span>
        <span className={'txt-sml'}>{word2}</span>
        <span style={txt2Style}>{word3}</span>
    </flex>);
};

const renderLinesWithNumberAtEnd = (elements, word1, word2, word3) => {

    var ln = word1.length;
    if (word2.length > word1.length) {
        ln = word2.length;
    }

    var txt1Style = {
        fontSize: `calc(${textWidthConstantHalf}px / ${ln})`,
        lineHeight: `calc(${lineHeightConstantHalf}px / ${ln})`
    };

    var txt2Style = {
        fontSize: `calc(${textWidthConstantHalf}px / ${ln})`,
        lineHeight: `calc(${lineHeightConstantHalf}px / ${ln})`
    };

    var txt3Style = {
        fontSize: `calc(${textWidthConstantHalf}px / ${ln / 2})`,
        lineHeight: `calc(${lineHeightConstantHalf}px / ${ln / 2})`
    };

    elements.push(<flex className='gap-10'>
        <flex className='vertical'>
            <span style={txt1Style}>{word1}</span>
            <span style={txt2Style}>{word2}</span>
        </flex>
        <span style={txt3Style}>{word3}</span>
    </flex>);
};

const defaultComposition = {
    Background: null,
    Foreground: null,
    GradientColor: '#cf01e9',
    GradientPosition: 50,
    Text: '',
    Layers: {
        background: {
            position: {
                x: 0,
                y: 0
            },
            opacity: 1,
            scale: 1
        },
        foreground: {
            position: {
                x: 0,
                y: 0
            },
            opacity: 1,
            scale: 1
        }
    }
};

const findImage = (options, image) => {
    if (!image) return null;
    var file = image.replace(thumbSource, '');
    return options.find(x => x.value == file);
};

export const CasinoImageComposer = (props) => {
    const originalData = props.game;
    const [game, setGame] = useState(props.game);

    const [layers, setLayers] = useState({ ...defaultComposition });
    const [textElements, setTextElements] = useState([]);

    const [gameData, setGameData] = useState({});
    const [showGameData, setShowGameData] = useState(false);

    const [ready, setReady] = useState(false);

    const [backgroundImage, setBackgroundImage] = useState(null);
    const [foregroundImage, setForeGroundImage] = useState(null);

    const [backgroundBlob, setBackgroundBlob] = useState(null);
    const [foregroundBlob, setForegroundBlob] = useState(null);

    const [gridsVisible, showGrids] = useState(false);


    const backgroundRef = React.createRef();
    const foregroundRef = React.createRef();


    const [selectedAction, selectAction] = useState(null);
    const [selectedLayer, selectLayer] = useState(Layers.Background);

    const [backgroundCDN, setBackgroundCDN] = useState(false);
    const [foregroundCDN, setForegroundCDN] = useState(false);


    const [cdnThumbnails, setCDNThumbnails] = useState([]);

    const pasteImageFromClipBoard = async (e) => {
        var name = e.target?.name;
        if (!name) {
            return;
        }

        var valid = name == 'background-image' || name == 'foreground-image';
        if (!valid) return;


        const clipboardItems = typeof navigator?.clipboard?.read === 'function' ? await navigator.clipboard.read() : e.clipboardData.items;

        for (const clipboardItem of clipboardItems) {
            let blob;
            if (clipboardItem instanceof File && clipboardItem.type.startsWith('image/')) {
                // For files from `e.clipboardData.items`.
                blob = clipboardItem;
            } else if (clipboardItem.types?.includes('image/png')) {
                // For items from `navigator.clipboard.read()`.
                blob = await clipboardItem.getType('image/png');
            }

            if (blob) {
                drawImageOnCanvas(name, blob);
                e.preventDefault();
            }
        }
    };

    useEffect(() => {

        document.addEventListener('paste', pasteImageFromClipBoard);

        API.post(CMSEndpoints.GetThumbnails).then((thumbnailFilesResult) => {
            var thumbnailsResult = JSON.parse(thumbnailFilesResult.result);
            setCDNThumbnails(thumbnailsResult.files);
            API.post(CMSEndpoints.GetThumbnailComposition(game.gameId)).then((r) => {
                var compositionData = r.result;

                try {
                    compositionData = JSON.parse(r.result);
                } catch (err) {
                    //
                }
                if (!compositionData || Object.keys(compositionData).length == 0) {
                    compositionData = { ...defaultComposition };
                }


                API.post(CMSEndpoints.GetGameData(game.gameId), null, 'please wait', 'unable to load', false, 'c', false).then((result) => {
                    var data = {};
                    try {
                        data = JSON.parse(result.result);
                        if (!compositionData?.Background) {
                            if (data.background) {
                                compositionData.Background = data.background;
                            }
                        }
                    } catch (err) {
                        console.log(err);
                    }


                    if (!compositionData.Text) compositionData.Text = game.name;

                    if (compositionData.Background?.indexOf(thumbSource) >= 0) {
                        setBackgroundCDN(true);
                    } else {
                        setBackgroundCDN(false);
                    }

                    if (compositionData.Foreground?.indexOf(thumbSource) >= 0) {
                        setForegroundCDN(true);
                    } else {
                        setForegroundCDN(false);
                    }



                    setLayers(compositionData);
                    setGameData(data);
                });
            });
        });
        return () => {
            document.removeEventListener('paste', pasteImageFromClipBoard);
        };
    }, []);

    useEffect(() => {
        if (!gameData) return;
        if (!ready) setReady(true);
    }, [gameData]);

    useEffect(() => {
        if (!ready) return;
        var copy = { ...layers };
        setLayers(copy);
    }, [ready]);


    const loadImage = (src, onComplete) => {
        var img = new Image();
        img.src = `/api/content/thumb?&img=${src}`;

        img.onload = function () {
            onComplete(img);
        };
    };

    const drawImage = (canvas, img, posX, posY, scale) => {
        var ctx = canvas.getContext('2d');
        canvas.width = imageWidth;
        canvas.height = imageHeight;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, posX ?? (imageWidth - img.width) / 2, posY ?? (imageHeight - img.height) / 2, img.width * (scale ?? 1), img.height * (scale ?? 1));
    };


    const drawImageOnCanvas = (target, blob) => {
        const img = new Image();
        img.onload = function () {
            // Assume `imageWidth` and `imageHeight` are defined or calculated dynamically
            switch (target) {
                case 'background-image':
                    setBackgroundImage(img);
                    setBackgroundBlob(blob);
                    break;
                case 'foreground-image':
                    setForeGroundImage(img);
                    setForegroundBlob(blob);
                    break;
            }
        };
        img.style.background = 'transparent';
        img.src = URL.createObjectURL(blob);
    };



    useEffect(() => {
        if (!layers) return;
        document.getElementsByClassName('color-picker')[0].value = layers.GradientColor;

    }, [layers]);

    useEffect(() => {
        if (!ready) return;

        loadImage(layers.Background, (img) => {
            setBackgroundImage(img);
        });

    }, [layers.Background]);

    useEffect(() => {
        if (!ready) return;
        loadImage(layers.Foreground, (img) => {
            setForeGroundImage(img);
        });

    }, [layers.Foreground]);

    useEffect(() => {
        if (!ready) return;
        if (!backgroundImage) return;
        drawImage(backgroundRef.current, backgroundImage, layers.Layers.background.position.x, layers.Layers.background.position.y, layers.Layers.background.scale);
    }, [layers.Layers.background.key]);

    useEffect(() => {
        if (!ready) return;
        if (!foregroundImage) return;
        drawImage(foregroundRef.current, foregroundImage, layers.Layers.foreground.position.x, layers.Layers.foreground.position.y, layers.Layers.foreground.scale);
    }, [layers.Layers.foreground.key]);


    useEffect(() => {
        if (!backgroundImage) return;
        drawImage(backgroundRef.current, backgroundImage, layers.Layers.background.position.x, layers.Layers.background.position.y, layers.Layers.background.scale);
    }, [backgroundImage]);

    useEffect(() => {
        if (!foregroundImage) return;
        drawImage(foregroundRef.current, foregroundImage, layers.Layers.foreground.position.x, layers.Layers.foreground.position.y, layers.Layers.foreground.scale);
    }, [foregroundImage]);

    useEffect(() => {
        if (layers.TextStyle !== undefined && layers.TextStyle !== null && layers.TextStyle != 'Auto') {
            renderTextStyle();
            return;
        }
        var text = layers.Text ?? game.name;
        var name = text.split(' ');
        var elements = [];
        var txt1Style = 'txt-lg';
        var txt2Style = 'txt-sml';
        var txt3Style = 'txt-lg';

        var word1;
        var word2;
        var word3;

        switch (name.length) {
            case 1:
                word1 = name[0];
                elements.push(<flex className=''>
                    <span className={txt1Style}>{word1}</span>
                </flex>);
                break;
            case 2:
                word1 = name[0];
                word2 = name[1];

                if (!isNaN(word2)) {
                    txt1Style = {
                        fontSize: `calc(${textWidthConstant}px / ${text.length})`,
                        lineHeight: `calc(${lineHeightConstant}px / ${text.length}`
                    };

                    elements.push(<flex className=''>
                        <span style={txt1Style}>{text}</span>
                    </flex>);
                    break;
                }

                txt1Style = {
                    fontSize: `calc(${textWidthConstant}px / ${word1.length})`,
                    lineHeight: `calc(${lineHeightConstant}px / ${word1.length})`
                };

                txt2Style = {
                    fontSize: `calc(${textWidthConstant}px / ${word2.length})`,
                    lineHeight: `calc(${lineHeightConstant}px / ${word2.length})`
                };


                // BOMB BONANZA
                if (word1.length < word2.length && word1.length < 5 && word2.length > 5) {
                    txt1Style = {
                        fontSize: `calc(${textWidthConstantHalf}px / ${word1.length})`,
                        lineHeight: `calc(${lineHeightConstantHalf}px / ${word1.length})`
                    };
                }


                // BOMB BONANZA
                if (word1.length > word2.length && word2.length < 5) {
                    txt1Style = {
                        fontSize: `calc(${textWidthConstantHalf}px / ${word1.length})`,
                        lineHeight: `calc(${lineHeightConstantHalf}px / ${word1.length})`
                    };

                    txt2Style = {
                        fontSize: `calc(${textWidthConstantHalf}px / ${word1.length})`,
                        lineHeight: `calc(${lineHeightConstantHalf}px / ${word1.length})`
                    };
                }





                if (word1.length == 3) {
                    txt1Style = {
                        fontSize: `calc(${lineHeightConstant}px / ${text.length})`,
                        lineHeight: `calc(${lineHeightConstant}px / ${text.length})`
                    };

                    txt2Style = {
                        fontSize: `calc(${textWidthConstant}px / ${word2.length})`,
                        lineHeight: `calc(${lineHeightConstant}px / ${word2.length})`
                    };
                }


                elements.push(<flex className=''>
                    <span style={txt1Style}>{word1}</span>
                    <span style={txt2Style}>{word2}</span>
                </flex>);
                break;
            case 3:
                word1 = name[0];
                word2 = name[1];
                word3 = name[2];

                if (word1.toLowerCase() == 'the') {
                    render3LinesWithThe(elements, word1, word2, word3);
                    break;
                }

                if (word2.toLowerCase() == 'of') {
                    render3LinesWithOfInTheMiddle(elements, word1, word2, word3);
                    break;
                }

                if (!isNaN(word3) && word2.length != 3) {
                    renderLinesWithNumberAtEnd(elements, word1, word2, word3);
                    break;
                }


                if (word1.length + word2.length < 15) {
                    txt2Style = 'txt-lg';
                }

                if (word2.length == 2) {
                    txt2Style = 'txt-sml';
                }

                if (word2.length > word3.length && word3.length - word2.length > 3) {
                    txt3Style = 'txt-sml';
                }

                if (word3.length > 7) {
                    txt3Style = 'txt-sml';
                }


                elements.push(<flex className=''>
                    <span className={txt1Style}>{word1}</span>
                    <span className={txt2Style}>{word2}</span>
                    <span className={txt3Style}>{word3}</span>
                </flex>);
                break;

            case 4:
                word1 = name[0] + ' ' + name[1] + ' ' + name[2];
                word2 = name[3];

                txt1Style = 'txt-med';
                txt2Style = 'txt-lg';

                elements.push(<flex className=''>
                    <span className={txt1Style}>{word1}</span>
                    <span className={txt2Style}>{word2}</span>
                </flex>);
                break;
        }

        setTextElements(elements);
    }, [game.name, layers?.Text, layers?.TextStyle]);


    const renderTextStyle = () => {

        var text = layers?.Text ?? game.name;
        var name = text.split(' ');
        var elements = [];
        var txt1Style = 'txt-lg';
        var txt2Style = 'txt-sml';
        var txt3Style = 'txt-lg';

        var word1;
        var word2;
        var word3;


        switch (layers.TextStyle) {
            case TextStyles.Style1:
                txt1Style = {
                    fontSize: `calc(${lineHeightConstant}px / ${text.length})`,
                    lineHeight: `calc(${lineHeightConstant}px / ${text.length})`
                };
                elements.push(<flex className=''>
                    <span style={txt1Style}>{text}</span>
                </flex>);
                break;
            case TextStyles.Style101:
                elements.push(<flex>
                    <span className='txt-lg'>{text}</span>
                </flex>);
                break;
            case TextStyles.Style2:
                word1 = name.slice(0, 1).join('');
                word2 = name.slice(1).join(' ');

                txt1Style = {
                    fontSize: `calc(${lineHeightConstant}px / ${word1.length})`,
                    lineHeight: `calc(${lineHeightConstantHalf}px / ${word1.length})`
                };


                txt2Style = {
                    fontSize: `calc(${lineHeightConstant}px / ${word2.length})`,
                    lineHeight: `calc(${lineHeightConstant}px / ${word2.length})`
                };


                elements.push(<flex className=''>
                    <span style={txt1Style}>{word1}</span>
                    <span style={txt2Style}>{word2}</span>
                </flex>);
                break;
            case TextStyles.Style201:
                word1 = name.slice(0, 1).join('');
                word2 = name.slice(1).join(' ');


                elements.push(<flex className='vertical tx-center'>
                    <span className={'txt-lg'}>{word1}</span>
                    <span className={'txt-med'}>{word2}</span>
                </flex>);
                break;
            case TextStyles.Style3:
                word1 = name.slice(0, 2).join(' ');
                word2 = name.slice(2).join(' ');

                txt1Style = {
                    fontSize: `calc(${lineHeightConstant}px / ${word1.length})`,
                    lineHeight: `calc(${lineHeightConstantHalf}px / ${word1.length})`
                };


                txt2Style = {
                    fontSize: `calc(${lineHeightConstant}px / ${word2.length})`,
                    lineHeight: `calc(${lineHeightConstant}px / ${word2.length})`
                };


                elements.push(<flex className=''>
                    <span style={txt1Style}>{word1}</span>
                    <span style={txt2Style}>{word2}</span>
                </flex>);
                break;
            case TextStyles.Style4:
                name = text;
                name = name.replace(' & ', '&');
                name = name.replace(' - ', '');
                name = name.split(' ');

                word1 = name.slice(0, 1).join('');
                word2 = name.slice(1, 2).join(' ');
                word3 = name.slice(2).join(' ');



                txt1Style = {
                    fontSize: `calc(${lineHeightConstant}px / ${word1.length})`,
                    lineHeight: `calc(${lineHeightConstantHalf}px / ${word1.length})`
                };


                txt2Style = {
                    fontSize: `calc(${lineHeightConstant}px / ${word2.length})`,
                    lineHeight: `calc(${lineHeightConstant}px / ${word2.length})`
                };


                txt3Style = {
                    fontSize: `calc(${lineHeightConstant}px / ${word3.length})`,
                    lineHeight: `calc(${lineHeightConstant}px / ${word3.length})`
                };


                elements.push(<flex className='vertical tx-center'>
                    <span className={'txt-lg'}>{word1}</span>
                    <span className={'txt-med'}>{word2}</span>
                    <span className={'txt-med'}>{word3}</span>
                </flex>);
                break;
            case TextStyles.Style5:
                name = text;
                name = name.replace(' & ', '&');
                name = name.replace(' - ', '');
                name = name.split(' ');

                word1 = name.slice(0, 2).join(' ');
                word2 = name.slice(2).join(' ');


                txt1Style = {
                    fontSize: `calc(${lineHeightConstant}px / ${word1.length})`,
                    lineHeight: `calc(${lineHeightConstantHalf}px / ${word1.length})`
                };


                txt2Style = {
                    fontSize: `calc(${lineHeightConstant}px / ${word2.length})`,
                    lineHeight: `calc(${lineHeightConstant}px / ${word2.length})`
                };


                elements.push(<flex className='vertical tx-center'>
                    <span className={'txt-lg'}>{word1}</span>
                    <span className={'txt-med'}>{word2}</span>
                </flex>);
                break;
        }

        setTextElements(elements);
    };

    const getMaxWidthOfSelectedLayer = () => {
        var img = selectedLayer == Layers.Background ? backgroundImage : foregroundImage;
        var target = selectedLayer == Layers.Background ? 'background' : 'foreground';
        var properties = layers.Layers[target];
        return img?.width * (1 / (properties?.scale ?? 1));
    };


    const getHeightOfSelectedLayer = () => {
        var img = selectedLayer == Layers.Background ? backgroundImage : foregroundImage;
        var target = selectedLayer == Layers.Background ? 'background' : 'foreground';
        var properties = layers.Layers[target];
        return img?.height * (1 / (properties?.scale ?? 1));
    };


    const gBlur = (ctx, blur) => {
        let start = +new Date();
        blur = blur;
        let canvas = backgroundRef;

        let sum = 0;
        let delta = 5;
        let alpha_left = 1 / (2 * Math.PI * delta * delta);
        let step = blur < 3 ? 1 : 2;
        for (let y = -blur; y <= blur; y += step) {
            for (let x = -blur; x <= blur; x += step) {
                let weight = alpha_left * Math.exp(-(x * x + y * y) / (2 * delta * delta));
                sum += weight;
            }
        }
        let count = 0;
        for (let y = -blur; y <= blur; y += step) {
            for (let x = -blur; x <= blur; x += step) {
                count++;
                ctx.globalAlpha = alpha_left * Math.exp(-(x * x + y * y) / (2 * delta * delta)) / sum * blur;
                ctx.drawImage(canvas, x, y);
            }
        }
        ctx.globalAlpha = 1;
    };

    const uploadImageToCDN = (target) => {
        var url = layers[target];

        var targetFilename = replaceSpaceAndNonAlphaNumerics(`${target}-${game.gameId}-${layers.Text}`);
        API.post(CMSEndpoints.SaveThumbnailImageTOCDN, {
            url: url,
            ext: target == 'Background' ? 'jpg' : 'png',
            filename: targetFilename
        }).then((result) => {
            result.result.fileName = result.result.fileName.replace(/(\r\n|\n|\r)/gm, '');


            var copy = { ...layers }
            copy[target] = `${thumbSource}${result.result.fileName}`;

            copy[target] = copy[target].replace(/(\r\n|\n|\r)/gm, '');

            var cdnCopy = [...cdnThumbnails];
            var newThumb = {
                label: result.result.fileName,
                value: result.result.fileName
            };
            cdnCopy.push(newThumb);

            setCDNThumbnails(cdnCopy);
            setLayers(copy);

            setTimeout(() => {
                switch (target) {
                    case 'Background':
                        setBackgroundCDN(true);
                        break;
                    case 'Foreground':
                        setForegroundCDN(true);
                        break;
                }
            }, 500);
        });
    };

    const convertToBase64 = (file, callback) => {
        const reader = new FileReader();
        reader.onload = function (e) {
            callback(e.target.result); // This will be a base64 string
        };
        reader.readAsDataURL(file);
    };

    const uploadImageToCDNFromFile = (target) => {
        var data = null;
        Modal.open(<ConfirmationModal title='Upload image' onConfirm={() => {
            uploadBase64(target, data);
        }}>
            <ImageUpload onImageData={(img) => {
                data = img.base64;
            }} />
        </ConfirmationModal>);
    };


    const uploadImageToCDNFromImage = (target, blob) => {
        convertToBase64(blob, (base64image) => {
            uploadBase64(target, base64image);
        });
    };


    const uploadBase64 = (target, base64) => {

        console.log(base64);
        var targetFilename = replaceSpaceAndNonAlphaNumerics(`${target}-${game.gameId}-${layers.Text}`);
        API.post(CMSEndpoints.SaveThumbnailImageTOCDN, {
            image: base64,
            ext: target == 'Background' ? 'jpg' : 'png',
            filename: targetFilename
        }).then((result) => {
            result.result.fileName = result.result.fileName.replace(/(\r\n|\n|\r)/gm, '');


            var copy = { ...layers }
            copy[target] = `${thumbSource}${result.result.fileName}`;

            copy[target] = copy[target].replace(/(\r\n|\n|\r)/gm, '');

            var cdnCopy = [...cdnThumbnails];
            var newThumb = {
                label: result.result.fileName,
                value: result.result.fileName
            };
            cdnCopy.push(newThumb);

            setCDNThumbnails(cdnCopy);
            setLayers(copy);

            setTimeout(() => {
                switch (target) {
                    case 'Background':
                        setBackgroundBlob(null);
                        setBackgroundCDN(true);
                        break;
                    case 'Foreground':
                        setForegroundBlob(null);
                        setForegroundCDN(true);
                        break;
                }
            }, 500);
        });
    };



    var styleOptions = [];
    Object.keys(TextStyles).map(x => {
        styleOptions.push({
            label: TextStylesDescriptions[x],
            value: x
        });
    });

    var selectedStyle = styleOptions.find(x => x.value == layers?.TextStyle);

    var thumbnailOptions = [];
    cdnThumbnails.map(x => {
        thumbnailOptions.push({
            label: x.file,
            value: x.file,
            data: x
        });
    });

    var selectedBackgroundImage = findImage(thumbnailOptions, layers.Background);
    var selectedForegroundImage = findImage(thumbnailOptions, layers.Foreground);

    return <FluxCard title={game.name} className={'no-overflow'}
        buttons={<flex className='align-right'><Button title='Close' className={'success'} onClick={() => {
            props.onClose();
            appHistory.push(`${window.location.pathname}&provider=${game.providerId}`);
        }} /></flex>}
        footer={<flex className='gap-10 fit'>
            <Button title='Save' className={'success'} onClick={() => {
                showGrids(false);
                const finalizeSave = () => {
                    API.post(CMSEndpoints.SaveThumbnailComposition, {
                        gameId: game.gameId,
                        name: game.name,
                        background: layers.Background,
                        foreground: layers.Foreground,
                        gradientColor: layers.GradientColor,
                        gradientPosition: layers.GradientPosition,
                        textStyle: layers.TextStyle,
                        text: layers.Text ?? game.name,
                        layers: layers.Layers
                    }).then(() => {
                        Popper.pop('information', 'Success!!', 'game thumbnail saved');
                    });
                };

                if (originalData.name !== game.name) {
                    API.post(CMSEndpoints.UpdateGame, game).then(() => {
                        finalizeSave();
                    });
                } else {
                    finalizeSave();
                }
            }} />

            <Button title='Publish' className={'success align-right'} onClick={() => {
                showGrids(false);
                //var thumb = document.getElementsByClassName('thumbnail-holder')[0];
                html2canvas(document.getElementsByClassName('thumbnail')[0]).then(canvas => {
                    var payload = {
                        Image: canvas.toDataURL('image/jpeg'),
                        Name: replaceSpaceAndNonAlphaNumerics(game.name),
                        ProviderName: game.providerName,
                        Width: imageWidth,
                        Height: imageHeight,
                        Extension: 'jpg'
                    };
                    API.post(CMSEndpoints.PublishThumbnailCompositionImage(game.gameId), payload).then((result) => {
                        props.onUpdate(result);
                    });
                });
            }} />
        </flex>}>

        <flex className='vertical gap-20'>
            <flex className={`gap-20 casino-image-composer ${gridsVisible ? 'show-grids' : ''}`}>
                <flex className='checker' />
                <flex className='image-size-information'>
                    <span>{imageWidth} x {imageHeight} pixel</span>
                </flex>

                <Button className='success align-right save-image' onClick={() => {
                    //var thumb = document.getElementsByClassName('thumbnail-holder')[0];
                    html2canvas(document.getElementsByClassName('thumbnail')[0]).then(canvas => {
                        // Create an 'a' element to trigger a download
                        const a = document.createElement('a');
                        a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
                        a.download = 'exported-div.jpg';
                        a.click();
                    });
                }} >
                    <flex className='gap-5'>
                        <i className='fa-solid fa-download' />
                        <span>{lang('Download')}</span>
                    </flex>
                </Button>
                <FluxCard className='thumbnail-layers gap-20'>
                    <flex className='vertical gap-10'>
                        <Button className={selectedAction == Actions.Move ? 'success' : ''} onClick={() => {
                            if (selectedAction == Actions.Move) {
                                selectAction(null);
                                return;
                            }
                            selectAction(Actions.Move);
                        }}>
                            <flex className='vertical gap-5 align-items-center'>
                                <i className='fa-solid fa-arrows-up-down-left-right' />
                                <span>{lang('Move')}</span>
                            </flex>
                        </Button>
                        <Button className={selectedAction == Actions.Scale ? 'success' : ''} onClick={() => {
                            if (selectedAction == Actions.Scale) {
                                selectAction(null);
                                return;
                            }
                            selectAction(Actions.Scale);
                        }}>
                            <flex className='vertical gap-5 align-items-center'>
                                <i className='fa-solid fa-maximize' />
                                <span>{lang('Scale')}</span>
                            </flex>
                        </Button>
                        <Button className={selectedLayer == Layers.Background ? 'success' : ''} onClick={() => {
                            selectLayer(Layers.Background);
                        }}>
                            <flex className='vertical gap-5 align-items-center'>
                                <i className='fa-solid fa-image' />
                                <span>{lang('Back')}</span>
                            </flex>
                        </Button>
                        <Button className={selectedLayer == Layers.Foreground ? 'success' : ''} onClick={() => {
                            selectLayer(Layers.Foreground);
                        }}>
                            <flex className='vertical gap-5 align-items-center'>
                                <i className='fa-solid fa-image-portrait' />
                                <span>{lang('Fore')}</span>
                            </flex>
                        </Button>
                        <Button className={gridsVisible ? 'success' : ''} onClick={() => {
                            showGrids(!gridsVisible);
                        }}>
                            <flex className='vertical gap-5 align-items-center'>
                                <i className='fa-solid fa-grip-lines-vertical' />
                                <span>{lang('Grids')}</span>
                            </flex>
                        </Button>
                    </flex>
                </FluxCard>
                <flex className='thumbnail-holder'>
                    <flex className='thumbnail'>
                        <canvas ref={backgroundRef} id='background-canvas-ref'></canvas>
                        <canvas ref={foregroundRef} id='foreground-canvas-ref'></canvas>
                        <flex className='gradient' style={{
                            background: `linear-gradient(0deg, ${layers.GradientColor}, transparent ${layers.GradientPosition}%)`
                        }}></flex>
                        <flex className='game-name'>
                            {textElements}
                        </flex>
                        <flex className='provider-name'>
                            <span>
                                {game.providerName}
                            </span>
                        </flex>
                        <flex className='grid-horizontal grid-top' />
                        <flex className='grid-horizontal grid-bottom' />
                        <flex className='grid-vertical grid-left' />
                        <flex className='grid-vertical grid-right' />
                    </flex>
                </flex>
                {
                    selectedAction == Actions.Move && <FluxCard className={'image-range-x'}>
                        <flex className='gap-10'>
                            <flex className='vertical align-items-center justify-content-center'>
                                <span>X</span>
                                <input type='range' min={(getMaxWidthOfSelectedLayer() * -1) - imageWidth} max={imageWidth / 2} defaultValue={selectedLayer == Layers.Background ? layers.Layers.background.position.x : layers.Layers.foreground.position.x} onChange={(e) => {
                                    var target = selectedLayer == Layers.Background ? 'background' : 'foreground';
                                    var copy = { ...layers };
                                    copy.Layers[target].position.x = e.target.value;
                                    copy.Layers[target].key = Date.now();
                                    setLayers(copy);
                                }} />
                            </flex>
                            <flex className='vertical align-items-center justify-content-center'>
                                <span>Y</span>
                                <input type='range' min={(getHeightOfSelectedLayer() * -1) - imageHeight} max={imageHeight / 2} step={5} defaultValue={selectedLayer == Layers.Background ? layers.Layers.background.position.y : layers.Layers.foreground.position.y} onChange={(e) => {
                                    var target = selectedLayer == Layers.Background ? 'background' : 'foreground';
                                    var copy = { ...layers };
                                    copy.Layers[target].position.y = e.target.value;
                                    copy.Layers[target].key = Date.now();
                                    setLayers(copy);
                                }} />
                            </flex>
                        </flex>
                    </FluxCard>
                }
                {
                    selectedAction == Actions.Scale && <FluxCard className={'image-range-x'}>
                        <flex className='gap-10'>
                            <flex className='vertical align-items-center justify-content-center'>
                                <span>Scale X and Y</span>
                                <input type='range' min={0} max={100} defaultValue={selectedLayer == Layers.Background ? layers.Layers.background.scale * 100 : layers.Layers.foreground.scale * 100} onChange={(e) => {
                                    var target = selectedLayer == Layers.Background ? 'background' : 'foreground';
                                    var copy = { ...layers };
                                    copy.Layers[target].scale = e.target.value / 100;
                                    copy.Layers[target].key = Date.now();
                                    setLayers(copy);
                                }} />
                            </flex>
                        </flex>
                    </FluxCard>
                }
            </flex>
            <FluxCard className={'casino-image-composer-editor-layers gap-10 no-overflow'}>
                <flex className='vertical gap-20'>
                    <flex className='gap-20'>
                        <flex className='vertical gap-10'>
                            <flex className='gap-10'>
                                <label>{lang('Name')}</label>
                                <input className='input-property' type='text' defaultValue={game.name} onChange={(e) => {
                                    var copy = { ...game };
                                    copy.name = e.target.value;
                                    setGame(copy);
                                }} />
                            </flex>
                            <flex className='gap-10'>
                                <label>{lang('Text')}</label>
                                <input className='input-property' type='text' defaultValue={layers.Text} onChange={(e) => {
                                    var copy = { ...layers };
                                    copy.Text = e.target.value;
                                    setLayers(copy);
                                }} />
                            </flex>
                            {
                                backgroundBlob && <flex className='gap-10'>
                                    <label>{lang('Background')}</label>
                                    <span className='image-source-field'>{lang('Pasted from clipboard')}</span>
                                    <Button className={`padding clickable warning gap-5`} onClick={() => {
                                        setBackgroundBlob(null);
                                    }}>
                                        <span>{lang('Clear')}</span>
                                    </Button>
                                    <Button className={`padding clickable success  gap-5`} onClick={() => {
                                        uploadImageToCDNFromImage('Background', backgroundBlob);
                                    }}>
                                        <span>{lang('Save to CDN')}</span>
                                    </Button>
                                </flex>
                            }
                            {!backgroundBlob && <flex className='gap-10'>
                                <label>{lang('Background')}</label>
                                {
                                    backgroundCDN ? <ReactSelect
                                        className='react-select'
                                        value={selectedBackgroundImage}
                                        options={thumbnailOptions}
                                        key={thumbnailOptions}
                                        onChange={(e) => {
                                            var copy = { ...layers };
                                            copy.Background = `${thumbSource}${e.value}`;
                                            setLayers(copy);

                                        }}
                                        name={'TextStyle'}
                                        hideSelectedOptions={false}
                                        closeMenuOnSelect={true}
                                        isClearable={false}
                                        isSearchable
                                    /> : <input className='image-source-field' name='background-image' type='text' defaultValue={layers.Background} onChange={(e) => {
                                        var copy = { ...layers };
                                        copy.Background = e.target.value;
                                        setLayers(copy);
                                    }} />
                                }
                                <flex className='padding clickable' onClick={() => {
                                    setBackgroundCDN(!backgroundCDN);
                                }}>
                                    {backgroundCDN ? <i className='fa-regular fa-brackets-curly' /> : <i className='fa-regular fa-folder' />}
                                </flex>
                                <Button className={`padding clickable ${backgroundCDN ? 'disabled' : 'success'} gap-5`} onClick={() => {
                                    if (layers.Background.indexOf(thumbSource) >= 0) return;
                                    uploadImageToCDN('Background');
                                }}>
                                    <span>{lang('Save to CDN')}</span>
                                </Button>
                                <Button className={`padding clickable success`} onClick={() => {
                                    uploadImageToCDNFromFile('Background');
                                }}>
                                    <span>{lang('Select File To Upload')}</span>
                                </Button>
                            </flex>
                            }
                            {
                                foregroundBlob && <flex className='gap-10'>
                                    <label>{lang('Foreground')}</label>
                                    <span className='image-source-field'>{lang('Pasted from clipboard')}</span>
                                    <Button className={`padding clickable warning gap-5`} onClick={() => {
                                        setForegroundBlob(null);
                                    }}>
                                        <span>{lang('Clear')}</span>
                                    </Button>

                                    <Button className={`padding clickable success  gap-5`} onClick={() => {
                                        uploadImageToCDNFromImage('Foreground', foregroundBlob);
                                    }}>
                                        <span>{lang('Save to CDN')}</span>
                                    </Button>
                                </flex>
                            }
                            {!foregroundBlob && <flex className='gap-10'>
                                <label>{lang('Foreground')}</label>
                                {
                                    foregroundCDN ? <ReactSelect
                                        className='react-select'
                                        value={selectedForegroundImage}
                                        options={thumbnailOptions}
                                        key={thumbnailOptions}
                                        onChange={(e) => {
                                            var copy = { ...layers };
                                            copy.Foreground = `${thumbSource}${e.value}`;
                                            setLayers(copy);

                                        }}
                                        name={'TextStyle'}
                                        hideSelectedOptions={false}
                                        closeMenuOnSelect={true}
                                        isClearable={false}
                                        isSearchable={true}
                                    />
                                        : <input className='image-source-field' name='foreground-image' type='text' defaultValue={layers.Foreground} onChange={(e) => {
                                            var copy = { ...layers };
                                            copy.Foreground = e.target.value;
                                            setLayers(copy);
                                        }} />
                                }
                                <flex className='padding clickable' onClick={() => {
                                    setForegroundCDN(!foregroundCDN);
                                }}>
                                    {foregroundCDN ? <i className='fa-regular fa-brackets-curly' /> : <i className='fa-regular fa-folder' />}
                                </flex>
                                <Button className={`padding clickable ${foregroundCDN ? 'disabled' : 'success'} gap-5`} onClick={() => {
                                    if (layers.Foreground.indexOf(thumbSource) >= 0) return;
                                    uploadImageToCDN('Foreground');
                                }}>
                                    <span>{lang('Save to CDN')}</span>
                                </Button>
                                <Button className={`padding clickable success`} onClick={() => {
                                    uploadImageToCDNFromFile('Foreground');
                                }}>
                                    <span>{lang('Select File To Upload')}</span>
                                </Button>
                            </flex>
                            }
                        </flex>
                        <flex className='vertical gap-10'>
                            <flex className='gap-10'>
                                <label>{lang('Gradient')}</label>
                                <input type='color' className='color-picker' defaultValue={layers.GradientColor} onChange={(e) => {
                                    var copy = { ...layers };
                                    copy.GradientColor = e.target.value;
                                    setLayers(copy);
                                    document.getElementsByClassName('gradient-value')[0].value = e.target.value;
                                }} />
                                <input className='gradient-value' type='text' defaultValue={layers.GradientColor} onChange={(e) => {
                                    var copy = { ...layers };
                                    copy.GradientColor = e.target.value;
                                    setLayers(copy);
                                    document.getElementsByClassName('color-picker')[0].value = e.target.value;
                                }} />
                                <input type='range' min='1' max='100' className='hidden' defaultValue={layers.GradientPosition} onChange={(e) => {
                                    var copy = { ...layers };
                                    copy.GradientPosition = e.target.value;
                                    setLayers(copy);
                                }} />
                            </flex>
                            <flex className='gap-10 relative'>
                                <label>{lang('Text Style')}</label>
                                <ReactSelect
                                    className='react-select'
                                    value={selectedStyle}
                                    options={styleOptions}
                                    onChange={(e) => {
                                        var copy = { ...layers };
                                        copy.TextStyle = e.value;
                                        setLayers(copy);
                                    }}
                                    name={'TextStyle'}
                                    hideSelectedOptions={false}
                                    closeMenuOnSelect={true}
                                    isClearable={false}
                                    isSearchable={false}
                                />
                            </flex>
                        </flex>

                    </flex>
                    <flex className='vertical'>
                        <flex className='gap-10'>
                            <Button title={showGameData ? 'Close game data' : 'Open game data'} className={!showGameData ? 'success' : 'warning'} onClick={() => {
                                setShowGameData(!showGameData);
                            }} />
                            <Button title='Copy to clipboard' className='success align-right' onClick={() => {
                                var cp = { ...layers };
                                delete cp.Text;
                                var cpData = {
                                    type: 'cp-layers',
                                    payload: cp
                                };
                                copyDataToClipboard(JSON.stringify(cpData));
                            }} />
                            <Button title='Paste from clipboard' className='success align-right' onClick={() => {
                                navigator.clipboard
                                    .readText()
                                    .then((clipText) => {
                                        try {
                                            var converted = JSON.parse(clipText);
                                            if (converted.type == 'cp-layers') {
                                                setLayers(converted.payload);
                                            }

                                        } catch (err) {
                                            //
                                        }
                                    });
                            }} />
                        </flex>
                        {showGameData && <FluxCard className='casino-image-composer-editor-layers'>
                            <flex className='vertical gap-10'>
                                {
                                    gameData && Object.keys(gameData).map(x => {
                                        return <flex className='game-image-data gap-10' key={x}>
                                            <label>{x}</label>
                                            <input defaultValue={gameData[x]} />
                                            <i className='fa-regular fa-copy clickable' onClick={() => {
                                                copyDataToClipboard(gameData[x]);
                                            }} />
                                        </flex>;
                                    })
                                }
                            </flex>
                        </FluxCard>}
                    </flex>
                </flex>
            </FluxCard>
        </flex>
    </FluxCard>;
};

CasinoImageComposer.propTypes = {
    game: PropTypes.object,
    onUpdate: PropTypes.func,
    onClose: PropTypes.func
};