import './_favourite-actions.scss';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '../icon/Icon';
import { appHistory } from '../../..';
import { lang } from '../../lib/Localization/language';
import { LocalStorage } from '../../lib/Helpers/LocalStorage/LocalStorageHelper';

export const FavouriteMenuItems = () => {
    const fluxSettings = useSelector((state) => state.flux).settings;
    const dispatch = useDispatch();

    if (!fluxSettings?.favouriteActions || Object.keys(fluxSettings?.favouriteActions).length === 0) return <></>;

    const removeFromFavourites = (key) => {
        var storage = LocalStorage.get();

        if (storage.settings.favouriteActions[key]) {
            delete storage.settings.favouriteActions[key];
        }

        LocalStorage.save(storage);
        dispatch({ type: 'FAVOURITE_ACTION_ADDED', payload: key });
    };

    return <div className='favourite-actions'>
        <flex className='gap-10 favourites-title'>
            <div className='menu-icon'>
                <Icon icon='star' type='fa-light' />
            </div>
            <span className=''>{lang('Favourites')}</span>
        </flex>
        {Object.keys(fluxSettings.favouriteActions).map(key => {
            var action = fluxSettings.favouriteActions[key];
            return <flex className='gap-10 align-sel favourite-action' key={action}>
                <div className='item' onClick={(e) => {
                    console.log(e.target.className);
                    appHistory.push(key);
                }}>
                    <div className='menu-icon'>
                        <Icon icon={action.icon} type='fa-light' />
                    </div>
                    <span>{action.title}</span>
                </div>
                <flex className='align-right remove-action' onClick={() => {
                    removeFromFavourites(key, action);
                }}>
                    <Icon icon='times' />
                </flex>
            </flex>;
        })}
    </div>;
};