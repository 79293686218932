/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { FluxCard } from '../../../../components/card/FluxCard';
import { FluxForm } from '../../../../components/form/FluxForm';
import { InputTypes } from '../../../../components/form/InputTypes';
import { MarketSelection } from '../../../../components/sports/MarketSelection/MarketSelection';
import { CurrencyMinMaxRow } from './CurrencyMinMax/CurrencyMinMaxRow';


const model = {
    fields: [
        {
            name: 'prematch',
            title: 'Prematch',
            type: InputTypes.Bool
        },
        {
            name: 'live',
            title: 'Live',
            type: InputTypes.Bool
        },
        {
            name: 'cashout',
            title: 'Enable Cashout',
            type: InputTypes.Bool
        }
    ]
};



const minMaxOdds = {
    fields: [
        {
            name: 'minOdds',
            title: 'Minimum Odds',
            type: InputTypes.Number
        },
        {
            name: 'maxOdds',
            title: 'Maximum Odds',
            type: InputTypes.Number
        }
    ]
};


export const BetboosterSettings = (props) => {
    let { promotion, currencies, onChange } = { ...props };
    const [data, setData] = useState(promotion.Configuration.sport ?? { prematch: false, live: false, cashout: false, stake: {}, minOdds: 0, maxOdds: 0, markets: {} });
    const [stakeData, setStakeData] = useState(promotion.Configuration.sport?.stake ?? {})


    useEffect(() => {
        if (!data) return;
        promotion.Configuration.sport = data;
        onChange({ ...promotion });
    }, [data]);


    useEffect(() => {
        promotion.Configuration.sport.stake = stakeData;
        onChange({ ...promotion });
    }, [stakeData]);

    const updateStakeSettings = (currency, value) => {
        var copy = { ...stakeData };
        if (!copy[currency]) {
            copy[currency] = [
                {
                    From: 0,
                    To: 0
                }
            ];
        }

        copy[currency][0] = value;
        setStakeData(copy);
    };

    const deleteCurrencySetting = (target) => {
        var copy = { ...stakeData };
        delete copy[target];
        setStakeData(copy);
    };


    return <flex className='vertical gap-10'>
        <FluxCard title='Game Settings'>
            <FluxForm data={data} model={model} columns={2} buttons={null} onChange={(d) => setData(d)} />
        </FluxCard>
        <FluxCard title='Min / Max Odd Settings'>
            <FluxForm data={data} model={minMaxOdds} columns={2} buttons={null} onChange={(d) => setData(d)} />
        </FluxCard>
        <FluxCard title='Minimum / Maximum Stake Amounts'>
            {currencies.map(x => <CurrencyMinMaxRow key={x} currency={x} updateStakeSettings={updateStakeSettings} deleteCurrencySetting={deleteCurrencySetting} data={stakeData} />)}
        </FluxCard>
        <FluxCard title='Market Selection'>
            <MarketSelection onChange={(c)=>{
                console.log(c);
            }}/>
        </FluxCard>
    </flex >;
};