export const AuthenticationEndpoints = {
    Login: '/api/user/login',
    Logout: '/api/user/logout',
    Ping: '/api/user/ping',
    Enable2FA: '/api/authentication/GenerateGoogle2faQR',
    Disable2FA: (userId) => `/api/authentication/Google2FA/${userId}/disable`,
    Validate2FA: '/api/authentication/ValidateGoogle2fa'
};

export const AffiliateEndpoints = {
    ListGroups: '/api/affiliates/groups/list',
    SaveGroup: '/api/affiliates/group/save',
    ListAffiliates: '/api/affiliates/list',
    GetAffiliate: '/api/affiliates/get',
    SaveAffiliate: '/api/affiliates/save',
    Transactions: '/api/affiliates/transactions/search',
    TransactionsSummary: '/api/affiliates/transactions/search/summary',
    GetContactInformation: '/api/affiliates/contact',

    PlayerBonusTransactions: '/api/affiliates/bonus/search',

    ListAffiliatePlayers: '/api/affiliates/players/list/0',
};

export const AffiliateDashboardEndpoints = {
    GetDashboardStats: '/api/dashboard/affiliate/stats'
};

export const TransactionEndpoints = {
    Search: '/api/transactions/search/all',
    SearchPSP: '/api/transactions/psp/search/',
    Confirm: '/api/transactions/confirm',
    Create: '/api/transactions/create',
    CreateBonusTransaction: '/api/transactions/bonus/create',
    RemoveBonus: '/api/transactions/bonus/remove',
    ResetBalance: '/api/transactions/balance/reset',
    CancelTransaction: '/api/transactions/cancel',
    RollbackDeposit: '/api/transaction/deposit/rollback',
    Reject: '/api/transactions/reject',
    //#region Transaction-Limits
    SearchTransactionLimits: '/api/transactions/limits/search',
    SearchTransactionLimitsOnUse: '/api/transactions/limits/on-use/search',
    TransactionLimitsModify: '/api/transactions/limits/modify',
    TransactionLimitsGet: '/api/transactions/limits/get',
    //#endregion
};

export const OperatorEndpoints = {
    Search: '/api/operators/search',
    GetOperatorConfiguration: (id) => `/api/operator/configuration/${id}`,
    SaveOperatorCasinoConfiguration: (id) => `/api/operator/configuration/casino/save/${id}`,
    SaveOperatorPaymentConfiguration: (id) => `/api/operator/configuration/payment-providers/save/${id}`
};

export const DataEndpoints = {
    Groups: '/api/data/myPlayerGroups',
    EnabledCurrencies: '/api/data/EnabledCurrencies',

    AvailableLanguages: '/api/data/languages/available',
    Operators: '/api/data/operators',
    MyFeatures: '/api/data/features',
    MyConfiguration: '/api/data/configuration',

    MyAffiliates: '/api/data/myaffiliates/',

    SaveOperatorCurrencies: '/api/operator/configuration/currencies/save',

    /*gaming*/
    GameProviders: '/api/data/providers/gaming',
    Games: '/api/data/provider/games',
    GameTypes: '/api/data/provider/gameTypes',
    /**/

    /*providers*/
    PaymentProviders: '/api/data/providers/payment',
    /**/

    /*Notifications*/
    MyNotifications: '/api/data/mynotifications',
    OperatorNotifications: '/api/data/notifications/operator',
    /**/

    /*custom*/
    GetCustomData: '/api/data/get',
    /**/
};

export const ProviderEndpoints = {
    Get: '/api/data/providers',
    Update: '/api/provider/update',
    MyProviders: '/api/data/myproviders',
    SaveProviderWeights: '/api/cms/providers/weights/save'
};

export const PaymentProviders = {
    Get: '/api/data/providers/payment',
    Enable: '/api/provider/payment/enable',
    EnableFrontend: '/api/provider/payment/frontend/enable',
    Disable: '/api/provider/payment/disable',
    DisableFrontend: '/api/provider/payment/frontend/disable',
    SaveConfiguration: '/api/provider/payment/save/configuration',
    UpdateDisplay: '/api/provider/payment/update/display',
    UpdateWeights: '/api/provider/payment/update/weights',
    WhiteListsGroupsGet: '/api/provider/payment/white-lists/groups',
    WhiteListsGroupsSave: '/api/provider/payment/white-lists/groups/save',
    WhiteListsPlayersGet: '/api/provider/payment/white-lists/players',
    WhiteListsPlayersSave: '/api/provider/payment/white-lists/players/save',
};

export const CasinoLimitsEndpoints = {
    CasinoLimits: '/api/risk/application/casino/limits/get',
    SaveCasinoLimits: '/api/risk/application/casino/limits/save',

    GetGameTypeLimits: '/api/risk/application/casino/limits/providers',
    SaveGameTypeLimits: '/api/risk/application/casino/limits/providers/save',

    GameLimits: '/api/risk/application/casino/limits/games',
    SaveGameLimits: '/api/risk/application/casino/limits/games/save',
};

export const SportLimitsEndpoints = {
    SportLimits: '/api/risk/application/sport/limits/get',
    SaveSportLimits: '/api/risk/application/sport/limits/save'
};

export const SportPlayerLimitsEndpoints = {
    SportPlayerLimits: '/api/risk/application/sport/player-limits/get',
    SaveSportPlayerLimits: '/api/risk/application/sport/player-limits/save'
};

export const HorseLimitsEndpoints = {
    Get: '/api/risk/horse/limits',
    Save: '/api/risk/horse/limits/save'
};

export const PromotionEndpoints = {
    List: '/api/promotions/list',
    Save: '/api/promotion/save',
    SaveWeights: '/api/promotion/save-weights',
    Delete: '/api/promotion/delete',
    Publish: '/api/cms/promotions/publish',
    FreeSpinConfiguration: '/api/promotion/freespin/configuration',
    AssignFreeSpinToPlayers: '/api/promotion/freespin/assign',
    CreateFreespinCampaign: '/api/promotion/freespin/campaign/create',
    ListCampaigns: '/api/promotion/freespin/campaign/list',
    UpdateCampaign: '/api/promotion/freespin/campaign/update',
    BulkRemovePromotion: '/api/promotion/player/bulk/remove',

    AutoGeneratePromotionCodes: '/api/promotion/auto-generate',
    BulkDisableAutoGeneratedPromotionCodes: '/api/promotion/auto-generate/bulk-disable',
    SearchAutoGeneratedPromotionCodes: '/api/promotion/auto-generate/search',

    PromotionCodeCreate: '/api/promotion/code/create',
    PromotionCodeDisable: '/api/promotion/code/disable',
    PromotionCodeSearch: '/api/promotion/code/search',
    PromotionCodeConsumeSearch: '/api/promotion/code/consumed-players/search',
};

export const CasinoTournamentEndpoints = {
    GetProviderConfiguration: '/api/bonus/casino/tournaments/configuration/provider',
    GetPromotionConfiguration: '/api/bonus/casino/tournaments/configuration/promotion',
    SaveTournamentConfiguration: '/api/bonus/casino/tournaments',
    UpdateTournamentState: '/api/bonus/casino/tournament/status/update',
    Create: '/api/bonus/casino/tournament/create',
    Get: '/api/bonus/casino/tournament'
};

export const PlayerPromotionEndpoints = {
    AssignBonus: '/api/transactions/bonus/create',
    BulkRemovePromotion: '/api/promotion/player/bulk/remove',
    CompleteBonus: '/api/transactions/bonus/complete',
    RemoveBonus: '/api/transactions/bonus/remove',
    GetPlayerPromotion: (playerId) => `/api/promotion/${playerId}/active`,
    GetPlayerConsumedPromotion: (playerId) => `/api/promotion/${playerId}/consumed`
};

export const RolloverAmountEndpoints = {
    Modify: '/api/transactions/deposit/rollover/modify',
    Reset: '/api/transactions/deposit/rollover/reset'
};

export const PlayerEndpoints = {
    Get: '/api/player',
    Search: '/api/player/search',
    SearchPlayerDetailWithBalance: '/api/player/search-player-detail-with-balance',
    Profile: '/api/player/profile',
    Create: '/api/player/new',
    Update: '/api/player/update',
    ToggleEnablePlayer: '/api/player/enabled/toggle',
    ChangePassword: '/api/player/changepassword',
    SearchGameHistory: '/api/player/history/games',
    SearcNotifications: '/api/player/notifications',
    SearchActivity: '/api/player/activity',
    SearchTransactions: '/api/player/transactions/search',
    Summary: '/api/player/summary',
    Verficiations: '/api/player/verifications/search',

    Notes: '/api/player/notes',
    AddPlayerNote: '/api/player/notes/add',
    RemovePlayerNote: '/api/player/notes/delete',
    UpdatePlayerNote: '/api/player/notes/update',

    ChangeEmail: '/api/player/email/update',
    GetPlayerGroups: '/api/player/groups',
    SavePlayerGroup: '/api/player/group/save',
    PlayerGroupsWithRelation: '/api/player/groups-with-relation',

    ShowActivityAfterDeposit: '/api/player/activity/after/deposit',
    NotifyPlayer: '/api/player/notify',
    ChangePlayerRisk: '/api/player/risk/change',
    SearchSameIpPlayers: '/api/compliance/sameIp',

    // promotions
    SearchPlayerBonusProgress: '/api/promotions/player/bonus/progress',
    SearchPlayersWithFreeSpin: '/api/promotion/freespin/players/list',
    SearchPlayersPendingPromotions: '/api/promotions/player/bonus/pending',
    RemoveFreeSpin: '/api/promotion/freespin/remove',

    RejectPendingPromotion: '/api/promotions/pending/reject',
    RejectPendingPromotions: '/api/promotions/pending/reject/selected',

    Enable2FA: (playerId) => `/api/player/2fa/enable/${playerId}`,
    Revoke2FA: (playerId) => `/api/player/2fa/revoke/${playerId}`,
    Verify: (playerId) => `/api/player/verify/enable/${playerId}`,
    Unverify: (playerId) => `/api/player/verify/disable/${playerId}`
};

export const InventoryEndpoints = {
    GetPlayerInventory: '/api/inventory/player',
};

export const SportEndpoints = {
    GetEventById: '/api/sports/event',
    RecoverEvent: '/api/sports/recover/event',
    Categories: '/api/sports/categories',
    Regions: '/api/sports/regions',
    SaveCategories: '/api/sports/categories/save',
    Leagues: '/api/sports/leagues',
    LeaguesByName: '/api/sports/leagues-by-name',
    Events: '/api/sports/events',
    Event: '/api/sports/event',
    Teams: '/api/sports/teams',
    SaveLeagues: '/api/sports/leagues/save',
    Markets: '/api/sports/markets',
    SaveMarkets: '/api/sports/categories/markets',
    SearchInTree: '/api/sports/search-in-tree',

    SearchSportEvents: '/api/sports/search/events',

    /*cms*/
    GetTopLeagues: '/api/sports/cms/topleagues/get',
    GetLiveTopLeagues: '/api/sports/cms/topleagues/live/get',
    SaveTopLeagues: '/api/sports/cms/topleagues/save',
    SaveLiveTopLeagues: '/api/sports/cms/topleagues/live/save',

    SaveTopEvents: '/api/sports/cms/topevents/save',
    GetTopEvents: '/api/sports/cms/topevents/get',
    /**/

    /*Trader*/
    GetOpenMarkets: '/api/trader/selections'
    /**/
};

export const TraderEndpoints = {
    GetOpenTickets: (operatorId) => `/api/trader/tickets/unsettled/${operatorId}`,
    GetUnsettledEvents: (operatorId) => `/api/trader/events/unsettled/${operatorId}`,
    GetEventsOpenSelections: (operatorId, id, showAll = false) => `/api/trader/${operatorId}/event/${id}/open-selections/${showAll}`,
    SendSettlement: (operatorId, id) => `/api/trader/${operatorId}/event/${id}/settlement`,
    SendManualSettlement: (eventId, marketId, selectionId, status) => `/api/sports/trading/event/${eventId}/market/${marketId}/selection/${selectionId}/status/${status}`,
    BrowseEvents: '/api/sports/trading/events',
    EventSelections: (id) => `/api/sports/trading/event/${id}/selections`,
    EventSelectionsWithBets: (id) => `/api/sports/trading/event/${id}/bets`,
    GetEventById: (id) => `/api/sports/trading/event/${id}`,
};

export const ManagementEndpoints = {
    SearchUser: '/api/operatorUsers/search',
    SearchUserActivity: '/api/operatorUsers/searchUserActivity',
    GetUserGroups: '/api/operators/getOperatorUserGroups',
    Save: '/api/operatorUsers/new',
    Update: '/api/operatorUsers/update',
    ChangePassword: '/api/operatorUsers/Password/Update'
};

export const POSEndpoints = {
    SearchUsers: '/api/pos/users/search',
    SearchPOSUserTransactions: '/api/pos/user/transactions/search',
    POSDeposit: '/api/pos/user/deposit',
    POSWithdraw: '/api/pos/user/withdraw',
    POSSearchPlayers: '/api/player/search',
    POSDepositPlayer: '/api/pos/player/deposit',
    POSWithdrawPlayer: '/api/pos/player/withdraw',
    POSProviderBindings: '/api/pos/user/setup/providers',
};

export const RetailEndpoints = {
    Save: '/api/retail/save',
    Retails: '/api/retails/',
    MyRetails: '/api/data/myRetails/',
    Get: '/api/retail',
    GetRetailConfiguration: '/api/retail/configuration',
    ListRetailUsers: '/api/retail/users',
    ListRetailPlayers: '/api/retail/players',
    CreateRetailUser: '/api/retail/user/create',
    UpdateRetailUser: '/api/retail/user/update',
    CreateRetailPlayer: '/api/retail/player/create',
    GetRetailPlayer: '/api/retail/player',
    UpdateRetailPlayer: '/api/retail/player/update',

    // obsolete
    ListRetailTransactions: '/api/retail/transactions',
    ListRetailChildTransactions: '/api/retail/transactions/children',

    RetailSettlements: '/api/retail/settlements',
    AddRetailCredit: '/api/retail/credit/add',
    RemoveRetailCredit: '/api/retail/credit/remove',
    DepositRetailPlayer: '/api/retail/player/deposit',
    WithdrawRetailPlayer: '/api/retail/player/withdraw',
    Tree: '/api/retail/tree'
};

export const ReportEndpoints = {
    // Revenues
    DailyRevenuesSummary: '/api/reports/revenues/daily/summary',
    CasinoRevenuesReport: '/api/reports/casino',
    CasinoProviderRevenuesReport: '/api/reports/casino/providers',
    GameRevenuesReports: '/api/reports/casino/games',
    PlayerCasinoRevenuesReport: '/api/reports/casino/player',
    PlayerGroupsGameRevenuesReport: '/api/reports/casino/groups',
    CasinoProviderCommissionReport: '/api/reports/casino/providers/commissions',
    ProviderRevenuesReport: '/api/reports/provider/revenues',
    // Sport Betting
    SportBettingRevenuesReport: '/api/reports/sports/revenues',
    SportBettingPlayerRevenuesReport: '/api/reports/sports/revenues/player',
    SportBettingPlayerGroupsRevenuesReport: '/api/reports/sports/revenues/groups',
    SportCategoryRevenueSummaryReport: '/api/reports/sports/categories',
    SportRegionRevenueSummaryReport: '/api/reports/sports/regions',
    SportLeagueRevenueSummaryReport: '/api/reports/sports/leagues',
    SportEventRevenueSummaryReport: '/api/reports/sports/events',
    SportMarketRevenueSummaryReport: '/api/reports/sports/markets',
    SportSelectionRevenueSummaryReport: '/api/reports/sports/selections',
    SportPlayerRevenueSummaryReport: '/api/reports/sports/players',
    SportTickets: '/api/sports/tickets',
    SportEventRevenueReport: '/api/reports/sports/eventRevenues',
    SearchPlayersWithoutSportBets: '/api/sports/players/withoutbets',
    // Horse / Greyhound
    HorseGreyhoundSummaryReport: '/api/reports/horse/summary',
    HorseGreyHoundRegionsReport: '/api/reports/horse/regions',
    HorseGreyHoundVenuesReport: '/api/reports/horse/venues',
    HorseGreyHoundDistanceReport: '/api/reports/horse/distance',
    HorseGreyHoundPlayersReport: '/api/reports/horse/players',
    HorseGreyHoundTicketsReport: '/api/horse/tickets/search',
    // Player Summary
    RevenuesPlayerSummaryReport: '/api/reports/players/revenues',
    GamesPlayerSummaryReport: '/api/reports/players/games',
    // Transactions
    TransactionsAccountSummaryReport: '/api/reports/transactions/summary',
    TransactionsProviderReport: '/api/reports/provider/transactions',
    TransactionsPlayerReport: '/api/reports/provider/transactions/players/details',
    TransactionsFirstTransactionReport: '/api/reports/transactions/first',
    //Retails
    RetailsAccountSummaryReport: '/api/reports/retail/account/summary',
    RetailTransactionReport: '/api/reports/retail/transactions',
    RetailPlayerTransactionReport: '/api/reports/retail/players/transactions',
    ChildRetailCasinoSummary: '/api/reports/retail/slot/summary-retails/children',
    //Bonus
    BonusTransactionsReport: '/api/transactions/bonus/search',
    BonusPlayersReport: '/api/promotions/player/bonus/progress',
    BonusPlayerSummaryReport: '/api/promotions/player/bonus/summary',
    BonusCompletedPayoutsReport: '/api/promotions/player/bonus/completed',
    BonusCompletedPayoutSummaryReport: '/api/promotions/player/bonus/completed/summary',
    BonusRemovedBonusesReport: '/api/promotions/player/bonus/removed',
};

export const CMSEndpoints = {
    GetDefaultLanguage: '/api/cms/language/web/default',
    GetGames: '/api/cms/games/list',
    GetGamesSummary: '/api/cms/games/list/summary',
    SaveGame: '/api/cms/game/save',
    SaveGameWeight: '/api/cms/game/weight/save',
    SaveGameTag: '/api/cms/game/tag/save',
    SaveContent: '/api/cms/save',
    PublishWebSiteLanguage: '/api/cms/language/publish/website',
    SaveLanguageKey: '/api/cms/language/key/save',
    DeleteLanguageKey: '/api/cms/language/key/delete',
    SaveAvailableLanguages: '/api/cms/languages/available/save',
    GetLSportsEventParticipants: '/api/cms/sport/participants/lsports',
    GetBetradarEventParticipants: '/api/cms/sport/participants/betradar',
    UpdateLSportTeamImages: '/api/cms/sport/participants/lsport/image/update',

    GetProviderLanguages: '/api/cms/providers/languages',

    GetSportCategoriesLanguages: '/api/cms/sports/categories/languages',
    GetSportRegionsLanguages: '/api/cms/sports/regions/languages',
    GetSportMarketLanguages: '/api/cms/sports/markets/languages',
    GetSportSelectionLanguages: '/api/cms/sports/selections/languages',

    GetSportMarkets: '/api/cms/sports/markets',
    GetSportMarketGroups: '/api/cms/sports/markets/groups',
    SaveSportMarketGroups: '/api/cms/sports/markets/groups/save',
    GetOverviewMarkets: '/api/sports/cms/markets/overview',
    SaveOverviewMarkets: '/api/sports/cms/markets/overview/save',

    /*Content*/
    Contents: '/api/cms/contents',
    Content: '/api/cms/content',
    CMSSaveContent: '/api/cms/save',

    CMSPublishPages: '/api/cms/pages/publish',
    PublishPromotions: '/api/cms/promotions/publish',
    /**/

    /*Upload images*/
    CMSUploadImage: '/api/cms/image/save',
    /**/
    SaveThumbnailImageTOCDN: '/api/cms/cdn/thumbnail/save',
    GetGameData: (gameId) => `/api/cms/game/data/${gameId}`,
    UpdateGame: '/api/cms/game/update',
    SaveThumbnailComposition: '/api/cms/game/thumbnail/save/composition',
    GetThumbnails: '/api/cms/game/thumbnails/cdn',
    GetThumbnailComposition: (gameId) => `/api/cms/game/thumbnail/composition/${gameId}`,
    PublishThumbnailCompositionImage: (gameId) => `/api/cms/game/thumbnail/composition/image/save/${gameId}`,
};

export const FixtureEndpoints = {
    SetupSportCategories: '/api/setup/sports/categories',
    SetupSportRegions: '/api/setup/sports/regions',
    SetupSportLeagues: '/api/setup/sports/leagues',
    SetupSportEvents: '/api/setup/sports/events',
    SaveSetupSportCategories: '/api/setup/sports/categories/save',
    SaveSetupSportRegions: '/api/setup/sports/regions/save',
    SaveSetupSportLeagues: '/api/setup/sports/leagues/save',
    SaveSetupSportEvents: '/api/setup/sports/events/save'
};

export const RiskLevelEndpoints = {
    Get: '/api/risk/levels',
    Save: '/api/risk/levels/save',
    Sports: '/api/risk/levels/sports',
    SaveSports: '/api/risk/levels/sports/save',
    Leagues: '/api/risk/levels/leagues',
    SaveLeagues: '/api/risk/levels/leagues/save',
    Markets: '/api/risk/levels/markets',
    SaveMarkets: '/api/risk/levels/markets/save',
    Liabilities: '/api/risk/liabilities',
    Monitor: '/api/risk/levels/monitor',
    MonitorSports: '/api/risk/levels/monitor/sports',
    MonitorEventVolumes: '/api/risk/levels/monitor/volumes/event',
    MonitorMarketVolumes: '/api/risk/levels/monitor/volumes/event/market',
    MonitorEventBets: '/api/risk/levels/monitor',
    MonitorTickets: '/api/risk/bet/monitor/tickets',
    LastRemainingTickets: '/api/risk/levels/monitor/tickets/lastremaining',
    Ticker: '/api/sport/risk/ticker',
    Stats: '/api/sport/risk/ticker/stats',
};

export const SportRiskLevelEndpoints = {
    AddToInvestigatation: '/api/risk/sport/investigate/add',
    RemoveFromInvestigation: '/api/risk/sport/investigate/remove',
    GetInvestigationFlags: '/api/risk/sport/investigate/flags',
};

export const RiskLimitEndpoints = {
    Templates: (operatorId) => `/api/risk/sports/operator-limits/${operatorId}/templates`,
    GetAllTemplates: (operatorId) => `/api/risk/sports/operator-limits/${operatorId}/templates/all`,
    GetTemplate: (operatorId, id) => `/api/risk/sports/operator-limits/${operatorId}/template/${id}`,
    GetAllTemplatesWithFilter: (operatorId) => `/api/risk/sports/operator-limits/${operatorId}/templates/all-with-filter`,
    GetTemplateWithAll: (operatorId, type, id) => `/api/risk/sports/operator-limits/${operatorId}/get-template/${type}/${id}`,
    SaveTemplate: (operatorId) => `/api/risk/sports/operator-limits/${operatorId}/template/save`,
    DisableTemplate: (operatorId, id) => `/api/risk/sports/operator-limits/${operatorId}/templates/disable/${id}`,
    AssignTemplate: (operatorId, type, id, templateId) => `/api/risk/sports/operator-limits/${operatorId}/template/assign/${type}/${id}/${templateId}`,
    GetTemplateUsage: (operatorId, templateId) => `/api/risk/sports/operator-limits/${operatorId}/templates/usage/${templateId}`,
    SaveStakeFactoring: (operatorId) => `/api/risk/sports/operator-limits/${operatorId}/stake-factoring/save`,
    GetStakeFactoring: (operatorId) => `/api/risk/sports/operator-limits/${operatorId}/stake-factoring`,
    SaveMarketLimits: (operatorId) => `/api/risk/sports/operator-limits/${operatorId}/market-limits/save`,
    GetMarketLimits: (operatorId) => `/api/risk/sports/operator-limits/${operatorId}/market-limits`,
};

export const HorseRiskLevelEndpoints = {
    Get: '/api/horse/risk/levels',
    Save: '/api/horse/risk/levels/save',
    Categories: '/api/horse/risk/levels/categories',
    SaveCategories: '/api/horse/risk/levels/categories/save',
    Venues: '/api/horse/risk/levels/venues',
    SaveVenues: '/api/horse/risk/levels/venues/save',
    Markets: '/api/horse/risk/levels/markets',
    SaveMarkets: '/api/horse/risk/levels/markets/save',

    Stats: '/api/risk/horse/ticker/stats',
    Ticker: '/api/risk/horse/ticker',

    AddToInvestigatation: '/api/risk/horse/investigate/add',
    RemoveFromInvestigation: '/api/risk/horse/investigate/remove',
    GetInvestigationFlags: '/api/risk/horse/investigate/flags',
};

export const SportRiskMonitorEndpoints = {
    Liabilities: '/api/sport/risk/liability',
    Event: '/api/sport/risk/event',
    Players: '/api/sport/risk/event/players',
    IncreaseEventLiabilityVolume: '/api/sport/risk/event/liability/change'
};

export const HorsePendingTickets = {
    confirm: '/api/horse/risk/pending/confirm',
    reject: '/api/horse/risk/pending/reject'
};

export const SportPendingTickets = {
    confirm: '/api/sport/risk/pending/confirm',
    reject: '/api/sport/risk/pending/reject'
};

export const SportOveraskEndpoints = {
    Accept: (operatorId, offerId) => `/api/risk/sports/offer/accept/${operatorId}/${offerId}`,
    Reject: (operatorId, offerId) => `/api/risk/sports/offer/reject/${operatorId}/${offerId}`,
    Offers: (operatorId) => `/api/risk/sports/offers/${operatorId}`,
    GetConfiguration: (operatorId) => `/api/risk/sports/offers/${operatorId}/configuration`,
    UpdateOverAskTimeout: (operatorId) => `/api/risk/sports/offers/${operatorId}/configuration/update-overask-countdown`,
};

export const TicketEndpoints = {
    Search: '/api/sports/tickets',
    Resolved: '/api/sports/tickets/resolved',
    TicketDetails: '/api/sports/ticket',
    ManualTickets: '/api/sports/tickets/manual',
    SearchHorseTickets: '/api/horse/tickets/search',
    SearchHorseTicketsResolved: '/api/horse/tickets/resolved',
    HorseTicket: '/api/horse/tickets',
    HorseTicketDetails: '/api/horse/ticket/details',
    OpenSportsTickets: '/api/betslip/sports/tickets/open',
    Cashout: '/api/betslip/cashout',
    SportTicketResult: '/api/betslip/sport/result',
    TicketResult: 'api/result/getticketresult',
    SportEventResults: '/api/sports/results/events',

    ManualTicketWin: '/api/ticket/win',
    ManualTicketLost: '/api/ticket/lost',
    ManualTicketCancel: '/api/ticket/cancel',
    ManualTicketUpdate: '/api/ticket/update',
    ManualTicketRollback: '/api/ticket/rollback',

    SportRejectedTickets: '/api/risk/sport/rejectedbets',
    SportResettlementReports: '/api/risk/sport/resettlement-reports'
};

export const SportTournamentEndpoints = {
    GetStats: (id) => `/api/tournament/sports/${id}/stats`,
    GetPlayers: (id) => `/api/tournament/sports/${id}/players`,
    GetLeaderboard: (id) => `/api/tournament/sports/${id}/leaderboard`,
    GetLeaderboardBetweenDates: (id) => `/api/tournament/sports/${id}/leaderboard/dates`
};

export const HorseTicketEndpoints = {
    TicketDetails: '/api/sports/ticket',

    ManualTicketWin: '/api/horse/ticket/win',
    ManualTicketLost: '/api/horse/ticket/lost',
    ManualTicketCancel: '/api/horse/ticket/cancel',
    ManualTicketUpdate: '/api/horse/ticket/update',
    ManualTicketRollback: '/api/horse/ticket/rollback',
};

export const SetupEndpoints = {
    ExchangeRates: '/api/exchangeRates/list',
    SaveExchangeRate: '/api/exchangeRates/save',
};

export const ConfigurationEndpoints = {
    Get: '/api/operator/configuration/',
    SaveOperatorRiskType: '/api/operator/configuration/risk/save',
    UserGroups: '/api/userGroups/search',
    UserGroupBinding: '/api/userGroups/getGroupBindings',
    UpdateUserGroupBindings: '/api/userGroups/update/',
    SaveOperatorUserRights: '/api/userGroups/operator/group/save'
};