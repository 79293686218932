
// React
import React, { useEffect, useState } from 'react';
import { Router, Route, Switch } from 'react-router-dom';

import PropTypes from 'prop-types';

// PAGES



import { LogoutPage } from '../components/pages/LogoutPage';


// COMPONENTS
//import { Menu } from 'flux/Menu/Menu';
import { Footer } from '../components/Footer/Footer';
import { Endpoints } from '../components/Application/Application';
import { useDispatch, useSelector } from 'react-redux';
import { postData } from '../state/services/ServiceHelpers';
import { lang } from '../flux/lib/Localization/language';
import { Header } from '../flux/components/header/Header';
import { Menu } from '../flux/components/menu/Menu';
import { FluxApp } from '../flux/components/application/FluxApp';
import { PlayersPage } from '../flux/pages/Players/PlayersPage';
import { SportsPage } from '../flux/pages/Sports/SportsPage';
import { ReportsPage } from '../flux/pages/Reports/ReportsPage';
import { HomePage } from '../flux/pages/Home/HomePage';
import { CasinoPage } from '../flux/pages/Casino/CasinoPage';
import { TransactionsPage } from '../flux/pages/Transactions/TransactionsPage';
import { AffiliatesPage } from '../flux/pages/Affiliates/AffiliatesPage';
import { LoginPage } from '../flux/pages/Login/LoginPage';
import { PromotionsPage } from '../flux/pages/Promotions/PromotionsPage';
import { AdministrationPage } from '../flux/pages/AdministrationPage/AdministrationPage';
import { RetailsPage } from '../flux/pages/Retails/RetailsPage';
import { ProfilePage } from '../flux/pages/Profile/ProfilePage';
import { FluxCard } from '../flux/components/card/FluxCard';
import { API } from '../flux/lib/API/Api';
import { AuthenticationEndpoints } from '../flux/lib/API/Endpoints';
import { FluxNotice } from '../flux/components/notification/FluxNotice';
import { NotificationType } from '../flux/components/notification/FluxNotification';
import { CmsPage } from '../flux/pages/CMS/CmsPage';
import { SetupPage } from '../flux/pages/Setup/SetupPage';
import { Button } from '../flux/components/button/Button';
import { HorseGreyhoundPage } from '../flux/pages/HorseGreyhound/HorseGreyhoundPage';
import { PlatformSettings } from '../flux/pages/PlatformSettings/PlatformSettings';
import { POSPage } from '../flux/pages/POS/POSPage';


var _history;

export const getHistory = () => {
    return _history;
};


export const AppRouterFlux = (props) => {
    _history = props.history;
    const [ping, setPing] = useState(null);
    const user = useSelector((state) => state.user)?.loginInformation;
    const dispatch = useDispatch();
    const [authError, setAuthError] = useState(false);


    useEffect(() => {
        if (user && !ping) {
            setPing(setInterval(() => {
                postData(null, Endpoints.Ping);
            }, 1000 * 60 * 5));
        }
    });

    useEffect(() => {
        dispatch({ type: 'ROUTER_READY', payload: null });
    }, []);

    if (!user) {
        return <React.Fragment>
            <FluxApp>
                <LoginPage />
            </FluxApp>
        </React.Fragment>;
    }

    if (!user.TwoFactorAuthenticated && user.AuthType == 1) {
        var code = 0;
        const sendCode = () => {
            if (code.length < 6) {
                return;
            }
            API.post(`${AuthenticationEndpoints.Validate2FA}/${code}/login`, null).then((result) => {
                if (result.result) {
                    dispatch({ type: 'TWO_FA_AUTHENTICATED', payload: null });
                } else {
                    setAuthError(true);
                }
            });
        };
        if (!authError) {
            document.getElementById('codeInput')?.focus();
        }
        return <FluxApp>
            <FluxCard title='Authenticate' className='center'>
                <flex className='vertical gap-20 center'>

                    {authError && <FluxNotice type={NotificationType.Error} title='Invalid code' description={'The code you entered is invalid or expired.'} />}
                    {!authError && <label className='medium'>{lang('User your google authenticator to continue')}</label>}
                    <flex className='vertical'>
                        <label className='medium'>{lang('Code')}</label>
                        <input id='codeInput' type='number' maxLength={6} max={1000000} size={6} onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                sendCode();
                                return false;
                            }
                        }} onChange={(e) => {
                            code = e.target.value;
                            if (code?.length == 6) {
                                sendCode();
                            }
                        }} />
                    </flex>
                    <flex className='flex vertical gap-5'>
                        <Button className='success center' title='Continue' onClick={() => {
                            sendCode();
                        }} />
                    </flex>
                </flex>

            </FluxCard>
        </FluxApp>;
    }

    return <Router history={props.history}>
        <React.Fragment>
            <Header />
            <FluxApp>
                <Menu history={props.history} />
                <Switch>
                    <Route exact path='/' render={() => <HomePage />} />
                    <Route exact path='/login' render={() => <LoginPage />} />
                    <Route exact path='/home' render={() => <HomePage />} />
                    <Route path='/casino' component={CasinoPage} />
                    <Route path='/reports' component={ReportsPage} />
                    <Route path='/players' component={PlayersPage} />
                    <Route path='/sports' component={SportsPage} />
                    <Route path='/horse-greyhound' component={HorseGreyhoundPage} />
                    <Route path='/transactions' component={TransactionsPage} />
                    <Route path='/promotions' component={PromotionsPage} />
                    <Route path='/affiliates' component={AffiliatesPage} />
                    <Route path='/affiliate' component={AffiliatesPage} />
                    <Route path='/retails' component={RetailsPage} />
                    <Route path='/pos' component={POSPage} />
                    <Route path='/cms' component={CmsPage} />
                    <Route path='/setup' component={SetupPage} />
                    <Route path='/administration' component={AdministrationPage} />
                    <Route path='/platform-settings' component={PlatformSettings} />
                    <Route path='/profile' component={ProfilePage} />
                    <Route path='/logout' component={LogoutPage} />
                </Switch>
                <Footer />
            </FluxApp>
        </React.Fragment>
    </Router>;

};


AppRouterFlux.propTypes = {
    user: PropTypes.object,
    history: PropTypes.object
};




