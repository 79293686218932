import './_manage-user-rights.scss';

import React, { useEffect, useState } from 'react';
import { ActionButton, FluxCard } from '../../../components/card/FluxCard';
import { FluxTab } from '../../../components/tab/FluxTab';
import { API } from '../../../lib/API/Api';
import { ConfigurationEndpoints } from '../../../lib/API/Endpoints';
import { FluxList } from '../../../components/list/FluxList';
import { ToggleButton } from '../../../components/toggleButton/ToggleButton';
import { toSpaceCase } from '../../../lib/Helpers/StringHelper';
import { Button } from '../../../components/button/Button';
import { Modal } from '../../../components/application/FluxApp';
import { ConfirmationModal } from '../../../components/modal/ConfirmationModal/ConfirmationModal';
import { FluxNotice } from '../../../components/notification/FluxNotice';
import { Popper } from '../../../components/notification/FluxPopper';


const groupTypes = {
    Manager: 3,
    Setup: 4,
    'Call Center': 5,
    Finance: 6,
    Marketting: 7,
    Support: 20
};





export const ManageUserRights = () => {
    const [selectedGroup, selectGroup] = useState(null);
    const [userRights, setMasterRights] = useState(null);

    const [selectedRight, selectRights] = useState(null);
    const [groupRights, setGroupRights] = useState(null);


    const [updateKey, setUpdateKey] = useState(null);

    var tabButtons = [
        {
            title: 'Manager'
        },
        {
            title: 'Setup'
        },
        {
            title: 'Call Center'
        },
        {
            title: 'Finance'
        },
        {
            title: 'Marketting'
        },
        {
            title: 'Support'
        }
    ];

    useEffect(() => {
        // get owner rights
        API.post(`${ConfigurationEndpoints.UserGroupBinding}/2`).then((result) => {
            setMasterRights(result.result);
        });
    }, []);

    useEffect(() => {
        if (!userRights) return;
        selectGroup(groupTypes.Manager);
    }, [userRights]);

    useEffect(() => {
        if (!selectedGroup) return;
        API.post(`${ConfigurationEndpoints.UserGroupBinding}/${selectedGroup}`).then((result) => {
            setGroupRights(result.result);
        });
    }, [selectedGroup]);


    useEffect(() => {
        if (!groupRights) return;
    }, [groupRights]);

    const renderUserRights = () => {
        if (!selectedRight) return;
        var masterRights = userRights[selectedRight];
        var groupActions = groupRights[selectedRight];

        return <FluxList className='actions-list-menu' data={Object.keys(masterRights).filter(x => masterRights[x])} onRender={(x) => {
            return <flex className='gap-10'>
                <span>{toSpaceCase(x)}</span>
                <flex className='align-right'>
                    <ToggleButton key={x} value={groupActions && groupActions[x]} onChange={(val) => {
                        var copy = { ...groupRights };
                        if (!copy[selectedRight]) {
                            copy[selectedRight] = {};
                        }
                        if (copy[selectedRight][x] === val) return;
                        copy[selectedRight][x] = val;
                        setGroupRights(copy);
                    }} />
                </flex>
            </flex>;
        }}>
        </FluxList>;
    };
    if (!userRights) return <></>;


    return <flex className='vertical gap-10 manage-user-rights overflow-hidden'>
        <FluxTab buttons={tabButtons} onChange={(index) => {
            var id = tabButtons[index];
            var type = groupTypes[id.title];
            selectGroup(type);
        }} className='gap-10 fx-shadow fx-radius rounded-buttons'></FluxTab>
        <flex className='gap-10 align-items-start overflow-hidden' key={selectedGroup}>
            <FluxCard className={'actions-menu'}>
                <flex className='vertical gap-10 overflow-hidden'>
                    <flex>
                        <Button title='Save user rights' className={'success'} onClick={() => {
                            Modal.open(<ConfirmationModal title='Save user rights?' onConfirm={() => {
                                var copy = {};
                                Object.keys(userRights).map(x => {
                                    copy[x] = {};
                                    Object.keys(userRights[x]).map(y => {
                                        copy[x][y] = groupRights[x] && groupRights[x][y] ? groupRights[x][y] : false;
                                    });
                                });
                                API.post(`${ConfigurationEndpoints.SaveOperatorUserRights}/${selectedGroup}`, copy, 'please wait!', 'unable to save user rights!').then(() => {
                                    Popper.pop('information', 'User rights updated');
                                });
                            }}>
                                <FluxNotice type='information' title='Please confirm' description={'Users access will be updated after their new login'} />
                            </ConfirmationModal>);
                        }} />
                    </flex>
                    <flex className='vertical overflow-hidden'>
                        <FluxList
                            className='actions-list'
                            data={Object.keys(userRights)}
                            onSelected={(x) => {
                                selectRights(x);
                            }}
                            onRender={(x) => {
                                var total = Object.keys(userRights[x]).length;
                                return <flex className='gap-10'>
                                    <span>{toSpaceCase(x)}</span>
                                    <flex className='gap-5 align-right'>
                                        <span>{total}</span>
                                    </flex>
                                </flex>;
                            }} />
                    </flex>
                </flex>
            </FluxCard>
            <FluxCard key={selectedRight + updateKey}
                title={selectedRight ? selectedRight : 'Please select a group from left menu'}
                className={'action-list overflow-hidden'}
                buttons={selectedRight ? <flex className='align-right'>
                    <ActionButton title='Disable All' icon='times' onClick={() => {
                        var copy = { ...groupRights };
                        Object.keys(copy[selectedRight]).map(x => {
                            copy[selectedRight][x] = false;
                        });
                        setGroupRights(copy);
                        setUpdateKey(Date.now());
                    }} />
                    <ActionButton title='Enable All' icon='check' onClick={() => {
                        var copy = { ...groupRights };
                        Object.keys(copy[selectedRight]).map(x => {
                            copy[selectedRight][x] = true;
                        });
                        setGroupRights(copy);
                        setUpdateKey(Date.now());
                    }} />
                </flex> : null}>
                <flex className='vertical gap-10 align-self-start'>
                    {renderUserRights()}
                </flex>
            </FluxCard>
        </flex>
    </flex>;
};