import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { API } from '../../../lib/API/Api';
import { AffiliateEndpoints } from '../../../lib/API/Endpoints';
import { FluxCard } from '../../../components/card/FluxCard';
import { lang } from '../../../lib/Localization/language';
import { Button } from '../../../components/button/Button';


export const AffiliateContactInformation = (props) => {
    const [user, setUser] = useState(null);
    useEffect(() => {
        API.post(`${AffiliateEndpoints.GetContactInformation}/${props.affiliate.Id}`).then((result) => {
            setUser(result.result);
        });
    }, []);

    if (!user) return <></>;
    return <FluxCard footer={<Button className='success' title='Edit' onClick={() => {
    }} />}>
        <flex className='gap-10 vertical'>
            <flex className='gap-10 columns-2'>
                <flex className='vertical'>
                    <label className='medium'>{lang('Name')}</label>
                    <span>{user.name}</span>
                </flex>
                <flex className='vertical'>
                    <label className='medium'>{lang('Surname')}</label>
                    <span>{user.surname}</span>
                </flex>
            </flex>
            <flex className='gap-10 columns-2'>
                <flex className='vertical'>
                    <label className='medium'>{lang('Email')}</label>
                    <span>{user.email}</span>
                </flex>
                <flex className='vertical'>
                    <label className='medium'>{lang('Username')}</label>
                    <span>{user.username}</span>
                </flex>
            </flex>
            <flex className='gap-10 columns-2'>
                <flex className='vertical'>
                    <label className='medium'>{lang('Enabled')}</label>
                    <span>{user.enabled ? 'true' : 'false'}</span>
                </flex>
            </flex>
        </flex>
    </FluxCard>;
};

AffiliateContactInformation.propTypes = {
    affiliate: PropTypes.object
};