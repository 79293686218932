import React, { useEffect, useState } from 'react';
import { currency } from '../../../components/currency/Currency';
import { InputTypes } from '../../../components/form/InputTypes';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { ReportEndpoints, RetailEndpoints } from '../../../lib/API/Endpoints';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { footerCurrencyValues } from '../../../lib/Helpers/Reports/Footer';
import { CreateTree } from '../../Retails/RetailTree/RetailTree';

const slotReportFilters = {
    name: 'slot-report',
    fields: [
        {
            name: 'RetailId',
            hidden: true
        },
        {
            name: 'Date',
            title: 'Date',
            type: InputTypes.DateTime,
            between: true,
            value: {
                start: DateHelpers.today(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'Code',
            title: 'Code'
        },
        {
            name: 'Username',
            title: 'Username'
        },
        {
            name: 'ProviderId',
            title: 'Provider'
        },
        {
            name: 'Game',
            title: 'Game'
        }
    ]
};



const slotReport = {
    name: 'slot-report',
    fields: [
        {
            name: 'TotalGames',
            title: 'Total Games'
        },
        {
            name: 'Bet',
            title: 'Stake',
            render: (val, row) => {
                return currency(Math.abs(row.Bet), 2, row.Currency);
            },
            renderFooter: (val) => footerCurrencyValues(val, true),
            export: (val, row) => {
                return Math.abs(row.Bet) - Math.abs(row.Cancel) - Math.abs(row.Win);
            }
        },
        {
            name: 'Cancel',
            title: 'Cancel',
            render: (val, row) => {
                return currency(Math.abs(row.Cancel), 2, row.Currency);
            },
            renderFooter: (val) => footerCurrencyValues(val, true),
            export: (val, row) => {
                return Math.abs(row.Bet) - Math.abs(row.Cancel) - Math.abs(row.Win);
            }
        },
        {
            name: 'Win',
            title: 'Win',
            render: (val, row) => {
                return currency(Math.abs(row.Win), 2, row.Currency);
            },
            renderFooter: (val) => footerCurrencyValues(val, true),
            export: (val, row) => {
                return Math.abs(row.Bet) - Math.abs(row.Cancel) - Math.abs(row.Win);
            }
        },
        {
            name: 'Profit',
            title: 'Profit',
            render: (val, row) => {
                return currency(Math.abs(row.Bet) - Math.abs(row.Cancel) - Math.abs(row.Win), 2, row.Currency);
            },
            renderFooter: (val) => footerCurrencyValues(val, true),
            export: (val, row) => {
                return Math.abs(row.Bet) - Math.abs(row.Cancel) - Math.abs(row.Win);
            }
        },
        {
            name: 'Margin',
            title: 'Margin',
            render: (val, row) => {
                var profit = Math.abs(row.Bet) - Math.abs(row.Cancel) - Math.abs(row.Win);
                var margin = (profit * 100) / (Math.abs(row.Bet) - Math.abs(row.Cancel));
                return `%${margin.toFixed(2)}`;
            },
            export: (val, row) => {
                var profit = Math.abs(row.Bet) - Math.abs(row.Cancel) - Math.abs(row.Win);
                var margin = (profit * 100) / (Math.abs(row.Bet) - Math.abs(row.Cancel));
                return `%${margin.toFixed(2)}`;
            }
        }

    ]
};


export const RetailCasinoReportSummary = (props) => {
    const { retail } = { ...props };
    const [model, setModel] = useState(null);
    const [resultModel, setResultModel] = useState(null);


    useEffect(() => {
        let copy = { ...slotReportFilters };
        copy.fields = [{
            name: 'Retails',
            title: 'Retail',
            type: InputTypes.Tree,
            nullable: true,
            data: {
                source: RetailEndpoints.Tree,
                key: 'Id',
                title: ['Name'],
                params: {
                    fields: [
                        {
                            name: 'Id',
                            value: retail.Id
                        }
                    ]
                },
                parser: (values) => {
                    if (!values) return;
                    var retails = CreateTree(values.result, {
                        Id: retail.Id,
                        Name: retail.Name,
                        children: []
                    });

                    return retails[0];
                }
            }
        }].concat(copy.fields);
        copy.fields.find(x => x.name == 'RetailId').value = retail.Id;
        setModel(copy);
    }, []);

    useEffect(() => {
        if (!model) return;
        var copy = { ...slotReport };
        setResultModel(copy);
    }, [model]);



    if (!model || !resultModel) return <></>;


    return <FluxSearch model={model} resultModel={resultModel} title='Casino Report Summary' endpoint={ReportEndpoints.ChildRetailCasinoSummary} />;
};
