/* eslint-disable react/display-name */
import React from 'react';
import { useSelector } from 'react-redux';
import { ReportEndpoints } from '../../../../v2/Lib/Api/Api';
import { Currency } from '../../../components/currency/Currency';
import { InputTypes } from '../../../components/form/InputTypes';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { footerCurrencyValues, footerValues } from '../../../lib/Helpers/Reports/Footer';
import { CurrencyFilterField } from '../../../lib/Models/CurrencyModel';
import './_report-betting-operational.scss';

const searchModel = {
    fields: [
        {
            name: 'OperatorId',
            title: 'Operator',
            type: InputTypes.Select,
            data: {
                source: 'operators',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'Date',
            title: 'Date',
            type: InputTypes.Date,
            between: true,
            value: {
                start: DateHelpers.yesterday(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'RetailId',
            title: 'Retail Id',
            nullable: true,
            type: InputTypes.Number
        },
        // {
        //     name: 'RetailCode',
        //     title: 'Retail Code'
        // },
        {
            name: 'Group',
            title: 'Player Group',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'myPlayerGroups',
                key: 'Id',
                title: ['Name']
            }
        },
        CurrencyFilterField,
        // {
        //     name: 'ConvertCurrency',
        //     title: 'Convert Currency',
        //     type: InputTypes.Bool
        // },
        {
            name: 'Retails',
            title: 'Only Retails',
            type: InputTypes.Bool
        }
    ]
};

const defaultCurrencyFieldWithFooter = (name, title, customHeader) => {
    if (customHeader) {
        return {
            name: name,
            title: title,
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true),
            renderHeaderWithSorting: () => customHeader(),
        };
    }
    return {
        name: name,
        title: title,
        render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
        renderFooter: (val) => footerCurrencyValues(val),
        exportFooter: (val) => footerCurrencyValues(val, true)
    };
};

const resultModel = {
    fields: [
        {
            name: 'Date',
            title: 'Date',
            type: InputTypes.Date
        },
        {
            name: 'NewPlayerCount',
            title: 'New Player Count',
            type: InputTypes.Number,
            renderHeaderWithSorting: () => {
                return <div className='flex vertical'>
                    <span className='center'>New</span>
                    <span className='center'>Player</span>
                    <span className='center'>Count</span>
                </div>;
            },
            renderFooter: (val) => val,
            exportFooter: (val) => val
        },
        {
            name: 'FirstDepositCount',
            title: 'First Deposit Count',
            type: InputTypes.Number,
            renderHeaderWithSorting: () => {
                return <div className='flex vertical'>
                    <span className='center'>First</span>
                    <span className='center'>Deposit</span>
                    <span className='center'>Count</span>
                </div>;
            },
            renderFooter: (val) => val,
            exportFooter: (val) => val
        },
        {
            name: 'ActiveUniquePlayerCount',
            title: 'Active Unique Player Count',
            type: InputTypes.Number,
            renderHeaderWithSorting: () => {
                return <div className='flex vertical'>
                    <span className='center'>Active Unique</span>
                    <span className='center'>Player Count</span>
                </div>;
            },
            renderFooter: (val) => val,
            exportFooter: (val) => val
        },
        {
            name: 'Currency',
            title: 'Currency',
            type: InputTypes.Text,
        },
        {
            name: 'SportTickets',
            title: 'Sport Tickets',
            type: InputTypes.Number,
            renderFooter: (val) => footerValues(val, false, 0),
            exportFooter: (val) => footerValues(val, true, 0)
        },
        defaultCurrencyFieldWithFooter('SportTurnover', 'Cash Turnover'),
        defaultCurrencyFieldWithFooter('SportWin', 'Cash Win'),
        defaultCurrencyFieldWithFooter('SportProfit', 'Cash Profit'),
        {
            name: 'SportMargin',
            title: 'Margin',
            renderFooter: (val) => footerValues(val, false),
            exportFooter: (val) => footerValues(val, true)
        },
        defaultCurrencyFieldWithFooter('SportBonusTurnover', 'Sport Bonus Turnover', () => {
            return <div className='flex vertical'>
                <span className='center'>Sport Bonus</span>
                <span className='center'>Turnover</span>
            </div >;
        }),
        defaultCurrencyFieldWithFooter('SportBonusWin', 'Sport Bonus Win', () => {
            return <div className='flex vertical'>
                <span className='center'>Sport</span>
                <span className='center'>Bonus Win</span>
            </div >;
        }),
        defaultCurrencyFieldWithFooter('SportFreebetBonusTurnover', 'Freebet Bonus Turnover',
            () => {
                return <div className='flex vertical'>
                    <span className='center'>Freebet</span>
                    <span className='center'>Bonus Turnover</span>
                </div >;
            }),
        defaultCurrencyFieldWithFooter('SportFreebetBonusWin', 'Freebet Bonus Win',
            () => {
                return <div className='flex vertical'>
                    <span className='center'>Freebet</span>
                    <span className='center'>Bonus Win</span>
                </div >;
            }),
        defaultCurrencyFieldWithFooter('BonusComplete', 'Wagering Bonus Cost',
            () => {
                return <div className='flex vertical'>
                    <span className='center'>Wagering</span>
                    <span className='center'>Bonus Cost</span>
                </div >;
            }),
        defaultCurrencyFieldWithFooter('BonusManual', 'Bonus Manual'),
        defaultCurrencyFieldWithFooter('BonusRemove', 'Bonus Remove'),
        defaultCurrencyFieldWithFooter('GGR', 'GGR'),
        defaultCurrencyFieldWithFooter('NGR', 'NGR')
    ]
};


export const ReportBettingOperational = () => {
    const user = useSelector((state) => state.user.loginInformation);

    searchModel.fields.find(x => x.name == 'OperatorId').value = user.UserPrivileges.PointOfView;

    return <FluxSearch
        tableClassName={'report-betting-operational'}
        model={searchModel}
        endpoint={ReportEndpoints.ReportBettingOperational}
        resultModel={resultModel}
        exportAllData={true}
        onBeforeSearch={(payload) => {
            var copy = { ...payload };
            copy.name = 'report-betting-operational';
            copy.fields.push({
                name: 'TimeZoneOffset',
                title: 'TimeZoneOffset',
                readonly: true,
                type: 'hidden',
                hidden: true,
                value: window.user.timeZoneConverted
            });
            return copy;
        }}
    />;
};
