import React from 'react';
import { Endpoints } from '../../components/Application/Application';
import { toPascalCase } from '../../components/Centrum/helpers';
import { API } from '../../v2/Lib/Api/Api';
import { lang } from '../lib/Localization/language';
import { Modal } from '../components/application/FluxApp';
import { FluxForm } from '../components/form/FluxForm';
import { InputTypes } from '../components/form/InputTypes';
import { ConfirmationModal } from '../components/modal/ConfirmationModal/ConfirmationModal';
import { ErrorModal } from '../components/modal/ErrorModal/ErrorModal';
import { FluxModal } from '../components/modal/FluxModal';
import { FluxNotice } from '../components/notification/FluxNotice';
import { NotificationType } from '../components/notification/FluxNotification';
import { Popper } from '../components/notification/FluxPopper';
import { RetailEndpoints } from '../lib/API/Endpoints';
import { currency } from '../components/currency/Currency';


export const removePlayerFromRetail = (player, handler) => {
    Modal.open(<ConfirmationModal title='Move Player to Retail' onConfirm={() => {
        API.post(`${Endpoints.RemoveMovePlayerFromRetail}/${player.Id}/`, null).then((result) => {
            Modal.close();
            Popper.pop('information', 'Player removed from retail.');
            if (handler) {
                handler(result.result);
            }
        }).catch((error) => {
            Modal.open(<ErrorModal title='Error'><p>{lang(error?.error?.message ?? 'API Error')}</p></ErrorModal>)
        });
    }}>
        <flex className='vertical gap-10'>
            {
                player.Balance > 0 && <FluxNotice type='warning' title='Player account is not empty' description={lang('%1 will be withdrawn from player balance and will be transfered to %2.', [currency(player.Balance, 2, player.Currency), player.Affiliate])} />
            }
            {player.Balance == 0 && <span>{lang('Please confirm removing player from retail %1', [player.RetailName])}</span>}
        </flex>
    </ConfirmationModal>);
};



export const movePlayerToRetail = (player, handler) => {
    Modal.open(<FluxModal title='Move Player to Retail'>
        {
            player.Balance > 0 && <FluxNotice type='warning' title='Player account is not empty' description={lang('%1 will be withdrawn from player balance.', [currency(player.Balance, 2, player.Currency), player.Affiliate])} />
        }
        <FluxForm
            onSubmit={(data) => {
                data.playerId = player.Id;

                API.post(`${Endpoints.MovePlayerToRetail}/${player.Id}/${data.RetailId}/true`, null).then((result) => {
                    Modal.close();
                    Popper.pop('information', 'Player moved.');
                    if (handler) {
                        handler(result.result);
                    }
                }).catch((error) => {
                    Modal.open(<ErrorModal title='Error'><p>{lang(error?.error?.message ?? 'API Error')}</p></ErrorModal>)
                });
            }}
            onCancel={() => Modal.close()}
            model={{
                fields: [
                    {
                        name: 'RetailId',
                        title: 'Retails',
                        type: InputTypes.Select,
                        data: {
                            source: 'myRetails',
                            key: 'Id',
                            title: ['Code', 'Name']
                        }
                    }
                ]
            }} />
    </FluxModal>);
};


export const editRetail = (id, handler) => {
    const editRetailModel = {
        fields: [
            {
                name: 'Code',
                title: 'Code'
            },
            {
                name: 'Name',
                title: 'Name'
            },
            {
                name: 'Description',
                title: 'Description',
                type: InputTypes.Textarea
            },
            {
                name: 'Enabled',
                title: 'Enabled',
                type: InputTypes.Bool
            }
        ]
    };

    API.post(`${RetailEndpoints.Get}/${id}`).then((result) => {
        Modal.open(<FluxModal title='Edit Retail'>
            <FluxForm
                data={toPascalCase(result.result)}
                columns={2}
                endpoint={RetailEndpoints.Save}
                model={editRetailModel}
                onCancel={() => Modal.close()}
                onSubmitComplete={(result, data) => {
                    if (handler) {
                        handler(toPascalCase(result.result), data);
                    }
                    Modal.close();
                    Popper.pop(NotificationType.Information, null, lang('Retail profile updated.'));
                }}
            />
        </FluxModal>);
    });
};



export const addCredit = (retail, handler) => {
    Modal.open(<FluxModal title='Add Credit'>
        <FluxForm
            submitButtonTitle='Deposit'
            onSubmit={(data) => {
                API.post(RetailEndpoints.AddRetailCredit, data, 'please wait', 'Unable add credit to retail.').then((result) => {
                    Modal.close();
                    handler(data, result);
                });
            }}
            model={{
                fields: [
                    {
                        name: 'RetailId',
                        value: retail.Id,
                        hidden: true
                    },
                    {
                        name: 'Amount',
                        title: 'Amount',
                        required: true,
                        type: InputTypes.Number
                    },
                    {
                        name: 'Notes',
                        title: 'Notes',
                        required: true,
                        type: InputTypes.Textarea
                    }
                ]
            }} />
    </FluxModal>);
};


export const removeCredit = (retail, handler) => {
    Modal.open(<FluxModal title='Remove Credit'>
        <FluxForm
            submitButtonTitle='Withdraw'
            onSubmit={(data) => {
                API.post(RetailEndpoints.RemoveRetailCredit, data, 'please wait', 'Unable add credit to retail.').then((result) => {
                    Modal.close();
                    handler(data, result);
                });
            }}
            model={{
                fields: [
                    {
                        name: 'RetailId',
                        value: retail.Id,
                        hidden: true
                    },
                    {
                        name: 'Amount',
                        title: 'Amount',
                        required: true,
                        type: InputTypes.Number
                    },
                    {
                        name: 'Notes',
                        title: 'Notes',
                        required: true,
                        type: InputTypes.Textarea
                    }
                ]
            }} />
    </FluxModal>);
};


