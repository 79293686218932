/* eslint-disable react/display-name */

import '../../../components/sports/RiskManagement/RiskLevels/_riskLevelConfiguration.scss';
import './_ticker.scss';

import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';


import { Modal } from '../../../components/application/FluxApp';
import { Button } from '../../../components/button/Button';
import { FluxCard } from '../../../components/card/FluxCard';
import { currency } from '../../../components/currency/Currency';
import { InputTypes } from '../../../components/form/InputTypes';
import { Icon } from '../../../components/icon/Icon';
import { ConfirmationModal } from '../../../components/modal/ConfirmationModal/ConfirmationModal';
import { FluxNotice } from '../../../components/notification/FluxNotice';
import { getLookupKey } from '../../../components/sports/RiskManagement/RiskLevels/RiskLevelLimitsConfiguration';
import { Table } from '../../../components/table/Table';
import { API } from '../../../lib/API/Api';
import { HorseRiskLevelEndpoints } from '../../../lib/API/Endpoints';
import { BettingFlags, RacingTicketTypes } from '../../../lib/Enums/BettingEnums';
import { NotifyWithSound } from '../../../lib/Helpers/Sound/SoundHelper';
import { lang } from '../../../lib/Localization/language';
import { HorseTicketId } from '../../../lib/Models/TicketModels';
import { SearchPlayerHorseGreyhoundHistory } from '../../Players/SearchPlayerHorseGreyhoundHistory/SearchPlayerHorseGreyhoundHistory';
import { BetMonitorSocketContext } from '../../Sports/BetMonitor/MonitorTickets/BetmonitorSocket';
import { TickerFilters } from './TickerFilters';


const SelectionStatus = {
    0: <Icon icon='square' type='fa-regular' />,
    2: 'Refund',
    3: <Icon icon='times-square' type='fa-regular' />,
    4: <Icon icon='check-square' type='fa-regular' />,
    5: 'Cashout',
    6: 'Cancel'
};


export const getMatchTime = (time) => {
    const minutes = Math.floor(time / 60);
    return minutes;
};



const tickerModel = {
    fields: [
        {
            name: 'Ticket',
            title: '',
            render: () => {
                return <Button className='success' onClick={(e) => {
                    e.target.parentNode.click(e);
                }}>
                    <i className='fa-regular fa-gear'></i>
                </Button>;
            }
        },
        {
            name: 'TicketDate',
            title: 'Date',
            render: (val, row) => {
                var date = new Date(row.TicketDate);
                return <flex className='vertical'>
                    <span>{date.toLocaleDateString('en-Gb', { hours12: false })}</span>
                    <span>{date.toLocaleTimeString('en-Gb', { hours12: false })}</span>
                </flex>;
            }
        },
        {
            name: 'TicketId',
            title: 'Ticket',
            render: (val, row, field, context, tableContext) => {
                row.Product = 0;
                return <HorseTicketId id={val} onChange={(ticket) => {
                    var data = tableContext.getData();
                    data = data.filter(x => x.TicketId != ticket.TicketId);
                    tableContext.setData(data);
                }} />;
            }
        },
        {
            name: 'Username',
            title: 'Player',
            render: (val, row) => {
                return <flex className='vertical clickable' onClick={() => {
                    window.open(`/players/search?&id=${row.PlayerId}`, 'new');
                }}>
                    <flex className='gap-5'><span>{row.Username}</span></flex>
                    <flex className='gap-5'><span>{row.PlayerGroupName}</span><span className={`risk-level-${row.PlayerRiskLevel}`}>{row.PlayerRiskLevel}</span></flex>
                </flex>;
            }
        },
        {
            name: 'Type',
            title: 'Type',
            render: (val, row) => {
                //if (row.ParentTicket.System) return row.ParentTicket.System;
                var single = row.Flags & BettingFlags.Single;
                var forecast = row.Flags & BettingFlags.Forecast;
                var tricast = row.Flags & BettingFlags.Tricast;
                var eachWay = row.Flags & BettingFlags.EachWay;


                if ((row.System == '' || !row.System) && row.Flags == 1) {
                    var result = RacingTicketTypes[row.TicketType];
                    return <div className='gap-5'>
                        <span>{lang(result)}</span>
                    </div>;
                } else
                    if (single) {
                        return <div className='gap-5'>
                            <span>{lang('Single')}</span>
                            {eachWay > 0 && <span>{lang('Eachway')}</span>}
                        </div>;
                    } else
                        if (forecast) {
                            return <div className='gap-5'>
                                <span>{lang('Forecast')}</span>
                            </div>;
                        } else
                            if (tricast) {
                                return <div className='gap-5'>
                                    <span>{lang('Tricast')}</span>
                                </div>;
                            } else {
                                return <div className='gap-5'>
                                    <span>{lang(RacingTicketTypes[row.HorseTicketType])}</span>
                                </div>;
                            }
            }
        },
        {
            name: 'Selection',
            title: 'Selection',
            renderHeader: () => {
                return <flex className='vertical gap-5'>
                    <span>Selections</span>
                    <flex className='gap-5 border-top'>
                        <span className='selection-level hidden'>Risk Levels</span>
                        <span className='selection-live-pre'>Live/Pre</span>
                        <span className='selection-event'>Event</span>
                        <span className='selection-event-date'>Date</span>
                        <span className=''>Market, Runner</span>
                        <span className='align-right selection-price'>Place</span>
                        <span className='align-right selection-price'>Price</span>
                    </flex>
                </flex>;
            },
            render: (val, row, field, context) => {
                return <flex className='vertical gap-10'>
                    {
                        Object.keys(row.Selections).map(x => {
                            var status = x.status;
                            var converted = x.riskLevel ? getLookupKey(x.riskLevel) : [];
                            const result = converted.map((x, index) => {
                                return <flex key={index} className={`risk-level-${x}`}>{x}</flex>;
                            });

                            var level = <flex className='gap-1 selection-level hidden'>
                                {result}
                            </flex>;


                            var regionLevel = converted[1];
                            var venueLevel = x.venueLevel;

                            var event = x.EventKey ? row.Events.find(y => y.EventKey == x.EventKey) : row.Events[0];
                            var eventName = `${event.Venue} ${event?.Distance} / ${event.DistanceInMeters} race`;
                            var date = new Date(event.Date);

                            var price = x.PriceDecimal ? x.PriceDecimal.toFixed(2) : '';

                            var market = 'Winner';
                            var place = x.Place;

                            if (row.EachWay) market = 'Each Way';
                            if (!x.PriceDecimal) price = 'SP';

                            if (row.Forecast) {
                                market = 'Forecast';
                            }
                            if (row.ForecastCFC) {
                                market = 'Forecast CFC';
                            }
                            if (row.ForecastRFC) {
                                market = 'Forecast RFC';
                            }

                            if (row.Tricast) {
                                market = 'Tricast';
                            }
                            if (row.TricastCTC) {
                                market = 'Tricast CTC';
                            }

                            return <flex className='vertical' key={x.Id}>
                                <flex className='gap-5'>
                                    <flex className='vertical'>
                                        <flex className='gap-5'>
                                            {level}
                                            <span className='selection-live-pre bold'>{x.Live ? 'live' : 'pre'}</span>
                                            <flex className='selection-event gap-5'>
                                                {SelectionStatus[status]}
                                                <span>{eventName}</span>
                                            </flex>
                                        </flex>
                                        <flex className='gap-5'>
                                            <span className={`small meta-level-${regionLevel}`}>{event.Country}</span>
                                            /
                                            <span className={`small meta-level-${venueLevel}`}>{event.Venue}</span>
                                        </flex>
                                    </flex>
                                    <flex className='selection-event-date vertical'>
                                        <span>{date.toLocaleDateString('en-Gb', { hours12: false })}</span>
                                        <span>{date.toLocaleTimeString('en-Gb', { hours12: false })}</span>
                                    </flex>
                                    <flex className='vertical'>
                                        <span className='bold'>{market}</span>
                                        <span className=''>{x.Name}</span>
                                    </flex>
                                    <span className='align-right selection-price'>{place}</span>
                                    <span className='align-right selection-price'>{price}</span>
                                </flex>
                            </flex>;
                        })
                    }
                </flex>;
            }
        },
        {
            name: 'Stake',
            title: 'Total Stake',
            render: (val, row) => {
                return currency(row.ConvertedStake, 2, '', false);
            }
        },
        {
            name: 'PossibleWin',
            title: 'Possible Win',
            render: (val, row) => {
                return currency(row.PossibleWin, 2, '', false);
            }
        },
        {
            name: 'IPAddress',
            title: 'IP'
        },
        {
            name: 'PostponePayment',
            title: 'Investigated',
            type: InputTypes.Bool,
            render: (val, row) => {
                if (row.postponePayment) {
                    return <flex className='vertical'>
                        <span className='bold'>{lang('Yes')}</span>
                        <span>{row.InvestigationLevels}</span>
                    </flex>;
                }
            }
        }
    ]
};

export const HorseInvestigationFlags = () => {
    const user = useSelector((state) => state.user).loginInformation;
    const operatorId = user.UserPrivileges.PointOfView;

    const [flags, setFlags] = useState({});

    useEffect(() => {
        API.get(`${HorseRiskLevelEndpoints.GetInvestigationFlags}/${operatorId}`).then((result) => {
            setFlags(result.result);
        });
    }, []);


    const renderTickets = () => {
        if (!flags) return;
        if (Object.keys(flags).length == 0) return;
        var values = [];
        Object.keys(flags).map(x => {
            if (x.indexOf('ticket_') == 0) {
                flags[x].description = x.replace('ticket_', '');
                values.push(flags[x]);
            }
        });

        return <Table model={{
            fields: [
                {
                    name: 'date',
                    title: 'Date',
                    type: InputTypes.DateTime
                },
                {
                    name: 'adminId',
                    title: 'Admin',
                    render: (val, row) => {
                        return <flex className='gap-10'><span>{row.admin}</span></flex>
                    }
                },
                {
                    name: 'description',
                    title: 'Description'
                }
            ]
        }} data={values} />;
    };



    const renderGroups = () => {
        if (!flags) return;
        if (Object.keys(flags).length == 0) return;
        var values = [];
        Object.keys(flags).map(x => {
            if (x.indexOf('group_') == 0) {
                if (!flags[x].description) {
                    flags[x].description = x.replace('group_', '');
                }
                values.push(flags[x]);
            }
        });

        return <Table model={{
            fields: [
                {
                    name: 'date',
                    title: 'Date',
                    type: InputTypes.DateTime
                },
                {
                    name: 'adminId',
                    title: 'Admin',
                    render: (val, row) => {
                        return <flex className='gap-10'><span>{row.admin}</span></flex>
                    }
                },
                {
                    name: 'description',
                    title: 'Description'
                }
            ]
        }} data={values} />;
    };

    const renderPlayers = () => {
        if (!flags) return;
        if (Object.keys(flags).length == 0) return;
        var values = [];
        Object.keys(flags).map(x => {
            if (x.indexOf('player_') == 0) {
                if (!flags[x].description) {
                    flags[x].description = x.replace('player_', '');
                }

                values.push(flags[x]);
            }
        });

        return <Table model={{
            fields: [
                {
                    name: 'date',
                    title: 'Date',
                    type: InputTypes.DateTime
                },
                {
                    name: 'adminId',
                    title: 'Admin',
                    render: (val, row) => {
                        return <flex className='gap-10'><span>{row.admin}</span></flex>
                    }
                },
                {
                    name: 'description',
                    title: 'Description'
                }
            ]
        }} data={values} />;
    };

    const renderIP = () => {
        if (!flags) return;
        if (Object.keys(flags).length == 0) return;
        var values = [];
        Object.keys(flags).map(x => {
            if (x.indexOf('ip_') == 0) {
                if (!flags[x].description) {
                    flags[x].description = x.replace('ip_', '');
                }
                values.push(flags[x]);
            }
        });

        return <Table model={{
            fields: [
                {
                    name: 'date',
                    title: 'Date',
                    type: InputTypes.DateTime
                },
                {
                    name: 'adminId',
                    title: 'Admin',
                    render: (val, row) => {
                        return <flex className='gap-10'><span>{row.admin}</span></flex>
                    }
                },
                {
                    name: 'description',
                    title: 'Description'
                }
            ]
        }} data={values} />;
    };

    const renderFlags = () => {
        return <flex className='vertical gap-10'>
            <FluxCard title='Tickets' className={'no-padding'}>
                {renderTickets()}
            </FluxCard>
            <FluxCard title='Groups' className={'no-padding'}>
                {renderGroups()}
            </FluxCard>
            <FluxCard title='Players' className={'no-padding'}>
                {renderPlayers()}
            </FluxCard>
            <FluxCard title='IP' className={'no-padding'}>
                {renderIP()}
            </FluxCard>
        </flex>;
    };

    return <FluxCard title='Investigated Flags'>
        {renderFlags()}
    </FluxCard>;
};

export const BetTicker = () => {
    const context = useContext(BetMonitorSocketContext);


    const [connected, setConnected] = useState(context.connected);

    const user = useSelector((state) => state.user).loginInformation;
    const operatorId = user.UserPrivileges.PointOfView;

    const [tickets, setTickets] = useState([]);


    const [newTickets, setNewTickets] = useState(null);
    const [newCashoutTicket, setCashoutTicket] = useState(null);
    const [resultingTicket, setResultedTicket] = useState(null);
    const [manualSettledTicket, setManualSettlementTicket] = useState(null);


    const [selectedTab, selectTab] = useState('all');
    const [regionStats, setRegionStats] = useState({});
    const [venueStats, setVenueStats] = useState({});
    const [eventStats, setEventStats] = useState({});
    const [runnerStats, setRunnerStats] = useState({});

    const [filters, setFilters] = useState(null);

    const [stats, setStats] = useState(null);


    const [ready, setReady] = useState(false);
    const [showFiltersMenu, toggleFilters] = useState(false);

    const [soundError, setSoundError] = useState(null);

    const [selectedTicket, selectTicket] = useState(null);


    useEffect(() => {
        context.eventHandlers({
            onConnected: () => setConnected(true),
            onNewTicket: (t) => {
                setNewTickets(t);
            },
            onCashoutTicket: (request) => setCashoutTicket(request),
            onCloseTicket: (request) => setResultedTicket(request),
            onRemoveTicket: (ticket) => {
                console.log(ticket);
            },
            onManualSettlement: (ticketId) => {
                setManualSettlementTicket(ticketId);
            },
            onScore: (/*scoreData*/) => {
                //
            }
        });
    }, []);

    useEffect(() => {
        if (!connected) return;

        NotifyWithSound('https://cdn-d.tentangle.com/bo/sounds/notification1.mp3', 100, false).catch(() => {
            setSoundError(<flex className='vertical gap-5'>
                <span>{lang('Playing sounds after window refresh requires user gesture to click!')}</span>
                <flex>
                    <Button title='Enable sounds' className='success' onClick={() => {
                        setSoundError(false);
                        NotifyWithSound('https://cdn-d.tentangle.com/bo/sounds/notification1.mp3', 100, false).catch(() => {

                        });

                    }} />
                </flex>
            </flex>);
        });
        loadTickets();

        var interval = setInterval(() => {
            API.post(`${HorseRiskLevelEndpoints.Stats}/${operatorId}`, null, null, null, 'monitor', false, false, true).then((result) => {
                setStats(result.result);
            });
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, [connected]);

    useEffect(() => {
        if (!newTickets) return;
        var copy = newTickets.concat(tickets);
        setTickets(copy.sort((a, b) => new Date(b.entryDate) - new Date(a.entryDate)));
        NotifyWithSound('https://cdn-d.tentangle.com/bo/sounds/notification1.mp3', 100, false);

    }, [newTickets]);

    useEffect(() => {
        if (!newCashoutTicket) return;
        var copy = tickets.filter(x => x.ticketId != newCashoutTicket.ticketId);
        setTickets(copy);
    }, [newCashoutTicket]);

    useEffect(() => {
        if (!resultingTicket) return;
        var copy = tickets.filter(x => x.ticketId != resultingTicket.ticketId);
        setTickets(copy);
    }, [resultingTicket]);

    useEffect(() => {
        if (!manualSettledTicket) return;
        var copy = tickets.filter(x => x.ticketId != manualSettledTicket);
        setTickets(copy);
    }, [manualSettledTicket]);



    useEffect(() => {
        if (!filters) return;
        loadTickets();
    }, [filters]);

    const loadTickets = () => {
        var copy = filters ?? {};

        Object.keys(filters ?? {}).map(x => {
            if (Array.isArray(filters[x])) {
                if (filters[x].length == 0) {
                    delete copy[x];
                }
            }
        });

        API.post(`${HorseRiskLevelEndpoints.Ticker}/${operatorId}`, copy).then((result) => {

            setRegionStats(result.result?.regions);
            setVenueStats(result.result?.venues);
            setEventStats(result.result?.events);
            setRunnerStats(result.result?.runners);

            setTickets(result.result?.tickets.sort((a, b) => new Date(b.entryDate) - new Date(a.entryDate)));
            setReady(true);
        });
    };

    const investigate = (type) => {
        var description = `${selectedTicket.ticketId} will be investigated and settled manually!`;
        var key = selectedTicket.ticketId;
        var add = true;

        switch (selectedTicket.postponePayment) {
            case true:
                switch (type) {
                    case 'ticket':
                        description = `${selectedTicket.ticketId} will be removed from investigation!`;
                        add = false;
                        break;
                    case 'player':
                        description = `All tickets from player ${selectedTicket.Username} will be removed from investigation!`;
                        key = selectedTicket.PlayerId;
                        add = false;
                        break;
                    case 'group':
                        description = `All tickets from group ${selectedTicket.PlayerGroupName} will be removed from investigation!`;
                        key = selectedTicket.PlayerGroupName;
                        add = false;

                        break;
                    case 'ip':
                        description = `All tickets from ip ${selectedTicket.IPAddress} will be removed from investigation!`;
                        key = selectedTicket.IpAddress;
                        add = false;

                        break;
                }
                break;
            case false:
                switch (type) {
                    case 'player':
                        description = `All tickets from player ${selectedTicket.Username} will be investigated and settled manually!`;
                        key = selectedTicket.PlayerId;
                        break;
                    case 'group':
                        description = `All tickets from group ${selectedTicket.PlayerGroupName} will be investigated and settled manually!`;
                        key = selectedTicket.PlayerGroupName;
                        break;
                    case 'ip':
                        description = `All tickets from ip ${selectedTicket.IpAddress} will be investigated and settled manually!`;
                        key = selectedTicket.IpAddress;
                        break;
                }
                break;
        }


        Modal.open(<ConfirmationModal title='Invesitage ticket' onConfirm={() => {
            API.post(`${add ? HorseRiskLevelEndpoints.AddToInvestigatation : HorseRiskLevelEndpoints.RemoveFromInvestigation}/${operatorId}`, {
                type: type,
                key: key,
                operatorId: operatorId
            }).then(() => {
                selectTicket(null);
                loadTickets();
            });
        }}>
            <FluxNotice type='warning' title='Invesitage ticket' description={description} />
        </ConfirmationModal>);
    }

    const renderTicker = () => {
        return <flex className='vertical'>
            {soundError && <FluxNotice type='warning' title='Sound error' description={soundError} />}
            {ready && <TickerFilters visible={showFiltersMenu}
                regions={regionStats}
                venues={venueStats}
                events={eventStats}
                runners={runnerStats}
                onFilter={(data) => {
                    setFilters(data);
                }}
            />}
            <flex className='padding gap-5 border'>
                <Button title={showFiltersMenu ? 'Close Filters' : 'Open Filters'} className={'success'} onClick={() => toggleFilters(!showFiltersMenu)} />
                <flex className={`align-right gap-5 ${selectedTicket ? '' : 'disabled'}`}>
                    <span>{lang('Investigate')}</span>
                    {selectedTicket && (!selectedTicket.postponePayment) && <Button title='This ticket' className={'warning'} onClick={() => investigate('ticket')} />}
                    {selectedTicket && selectedTicket.postponePayment && <Button title='Remove ticket from investigation' className={'success'} onClick={() => investigate('ticket')} />}
                    {selectedTicket && <Button title={lang('%1\'s tickets', [selectedTicket.Username])} className={'warning'} onClick={() => investigate('player')} />}
                    {selectedTicket && <Button title={lang('%1\'s tickets', [selectedTicket.PlayerGroupName])} className={'warning'} onClick={() => investigate('group')} />}
                    {selectedTicket && <Button title={lang('IP %1\'s tickets', [selectedTicket.IpAddress])} className={'warning'} onClick={() => investigate('ip')} />}
                </flex>
            </flex>
            <Table model={tickerModel} data={tickets} context={{ scores: {} }} className={'border'} onRowClick={(row) => {
                selectTicket(row);
            }} />
        </flex>;
    };


    return <FluxCard title='Ticker' className={`no-padding content${connected ? '' : ' disabled'} overflow-hidden`}
        buttons={<flex className='gap-10 align-right'>
            <Button className={selectedTab == 'all' ? 'active' : ''} title='All Bets' onClick={() => selectTab('all')} />
            <Button className={selectedTab == 'flags' ? 'active' : ''} title='Investigation Flags' onClick={() => selectTab('flags')} />
            <Button className={selectedTab == 'investigated' ? 'active' : ''} title='Investigated' onClick={() => selectTab('investigated')} />
            <Button className={selectedTab == 'resulted' ? 'active' : ''} title='Resulted' onClick={() => selectTab('resulted')} />
        </flex>}
        footer={<flex className='gap-20 fit'>
            <flex className='vertical'>
                <flex className='gap-5'>
                    <span className='bold'>{lang('Open tickets')}</span>
                    <span>{stats?.openTickets.total}</span>
                </flex>
                <flex className='gap-10'>
                    <flex className='gap-5'>
                        <span className='bold'>{lang('Stake')}</span>
                        <span>{currency(stats?.openTickets.totalStake, 2, '', false)}</span>
                    </flex>
                    <flex className='gap-5'>
                        <span className='bold'>{lang('Possible Win')}</span>
                        <span>{currency(stats?.openTickets.totalPossibleWin, 2, '', false)}</span>
                    </flex>
                </flex>
            </flex>
            <flex className='vertical align-right'>
                <flex className='gap-10'>
                    <flex className='gap-5'>
                        <span className='bold'>{lang('Stake')}</span>
                        <span>{currency(stats?.openTickets.totalStake, 2, '', false)}</span>
                    </flex>
                </flex>
            </flex>
        </flex>}>
        {selectedTab == 'all' && renderTicker()}
        {selectedTab == 'flags' && <HorseInvestigationFlags />}
        {selectedTab == 'investigated' && <SearchPlayerHorseGreyhoundHistory pending />}
        {selectedTab == 'resulted' && <SearchPlayerHorseGreyhoundHistory resolved />}
    </FluxCard>;
};