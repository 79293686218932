export const RetailTypes = {
    Virtual: 0,
    Physical: 1
};

export const RetailUserTypes = {
    9: 'VR. Owner',
    13: 'VR. Manager',
    14: 'VR. Agent',
    15: 'VR. Cashier',
    16: 'VR. Accountant',
    18: 'VR. Shop Owner',
    19: 'VR. Shop Manager',
    10: 'P. Owner',
    11: 'P. Manager',
    12: 'P. Cashier',
};


export const VirtualRetailUserTypes = {
    9: 'Owner',
    13: 'Manager',
    14: 'Agent',
    15: 'Cashier',
    16: 'Accountant',
    18: 'Shop Owner',
    19: 'Shop Manager',
};


export const PhysicalRetailUserTypes = {
    10: 'Owner',
    11: 'Manager',
    12: 'Cashier'
};


export const RetailTransactionTypes = {
    0: 'Deposit',
    1: 'Withdraw',
    2: 'Deposit',
    3: 'Withdraw',
    6: 'Deposit',
    7: 'Withdraw',
    8: 'Deposit',
    9: 'Withdraw',
};

export const RetailPlayerTransactionTypes = {
    2: 'Deposit',
    3: 'Withdraw'
};



export const RetailTransactionSelection = {
    null: 'All',
    0: 'HQ',
    1: 'Retails',
    2: 'Players'
};
