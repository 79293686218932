import './_form.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FluxInput } from './Components/FluxInput';
import { Button } from '../button/Button';
import { API } from '../../../v2/Lib/Api/Api';
import { lang } from '../../lib/Localization/language';
import { Modal } from '../application/FluxApp';
import { ConfirmationModal } from '../modal/ConfirmationModal/ConfirmationModal';
import { ErrorModal } from '../modal/ErrorModal/ErrorModal';
import { isNullOrEmpty } from '../../lib/Helpers/StringHelper';
import { Icon } from '../../../components/Controls/Icon/Icon';
import { InputTypes } from './InputTypes';
import { DateRange } from './Components/DateTimeInput';
import { useSelector } from 'react-redux';
import { fieldDataParser } from './Components/FieldHelpers';

export const FormContext = React.createContext({})

export const FluxForm = (props) => {
    const [sections, setSections] = useState(null);
    const [data, setData] = useState(null);
    const [errorFields, setErrorFields] = useState({});
    const [canSave, setSaveEnabled] = useState(false);
    const [fields, setFields] = useState(null);
    const [focusedField, setFocusedField] = useState(null);

    const user = useSelector((state) => state.user?.loginInformation);

    const [ready, setReady] = useState(false);

    const formRef = React.createRef();

    useEffect(() => {
        if (!props.model) return;
        if (!props.model?.fields && !props.model?.sections) return;

        if (props.model.fields) {
            prepareSectionsAndFields();
        } else {
            var sectionData = [];
            var fieldsCopy = [];
            var sectionKeys = {
            }

            Object.keys(props.model.sections).map(x => {
                if (!sectionKeys[x]) {
                    sectionKeys[x] = {
                        fields: []
                    }
                }
                fieldsCopy = Array.concat(fieldsCopy, props.model.sections[x].fields);
            });
            Object.keys(sectionKeys).map(x => {
                sectionData.push({ name: x, fields: props.model.sections[x].fields, icon: props.model.sections[x].icon, type: props.model.sections[x].type });
            });

            setFields(fieldsCopy);
            setSections(sectionData);
        }
    }, []);

    useEffect(() => {
        if (!props.onSaveEnabled) return;
        props.onSaveEnabled(canSave);
    }, [canSave]);

    const prepareSectionsAndFields = () => {
        var sectionKeys = {
            none: {
                fields: [],
                weight: 1000000
            }
        }

        var sectionData = [];
        var sectionIndex = 0;
        props.model.fields.map(x => {
            if (!x.section) {
                sectionKeys.none.fields.push(x);
            } else {
                if (!sectionKeys[x.section]) {
                    sectionIndex++;
                    sectionKeys[x.section] = {
                        fields: [],
                        type: x.section.type,
                        weight: sectionIndex
                    }
                }
                sectionKeys[x.section].fields.push(x);
            }
        });

        Object.keys(sectionKeys).map(x => {
            sectionData.push({ name: x, fields: sectionKeys[x].fields, weight: sectionKeys[x].weight, type: sectionKeys[x].type });
        });

        setFields(props.model.fields);
        setSections(sectionData.sort((a, b) => a.weight - b.weight));
    }

    const update = (field, value) => {
        setFocusedField(field.name);
        var copy = { ...data };
        if (field.type == InputTypes.Number) {
            value = parseFloat(value);
        }

        if (copy[field.name] == value) {
            return;
        }
        copy[field.name] = value;
        setData(copy);
    };

    const updateMultipleFields = (fields) => {
        var copy = { ...data };
        fields.map(field => {
            copy[field.name] = field.value;
        });
        setData(copy);
    };

    const completePreparingFields = () => {
        // prepare data
        var copy = props.data ?? {};
        copy = { ...props.data };
        fields.map(field => {
            if (copy[field.name] === undefined) {
                if (!field.between && field.value) {
                    copy[field.name] = field.value;
                }

                if (field.between) {
                    if (field.value?.start) {
                        var minValue = field.value.start;
                        if (typeof field.value.start === 'object' && field.type == InputTypes.DateTime) {
                            //minValue = minValue.toISOString().replace('Z', '');
                        }
                        copy[field.name + '_min'] = minValue;
                    }
                    if (field.value?.end) {
                        var maxValue = field.value.end;
                        if (typeof field.value.start === 'object' && field.type == InputTypes.DateTime) {
                            //maxValue = maxValue.toISOString().replace('Z', '');
                        }
                        copy[field.name + '_max'] = maxValue;
                    }
                }

                if (field.type == InputTypes.Select && field.values && (field.value === undefined || field.value === null) && !field.nullable) {
                    if (typeof field.values === 'object') {
                        if (!isNaN(Object.keys(field.values)[0])) {
                            copy[field.name] = Object.keys(field.values)[0];
                        } else {
                            copy[field.name] = Object.values(field.values)[0];
                        }
                    } else {
                        copy[field.name] = field.values[0].value;
                    }
                }
            }
        });
        setData(copy);
        if (props.onReady) {
            props.onReady(fields);
        }
        setReady(true);
    };

    useEffect(() => {
        if (!fields) return;

        var dataSources = {};
        fields.map(x => {
            if (x.data && !x.data.ready) {
                dataSources[x.name] = x;
            }
        });

        if (Object.keys(dataSources).length) {
            var queries = Object.values(dataSources);
            for (let i = 0; i < queries.length; i++) {
                var fieldData = queries[i];
                fieldDataParser(fieldData, (result) => {
                    fieldData.data.ready = true;
                    fieldData.data.values = result;
                    setFields([...fields]);
                });
                return;
            }
        }

        completePreparingFields();
    }, [fields]);

    useEffect(() => {
        if (data == null) return;
        var okToSave = true;
        var errorFields = {};
        fields.map(field => {
            if (field && field.required) {
                if (isNullOrEmpty(data[field.name])) {
                    var input = formRef.current.querySelector(`input[name="${field.name}"]`);
                    if (isNullOrEmpty(input?.value)) {
                        errorFields[field.name] = { error: 'Empty' };
                        okToSave = false;
                    }
                }
            }
            if (field.readonly) {
                data[field.name] = field.value;
            }
        });


        setErrorFields(errorFields);
        setSaveEnabled(okToSave);

        if (!props.onChange) return;
        props.onChange(data);
    }, [data]);

    const onMultiFilterChange = (field, mode) => {
        if (field.multiFilterEnabled && props.onMultiFilterChange) {
            var copy = [...fields]
            var fieldData = copy.find(x => x.name == field.name);
            fieldData.multiFilter = mode;
            props.onMultiFilterChange(copy);
        }
    }

    const submit = () => {
        if (!props.endpoint) {
            props.onSubmit(data);
            return;
        }

        var copy = { ...data };

        fields.map(field => {
            if (field && field.required) {
                if (isNullOrEmpty(copy[field.name])) {
                    var input = formRef.current.querySelector(`input[name="${field.name}"]`);
                    copy[field.name] = input?.value;
                }
            }
            if (field.readonly) {
                copy[field.name] = field.value;
            }
        });


        if (props.onBeforeSubmit) {
            copy = props.onBeforeSubmit(copy);
        }

        const post = () => {
            API.post(props.endpoint, copy, props.saveMessage ?? 'please wait').then((result) => {
                if (props.onSubmitComplete) props.onSubmitComplete(result, copy);
            }).catch((error) => {
                Modal.open(<ErrorModal title={'Error'}>{lang(error.error?.message ?? 'API Error.')}</ErrorModal>);
            });
        };
        if (props.confirm) {
            Modal.open(<ConfirmationModal title={props.confirmTitle ?? 'Please confirm'} onConfirm={() => {
                post();
            }}>{lang(props.confirmMessage ?? 'Please confirm the operation .')}</ConfirmationModal>);
        } else {
            post();
        }
    };

    const cancel = () => {
        if (props.onCancel) props.onCancel();
    };

    if (!ready) return <></>;
    if (!fields) return <></>;
    if (!sections) return <></>;

    var classList = [];
    if (props.className) classList.push(props.className);
    if (props.columns) classList.push(`form-columns-${props.columns}`);
    if (sections?.length % props.columns === 1) classList.push('full-width-last');


    const renderField = (field) => {
        var copy = { ...field };
        copy.readonly = props.readonly ? props.readonly : copy.readonly;
        return <forminput>
            <flex className='input-title fit-children'>
                {copy.information && <a title={copy.information}><Icon icon='square-question' size='xs' type='fa-solid' /></a>}
                {copy.required && <Icon icon='asterisk' size='xs' type='fa-sharp fa-light' />}
                {copy.hideTitle == undefined && field.renderTitle ? field.renderTitle(data) : <span>{lang(copy.title ?? copy.name)}</span>}
            </flex>
            <FluxInput
                field={copy}
                onChange={(field, value) => update(field, value)}
                onMultiFilterChange={(field, mode) => onMultiFilterChange(field, mode)}
                data={data}
                focused={focusedField == copy.name}
            />
            {copy.description && <flex className='form-description'>{copy.description(data)}</flex>}
        </forminput>;
    };

    const renderBetweenField = (field) => {
        var minField = { ...field };
        var maxField = { ...field };

        minField.min = true;
        maxField.max = true;

        var titleMin = lang('%1 Start', [lang(field.title ?? field.name)]);
        var titleMax = lang('%1 End', [lang(field.title ?? field.name)]);


        if (field.type == InputTypes.Number) {
            titleMin = lang('%1 Min', [field.title ?? field.name]);
            titleMax = lang('%1 Max', [field.title ?? field.name]);
        }
        minField.name += '_min';
        maxField.name += '_max';

        minField.title = titleMin;
        maxField.title = titleMax;

        minField.value = field.value?.start;
        maxField.value = field.value?.end;

        return <React.Fragment>
            <flex className='gap-5 input-between'>
                <flex className='gap-5'>
                    {renderField(minField)}
                    {renderField(maxField)}
                </flex>
                <flex className='between-date-selector'>
                    <DateRange startField={minField} endField={maxField} selected={field.range?.selected} onChange={updateMultipleFields.bind(this)} tomorrow={field.tomorrow} />
                </flex>
            </flex>
        </React.Fragment>
    };

    return <fluxform className={classList.join(' ')} ref={formRef}>
        <FormContext.Provider value={{
            submit: () => submit()
        }}>
            <rows>
                {
                    sections.map(section => {
                        var elements = section.fields.map(field => {
                            if (field.hidden) return <input type='hidden' name={field.name} defaultValue={field.value} key={field} />;
                            if (field.access) {
                                var hasAccess = user.UserPrivileges?.SpecialActions?.indexOf('UpdatePlayerDet') >= 0 || user.UserGroupId <= 2;
                                if (!hasAccess) {
                                    return <row key={field}>
                                        <flex className='gap-5'>
                                            <span className='input-title'>{lang(field.title ?? field.name)}</span>
                                            <span>***</span>
                                        </flex>
                                    </row>;
                                }
                            }
                            return <row key={field}>
                                <flex className={`gap-5${field.required ? ' required' : ''}${errorFields[field.name] ? ' field-error' : ''}`}>
                                    {!field.between && renderField(field)}
                                    {field.between && renderBetweenField(field)}
                                </flex>
                            </row>;
                        });
                        if (section.name == 'none') return elements;
                        return <section>
                            <header>{section.icon}<span>{lang(section.name)}</span></header>
                            <fields className={section.type}>{elements}</fields>
                        </section>
                    })
                }
            </rows>
            {props.children}
            {props.buttons === undefined &&
                <buttons>
                    <Button title={props.submitButtonTitle ?? 'Save'} className={canSave ? 'success' : 'disabled'} onClick={() => submit()} />
                    <Button title='Cancel' className='warning' onClick={() => cancel()} />
                </buttons>
            }
            {props.buttons}
            {props.clear && <buttons><Button title='Clear' onClick={() => {
                setData({});
            }} /></buttons>}
        </FormContext.Provider>
    </fluxform >;
};

FluxForm.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    columns: PropTypes.number,
    buttons: PropTypes.node,
    submitButtonTitle: PropTypes.string,
    clear: PropTypes.bool,
    model: PropTypes.object,
    data: PropTypes.object,
    readonly: PropTypes.bool,
    endpoint: PropTypes.string,

    confirm: PropTypes.bool,
    confirmTitle: PropTypes.any,
    confirmMessage: PropTypes.any,

    onSaveEnabled: PropTypes.func,
    onChange: PropTypes.func,
    onBeforeSubmit: PropTypes.func,
    onSubmit: PropTypes.func,
    onSubmitComplete: PropTypes.func,
    onCancel: PropTypes.func,
    onReady: PropTypes.func,
    onMultiFilterChange: PropTypes.func,
};
