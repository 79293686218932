
import React, { useEffect, useState } from 'react';
import { FluxCard } from '../../../../../../components/card/FluxCard';
import { FluxForm } from '../../../../../../components/form/FluxForm';
import { InputTypes } from '../../../../../../components/form/InputTypes';


export const CampaignSettings = (props) => {
    const { promotion, onChange } = { ...props };
    const [model, setModel] = useState(null);

    useEffect(() => {
        if (promotion.Type == 7 || promotion.Type == 8 || promotion.Type == 10 || promotion.Type == 11) {
            setModel({
                sections: {
                    'Bonus Information': {
                        fields: [
                            {
                                name: 'Name',
                                required: true
                            },
                            {
                                name: 'Description',
                                type: InputTypes.Textarea,
                                required: true
                            },
                            {
                                name: 'Enabled',
                                type: InputTypes.Bool
                            }
                        ]
                    }
                }
            });

            return;
        }

        var model = {
            sections: {
                'Bonus Information': {
                    fields: [
                        {
                            name: 'Name',
                            required: true
                        },
                        {
                            name: 'Description',
                            type: InputTypes.Textarea,
                            required: true
                        },
                        {
                            name: 'Enabled',
                            type: InputTypes.Bool
                        }
                    ]
                },
                'Date Settings': {
                    fields: [
                        {
                            name: 'StartDate',
                            title: 'Start Date',
                            type: InputTypes.DateTime
                        },
                        {
                            name: 'EndDate',
                            title: 'End Date',
                            type: InputTypes.DateTime
                        }
                    ]
                }
            }
        };

        // free bet bonus wallet settings is always true
        if (promotion.Type == 13) {
            var bonusWallet = model.sections['Wallet'].fields.find(x => x.name == 'BonusWallet');
            bonusWallet.readonly = true;
            bonusWallet.value = true;
        }
        setModel(model);

    }, []);

    if (!model) return <></>;


    return <FluxCard>
        <FluxForm data={promotion} model={model} columns={2} buttons={null} onChange={onChange} />
    </FluxCard>;
};