// INITIALIZE STATE

import { LocalStorage } from '../../flux/lib/Helpers/LocalStorage/LocalStorageHelper';

const initialState = {
    menuAction: null,
    settings: {}
};


// REDUCER
export const FluxReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ROUTER_READY':
            var r = { ...state };
            r.settings = LocalStorage.get().settings;
            return r;

        case 'SELECTED_MENU_ACTION':
            var l = { ...state };
            l.menuAction = action.payload;
            return l;
        case 'FAVOURITE_ACTION_ADDED':
            var f = { ...state };
            f.settings = LocalStorage.get().settings;
            return f;
    }

    return state;
};