import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { lang } from '../../../flux/lib/Localization/language';
import { Icon } from '../Icon/Icon';

export class Button extends Component {
    constructor(props) {
        super(props);
        this.state = Object.assign({}, props);
    }

    render() {
        return <button type={this.props.type} className={this.props.className} onClick={() => this.props.onClick()}>{this.props.icon && <Icon icon={this.props.icon} />}{this.props.children}{lang(this.props.title)}</button>;
    }
}

Button.defaultProps = {
    title: '',
    className: '',
    children: null,
    type: 'button',
    icon: null,

    onClick: () => { }
};

Button.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
    icon: PropTypes.node,
    children: PropTypes.node,


    onClick: PropTypes.func
};