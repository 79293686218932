import './_carousel-list.scss';

import React, { useEffect, useState } from 'react';

import ScrollContainer from 'react-indiana-drag-scroll';
import { Icon } from '../../icon/Icon';

export const FluxCarouselList = (props) => {
    const { items, className, selected, children, onRenderItem, onItemClick } = { ...props };
    const [selectedItem, select] = useState(selected);
    const navRef = React.createRef();
    const [sliderArrowVisible, setSliderArrowVisible] = useState({
        left: false,
        right: true,
    });

    useEffect(() => {
        select(selected);
    }, [selected]);

    useEffect(() => {
        if (!selectedItem) return;
        onItemClick(selectedItem);
    }, [selectedItem]);


    const onSliderScrollElement = () => {
        const scrollLeft = navRef?.current?.getElement()?.scrollLeft;
        const scrollRight = navRef?.current?.getElement()?.scrollWidth - navRef?.current?.getElement()?.getBoundingClientRect().width - scrollLeft;
        setSliderArrowVisible({
            left: scrollLeft > 0,
            right: scrollRight > 0,
        });
    };

    return <flex className={`flux-carousel fx-inset ${className}`} key={selected}>
        {sliderArrowVisible?.left && <Icon icon='angle-left' className='clickable' size='1x' onClick={() => navRef.current.getElement().scrollLeft -= 120} />}
        <ScrollContainer className='items scroll'
            horizontal={true}
            vertical={false}
            hideScrollbars={false}
            nativeMobileScroll={true}
            ref={navRef}
            onScroll={onSliderScrollElement}
        >
            {children}
            {
                items.map(x => {
                    var isSelected = x == selectedItem;
                    return <flex className={`${isSelected ? 'active' : ''} vertical item`} key={x}
                        onClick={() => select(x)}>
                        {onRenderItem(x)}
                    </flex>;
                })
            }
        </ScrollContainer>
        {sliderArrowVisible?.right && (
            <Icon icon='angle-right' className='clickable' size='1x' onClick={() => navRef.current.getElement().scrollLeft += 120} />
        )}
    </flex>;
};