import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FluxTab } from '../../../components/tab/FluxTab';
import { API } from '../../../lib/API/Api';
import { AffiliateEndpoints } from '../../../lib/API/Endpoints';
import { ActionButton, FluxCard } from '../../../components/card/FluxCard';
import { lang } from '../../../lib/Localization/language';
import { AffiliateDashboard } from '../AffiliateDashboard/AffiliateDashboard';
import { SearchPlayers } from '../../Players/SearchPlayers/SearchPlayers';
import { SearchPlayerTransactions } from '../../Players/SearchPlayerTransactions/SearchPlayerTransactions';
import { SearchPlayerGameHistory } from '../../Players/SearchPlayerGameHistory/SearchPlayerGameHistory';
import { SearchPlayerBetHistory } from '../../Players/SearchPlayerBetHistory/SearchPlayerBetHistory';
import { AffiliateContactInformation } from '../AffiliateContactInformation/AffiliateContactInformation';
import { appHistory } from '../../../..';
import { Icon } from '../../../components/icon/Icon';
import { copyDataToClipboard } from '../../../lib/Helpers/StringHelper';

export const Affiliate = (props) => {
    const [affiliate, setAffiliate] = useState(props.affiliate);


    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = parseFloat(urlParams.get('affiliateId'));
        if (!affiliate) {
            API.post(AffiliateEndpoints.ListAffiliates, { fields: [{ name: 'Id', value: id }] }).then((result) => {
                if (result.result[0].Id == id) {
                    setAffiliate(result.result[0]);
                }
            });
        }
    }, []);

    var tabButtons = [
        {
            title: 'Dashboard'
        },
        {
            title: 'Contact Information'
        },
        {
            title: 'Players'
        },
        {
            title: 'Transactions'
        },
        {
            title: 'Game History'
        },
        {
            title: 'Bet History'
        }
    ];

    if (!affiliate) return <></>;

    return <flex className='vertical gap-10'>
        <FluxCard title={affiliate.Name}
            buttons={<ActionButton title='Close' icon='times' onClick={() => appHistory.push('/affiliates/affiliate-center')} />}>
            <flex className='vertical gap-10'>
                <flex className='gap-20 columns-3'>
                    <flex className='vertical'>
                        <label className='medium'>{lang('Group')}</label>
                        <span>{affiliate.GroupName}</span>
                    </flex>
                    <flex className='vertical'>
                        <label className='medium'>{lang('Last settlement date')}</label>
                        <span>{affiliate.LastSettlementDate ? new Date(affiliate.LastSettlementDate).toLocaleString('en-Gb', { hours12: false }) : lang('there is no settlement.')}</span>
                    </flex>
                    <flex className='vertical'>
                        <label className='medium'>{lang('Last settlement amount')}</label>
                        <span>{affiliate.LastSettlementAmount ? affiliate.LastSettlementAmount : ''}</span>
                    </flex>
                </flex>
                <flex className={`gap-20 columns-${affiliate.InviteCode ? 2 : 1}`}>
                    <flex className='vertical'>
                        <label className='medium'>{lang('Link')}</label>
                        <flex className='gap-5' onClick={() => {
                            copyDataToClipboard(`${affiliate.WebSite}/register?&cid=${affiliate.Link}`);
                        }}>
                            <Icon icon='copy' />
                            <span>{`${affiliate.WebSite}/register?&cid=${affiliate.Link}`}</span>
                        </flex>
                    </flex>
                    {affiliate.InviteCode && <flex className='vertical'>
                        <label className='medium'>{lang('Invite Code')}</label>
                        <flex className='gap-5' onClick={() => {
                            copyDataToClipboard(`${affiliate.InviteCode}`);
                        }}>
                            <Icon icon='copy' />
                            <span>{affiliate.InviteCode}</span>
                        </flex>
                    </flex>}
                </flex>
                <flex className='vertical'>
                    <label className='medium'>{lang('Description')}</label>
                    <span>{affiliate.Description}</span>
                </flex>
            </flex>
        </FluxCard>
        <FluxTab buttons={tabButtons} className='gap-10 fx-shadow fx-radius rounded-buttons'>
            <AffiliateDashboard affiliate={affiliate} />
            <AffiliateContactInformation affiliate={affiliate} />
            <SearchPlayers affiliate={affiliate} />
            <SearchPlayerTransactions affiliate={affiliate} />
            <SearchPlayerGameHistory affiliate={affiliate} />
            <SearchPlayerBetHistory affiliate={affiliate} />
        </FluxTab>
    </flex>;
};

Affiliate.propTypes = {
    affiliate: PropTypes.object
};