/* eslint-disable react/display-name */
import React from 'react';

import { FluxSearch } from '../../../components/search/FluxSearch';
import { InputTypes } from '../../../components/form/InputTypes';
import { lang } from '../../../lib/Localization/language';
import { Icon } from '../../../components/icon/Icon';
import { RetailEndpoints, TransactionEndpoints } from '../../../lib/API/Endpoints';
import { RetailTransactionSelection, RetailTransactionTypes } from '../../../lib/Enums/RetailEnums';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { footerCurrencyValues } from '../../../lib/Helpers/Reports/Footer';
import { useSelector } from 'react-redux';
import { Currency } from '../../../components/currency/Currency';



const resultTransactionInformation = {
    fields: [
        {
            name: 'Id',
            title: 'Id'
        },
        {
            name: 'EntryDate',
            title: 'Date',
            type: InputTypes.DateTime,
        },
        {
            name: 'Type',
            title: 'Type',
            render: (val, row) => {
                return RetailTransactionTypes[row.Type];
            }
        },
        {
            name: 'From',
            title: 'From',
            render: (val, row) => {
                var from = null;
                switch (row.Type) {
                    case 0:
                        from = <span className='hq-color'>HQ</span>;
                        break;
                    case 1:
                        from = <div className='flex vertical'><span className='retail-color'>{row.RetailName}</span><span className='small retail-color'>retail</span></div>;
                        break;
                    case 2:
                        from = <div className='flex vertical'><span className='retail-color'>{row.RetailName}</span><span className='small retail-color'>retail</span></div>;
                        break;
                    case 3:
                        from = <div className='flex vertical'><span className='player-color'>{row.Username}</span><span className='small player-color'>player</span></div>;
                        break;
                    case 6:
                        from = <div className='flex vertical'><span className='parent-retail-color'>{row.ParentRetailName}</span><span className='small parent-retail-color'>retail</span></div>;
                        break;
                    case 7:
                        from = <div className='flex vertical'><span className='retail-color'>{row.RetailName}</span><span className='small retail-color'>retail</span></div>;
                        break;
                }

                return <div className='flex gap-5'>
                    {from}
                </div>;
            }
        },
        {
            name: 'To',
            title: 'To',
            render: (val, row) => {
                var to = null;
                switch (row.Type) {
                    case 0:
                        to = <div className='flex vertical'><span className='retail-color'>{row.RetailName}</span><span className='small retail-color'>retail</span></div>;
                        break;
                    case 1:
                        to = <span className='hq-color'>HQ</span>;
                        break;
                    case 2:
                        to = <div className='flex vertical'><span className='player-color'>{row.Username}</span><span className='small player-color'>player</span></div>;
                        break;
                    case 3:
                        to = <div className='flex vertical'><span className='retail-color'>{row.RetailName}</span><span className='small retail-color'>retail</span></div>;
                        break;
                    case 6:
                        to = <div className='flex vertical'><span className='retail-color'>{row.RetailName}</span><span className='small retail-color'>retail</span></div>;
                        break;
                    case 7:
                        to = <div className='flex vertical'><span className='parent-retail-color'>{row.ParentRetailName}</span><span className='small parent-retail-color'>retail</span></div>;
                        break;
                }

                return <div className='flex gap-5'>
                    {to}
                </div>;
            }
        },
        {
            name: 'Amount',
            title: 'Amount',
            type: InputTypes.Number,
            render: (val, row) => <Currency amount={(row.Type == 1 || row.Type == 3) ? -1 * val : val} sign={row.Currency} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'BalanceBefore',
            title: 'Balance Before',
            type: InputTypes.Number,
            render: (val, row) => <Currency amount={val} sign={row.Currency} />,
        },
        {
            name: 'Balance',
            title: 'Balance',
            type: InputTypes.Number,
            render: (val, row) => <Currency amount={val} sign={row.Currency} />,
        }
    ],
    onRenderRow: (rowObject) => {
        if (rowObject.data.win > Math.abs(rowObject.data.bet)) {
            rowObject.className = 'player-win';
        } else {
            if (rowObject.data.Type == 1) {
                rowObject.className = 'player-lost';
            }
        }
        return rowObject;
    }
};

export const SearchRetailTransactions = (props) => {
    const user = useSelector((state) => state.user).loginInformation;
    const { retail } = { ...props };

    var searchTransactionsModel = {
        fields: [
            {
                name: 'EntryDate',
                title: 'Date',
                type: InputTypes.DateTime,
                between: true,
                value: {
                    start: DateHelpers.yesterday(),
                    end: DateHelpers.tonight()
                }
            },
            {
                name: 'Admin',
                title: 'Admin Username'
            },
            {
                name: 'PlayerId',
                title: 'Player Id'
            },
            {
                name: 'PlayerCode',
                title: 'Player Code'
            },
            {
                name: 'Username',
                title: 'Player Username'
            },
            {
                name: 'Type',
                title: 'Transaction Type',
                type: InputTypes.Select,
                nullable: true,
                values: RetailTransactionSelection
            }
        ]
    };


    const endpoint = () => {
        if (retail) {
            return `${RetailEndpoints.ListRetailChildTransactions}/${retail.Id}`;
        }
        return TransactionEndpoints.Search;
    };

    return <FluxSearch
        context={{
            Operator: user.Operator
        }}
        title={<React.Fragment><span>{lang('Retail')}</span><Icon icon='chevron-right' type='fa-light' /><span>{retail.Name}</span><Icon icon='chevron-right' type='fa-light' /><span>{lang('Search transactions')}</span></React.Fragment>}
        model={searchTransactionsModel}
        resultModel={resultTransactionInformation}
        endpoint={endpoint()}
    />;
};

