import React from 'react';
import { useSelector } from 'react-redux';
import { Icon } from '../../../../../components/Controls/Icon/Icon';
import { lang } from '../../../../lib/Localization/language';
import { Modal } from '../../../../components/application/FluxApp';
import { Button } from '../../../../components/button/Button';
import { ActionButton, FluxCard } from '../../../../components/card/FluxCard';
import { SelectGames } from '../../../../components/dataControls/SelectGames';
import { FluxModal } from '../../../../components/modal/FluxModal';
import { ToggleButton } from '../../../../components/toggleButton/ToggleButton';
import { ProviderTypes } from '../../../../lib/Enums/ProviderEnums';
import { SliderConfigurator } from '../Components/Slider/SliderConfigurator';


var filterGroups = [
    {
        'title': 'All Games',
        'tag': 'all'
    },
    {
        'title': 'My Favourites',
        'tag': 'favourites'
    },
    {
        'title': 'Popular',
        'tag': 'popular'
    },
    {
        'title': 'New',
        'tag': 'new'
    },
    {
        'title': 'Jackpots',
        'tag': 'jackpots'
    },
    {
        'title': 'Video Slots',
        'tag': 'videoslots'
    },
    {
        'title': 'Crash',
        'tag': 'crash'
    },
    {
        'title': 'Dragon',
        'tag': 'dragon'
    },
    {
        'title': 'Fruits',
        'tag': 'fruits'
    },
    {
        'title': 'Sicbo',
        'tag': 'sicbo'
    },
    {
        'title': 'Wheel',
        'tag': 'wheel'
    },
    {
        'title': 'Candy',
        'tag': 'candy'
    },
    {
        'title': 'Blackjack',
        'tag': 'blackjack'
    },
    {
        "title": "Baccarat",
        "tag": "baccarat"
    },
    {
        "title": "Roulette",
        "tag": "roulette"
    },
    {
        "title": "Lobby",
        "tag": "lobby"
    }
];

export const GamingPageConfigurator = (props) => {
    const user = useSelector((state) => state.user.loginInformation);
    const { page, updateConfiguration, save } = { ...props };

    const updateSlider = (configuration) => {
        var copy = { ...page };
        copy.configuration.slider = configuration;
        save(copy);
    };

    const toggleProviderType = (type, value) => {
        var copy = { ...page };
        if (value) {
            copy.configuration.providerTypes |= type;
        } else {
            copy.configuration.providerTypes &= ~type;
        }

        updateConfiguration(copy.configuration);
    };

    const toggleLiveGamesOnly = (value) => {
        var copy = { ...page };
        copy.configuration.liveGamesOnly = value === true;
        updateConfiguration(copy.configuration);
    };

    const renderLaunchingGame = () => {
        if (!page.configuration.game) return;
        return <flex className='vertical'>
            <label>{lang('Game Id')}</label>
            <span>{page.configuration.game}</span>
        </flex>
    };

    const addGame = () => {
        var selectedItems = null;
        var selectedGames = {};
        if (page.configuration.game) {
            selectedGames[page.configuration.game] = true;
        }

        Modal.open(<FluxModal title='Select Game' footer={<flex className='gap-10 center'>
            <Button className='success' title={'Save'} onClick={() => {
                Modal.close();
                var copy = { ...page };
                if (Object.values(selectedItems).length == 0) {
                    delete copy.configuration.game;
                    delete copy.configuration.launch;
                } else {
                    copy.configuration.launch = Object.values(selectedItems)[0];
                    copy.configuration.game = Object.values(selectedItems)[0].id;
                }
                updateConfiguration(copy.configuration);
            }} />
            <Button title='Cancel' onClick={() => Modal.close()} />
        </flex>}>
            <SelectGames selectedGames={selectedGames} onChange={(selected) => {
                selectedItems = selected;
            }} multiple={false} />
        </FluxModal>);
    };

    return <flex className='vertical gap-10 overflow-hidden overflow-y-auto'>
        <flex className='gap-5 fit-children'>
            <FluxCard title='Game Types' className='no-padding' key={page.key}>
                <flex className=' vertical gap-10'>
                    <flex className='gap-10 padding'>
                        {
                            Object.keys(ProviderTypes).map((type) => {
                                var checked = page.configuration.providerTypes & parseInt(type);
                                if (type == 1) return;
                                if (type == 64) return;
                                if (type == 128) return;

                                return <flex className='gap-5 clickable' key={type} onClick={() => {
                                    toggleProviderType(type, !checked);
                                }}>
                                    <label className='medium'>{ProviderTypes[type]}</label>
                                    <ToggleButton value={checked > 0} onChange={(v) => {
                                        toggleProviderType(type, v);
                                    }} />
                                </flex>;
                            })
                        }
                    </flex>
                    <flex className='border-top' />
                    <flex className='gap-5 padding'>
                        <label className='medium'>{lang('Live games only')}</label>
                        <ToggleButton value={page.configuration.liveGamesOnly} onChange={(v) => {
                            toggleLiveGamesOnly(v);
                        }} />
                    </flex>
                </flex>
            </FluxCard>
            <FluxCard title='Launch Game' buttons={<flex className='align-right'><Button title='Add' className={'success'} onClick={() => addGame()} /></flex>}>
                {page.configuration.game && renderLaunchingGame()}
            </FluxCard>
        </flex>
        <FluxCard title='Filter Types' buttons={<ActionButton title='Save' icon='floppy-disk' onClick={() => {
            var groups = [];
            filterGroups.map(x => {
                var checked = page.configuration.filterGroups.find(y => y.tag == x.tag);
                if (checked) {
                    groups.push(x);
                }
            });
            var copy = { ...page };
            copy.configuration.filterGroups = groups;
            updateConfiguration(copy.configuration);
            save(copy);
        }} />}>
            <flex className='gap-10'>
                {
                    page.configuration.filterGroups && filterGroups.map((x) => {
                        var checked = page.configuration.filterGroups.find(y => y.tag == x.tag);
                        if (x.tag == 'all') {
                            checked = true;
                        }
                        return <flex className='gap-5' onClick={() => {
                            var copy = { ...page };
                            if (checked) {
                                copy.configuration.filterGroups = copy.configuration.filterGroups.filter(y => y.tag != x.tag);
                            } else {
                                copy.configuration.filterGroups.push(x);
                            }

                            updateConfiguration(copy.configuration);
                        }}>
                            <Icon icon={checked ? 'check-square' : 'square'} type='fa-light' /><label className='medium'>{x.title}</label>
                        </flex>
                    })
                }
            </flex>
        </FluxCard>
        <FluxCard title='Page Slider'>
            <SliderConfigurator allowGameSelection configuration={page.configuration?.slider ?? {}} {...props} onUpdate={(configuration) => updateSlider(configuration)}  />
        </FluxCard>
    </flex>;
};