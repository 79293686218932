import './_menu.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../icon/Icon';
import { Button } from '../button/Button';
import { MenuItem } from './MenuItem';
import { gotoProfile } from '../../lib/Helpers/NavigationHelper';
import { applicationMenu, hasAccess } from '../../lib/ActionManager/FluxActions';
import { API } from '../../lib/API/Api';
import { DataEndpoints } from '../../lib/API/Endpoints';
import { useDispatch, useSelector } from 'react-redux';
import { toPascalCase } from '../../../components/Centrum/helpers';
import { FavouriteMenuItems } from './FavouriteMenuItems';
import { searchPlayer } from '../../pages/Players/PlayerShortcuts/PlayerCenter';

export const fluxVersion = '1.71 A';

export const Menu = (props) => {
    const [minimized, minimizeMenu] = useState(false);
    const user = useSelector((state) => state.user.loginInformation);
    const isAffiliate = user.AffiliateId > 0;

    const dispatch = useDispatch();

    useEffect(() => {
        setInterval(() => {
            API.post(DataEndpoints.MyNotifications, null, '', '', false, null, false, true).then((result) => {
                dispatch({ type: 'MENU_NOTIFICATIONS', payload: toPascalCase(result.result) });
            });
        }, 5000);

        /*
        addEventListener('keypress', (event) => {
            console.log(event);
        });
        */
    }, []);

    useEffect(() => {
        document.getElementsByClassName('main-switch')[0].classList.remove('app-menu-minimized');
        if (minimized) {
            document.getElementsByClassName('main-switch')[0].classList.add('app-menu-minimized');
        }
    }, [minimized]);

    return <div className={`app-menu${minimized ? ' minimized' : ''}`}>
        <div className='flex gap-10'>
            <input className='search-app-menu'
                placeholder='use / for hints'
                onKeyUp={(e) => {
                    if (e.key == 'Enter') {
                        var value = e.target.value;
                        var firstChar = value[0];

                        if (firstChar == '/') {
                            var secondChar = value[1];
                            var rest = value.substr(2);
                            switch (secondChar) {
                                case 'u':
                                    searchPlayer({ 'Username': rest.trimStart().trimEnd() }, user, () => {
                                    });
                                    break;
                                case 'p':
                                    gotoProfile(parseFloat(rest));
                                    break;
                            }
                        }
                    }
                }} />
            <Button className='minimize-button' onClick={() => minimizeMenu(!minimized)}><Icon icon={minimized ? 'right-to-line' : 'left-to-line'} type='fa-light' /></Button>
        </div>
        <FavouriteMenuItems />
        <div className='items'>
            {applicationMenu.children.map(action => {
                if (action.hidden) return;
                if (isAffiliate && !action.anonymous && !action.affiliate) return;
                var activeItems = 0;
                if (action.anonymous) activeItems++;
                if (action.children) {
                    action.children?.map(x => {
                        if (!hasAccess(x.type, x.action)) return;
                        activeItems++;
                    });
                } else {
                    if (!hasAccess(action.type, action)) return;
                }
                if (activeItems == 0) return;
                return <MenuItem key={action.type} action={action} onSelected={(menuItem) => props.onSelected(menuItem)} />;
            })}
        </div>
        <label className='version'>{fluxVersion}</label>
    </div>;
};

Menu.propTypes = {
    onSelected: PropTypes.func
};