import './_form-input.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import { lang } from '../../../lib/Localization/language';
import { API } from '../../../lib/API/Api';
import ReactSelect from 'react-select';
import { isNullOrEmpty } from '../../../lib/Helpers/StringHelper';
import { lang } from '../../../lib/Localization/language';
import { Icon } from '../../icon/Icon';


export const SelectInput = (props) => {
    const [ready, setReady] = useState(false);
    const [values, setValues] = useState([]);
    const [value, setValue] = useState(undefined);
    const [filterMode, setFilterMode] = useState("in");
    const [showFilterOptions, setShowFilterOptions] = useState(false);

    const field = props.field;

    useEffect(() => {
        if (ready) return;
        if (field.data && field.data.ready) {
            setValues(field.data.values);
            setReady(true);
            return;
        }
        if (field.data && !field.data.ready) {
            API.post(field.data.source.indexOf('/') == 0 ? field.data.source : `/api/data/${field.data.source}`, field.data.payload ?? {}, null, null, false, null, true).then((result) => {
                if (field.data.filter) {
                    result.result = result.result.filter(x => field.data.filter(x));
                }
                if (field.data.sort) {
                    result.result = result.result.sort((a, b) => field.data.sort(a, b));
                }
                if (field.data.onParseComplete) {
                    field.data.onParseComplete(result.result);
                }
                if (field.data.parser) {
                    setValues(field.data.parser(result));
                    setReady(true);
                    return;
                }
                var dataValues = [];
                result.result.map(x => {
                    var title = [];
                    field.data.title.map(y => {
                        title.push(x[y]);
                    });
                    dataValues.push({ value: x[field.data.key], title: title.join(' ') });
                });
                setValues(dataValues);
                setReady(true);
            });
            return;
        }
        if (Array.isArray(field.values)) {
            setValues(field.values);
            setReady(true);
            return;
        }
        var keyValuePair = [];
        Object.keys(field.values).map(x => {
            keyValuePair.push({ value: x, title: lang(field.values[x]) });
        });

        setValues(keyValuePair);
        setReady(true);
    }, [props.data]);

    useEffect(() => {
        if (!values) return;
        if (!values.length) return;
        if (props.data && !isNullOrEmpty(props.data[field.name])) {
            setValue(props.data[field.name]);
            return;
        } else {
            if (field.nullable && (field.value === null || field.value === undefined)) {
                return;
            }
            if (value === undefined) {
                setValue((field.value !== null && field.value !== undefined) ? field.value : values[0].value);
            }
        }
    }, [values]);

    useEffect(() => {
        if (ready) {
            if (!value) {
                if (field.required || !field.nullable) {
                    if (values) {
                        var temp = (props.data && props.data[field.name]) ?? values[0];
                        setValue(temp?.value);
                    }
                }
            }
        }
    }, [ready]);

    useEffect(() => {
        if (value === undefined) {
            return;
        }

        if (value === null) {
            if (props.field.nullable) {
                props.onChange(props.field, null);
            }
            return;
        }
        props.onChange(props.field, value);
    }, [value]);


    if (!values) return <></>;
    if (!ready) return <></>;

    const handleMultiFilterChange = (mode) => {
        if (["in", "not in"].includes(mode)) {
            if (filterMode == mode) return;
            setFilterMode(mode);
            props.onMultiFilterChange(field, mode);
            setShowFilterOptions(false);
        }
    };

    const renderOptions = () => {
        var groups = {
            default: {
                label: 'Default',
                options: []
            }
        };

        values.map(x => {
            var group = x.group;
            var groupId = x.group?.id;
            if (!groupId) {
                groupId = 'default';
            } else if (!groups[groupId]) {
                groups[groupId] = {
                    label: group.title,
                    options: []
                };
            }
            groups[groupId].options.push({
                value: x.value,
                label: x.title
            });
        });

        if (Object.keys(groups).length == 1) return groups.default.options;

        return Object.values(groups);
    };

    const renderFilterButton = () => (
        <div
            className="multi-filter"
            onMouseEnter={() => setShowFilterOptions(true)}
        >
            <button className="filter-button">
                <Icon icon={filterMode === 'in' ? 'equals' : 'not-equal'} />
            </button>
            {showFilterOptions && (
                <div className="filter-options"
                    onMouseLeave={() => setShowFilterOptions(false)}
                >
                    <div
                        className={`filter-option ${filterMode === 'in' ? 'selected' : ''}`}
                        onClick={() => handleMultiFilterChange('in')}
                    >
                        = Equals
                    </div>
                    <div
                        className={`filter-option ${filterMode === 'not in' ? 'selected' : ''}`}
                        onClick={() => handleMultiFilterChange('not in')}
                    >
                        ≠ Does not equal
                    </div>
                </div>
            )}
        </div>
    );

    const renderMultipleSelect = () => {
        var options = [];
        values.map(x => {
            options.push({ label: x.title, value: x.value });
        });

        var selectedValues = [];

        var selectedData = field.value ?? (props.data ? props.data[field.name] : []);

        if (Array.isArray(values) && selectedData) {
            if (Array.isArray(selectedData)) {
                selectedData.map(x => {
                    var val = values.find(y => y.value == x);
                    if (val) {
                        selectedValues.push({ label: val.title, value: val.value });
                    }
                });
            }

            var selectedValuesConverted = [];
            selectedValues.map(x => {
                selectedValuesConverted.push(x.value);
            });

        }

        return <ReactSelect
            className='react-select multi'
            isDisabled={field.readOnly}
            name={field.name}
            defaultValue={selectedValues}
            options={options}
            onChange={(e) => {
                var selectedValues = [];
                e.map(x => {
                    selectedValues.push(x.value);
                });

                setValue(selectedValues);
            }}
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            isClearable={props?.field?.nullable ?? false}
            isSearchable={true}
            isMulti
        />;
    }

    const renderSelect = () => {
        var selectedValue = null;
        var options = renderOptions();
        selectedValue = value ? options.find(x => x.value == value) : props.data[field.name] ? options.find(x => x.value == props.data[field.name]) : field.required ? options[0] : null;

        return <ReactSelect
            isDisabled={field.readOnly}
            className='react-select'
            styles={{ menuPortal: base => ({ ...base, zIndex: 100 }) }}
            menuPortalTarget={document.body}
            defaultValue={selectedValue}
            options={options}
            onChange={(e) => {
                if (!e) {
                    setValue(null);
                    return;
                }
                setValue(e.value);
            }}
            name={field.name}
            hideSelectedOptions={false}
            closeMenuOnSelect={true}
            isClearable={props?.field?.nullable ?? false}
            isSearchable={true}
        />;
    };

    if (field.multiple) {
        if (field.multiFilterEnabled) {
            return (
                <div className="select-input-with-filter">
                    {renderFilterButton()}
                    {renderMultipleSelect()}
                </div>
            );
        }
        return renderMultipleSelect();
    }

    return renderSelect();
};

SelectInput.propTypes = {
    field: PropTypes.object,
    data: PropTypes.object,
    onChange: PropTypes.func,
    onMultiFilterChange: PropTypes.func,
};
