import React, { useEffect, useState } from 'react';
import { lang } from '../../../../lib/Localization/language';
import { FluxCard } from '../../../../components/card/FluxCard';
import { FluxForm } from '../../../../components/form/FluxForm';
import { InputTypes } from '../../../../components/form/InputTypes';
import { CurrencyMinMaxRow } from './CurrencyMinMax/CurrencyMinMaxRow';


const model = {

    fields: [
        {
            name: 'multiplier',
            title: 'Turnover Multiplier',
            type: InputTypes.Number
        },
        {
            name: 'deductDepositAmount',
            title: 'Deduct Bonus Amount',
            type: InputTypes.Bool
        }
    ]
};


export const TurnoverRules = (props) => {
    let { promotion, currencies, onChange } = { ...props };
    const [data, setData] = useState(promotion.Configuration.turnover ?? { multiplier: 0, deductDepositAmount: false });
    const [stakeData, setStakeData] = useState(promotion.Configuration.stake ?? {})

    useEffect(() => {
        if (!data) return;
        promotion.Configuration.turnover = data;
        onChange({ ...promotion });
    }, [data]);


    useEffect(() => {
        promotion.Configuration.stake = stakeData;
        onChange({ ...promotion });
    }, [stakeData]);

    const updateStakeSettings = (currency, value) => {
        var copy = { ...stakeData };
        if (!copy[currency]) {
            copy[currency] = [
                {
                    Min: 0,
                    Max: 0
                }
            ];
        }

        copy[currency][0] = value;
        setStakeData(copy);
    };

    const deleteCurrencySetting = (target) => {
        var copy = { ...stakeData };
        delete copy[target];
        setStakeData(copy);
    };

    return <FluxCard>
        <FluxForm data={data} model={model} columns={2} buttons={null} onChange={(d) => {
            setData(d);
        }} />
        <flex className='vertical gap-5 padding'>
            <label className='medium'>{lang('Minimum / Maximum Stake Amounts')}</label>
            <flex className='vertical gap-10'>
                {currencies.map(x => <CurrencyMinMaxRow key={x} currency={x} updateStakeSettings={updateStakeSettings} deleteCurrencySetting={deleteCurrencySetting} data={stakeData} />)}
            </flex>
        </flex>
    </FluxCard>;
};