// IMPORT PACKAGE REFERENCES
import 'whatwg-fetch';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '../Modal/Modal';
import { Loading } from '../Modal/Modals/Loading/Loading';
import { ErrorModal } from '../Modal/Modals/Error/ErrorModal';
import { lang } from '../../flux/lib/Localization/language';
import { SnackBar } from '../Modal/Modals/SnackBar/SnackBar';
import { Snackbars } from '../Modal/Snackbars';
import { Overlay } from '../Modal/Overlay';
import { getLocalStorage } from '../Centrum/helpers';
import { getStore } from '../..';

var _applicationContext = null;
export const Context = React.createContext();
export const application = () => {
    return _applicationContext;
};


export const Endpoints = {

    //Login: '/api/authentication/login',
    //Logout: '/api/authentication/logout',
    Login: '/api/user/login',
    Logout: '/api/user/logout',
    Ping: '/api/user/ping',



    // Data
    AdminNotifications: '/api/data/adminNotifications',
    EnabledCurrencies: '/api/data/enabledCurrencies',
    MyConfiguration: '/api/data/configuration',
    EnabledCountries: '/api/data/enabledCountries',
    GetCustomData: '/api/data/get',
    MyProviders: '/api/data/myproviders',

    // Dashboard
    DashboardTotalPlayers: '/api/dashboard/main/players/summary',
    DashboardGameSessions: '/api/dashboard/main/games/sessions',
    DashboardLiability: '/api/dashboard/main/liability/summary',
    DashboardMostPlayedGames: '/api/dashboard/main/games/summary',


    //Exchange Rates
    ExchangeRates: '/api/exchangeRates/list',
    SaveExchangeRate: '/api/exchangeRates/save',

    //Player
    CreatePlayerGroup: '/api/player/group/save',
    GetPlayerGroups: '/api/player/groups',
    MyPlayerGroups: '/api/data/MyPlayerGroups',
    CreatePlayerNote: '/api/player/notes/add/',
    GetProfile: '/api/player/profile/',
    SearchPlayerActivity: '/api/player/activity',
    SearchPlayerNotifications: '/api/player/notifications',
    GetPlayer: '/api/player/',
    ChangePlayerGroup: '/api/player/group/change/',
    ChangePlayerTag: '/api/player/tag/change/',
    PlayerSearch: '/api/player/search',
    PlayerSearchGameHistory: '/api/player/searchGameHistory',
    PlayerGetAction: '/api/player/',
    PlayerUpdate: '/api/player/update',
    ToggleEnablePlayer: '/api/player/enabled/toggle/',
    Disable2FA: '/api/player/twofactorauthentication/disable',
    ToggleVerification: '/api/player/verification/toggle/',
    ToggleTestAccount: '/api/player/test/toggle/',
    PlayerSummary: '/api/player/summary',
    MovePlayerToRetail: '/api/retail/player/move',
    RemoveMovePlayerFromRetail: '/api/retail/player/remove',
    ChangePlayerRisk: '/api/player/risk/change/',




    //Profile
    MyProfileGet: '/api/myProfile/get',
    MyProfileUpdate: '/api/myProfile/updateMyProfile',
    MyProfileActivity: '/api/myProfile/activity',
    UploadedDocuments: '/api/player/verification/documents/',

    //UserGroups
    SearchUserGroups: '/api/userGroups/search',
    UserGroupsGetBindingKeys: '/api/userGroups/getBindingKeys',
    UserGroupsGetGroupBindings: '/api/userGroups/getGroupBindings/',
    UpdateUserGroupBindings: '/api/userGroups/update/',
    GetUsersGroupBinding: '/api/userGroups/user/bindings',
    SaveUsersGroupBinding: '/api/userGroups/user/bindings/save',

    //CentrumNode
    CentrumGetNodeByType: '/api/centrumHub/getNodeByType?&type=',
    CentrumGetCrashNodes: '/api/centrumHub/getCrashNodes',
    CentrumCloseCrashRoom: '/api/centrumHub/closeCrashRoom',
    CentrumOpenCrashRoom: '/api/centrumHub/openCrashRoom',
    CentrumNoticeCrashRoom: '/api/centrumHub/NoticeCrashRoom',
    CentrumShutDownHub: '/api/centrumHub/shutdownHub',
    CentrumRestartHub: '/api/centrumHub/restartHub',
    CentrumNoticeHub: '/api/centrumHub/noticeHub',
    CentrumKickPlayer: '/api/centrumHub/kickPlayer',
    CentrumBanPlayer: '/api/centrumHub/banPlayer',
    CentrumNoticePlayer: '/api/centrumHub/noticePlayer',
    CentrumRevokeRestriction: '/api/centrumHub/revokeRestriction',
    CentrumSearchNodeRestrictions: '/api/centrumHub/searchNodeRestrictions',

    //Crash
    CrashGameSearchRooms: '/api/crashGame/searchRooms',
    CrashGameSearchGameRound: '/api/crashGame/searchCrashGameRound',
    CrashGameSearchCrashGameRoundTransactions: '/api/crashGame/searchCrashGameRoundTransactions',
    CrashGameSearchPassiveRooms: '/api/crashGame/searchPassiveRooms?&nodeKey=',
    CrashGameSearchCrashServerNotifications: '/api/crashGame/searchCrashServerNotifications',
    CrashGameSearchCrashGameBankrolls: '/api/crashGame/SearchCrashGameBankrolls',
    CrashGameSearchCrashGameLeaderboard: '/api/crashGame/searchCrashGameLeaderboard',

    //Language
    UserChangeLanguage: '/api/user/changeLanguage?&language=',

    //Marketing
    MarketingGetEmailTemplate: '/api/marketing/getEmailTemplate?&id=',
    MarketingSearchEmailTemplate: '/api/marketing/searchEmailTemplate',
    MarketingSearchTemplateEvents: '/api/marketing/searchTemplatesEvents?id=',
    MarketingAddEmailTemplate: '/api/marketing/addEmailTemplate',
    MarketingUpdateEmailTemplate: '/api/marketing/updateEmailTemplate',
    MarketingDeleteEmailTemplate: '/api/marketing/deleteEmailTemplate?&id=',
    MarketingAddTemplatesEvents: '/api/marketing/addTemplatesEvents',

    //Operator
    OperatorUsersSearch: '/api/operatorUsers/search',
    OperatorUsersActivity: '/api/operatorUsers/searchUserActivity',
    OperatorGetUserGroups: '/api/operators/getOperatorUserGroups',

    //Provider
    ProviderSearch: '/api/provider/search',
    ProviderSearchGames: '/api/provider/searchGames',
    ProviderToggleProviderStatus: '/api/provider/toggleProviderStatus/',
    ProviderSearchOperatorProviderConfiguration: '/api/provider/searchOperatorProviderConfiguration',
    ProviderToggleProviderStatusForOperator: '/api/provider/toggleProviderStatusForOperator?&id=',
    ProviderSearchOperatorProviderGameConfiguration: '/api/provider/searchOperatorProviderGameConfiguration',
    ProviderToggleProviderGameStatus: '/api/provider/toggleProviderGameStatus?&gameId=',
    ProviderToggleProviderGameStatusForOperator: '/api/provider/toggleProviderGameStatusForOperator?&operatorId=',
    ProviderUpdateGame: '/api/provider/updateGame',



    // Player
    ShowActivityAfterDeposit: '/api/player/activity/after/deposit',

    //Compliance
    SearchSameIpPlayers: '/api/compliance/sameIp',

    // Game
    ToggleGameStatus: '/api/provider/game/toggleStatus/',

    //CMS
    GetCMSSkinConfiguration: '/api/cms/skin/configuration/images',
    GetCMSGames: '/api/cms/games/list',
    GetCMSGameData: '/api/cms/gameData/',
    CMSCreateGameImage: '/api/cms/game/image/save',
    CMSUploadImage: '/api/cms/image/save',
    CMSGetGameDetail: '/api/cms/gameDetail/',
    CMSSaveGame: '/api/cms/game/save',
    CMSGetLanguage: '/api/cms/language/get',
    CMSSaveLanguageKey: '/api/cms/language/key/save',
    CMSPublishWebSiteLanguage: '/api/cms/language/publish/website',
    CMSPublishPages: '/api/cms/pages/publish',
    CMSGetContent: '/api/cms/content',
    CMSGetContents: '/api/cms/contents',
    CMSSaveContent: '/api/cms/save',
    CMSSaveImage: '/api/cms/image/upload',

    CMSBackofficeLanguage: '/api/cms/language/backoffice/get',
    CMSSaveBackofficeLanguage: '/api/cms/language/backoffice/save',
    CMSPublishBackofficeLanguage: '/api/cms/language/publish/backoffice',

    //Risk Management
    GetSportsConfiguration: '/api/sports/configuration/get',
    SaveSportsConfiguration: '/api/sports/configuration/save',
    GetCasinoRiskMangement: '/api/risk/configuration/casino',
    SaveOperatorGamingProviderLimits: '/api/risk/configuration/casino/save',
    GetRiskGroups: '/api/risk/groups',
    GetProviderGameTypes: '/api/risk/providers/gameTypes',
    GetOperatorGroupGamingRiskConfiguration: '/api/risk/configuration/group/',
    SaveOperatorGroupGamingRiskConfiguration: '/api/risk/configuration/group/save/',
    GetPlayerRiskMangement: '/api/risk/configuration/player/',
    GetPlayerPaymentManagement: '/api/player/configuration/payment/',
    SavePlayerRiskMangement: '/api/risk/configuration/player/save/',
    SavePlayerPaymentMangement: '/api/player/configuration/save/payment',
    BetStopEvent: '/api/risk/event/stop',
    BetStartEvent: '/api/risk/event/start',
    BetStopMarket: '/api/risk/market/stop',
    BetStartMarket: '/api/risk/market/start',
    EventStakeLimit: '/api/risk/event/stake/limit',
    EventLiabilityLimit: '/api/risk/event/liability/limit',

    //Operator
    NewOperator: '/api/operator/new',
    SaveOperatorFeatures: '/api/operator/features/save',
    SearchOperators: '/api/operators/search',
    GetOperatorConfiguration: '/api/operator/configuration/',
    SaveOperatorCountries: '/api/operator/configuration/countries/save',
    SaveOperatorCurrencies: '/api/operator/configuration/currencies/save',
    SaveOperatorCasino: '/api/operator/configuration/casino/save',
    SaveOperatorPaymentGateways: '/api/operator/configuration/paymentGateways/save',


    //Lottery
    LotteryAddEvent: '/api/lottery/event/new',
    LotteryUpdateEvent: '/api/lottery/event/update',
    LotteryEvents: '/api/lottery/events/list',
    LotteryResult: '/api/lottery/event/result',
    LotteryEventDetails: '/api/lottery/event/summary',
    LotteryEventPayoutPreview: '/api/lottery/event/payout/preview',
    LotteryClose: '/api/lottery/event/close',
    LotteryRemoveEvent: '/api/lottery/event/remove',
    LotterySearchTickets: '/api/lottery/search/tickets',
    LotterySearchEvents: '/api/lottery/search/events',
    LotteryEventLimits: '/api/lottery/limits',
    LotterySaveEventLimits: '/api/lottery/limits/save',

    // Sports
    SportCategoryConfiguration: '/api/sports/configuration/categories',
    SportCategories: '/api/sports/categories',
    SportRegions: '/api/sports/regions',
    SportLeagues: '/api/sports/leagues',
    SportLeaguesByDates: '/api/sports/league/dates',
    SportEvents: '/api/sports/events',
    SearchSportEvents: '/api/sports/search/events',

    SaveTopLeagues: '/api/sports/cms/topleagues/save',
    SaveLiveTopLeagues: '/api/sports/cms/topleagues/live/save',
    GetTopLeagues: '/api/sports/cms/topleagues/get',
    GetLiveTopLeagues: '/api/sports/cms/topleagues/live/get',
    SaveTopEvents: '/api/sports/cms/topevents/save',
    GetTopEvents: '/api/sports/cms/topevents/get',

    GetActiveBulletin: '/api/sports/bulletin/active',
    SaveBulletin: '/api/sports/bulletin/save',
    GetLiveEvents: '/api/sports/events/live',


    SearchSportTickets: '/api/sports/tickets',
    SearchManualSportTickets: '/api/sports/tickets/manual',
    SearchBookedTickets: '/api/sports/tickets/booked',
    SearchSportEventTickets: '/api/sports/tickets/events',
    SearchSportEventMarketTickets: '/api/sports/tickets/markets',
    SearchSportEventSelectionTickets: '/api/sports/tickets/selections',
    SearchOpenTickets: '/api/sports/tickets/open',
    SearchEventsOfOpenSlipsModel: '/api/sports/tickets/open/events',
    SearchLastRemainingTickets: '/api/sports/tickets/lastRemaining',
    SearchBonusTickets: '/api/sports/tickets/bonus',
    TicketDetails: '/api/sports/ticket',
    HorseTicketDetails: '/api/horse/ticket',
    HorseTickets: '/api/horse/tickets',
    HorseGreyhoundBetslipDetails: '/api/horse/ticket/details',
    SportEventResults: '/api/sports/results/events',
    GetPendingTicket: '/api/sports/ticket/pending',
    ConfirmPendingTicket: '/api/risk/offer/confirm',
    RejectPendingTicket: '/api/risk/offer/reject',
    AssignEventToAdmin: '/api/risk/event/assign',
    GetMyAssignedEvents: '/api/risk/event/assignedToMe',
    ManagedEvents: '/api/risk/event/managed',
    GetAffectedTicketsFromSelectionUpdates: '/api/sports/ticket/updateSelections/preview',
    SearchPlayersWithoutSportBets: '/api/sports/players/withoutbets',

    // Sport Radar Prices
    GetMarketPrices: '/api/sport/prices/markets',
    SportSelectionPrices: '/api/sport/prices/selections',


    //Transactions
    SearchTransactions: '/api/transactions/search',
    SearchProcessingTransactions: '/api/transactions/search/processing',
    SearchAllTransactions: '/api/transactions/search/all',
    SearchBonusTransactions: '/api/transactions/bonus/search',
    SearchPlayerBonus: '/api/promotions/player/search',
    SearchPlayerBonusProgress: '/api/promotions/player/bonus/progress',
    SearchPlayersWithoutBonus: '/api/promotions/player/bonus/notused',
    SearchPlayersPendingPromotions: '/api/promotions/player/bonus/pending',
    SearchSlipLotteryTickets: '/api/promotions/player/sports/lottery',
    SearchPlayerBonusSummary: '/api/promotions/player/bonus/summary',
    SearchCompletedBonusPayouts: '/api/promotions/player/bonus/completed',
    SearchCompletedBonusPayoutsSummary: '/api/promotions/player/bonus/completed/summary',
    SearchRemovedBonusReports: '/api/promotions/player/bonus/removed',
    SearchConsumedPromotions: '/api/promotions/consumed',
    CreateTransaction: '/api/transactions/create',
    ResetBalance: '/api/transactions/balance/reset',
    CancelTransction: '/api/transactions/cancel',
    CreateBonusTransaction: '/api/transactions/bonus/create',
    RejectPendingPromotion: '/api/promotions/pending/reject',
    RejectPendingPromotions: '/api/promotions/pending/reject/selected',
    CompleteBonus: '/api/transactions/bonus/complete',
    RemoveBonus: '/api/transactions/bonus/remove',
    TransactionsConfirmDeposits: '/api/transactions/confirmDeposits',
    TransactionsConfirmWithdraws: '/api/transactions/confirmWithdraws',
    TransactionsRejectDeposits: '/api/transactions/rejectDeposits',
    TransactionsRejectWithdraws: '/api/transactions/rejectWithdraws',
    UpdateTransactionFormData: '/api/transactions/updateFormData',
    PrepareFinanceReport: '/api/reports/revenues/finance/summary',
    NotifyPlayer: '/api/player/notify',

    //Shop
    ShopSearch: '/api/shop/search',
    ShopSearchTransactions: '/api/shop/searchTransactions',
    ShopGet: '/api/shop/get/',


    //History
    SearchPlayerGameHistory: '/api/player/history/games',
    SearchPlayerGameSessions: '/api/player/history/games/sessions',
    SearchPlayerBetHistory: '/api/player/history/sports',


    //Report
    DailyRevenuesSummaryReport: '/api/reports/revenues/daily/summary',
    CasinoRevenuesReport: '/api/reports/casino',
    CasinoProviderRevenuesReport: '/api/reports/casino/providers',
    CasinoProviderCommissionReport: '/api/reports/casino/providers/commissions',
    GameRevenuesReports: '/api/reports/casino/games',
    PlayerCasinoRevenuesReport: '/api/reports/casino/player',
    PlayerGroupsGameRevenuesReportResult: '/api/reports/casino/groups',

    SportBettingRevenuesReport: '/api/reports/sports/revenues',
    SportBettingPlayerRevenuesReport: '/api/reports/sports/revenues/player',
    SportPlayerGroupRevenues: '/api/reports/sports/revenues/groups',
    SportCategoryRevenueSummaryReport: '/api/reports/sports/categories',
    SportRegionRevenueSummaryReport: '/api/reports/sports/regions',
    SportLeagueRevenueSummaryReport: '/api/reports/sports/leagues',
    SportEventRevenueSummaryReport: '/api/reports/sports/events',
    SportMarketRevenueSummaryReport: '/api/reports/sports/markets',
    SportSelectionRevenueSummaryReport: '/api/reports/sports/selections',
    SportPlayerRevenueSummaryReport: '/api/reports/sports/players',
    SportEventRevenueReport: '/api/reports/sports/eventRevenues',

    HorseGreyhoundSummaryReport: '/api/reports/horse/summary',
    HorseGreyHoundRegions: '/api/reports/horse/regions',
    HorseGreyHoundVenues: '/api/reports/horse/venues',
    HorseGreyHoundDistance: '/api/reports/horse/distance',
    HorseGreyHoundPlayers: '/api/reports/horse/players',
    SearchHorseGreyhoundTickets: '/api/horse/tickets/search',

    ProviderRevenuesReport: '/api/reports/provider/revenues',
    OperatorRevenuesReport: '/api/reports/operator/revenues',

    PlayerRevenuesReport: '/api/reports/players/revenues',
    PlayerGameRevenuesReport: '/api/reports/players/games',

    ProviderTransactionsReport: '/api/reports/provider/transactions',
    PlayerProviderTransactions: '/api/reports/provider/transactions/players',
    PlayerProviderTransactionsDetails: '/api/reports/provider/transactions/players/details',
    SearchTransactionsAccountSummary: '/api/reports/transactions/summary',
    SearchFirstTransactions: '/api/reports/transactions/first',




    ReportByPlayer: '/api/report/reportByPlayer',
    ReportByPlayerGame: '/api/report/reportByPlayerGame',
    ReportByProvider: '/api/report/reportByProvider',
    ReportByBetResult: '/api/report/reportByBetResult',
    ReportByCrashPlayerTransaction: '/api/report/reportByCrashPlayerTransaction',
    ReportByCrashRoundTransaction: '/api/report/reportByCrashRoundTransaction',
    ReportByFinancialOperator: '/api/report/reportByFinancialOperator',
    ReportByFinancialOperatorDetail: '/api/report/reportByFinancialOperatorDetail',
    ReportByFinancialOperatorTransaction: '/api/report/reportByFinancialOperatorTransaction',
    ReportByFinancialManualTransaction: '/api/report/reportByFinancialManualTransaction',
    ReportOfMyManualTransactions: '/api/report/reportOfMyManualTransactions',
    ReportDailyManualTransaction: '/api/report/dailyManualTransactionReport',
    ReportMyDailyManualTransaction: '/api/report/myDailyManualTransactionReport',
    ReportByPlayerPurse: '/api/report/reportByPlayerPurse',
    ReportSportsBookByBet: '/api/report/sportsBookReportByBet',
    ReportSportsBookBySport: '/api/report/sportsBookReportBySport',
    ReportSportsBookByCompetiton: '/api/report/sportsBookReportByCompetiton',
    ReportSportsBookByMarketType: '/api/report/sportsBookReportByMarketType',
    ReportCrashByLeaderboard: '/api/report/crashReportByLeaderboard',
    RetailTransactionsReport: '/api/reports/retail/transactions',
    RetailAccountSummary: '/api/reports/retail/account/summary',
    RetailPlayerTransactionsReport: '/api/reports/retail/players/transactions',


    //Affiliates
    GetAffiliate: '/api/affiliates/get',
    GetAffilliateDebt: '/api/affiliates/debts',
    ListAffiliateGroups: '/api/affiliates/groups/list',
    SaveAffiliateGroup: '/api/affiliates/group/save',
    ListAffiliates: '/api/affiliates/list',
    SaveAffiliate: '/api/affiliates/save',
    SearchAffiliateUsers: '/api/affiliates/users/search',
    ListAffiliatePlayers: '/api/affiliates/players/list',
    SearchAffiliateTransactions: '/api/affiliates/transactions/search',
    SearchAffiliateBonus: '/api/affiliates/bonus/search',
    SearchAffiliatePlayerGameHistory: '/api/affiliates/history/game/search',
    SearchAffiliateSettlements: '/api/affiliates/settlements/search',
    AffiliateRemoveActivePromotion: '/api/affiliates/promotion/active/remove',
    AffiliateAddPromotion: '/api/affiliates/promotion/add',
    CreateAffiliateSettlement: '/api/affiliates/settlement/create',
    AddAffiliateUser: '/api/affiliates/user/save',
    SearchAffiliateTransactionsSummary: '/api/affiliates/transactions/search/summary',

    // Manual Bet
    SigninWithPlayer: '/api/player/signin',

    //Tickets
    ManualTicketWin: '/api/ticket/win',
    ManualTicketLost: '/api/ticket/lost',
    ManualTicketCancel: '/api/ticket/cancel',
    ManualTicketUpdate: '/api/ticket/update',
    ManualTicketRollback: '/api/ticket/rollback',
    BookABet: '/api/ticket/manual/create',
    WinBookABet: '/api/ticket/manual/win',
    LostBookABet: '/api/ticket/manual/lost',
    GetTicketSelections: '/api/sports/ticket/selections',
    UpdateSelectionsOfEvent: '/api/sports/ticket/selections/update',
    ChangeStatusOfAllOpenSelections: '/api/sports/ticket/selection/changeStatus',



    //Retail,
    AddModifyRetail: '/api/retail/save',
    ListRetails: '/api/retails/',
    MyRetails: '/api/data/myRetails/',
    MyUsers: '/api/data/MyOperatorUsers',
    GetRetail: '/api/retail',
    GetRetailConfiguration: '/api/retail/configuration',
    ListRetailUsers: '/api/retail/users',
    ListRetailPlayers: '/api/retail/players',
    CreateRetailUser: '/api/retail/user/create',
    UpdateRetailUser: '/api/retail/user/update',
    CreateRetailPlayer: '/api/retail/player/create',
    GetRetailPlayer: '/api/retail/player',
    UpdateRetailPlayer: '/api/retail/player/update',
    ListRetailTransactions: '/api/retail/transactions',
    RetailSettlements: '/api/retail/settlements',
    AddRetailCredit: '/api/retail/credit/add',
    RemoveRetailCredit: '/api/retail/credit/remove',
    DepositRetailPlayer: '/api/retail/player/deposit',
    WithdrawRetailPlayer: '/api/retail/player/withdraw',

    ProviderCards: '/api/data/provider-cards/get',
    SaveProviderCards: '/api/data/provider-cards/save',

    //Provider
    GetConfiguration: '/api/provider/configuration',

    //Promotions
    ListPromotions: '/api/promotions/list',
    SavePromotion: '/api/promotion/save',
    DeletePromotion: '/api/promotion/delete',
    PublishPromotions: '/api/cms/promotions/publish',

    RunPeriodicPromotion: '/api/promotion/periodic/run',

    GetPromotionPaymentGateways: '/api/promotion/configuration/paymentGateway',
    SavePromotionPaymentGateways: '/api/promotion/configuration/savePaymentGateway',

    GetPromotionGameProviders: '/api/promotion/configuration/gameProviders',
    SavePromotionGameProviders: '/api/promotion/configuration/saveGameProviders',

    GetPromotionGameBindings: '/api/promotion/configuration/gameBindings',
    SavePromotionGameBindings: '/api/promotion/configuration/saveGameBindings',


    GetPromotionPlayerGroups: '/api/promotion/configuration/playerGroups',
    SavePromotionPlayerGroups: '/api/promotion/configuration/savePlayerGroups',

    GetPromotionConfiguration: '/api/promotion/configuration/get',
    SavePromotionConfiguration: '/api/promotion/configuration/save',
    BulkRemovePromotion: '/api/promotion/player/bulk/remove',

    //Super User
    ChangePointOfView: '/api/user/ChangePointOfView',

    // Risk Management
    SaveApplicationSportLimits: '/api/risk/application/sport/limits/save',
    GetApplicationSportLimits: '/api/risk/application/sport/limits/get',
    SaveSportLimits: '/api/risk/sport/limits/save',
    SaveLeagueLimits: '/api/risk/sport/limits/leagues/save',
    SaveCategoryLimits: '/api/risk/sport/limits/categories/save',
    SavePlayerLimits: '/api/risk/sport/limits/players/save',
    GetCategoryLimits: '/api/risk/sport/limits/categories/get',
    GetLeagueLimits: '/api/risk/sport/limits/leagues/get',
    GetSportLimits: '/api/risk/sport/limits/get',
    GetEventLimits: '/api/risk/sport/limits/events/get',
    GetPlayerLimits: '/api/risk/sport/limits/players/get',
    SaveEventLimits: '/api/risk/sport/limits/events/save',



    SaveApplicationCasinoLimits: '/api/risk/application/casino/limits/save',
    GetApplicationCasinoLimits: '/api/risk/application/casino/limits/get',
    GetProviderCasinoLimits: '/api/risk/application/casino/provider/limits/get',

    BetMonitor: '/api/sports/betMonitor',
    RiskReport: '/api/sports/riskReport',
    RiskVolumes: '/api/risk/volumes',
    GetTickerUrl: '/api/risk/getTickerUrl',


    // Setup
    SetupSportCategories: '/api/setup/sports/categories',
    SetupSportRegions: '/api/setup/sports/regions',
    SetupSportLeagues: '/api/setup/sports/leagues',
    SetupSportEvents: '/api/setup/sports/events',
    SaveSetupSportCategories: '/api/setup/sports/categories/save',
    SaveSetupSportRegions: '/api/setup/sports/regions/save',
    SaveSetupSportLeagues: '/api/setup/sports/leagues/save',
    SaveSetupSportEvents: '/api/setup/sports/events/save',
};



export const logout = () => {
    return apiResult('', Endpoints.Logout, {}, 'sign off', '').then(() => {
        document.cookie = 'backend-portal-auth=;expires=Fri, 31 Dec 2000 23:59:59 GMT;path=/';
        document.location.href = '/';
    }).catch(() => {
        document.cookie = 'backend-portal-auth=;expires=Fri, 31 Dec 2000 23:59:59 GMT;path=/';
        document.location.href = '/';
    });
};


export const apiResult = (action, url, data, title, errorTitle) => {
    return _applicationContext.resolve(action ? '_' + action : null, url, data, title, errorTitle);
};

export class Application extends Component {
    constructor(props) {
        super(props);
        this.modals = null;
        this.notifications = null;
        this.overlays = null;

        window.mobile = false;
        this.width = window.innerWidth;


        this.applicationContext = {
            user: {
            },
            closeModals: (e) => {
                if (e.key == 'Escape') {
                    this.applicationContext.overlay.close();
                    this.applicationContext.modal.close();
                    this.applicationContext.snackbar.close();
                    document.removeEventListener('keyup', this.applicationContext.closeModals.bind(this));
                }
            },
            overlay: {
                open: (component) => {
                    document.addEventListener('keyup', this.applicationContext.closeModals.bind(this));
                    var overlay = document.getElementsByClassName('overlay')[0];
                    if (overlay != undefined) overlay.style = 'display:unset';
                    this.overlays.open(component);
                },
                close: () => {
                    var overlay = document.getElementsByClassName('overlay')[0];
                    if (overlay != undefined) overlay.style = 'display:none';
                    this.overlays.close();
                }
            },


            snackbar: {
                open: (text) => {
                    document.addEventListener('keyup', this.applicationContext.closeModals.bind(this));
                    if (!this.snackbars) return;
                    this.snackbars.open(<SnackBar text={text} />);
                },
                close: () => {
                    if (!this.snackbars) return;
                    this.snackbars.close();
                }
            },
            modal: {
                open: (modal) => {
                    document.addEventListener('keyup', this.applicationContext.closeModals.bind(this));
                    if (!this.modals) return;
                    this.modals.open(modal);
                },
                close: () => {
                    if (!this.modals) return;
                    this.modals.close();
                }
            },
            notifications: {
                pop: (type, message) => {
                    this.notifications.pop(type, message);
                }
            },
            // post
            post: (url, data) => {
                return this.post(url, data);
            },
            get: (url) => {
                return this.get(url);
            },
            busy: (text = 'loading') => {
                this.applicationContext.modal.open(<Loading title={text} />);
            },
            // resolves given action such as player updates , register etc.
            resolve: (action, url, data, title, errorTitle, onlyResult) => {
                var startTime = new Date();

                // show only if there is title
                if (title) {
                    this.applicationContext.modal.open(<Loading title={title} />);
                }

                if (action !== null) {
                    getStore().dispatch({
                        type: action + '_PENDING'
                    });
                }

                var promise = this.post(url, data);
                promise.then((result) => {

                    // whenever a success action
                    // dispatch it to store
                    if (action !== null) {
                        getStore().dispatch({
                            type: action + '_FULFILLED',
                            payload: onlyResult ? result.result : result
                        });
                    }



                    var endTime = new Date();
                    var timeDiff = endTime - startTime; //in ms
                    // get seconds 
                    var seconds = Math.round(timeDiff / 1000);
                    const showLog = false;
                    if (showLog) console.log('action completed in ' + seconds + ' seconds' + timeDiff + ' ms');

                    if (title) {
                        this.applicationContext.modal.close();
                    }
                }).catch((result) => {
                    if (action !== null) {
                        getStore().dispatch({
                            type: action + '_FAILED'
                        });
                    }

                    if (errorTitle) {
                        this.applicationContext.modal.close();
                    }

                    if (result.toString().indexOf('SyntaxError') >= 0 && errorTitle) {
                        // general error , 404 or unable to parse json because the api resulted error
                        this.applicationContext.modal.open(<ErrorModal title={lang(errorTitle)}><p>{lang('Service is not available at the moment. Please try again later.')}</p></ErrorModal>);
                        return;
                    }

                    if (errorTitle) {
                        if (result.error === undefined) {
                            this.applicationContext.modal.open(<ErrorModal title={lang(errorTitle)}><p>{lang(result.title)}</p></ErrorModal>);
                        } else {
                            this.applicationContext.modal.open(<ErrorModal title={lang(errorTitle)}><p>{lang(result.error ? result.error.message : (result.status == 415 ? 'Not a valid post data' : 'Service not available'))}</p></ErrorModal>);
                        }
                    }
                });
                return promise;
            }
        };
        _applicationContext = this.applicationContext;
    }


    componentDidMount() {
        this.adjustApplicationSize();
        window.addEventListener('resize', () => {
            this.adjustApplicationSize();
        });
    }

    post(url, data) {
        var currency = getLocalStorage('selected-currency');
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-Currency': currency,
                    'X-Timezone-Offset': new Date().getTimezoneOffset(),
                    'X-Selected-TimeZone-Offset': window.user?.timeZoneConverted
                },
                credentials: 'same-origin',
                body: JSON.stringify(data)
            })
                .then(res => {
                    try {
                        return res.json();
                    } catch (err) {
                        reject(err);
                    }
                })
                .then(
                    (result) => {
                        try {
                            if (result.status === 1) {
                                resolve(result);
                                return;
                            } else {
                                if (result.error.message == 'Unauthorized Access' && url.indexOf('adminNotifications') >= 0) {
                                    return;
                                }
                                if (result.error.message == 'Unauthorized Access') {
                                    reject(result);
                                    return;
                                }
                                reject(result);
                                return;
                            }
                        } catch (error) {
                            reject(error);
                        }
                        reject(result);
                    },
                    (error) => {
                        reject(error);
                    }
                ).catch(e => {
                    throw (e);
                });
        });
    }

    get(url) {
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: 'same-origin'
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        resolve(result);
                    },
                    (error) => {
                        reject(error);
                    }
                ).catch(e => {
                    throw (e);
                });
        });
    }

    adjustApplicationSize() {
        var width = window.innerWidth;
        var height = window.innerWidth;
        var mobile = false;
        this.width = width;
        this.height = height;

        if (width < 1024) {
            mobile = true;
        } else {
            mobile = false;
        }


        getStore().dispatch(
            {
                type: 'RESIZE',
                payload:
                {
                    mobile: mobile,
                    width: this.width,
                    height: this.height
                }
            });
    }

    render() {
        return <Context.Provider value={this.applicationContext}>
            {this.props.children}
            <Overlay ref={(r) => this.overlays = r} />
            <Modal ref={(r) => this.modals = r} />
            <Snackbars ref={(r) => this.snackbars = r} />
        </Context.Provider>;
    }
}

Application.defaultProps = {
    children: null
};

Application.propTypes = {
    children: PropTypes.node
};
