/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/display-name */

import './_search-events.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../button/Button';
import { lang } from '../../../lib/Localization/language';
import { API } from '../../../lib/API/Api';
import { SportEndpoints } from '../../../lib/API/Endpoints';
import { Table } from '../../table/Table';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { DateTime } from '../../datetime/DateTime';


export const SearchEvent = (props) => {
    const [selectedEvents, setSelectedEvents] = useState(props.events);
    const [event, setEvent] = useState(null);
    const [events, setEvents] = useState([]);
    const [selectedEvent, selectEvent] = useState(null);
    const [searching, setSearching] = useState(false);



    useEffect(() => {
        if (!selectedEvent) return;
        props.onEventSelected(selectedEvent);
    }, [selectedEvent]);

    const search = () => {
        setSearching(true);
        var payload = {
            name: event,
            startDate: DateHelpers.today()
        };

        if (props.sport) {
            payload.sportId = props.sport;
        }

        API.post(SportEndpoints.Events, payload).then((result) => {
            setSearching(false);
            setEvents(result?.result);
        });
    }

    return <flex className='vertical gap-10'>
        <flex className='vertical gap-5'>
            <label className='bold'>{lang('Search Event')}</label>
            <flex className='search-event gap-5'>
                <input type='text' className={searching ? 'disabled' : ''}
                    onChangeCapture={(e) => {
                        setEvent(e.target.value);
                    }}
                    onKeyUp={(e) => {
                        if (!event) return;
                        if (event.length < 3) return;
                        if (searching) return;
                        if (e.key == 'Enter') {
                            search();
                        }
                    }}
                />

                <flex className='align-right'>
                    <Button title='Search' className={searching ? 'disabled' : 'success'} onClick={() => {
                        if (!event) return;
                        if (event.length < 3) return;
                        if (searching) return;

                        search();
                    }} />
                </flex>
            </flex>
        </flex>
        <Table model={
            {
                fields: [
                    {
                        name: 'id',
                        title: 'Id'
                    },
                    {
                        name: 'date',
                        title: 'Date',
                        render: (val, row) => {
                            return <DateTime date={row.date} time />;
                        }
                    },
                    {
                        name: 'sport',
                        title: 'Sport'
                    },
                    {
                        name: 'fixture',
                        title: 'Event',
                        render: (val, row) => {
                            return <flex className='vertical gap-5 align-start'>
                                <span className='bold'>{row.name}</span>
                                <flex className='vertical'>
                                    <span>{row.region}</span>
                                    <span>{row.league}</span>
                                </flex>
                            </flex>;
                        }
                    }
                ]
            }
        } data={events ?? []} buttons={(row) => {
            if (selectedEvents.find(x => x.id == row.id)) {
                return <Button title='Remove' className={'warning'} onClick={() => {
                    var copy = selectedEvents.filter(x => x.id != row.id);
                    setSelectedEvents(copy);
                    props.onChangeEvents(copy);
                }} />;
            }
            return <Button title='Add' className={'success'} onClick={() => {
                var copy = [...selectedEvents];
                copy.push(row);
                setSelectedEvents(copy);
                props.onChangeEvents(copy);
            }} />;
        }} />
    </flex>;
};

SearchEvent.propTypes = {
    sport: PropTypes.number,
    events: PropTypes.array,
    onSearch: PropTypes.func,
    onEventSelected: PropTypes.func,
    onChangeEvents: PropTypes.func
};