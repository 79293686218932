import React, { useEffect, useState } from 'react';
import { FluxForm } from '../../../../components/form/FluxForm';
import { InputTypes } from '../../../../components/form/InputTypes';



const filterModel = {
    fields: [
        {
            name: 'Sports',
            title: 'Sports',
            type: InputTypes.Select,
            multiple: true,
            nullable: true,
            values: []
        },
        {
            name: 'Regions',
            title: 'Regions',
            type: InputTypes.Select,
            multiple: true,
            nullable: true,
            values: []
        },
        {
            name: 'Leagues',
            title: 'Leagues',
            type: InputTypes.Select,
            multiple: true,
            nullable: true,
            values: []
        },
        {
            name: 'Events',
            title: 'Events',
            type: InputTypes.Select,
            multiple: true,
            nullable: true,
            values: []
        },
        {
            name: 'BetType',
            title: 'Bet Type',
            type: InputTypes.Select,
            multiple: false,
            nullable: true,
            values: [
                {
                    title: 'Prematch',
                    value: 0,
                },
                {
                    title: 'Live',
                    value: 1,
                }
            ]
        },
        {
            name: 'TicketType',
            title: 'Ticket Types',
            type: InputTypes.Select,
            multiple: false,
            nullable: true,
            values: [
                {
                    title: 'Single',
                    value: 0,
                },
                {
                    title: 'Multi',
                    value: 1,
                }
            ]
        },
        {
            name: 'Stake',
            title: 'Stake',
            type: InputTypes.Number,
            between: true,
            value: {
                start: 0,
                end: 0
            }
        },
        {
            name: 'PossibleWin',
            title: 'Potential Win',
            type: InputTypes.Number,
            between: true,
            value: {
                start: 0,
                end: 0
            }
        },
        {
            name: 'Odds',
            title: 'Odds',
            type: InputTypes.Number,
            between: true,
            value: {
                start: 0,
                end: 0
            }
        },
        {
            name: 'Legs',
            title: 'Number of legs',
            type: InputTypes.Number,
            between: true,
            value: {
                start: 0,
                end: 0
            }
        },
        {
            name: 'Currency',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'enabledCurrencies',
                key: 'CurrencyCode',
                title: ['Name']
            }
        },
        {
            name: 'Group',
            title: 'Player Group',
            type: InputTypes.Select,
            nullable: true,
            multiple: true,
            data: {
                source: 'myPlayerGroups',
                key: 'Id',
                title: ['Name']
            }
        },
    ]
};

export const filteredNewTickets = (newTickets, filters) => {
    if (!filters) return newTickets;
    var copy = [...newTickets];
    if (filters.BetType != null) {
        copy = copy.filter(x => x.betType == filters.BetType);
    }
    if (filters.TicketType != null) {
        copy = copy.filter(x => x.ticketType == filters.TicketType);
    }
    if (filters.Currency) {
        copy = copy.filter(x => x.currency == filters.Currency);
    }
    if (filters.Sports) {
        copy = copy.filter(ticket => {
            const selections = Object.values(ticket.selections);
            if (selections) {
                return selections.some(selection => filters.Sports.includes(selection.sportId));
            }
            return true;
        });
    }

    if (filters.Regions) {
        copy = copy.filter(ticket => {
            const selections = Object.values(ticket.selections);
            if (selections) {
                return selections.some(selection => filters.Regions.includes(selection.regionId?.toString()));
            }
            return true;
        });
    }

    if (filters.Leagues) {
        copy = copy.filter(ticket => {
            const selections = Object.values(ticket.selections);
            if (selections) {
                return selections.some(selection => filters.Leagues.includes(selection.leagueId?.toString()));
            }
            return true;
        });
    }

    if (filters.Events) {
        copy = copy.filter(ticket => {
            const selections = Object.values(ticket.selections);
            if (selections) {
                return selections.some(selection => filters.Events.includes(selection.eventId) || filters.Events.includes(selection.eventExternalId));
            }
            return true;
        });
    }

    if (filters.Odds_min) {
        copy = copy.filter(x => x.odds >= filters.Odds_min);
    }
    if (filters.Odds_max) {
        copy = copy.filter(x => x.odds <= filters.Odds_max);
    }

    if (filters.PossibleWin_min) {
        copy = copy.filter(x => x.possibleWin >= filters.PossibleWin_min);
    }
    if (filters.PossibleWin_max) {
        copy = copy.filter(x => x.possibleWin <= filters.PossibleWin_max);
    }

    if (filters.Stake_min) {
        copy = copy.filter(x => x.amount >= filters.Stake_min);
    }
    if (filters.Stake_max) {
        copy = copy.filter(x => x.amount <= filters.Stake_max);
    }

    if (filters.Group) {
        copy = copy.filter(x => filters.Group.includes(x.playerGroup));
    }
    return copy;
};


export const TickerFilters = (props) => {
    const { visible, sports, regions, leagues, events, onFilter } = { ...props };

    const [model, setModel] = useState(null);

    useEffect(() => {
        var copy = { ...filterModel };

        copy.fields.find(x => x.name == 'Sports').values = Object.keys(sports).map(x => { return { title: x.split(':').reverse()[0], value: x.split(':')[0] }; });
        copy.fields.find(x => x.name == 'Regions').values = Object.keys(regions).map(x => { return { title: x.split(':').reverse()[0], value: x.split(':')[0] }; });
        copy.fields.find(x => x.name == 'Leagues').values = Object.keys(leagues).map(x => { return { title: x.split(':').reverse()[0], value: x.split(':')[0] }; });
        copy.fields.find(x => x.name == 'Events').values = Object.keys(events).map(x => { return { title: x.split(':').reverse()[0], value: x.split(':')[0] }; });
        setModel(copy);
    }, []);

    if (!visible) return <></>;

    if (!model) return <></>;
    return <flex className='vertical gap-10'>
        <FluxForm model={model} className='horizontal' columns={5} onSubmit={(data) => {
            onFilter(data);
        }} />
    </flex>;
};