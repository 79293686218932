/* eslint-disable react/display-name */
import React from 'react';
import { ReportEndpoints } from '../../../../v2/Lib/Api/Api';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { SearchModelHorseBettingReport, ResultModelHorseBettingReport } from '../../../lib/Models/HorseModels';

const ResultModel = () => {
    var fields = Object.assign([], ResultModelHorseBettingReport.fields);
    fields.splice(2, 0,
        { name: 'Venues', title: 'Venue' },
        { name: 'Distance' });
    return {
        fields: fields
    };
};

export const ReportDistanceHorseGreyhound = () => {
    return <FluxSearch
        model={SearchModelHorseBettingReport}
        endpoint={ReportEndpoints.HorseGreyHoundDistanceReport}
        resultModel={ResultModel()}
    />;
};