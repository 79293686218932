/* eslint-disable react/display-name */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SportTicketHistory } from './SportTicketHistory';
import { lang } from '../../../lib/Localization/language';
import { FluxCard } from '../../card/FluxCard';
import { SportsTicketStatus, SportsTicketTypes } from '../../../lib/Enums/BettingEnums';
import { Currency } from '../../currency/Currency';
import { Table } from '../../table/Table';
import { Country } from '../../icons/country/Country';
import { DateTime } from '../../datetime/DateTime';
import { Button } from '../../button/Button';
import { ManualCancel, ManualLost, ManualWin } from '../../../actions/HorseTicketActions';
import { Silk } from './Silk/Silk';
import { ordinalNumbers } from '../../../../v2/Lib/Common/numberHelpers';
import { FluxNotice } from '../../notification/FluxNotice';
import { NotificationType } from '../../notification/FluxNotification';

export const SelectionStatus = (props) => {
    const { status } = { ...props };
    switch (status) {
        case 0:
            return <span>{lang('Open')}</span>;
        case 2:
            return <span>{lang('Refund')}</span>;
        case 3:
            return <span>{lang('Lost')}</span>;
        case 4:
            return <span>{lang('Win')}</span>;
        case 8:
            return <span>{lang('Cashout')}</span>;
        case 9:
            return <span>{lang('Cancel')}</span>;
        default:
            return <span>{status}</span>;
    }
};


const IsActionEnabled = (ticketStatus, status) => {
    var converted = SportsTicketStatus[status];
    var convertedTicketStatus = SportsTicketStatus[ticketStatus];
    if (converted == convertedTicketStatus) return false;
    // cash outs are not allowed
    if (ticketStatus == 3) return false;
    if (ticketStatus == 0) return true;
    switch (converted) {
        case 'Win':
            if (convertedTicketStatus == 'Lost') return true;
            if (convertedTicketStatus == 'Cancel') return true;
            if (convertedTicketStatus == 'Cashback') return true;
            if (convertedTicketStatus == 'Bet Cancel') return true;
            if (convertedTicketStatus == 'Bet Cancel') return true;
            return false;
        case 'Lost':
            if (convertedTicketStatus == 'Win') return true;
            if (convertedTicketStatus == 'Cancel') return true;
            if (convertedTicketStatus == 'Cashback') return true;
            if (convertedTicketStatus == 'Bet Cancel') return true;
            if (convertedTicketStatus == 'Bet Cancel') return true;
            return false;
        case 'Open':
        default:
            return true;
    }
}



const countries = {
    UK: 'United Kingdom',
    MX: 'Mexico',
    IE: 'Ireland',
    ES: 'Spain',
    US: 'USA',
    IT: 'Italy',
    AU: 'Australia',
    DE: 'Germany',
    UY: 'Uruguay',
    SA: 'South Africa',
    HU: 'Hungary',
    MU: 'Mauritius',
    CL: 'Chile',
    AR: 'Argentina',
    PA: 'Paraguay',
    CA: 'Canada',
    world: lang('All'),
};

export const HorseTicket = (props) => {
    const [ticket, setTicket] = useState(props.ticket);
    const selections = props.selections;
    const events = props.events ?? {};

    if (!props.ticket) return <FluxNotice type={NotificationType.Error} title='Unable to load ticket' description={'Ticket not loaded, please contact with support!'} />;

    var ticketBets = JSON.parse(props.ticket.bets);
    var bets = [];

    ticketBets.Summary.split('~').map(x => {
        var betData = x.split('|');
        var event = events.find(y => y.eventKey.toString() == betData[0]);
        bets.push({
            Date: betData[5],
            EventId: betData[0],
            Region: countries[betData[1]],
            Venue: betData[2],
            EventName: event.name,
            Event: event
        });
    });


    return <flex className='vertical gap-20'>
        <SportTicketHistory history={props.history} currency={props.ticket.currency} />
        {ticket.postponePayment && <FluxNotice type='warning' title='Investiged ticket' description={`Ticket is flagged as investigated. ${ticket.investigationLevels ?? ''}`} />}
        <FluxCard title={
            <React.Fragment>
                <span>{SportsTicketTypes[ticket.ticketType]}</span>
                <flex className='gap-20 align-right'>
                    {ticket.win > 0 &&
                        <flex className='gap-5'>
                            <label>{lang('Return')}</label>
                            <span><Currency amount={ticket.win} sign={ticket.currency} /></span>
                            {(ticket.exchangeRate != 0 && ticket.exchangeRate != 1) && <span><Currency amount={ticket.win * ticket.exchangeRate} sign={ticket.exchangeCurrency} /></span>}
                        </flex>
                    }
                    <span>{SportsTicketStatus[ticket.status]}</span>
                </flex>
            </React.Fragment>
        }>
            <flex className='vertical gap-20 fit-children'>
                <flex className='columns-4 gap-20'>
                    <flex className='vertical'>
                        <label>{lang('Entry Date')}</label>
                        <DateTime date={ticket.entryDate} time />
                    </flex>
                    <flex className='vertical'>
                        <label>{lang('Stake')}</label>
                        <span><Currency amount={ticket.bet} sign={ticket.currency} /></span>
                        {(ticket.exchangeRate != 0 && ticket.exchangeRate != 1) && <span><Currency amount={ticket.bet * ticket.exchangeRate} sign={ticket.exchangeCurrency} /></span>}
                    </flex>
                    <flex className='vertical'>
                        <label>{lang('Odds')}</label>
                        <span>{ticket.odds.toFixed(2)}x</span>
                    </flex>
                    <flex className='vertical'>
                        <label>{lang('Possible Win')}</label>
                        <span><Currency amount={ticket.possibleWin} sign={ticket.currency} /></span>
                        {(ticket.exchangeRate != 0 && ticket.exchangeRate != 1) && <span><Currency amount={ticket.possibleWin * ticket.exchangeRate} sign={ticket.exchangeCurrency} /></span>}
                    </flex>
                    <flex className='gap-10 justify-content-end'>
                        <flex className='gap-5'>
                            <Button title='Win' className={IsActionEnabled(ticket.status, 1) ? ' success' : 'disabled'} onClick={() => {
                                ManualWin(ticket, (newTicket) => {
                                    setTicket(newTicket);
                                    if (props.onChange) {
                                        props.onChange(newTicket);
                                    }
                                });
                            }} />
                            <Button title='Lost' className={IsActionEnabled(ticket.status, 2) ? ' warning' : 'disabled'} onClick={() => {
                                ManualLost(ticket, (newTicket) => {
                                    setTicket(newTicket);
                                    if (props.onChange) {
                                        props.onChange(newTicket);
                                    }
                                });
                            }} />
                        </flex>
                        <Button title='Cancel' className={IsActionEnabled(ticket.status, 1) ? ' warning' : 'disabled'} onClick={() => {
                            ManualCancel(ticket, (newTicket) => {
                                setTicket(newTicket);
                                if (props.onChange) {
                                    props.onChange(newTicket);
                                }
                            });
                        }} />
                    </flex>
                </flex>
                <Table className='fx-borders fx-shadow' model={{
                    fields: [
                        {
                            name: 'Date',
                            title: 'Event Date',
                            render: (val, row) => {
                                return <flex className='vertical'>
                                    <DateTime date={val} time vertical />
                                </flex>;
                            }
                        },
                        {
                            name: 'OffTime',
                            title: 'Off Time',
                            // eslint-disable-next-line react/display-name
                            render: (val, row) => {
                                var event = row.Event;
                                if (!event) return;
                                return <flex className='flex gap-5'>
                                    {event.offTime}
                                </flex>;
                            }
                        },
                        {
                            name: 'EventId',
                            title: 'Event Id',
                            render: (val, row) => {
                                return <flex className='vertical'>
                                    <span>{val}</span>
                                    <span>{row.Event.raceType == 0 ? 'Horse' : 'Greyhound'}</span>
                                </flex>;
                            }
                        },

                        {
                            name: 'Venue',
                            title: 'Venue',
                            render: (val, row) => {
                                return <flex className='gap-10'>
                                    <Country name={row.Region} circular />
                                    <flex className='vertical'>
                                        <span>{row.Venue}</span>
                                    </flex>
                                </flex>;
                            }
                        },
                        {
                            name: 'EventName',
                            title: 'Event',
                            render: (val, row) => {
                                var event = row.Event;
                                var forecast = null;
                                var tricast = null;
                                if (event.resultPrices) {
                                    forecast = event.resultPrices.find(x => x.type == 0);
                                    tricast = event.resultPrices.find(x => x.type != 0);
                                }

                                return <flex className='vertical gap-5'>
                                    <span>{val}</span>
                                    <flex className='vertical'>
                                        {forecast && <flex className='gap-5'>
                                            <span className='bold'>{lang('Forecast')}</span>
                                            <span>{forecast.price.toFixed(2)}</span>
                                        </flex>}
                                        {tricast && <flex className='gap-5'>
                                            <span className='bold'>{lang('Tricast')}</span>
                                            <span>{tricast.price.toFixed(2)}</span>
                                        </flex>}
                                    </flex>
                                </flex>
                            }
                        },
                        {
                            name: 'Bets',
                            title: 'Bets',
                            render: (val, row) => {
                                var event = row.Event;
                                return <flex className='vertical'>
                                    {selections.map(x => {
                                        var runner = event.runners.find(y => y.id == x.Id);
                                        if (!runner) return;
                                        return <flex className='gap-5' key={x.Id}>
                                            <Silk competitor={runner} raceType={event.raceType} />
                                            <flex className='vertical'>
                                                <span>{runner.name}</span>
                                                <flex className='gap-10'>
                                                    {x.Place && <span className='bold'>{ordinalNumbers[x.Place]}</span>}
                                                    <span>{runner.priceDecimal.toFixed(2)}</span>
                                                </flex>
                                            </flex>
                                        </flex>;
                                    })}
                                </flex>;
                            }
                        },
                        {
                            name: 'Forecast',
                            title: 'Forecast',
                            render: (val, row) => {
                                var event = row.Event;
                                if (!event) return;
                                if (!event.resultPrices) return;
                                return <flex className='result-prices gap-10'>
                                    {
                                        event.resultPrices.map((p, index) => {
                                            if (p.type !== 0) return;
                                            return <flex className='vertical result-types' key={index}>
                                                <flex className='vertical'>{p.betPlaces.sort((a, b) => { return a.place - b.place; }).map(betPlace => {
                                                    const competitor = event.runners.find(r => r.id == betPlace.runnerId);
                                                    return <div key={betPlace.runnerId} className='flex gap-5'>
                                                        {!props.small && <Silk competitor={competitor} raceType={event.raceType} />}
                                                        {!props.small && <span className='primary'>{competitor.name}</span>}
                                                        {props.small && <React.Fragment><span className='light'>{competitor.number}</span><span className='primary'>{competitor.name}</span></React.Fragment>}
                                                    </div>;
                                                })}
                                                </flex>
                                            </flex>;
                                        })
                                    }
                                </flex>;
                            }
                        },
                        {
                            name: 'Tricast',
                            title: 'Tricast',
                            render: (val, row) => {
                                var event = row.Event;
                                if (!event) return;
                                if (!event.resultPrices) return;
                                return <flex className='result-prices gap-10'>
                                    {
                                        event.resultPrices.map((p, index) => {
                                            if (p.type === 0) return;
                                            return <flex className='vertical result-types' key={index}>
                                                <flex className='vertical'>{p.betPlaces.sort((a, b) => { return a.place - b.place; }).map(betPlace => {
                                                    const competitor = event.runners.find(r => r.id == betPlace.runnerId);
                                                    return <div key={betPlace.runnerId} className='flex gap-5'>
                                                        {!props.small && <Silk competitor={competitor} raceType={event.raceType} />}
                                                        {!props.small && <span className='primary'>{competitor.name}</span>}
                                                        {props.small && <React.Fragment><span className='light'>{competitor.number}</span><span className='primary'>{competitor.name}</span></React.Fragment>}
                                                    </div>;
                                                })}
                                                </flex>
                                            </flex>;
                                        })
                                    }
                                </flex>;
                            }
                        },
                        {
                            name: 'NonRunners',
                            title: 'Non Runners',
                            render: (val, row) => {
                                var event = row.Event;
                                if (!event) return;
                                const nonRunners = event?.runners.filter(x => x.status == 'N') ?? [];
                                return <flex className='vertical'>
                                    {
                                        nonRunners.map(x => {
                                            return <flex key={x.id} className='gap-5'>
                                                <Silk competitor={x} raceType={event.raceType} />
                                                <span>{x.name}</span>
                                            </flex>;
                                        })
                                    }
                                </flex>;
                            }
                        }
                    ],
                    onRenderClassName: (row, className) => {
                        switch (row.Status) {
                            case 0:
                                return className;
                            case 3:
                                return 'bet-selection-lost';
                            case 4:
                                return 'bet-selection-win';
                            case 8:
                                return 'bet-selection-cashout';
                            case 9:
                                return 'bet-selection-cancel';
                        }
                        return className;
                    }
                }} data={bets} />
            </flex>
        </FluxCard>
    </flex>;
};

HorseTicket.propTypes = {
    history: PropTypes.object,
    ticket: PropTypes.object,
    selections: PropTypes.array,
    events: PropTypes.object,
    onChange: PropTypes.func
};