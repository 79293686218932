import React from 'react';

import { Modal } from '../components/application/FluxApp';
import { FluxForm } from '../components/form/FluxForm';
import { FluxModal } from '../components/modal/FluxModal';
import { InputTypes } from '../components/form/InputTypes';
import { API } from '../lib/API/Api';
import { POSEndpoints, } from '../lib/API/Endpoints';
import { toSnakeCase } from '../../components/Centrum/helpers';
import { isNullOrEmpty } from '../lib/Helpers/StringHelper';


const providerBindingModel = {
    fields: [
        {
            name: 'ProviderId',
            title: 'Provider',
            type: InputTypes.Select,
            nullable: true,
            multiple: true,
            data: {
                source: 'providers/payment',
                key: 'id',
                title: ['display'],
                parser: (result) => {
                    var results = [];
                    var group = 'Gaming';
                    result.result.map(x => {
                        x = toSnakeCase(x);
                        if (x.type == 128) group = 'Payment Provider';
                        results.push({
                            value: x.id, title: x.display, group: {
                                id: group,
                                title: group
                            }
                        });
                    });
                    return results;
                }
            }
        },
    ]
};


const transactionModel = {
    fields: [
        {
            name: 'Amount',
            title: 'Amount',
            required: true,
            type: InputTypes.Number
        },
        {
            name: 'Notes',
            title: 'Notes',
            type: InputTypes.Textarea
        }
    ]
};


export const depositPOSUser = (user, handler) => {
    const confirmed = (data) => {
        var payload = {
            amount: data.Amount,
            notes: data.Notes,
            userId: user.Id
        };
        API.post(POSEndpoints.POSDeposit, payload, 'please wait', 'unable to create transaction').then((result) => {
            Modal.close();
            handler(result.result);
        });
    };
    Modal.open(
        <FluxModal title={'Deposit'} onConfirm={confirmed}>
            <FluxForm className='tab-next'
                submitButtonTitle='Deposit'
                model={transactionModel}
                onSubmit={(data) => {
                    confirmed(data);
                }}
                onCancel={() => Modal.close()}>
            </FluxForm>
        </FluxModal>
    );
};

export const withdrawPOSUser = (user, handler) => {
    const confirmed = (data) => {
        var payload = {
            amount: data.Amount,
            notes: data.Notes,
            userId: user.Id
        };
        API.post(POSEndpoints.POSWithdraw, payload, 'please wait', 'unable to create transaction').then((result) => {
            Modal.close();
            handler(result.result);
        });
    };
    Modal.open(
        <FluxModal title={'Withdraw'} onConfirm={confirmed}>
            <FluxForm className='tab-next'
                submitButtonTitle='Withdraw'
                model={transactionModel}
                onSubmit={(data) => {
                    confirmed(data);
                }}
                onCancel={() => Modal.close()}>
            </FluxForm>
        </FluxModal>
    );
};


export const setupPOSBindings = (user, handler) => {
    const confirmed = (data) => {

        var payload = {
            userId: user.Id,
            providers: data.ProviderId ? data.ProviderId.join(',') : ''
        };
        API.post(POSEndpoints.POSProviderBindings, payload, 'please wait', 'unable to create transaction').then((result) => {
            Modal.close();
            handler(result.result);
        });
    };

    var providers = [];
    try {
        if (user.Bindings && !isNullOrEmpty(user.Bindings)) {
            var converted = JSON.parse(user.Bindings);
            var list = converted.providers;
            list.map(x => {
                providers.push(x.id);
            });
        }
    } catch (err) {
        //
        console.log(err);
    }

    Modal.open(
        <FluxModal title={'Provider Bindings'} onConfirm={confirmed}>
            <FluxForm className='tab-next'
                data={{
                    ProviderId: providers
                }}
                submitButtonTitle='Save'
                model={providerBindingModel}
                onSubmit={(data) => {
                    confirmed(data);
                }}
                onCancel={() => Modal.close()}>
            </FluxForm>
        </FluxModal>
    );
};









export const depositPOSPlayer = (player, handler) => {
    const confirmed = (data) => {
        var payload = {
            amount: data.Amount,
            notes: data.Notes,
            userId: player.Id
        };
        API.post(POSEndpoints.POSDepositPlayer, payload, 'please wait', 'unable to create transaction').then((result) => {
            Modal.close();
            handler(payload, result.result);
        });
    };
    Modal.open(
        <FluxModal title={'Deposit'} onConfirm={confirmed}>
            <FluxForm className='tab-next'
                submitButtonTitle='Deposit'
                model={transactionModel}
                onSubmit={(data) => {
                    confirmed(data);
                }}
                onCancel={() => Modal.close()}>
            </FluxForm>
        </FluxModal>
    );
};

export const withdrawPOSPlayer = (player, handler) => {
    const confirmed = (data) => {
        var payload = {
            amount: data.Amount,
            notes: data.Notes,
            userId: player.Id
        };
        API.post(POSEndpoints.POSWithdrawPlayer, payload, 'please wait', 'unable to create transaction').then((result) => {
            Modal.close();
            handler(payload, result.result);
        });
    };
    Modal.open(
        <FluxModal title={'Withdraw'} onConfirm={confirmed}>
            <FluxForm className='tab-next'
                submitButtonTitle='Withdraw'
                model={transactionModel}
                onSubmit={(data) => {
                    confirmed(data);
                }}
                onCancel={() => Modal.close()}>
            </FluxForm>
        </FluxModal>
    );
};
