import './_event-selection.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ActionButton, FluxCard } from '../../../../../components/card/FluxCard';
import { SportsTree } from '../../../../../components/sports/SportsTree/SportsTree';
import { Button } from '../../../../../components/button/Button';
import { lang } from '../../../../../lib/Localization/language';
import { Icon } from '../../../../../components/icon/Icon';


export const EventSelection = (props) => {
    const [event, selectEvent] = useState(null);
    const [events, setEvents] = useState(props.configuration?.Configuration?.sport?.enabledEvents ?? {});

    useEffect(() => {
        var copy = { ...props.configuration };
        if (!copy.Configuration.sport) {
            copy.Configuration.sport = {
                enabledEvents: {}
            };
        }
        copy.Configuration.sport.enabledEvents = events;
        props.onChange(copy);
    }, [events]);


    const add = () => {
        var copy = { ...events };
        copy[event.id] = {
            id: event.id,
            name: event.name,
            date: event.date,
            region: {
                id: event.regionId,
                name: event.region
            },
            sport: {
                id: event.sportId,
                name: event.sport
            },
            league: {
                id: event.leagueId,
                name: event.league
            }
        };
        setEvents(copy);
    };

    const remove = (l) => {
        var copy = { ...events };
        delete copy[l.id];
        setEvents(copy);
    };


    return <flex className='gap-10 promotion-sport-rules-league-selection align-items-stretch fit-children'>
        <FluxCard title='Select League' className={'league-selection-card no-padding'}>
            <SportsTree regions leagues events onRenderEventOptions={(x) => {
                if (events[x.id]) {
                    return <ActionButton title='Remove' icon='x' onClick={() => remove(x)} />;
                }
            }} onEventSelected={(x) => {
                selectEvent(x);
            }} />
        </FluxCard>
        <FluxCard className={'promotion-sport-selection-rules no-padding'} title='Selected Leagues'>
            <flex className='vertical gap-10'>
                {
                    event &&
                    <flex className='padding gap-5 border-bottom'>
                        <flex className='gap-5'>
                            <label className='medium'>{event.sport}</label>
                            <Icon icon='chevron-right' size='xs' />
                            <label className='medium'>{event.region}</label>
                            <Icon icon='chevron-right' size='xs' />
                            <label className='medium'>{event.league}</label>
                            <Icon icon='chevron-right' size='xs' />
                            <label className='medium'>{new Date(event.date).toLocaleString('en-Gb', { hours12: false })}</label>
                            <Icon icon='chevron-right' size='xs' />
                            <label className='medium'>{event.name}</label>
                        </flex>
                        <Button title='Add' className={'align-right success'} onClick={() => add()} />
                    </flex>
                }
                {
                    !event &&
                    <flex className='padding gap-5 border-bottom'>
                        <label className='medium'>{lang('Please select events from the tree')}</label>
                        <Button title='Add' className={'align-right disabled'} />
                    </flex>
                }
                <flex className='padding border-bottom'>
                    <label className='medium'>{lang('Selected Events')}</label>
                    {Object.keys(events).length > 0 &&
                        <flex className='align-right'>
                            <label>{lang('%1 events selected', [Object.keys(events).length])}</label>
                        </flex>
                    }
                </flex>
                <fluxList>
                    <items>
                        {Object.values(events).map(x => {
                            return <item key={x}>
                                <flex className='gap-10'>
                                    <span>{x.sport.name}</span>
                                    <Icon icon='chevron-right' size='xs' />
                                    <span>{x.region.name}</span>
                                    <Icon icon='chevron-right' size='xs' />
                                    <span>{new Date(x.date).toLocaleString('en-Gb', { hours12: false })}</span>
                                    <Icon icon='chevron-right' size='xs' />
                                    <span>{x.name}</span>
                                    <flex className='align-right'>
                                        <Button title='Remove' className='warning' onClick={() => remove(x)} />
                                    </flex>
                                </flex>
                            </item>;
                        })}
                    </items>
                </fluxList>
            </flex>
        </FluxCard >
    </flex >;
};

EventSelection.propTypes = {
    configuration: PropTypes.object,
    onChange: PropTypes.func
};



