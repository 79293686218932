import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { hasAccess } from '../../lib/ActionManager/FluxActions';


export const FluxAction = (props) => {
    const selectedMenuAction = useSelector((state) => state.flux.menuAction);
    const [enabled, setEnabled] = useState(false);
    const active = selectedMenuAction?.type == props.action;

    useEffect(() => {
        if (!hasAccess(props.action)) {
            setEnabled(false);
            return;
        }
        setEnabled(true);
    }, []);


    if (!enabled) return <></>;
    if (!active && props.checkActive) return <></>;

    return props.children;
};

FluxAction.defaultProps = {
    checkActive: true
};

FluxAction.propTypes = {
    checkActive: PropTypes.bool,
    action: PropTypes.string,
    children: PropTypes.node
};