/* eslint-disable react/display-name */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ActionTypes } from '../../../lib/ActionManager/FluxActions';
import { useSelector } from 'react-redux';
import { lang } from '../../../lib/Localization/language';
import { FluxAction } from '../../../components/application/FluxAction';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { Icon } from '../../../components/icon/Icon';
import { AffiliateEndpoints, POSEndpoints, RetailEndpoints } from '../../../lib/API/Endpoints';
import { InputTypes } from '../../../components/form/InputTypes';
import { Currency } from '../../../components/currency/Currency';
import { depositPOSPlayer, withdrawPOSPlayer } from '../../../actions/POSTransactionActions';


export const ModelSearchPlayer = {
    name: 'search-players',
    fields: [
        {
            name: 'Id',
            max: 5,
            type: InputTypes.Number
        },
        {
            name: 'Code',
            type: InputTypes.Text,
            exact: true,
        },
        {
            name: 'Username',
            type: InputTypes.Text,
            exact: false
        },
        {
            name: 'Name',
            type: InputTypes.Text,
            exact: false
        },
        {
            name: 'Surname',
            type: InputTypes.Text,
            exact: false
        },
        {
            name: 'IdNumber',
            title: 'Id / Passport',
            type: InputTypes.Text,
            hidden: true,
            exact: true
        },
        {
            name: 'Phone',
            title: 'Phone',
            type: InputTypes.Text
        }
    ]
};


export const ModelPlayerSummary = {
    fields: [

        {
            name: 'Id',
            type: InputTypes.Number,
            identity: true,
        },
        {
            name: 'Username',
            title: 'Username',
            type: InputTypes.Text
        },
        {
            name: 'Code',
            type: InputTypes.Text,
        },
        {
            name: 'Name',
            type: InputTypes.Text
        },
        {
            name: 'Surname',
            type: InputTypes.Text
        },
        {
            name: 'Phone',
            type: InputTypes.Text
        },
        {

            name: 'Balance',
            type: InputTypes.Number,
            render: (val, data) => {
                return <Currency amount={val} sign={data.Currency} />;
            }
        }
    ]
};


export const SearchPOSPlayers = (props) => {
    const [title, setTitle] = useState('Search players');
    const [endpoint, setEndpoint] = useState(POSEndpoints.POSSearchPlayers);
    const [actions, setActions] = useState(ActionTypes.POSSearchPlayers);
    const user = useSelector((state) => state.user).loginInformation;


    useEffect(() => {
        if (props.affiliate) {
            setTitle(<React.Fragment><span>{lang('Affiliate')}</span><Icon icon='chevron-right' type='fa-light' /><span>{props.affiliate.Name}</span><Icon icon='chevron-right' type='fa-light' /><span>{lang('Search Players')}</span></React.Fragment>);

            // this component is used by affiliate
            if (user.AffiliateId > 0) {
                setEndpoint(AffiliateEndpoints.ListAffiliatePlayers);
            }
            return;
        }
        if (props.retail) {
            setTitle(<React.Fragment><span>{lang('Retail')}</span><Icon icon='chevron-right' type='fa-light' /><span>{props.retail.Name}</span><Icon icon='chevron-right' type='fa-light' /><span>{lang('Search Players')}</span></React.Fragment>);
            setEndpoint(`${RetailEndpoints.ListRetailPlayers}/${props.retail.Id}`);
            setActions(ActionTypes.ListRetailPlayers);
            return;
        }
    }, []);

    const prepareModel = () => {
        var copy = { ...ModelSearchPlayer };
        return copy;
    };

    const prepareResultModel = () => {
        var copy = { ...ModelPlayerSummary };
        return copy;
    };



    const onActionClicked = (action, player) => {
        switch (action.type) {
            case ActionTypes.POSDepositPlayer:
                depositPOSPlayer(user, () => {

                });
                break;
            case ActionTypes.POSWithdrawPlayer:
                withdrawPOSPlayer(user, () => {

                });
                break;

            default:
                console.log(action.type, player);
                break;
        }
    };


    return <FluxAction action={ActionTypes.POSSearchPlayers} checkActive={false}>
        <FluxSearch
            model={prepareModel()}
            resultModel={prepareResultModel()}
            endpoint={endpoint}
            actions={actions}
            title={title}
            exportToExcel={false}
            exportAllData={false}
            onActionClicked={onActionClicked.bind(this)} />
    </FluxAction>;
};

SearchPOSPlayers.propTypes = {
    affiliate: PropTypes.object,
    retail: PropTypes.object
};