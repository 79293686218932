import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { FluxCard } from '../../../components/card/FluxCard';
import { FluxForm } from '../../../components/form/FluxForm';
import { InputTypes } from '../../../components/form/InputTypes';
import { ToggleButton } from '../../../components/toggleButton/ToggleButton';
import { Icon } from '../../../components/icon/Icon';
import { Tip } from '../../../components/tip/Tip';
import { RetailCasinoProviderLimits } from './RetailCasinoProviderLimits';
import { RetailCasinoDisabledProviders } from './RetailCasinoDisabledProviders';
import { RetailGameLimits } from './RetailGameLimits';
import { RetailDisabledGames } from './RetailDisabledGames';
import { Button } from '../../../components/button/Button';
import { Modal } from '../../../components/application/FluxApp';
import { ConfirmationModal } from '../../../components/modal/ConfirmationModal/ConfirmationModal';
import { FluxNotice } from '../../../components/notification/FluxNotice';
import { NotificationType } from '../../../components/notification/FluxNotification';
import { API } from '../../../lib/API/Api';
import { RetailEndpoints } from '../../../lib/API/Endpoints';
import { Popper } from '../../../components/notification/FluxPopper';



const calculateCasinoPercentages = (parentConfiguration, config) => {
    if (!config) return;
    if (!config.casino) return;
    // calculate casino percentages
    const casinoPercentage = config.casino.percentage;
    config.casino.revenueLimits.map(x => {
        if (!parentConfiguration?.casino?.revenueLimits) {
            x.calculatedPercentage = x.percentage;
        } else {
            const parentConfig = parentConfiguration.casino.revenueLimits.find(y => y.type == x.type);
            const percentage = parentConfig.calculatedPercentage ?? parentConfig.percentage;
            if (x.percentage == 0) {
                x.calculatedPercentage = (percentage * casinoPercentage) / 100;
            } else {
                x.calculatedPercentage = (percentage * casinoPercentage) / 100;
                x.calculatedPercentage *= x.percentage / 100;
            }
        }
    });

    config.casino.providerLimits.map(x => {
        if (!parentConfiguration?.casino?.providerLimits) {
            x.calculatedPercentage = x.percentage;
        } else {
            const parentConfig = parentConfiguration.casino.providerLimits.find(y => y.id == x.id);
            const percentage = parentConfig.calculatedPercentage ?? parentConfig.percentage;
            if (x.percentage == 0) {
                x.calculatedPercentage = (percentage * casinoPercentage) / 100;
            } else {
                x.calculatedPercentage = (percentage * casinoPercentage) / 100;
                x.calculatedPercentage *= x.percentage / 100;
            }
        }
    });

    config.casino?.gameLimits?.map(x => {
        if (!parentConfiguration?.casino?.gameLimits) {
            x.calculatedPercentage = x.percentage;
        } else {
            const parentConfig = parentConfiguration.casino.gameLimits.find(y => y.id == x.id);
            const percentage = parentConfig.calculatedPercentage ?? parentConfig.percentage;
            if (x.percentage == 0) {
                x.calculatedPercentage = (percentage * casinoPercentage) / 100;
            } else {
                x.calculatedPercentage = (percentage * casinoPercentage) / 100;
                x.calculatedPercentage *= x.percentage / 100;
            }
        }
    });
};


const calculateSportAndHorseLimits = (parentConfiguration, target, config) => {
    if (!config) return;
    if (!config[target]) return;
    const targetPercentage = config[target].percentage;
    config[target].revenueLimits?.map(x => {
        if (!parentConfiguration || !parentConfiguration[target]?.revenueLimits) {
            x.calculatedPercentage = x.percentage;
        } else {
            const parentConfig = parentConfiguration[target].revenueLimits.find(y => y.type == x.type);
            const percentage = parentConfig.calculatedPercentage ?? parentConfig.percentage;
            if (x.percentage == 0) {
                x.calculatedPercentage = (percentage * targetPercentage) / 100;
            } else {
                x.calculatedPercentage = (percentage * targetPercentage) / 100;
                x.calculatedPercentage *= x.percentage / 100;
            }
        }
    });
};



const prepareCasinoLimits = (copy) => {
    if (!copy.casino.revenueLimits) {
        copy.casino.revenueLimits = [];
    }
    if (copy.casino.revenueLimits.length == 0) {
        copy.casino.revenueLimits = [
            {
                type: 0,
                minBet: 0,
                maxBet: 0,
                percentage: 0,
                enabled: false
            },
            {
                type: 1,
                minBet: 0,
                maxBet: 0,
                percentage: 0,
                enabled: false
            }
        ];
    }

    var slotLimits = copy.casino.revenueLimits.find(x => x.type == 0);
    var casinoLimits = copy.casino.revenueLimits.find(x => x.type == 1);

    if (!slotLimits) {
        slotLimits = {
            type: 0,
            minBet: 0,
            maxBet: 0,
            percentage: 0,
            enabled: false
        };
    }

    if (!casinoLimits) {
        casinoLimits = {
            type: 1,
            minBet: 0,
            maxBet: 0,
            percentage: 0,
            enabled: true
        };
    }


    copy.casino.revenueLimits = [slotLimits, casinoLimits];


    return copy;
};


const evaluateConfiguration = (configuration, parent) => {
    if (!parent) return configuration;
    if (parent.enableTransferFundsToAgents !== undefined && parent.enableTransferFundsToAgents === false) {
        configuration.enableTransferFundsToAgents = false;
    }

    return configuration;
};

const checkReadOnly = (target, parent) => {
    if (!parent) return false;

    if (parent[target] === undefined) return false;
    if (parent[target] === false) {
        return true;
    }
    return false;
};

export const RetailConfiguration = (props) => {
    const retail = props.retail;
    const [ready, setReady] = useState(false);
    const [configuration, setConfiguration] = useState(null);

    const [parentConfiguration, setParentConfiguration] = useState(null);

    useEffect(() => {
        var c = {};
        try {
            c = JSON.parse(retail.Configuration);
        } catch (err) {
            //
        }

        try {
            var copy = { ...c };

            if (copy.enableTransferFundsToAgents === undefined) {
                copy.enableTransferFundsToAgents = true;
            }

            if (!copy.sport) {
                copy.sport = {
                    enabled: false,
                    revenueType: 0,
                    percentage: 0,
                    deductBonus: false
                };
            }
            if (!copy.casino) {
                copy.casino = {
                    enabled: false,
                    revenueType: 0,
                    percentage: 0,
                    deductBonus: false,
                    providerLimits: [],
                    revenueLimits: [],
                    gameLimits: [],
                    disabledProviders: [],
                    disabledGames: []
                };
            }
            if (!copy.horse) {
                copy.horse = {
                    enabled: false,
                    revenueType: 0,
                    percentage: 0,
                    deductBonus: false
                };
            }
            if (!copy.payment) {
                copy.payment = { enabled: false };
            }

        } catch (err) {
            console.log(err);
        }
        const finalize = () => {
            prepareCasinoLimits(copy);
            setConfiguration(copy);
            setReady(true);
        };



        if (retail.ParentRetailId > 0) {
            API.post(`${RetailEndpoints.GetRetailConfiguration}/${retail.ParentRetailId}`, null, 'fetching please wait', 'unable to get retail').then((result) => {
                var parentConfigurationConverted = JSON.parse(result.result);
                evaluateConfiguration(copy, parentConfigurationConverted);
                setParentConfiguration(parentConfigurationConverted);
                finalize();
            });
        } else {
            finalize();
        }
    }, []);

    useEffect(() => {
        if (!configuration) return;
    }, [configuration]);

    useEffect(() => {
        if (!parentConfiguration) return;
    }, [parentConfiguration]);



    const retailRightsModel = () => {
        return {
            fields: [
                { name: 'subRetails', title: 'Enable Creating Sub Retails', type: InputTypes.Bool, readonly: checkReadOnly('subRetails', parentConfiguration) },
                { name: 'subUsers', title: 'Enable Creating Sub Users', type: InputTypes.Bool, readonly: checkReadOnly('subUsers', parentConfiguration) },
                { name: 'enableTransferFundsToAgents', title: 'Enable Transfer Funds to sub retails', type: InputTypes.Bool, readonly: checkReadOnly('enableTransferFundsToAgents', parentConfiguration) },
                { name: 'invitationCode', title: 'Enable Invitation Codes to register players', type: InputTypes.RandomNumber, size: 6, suffix: `-${retail.Id}`, readonly: false },
            ]
        };
    };


    const update = (target, payload) => {
        var copy = { ...configuration };
        copy[target] = payload;
        checkRulesAndSetConfiguration(copy);
    };


    const checkRulesAndSetConfiguration = (c) => {
        evaluateConfiguration(c, parentConfiguration);
        setConfiguration(c);
    };

    if (!ready) return <></>;

    return <flex className='vertical gap-10'>
        <FluxCard title='Retail Rights' >
            <FluxForm
                buttons={null}
                columns={2}
                model={retailRightsModel()} data={configuration} onChange={(d) => {
                    var copy = { ...configuration };
                    copy.subRetails = d.subRetails;
                    copy.subUsers = d.subUsers;
                    copy.enableTransferFundsToAgents = d.enableTransferFundsToAgents;
                    copy.invitationCode = d.invitationCode;

                    checkRulesAndSetConfiguration(copy);
                }} />
        </FluxCard>
        <FluxCard title='Payment Gateways'
            buttons={<flex className='gap-10 align-right'>
                <ToggleButton value={configuration.payment?.enabled} onChange={(value) => {
                    var copy = { ...configuration };
                    copy.payment.enabled = value;
                    checkRulesAndSetConfiguration(copy);
                }} />
            </flex>}>
            <flex className={configuration.payment?.enabled ? '' : 'disabled'}>
                <Tip tip='Players can deposit using payment gateways if this option is enabled.' />
            </flex>
        </FluxCard>
        <FluxCard title='Sports'
            buttons={<flex className='gap-10 align-right'>
                <ToggleButton value={configuration.sport?.enabled} onChange={(value) => {
                    var copy = { ...configuration };
                    copy.sport.enabled = value;
                    checkRulesAndSetConfiguration(copy);
                }} />
            </flex>}>
            <FluxForm
                className={configuration.sport?.enabled ? '' : 'disabled'}
                onChange={(data) => {
                    update('sports', data);
                }}
                buttons={null}
                columns={3}
                model={{
                    fields: [
                        { name: 'revenueType', title: 'Aggrement Type', type: InputTypes.Select, values: { 0: 'GGR', 1: 'Deposit' } },
                        { name: 'percentage', title: 'Percentage', type: InputTypes.Number },
                        { name: 'deductBonus', title: 'Deduct Bonus', type: InputTypes.Bool }
                    ]
                }} data={configuration.sport} />
        </FluxCard>
        <FluxCard title='Horse & Greyhound'
            buttons={<flex className='gap-10 align-right'>
                <ToggleButton value={configuration.horse?.enabled} onChange={(value) => {
                    var copy = { ...configuration };
                    copy.horse.enabled = value;
                    checkRulesAndSetConfiguration(copy);
                }} />
            </flex>}>
            <FluxForm
                className={configuration.horse?.enabled ? '' : 'disabled'}
                onChange={(data) => {
                    update('horse', data);
                }}
                buttons={null}
                columns={3}
                model={{
                    fields: [
                        { name: 'revenueType', title: 'Aggrement Type', type: InputTypes.Select, values: { 0: 'GGR', 1: 'Deposit' } },
                        { name: 'percentage', title: 'Percentage', type: InputTypes.Number },
                        { name: 'deductBonus', title: 'Deduct Bonus', type: InputTypes.Bool }
                    ]
                }} data={configuration.horse} />
        </FluxCard>
        <FluxCard title='Casino' className={'gap-10'}
            buttons={<flex className='gap-10 align-right'>
                <ToggleButton value={configuration.casino?.enabled} onChange={(value) => {
                    var copy = { ...configuration };
                    copy.casino.enabled = value;
                    checkRulesAndSetConfiguration(copy);
                }} />
            </flex>}>
            <flex className='vertical gap-10'>
                <FluxCard title='Agreement' className={configuration.casino?.enabled ? 'no-padding' : 'disabled no-padding'}>
                    <FluxForm
                        className={configuration.casino?.enabled ? '' : 'disabled'}
                        onChange={(data) => {
                            var copy = { ...configuration.casino };
                            copy.revenueType = data.revenueType;
                            copy.percentage = data.percentage;
                            copy.deductBonus = data.deductBonus;
                            update('casino', copy);
                        }}
                        buttons={null}
                        columns={3}
                        model={{
                            fields: [
                                { name: 'revenueType', title: 'Aggrement Type', type: InputTypes.Select, values: { 0: 'GGR', 1: 'Deposit' } },
                                { name: 'percentage', title: 'Percentage', type: InputTypes.Number },
                                { name: 'deductBonus', title: 'Deduct Bonus', type: InputTypes.Bool }
                            ]
                        }} data={configuration.casino} />
                </FluxCard>
                <FluxCard title='Limits & Commissions' className={configuration.casino?.enabled ? 'no-padding' : 'disabled no-padding'}>
                    <FluxForm
                        onChange={(data) => {
                            var copy = { ...configuration.casino };
                            copy.revenueLimits[0] = data;
                            update('casino', copy);
                        }}
                        buttons={null}
                        model={{
                            sections: {
                                'Slot Games': {
                                    icon: <Icon icon='slot-machine' type='fa-light' />,
                                    type: 'horizontal',
                                    fields: [
                                        {
                                            name: 'minBet',
                                            title: 'Minimum Bet'
                                        },
                                        {
                                            name: 'maxBet',
                                            title: 'Maximum Bet'
                                        },
                                        {
                                            name: 'percentage',
                                            title: 'Percentage'
                                        },
                                        {
                                            name: 'enabled',
                                            title: 'Enabled',
                                            type: InputTypes.Bool
                                        }
                                    ]
                                }
                            }
                        }} data={configuration.casino.revenueLimits[0]} />
                    <FluxForm
                        onChange={(data) => {
                            var copy = { ...configuration.casino };
                            copy.revenueLimits[1] = data;
                            update('casino', copy);
                        }}
                        buttons={null}
                        model={{
                            sections: {
                                'Live Casino Games': {
                                    icon: <Icon icon='slot-machine' type='fa-light' />,
                                    type: 'horizontal',
                                    fields: [
                                        {
                                            name: 'minBet',
                                            title: 'Minimum Bet'
                                        },
                                        {
                                            name: 'maxBet',
                                            title: 'Maximum Bet'
                                        },
                                        {
                                            name: 'percentage',
                                            title: 'Percentage'
                                        },
                                        {
                                            name: 'enabled',
                                            title: 'Enabled',
                                            type: InputTypes.Bool
                                        }
                                    ]
                                }
                            }
                        }} data={configuration.casino.revenueLimits[1]} />
                </FluxCard>
                <RetailCasinoProviderLimits configuration={configuration} onUpdate={(c) => checkRulesAndSetConfiguration(c)} />
                <RetailGameLimits configuration={configuration} onUpdate={(c) => checkRulesAndSetConfiguration(c)} />
                <RetailCasinoDisabledProviders configuration={configuration} onUpdate={(c) => checkRulesAndSetConfiguration(c)} />
                <RetailDisabledGames configuration={configuration} onUpdate={(c) => checkRulesAndSetConfiguration(c)} />
            </flex>
        </FluxCard>
        <FluxCard>
            <Button title='Save' className={'align-left success'} onClick={() => {
                Modal.open(<ConfirmationModal title='Save configuration?' onConfirm={() => {
                    var copy = { ...retail };
                    copy.Configuration = JSON.parse(copy.Configuration);

                    if (parentConfiguration) {
                        if (parentConfiguration?.enableTransferFundsToAgents === undefined) {
                            //
                        } else {
                            if (parentConfiguration.enableTransferFundsToAgents === false && copy.Configuration.enableTransferFundsToAgents === true) {
                                copy.Configuration.enableTransferFundsToAgents = false;
                            }
                        }
                    }

                    calculateSportAndHorseLimits(parentConfiguration, 'sport', copy.Configuration);
                    calculateSportAndHorseLimits(parentConfiguration, 'horse', copy.Configuration);
                    calculateCasinoPercentages(parentConfiguration, copy.Configuration);


                    copy.Configuration = JSON.stringify(configuration);
                    API.post(RetailEndpoints.Save, copy, 'Please wait', 'Unable to save retail').then(() => {
                        Popper.pop(NotificationType.Information, 'Retail saved');
                    });
                }}>
                    <FluxNotice type={NotificationType.Information} title='Please confirm saving retail configuration' />
                </ConfirmationModal>);
            }} />
        </FluxCard>
    </flex >;
};

RetailConfiguration.propTypes = {
    configuration: PropTypes.object,
    retail: PropTypes.object
};