import './_exchange-rates.scss';

import React, { useEffect, useState } from 'react';
import { API } from '../../../../v2/Lib/Api/Api';
import { DataEndpoints, SetupEndpoints } from '../../../lib/API/Endpoints';
import { ActionButton, FluxCard } from '../../../components/card/FluxCard';
import { FluxList } from '../../../components/list/FluxList';
import { lang } from '../../../lib/Localization/language';
import { Currency } from '../../../components/currency/Currency';
import { Button } from '../../../components/button/Button';
import { Popper } from '../../../components/notification/FluxPopper';
import { Modal } from '../../../components/application/FluxApp';
import { ConfirmationModal } from '../../../components/modal/ConfirmationModal/ConfirmationModal';

export const ExchangeRates = () => {
    const [currencies, setCurrencies] = useState(null);
    const [rates, setExchangeRates] = useState(null);
    const [currency, setCurrency] = useState(null);

    useEffect(() => {
        API.post(DataEndpoints.EnabledCurrencies).then((currencyResult) => {
            setCurrencies(currencyResult.result);
        });
    }, []);

    useEffect(() => {
        if (!currencies) return;
        API.post(SetupEndpoints.ExchangeRates, {}, 'fetching exchange rates', 'unable to fetch exchange rates').then((result) => {
            setExchangeRates(result.result);
        });
    }, [currencies]);


    const renderRates2 = () => {
        var selectedCurrency = null;
        return <FluxCard key={currency.CurrencyCode} title={currency.Name} buttons={<ActionButton title='Add new currency' icon='plus' onClick={() => {
            Modal.open(<ConfirmationModal title='Add new currency' confirmTitle={'Add'} onConfirm={() => {
                var copy = [...rates];
                copy.push({
                    base: currency.CurrencyCode,
                    currencyCode: selectedCurrency.CurrencyCode,
                    buy: 1,
                    sell: 1
                });
                setExchangeRates(copy);
            }}>
                <flex className='vertical gap-10'>
                    <select onChange={(e) => {
                        selectedCurrency = currencies.find(y => y.CurrencyCode == e.target.value);
                    }}>
                        {
                            currencies.map(x => {
                                if (x.CurrencyCode == currency.CurrencyCode) return;
                                if (rates.find(y => y.base == currency.CurrencyCode && y.currencyCode == x.CurrencyCode)) return;
                                if (selectedCurrency == null) selectedCurrency = x;
                                return <option key={x} value={x.CurrencyCode}>{x.Name} {x.CurrencyCode}</option>;
                            })
                        }
                    </select>
                </flex>
            </ConfirmationModal>);
        }} />}>
            <flex className='vertical gap-10'>
                {
                    rates.filter(x => x.base == currency.CurrencyCode).map(x => {
                        return <FluxCard key={x} title={lang('%1 to %2', [currency.CurrencyCode, x.currencyCode])}>
                            <flex className='vertical gap-10'>
                                <flex className='gap-20 fit-children'>
                                    <flex className='vertical'>
                                        <label className='medium' >{lang('Buy')}</label>
                                        <input defaultValue={x.buy} onChange={(e) => {
                                            var value = parseFloat(e.target.value);
                                            if (isNaN(value)) return;
                                            var copy = [...rates];
                                            copy.find(y => y.base == currency.CurrencyCode && y.currencyCode == x.currencyCode).buy = value;
                                            setExchangeRates(copy);
                                        }} />
                                    </flex>
                                    <flex className='gap-10'>
                                        <label>{lang('1 %1', [x.currencyCode])}</label>
                                        <span>=</span>
                                        <Currency sign={currency.CurrencyCode} amount={1 * x.buy} places={5} />
                                    </flex>
                                </flex>
                                <flex className='gap-20 fit-children'>
                                    <flex className='vertical'>
                                        <label className='medium' >{lang('Sell')}</label>
                                        <input defaultValue={x.sell} onChange={(e) => {
                                            var value = parseFloat(e.target.value);
                                            if (isNaN(value)) return;
                                            var copy = [...rates];
                                            copy.find(y => y.base == currency.CurrencyCode && y.currencyCode == x.currencyCode).sell = value;
                                            setExchangeRates(copy);
                                        }} />
                                    </flex>
                                    <flex className='gap-10'>
                                        <label>{lang('1 %1', [x.currencyCode])}</label>
                                        <span>=</span>
                                        <Currency sign={currency.CurrencyCode} amount={1 * x.sell} places={5} />
                                    </flex>
                                </flex>
                                <flex className='align-left'>
                                    <Button title='Save' className='success' onClick={() => {
                                        var copy = { ...x };
                                        copy.EntryDate = new Date();
                                        API.post(SetupEndpoints.SaveExchangeRate, copy, 'please wait saving rate', 'unable to save rate').then(() => {
                                            Popper.pop('information', 'Rate saved');
                                        });
                                    }} />
                                </flex>
                            </flex>
                        </FluxCard>;
                    })
                }
            </flex>
        </FluxCard>;
    };

    const convert = (value) => {
        if (value == 0) return 1;
        return (1 / value).toFixed(4);
    };

    const renderRates = () => {
        var hede = false;
        if (hede) renderRates2();

        var selectedCurrency = null;
        return <FluxCard key={currency.CurrencyCode} className='no-padding' title={currency.Name} buttons={<ActionButton title='Add new currency' icon='plus' onClick={() => {
            Modal.open(<ConfirmationModal title='Add new currency' confirmTitle={'Add'} onConfirm={() => {
                var copy = [...rates];
                copy.push({
                    base: currency.CurrencyCode,
                    currencyCode: selectedCurrency.CurrencyCode,
                    buy: 1,
                    sell: 1
                });
                setExchangeRates(copy);
            }}>
                <flex className='vertical gap-10'>
                    <select onChange={(e) => {
                        selectedCurrency = currencies.find(y => y.CurrencyCode == e.target.value);
                    }}>
                        {
                            currencies.map(x => {
                                if (x.CurrencyCode == currency.CurrencyCode) return;
                                if (rates.find(y => y.base == currency.CurrencyCode && y.currencyCode == x.CurrencyCode)) return;
                                if (selectedCurrency == null) selectedCurrency = x;
                                return <option key={x} value={x.CurrencyCode}>{x.Name} {x.CurrencyCode}</option>;
                            })
                        }
                    </select>
                </flex>
            </ConfirmationModal>);
        }} />}>
            <fluxList className='vertical gap-20'>
                <items>
                    {
                        rates.filter(x => x.base == currency.CurrencyCode).map(x => {
                            var c = currencies.find(y => y.CurrencyCode == x.currencyCode);
                            if (!c) {
                                return;
                            }
                            return <item key={x.currencyCode}>
                                <flex className='gap-10' >
                                    <flex className='gap-5 currency-code-selection align-items-center'>
                                        <flex className='vertical justify-content-center'>
                                            <label className='medium'>{c.CurrencyCode}</label>
                                            <span>{c.Name}</span>
                                        </flex>
                                    </flex>
                                    <flex className='vertical'>
                                        <label className='medium'>{lang('Buy')}</label>
                                        <input defaultValue={convert(x.buy)} onChange={(e) => {
                                            var value = parseFloat(e.target.value);
                                            if (isNaN(value)) return;
                                            var copy = [...rates];
                                            copy.find(y => y.base == currency.CurrencyCode && y.currencyCode == x.currencyCode).buy = 1 / value;
                                            setExchangeRates(copy);
                                        }} />
                                    </flex>
                                    <flex className='vertical'>
                                        <label className='medium'>{lang('Sell')}</label>
                                        <input defaultValue={convert(x.sell)} onChange={(e) => {
                                            var value = parseFloat(e.target.value);
                                            if (isNaN(value)) return;
                                            var copy = [...rates];
                                            copy.find(y => y.base == currency.CurrencyCode && y.currencyCode == x.currencyCode).sell = 1 / value;
                                            setExchangeRates(copy);
                                        }} />
                                    </flex>
                                    <flex className='align-right'>
                                        <Button title='Save' className='success' onClick={() => {
                                            var copy = { ...x };
                                            copy.EntryDate = new Date();
                                            API.post(SetupEndpoints.SaveExchangeRate, copy, 'please wait saving rate', 'unable to save rate').then(() => {
                                                Popper.pop('information', 'Rate saved');
                                            });
                                        }} />
                                    </flex>
                                </flex>
                            </item>;
                        })
                    }
                </items>
            </fluxList>
        </FluxCard>;
    };
    return <flex className='gap-5 overflow-hidden fit-children exchange-rates'>
        <FluxCard title='Currencies' className={'overflow-hidden align-self-stretch no-padding'}>
            <FluxList key={currencies} data={currencies ?
                currencies.map(x => {
                    return {
                        title: x.Name,
                        value: x.CurrencyCode
                    };
                }) : []
            } onSelected={(c) => {
                if (!c) return;
                setCurrency(currencies.find(x => x.CurrencyCode == c.value));
            }} />
        </FluxCard>
        {currency && renderRates()}
        {!currency && <flex className='center' ></flex>}
    </flex>;
};