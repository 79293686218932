import './_sport-icons.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { replaceSpace } from '../../../lib/Helpers/StringHelper';


export const SportIcon = (props) => {
    if (!props.sport) return <span />;
    var classList = [props.className, props.size, props.type, 'sport-icon sport-' + replaceSpace(props.sport.toLowerCase()).replace('&', 'and').replace(',', '')];

    return <span className={classList.join(' ')}></span>;
};

SportIcon.defaultProps = {
    size: '',
    className: '',
    type: ''
};

SportIcon.propTypes = {
    className: PropTypes.string,
    sport: PropTypes.string,
    size: PropTypes.string,
    type: PropTypes.string
};
