import './_league-selection.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FluxCard } from '../../../../../components/card/FluxCard';
import { Button } from '../../../../../components/button/Button';
import { lang } from '../../../../../lib/Localization/language';
import { Icon } from '../../../../../components/icon/Icon';
import { FluxList } from '../../../../../components/list/FluxList';
import { SportEndpoints } from '../../../../../lib/API/Endpoints';
import { API } from '../../../../../lib/API/Api';


export const MarketSelection = (props) => {
    const [sport, selectSport] = useState(null);
    const [market, selectMarket] = useState(null);
    const [sportMarkets, setSportMarkets] = useState(null);
    const [league, selectLeague] = useState(null);
    const [markets, setMarkets] = useState(props.configuration?.Configuration?.sport?.enabledMarkets ?? {});

    const lineRef = React.createRef();
    const selectionRef = React.createRef();

    useEffect(() => {
        var copy = { ...props.configuration };
        if (!copy.Configuration.sport) {
            copy.Configuration.sport = {
                enabledMarkets: {}
            };
        }
        copy.Configuration.sport.enabledMarkets = markets;
        props.onChange(copy);
    }, [markets]);


    useEffect(() => {
        if (!sport) return;
        if (lineRef?.current) lineRef.current.value = '';
        if (selectionRef?.current) selectionRef.current.value = '';

        API.post(`${SportEndpoints.Markets}/${sport.value}`).then((result) => {
            if (result.result.length) {
                selectMarket(result.result[0]);
            }

            setSportMarkets(result.result);
        });
    }, [sport]);


    const add = () => {
        var copy = { ...markets };
        var key = sport.value + ':' + market.id + ':' + selectionRef.current.value + ':' + lineRef.current.value;
        copy[key] = {
            id: market.id,
            name: market.name,
            sport: {
                id: sport.value,
                name: sport.title
            },
            selection: selectionRef.current.value,
            line: lineRef.current.value
        };
        setMarkets(copy);
    };

    const remove = (l) => {
        var copy = { ...markets };
        var key = l.sport.id + ':' + l.id + ':' + (l.selection ?? '') + ':' + (l.line ?? '')
        delete copy[key];
        setMarkets(copy);
    };


    return <flex className='gap-10 promotion-sport-rules-market-selection align-items-stretch fit-children'>
        <FluxCard title='Select Markets' className={'market-selection-card no-padding'}>
            <flex className='vertical gap-10 padding'>
                <label className='medium'>{lang('Sports')}</label>
                <FluxList
                    type={'select'}
                    endpoint={{ source: SportEndpoints.Categories, key: 'id', title: ['name'] }}
                    onSelected={(s) => {
                        if (!s) return;
                        selectSport(s);
                    }}
                    sort={(a, b) => {
                        return a.data.weight - b.data.weight;
                    }} />

                {sportMarkets && <flex className='vertical gap-10 padding'>
                    <label>{lang('Markets')}</label>
                    <select onChangeCapture={(e) => selectMarket(sportMarkets.find(x => x.id == parseInt(e.target.value)))}>
                        {sportMarkets?.map(x => <option value={x.id}>[{x.id}] {x.name}</option>)}
                    </select>
                    <span>{lang('Selection')}</span>
                    <input ref={selectionRef} type='text' name='selection' />
                    <span>{lang('Line')}</span>
                    <input ref={lineRef} type='text' name='line' />
                    <Button title='Add' className={'success center'} onClick={() => add()} />
                </flex>}
            </flex>
        </FluxCard>
        <FluxCard className={'promotion-sport-selection-rules no-padding'} title='Selected Markets'>
            <flex className='vertical gap-10'>
                <fluxList>
                    <items>
                        {Object.values(markets).map(x => {
                            return <item key={x}>
                                <flex className='gap-10'>
                                    <span>{x.sport.name}</span>
                                    <Icon icon='chevron-right' size='xs' />
                                    <span>{x.name}</span>
                                    <Icon icon='chevron-right' size='xs' />
                                    <span>{x.selection ?? 'all selections'}</span>
                                    <Icon icon='chevron-right' size='xs' />
                                    <span>{x.line ?? 'all lines'}</span>
                                    <flex className='align-right'>
                                        <Button title='Remove' className='warning' onClick={() => remove(x)} />
                                    </flex>
                                </flex>
                            </item>;
                        })}
                    </items>
                </fluxList>
            </flex>
        </FluxCard>
    </flex>;
};

MarketSelection.propTypes = {
    configuration: PropTypes.object,
    onChange: PropTypes.func
};



