import React from 'react';
import { FluxTab } from '../../../components/tab/FluxTab';
import { BetboosterSettings } from './Components/BetboosterSettings';
import { GeneralSettings } from './Components/GeneralSettings';
import { PayoutSettings } from './Components/PayoutSettings';

export const BetBoosterBonus = (props) => {
    var buttons = [
        {
            title: 'General Settings'
        },
        {
            title: 'Rules'
        },
        {
            title: 'Payout'
        }
    ];
    return <FluxTab buttons={buttons} className='gap-10 fx-shadow fx-radius rounded-buttons'>
        <GeneralSettings {...props} />
        <BetboosterSettings {...props} />
        <PayoutSettings {...props} />
    </FluxTab>;
};