import './_top-events.scss';
import React, { useEffect, useState } from 'react';
import { API } from '../../../../lib/API/Api';
import { SportEndpoints } from '../../../../lib/API/Endpoints';
import { Popper } from '../../../../components/notification/FluxPopper';
import { ActionButton, FluxCard } from '../../../../components/card/FluxCard';
import { SportsTree } from '../../../../components/sports/SportsTree/SportsTree';
import { Button } from '../../../../components/button/Button';
import { Table } from '../../../../components/table/Table';
import { Icon } from '../../../../components/icon/Icon';
import { lang } from '../../../../lib/Localization/language';
import { Modal } from '../../../../components/application/FluxApp';
import { SearchEvent } from '../../../../components/sports/SearchEvent/SearchEvent';
import { FluxModal } from '../../../../components/modal/FluxModal';
import { InputTypes } from '../../../../components/form/InputTypes';

export const model = {
    fields: [
        {
            name: 'id',
            title: 'Id'
        },
        {
            name: 'name',
            title: 'Name'
        },
        {
            name: 'date',
            title: 'Date',
            // multiLine: true,
            type: InputTypes.DateTime,

            // render: (val, row) => {
            //     var d = row.date;
            //     if (d.indexOf('Z') == -1) d += 'Z';
            //     const date = new Date(d).toLocaleString('en-Gb', { hour12: false });
            //     const splitted = date.split(',');
            //     if (splitted && splitted.length > 1) {
            //         return <div className='flex vertical'>
            //             <div className='flex'>{splitted[0]}</div>
            //             <div className='flex'>{splitted[1]}</div>
            //         </div>;
            //     } else {
            //         return date;
            //     }
            // }
        },
        {
            name: 'sport',
            title: 'Sport'
        },
        {
            name: 'region',
            title: 'Region'
        },
        {
            name: 'league',
            title: 'League'
        }
    ],
    onRenderClassName: (row, className) => {
        var d = row.date;
        if (d.indexOf('Z') == -1) d += 'Z';
        var converted = new Date(d);
        if (new Date() > converted) return 'warning-row';

        return className;
    }
};



export const TopEvents = () => {
    const [topEvents, setTopEvents] = useState(null);
    const [topEventsLookup, setTopEventsLookup] = useState({});
    useEffect(() => {
        API.post(SportEndpoints.GetTopEvents, {}, 'fetching top events', 'unable to fetch top events').then((result) => {
            try {
                var data = JSON.parse(result.result);
                if (!data) data = [];
                setTopEvents(data);
            } catch (err) {
                setTopEvents([]);
            }
        });
    }, []);


    useEffect(() => {
        var copy = {};
        if (!topEvents) return;
        topEvents.map(x => {
            copy[x.id] = true;
        });
        setTopEventsLookup(copy);
    }, [topEvents]);

    const removeEvent = (event) => {
        var copy = [...topEvents];
        copy.splice(copy.findIndex(x => x.id == event.id), 1);
        setTopEvents(copy);
    };

    const save = () => {
        var saveData = [...topEvents];
        saveData.map(x => {
            delete x.__selected;
            delete x.__highlighted;
        });

        API.post(SportEndpoints.SaveTopEvents, saveData, 'fetching events', 'unable to fetch events').then(() => {
            Popper.pop('information', 'Top events saved.', 'Top events saved, web site data updated');
        });
    };


    if (!topEvents) return <div />;

    return <flex className='vertical gap-10 overflow-hidden'>
        <flex className='gap-10 overflow-hidden fit-children align-items-start'>
            <FluxCard title='Events' className={'sports-tree-card no-padding overflow-hidden'} buttons={
                <flex className='align-right'>
                    <Button className='success' title='Search' onClick={() => {
                        Modal.open(<FluxModal title='Search' className={'search-events-modal'} footer={<flex>
                            <Button title='Close' className={'success'} onClick={() => {
                                Modal.close();
                            }} />
                        </flex>}>
                            <SearchEvent events={topEvents} onChangeEvents={(e) => {
                                setTopEvents(e);
                            }} />
                        </FluxModal>);
                    }} />
                </flex>
            }>
                <SportsTree
                    regions leagues events onRenderEventOptions={(event) => {
                        var selected = topEventsLookup[event.id];
                        if (selected) {
                            return <ActionButton title='remove' icon='square-xmark' onClick={() => removeEvent(event)} />;
                        }

                        return <ActionButton title='add' icon='square-plus' onClick={() => {
                            var copy = [...topEvents];
                            copy.push(event);
                            setTopEvents(copy);
                        }} />;
                    }} />
            </FluxCard>
            <FluxCard title='Events' buttons={<Button title='Save' onClick={() => save()} className='align-right success' />}>
                <flex className='vertical gap-10'>
                    <flex className='gap-5'>
                        <Button onClick={() => {
                            var copy = [...topEvents];
                            copy = copy.sort((b, a) => new Date(a.date) - new Date(b.date));
                            setTopEvents(copy);
                        }}><flex className='gap-5'><Icon icon='arrow-down-9-1' type='fa-light' /><span>{lang('Sort by Date Ascending')}</span></flex></Button>
                        <Button onClick={() => {
                            var copy = [...topEvents];
                            copy = copy.sort((a, b) => new Date(a.date) - new Date(b.date));
                            setTopEvents(copy);
                        }}><flex className='gap-5'><Icon icon='arrow-down-1-9' type='fa-light' /><span>{lang('Sort by Date Descending')}</span></flex></Button>
                        <flex className='align-right'>
                            <Button title='Remove passed events' className='warning' onClick={() => {
                                var copy = [...topEvents];
                                copy = copy.filter(x => {
                                    var converted = new Date(x.date);
                                    if (new Date() > converted) return false;
                                    return true;
                                });

                                setTopEvents(copy);
                            }} />
                        </flex>
                    </flex>
                    <Table model={model} data={topEvents} className='fx-shadow fx-borders no-select cms-top-events-table'
                        buttons={(row) => {
                            return <Button title='Remove' className={'warning'} onClick={() => {
                                var copy = [...topEvents];
                                copy = copy.filter(x => x != row);
                                setTopEvents(copy);
                            }} />;
                        }}
                        options={{
                            draggable: {
                                enabled: true
                            }
                        }} onDataChange={(row, index, value) => {
                            setTopEvents(Object.assign([], value));
                        }} />
                </flex>
            </FluxCard>
        </flex>
    </flex>;
};


