
export const calculateRTP = (row) => {
    try {
        //escaping from undefined
        var bets = (row.Bet || 0) - (row.Cancel || 0);
        var g = bets - (row.Win || 0);
        var perc = 100 - ((g * 100) / bets);
        if (isNaN(perc) || perc < 0) perc = 0;
        return `%${perc.toFixed(2)}`;
    } catch (err) {
        return '';
    }
};