import './_player-information.scss';

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from '../../../../components/Controls/DateTime/DateTime';
import { changePlayerRiskLevel, editPlayer, sendMessageToPlayer } from '../../../actions/PlayerActions';
import { Modal } from '../../../components/application/FluxApp';
import { Button } from '../../../components/button/Button';
import { ActionButton, FluxCard } from '../../../components/card/FluxCard';
import { currency } from '../../../components/currency/Currency';
import { FluxForm } from '../../../components/form/FluxForm';
import { Icon } from '../../../components/icon/Icon';
import { ConfirmationModal } from '../../../components/modal/ConfirmationModal/ConfirmationModal';
import { FluxModal } from '../../../components/modal/FluxModal';
import { FluxNotice } from '../../../components/notification/FluxNotice';
import { NotificationType } from '../../../components/notification/FluxNotification';
import { Popper } from '../../../components/notification/FluxPopper';
import { API } from '../../../lib/API/Api';
import { PlayerEndpoints } from '../../../lib/API/Endpoints';
import { PlayerRiskLevel } from '../../../lib/Enums/RiskEnums';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { lang } from '../../../lib/Localization/language';

export const PlayerInformation = (props) => {
    const user = useSelector((state) => state.user.loginInformation);
    const [player, setPlayer] = useState(props.player);

    const getAsteriskValue = (value, startUnmasked = 0, endUnmasked = 0) => {
        if (!value) return value;
        if (user.UserGroupId == 2 || user.UserGroupId == 3 || user.UserPrivileges?.SpecialActions?.indexOf('ViewPlayerDetails') >= 0) return value;

        let maskedLength = value.length - startUnmasked - endUnmasked;

        if (maskedLength <= 0) {
            return value;
        }

        let maskedPart = '*'.repeat(maskedLength);

        return value.substring(0, startUnmasked) + maskedPart + value.substring(value.length - endUnmasked);
    };


    const getNameTag = () => {
        if (!player.name || !player.surname) return '';
        if (player.name == 'Empty' || player.surname == 'Empty') return '';
        if (player.name == 'John' && player.surname == 'doe') return '';
        return ` - ${player.name} ${player.surname}`;
    };
    return <FluxCard className='player-information fit align-self-stretch' title={<React.Fragment>
        <span>#{player.id}</span>
        <Icon icon='chevron-right' size='xs' />
        <span>{player.operator}</span>
        <Icon icon='chevron-right' size='xs' />
        <span className='bold player-username-with-fullname'>{player.username}{(getNameTag())}</span>
    </React.Fragment>
    } buttons={<ActionButton icon='user-pen' title='Edit Player' onClick={() => {
        editPlayer(player.id, (result, data) => {
            var copy = { ...player };
            copy.name = data.Name;
            copy.code = data.Code;
            copy.surname = data.Surname;
            copy.groupName = data.PlayerGroup;
            copy.group = data.Group;
            setPlayer(copy);
        });
    }} />} footer={user.AffiliateId ? null :
        <flex className='gap-10 center'>
            <Button title='Send Message' className={'success'} onClick={() => {
                sendMessageToPlayer(player, () => {
                    Popper.pop('information', 'message sent to player.');
                });
            }} />
            <Button title='Change Password' className='warning' onClick={() => {
                Modal.open(<FluxModal title='Update password'>
                    <FluxForm
                        data={{
                            Id: player.id
                        }}
                        endpoint={PlayerEndpoints.ChangePassword}
                        model={{
                            fields: [
                                { name: 'Password', title: 'Password', type: 'password' },
                                { name: 'PasswordConfirm', title: 'Confirm Password', type: 'password' },
                            ]
                        }}
                        onCancel={() => Modal.close()}
                        onSubmitComplete={() => {
                            Modal.close();
                            Popper.pop(NotificationType.Success, lang('Password changed.'));
                        }}
                    />
                </FluxModal>);
            }} />
            {player.authType == 2 && <Button title='Revoke 2FA' className={'warning'} onClick={() => {
                Modal.open(<ConfirmationModal confirmTitle='Confirm' title='Please confirm' onConfirm={() => {
                    API.post(PlayerEndpoints.Revoke2FA(player.id), null, 'Please wait', 'Unable to revoke 2FA!').then(() => {
                        var copy = { ...player };
                        copy.authType = 1;
                        setPlayer(copy);
                        Popper.pop(NotificationType.Success, lang('Player 2FA revoked.'));
                    });
                }}>
                    <FluxNotice type='information' title='Players 2FA authentication will be revoked! Please click confirm to continue!' />
                </ConfirmationModal>);
            }} />}
            {player.authType == 1 && <Button title='Enable 2FA' className={'warning'} onClick={() => {
                Modal.open(<ConfirmationModal confirmTitle='Confirm' title='Please confirm' onConfirm={() => {
                    API.post(PlayerEndpoints.Enable2FA(player.id), null, 'Please wait', 'Unable to enable 2FA!').then(() => {
                        var copy = { ...player };
                        copy.authType = 2;
                        setPlayer(copy);
                        Popper.pop(NotificationType.Success, lang('Player 2FA enabled.'));
                    });
                }}>
                    <FluxNotice type='information' title='Players 2FA authentication will be enabled! Please click confirm to continue!' />
                </ConfirmationModal>);
            }} />}
            {player.verified == 0 && <Button title='Verify Player' className={'success'} onClick={() => {
                Modal.open(<ConfirmationModal confirmTitle='Confirm' title='Please confirm' onConfirm={() => {
                    API.post(PlayerEndpoints.Verify(player.id), null, 'Please wait', 'Unable to verify').then(() => {
                        var copy = { ...player };
                        copy.verified = 1;
                        setPlayer(copy);
                        Popper.pop(NotificationType.Success, lang('Player is verified.'));
                    });
                }}>
                    <FluxNotice type='information' title='Player will be verified! Please click confirm to continue!' />
                </ConfirmationModal>);
            }} />}
            {player.verified == 1 && <Button title='Unverify Player' className={'warning'} onClick={() => {
                Modal.open(<ConfirmationModal confirmTitle='Confirm' title='Please confirm' onConfirm={() => {
                    API.post(PlayerEndpoints.Unverify(player.id), null, 'Please wait', 'Unable to unverify').then(() => {
                        var copy = { ...player };
                        copy.verified = 0;
                        setPlayer(copy);
                        Popper.pop(NotificationType.Success, lang('Player is unverified.'));
                    });
                }}>
                    <FluxNotice type='information' title='Player will be unverified! Please click confirm to continue!' />
                </ConfirmationModal>);
            }} />}
        </flex>}>
        <flex className='vertical gap-10'>
            {player.testAccount && <flex className='gap-10 padding warning'>
                <span className='center'>{lang('Test account')}</span>
            </flex>}
            <flex className='gap-10'>
                <label className='medium bolder'>{lang('Register Date')}</label>
                <DateTime date={player.created} />
            </flex>
            <flex className='gap-10 columns-2 fit'>
                <flex className='vertical'>
                    <label className='medium bolder'>{lang('Group')}</label>
                    <span>{player.groupName}</span>
                </flex>
                <flex className='vertical'>
                    <label className='medium bolder'>{lang('Name')}</label>
                    <span>{player.name} {player.surname}</span>
                </flex>
            </flex>
            <flex className='gap-10 columns-2 fit'>
                <flex className='vertical'>
                    <label className='medium bolder'>{lang('Code')}</label>
                    <span>{player.code}</span>
                </flex>
                <flex className='vertical'>
                    <label className='medium bolder'>{lang('Risk Level')}</label>
                    <flex className='gap-5 clickable' onClick={() => {
                        changePlayerRiskLevel(player, (changedRiskLevel) => {
                            var copy = { ...player };
                            copy.riskLevel = changedRiskLevel;
                            setPlayer(copy);
                            Popper.pop('warning', 'Player risk level changed.');
                        });
                    }}>
                        <Icon icon='pen-to-square' size='xs' />
                        <span className={`player-risk risk-level-${PlayerRiskLevel[player.riskLevel]}`}>{PlayerRiskLevel[player.riskLevel]}</span>
                    </flex>
                </flex>
            </flex>
            <flex className='gap-10 columns-2 fit'>
                {
                    user.UserPrivileges?.SpecialActions?.indexOf('ViewPlayerDetails') >= 0 || user.UserGroupId <= 2
                        ?
                        <flex className='vertical'>
                            <label className='medium bolder'>{lang('Phone')}</label>
                            <span>{player.phone}</span>
                        </flex>
                        //HAVE PERM
                        :
                        //DOES NOT HAVE PERM
                        <flex className='vertical'>
                            <label className='medium bolder'>{lang('Phone')}</label>
                            <span>***</span>
                        </flex>
                }

                <flex className='vertical'>
                    <label className='medium bolder'>{lang('Currency')}</label>
                    <span>{player.currency}</span>
                </flex>
            </flex>
            <flex className='gap-10 columns-2 fit'>
                <flex className='vertical'>
                    <label className='medium bolder'>{lang('Email')}</label>
                    <span>{getAsteriskValue(player.email, 4, 0)}</span>
                </flex>
                <flex className='vertical'>
                    <label className='medium bolder'>{lang('IdNumber')}</label>
                    <span>{getAsteriskValue(player.idNumber, 0, 4)}</span>
                </flex>
            </flex>
            <flex className='gap-10 columns-2 fit'>
                <flex className='vertical'>
                    <label className='medium bolder'>{lang('BirthDay')}</label>
                    <DateTime convert={false} includeTime={false} date={player.birthDay} />
                </flex>
                <flex>
                </flex>
            </flex>
            {
                player.retailId > 0 &&
                <flex className='gap-10 columns-2 fit border-top padding-top'>
                    <flex className='vertical'>
                        <label className='medium bolder'>{lang('Retail')}</label><span>{player.retailCode} {player.retailName}</span>
                    </flex>
                    <flex className='vertical'>
                        <label className='medium bolder'>{lang('Retail Balance')}</label><span>{currency(player.retailBalance, 2, player.currency)}</span>
                    </flex>
                </flex>
            }
            {
                player.affiliateId > 0 &&
                <flex className='gap-10 columns-2 fit border-top padding-top'>
                    <flex className='vertical'>
                        <label className='medium bolder'>{lang('Affiliate')}</label><span>{player.affiliateName}</span>
                    </flex>
                </flex>
            }

            {
                player.lastNote && props.showNotes &&
                <div className='padding-top'>
                    <FluxNotice type='error' title={lang('%1,%2', [new Date(player.lastNote.entryDate).toLocaleDateString('en-gb', { hours12: false }) + ' ' + DateHelpers.getTime(new Date(player.lastNote.entryDate)), player.lastNote.notedBy])} description={player.lastNote.notes} />
                </div>

            }

        </flex>
    </FluxCard>;
};

PlayerInformation.propTypes = {
    player: PropTypes.object,
    showNotes: PropTypes.bool
};
