import React, { useEffect, useState } from 'react';
import { OperatorEndpoints } from '../../../lib/API/Endpoints';
import { ActionTypes } from '../../../lib/ActionManager/FluxActions';
import { InputTypes } from '../../../components/form/InputTypes';
import { FluxAction } from '../../../components/application/FluxAction';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { appHistory } from '../../../..';
import { OperatorCasinoGames } from '../CasinoGames/OperatorCasinoGames';
import { API } from '../../../lib/API/Api';
import { OperatorPaymentProviders } from '../PaymentProviders/PaymentProviders';



const model = {
    fields: [
        {
            name: 'Name'
        },
        {
            name: 'Enabled',
            type: InputTypes.Bool
        }
    ]
};

const resultModel = {
    fields: [
        {
            name: 'Id'
        },
        {
            name: 'Prefix'
        },
        {
            name: 'Name'
        },
        {
            name: 'WebSite',
            display: 'Site'
        },
        {
            name: 'Description'
        },
        {
            name: 'Enabled',
            type: InputTypes.Bool
        }
    ]
};

export const ConfigureOperators = () => {
    const [selectedOperator, selectOperator] = useState(null);
    const [action, setAction] = useState(null);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
        const action = urlParams.get('action');
        if (!isNaN(parseFloat(id))) {
            API.post(OperatorEndpoints.Search, {
                fields: [
                    {
                        name: 'Id',
                        value: parseFloat(id)
                    }
                ]
            }).then((result) => {
                if (result.result.length) {
                    selectOperator(result.result[0]);
                    switch (action) {
                        case 'casino':
                            setAction(ActionTypes.SaveOperatorCasinoConfiguration);
                            break;
                        case 'payment':
                            setAction(ActionTypes.SaveOperatorPaymentGatewayConfiguration);
                            break;
                    }
                }
            });
        }
    }, []);


    const onActionClicked = (action, o) => {
        switch (action.type) {
            case ActionTypes.SaveOperatorCasinoConfiguration:
                selectOperator(o);
                setAction(action.type);
                appHistory.push(`?&id=${o.Id}&action=casino`);
                break;
            case ActionTypes.SaveOperatorPaymentGatewayConfiguration:
                selectOperator(o);
                setAction(action.type);
                appHistory.push(`?&id=${o.Id}&action=payment`);
                break;
            default:
                console.log(action.type);
                break;
        }
    };

    const renderAction = () => {
        if (!action) return;
        switch (action) {
            case ActionTypes.SaveOperatorCasinoConfiguration:
                return <OperatorCasinoGames operator={selectedOperator} onClose={() => {
                    reset();
                }} />;
            case ActionTypes.SaveOperatorPaymentGatewayConfiguration:
                return <OperatorPaymentProviders operator={selectedOperator} onClose={() => {
                    reset();
                }} />;
        }
    };

    const reset = () => {
        setAction(null);
        selectOperator(null);
        appHistory.push('/platform-settings/operators');
    };

    return <FluxAction action={ActionTypes.Operators} checkActive={false}>
        {!selectedOperator && <FluxSearch
            model={model}
            actions={ActionTypes.Operators}
            resultModel={resultModel}
            endpoint={OperatorEndpoints.Search}
            title={'Search Operators'}
            searchOnStart
            onActionClicked={onActionClicked.bind(this)} />
        }
        {renderAction()}
    </FluxAction>;
};