import React from 'react';
import PropTypes from 'prop-types';
import { ActionButton, FluxCard } from '../../../components/card/FluxCard';
import { Button } from '../../../components/button/Button';
import { lang } from '../../../lib/Localization/language';
import { depositPlayer, resetBalance, withdrawPlayer } from '../../../actions/TransactionActions';
import { Popper } from '../../../components/notification/FluxPopper';
import { currency } from '../../../components/currency/Currency';
import { useSelector } from 'react-redux';

export const PlayerBonusBalance = (props) => {
    const player = props.player;
    const user = useSelector((state) => state.user.loginInformation);
    const isAffiliate = user.AffiliateId > 0;


    return <FluxCard title={'Bonus balance'}
        buttons={isAffiliate ? null : <ActionButton title='Remove Balance' icon='times' onClick={() => resetBalance(player, true, () => props.onTransactionComplete())} />}
        footer={
            isAffiliate ? null :
                <flex className='gap-10 center'>
                    <Button title='Deposit Bonus' className={!player.activePromotion ? 'disabled' : 'success'} onClick={() => {
                        depositPlayer(player, true, (data) => {
                            Popper.pop('information', lang('%1 deposited to the player.', [currency(data.Amount, 2, data.Currency)]));
                            props.onTransactionComplete();
                        });
                    }} />


                    <Button title='Withdraw Bonus' className={!player.activePromotion ? 'disabled' : 'warning'} onClick={() => {
                        withdrawPlayer(player, true, (data) => {
                            Popper.pop('information', lang('%1 withdraw completed from player account.', [currency(data.Amount, 2, data.Currency)]));
                            props.onTransactionComplete();
                        });
                    }} />

                </flex>}>
        <h2 className='center'>{currency(player.bonusBalance, 2, player.currency)}</h2>
    </FluxCard>;
};

PlayerBonusBalance.propTypes = {
    player: PropTypes.object,
    onTransactionComplete: PropTypes.func
};
