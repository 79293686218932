import React, { useEffect, useState } from 'react';

import { FluxTab } from '../../../../components/tab/FluxTab';
import { BulkPromotionCodeSettings } from './PromotionCode/BulkPromotionCodeSettings';
import { PromotionCodeCreateSettings } from './PromotionCode/PromotionCodeCreateSettings';


export const PromotionCodeSettings = (props) => {
    const { promotion, onChange } = { ...props };
    const [configuration, setConfiguration] = useState(null);

    var buttons = [
        {
            title: 'Promotion Code'
        },
        {
            title: 'Auto Code Generator'
        }
    ];

    useEffect(() => {

        var c = { ...promotion.Configuration };

        if (!c.Code) {
            c.Code = {
                Code: promotion.Code ?? '',
                ConsumeOnRegister: false
            };
        }

        setConfiguration(c);

    }, []);


    useEffect(() => {
        if (!configuration) return;
        var copy = { ...promotion };
        copy.Configuration = configuration;
        copy.Code = configuration.Code.Code;
        onChange(copy);
    }, [configuration]);


    if (!configuration) return <></>;
    return <FluxTab buttons={buttons} className='gap-10 fx-shadow fx-radius rounded-buttons campaign-settings'>
        <PromotionCodeCreateSettings {...props} />
        <BulkPromotionCodeSettings {...props} />
    </FluxTab >
};