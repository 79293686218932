import React from 'react';
import { FluxAction } from '../../components/application/FluxAction';
import { FluxPage } from '../../components/page/FluxPage';
import { PlayerOperations } from './PlayerOperations/PlayerOperations';
import { CreatePlayers } from './CreatePlayer/CreatePlayers';
import { PlayerGroups } from './PlayerGroups/PlayerGroups';
import { PlayerNotifications } from './PlayerNotifications/PlayerNotifications';
import { PlayersWithSameIp } from './PlayersWithSameIp/PlayersWithSameIp';
import { ActionTypes } from '../../lib/ActionManager/FluxActions';

export const PlayersPage = () => {
    return <FluxPage action={ActionTypes.Player}>
        <FluxAction action={ActionTypes.CreatePlayer}><CreatePlayers /></FluxAction>
        <FluxAction action={ActionTypes.CreatePlayerGroup}><PlayerGroups /></FluxAction>
        <FluxAction action={ActionTypes.SearchPlayer}><PlayerOperations /></FluxAction>
        <FluxAction action={ActionTypes.PlayerAnnouncements}><PlayerNotifications /></FluxAction>
        <FluxAction action={ActionTypes.PlayersWithSameIp}><PlayersWithSameIp /></FluxAction>
    </FluxPage>;
};
