/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { lang } from '../../../../lib/Localization/language';
import { FluxCard } from '../../../../components/card/FluxCard';
import { FluxForm } from '../../../../components/form/FluxForm';
import { InputTypes } from '../../../../components/form/InputTypes';
import { FluxTab } from '../../../../components/tab/FluxTab';
import { Table } from '../../../../components/table/Table';
import { ToggleButton } from '../../../../components/toggleButton/ToggleButton';
import { SportsBetTypes, SportsTicketTypes } from '../../../../lib/Enums/BettingEnums';
import { EventSelection } from './Events/EventSelection';
import { LeagueSelection } from './Leagues/LeagueSelection';
import { MarketSelection } from './MarketSelection/MarketSelection';
import { SportSelection } from './Sports/SportSelection';




export const SportsSettings = (props) => {
    let { promotion, currencies, enabled, enableMinimumStake, onChange, disabled, disabledReason } = { ...props };

    const [data, setData] = useState(promotion.Configuration.sport ?? { enabled: enabled ?? false, cashout: false });

    var model = {
        fields: [
            {
                name: 'enabled',
                title: 'Enable sports',
                value: enabled ?? false,
                type: InputTypes.Bool,
                readonly: enabled
            },
            {
                name: 'cashout',
                title: 'Enable cashout',
                type: InputTypes.Bool
            }
        ]
    };


    const [settings, updateSettings] = useState(promotion.Configuration?.sport?.bet ?? [
        {
            Type: 0,
            TicketType: 0,
            MinimumSelection: 1,
            MinOdds: 0,
            MaxOdds: 0,
            Enabled: true
        },
        {
            Type: 0,
            TicketType: 1,
            MinimumSelection: 2,
            MinOdds: 0,
            MaxOdds: 0,
            Enabled: true
        },
        {
            Type: 1,
            TicketType: 0,
            MinimumSelection: 1,
            MinOdds: 0,
            MaxOdds: 0,
            Enabled: true
        },
        {
            Type: 1,
            TicketType: 1,
            MinimumSelection: 2,
            MinOdds: 0,
            MaxOdds: 0,
            Enabled: true
        },
        {
            Type: 2,
            TicketType: 1,
            MinimumSelection: 2,
            MinOdds: 0,
            MaxOdds: 0,
            Enabled: true
        },
    ]);


    useEffect(() => {
        if (!data) return;
        if (enabled) {
            data.enabled = true;
        }

        promotion.Configuration.sport = data;
        onChange({ ...promotion });
    }, [data]);

    useEffect(() => {
        var copy = { ...data };
        copy.bet = settings;
        setData(copy);
    }, [settings]);

    const context = {
        update: (e, row) => {
            var copy = [...settings];
            var field = copy.find(x => x.Type == row.Type && x.TicketType == row.TicketType);
            var value = e.target.value;
            if (e.target.type == 'number') {
                value = parseFloat(value);
            }
            field[e.target.name] = value;
            updateSettings(copy);
        }
    };


    var tabButtons = [
        {
            title: 'Sports'
        },
        {
            title: 'Leagues'
        },
        {
            title: 'Events'
        },
        {
            title: 'Markets'
        }
    ];

    if (disabled) {
        return <flex className='vertical gap-10'>
            {disabledReason}
        </flex>;
    }
    return <flex className='vertical gap-10'>
        <FluxCard>
            <flex className='vertical gap-10'>
                <FluxForm data={data} model={model} columns={2} buttons={null} onChange={(d) => setData(d)} />
                <flex className='vertical gap-5 padding'>
                    <label className='medium'>{lang('Sport ticket rules')}</label>
                    <flex className='vertical gap-10'>
                        <Table
                            className={'fx-borders fx-shadow sport-ticket-settings'}
                            context={context}
                            model={{
                                fields:
                                    [
                                        {
                                            name: 'Type',
                                            render: (val) => SportsBetTypes[val]
                                        },
                                        {
                                            name: 'TicketType',
                                            title: 'Ticket Type',
                                            render: (val) => SportsTicketTypes[val]
                                        },
                                        {
                                            name: 'MinStake',
                                            title: 'Minimum Stake',
                                            hidden: !enableMinimumStake,
                                            render: (val, row, field, context) => <input type='number' name={field.name} defaultValue={val} onChange={(e) => context.update(e, row)} />
                                        },
                                        {
                                            name: 'MinimumSelection',
                                            title: 'Minimum Selection',
                                            render: (val, row, field, context) => row.TicketType == 0 ? <span className=''>{lang('Minimum selection for singles is 1.')}</span> : <input type='number' name={field.name} defaultValue={val} onChange={(e) => context.update(e, row)} />
                                        },
                                        {
                                            name: 'MinOdds',
                                            title: 'Min Odds',
                                            render: (val, row, field, context) => <input type='number' name={field.name} defaultValue={val} onChange={(e) => context.update(e, row)} />
                                        },
                                        {
                                            name: 'MaxOdds',
                                            title: 'Max Odds',
                                            render: (val, row, field, context) => <input type='number' name={field.name} defaultValue={val} onChange={(e) => context.update(e, row)} />
                                        },
                                        {
                                            name: 'Enabled',
                                            title: 'Enabled',
                                            render: (val, row, field, context) => <ToggleButton value={val} onChange={(v) => {
                                                context.update({ target: { name: field.name, value: v } }, row);
                                            }} />
                                        }
                                    ]
                            }} data={settings} />
                    </flex>
                </flex>
            </flex>
        </FluxCard>
        <FluxTab buttons={tabButtons} className='gap-10 fx-shadow fx-radius rounded-buttons'>
            <SportSelection configuration={promotion} onChange={(c) => {
                var copy = { ...data };
                copy.enabledSports = c.Configuration.sport.enabledSports;
                setData(copy);
            }} />
            <LeagueSelection configuration={promotion} onChange={(c) => {
                var copy = { ...data };
                copy.enabledLeagues = c.Configuration.sport.enabledLeagues;
                setData(copy);
            }} />
            <EventSelection configuration={promotion} onChange={(c) => {
                var copy = { ...data };
                copy.enabledEvents = c.Configuration.sport.enabledEvents;
                setData(copy);
            }} />
            <MarketSelection configuration={promotion} onChange={(c) => {
                var copy = { ...data };
                copy.enabledMarkets = c.Configuration.sport.enabledMarkets;
                setData(copy);
            }} />
        </FluxTab>
    </flex>;
};