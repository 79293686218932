/* eslint-disable react/display-name */
import React from 'react';
import { useSelector } from 'react-redux';
import { currency, Currency } from '../../../components/currency/Currency';
import { InputTypes } from '../../../components/form/InputTypes';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { TicketEndpoints } from '../../../lib/API/Endpoints';
import { SportsBetTypes, SportsTicketStatus, SportsTicketTypes } from '../../../lib/Enums/BettingEnums';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { gotoProfile } from '../../../lib/Helpers/NavigationHelper';
import { footerCurrencyValues } from '../../../lib/Helpers/Reports/Footer';
import { CurrencyFilterField } from '../../../lib/Models/CurrencyModel';
import { renderBetSlipStatus, TicketId } from '../../../lib/Models/TicketModels';

const searchModel = {
    name: 'search-manual-tickets',
    fields: [
        {
            name: 'OperatorId',
            title: 'Operator',
            type: InputTypes.Select,
            exact: true,
            nullable: true,
            data: {
                source: 'operators',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'ExternalTicketId',
            title: 'Ticket Id',
            type: InputTypes.Number
        },
        {
            name: 'EntryDate',
            title: 'Date',
            type: InputTypes.DateTime,
            nullable: true,
            between: true,
            value: {
                start: DateHelpers.yesterday(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'EventId',
            title: 'Event Id',
        },
        {
            name: 'Type',
            title: 'Betting Type',
            type: InputTypes.Select,
            nullable: true,
            values: SportsBetTypes
        },
        {
            name: 'TicketType',
            title: 'Ticket Type',
            type: InputTypes.Select,
            nullable: true,
            values: SportsTicketTypes
        },
        {
            name: 'Status',
            title: 'Status',
            type: InputTypes.Select,
            nullable: true,
            values: SportsTicketStatus
        },
        {
            name: 'PlayerId',
            title: 'Player Id',
            exact: true,
        },
        {
            name: 'Username',
        },
        {
            name: 'Code',
            optional: true
        },
        {
            name: 'RetailId',
            title: 'Retail Id',
            nullable: true,
            type: InputTypes.Number
        },
        {
            name: 'RetailCode',
            title: 'Retail Code'
        },
        {
            name: 'Group',
            title: 'Player Group',
            type: InputTypes.Select,
            exact: true,
            nullable: true,
            data: {
                source: 'myPlayerGroups',
                key: 'Id',
                title: ['Name']
            }
        },
        CurrencyFilterField,
        {
            name: 'Retails',
            title: 'Only Retails',
            type: InputTypes.Bool
        }
    ]
};


const resultModel = {
    name: 'result-manual-tickets',
    fields: [
        {
            name: 'ExternalTicketId',
            title: 'Ticket Id',
            render: (val, row, field, context, tableContext) => {
                row.Product = 0;
                return <TicketId id={val} onChange={(ticket) => {

                    var data = tableContext.getData();
                    var found = data.find(x => x.Id == ticket.id);
                    found.Status = ticket.status;
                    found.Win = ticket.win;
                    tableContext.setData(data);
                }} />;
            }
        },
        {
            name: 'EntryDate',
            title: 'Date',
            type: InputTypes.DateTime
        },
        {
            name: 'Updated',
            title: 'Update',
            type: InputTypes.DateTime
        },
        {
            name: 'Operator',
            title: 'Operator'
        },
        {
            name: 'RetailName',
            title: 'Retail'
        },
        {
            name: 'Group',
            title: 'Group',
            hidden: true
        },
        {
            name: 'PlayerId',
            title: 'Player Id',
            hidden: true
        },
        {
            name: 'Username',
            title: 'Username',
            render: (val, row) => {
                return <a style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
                    gotoProfile(row.PlayerId);
                }}>{val}</a>;
            }
        },
        {
            name: 'Type',
            title: 'Betting Type',
            type: InputTypes.Select,
            values: SportsBetTypes
        },
        {
            name: 'TicketType',
            title: 'Ticket Type',
            type: InputTypes.Select,
            values: SportsTicketTypes
        },
        {
            name: 'Status',
            title: 'Status',
            render: (val, row) => {
                if (row.TicketType == 2 && row.ChildTickets > 1) {
                    if (row.OpenTickets > 0) {
                        return `${row.ChildTickets} / ${row.OpenTickets}`;
                    }
                    return 'closed';
                }
                return renderBetSlipStatus(row);
            }
        },
        {
            name: 'BonusWallet',
            title: 'Bonus Wallet',
            hidden: true
        },
        {
            name: 'BonusName',
            title: 'Bonus Name',
            hidden: true
        },
        {
            name: 'Bet',
            title: 'Stake',
            type: InputTypes.Number,
            export: (val, row) => currency(val, 2, row.Currency),
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            exportFooter: (val) => footerCurrencyValues(val, true),
            renderFooter: (val) => footerCurrencyValues(val)
        },
        {
            name: 'Odds',
            title: 'Odds',
            type: InputTypes.Number
        },
        {
            name: 'PossibleWin',
            title: 'Possible Win',
            type: InputTypes.Number,
            export: (val, row) => currency(val, 2, row.Currency),
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            exportFooter: (val) => footerCurrencyValues(val, true),
            renderFooter: (val) => footerCurrencyValues(val)
        },
        {
            name: 'TicketBonusName',
            title: 'Ticket Bonus Name',
            hidden: true,
        },
        {
            name: 'TicketBonusPercentage',
            title: 'Ticket Bonus %',
            type: InputTypes.Number,
            hidden: true,
            render: (val) => {
                if (!val) return;
                return `%${val}`;
            }
        },
        {
            name: 'TicketBonusAmount',
            title: 'Ticket Bonus Amount',
            type: InputTypes.Number,
            hidden: true,
            export: (val) => val,
            render: (val, row) => {
                if (row.TicketBonusPercentage) {
                    return <flex className='vertical'>
                        <span>{val}</span>
                        <flex className='gap-5'>
                            <span>{`%${row.TicketBonusPercentage}`}</span>
                            <Currency amount={(row.TicketBonusLimit > 0 && row.TicketBonusLimit < row.TicketBonusAmount) ? row.TicketBonusLimit : row.TicketBonusAmount} sign={row.Currency} />
                        </flex>
                    </flex>;
                }

                return val;
            }
        },
        {
            name: 'Win',
            title: 'Win',
            type: InputTypes.Number,
            export: (val, row) => currency(val, 2, row.Currency),
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            exportFooter: (val) => footerCurrencyValues(val, true),
            renderFooter: (val) => footerCurrencyValues(val)
        },
        {
            name: 'TaxAmount',
            title: 'Tax',
            type: InputTypes.Number,
            hidden: true,
            export: (val, row) => currency(val, 2, row.Currency),
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
        },
        {
            name: 'GGR',
            title: 'GGR',
            type: InputTypes.Number,
            export: (val, row) => currency(val, 2, row.Currency),
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            exportFooter: (val) => footerCurrencyValues(val, true),
            renderFooter: (val) => footerCurrencyValues(val)
        }
    ],
    onRenderClassName: (row, className) => {
        if (row.Status == 2) {
            if (row.TicketBonusConsumed) {
                className = 'player-row-bonus';
            } else {
                className = 'player-row-lost';
            }

        }
        if (row.Status == 1) {
            className = 'player-row-win';
        }
        if (row.Status == 3) {
            className = 'player-row-cashout';
        }

        if (row.Status == 5) {
            className = 'player-row-cashback';
        }


        return className;
    }
};


export const ReportTicketsSportBetting = () => {
    const user = useSelector((state) => state.user.loginInformation);
    const singleOperator = user.UserPrivileges.Operators.length == 1;


    return <FluxSearch
        model={searchModel}
        endpoint={TicketEndpoints.ManualTickets}
        resultModel={resultModel}
        onPrepareResultModel={(model) => {
            var copy = { ...model };

            if (singleOperator) {
                model.fields.find(x => x.name == 'Operator').hidden = true;
            }
            return copy;
        }}
    />;
};
