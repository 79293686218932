/* eslint-disable react/display-name */

import React, { useEffect, useState } from 'react';

import { FluxCard } from '../../../../components/card/FluxCard';
import { Table } from '../../../../components/table/Table';




export const FreeBetPrizeSettings = (props) => {
    const { promotion, currencies, onChange } = { ...props };
    const [configuration, setConfiguration] = useState(null);


    useEffect(() => {

        var c = { ...promotion.Configuration };

        if (!c.Prize) {
            c.Prize = {};
        }

        currencies.map(x => {
            if (!c.Prize[x.CurrencyCode]) {
                c.Prize[x.CurrencyCode] = {
                    Currency: x.CurrencyCode,
                    Prize: 0
                };
            }
        });

        setConfiguration(c);

    }, []);


    useEffect(() => {
        if (!configuration) return;
        var copy = { ...promotion };
        copy.Configuration = configuration;
        onChange(copy);
    }, [configuration]);


    const updateRow = (e, currency) => {
        var value = parseFloat(e.target.value);
        var name = e.target.name;
        var copy = { ...configuration };
        copy.Prize[currency][name] = value;
        setConfiguration(copy);
    };

    if (!configuration) return <></>;
    return <FluxCard>
        <flex className='vertical gap-10'>
            <Table className='free-bet-prize-settings' model={{
                fields: [
                    {
                        name: 'Currency',
                        title: 'Currency'
                    },
                    {
                        name: 'Prize',
                        title: 'Prize',
                        render: (val, row) => {
                            return <input type='number' name={'Prize'} defaultValue={val} onChange={(e) => updateRow(e, row.Currency)} />;
                        }
                    }
                ]
            }} data={Object.values(configuration.Prize)} />
        </flex>
    </FluxCard>;
};