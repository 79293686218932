import './_form-input.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../../icon/Icon';
import { isNullOrEmpty } from '../../../lib/Helpers/StringHelper';
import { lang } from '../../../lib/Localization/language';

export const MultipleSelectInput = (props) => {
    const [value, setValue] = useState(null);

    useEffect(() => {
        const field = props.field;

        if (props.data && props.data[field.name] !== undefined) {
            setValue(props.data[field.name]);
            return;
        }

        if (!isNullOrEmpty(props.field.value)) setValue(props.field.value);
    }, [props.data]);


    useEffect(() => {
        if (value == null && props.field.nullable) {
            props.onChange(props.field, value);
            return;
        }
        if (isNullOrEmpty(value)) {
            return;
        }
        props.onChange(props.field, value);
    }, [value]);


    return <div className='flex input-bool gap-10'>
        {
            Object.keys(props.field.values).map(x => {
                var checked = value == props.field.values[x];
                return <flex className='gap-5' key={x} onClick={() => {
                    setValue(props.field.values[x]);
                }}>
                    <span>{lang(x)}</span>
                    <Icon icon={checked ? 'circle-check' : 'circle-dot'} type={checked ? 'fa-solid' : 'fa-solid'} />
                </flex>;
            })
        }

    </div>;
};

MultipleSelectInput.propTypes = {
    field: PropTypes.object,
    data: PropTypes.object,
    onChange: PropTypes.func
};
