export const BonusType = {
    //'Manual': 0,
    1: 'Welcome',
    2: 'Deposit',
    3: 'Discount',
    //'Free Bet': 4,
    5: 'Free Spin',
    //6: 'Betslip Bonus',
    7: 'Near Miss Bonus',
    8: 'Cash Bonus',
    9: 'Slip Lottery',
    10: 'Bet Booster',
    11: 'Multiplier Bonus',
    12: 'Casino Tournaments',
    13: 'Split Free-Bet Bonus',
    14: 'Campaign',
    15: 'Sport Tournament',
    16: 'Referral Program',
    17: 'Free-Bet Bonus',
    18: 'ReFill Bonus',
    19: 'First Time Free Bet',
    20: 'First Time Deposit',
    21: 'Cashback Bonus',
    //'Lottery Bonus': 99
};