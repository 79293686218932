/* eslint-disable react/display-name */
import React from 'react';
import { ReportEndpoints } from '../../../../v2/Lib/Api/Api';
import { Currency } from '../../../components/currency/Currency';
import { InputTypes } from '../../../components/form/InputTypes';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { CurrencyFilterField } from '../../../lib/Models/CurrencyModel';


const searchModel = {
    fields: [
        {
            name: 'EventId',
            title: 'Event Id',
            type: InputTypes.Number
        },
        {
            name: 'PlayerId',
            title: 'Player Id',
            type: InputTypes.Number
        },
        {
            name: 'Username'
        },
        {
            name: 'Code'
        },
        {
            name: 'RetailId',
            title: 'Retail Id',
            nullable: true,
            type: InputTypes.Number
        },
        {
            name: 'RetailCode',
            title: 'Retail Code'
        },
        {
            name: 'Group',
            title: 'Player Group',
            type: InputTypes.Select,
            exact: true,
            nullable: true,
            data: {
                source: 'myPlayerGroups',
                key: 'Id',
                title: ['Name']
            }
        },
        CurrencyFilterField,
        {
            name: 'Retails',
            title: 'Only Retails',
            type: InputTypes.Bool
        }
    ]
};


const resultModel = {
    fields: [
        {
            name: 'Market'
        },
        {
            name: 'Currency'
        },
        {
            name: 'Bets'
        },
        {
            name: 'BonusTickets',
            title: 'Bonus Bets'
        },
        {
            name: 'Stakes',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />
        },
        {
            name: 'BonusStakes',
            title: 'Bonus Stakes',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />
        },
        {
            name: 'TicketBonusAmount',
            title: 'Ticket Bonus',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />
        },
        {
            name: 'Win',
            title: 'Win',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />
        },
        {
            name: 'BonusWin',
            title: 'Bonus Win',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />
        },
        {
            name: 'Profit',
            title: 'Profit',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />
        }
    ]
};

export const ReportEventSummary = () => {
    return <FluxSearch
        model={searchModel}
        endpoint={ReportEndpoints.SportEventRevenueReport}
        resultModel={resultModel}
    />;
};
