/* eslint-disable react/display-name */

import React, { useEffect, useState } from 'react';
import { Modal } from '../../../../components/application/FluxApp';
import { Button } from '../../../../components/button/Button';
import { ActionButton, FluxCard } from '../../../../components/card/FluxCard';
import { SelectGameProviders } from '../../../../components/dataControls/SelectGameProviders';
import { FluxModal } from '../../../../components/modal/FluxModal';
import { Table } from '../../../../components/table/Table';
import { CasinoTournamentEndpoints, DataEndpoints } from '../../../../lib/API/Endpoints';
import { API } from '../../../../lib/API/Api';
import { FluxForm } from '../../../../components/form/FluxForm';
import { FluxInput } from '../../../../components/form/Components/FluxInput';
import { InputTypes } from '../../../../components/form/InputTypes';
import { lang } from '../../../../lib/Localization/language';
import { FluxNotice } from '../../../../components/notification/FluxNotice';
import { ConfirmationModal } from '../../../../components/modal/ConfirmationModal/ConfirmationModal';



const PrizeTypes = {
    Item: 'Item',
    Money: 'Money',
    Freespin: 'Freespin'
};

const TournamentStatus = {
    1: 'Announcement',
    2: 'Registration',
    3: 'Start',
    4: 'Finish',
    5: 'Cancel'
};



export const PrizeConfiguration = (props) => {
    const { data, onChange } = { ...props }
    const [prizes, setPrizes] = useState(data?.Prizes ?? []);
    const [currencies, setCurrencies] = useState(null);



    useEffect(() => {
        API.post(DataEndpoints.EnabledCurrencies, null).then((result) => {
            setCurrencies(result.result);
        });
    }, []);


    const addNew = (row) => {
        var copy = [...prizes];
        copy.push(row);
        setPrizes(copy);
    };


    useEffect(() => {
        if (!prizes) return;
        if (onChange) {
            onChange(prizes);
        }
    }, [prizes]);

    const updateData = (row, column, value) => {
        var copy = [...prizes];
        copy.find(x => x.Place == row.Place)[column] = value;
        setPrizes(copy);
    };

    const renderRows = () => {
        return <flex className='vertical gap-5'>
            {prizes.map((x, index) => {
                return <div className='grid gap-20 border radius fx-shaddow columns-6' key={index}>
                    <flex className='vertical padding gap-5'>
                        <span className='bold'>{lang('Place')}</span>
                        <FluxInput field={{ type: InputTypes.Number, name: 'Place', value: x.Place, readonly: true }} value={x.place} data={x} />
                    </flex>
                    <flex className='vertical padding gap-5'>
                        <span className='bold'>{lang('Name')}</span>
                        <FluxInput field={{ type: InputTypes.Text, name: 'Name', value: x.Name }} onChange={(field, value) => {
                            updateData(x, 'Name', value);
                        }} />
                    </flex>
                    <flex className='vertical padding gap-5'>
                        <span className='bold'>{lang('Type')}</span>
                        <FluxInput field={{ type: InputTypes.Select, name: 'Type', values: PrizeTypes, value: x.Type }} onChange={(field, value) => {
                            updateData(x, 'Type', value);
                        }} />
                    </flex>
                    <flex className='padding fx-border-left fx-border-right justify-content-center'>
                        <flex className='vertical gap-5'>
                            <span className='bold'>{lang('Currency')}</span>
                            <select defaultValue={x.CurrencyCode ?? currencies[0].CurrencyCode} onChange={(e) => {
                                updateData(x, 'CurrencyCode', e.target.value);
                            }}>
                                {currencies.map(x => {
                                    return <option key={x.Id} value={x.CurrencyCode}>{x.CurrencyCode}</option>
                                })}
                            </select>
                        </flex>
                    </flex>
                    <flex className='vertical padding gap-5'>
                        <span className='bold'>{lang('Value')}</span>
                        <FluxInput field={{ type: InputTypes.Number, name: 'Value', value: x.Value }} onChange={(field, value) => {
                            updateData(x, 'Value', value);
                        }} />
                    </flex>
                    <flex className='padding align-right justify-content-center align-items-center'>
                        <Button title='Delete' className={'warning'} onClick={() => {
                            var copy = [...prizes].filter(y => y.place != x.place);
                            copy.map((y, i) => {
                                y.place = i + 1;
                            })
                            setPrizes(copy);
                        }} />
                    </flex>
                </div>
            })
            }
        </flex >;
    };

    if (!currencies) return <></>;
    return <FluxCard title='Prize configuration'>
        {renderRows()}
        <flex className='padding'>
            <Button title='Add new' className={'success center'} onClick={() => {
                addNew({ Type: PrizeTypes.Item, Name: '', Place: prizes.length + 1, CurrencyCode: currencies[0].CurrencyCode, Value: '' });
            }} />
        </flex>
    </FluxCard>;
};


export const ProviderTournamentConfiguration = (props) => {
    const { promotion, provider } = { ...props };

    const [model, setModel] = useState(null);
    const [data, setData] = useState(null);
    const [prizes, setPrizes] = useState(null);

    const [status, setStatus] = useState(0);


    useEffect(() => {
        API.post(`${CasinoTournamentEndpoints.GetProviderConfiguration}/${provider.id}`).then((modelResult) => {

            API.post(`${CasinoTournamentEndpoints.GetPromotionConfiguration}/${promotion.Id}`).then((result) => {
                setStatus(result.result.status);
                var tournamentData = result.result?.data;
                if (tournamentData) {
                    tournamentData = JSON.parse(tournamentData);
                    setPrizes(tournamentData.PrizeTypes ?? []);

                    tournamentData.PromoEndDate = new Date(tournamentData.PromoEndDate);
                    setData(tournamentData);
                    //setData({});
                } else {
                    setData({});
                }

                setModel({
                    fields: modelResult.result.concat([{
                        name: 'Prizes',
                        title: 'Prizes',
                        hideTitle: true,
                        type: InputTypes.Custom,
                        component: <PrizeConfiguration data={tournamentData} onChange={(p) => {
                            setPrizes(p);
                        }} />
                    }])
                });

            });

        });
    }, []);

    const updateStatus = () => {
        API.post(`${CasinoTournamentEndpoints.UpdateTournamentState}/${promotion.Id}/${status + 1}`, null, 'please wait', 'unable to update status').then(() => {
            setStatus(status + 1);
        });
    };

    const getNextStatusTitle = () => {
        if (status == 4) return;
        return TournamentStatus[status + 1];
    };

    const proceedToNextStatus = () => {
        switch (status) {
            case 0:
            case 1:
                Modal.open(<ConfirmationModal title='Please confirm' onConfirm={() => {
                    updateStatus();
                }}>
                    <FluxNotice type='information' title='Registration' description={'The tournament will now accept registration of players! You can modify prizes, tournament details until you change the status to Start!'} />
                </ConfirmationModal>);
                return;
            case 2:
                Modal.open(<ConfirmationModal title='Please confirm' onConfirm={() => {
                    updateStatus();
                }}>
                    <FluxNotice type='warning' title='Start' description={'The tournament will now start! From this moment no more players can participate and can not modify the tournament.'} />
                </ConfirmationModal>);
                return;
            case 3:
                Modal.open(<ConfirmationModal title='Please confirm' onConfirm={() => {
                    updateStatus();
                }}>
                    <FluxNotice type='warning' title='Finish' description={'The tournament will finish! You can now distribute prizes.'} />
                </ConfirmationModal>);
                return;
        }
        setStatus(status + 1);
    };


    if (!model) return <></>;

    const enableEdit = status == 0 || status == 1 || status == 2;
    const enableProceedToNextSate = status < 4;

    console.log(status);


    return <flex className={'vertical gap-5'}>
        <FluxCard title='Tournament Status'>
            <flex className='vertical gap-10'>
                {!enableEdit && <FluxNotice type='information' title='Tournament started' description={'You can not edit tournament details after it starts!'} />}
                <flex className='gap-10'>
                    <span className='medium bold'>{lang('Current status')}</span>
                    <span className='medium'>{TournamentStatus[status]}</span>
                </flex>
                {
                    enableProceedToNextSate && <flex className='gap-10'>
                        <span className='medium bold'>{lang('Proceed to next status')}</span>
                        <Button title={getNextStatusTitle()} className={'success'} onClick={() => proceedToNextStatus()} />
                    </flex>
                }
            </flex>
        </FluxCard>
        <FluxCard title='Tournament Players'>

        </FluxCard>

        <FluxCard title='Provider Tournament Configuration' className={enableEdit ? '' : 'disabled'}>
            <FluxForm model={model} data={data} endpoint={`${CasinoTournamentEndpoints.SaveTournamentConfiguration}/${provider.id}/configuration/${promotion.Id}/save`}
                onBeforeSubmit={(formData) => {
                    formData.Prizes = prizes;
                    return formData;
                }}
                confirm={true}
                confirmTitle={'Please confirm'}
                confirmMessage={'Confirm saving tournament settings'} />
        </FluxCard>
    </flex>;
};



export const CasinoTournamentSettings = (props) => {
    let { promotion, currencies, slotsEnabled, liveCasinoDisabled, onChange } = { ...props };
    const [data, setData] = useState(promotion.Configuration.games ?? { rng: false, live: false, gameTypes: {}, providers: {}, disabledGames: {} });


    useEffect(() => {
        var copy = { ...data };

        if (!copy.gameTypes) {
            copy.gameTypes = {};
        }

        if (!copy.providers) {
            copy.providers = {};
        }

        if (!copy.disabledGames) {
            copy.disabledGames = {};
        }

        if (slotsEnabled) {
            copy.rng = true;
        }

        if (liveCasinoDisabled) {
            copy.casino = false;
        }

        setData(copy);

    }, []);

    useEffect(() => {
        if (!data) return;
        promotion.Configuration.games = data;
        onChange({ ...promotion });
    }, [data]);



    const addProvider = () => {
        var provider = null;
        Modal.open(<FluxModal title='Select Provider' footer={<flex className='gap-10 center'>
            <Button className='success' title='Add' onClick={() => {
                Modal.close();
                var copy = { ...data };
                copy.providers[provider.id] = {
                    id: provider.id,
                    name: provider.name
                };
                setData(copy);
            }} />
            <Button title='Cancel' onClick={() => Modal.close()} />
        </flex>}>
            <SelectGameProviders filter={(x) => {
                if (data.providers[x.id]) {
                    return false;
                }
                return true;
            }} onChange={(data) => provider = data} />
        </FluxModal>);
    };


    const removeProvider = (provider) => {
        var copy = { ...data };
        delete copy.providers[provider.id];
        setData(copy);
    };



    return <flex className='vertical gap-10'>
        <FluxCard title='Providers' buttons={<ActionButton title='Add' icon='plus-square' onClick={() => addProvider()} />}>
            <Table className='fx-borders fx-shadow' model={{
                fields: [
                    {
                        name: 'id',
                        title: 'Id'
                    },
                    {
                        name: 'name',
                        title: 'Name',
                    }
                ]
            }} data={data?.providers ? Object.values(data.providers) : []} buttons={(row) => {
                return <Button title='Remove' className={'warning'} onClick={() => removeProvider(row)} />;
            }} />
        </FluxCard>
        <ProviderTournamentConfiguration provider={{ name: 'EGT', id: 191536 }} promotion={promotion} />
    </flex>;
};