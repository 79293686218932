import './_notification.scss';

import React from 'react';
import PropTypes from 'prop-types';

export const NotificationType = {
    Success: 'success',
    Information: 'information',
    Warning: 'warning',
    Error: 'error'
};


export const FluxNotification = (props) => {
    return <notification className={`${props.type ? 'notification-' + props.type : ''}${props.className ? ' ' + props.className : ''}`}>{props.children}</notification>;
};

FluxNotification.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
    children: PropTypes.node
};