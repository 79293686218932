/* eslint-disable react/display-name */
import React from 'react';
import { useSelector } from 'react-redux';
import { ReportEndpoints } from '../../../../v2/Lib/Api/Api';
import { Currency } from '../../../components/currency/Currency';
import { InputTypes } from '../../../components/form/InputTypes';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { footerCurrencyValues } from '../../../lib/Helpers/Reports/Footer';
import { calculateRTP } from '../../../lib/Helpers/RTPHelper';

export const searchModel = {
    fields: [
        {
            name: 'OperatorId',
            title: 'Operator',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'operators',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'EventTime',
            title: 'Date',
            type: InputTypes.DateTime,
            between: true,
            value: {
                start: DateHelpers.yesterday(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'Retails',
            title: 'Only Retails',
            type: InputTypes.Bool
        }
    ]
};

export const resultModel = {
    fields: [
        {
            name: 'Operator'
        },
        {
            name: 'Currency',
            title: 'Currency'
        },
        {
            name: 'ProviderName',
            title: 'Provider'
        },
        {
            name: 'Aggregator',
            title: 'Aggregator'
        },
        {
            name: 'Bet',
            title: 'Bet',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'Cancel',
            title: 'Cancel',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'Win',
            title: 'Win',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'GGR',
            title: 'GGR',
            render: (val, row) => <Currency amount={row.Bet - row.Win} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'RTP',
            title: 'RTP',
            type: InputTypes.Number,
            render: (val, row) => calculateRTP(row)
        },
        {
            name: 'TotalHands',
            title: 'Total Games',
            type: InputTypes.Number,
        }
    ]
};

export const ReportCasinoProviderCommision = () => {
    const user = useSelector((state) => state.user.loginInformation);
    const singleOperator = user.UserPrivileges.Operators.length == 1;

    searchModel.fields.find(x => x.name == 'OperatorId').value = user.UserPrivileges.PointOfView;

    
    return <FluxSearch
        model={searchModel}
        endpoint={ReportEndpoints.CasinoProviderCommissionReport}
        resultModel={resultModel}
        onPrepareResultModel={(model) => {
            var copy = { ...model };

            if (singleOperator) {
                model.fields.find(x => x.name == 'Operator').hidden = true;
            }
            return copy;
        }}
    />;
};
