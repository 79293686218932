import './_image-upload.scss';
import React, { useEffect, useState } from 'react';

export const ImageUpload = (props) => {
    const { onImageData } = { ...props };
    const [file, setFile] = useState('');

    const fileRef = React.createRef();
    const imageRef = React.createRef();
    const dropZone = React.createRef();

    const [droppedImage, setDroppedImage] = useState(null);

    const handleChange = (e) => {
        const data = e.target.files[0];


        setFile(data);
    };


    useEffect(() => {
        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
            dropZone.current.addEventListener(eventName, (e) => {
                e.preventDefault();
                e.stopPropagation();
            }, false);
            document.body.addEventListener(eventName, (e) => {
                e.preventDefault();
                e.stopPropagation();
            }, false);
        });

        ['dragenter', 'dragover'].forEach(eventName => {
            dropZone.current.addEventListener(eventName, (e) => {
                e.target.focus();
            }, false);
        });

        ['dragleave', 'drop'].forEach(eventName => {
            dropZone.current.addEventListener(eventName, () => {

            }, false);
        });

        dropZone.current.addEventListener('drop', (e) => {
            var dt = e.dataTransfer;
            var files = dt.files;
            var file = files[0];
            try {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = () => {

                    setDroppedImage(reader.result);
                };
            } catch (e) {
                console.log(e);
            }

        }, false);


    }, []);

    return <flex className='vertical image-upload'>
        {(!file && !droppedImage) && <form>
            <input type="file" onChange={handleChange} ref={fileRef} className='hidden' />
            <flex className='dropzone' ref={dropZone} onClick={() => {
                fileRef.current.click();
            }}>
                <p>Drag and drop some files here, or click to select files</p>
            </flex>
        </form>}
        {file && <flex className='padding image-content'>
            <img src={URL.createObjectURL(file)} ref={imageRef} onLoad={(e) => {
                var element = document.createElement('img');
                element.src = e.target.src;

                var canvas = document.createElement('canvas');
                canvas.width = element.clientWidth;
                canvas.height = element.clientHeight;

                var ctx = canvas.getContext('2d');
                ctx.drawImage(element, 0, 0);

                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = function () {
                    var base64data = reader.result;
                    onImageData({
                        data: file,
                        key: Date.now(),
                        base64: base64data
                    });
                };
            }} />
        </flex>}
        {droppedImage && <flex className='padding image-content'>
            <img src={droppedImage} ref={imageRef} onLoad={(e) => {
                var element = document.createElement('img');
                element.src = e.target.src;

                onImageData({
                    data: null,
                    key: Date.now(),
                    base64: element.src
                });

            }} />
        </flex>}
    </flex>;
};