import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FluxCard } from '../../../../../card/FluxCard';
import { Button } from '../../../../../button/Button';
import { FluxForm } from '../../../../../form/FluxForm';
import { templateModel } from './RiskTemplates';
import { MarketLiabilitySettings } from './MarketLiabilitySettings';


export const AddNewTemplate = (props) => {
    const { template, onUpdateData } = { ...props };
    const [data, setData] = useState(null);
    const user = useSelector((state) => state.user);

    const [showMarketSettings, toggleShowMarketSettings] = useState(false);

    useEffect(() => {
        if (!data) return;
        onUpdateData(data, user);
    }, [data]);

    return <FluxCard title={<flex className='gap-10 fit'>
        <Button title={'General settings'} className={!showMarketSettings ? 'success' : ''} onClick={() => toggleShowMarketSettings(false)} />
        <flex className='align-right hidden'>
            <Button title={'Market settings'} className={showMarketSettings ? 'success' : ''} onClick={() => toggleShowMarketSettings(true)} />
        </flex>
    </flex>} className={'no-padding risk-template-editor-card'}>
        <FluxForm
            className={showMarketSettings ? 'hidden' : ''}
            data={template ?? {}}
            buttons={null}
            onChange={(d) => {
                setData(d);
            }}
            model={templateModel}>
        </FluxForm>
        {showMarketSettings && <MarketLiabilitySettings className={showMarketSettings ? '' : 'hidden'} />}
    </FluxCard>;
};
