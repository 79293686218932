import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { appHistory } from '../../../..';
import { toCamelCase } from '../../../../components/Centrum/helpers';
import { Button } from '../../../components/button/Button';
import { FluxCard } from '../../../components/card/FluxCard';
import { FluxInput } from '../../../components/form/Components/FluxInput';
import { InputTypes } from '../../../components/form/InputTypes';
import { Icon } from '../../../components/icon/Icon';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { API } from '../../../lib/API/Api';
import { TransactionEndpoints } from '../../../lib/API/Endpoints';
import { lang } from '../../../lib/Localization/language';
import { CurrencyModalField } from '../../../lib/Models/CurrencyModel';
import './_transaction-limit-modify.scss';
import { AutoConfirm, Limit, LimitDataPlayer, LimitDataPlayerGroup, LimitDataProvider, LimitDataRetail, LimitKind, LimitType, Reset, ResetCron } from './TransactionLimit.Model';

const model = {
    fields: [
    ]
};

export const resultTransactionLimitOnUse = {
    fields: [
        { name: 'Id', title: 'Id' },
        { name: 'StartDate', title: 'Start Date', type: InputTypes.DateTime },
        { name: 'EndDate', title: 'End Date', type: InputTypes.DateTime },
        { name: 'Limit', title: 'Limit', type: InputTypes.Number },
        { name: 'CurrentLimit', title: 'Current Limit', type: InputTypes.Number },
        {
            name: 'progress',
            title: 'Progress',
            render: (val, row) => {
                var current = parseInt(row.CurrentLimit ?? 0);
                var limit = parseInt(row.Limit);

                const currentPercentage = (current / limit) * 100;

                const limitPercentage = 100 - currentPercentage;
                return <flex>
                    <flex className='gap-10 stat' >
                        <span className='current'>{current}</span>
                        <flex className='vertical stat-progress'>
                            <flex className='bar'>
                                <flex className='current' style={{ width: (current > limit ? 100 : currentPercentage) + '%' }} />
                                <flex className='limit' style={{ width: (current > limit ? 0 : limitPercentage) + '%' }} />
                            </flex>
                        </flex>
                        <span className='limit'>{limit}</span>
                    </flex>
                </flex>;
            }
        }
    ]
};

export const TransactionLimitModify = () => {
    const [data, setData] = useState({});
    const [focusedField, setFocusedField] = useState(null);
    const [location, setLocation] = useState(null);
    const user = useSelector((state) => state.user).loginInformation;
    const operatorId = user.UserPrivileges.PointOfView;

    useEffect(() => {
        setLocation(window.location.search);
    }, []);

    useEffect(() => {
        if (location !== window.location.search) {
            setLocation(window.location.search);
            const urlParams = new URLSearchParams(window.location.search);
            const id = parseFloat(urlParams.get('id'));
            if (isNaN(id)) {
                return;
            }

            API.post(TransactionEndpoints.TransactionLimitsGet, {
                id: id,
                operatorId: operatorId,
                userId: user.Id
            }).then((result) => {
                if (result?.result) {
                    setData(toCamelCase(result.result));
                }
            }, () => {
                appHistory.push('/setup/transaction-limit-modify');
            });
        }
    });

    const update = (field, value) => {
        setFocusedField(field.name);

        var copy = { ...data };
        if (field.type == InputTypes.Number) {
            value = parseFloat(value);
        }
        if (copy[field.name] == value) {
            return;
        }

        copy[field.name] = value;
        setData(copy);
    };

    const disableEnable = () => {
        API.post(TransactionEndpoints.TransactionLimitsModify, data, 'Disabling/Enabling limit please wait', 'Unable to complete process.').then((result) => {
            if (result.result) {
                var copy = { ...data };
                copy.Id = result.result.id;
                copy.Disabled = result.result.disabled;
                setData(copy);
            }
        });
    };

    const save = () => {
        API.post(TransactionEndpoints.TransactionLimitsModify, data, 'Adding/Editing limit please wait', 'Unable to complete process.').then((result) => {
            if (result.result) {
                var copy = { ...data };
                copy.Id = result.result.id;
                appHistory.push(`/setup/transaction-limit-modify?id=${result.result.id}`);
                setData(copy);
            }
        });
    };

    return <flex className='vertical gap-20 transaction-limit-modify'>
        <FluxCard title={
            <React.Fragment>
                <span>How it works?</span>
                <Button className='align-right success' onClick={() => appHistory.push('/setup/transaction-limits')}>
                    <flex className='gap-5'>
                        <Icon icon='chevrons-left' size='xs' />
                        <span>{lang('All Limits')}</span>
                    </flex>
                </Button>
            </React.Fragment>
        }>In Progress...</FluxCard>
        <FluxCard >
            <flex className='vertical gap-20'>
                <fluxform className='gap-20 grid form-columns-2'>
                    <rows className='gap-5'>
                        <section>
                            <header>
                                <Icon icon='cogs' type='fa-light' /><span>{lang('General')}</span>
                            </header>
                            <fields>
                                <row>
                                    <forminput>
                                        <flex className='input-title fit-children'>
                                            <Icon icon='asterisk' size='xs' type='fa-sharp fa-light' />
                                            <span>{lang(LimitType.title ?? LimitType.name)}</span>
                                        </flex>
                                        <FluxInput
                                            field={{ ...LimitType, readOnly: data && data.Id > 0 }}
                                            onChange={(field, value) => update(field, value)}
                                            data={data}
                                            focused={focusedField == LimitType.name} />
                                    </forminput>
                                </row>
                                <row>
                                    <forminput>
                                        <flex className='input-title fit-children'>
                                            <Icon icon='asterisk' size='xs' type='fa-sharp fa-light' />
                                            <span>{lang(LimitKind.title ?? LimitKind.name)}</span>
                                        </flex>
                                        <FluxInput
                                            field={{ ...LimitKind, readOnly: data && data.Id > 0 }}
                                            onChange={(field, value) => update(field, value)}
                                            data={data}
                                            focused={focusedField == LimitKind.name} />
                                    </forminput>
                                </row>
                                <row>
                                    <forminput>
                                        <flex className='input-title fit-children'>
                                            <Icon icon='asterisk' size='xs' type='fa-sharp fa-light' />
                                            <span>{lang(Limit.title ?? Limit.name)}</span>
                                        </flex>
                                        <FluxInput
                                            field={Limit}
                                            onChange={(field, value) => update(field, value)}
                                            data={data}
                                            focused={focusedField == Limit.name} />
                                    </forminput>
                                </row>
                                <row>
                                    <forminput>
                                        <flex className='input-title fit-children'>
                                            <span>{lang(AutoConfirm.title ?? AutoConfirm.name)}</span>
                                        </flex>
                                        <FluxInput
                                            field={{ ...AutoConfirm, readOnly: data && data.Id > 0 }}
                                            onChange={(field, value) => update(field, value)}
                                            data={data}
                                            focused={focusedField == AutoConfirm.name} />
                                    </forminput>
                                </row>
                            </fields>
                        </section>
                    </rows>
                    <rows className='gap-5'>
                        <section>
                            <header>
                                <Icon icon='info-circle' type='fa-light' /><span>{lang('Detail')}</span>
                            </header>
                            <fields>
                                {data[LimitType.name] == '5' && <row>
                                    <forminput>
                                        <flex className='input-title fit-children'>
                                            <Icon icon='asterisk' size='xs' type='fa-sharp fa-light' />
                                            <span>{lang(LimitDataProvider.title ?? LimitDataProvider.name)}</span>
                                        </flex>
                                        <FluxInput
                                            field={{ ...LimitDataProvider, readOnly: data && data.Id > 0 }}
                                            onChange={(field, value) => update(field, value)}
                                            data={data}
                                            focused={focusedField == LimitDataProvider.name} />
                                    </forminput>
                                </row>}
                                {data[LimitType.name] == '6' && <row>
                                    <forminput>
                                        <flex className='input-title fit-children'>
                                            <Icon icon='asterisk' size='xs' type='fa-sharp fa-light' />
                                            <span>{lang(LimitDataPlayer.title ?? LimitDataPlayer.name)}</span>
                                        </flex>
                                        <FluxInput
                                            field={{ ...LimitDataPlayer, readOnly: data && data.Id > 0 }}
                                            onChange={(field, value) => update(field, value)}
                                            data={data}
                                            focused={focusedField == LimitDataPlayer.name} />
                                    </forminput>
                                </row>}
                                {data[LimitType.name] == '7' && <row>
                                    <forminput>
                                        <flex className='input-title fit-children'>
                                            <Icon icon='asterisk' size='xs' type='fa-sharp fa-light' />
                                            <span>{lang(LimitDataRetail.title ?? LimitDataRetail.name)}</span>
                                        </flex>
                                        <FluxInput
                                            field={{ ...LimitDataRetail, readOnly: data && data.Id > 0 }}
                                            onChange={(field, value) => update(field, value)}
                                            data={data}
                                            focused={focusedField == LimitDataRetail.name} />
                                    </forminput>
                                </row>}
                                {data[LimitType.name] == '8' && <row>
                                    <forminput>
                                        <flex className='input-title fit-children'>
                                            <Icon icon='asterisk' size='xs' type='fa-sharp fa-light' />
                                            <span>{lang(LimitDataPlayerGroup.title ?? LimitDataPlayerGroup.name)}</span>
                                        </flex>
                                        <FluxInput
                                            field={{ ...LimitDataPlayerGroup, readOnly: data && data.Id > 0 }}
                                            onChange={(field, value) => update(field, value)}
                                            data={data}
                                            focused={focusedField == LimitDataPlayerGroup.name} />
                                    </forminput>
                                </row>}
                                <row>
                                    <forminput>
                                        <flex className='input-title fit-children'>
                                            <Icon icon='asterisk' size='xs' type='fa-sharp fa-light' />
                                            <span>{lang(CurrencyModalField.title ?? CurrencyModalField.name)}</span>
                                        </flex>
                                        <FluxInput
                                            field={{ ...CurrencyModalField, readOnly: data && data.Id > 0 }}
                                            onChange={(field, value) => update(field, value)}
                                            data={data}
                                            focused={focusedField == CurrencyModalField.name} />
                                    </forminput>
                                </row>
                                <row>
                                    <forminput>
                                        <flex className='input-title fit-children'>
                                            <Icon icon='asterisk' size='xs' type='fa-sharp fa-light' />
                                            <span>{lang(Reset.title ?? Reset.name)}</span>
                                        </flex>
                                        <FluxInput
                                            field={{ ...Reset, readOnly: data && data.Id > 0 }}
                                            onChange={(field, value) => update(field, value)}
                                            data={data}
                                            focused={focusedField == Reset.name} />
                                    </forminput>
                                </row>
                                {data[Reset.name] == '6' && <row>
                                    <forminput>
                                        <flex className='input-title fit-children'>
                                            <span>{lang(ResetCron.title ?? ResetCron.name)}</span>
                                        </flex>
                                        <FluxInput
                                            field={{ ...ResetCron, readOnly: data && data.Id > 0 }}
                                            onChange={(field, value) => update(field, value)}
                                            data={data}
                                            focused={focusedField == ResetCron.name} />
                                    </forminput>
                                </row>}
                            </fields>
                        </section>
                    </rows>
                </fluxform>
                <div className='flex gap-20 center'>
                    <Button title='Save' className={'success'} onClick={() => {
                        save();
                    }} />
                    {data?.Id > 0 && <Button title={data?.Disabled ? 'Enable' : 'Disable'} className={data?.Disabled ? 'success' : 'warning'} onClick={() => {
                        disableEnable();
                    }}></Button>}
                </div>
            </flex>
        </FluxCard >
        {data?.Id > 0 && <FluxSearch
            title={'Transaction Limits On Use'}
            model={model}
            resultModel={resultTransactionLimitOnUse}
            endpoint={TransactionEndpoints.SearchTransactionLimitsOnUse}
            onPrepareModel={(model) => {
                var copy = { ...model };
                copy.name = 'transaction-limits-on-use-search';
                copy.fields = [];
                copy.fields.push({
                    name: 'TransactionLimitId',
                    title: 'Transaction Limit',
                    hidden: true,
                    readonly: true,
                    type: 'hidden',
                    value: data?.Id
                });
                return copy;
            }}
            buttons={(row) => {
                if (row.Enabled) {
                    return <React.Fragment>
                        <Button className='warning' title='Disable' onClick={() => {
                        }} />
                        <Button className='success' title='Edit' onClick={() => {
                        }} />
                    </React.Fragment>;
                }
            }} />}
    </flex>
};