/* eslint-disable react/display-name */
import './_operator-casino-games.scss';

import React, { useEffect, useState } from 'react';
import { FluxCard } from '../../../components/card/FluxCard';
import { Button } from '../../../components/button/Button';
import { API } from '../../../lib/API/Api';
import { CMSEndpoints, OperatorEndpoints, ProviderEndpoints } from '../../../lib/API/Endpoints';
import { Table } from '../../../components/table/Table';
import { lang } from '../../../lib/Localization/language';
import ReactSelect from 'react-select';




export const OperatorCasinoGames = (props) => {
    const { operator, onClose } = { ...props };
    const [casinoConfiguration, setCasinoConfiguration] = useState(null);

    const [aggregators, setAggregators] = useState(null);
    const [providers, setProviders] = useState(null);

    const [filters, setFilters] = useState({ aggregators: null, providers: null });
    const [games, setGames] = useState(null);

    useEffect(() => {
        API.post(ProviderEndpoints.Get).then((result) => {
            var tempAggregators = {};
            var tempProviders = [];
            result.result.map(x => {
                if (x.Type == 512) return;
                if (x.Type == 128) return;
                if (x.Type == 64) return;
                if (x.Type == 888) return;
                if (x.Type == 98) return;
                if (x.Type == 256) return;

                if (x.AggregatorId) {
                    if (!tempAggregators[x.AggregatorId]) {
                        tempAggregators[x.AggregatorId] = {
                            id: x.AggregatorId,
                            name: x.AggregatorName,
                            providers: []
                        };
                    }

                    tempAggregators[x.AggregatorId].providers.push(x);
                    tempProviders.push(x);
                } else {
                    tempProviders.push(x);
                }
            });

            setAggregators(tempAggregators);
            setProviders(tempProviders.sort((a, b) => a.AggregatorId - b.AggregatorId));

            API.post(OperatorEndpoints.GetOperatorConfiguration(operator.Id)).then((oc) => {
                setCasinoConfiguration(oc.result.casinoConfiguration);
            });

            API.post(CMSEndpoints.GetGames).then((g) => {
                setGames(g.result);
            });

        });

    }, []);

    useEffect(() => {

    }, [games]);

    const filter = () => {
        var result = providers?.filter(x => {
            if ((filters.providers && filters.providers.length > 0) || (filters.aggregators && filters.aggregators.length > 0)) {
                var result = false;
                if (filters.providers && filters.providers.length > 0) {
                    if (filters.providers.find(y => y.value == x.Id)) {
                        result = true;
                    }
                }
                if (filters.aggregators && filters.aggregators.length > 0) {
                    if (filters.aggregators.find(y => y.value == x.AggregatorId)) {
                        result = true;
                    }
                }
                return result;
            }
            return true;
        });
        return result;
    };


    if (!casinoConfiguration) return <></>;

    var aggregatorOptions = [];
    Object.values(aggregators).map(x => {
        aggregatorOptions.push({ label: x.name, value: x.id });
    });


    var providerOptions = [];
    providers.map(x => {
        providerOptions.push({ label: x.Name, value: x.Id });
    });

    var totalProviders = {};
    casinoConfiguration.filter(x => !x.disabledByPlatform).map(x => {
        if (x.type == 512) return;
        if (x.type == 128) return;
        if (x.type == 64) return;
        if (x.type == 888) return;
        if (x.type == 98) return;
        if (x.type == 256) return;
        totalProviders[x.providerId] = 0;
    });


    return <flex className='vertical gap-10 casino-games-configuration'>
        <FluxCard title='Edit Operator Casino Providers' buttons={<flex className='align-right'>
            <Button title='Close' className={'warning'} onClick={() => { onClose(); }} />
        </flex>}>
            <flex className='gap-10'>
                <span>{lang('%1 providers selected', [Object.keys(totalProviders).length])}</span>
                <flex className='align-right'>
                    <Button title='Save' className={'success'} onClick={() => {
                        API.post(OperatorEndpoints.SaveOperatorCasinoConfiguration(operator.Id), casinoConfiguration, 'Please wait', 'Unable to save configuration').then((result) => {
                            console.log(result);
                        });
                    }} />
                </flex>
            </flex>
        </FluxCard>
        <FluxCard title='Filters' className={'no-overflow'}>
            <flex className='gap-20'>
                <flex className='gap-10'>
                    <label>{lang('Aggregators')}</label>
                    <ReactSelect
                        className='react-select'
                        defaultValue={null}
                        options={aggregatorOptions}
                        onChange={(e) => {
                            var copy = { ...filters };
                            copy.aggregators = e;
                            setFilters(copy);
                        }}
                        name={'Aggregators'}
                        hideSelectedOptions={false}
                        closeMenuOnSelect={true}
                        isClearable={true}
                        isSearchable={true}
                        isMulti
                    />
                </flex>
                <flex className='gap-10'>
                    <label>{lang('Providers')}</label>
                    <ReactSelect
                        className='react-select'
                        defaultValue={null}
                        options={providerOptions}
                        onChange={(e) => {
                            var copy = { ...filters };
                            copy.providers = e;
                            setFilters(copy);
                        }}
                        name={'Providers'}
                        hideSelectedOptions={false}
                        closeMenuOnSelect={true}
                        isClearable={true}
                        isSearchable={true}
                        isMulti
                    />
                </flex>
            </flex>
        </FluxCard>
        <FluxCard title='Providers' className={'no-padding'} buttons={<flex className='align-right gap-10'>
            <Button title='Clear All' className={'warning'} onClick={() => {
                var filtered = filter();
                var copy = [...casinoConfiguration];
                copy.map(x => {
                    var found = filtered.find(y => y.Id == x.providerId);
                    if (found) {
                        x.disabledByPlatform = true;
                    }
                });

                setCasinoConfiguration(copy);
            }} />
            <Button title='Select All' className='success' onClick={() => {
                var filtered = filter();
                var copy = [...casinoConfiguration];
                var notFound = [];
                filtered.map(x => {
                    var found = copy.filter(y => y.providerId == x.Id);
                    if (found && found.length) {
                        found.map(y => {
                            y.disabledByPlatform = false;
                        });
                    } else {
                        notFound.push(x);
                    }
                });

                setCasinoConfiguration(copy);
            }} />
        </flex>}>
            <Table model={{
                fields: [
                    {
                        name: 'AggregatorName',
                        title: 'Aggregator'
                    },
                    {
                        name: 'Id',
                        title: 'Id'
                    },
                    {
                        name: 'PName',
                        title: 'Provider Name',
                        render: (val, row) => {
                            return row.Name;
                        }
                    },
                    {
                        name: 'Display'
                    },
                    {
                        name: 'Enabled',
                        render: (val, row) => {
                            var found = casinoConfiguration?.find(x => x.providerId == row.Id)?.disabledByPlatform;
                            var enabled = found == false && found !== undefined;
                            var icon = enabled ? 'fa-solid fa-check-circle' : 'fa-light fa-circle';
                            return <flex className='padding clickable' onClick={() => {
                                var copy = [...casinoConfiguration];
                                copy.filter(x => x.providerId == row.Id).map(x => {
                                    x.disabledByPlatform = enabled;
                                });

                                setCasinoConfiguration(copy);
                            }}>
                                <i className={`${icon} fa-1x`} />
                            </flex>;
                        }
                    }
                ]
            }} data={filter()} />
        </FluxCard>
    </flex>;
};