/* eslint-disable react/display-name */
import React from 'react';
import { useSelector } from 'react-redux';
import { ReportEndpoints } from '../../../../v2/Lib/Api/Api';
import { Currency } from '../../../components/currency/Currency';
import { InputTypes } from '../../../components/form/InputTypes';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { SportsBetTypes, SportsTicketTypes } from '../../../lib/Enums/BettingEnums';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { CurrencyFilterField } from '../../../lib/Models/CurrencyModel';


export const searchModel = {
    fields: [
        {
            name: 'OperatorId',
            title: 'Operator',
            type: InputTypes.Select,
            exact: true,
            nullable: true,
            data: {
                source: 'operators',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'EntryDate',
            title: 'Settlement Date',
            type: InputTypes.DateTime,
            nullable: true,
            between: true,
            value: {
                start: DateHelpers.yesterday(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'PlayerId',
            title: 'Player Id',
        },
        {
            name: 'Type',
            title: 'Betting Type',
            type: InputTypes.Select,
            nullable: true,
            values: SportsBetTypes
        },
        {
            name: 'TicketType',
            title: 'Ticket Type',
            type: InputTypes.Select,
            nullable: true,
            values: SportsTicketTypes
        },
        {
            name: 'RetailId',
            title: 'Retail Id',
            nullable: true,
            type: InputTypes.Number
        },
        {
            name: 'RetailCode',
            title: 'Retail Code'
        },
        {
            name: 'Group',
            title: 'Player Group',
            type: InputTypes.Select,
            exact: true,
            nullable: true,
            data: {
                source: 'myPlayerGroups',
                key: 'Id',
                title: ['Name']
            }
        },
        CurrencyFilterField,
        {
            name: 'Retails',
            title: 'Only Retails',
            type: InputTypes.Bool
        }
    ]
};

const currencyRow = (name, title = name) => {
    return {
        name: name,
        title: title,
        type: InputTypes.Number,
        render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
    };
};

export const resultModel = {
    fields: [
        {
            name: 'Operator'
        },
        {
            name: 'Username'
        },
        {
            name: 'Slips',
        },
        {
            name: 'Currency',
        },
        currencyRow('Stake'),
        currencyRow('Win'),
        currencyRow('CashBet','Cash Turnover'),
        currencyRow('CashWin','Cash Win'),
        currencyRow('CashProfit','Cash Profit'),
        currencyRow('GGR'),
        currencyRow('Cashout'),
        currencyRow('BonusBet', 'Bonus Bet'),
        currencyRow('BonusWin', 'Bonus Win'),
        currencyRow('BonusCashout', 'Bonus Cashout'),
        currencyRow('BonusGGR', 'Bonus GGR'),
    ]
};


export const ReportPlayersSportBetting = () => {
    const user = useSelector((state) => state.user.loginInformation);
    const singleOperator = user.UserPrivileges.Operators.length == 1;


    return <FluxSearch
        model={searchModel}
        endpoint={ReportEndpoints.SportPlayerRevenueSummaryReport}
        resultModel={resultModel}
        onPrepareResultModel={(model) => {
            var copy = { ...model };

            if (singleOperator) {
                model.fields.find(x => x.name == 'Operator').hidden = true;
            }
            return copy;
        }}
    />;
};
