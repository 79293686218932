import React from 'react';
import PropTypes from 'prop-types';

export const FluxPageFooter = (props) => {
    return <footer>
        {props.children}
    </footer>;
};

FluxPageFooter.propTypes = {
    children: PropTypes.node
};