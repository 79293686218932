import './_scores.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { lang } from '../../../../lib/Localization/language';

export const EventScoreSummary = (props) => {
    const event = props.event;
    const [stats, setStats] = useState(null);


    useEffect(() => {
        if (!props.statistics) return;
        var copy = {
            properties: {
                homeScore: null,
                awayScore: null
            },
            periodScores: [

            ]
        };

        copy.properties.homeScore = props.statistics.Scoreboard?.Results?.find(x => x.Position == '1').Value ?? props.statistics.Results?.find(x => x.Position == '1').Value;
        copy.properties.awayScore = props.statistics.Scoreboard?.Results?.find(x => x.Position == '2').Value ?? props.statistics.Results?.find(x => x.Position == '2').Value;

        props.statistics.Periods?.map(x => {
            copy.periodScores.push({
                homeScore: x.Results?.find(x => x.Position == '1').Value,
                awayScore: x.Results?.find(x => x.Position == '2').Value
            });
        });

        if (event.sport.id == 1) {
            var redCardStats = props.statistics.Statistics?.find(x => x.Type == 7);
            if (redCardStats) {
                copy.redCards = {
                    home: redCardStats.Results.find(x => x.Position == '1').Value,
                    away: redCardStats.Results.find(x => x.Position == '2').Value
                }
            }
        }
        setStats(copy);
    }, []);

    const renderRedCards = () => {
        const home = stats?.redCards?.home ?? '0';
        const away = stats?.redCards?.away ?? '0';

        return <div className='event-stat red-cards'>
            <span className={home == '0' ? 'no-card' : ''}>{stats?.redCards?.home}</span>
            <span className={away == '0' ? 'no-card' : ''}>{stats?.redCards?.away}</span>
        </div>
    };

    const getPeriodScore = (detail) => {
        if (stats?.periodScores) {
            var home = stats?.periodScores[stats?.periodScores.length - 2]?.homeScore;
            var away = stats?.periodScores[stats?.periodScores.length - 2]?.awayScore;

            return <div className='event-stat period-score'>
                <span className='score'>{home}</span>
                <span className='score'>{away}</span>
                <span className='sets'>{detail}</span>
            </div>;
        }
    };


    const getGameScore = (detail) => {
        if (stats?.periodScores) {
            var home = stats?.periodScores[stats?.periodScores.length - 1]?.homeScore ?? 'A';
            var away = stats?.periodScores[stats?.periodScores.length - 1]?.awayScore ?? 'A';

            return <div className='event-stat period-score'>
                <span className='score'>{home}</span>
                <span className='score'>{away}</span>
                <span className='sets'>{detail}</span>
            </div>;
        }
    };


    if (!stats) return <></>;

    switch (event.sport.id) {
        case 1:
            if (props.onlyPeriods) return <></>;
            return <div className='event-stats'>
                {stats?.redCards && renderRedCards()}
                <div className='event-stat'>
                    <span className='score'>{stats?.properties?.homeScore}</span>
                    <span className='score'>{stats?.properties?.awayScore}</span>
                </div>
            </div>;

        case 5: // tennis
            return <div className='event-stats'>
                {getGameScore('P')}
                {getPeriodScore('G')}
                <div className='event-stat'>
                    <span className='score'>{stats?.properties?.homeScore}</span>
                    <span className='score'>{stats?.properties?.awayScore}</span>
                    <span className='sets'>{lang('Sets')}</span>
                </div>
            </div>;
        case 20: //table-tennis
        case 23: //voleyball
            return <div className='event-stats'>
                {getGameScore()}
                <div className='event-stat'>
                    <span className='score'>{stats?.properties?.homeScore}</span>
                    <span className='score'>{stats?.properties?.awayScore}</span>
                    <span className='sets'>{lang('Sets')}</span>
                </div>
            </div>;
        default:
            if (props.onlyPeriods) return <></>;
            return <div className='event-stats'>
                <div className='event-stat'>
                    <span className='score'>{stats?.properties?.homeScore}</span>
                    <span className='score'>{stats?.properties?.awayScore}</span>
                </div>
            </div>;
    }


};

EventScoreSummary.propTypes = {
    event: PropTypes.object,
    onlyPeriods: PropTypes.bool,
    statistics: PropTypes.object
};