/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/display-name */

import './_search-leagues.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../button/Button';
import { lang } from '../../../lib/Localization/language';
import { API } from '../../../lib/API/Api';
import { SportEndpoints } from '../../../lib/API/Endpoints';
import { Table } from '../../table/Table';
import ReactSelect from 'react-select';


export const SearchLeagues = (props) => {
    const [selectedLeagues, setSelectedLeagues] = useState(props.leagues);
    const [league, setLeague] = useState(null);
    const [leagues, setLeagues] = useState([]);
    const [searching, setSearching] = useState(false);

    const [selectedSport, selectSport] = useState(null);

    useEffect(() => {
        selectSport({
            label: props.categories[0].name,
            value: props.categories[0].id
        });
    }, []);

    const search = () => {
        setSearching(true);
        var payload = {
            sport: selectedSport?.value ?? 0,
            name: league
        };


        API.post(SportEndpoints.LeaguesByName, payload).then((result) => {
            setSearching(false);
            setLeagues(result?.result);
        });
    }

    return <flex className='vertical gap-10 league-list'>
        <flex className='vertical gap-5'>
            <flex className='vertical'>
                <label className='bold'>{lang('Sport')}</label>
                {selectedSport && <ReactSelect
                    className='react-select'
                    defaultValue={selectedSport}
                    options={props.categories.map(x => {
                        return {
                            label: x.name,
                            value: x.id
                        };
                    })}
                    onChange={(e) => {
                        selectSport(e);
                    }}
                    hideSelectedOptions={false}
                    closeMenuOnSelect={true}
                    isClearable={true}
                    isSearchable={true}
                />
                }
            </flex>

            <label className='bold'>{lang('League name')}</label>
            <flex className='search-event gap-5'>
                <input type='text' className={searching ? 'disabled' : ''}
                    onChangeCapture={(e) => {
                        setLeague(e.target.value);
                    }}
                    onKeyUp={(e) => {
                        if (!league) return;
                        if (league.length < 3) return;
                        if (searching) return;
                        if (e.key == 'Enter') {
                            search();
                        }
                    }}
                />

                <flex className='align-right'>
                    <Button title='Search' className={searching ? 'disabled' : 'success'} onClick={() => {
                        if (!league) return;
                        if (league.length < 3) return;
                        if (searching) return;

                        search();
                    }} />
                </flex>
            </flex>
        </flex>
        <flex className='table-content-holder'>
            <Table model={
                {
                    fields: [
                        {
                            name: 'id',
                            title: 'Id'
                        },
                        {
                            name: 'sportName',
                            title: 'Sport'
                        },
                        {
                            name: 'fixture',
                            title: 'League',
                            render: (val, row) => {
                                return <flex className='vertical gap-5 align-start'>
                                    <span className='bold'>{row.name}</span>
                                    <flex className='vertical'>
                                        <span>{row.regionName}</span>
                                    </flex>
                                </flex>;
                            }
                        }
                    ]
                }
            } data={leagues ?? []} buttons={(row) => {
                if (selectedLeagues.find(x => x.leagueId == row.id)) {
                    return <Button title='Remove' className={'warning'} onClick={() => {
                        var copy = selectedLeagues.filter(x => x.leagueId != row.id);
                        setSelectedLeagues(copy);
                        props.onChangeLeagues(copy);
                    }} />;
                }
                return <Button title='Add' className={'success'} onClick={() => {
                    var copy = [...selectedLeagues];
                    copy.push({
                        league: row.name,
                        leagueId: row.id,
                        region: row.regionName,
                        regionId: row.region,
                        sport: row.sportName,
                        sportId: row.sport
                    });
                    setSelectedLeagues(copy);
                    props.onChangeLeagues(copy);
                }} />;
            }} />
        </flex>
    </flex>;
};

SearchLeagues.propTypes = {
    sport: PropTypes.number,
    leagues: PropTypes.array,
    onSearch: PropTypes.func,
    onChangeLeagues: PropTypes.func
};