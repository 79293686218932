import './_error-modal.scss';

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FluxModal } from '../FluxModal';
import { Button } from '../../button/Button';
import { Modal } from '../../application/FluxApp';

export const ErrorModal = (props) => {

    const close = (e) => {
        if (e.code == 'Escape') Modal.close();
    };

    useEffect(() => {
        window.addEventListener('keyup', close);
        return () => window.removeEventListener('keyup', close);
    }, []);

    return <FluxModal className='error-modal'
        title={props.title}
        closeButton={null}
        footer={<buttons>
            <Button title='Close' onClick={() => {
                if (props.onClose) {
                    props.onClose();
                    return;
                }
                Modal.close();
            }} />
        </buttons>}>
        {props.children}
    </FluxModal>;
};

ErrorModal.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    footer: PropTypes.node,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func
};
