
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { addDays, addHours, addMonths } from 'date-fns';
import { API } from '../../../lib/API/Api';
import { AffiliateDashboardEndpoints } from '../../../lib/API/Endpoints';
import { FluxCard } from '../../../components/card/FluxCard';
import { lang } from '../../../lib/Localization/language';
import { Button } from '../../../components/button/Button';
import { Currency, currency } from '../../../components/currency/Currency';
// COMPONENT


const convertDate = (d) => {
    var date = new Date(d);

    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
};


const today = (d) => {
    var date = !d ? new Date() : d;

    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} 00:00`;
};



const tonight = (d) => {
    var date = !d ? new Date() : d;
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} 23:59`;
};

const thisMonth = () => {
    var date = new Date();
    date = addDays(date, -date.getDate());
    return new Date(today(date));
};

const lastMonth = () => {
    var date = new Date();
    date = addDays(date, -date.getDate());
    date = addMonths(date, -1);
    return new Date(today(date));
};

export const AffiliateDashboard = (props) => {
    const [startDate, setStartDate] = useState(today());
    const [endDate, setEndDate] = useState(tonight());

    const [dashboard, setDashboardData] = useState(null);

    useEffect(() => {
        fetchDashboard();
    }, []);


    const fetchDashboard = () => {
        var convertedStartDate = new Date(startDate);
        var convertedEndDate = new Date(endDate);

        convertedStartDate = addHours(convertedStartDate, 0).toISOString();
        convertedEndDate = addHours(convertedEndDate, 0).toISOString();

        API.post(`${AffiliateDashboardEndpoints.GetDashboardStats}/${props.affiliate.Id}`, { dateStart: convertedStartDate, dateEnd: convertedEndDate }).then((result) => {
            setDashboardData(result.result);
        });
    };

    if (!dashboard) return <></>;


    return <flex className='vertical gap-10'>
        <flex className='gap-10 surface low border radius fx-shadow padding'>
            <flex className='gap-5'>
                <label>{lang('Start Date')}</label>
                <input type='datetime-local' value={convertDate(startDate)} onChange={(e) => {
                    setStartDate(new Date(e.target.value));
                }} />
                <label>{lang('End Date')}</label>
                <input type='datetime-local' value={convertDate(endDate)} onChange={(e) => {
                    setEndDate(new Date(e.target.value));
                }} />
                <Button title='Today' onClick={() => {
                    setStartDate(today());
                    setEndDate(tonight());
                }} />
                <Button title='Last Month' onClick={() => {
                    setStartDate(addDays(lastMonth(), 1));
                    setEndDate(addDays(thisMonth(), 1));
                }} />
                <Button title='This Month' onClick={() => {
                    setStartDate(thisMonth());
                    setEndDate(tonight());
                }} />
            </flex>
            <Button title='List' className='align-right success' onClick={() => {
                fetchDashboard();
            }} />
        </flex>
        <flex className='gap-5 columns-5'>
            <FluxCard title='Players'>
                <flex className='vertical gap-10'>
                    <flex className='vertical'>
                        <label className='medium'>{lang('All')}</label>
                        <span>{dashboard.totalPlayers}</span>
                    </flex>
                    <div className='border-top' />
                    <flex className='vertical'>
                        <label className='medium'>{lang('New')}</label>
                        <span>{dashboard.registerdPlayers}</span>
                    </flex>
                </flex>
            </FluxCard>

            <FluxCard title='Deposit' buttons={() => <flex>{currency(dashboard.deposits + dashboard.manualDeposits, 2, props.affiliate.Currency)}</flex>}>
                <flex className='vertical gap-10'>
                    <flex className='vertical'>
                        <label className='medium'>{lang('Provider Deposits')}</label>
                        <Currency amount={dashboard.deposits} sign={props.affiliate.Currency} />
                    </flex>
                    <div className='border-top' />
                    <flex className='vertical'>
                        <label className='medium'>{lang('Manual Deposits')}</label>
                        <Currency amount={dashboard.manualDeposits} sign={props.affiliate.Currency} />
                    </flex>
                </flex>
            </FluxCard>
            <FluxCard title='Withdraw' buttons={() => <flex>{currency(dashboard.withdraws + dashboard.manualWithdraws,2, props.affiliate.Currency)}</flex>}>
                <flex className='vertical gap-10'>
                    <flex className='vertical'>
                        <label className='medium'>{lang('Provider Withdraws')}</label>
                        <Currency amount={dashboard.withdraws} sign={props.affiliate.Currency} />
                    </flex>
                    <div className='border-top' />
                    <flex className='vertical'>
                        <label className='medium'>{lang('Manual Withdraws')}</label>
                        <Currency amount={dashboard.manualWithdraws} sign={props.affiliate.Currency} />
                    </flex>
                </flex>
            </FluxCard>
            <FluxCard title='Casino'>
                <flex className='vertical gap-10'>
                    <flex className='gap-20 columns-2'>
                        <flex className='vertical'>
                            <label className='medium'>{lang('GGR')}</label>
                            <Currency amount={dashboard.games.ggr} sign={props.affiliate.Currency} />
                        </flex>
                        <flex className='vertical'>
                            <label className='medium'>{lang('Hands')}</label>
                            <span>{dashboard.games.totalHands}</span>
                        </flex>
                    </flex>
                    <div className='border-top' />
                    <flex className='gap-20 columns-2'>
                        <flex className='vertical'>
                            <label className='medium'>{lang('Bet')}</label>
                            <Currency amount={dashboard.games.bet} sign={props.affiliate.Currency} />
                        </flex>
                        <flex className='vertical'>
                            <label className='medium'>{lang('Win')}</label>
                            <Currency amount={dashboard.games.win} sign={props.affiliate.Currency} />
                        </flex>
                    </flex>
                </flex>
            </FluxCard>
            <FluxCard title='Sports'>
                <flex className='vertical gap-10'>
                    <flex className='gap-20 columns-2'>
                        <flex className='vertical'>
                            <label className='medium'>{lang('GGR')}</label>
                            <Currency amount={dashboard.sports.paid.ggr} sign={props.affiliate.Currency} />
                        </flex>
                        <flex className='vertical'>
                            <label className='medium'>{lang('Total Paid')}</label>
                            <span>{dashboard.sports.paid.total}</span>
                        </flex>
                    </flex>
                    <div className='border-top' />
                    <flex className='gap-10'>
                        <label className='medium'>{lang('Open Tickets')}</label>
                        <span className='align-right'>{dashboard.sports.open.total}</span>
                    </flex>
                    <flex className='gap-20 columns-2'>
                        <flex className='vertical'>
                            <label className='medium'>{lang('Open Stakes')}</label>
                            <Currency amount={dashboard.sports.open.bet} sign={props.affiliate.Currency} />
                        </flex>
                        <flex className='vertical'>
                            <label className='medium'>{lang('Open Possible Win')}</label>
                            <Currency amount={dashboard.sports.open.possibleWin} sign={props.affiliate.Currency} />
                        </flex>
                    </flex>
                </flex>
            </FluxCard>
        </flex>
    </flex>;
};


AffiliateDashboard.defaultProps = {
    groups: [],
    commission: 0,
    affiliate: null
};

AffiliateDashboard.propTypes = {
    groups: PropTypes.array,
    commission: PropTypes.number,
    affiliate: PropTypes.object,
    relatedPlayer: PropTypes.array
};
