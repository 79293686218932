/* eslint-disable react/display-name */
import PropTypes from 'prop-types';
import React from 'react';
import './_search-rejected-tickets.scss';

import { useSelector } from 'react-redux';
import { Modal } from '../../../components/application/FluxApp';
import { FluxCard } from '../../../components/card/FluxCard';
import { Currency } from '../../../components/currency/Currency';
import { InputTypes } from '../../../components/form/InputTypes';
import { FluxModal } from '../../../components/modal/FluxModal';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { Table } from '../../../components/table/Table';
import { TicketEndpoints } from '../../../lib/API/Endpoints';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { gotoProfile } from '../../../lib/Helpers/NavigationHelper';

const renderSportBets = (val) => {
    var converted = JSON.parse(val);
    return <flex className='vertical'>
        {Object.keys(converted).map(selectionId => {
            var selection = converted[selectionId];
            return <flex className='gap-10' key={selectionId}>
                <flex className='gap-5'>
                    <span className='bold'>{selection.League}</span>
                    /
                    <span>{selection.EventName}</span>
                </flex>
                <flex className='gap-5'>
                    <span>[{selection.MarketName}</span>
                    /
                    <span>{selection.SelectionName}]</span>
                </flex>
                <span className='align-right'>{selection.Price}</span>
            </flex>;
        })}
    </flex>;
};

const renderHorseBets = (val) => {
    console.log(val);
};

const model = {
    fields: [
        {
            name: 'OperatorId',
            title: 'Operator',
            type: InputTypes.Select,
            exact: true,
            nullable: true,
            data: {
                source: 'operators',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'EntryDate',
            title: 'Date',
            type: InputTypes.DateTime,
            between: true,
            value: {
                start: DateHelpers.yesterday(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'Group',
            title: 'Player Group',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'myPlayerGroups',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'PlayerId',
            title: 'Player Id',
            type: InputTypes.Number
        },
        {
            name: 'Type',
            title: 'Type',
            type: InputTypes.Select,
            nullable: true,
            values: {
                0: 'Prematch',
                1: 'Live',
                2: 'Prematch & Live'
            }
        },
        {
            name: 'TicketType',
            title: 'Ticket Type',
            type: InputTypes.Select,
            nullable: true,
            values: {
                0: 'Single',
                1: 'Combined'
            }
        },
        {
            name: 'Username',
            exact: false
        },
    ]
};

const displayRejectedTicketReasonDescPopup = () => {
    var list = [];
    Object.keys(RejectedTicketReasonDescriptions).map(x => {
        list.push({
            Value: RejectedTicketReasonDescriptions[x],
            Title: x
        });
    });
    Modal.open(<FluxModal title={'Rejected Ticket Reason Descriptions'} >
        <FluxCard>
            <Table
                tableClassName={'rejected-tickets-descriptions-table'}
                model={{
                    fields: [
                        {
                            name: 'Title',
                        },
                        {
                            name: 'Value',
                        },
                    ]
                }}
                data={list}
            />
        </FluxCard>
    </FluxModal >);
};

export const TicketErrorTypes = {
    0: 'None',
    1: 'Player Limits',
    2: 'Betslip Limits',
    3: 'Event Limits',
    4: 'Market Selection Limits',
};

export const RejectedTicketReasonDescriptions = {
    RM001: 'Betslip limits, if group config has enabled the stop prematch and slip is from prematch ticket',
    RM002: 'Betslip limits, if group config has enabled the stop live and slip is from live ticket',
    RM003: 'Betslip limits, if group config has reached its max bet amount with current slip and overask is enabled on config.',
    RM004: 'Betslip limits, if group config has reached its max bet amount with current slip and overask is disabled on config.',
    RM005: 'Betslip limits, if group config has reached its max odds amount with current slip and overask is enabled on config.',
    RM006: 'Betslip limits, if group config has reached its max odds amount with current slip and overask is disabled on config.',
    RM007: 'Betslip limits, if group config has reached its max payout amount with current slip and overask is enabled on config.',
    RM008: 'Betslip limits, if group config has reached its max payout amount with current slip and overask is disabled on config.',
    RM009: 'Event Limits, if player + market + event uniqueness reaches its max stake or payout limit with current slip and overask is enabled on template config.',
    RM010: 'Event Limits, if player + market + event uniqueness reaches its max stake or payout limit with current slip and overask is disabled on template config.',
    RM011: 'Event Limits, if event reaches its max liability limit with current slip and overask is enabled on template config.',
    RM012: 'Event Limits, if event reaches its max liability limit with current slip and overask is disabled on template config.',
    RM021: 'Player limits, if group config has enabled the stop prematch and slip is from prematch ticket',
    RM022: 'Player limits, if group config has enabled the stop live and slip is from live ticket',
    RM023: 'Player limits, if group config has reached its max bet amount with current slip and overask is enabled on config.',
    RM024: 'Player limits, if group config has reached its max bet amount with current slip and overask is disabled on config.',
    RM025: 'Player limits, if group config has reached its max odds amount with current slip and overask is enabled on config.',
    RM026: 'Player limits, if group config has reached its max odds amount with current slip and overask is disabled on config.',
    RM027: 'Player limits, if group config has reached its max payout amount with current slip and overask is enabled on config.',
    RM028: 'Player limits, if group config has reached its max payout amount with current slip and overask is disabled on config.',
    RM029: 'Player limits, if group config has reached its min bet amount with current slip and overask is enabled on config.',
    RM030: 'Player limits, if group config has reached its min bet amount with current slip and overask is disabled on config.',
    RM031: 'Betslip limits, if group config has reached its minumum selection amount with current slip and overask is enabled on config.',
    RM032: 'Betslip limits, if group config has reached its minumum selection amount with current slip and overask is disabled on config.',
    RM033: 'Betslip limits, if group config has reached its maximum selection amount with current slip and overask is enabled on config.',
    RM034: 'Betslip limits, if group config has reached its maximum selection amount with current slip and overask is disabled on config.',
    RM035: 'Player limits, if group config has reached its minumum selection amount with current slip and overask is enabled on config.',
    RM036: 'Player limits, if group config has reached its minumum selection amount with current slip and overask is disabled on config.',
    RM037: 'Player limits, if group config has reached its maximum selection amount with current slip and overask is enabled on config.',
    RM038: 'Player limits, if group config has reached its maximum selection amount with current slip and overask is disabled on config.',
    RM039: 'Betslip Limits, if group config line is disabled on config.',
    RM040: 'Player Limits, if group config line is disabled on config.',
    RM041: 'Bonus Balance, Betslip Market Selection Limits, Player tried to place bet on same market, different selection of event',
    RM042: 'Bonus Balance, Same IP from different Player bet',
    RM043: 'Betslip limits, if group config has enabled the stop cashout',
    RM044: 'Player limits, if group config has enabled the stop cashout',
};


export const RejectedTicketModel = {
    fields: [
        {
            name: 'Operator'
        },
        {
            name: 'EntryDate',
            title: 'Date',
            type: InputTypes.DateTime
        },
        {
            name: 'RejectType',
            title: 'Limit Type',
            render: (val) => {
                var reason = val;
                if (val) {
                    reason = TicketErrorTypes[val];
                } else {
                    reason = TicketErrorTypes[0];
                }
                return reason;
            }
        },
        {
            name: 'RejectSubType',
            title: 'Sub Type',
            render: (val) => {
                if (val) {
                    const data = val.split(' | ');
                    return <div>{data.map((element, index) => {
                        return <p key={index}> {element}</p>;
                    })}</div>;
                }
                return val;
            }
        },
        {
            name: 'CurrentData',
            title: 'Current Data',
        },
        {
            name: 'LimitData',
            title: 'Limit Data',
        },
        {
            name: 'Source',
            title: 'Source'
        },
        {
            name: 'Reason',
            title: 'Reason',
            render: (val) => {
                var reason = val;
                if (val) {
                    var d = val?.toString()?.trim()?.split(' ')[0];
                    reason = RejectedTicketReasonDescriptions[d];
                }
                return <a title={reason} style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
                    displayRejectedTicketReasonDescPopup();
                }}>{val}</a>;
            }
        },
        {
            name: 'Group',
            title: 'Group',
            hidden: true
        },
        {
            name: 'PlayerId',
            title: 'Player Id',
            hidden: true
        },
        {
            name: 'Username',
            title: 'Username',
            render: (val, row) => {
                return <a style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
                    gotoProfile(row.PlayerId);
                }}>{val}</a>;
            }
        },
        {
            name: 'OverAsk',
            title: 'OverAsk',
            type: InputTypes.Bool
        },
        {
            name: 'Type',
            title: 'Betting Type',
            render: (val) => {
                switch (val) {
                    case 0: return 'Prematch';
                    case 1: return 'Live';
                    case 2: return 'Prematch & Live';
                }
            }
        },
        {
            name: 'TicketType',
            title: 'Ticket Type',
            render: (val) => {
                switch (val) {
                    case 0: return 'Single';
                    case 1: return 'Combined';
                }
            }
        },
        {
            name: 'Bets',
            title: 'Bets',
            render: (val, row) => {
                switch (row.ProviderId) {
                    case 42000:
                        return renderSportBets(val, row);
                    case 999:
                        return renderHorseBets(val, row);
                }
            }
        },
        {
            name: 'Stake',
            title: 'Stake',
            type: InputTypes.Number,
            render: (val, row) => <Currency amount={val} sign={row.Currency} />
        },
        {
            name: 'Odds',
            title: 'Odds',
            type: InputTypes.Number
        }
    ]
};



export const SearchRejectedTickets = (props) => {
    const user = useSelector((state) => state.user.loginInformation);
    const singleOperator = user.UserPrivileges.Operators.length == 1;

    const title = () => {
        return 'Rejected Tickets';
    };


    return <FluxSearch
        {...props}
        title={title()}
        model={model}
        resultModel={RejectedTicketModel}
        endpoint={TicketEndpoints.SportRejectedTickets}
        searchOnStart
        onPrepareModel={(model) => {
            var copy = { ...model };
            copy.name = `rejected-tickets${props.providerId}}`;
            return copy;
        }}
        onPrepareResultModel={(copy) => {
            copy.name = `rejected-tickets${props.providerId}}`;
            if (singleOperator) {
                copy.fields.find(x => x.name == 'Operator').hidden = true;
            }

            return copy;
        }}
    />;
};




SearchRejectedTickets.propTypes = {
    providerId: PropTypes.number
};