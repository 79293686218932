import React from 'react';
import { FluxTab } from '../../../../../components/tab/FluxTab';
import { PromotionGeneralSettings } from '../PromotionGeneralSettings';
import { PromotionGroupSettings } from '../PromotionGroupSettings';
import { PromotionCodeSettings } from '../PromotionCodeSettings';
import { FirstTimeFreeBetSettings } from '../FirstTimeFreeBetSettings';
import { SportsSettings } from '../SportsSettings';
import { FluxNotice } from '../../../../../components/notification/FluxNotice';
import { FluxCard } from '../../../../../components/card/FluxCard';
import { TurnoverRules } from '../TurnoverRules';



export const FirstTimeFreeBet = (props) => {
    const { promotion } = { ...props };

    var buttons = [
        {
            title: 'General Settings'
        },
        {
            title: 'Group Settings'
        },
        {
            title: 'Code'
        },
        {
            title: 'Rules'
        },
        {
            title: 'Sports'
        },
        {
            title: 'Free Bet Settings'
        }
    ];

    return <FluxTab buttons={buttons} className='gap-10 fx-shadow fx-radius rounded-buttons campaign-settings'>
        <PromotionGeneralSettings {...props} description='New users receive a free bet on their first wager, enhancing their onboarding experience. This tool helps you launch and manage the promotion to boost user engagement.'/>
        <PromotionGroupSettings {...props} />
        <PromotionCodeSettings {...props} />
        <TurnoverRules {...props} />
        <SportsSettings {...props} disabled={promotion?.Configuration?.deposit?.prizeType == 'FreeBet'} disabledReason={<FluxCard>
            <FluxNotice type='information' title='Sport configuration is not enabled' description={'Configuration should be completed using the selected awarded promotion settings!'} />
        </FluxCard>} />
        <FirstTimeFreeBetSettings  {...props} />
    </FluxTab>;
};