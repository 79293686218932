import './_casino-limits.scss';

import React, { useEffect, useState } from 'react';
import { FluxCard } from '../../../components/card/FluxCard';
import { PlayerGroupList } from '../../../components/player/player-groups/PlayerGroupList';
import { FluxTab } from '../../../components/tab/FluxTab';
import { API } from '../../../lib/API/Api';
import { DataEndpoints } from '../../../lib/API/Endpoints';
import { CasinoGameLimits } from '../GameLimits/CasinoGameLimits';
import { CasinoGameTypeLimits } from '../GameTypesLimits/CasinoGameTypeLimits';
import { CasinoLimitConfigurator } from './CasinoLimitConfigurator';

export const CasinoLimits = () => {
    const [group, selectGroup] = useState(null);
    const [currencies, setCurrencies] = useState(null);
    var tabButtons = [
        {
            title: 'General Limits'
        },
        {
            title: 'Game Types'
        },
        {
            title: 'Games'
        }
    ];

    useEffect(() => {
        API.post(DataEndpoints.EnabledCurrencies).then((result) => {
            setCurrencies(result.result);
        });
    }, []);

    if (!currencies) return <></>;
    return <flex className='vertical gap-10 fit-children overflow-hidden'>
        <flex className='gap-10 overflow-hidden fit-children'>
            <FluxCard title='Configuration' className={'overflow-hidden align-self-stretch no-padding sport-limits-configuration-type'}>
                <PlayerGroupList
                    onSelectGroup={(data) => selectGroup(data)}
                ></PlayerGroupList>
            </FluxCard>
            <FluxTab buttons={tabButtons} className='gap-10 fx-shadow fx-radius rounded-buttons overflow-hidden'>
                <CasinoLimitConfigurator group={group} currencies={currencies} />
                <CasinoGameTypeLimits group={group} currencies={currencies} />
                <CasinoGameLimits group={group} currencies={currencies} />
            </FluxTab>
        </flex>
    </flex>;
};