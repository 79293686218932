/* eslint-disable react/display-name */
import React from 'react';
import { useSelector } from 'react-redux';
import { ReportEndpoints } from '../../../../v2/Lib/Api/Api';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { SearchModelHorseBettingReport, ResultModelHorseBettingReport } from '../../../lib/Models/HorseModels';

const ResultModel = () => {
    var fields = Object.assign([], ResultModelHorseBettingReport.fields);
    fields.splice(0, 0, {
        name: 'Operator'
    });
    return {
        fields: fields
    };
};

export const ReportRegionsHorseGreyhound = () => {
    const user = useSelector((state) => state.user.loginInformation);
    const singleOperator = user.UserPrivileges.Operators.length == 1;

    
    return <FluxSearch
        model={SearchModelHorseBettingReport}
        endpoint={ReportEndpoints.HorseGreyHoundRegionsReport}
        resultModel={ResultModel()}
        onPrepareResultModel={(model) => {
            var copy = { ...model };

            if (singleOperator) {
                model.fields.find(x => x.name == 'Operator').hidden = true;
            }
            return copy;
        }}
    />;
};
