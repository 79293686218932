/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { Modal } from '../../../../components/application/FluxApp';
import { Button } from '../../../../components/button/Button';
import { FluxCard } from '../../../../components/card/FluxCard';
import { InputTypes } from '../../../../components/form/InputTypes';
import { ConfirmationModal } from '../../../../components/modal/ConfirmationModal/ConfirmationModal';
import { FluxNotice } from '../../../../components/notification/FluxNotice';
import { NotificationType } from '../../../../components/notification/FluxNotification';
import { Popper } from '../../../../components/notification/FluxPopper';
import { ToggleButton } from '../../../../components/toggleButton/ToggleButton';
import { API } from '../../../../lib/API/Api';
import { FixtureEndpoints, SportEndpoints } from '../../../../lib/API/Endpoints';
import { Table } from '../../../../../flux/components/table/Table';

export const SetupRegions = () => {
    const [categories, setCategories] = useState(null);
    const [category, selectCategory] = useState(null);
    const [regions, setRegions] = useState(null);
    const [weights, setWeights] = useState(null);
    let weightsCopy = { ...weights };


    useEffect(() => {
        API.post(FixtureEndpoints.SetupSportCategories).then((weightResult) => {
            var w = {};
            try {
                w = JSON.parse(weightResult.result);
            } catch (err) {
                //
            }

            API.post(SportEndpoints.Categories).then((result) => {

                var copy = [...result.result];
                copy.map(x => {
                    if (w.hasOwnProperty(x.id)) {
                        x.weight = w[x.id].weight;
                    }
                });

                var sorted = Object.assign([], copy).sort((b, a) => { return a.weight - b.weight; });

                setCategories(sorted);
                selectCategory(sorted[0]);
            });

        });

    }, []);


    useEffect(() => {

        if (!category) return;
        API.post(`${SportEndpoints.Regions}/${category.id}`).then((regionResult) => {
            API.post(FixtureEndpoints.SetupSportRegions).then((result) => {
                try {
                    var regionWeights = JSON.parse(result.result);

                    if (!regionWeights) regionWeights = {};
                    if (!regionWeights.hasOwnProperty(category.id)) {
                        regionWeights[category.id] = {};
                    }

                    setWeights(regionWeights);
                    regionResult.result.map(x => {
                        if (regionWeights.hasOwnProperty(category.id)) {
                            if (regionWeights[category.id][x.id]) {
                                x.weight = regionWeights[category.id][x.id].weight;
                                x.enabled = regionWeights[category.id][x.id].enabled === undefined ? true : regionWeights[category.id][x.id].enabled;
                            }
                        } else {
                            x.enabled = true;
                        }
                    });

                    var sorted = Object.assign([], regionResult.result).sort((b, a) => { return a.weight - b.weight; });
                    setRegions(sorted);
                } catch (err) {
                    //
                }
            });

        }).catch((e) => {
            console.log(e);
        });

    }, [category]);



    const save = () => {
        Modal.open(<ConfirmationModal title='Confirm operation' onConfirm={() => {
            API.post(FixtureEndpoints.SaveSetupSportRegions, weightsCopy).then(() => {
                Popper.pop(NotificationType.Information, 'Fixture Saved!', 'Regions saved, all changes are effective.');
            });
        }}>
            <FluxNotice type={NotificationType.Information} title='Please confirm save!' description={'The order of regions & enabled properties will be effective active save.'} />
        </ConfirmationModal>);
    };

    if (!categories) return <></>;
    if (!category) return <></>;
    if (!regions) return <></>;
    if (!weights) return <></>;


    return <flex className='vertical gap-10'>
        <FluxCard title='Categories' buttons={
            <flex className='align-right gap-5'>
                <Button title='Save' className={'success'} onClick={() => save()} />
            </flex>
        }>
            <select defaultValue={category.id} onChangeCapture={(e) => {
                selectCategory(categories.find(x => x.id == parseInt(e.target.value)));
            }}>
                {categories.map(x => {
                    return <option key={x.id} value={x.id}>{x.name}</option>;
                })}
            </select>

        </FluxCard>
        <FluxCard title='Manage Regions' >
            <Table
                data={regions}
                context={{
                    toggleEnabled: (row, value) => {
                        if (value == row.enabled) return;
                        if (!weightsCopy[category.id]) {
                            weightsCopy[category.id] = {};
                        }
                        if (!weightsCopy[category.id][row.id]) {
                            weightsCopy[category.id][row.id] = row;
                        }

                        var copy = [...regions];
                        weightsCopy[category.id][row.id].enabled = value;
                        copy.find(x => x.id == row.id).enabled = value;
                        setRegions(copy);
                    }
                }}
                model={{
                    fields: [
                        {
                            name: 'id',
                            title: 'Id'
                        },
                        {
                            name: 'name',
                            title: 'Name'
                        },
                        {
                            name: 'enabled',
                            title: 'Enabled',
                            type: InputTypes.Bool,
                            render: (val, row, field, context) => {
                                return <ToggleButton key={`${category.id}_${row.id}_${field.name}`} value={row.enabled} onChange={(value) => {
                                    if (row.enabled === value) return;
                                    context.toggleEnabled(row, value);
                                }} />;
                            }
                        }
                    ]
                }}
                options={{
                    data: {
                        delete: {
                            enabled: false,
                            header: false
                        }
                    },
                    draggable: {
                        enabled: true
                    }
                }}
                onSortEnd={(row, index, value) => {
                    value.map((x, i) => {
                        x.weight = value.length - i;
                        weightsCopy[category.id][x.id] = x;
                    });
                    setRegions(value);
                    //setWeights(weightsCopy);
                }}
            />
        </FluxCard>
    </flex>;
};