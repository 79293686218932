/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../components/button/Button';
import { ActionButton, FluxCard } from '../../../components/card/FluxCard';
import { Table } from '../../../components/table/Table';
import { Modal } from '../../../components/application/FluxApp';
import { FluxModal } from '../../../components/modal/FluxModal';
import { InputTypes } from '../../../components/form/InputTypes';
import { SelectGames } from '../../../components/dataControls/SelectGames';


export const RetailGameLimits = (props) => {
    const [configuration, setConfiguration] = useState(props.configuration);

    const model = {
        fields: [
            {
                name: 'providerName',
                title: 'Provider'
            },
            {
                name: 'name',
                title: 'Game'
            },
            {
                name: 'minBet',
                title: 'Min Bet',
                type: InputTypes.Number,
                render: (val) => {
                    return <input defaultValue={val} />;
                }
            },
            {
                name: 'maxBet',
                title: 'Max Bet',
                type: InputTypes.Number,
                render: (val) => {
                    return <input defaultValue={val} />;
                }
            },
            {
                name: 'percentage',
                title: 'Percentage',
                type: InputTypes.Number,
                render: (val) => {
                    return <input defaultValue={val} />;
                }
            }
        ]
    };


    useEffect(() => {
        props.onUpdate(configuration);
    }, [configuration]);


    const removeProvider = (id) => {
        var copy = { ...configuration };
        copy.casino.gameLimits = copy.casino.gameLimits.filter(x => x.id !== id);
        setConfiguration(copy);
    };


    const addGameLimit = () => {
        var selectedItems = null;
        var selectedGames = {};
        configuration.casino.gameLimits.map(x => {
            selectedGames[x.id] = x;
        });

        Modal.open(<FluxModal title='Select Provider' footer={<flex className='gap-10 center'>
            <Button key={selectedItems} className='success' title={'Save'} onClick={() => {
                Modal.close();
                var copy = { ...configuration };
                if (Object.values(selectedItems).length == 0) {
                    copy.casino.gameLimits = [];
                } else {
                    copy.casino.gameLimits = copy.casino.gameLimits.concat(Object.values(selectedItems));
                }
                setConfiguration(copy);
            }} />
            <Button title='Cancel' onClick={() => Modal.close()} />
        </flex>}>
            <SelectGames selectedGames={selectedGames} onChange={(selected) => {
                selectedItems = selected;
            }} />
        </FluxModal>);
    };

    return <FluxCard title='Game Limits & Commission Configuration' className={configuration.casino?.enabled ? 'no-padding' : 'disabled no-padding'} buttons={<ActionButton title='Add' icon='plus-square' onClick={() => addGameLimit(configuration, setConfiguration)} />}>
        <Table model={model} data={configuration.casino.gameLimits} buttons={(row) => {
            return <Button title='Remove' className={'warning'} onClick={() => removeProvider(row.id)} />;
        }} />
    </FluxCard>;
};

RetailGameLimits.propTypes = {
    onUpdate: PropTypes.func,
    configuration: PropTypes.object
};