import './_form-input.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { isNullOrEmpty } from '../../../lib/Helpers/StringHelper';
import { Icon } from '../../icon/Icon';
import { InputTypes } from '../InputTypes';

export const DefaultInput = (props) => {
    const [value, setValue] = useState(null);
    const field = props.field;

    const inputRef = React.createRef();

    useEffect(() => {
        if (inputRef.current) {
            if (props.focused) {
                inputRef.current.focus();
            }
        }

        if (props.data && props.data[field.name]) {
            setValue(props.data[field.name]);
            return;
        } else if (field.value) {
            setValue(field.value);
        }

        if (isNullOrEmpty(props.field.value)) setValue(props.field.value);
    }, [props.data]);


    if (field.readonly) {
        return field.render ? field.render(field.value, props.data) : <input autoComplete='off' type='text' value={value} readOnly />;
    }

    if (field.type == InputTypes.Textarea) {
        return <textarea
            autoComplete='off'
            className='flux-input'
            maxLength={field.maxLength}
            placeholder={field.placeholder}
            value={value}
            onChangeCapture={(e) => props.onChange(field, e.target.value)}
            onKeyUp={field.onKeyUp ? (e) => field.onKeyUp(e) : null} >
            {value}
        </textarea>;
    }

    if (field.type == InputTypes.Password) {
        return <React.Fragment>
            <input
                ref={inputRef}
                autoComplete='off'
                className='flux-input'
                type={field.type}
                defaultValue={value}
                onChangeCapture={(e) => props.onChange(field, e.target.value)}
                onKeyUp={field.onKeyUp ? (e) => field.onKeyUp(e) : null}
            />
            <Icon icon='eye' className={'show-password'} type='fa-light' onClick={(e) => {
                inputRef.current.type = inputRef.current.type == 'password' ? 'text' : 'password';
                if (inputRef.current.type == 'password') {
                    e.target.className = 'icon fa-light fa-eye fa-1x show-password';
                } else {
                    e.target.className = 'icon fa-light fa-eye-slash fa-1x show-password';
                }
            }} />
        </React.Fragment>;
    }

    const onInput = (e) => {
        //only to fetch ctrl+x or single character backspace removal to avoid duplicate trigger we must not trigger everytime
        if (!e.target.value) {
            props.onChange(field, e.target.value);
        }
    };
    const onChangeCapture = (e) => {
        //if there is a value it must work normaly, otherwise to capture ctrl+x or single char backspace removal we must trigger from onInput to avoid duplicate trigger
        if (e.target.value) {
            props.onChange(field, e.target.value);
        }
    };

    return <input
        ref={inputRef}
        autoComplete='off'
        className='flux-input'
        placeholder={field.placeholder}
        maxLength={field.maxLength}
        minLength={field.minLength}
        type={field.type}
        defaultValue={value}
        onChangeCapture={(e) => onChangeCapture(e)}
        onKeyUp={field.onKeyUp ? (e) => field.onKeyUp(e) : null}
        onInput={(e) => onInput(e)}
    />;
};

DefaultInput.propTypes = {
    field: PropTypes.object,
    focused: PropTypes.bool,
    data: PropTypes.object,
    onChange: PropTypes.func
};
