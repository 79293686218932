/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { API } from '../../../lib/API/Api';
import { ProviderEndpoints } from '../../../lib/API/Endpoints';
import { Table } from '../../../../flux/components/table/Table';
import { FluxCard } from '../../../components/card/FluxCard';
import { FluxTab } from '../../../components/tab/FluxTab';
import { Button } from '../../../components/button/Button';
import { arrayMove } from 'react-sortable-hoc';

export const ProviderConfigurations = () => {
    const [providers, setProviders] = useState([]);
    const [filter, setFilter] = useState('Games');

    useEffect(() => {
        API.post(ProviderEndpoints.MyProviders, {}).then((result) => {
            setProviders(result.result);
        });
    }, []);


    return <flex className='vertical gap-10 provider-configuration-container'>
        <FluxCard>
            <flex>
                <Button title='Save' onClick={() => {
                    var copy = [...providers];
                    var totalGaming = copy.filter(x => x.type != 128).length;
                    var totalPayment = copy.filter(x => x.type == 128).length;
                    copy.filter(x => x.type != 128).map((x, index) => x.weight = totalGaming - index);
                    copy.filter(x => x.type == 128).map((x, index) => x.weight = totalPayment - index);

                    API.post(ProviderEndpoints.SaveProviderWeights, copy).then((result) => {
                        console.log(result);
                    });
                }}></Button>
            </flex>
        </FluxCard>
        <FluxTab buttons={[
            {
                data: 'Games',
                title: 'Games'
            },
            {
                data: 'Payment',
                title: 'Payment Providers'
            }
        ]} className='gap-10 fx-shadow fx-radius rounded-buttons' onChange={(index) => {
            setFilter(index == 0 ? 'Games' : 'Payment');
        }}></FluxTab>
        <FluxCard>
            <Table
                className={'fx-borders fx-shadow'}
                data={(filter == 'Games' ? providers.filter(x => x.type != 128) : providers.filter(x => x.type == 128)).sort((a, b) => b.weight - a.weight)}
                context={{
                    toggleEnabled: (row, value) => {
                        console.log(value);
                    }
                }}
                model={{
                    fields: [
                        {
                            name: 'display',
                            title: 'Display'
                        }
                    ]
                }}
                options={{
                    data: {
                        delete: {
                            enabled: false,
                            header: false
                        }
                    },
                    draggable: {
                        enabled: true
                    }
                }}
                onSortEnd={(o, n, v) => {
                    var copy = [...v];
                    copy.map((x, i) => {
                        x.weight = copy.length - i;
                    });
                    setProviders(copy);
                }}
                onDoubleClick={(row) => {
                    var data = [...providers];
                    var oldIndex = data.findIndex(x => x == row);
                    var newIndex = 0;
                    var res = arrayMove(data, oldIndex, newIndex);
                    res.map((x, i) => {
                        x.weight = res.length - i;
                    });
                    setProviders(res);
                }}
            />
        </FluxCard>
    </flex>;
};