/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { FluxCard } from '../../../../components/card/FluxCard';
import { FluxForm } from '../../../../components/form/FluxForm';
import { InputTypes } from '../../../../components/form/InputTypes';


const model = {

    fields: [
        {
            name: 'enabled',
            title: 'Enable Horse & Greyhound',
            type: InputTypes.Bool
        }
    ]
};

export const HourseGreyhoundSettings = (props) => {
    let { promotion, currencies, onChange, disabled, disabledReason } = { ...props };
    const [data, setData] = useState(promotion.Configuration.horse ?? { enabled: false });


    useEffect(() => {
        if (!data) return;
        promotion.Configuration.horse = data;
        onChange({ ...promotion });
    }, [data]);



    if (disabled) {
        return <flex className='vertical gap-10'>
            {disabledReason}
        </flex>;
    }

    return <flex className='vertical gap-10'>
        <FluxCard>
            <FluxForm data={data} model={model} columns={2} buttons={null} onChange={(d) => setData(d)} />
        </FluxCard>
    </flex>;
};