import './_tree-input.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useOnClickOutside } from '../../../lib/Helpers/HTMLElementHelpers';
import { isNullOrEmpty } from '../../../lib/Helpers/StringHelper';
import { Icon } from '../../icon/Icon';
import { API } from '../../../lib/API/Api';

export const TreeInput = (props) => {
    const [ready, setReady] = useState(false);
    const [root, setRoot] = useState([]);
    const [value, setValue] = useState(undefined);

    const [open, setOpen] = useState(false);

    const [selected, select] = useState(null);
    const [expandedItems, setExpandedItems] = useState({});

    const [search, setSearch] = useState(null);

    const field = props.field;

    const ref = React.createRef();
    useOnClickOutside(ref, () => setOpen(false));

    useEffect(() => {
        if (field.data) {
            API.post(field.data.source.indexOf('/') == 0 ? field.data.source : `/api/data/${field.data.source}`, field.data.params ?? {}, null, null, false, null, true).then((result) => {
                if (field.data.filter) {
                    result.result = result.result.filter(x => field.data.filter(x));
                }
                if (field.data.sort) {
                    result.result = result.result.sort((a, b) => field.data.sort(a, b));
                }
                if (field.data.onParseComplete) {
                    field.data.onParseComplete(result.result);
                }
                if (field.data.parser) {
                    setRoot(field.data.parser(result));
                    setReady(true);
                    return;
                }
                var dataValues = [];
                result.result.map(x => {
                    var title = [];
                    field.data.title.map(y => {
                        title.push(x[y]);
                    });
                    dataValues.push({ value: x[field.data.key], title: title.join(' ') });
                });
                setRoot(dataValues);
                setReady(true);
            });
            return;
        }
        if (Array.isArray(field.values)) {
            setRoot(field.values);
            setReady(true);
            return;
        }
        var keyValuePair = [];
        Object.keys(field.values).map(x => {
            keyValuePair.push({ value: x, title: field.values[x] });
        });
        setRoot(keyValuePair);
        setReady(true);
    }, []);

    useEffect(() => {

    }, [props.data]);

    useEffect(() => {
        setValue(selected?.Id ?? null);
    }, [selected]);

    useEffect(() => {
        if (!root) return;
        if (!root.length) return;
        if (props.data && !isNullOrEmpty(props.data[field.name])) {
            setValue(props.data[field.name]);
            return;
        } else {
            if (field.nullable && (field.value === null || field.value === undefined)) {
                return;
            }
            if (value === undefined) {
                setValue((field.value !== null && field.value !== undefined) ? field.value : root[0].value);
            }
        }
    }, [root]);


    useEffect(() => {
        if (!search) return;
        if (search.length < 3) return;

        var foundItems = {};
        root?.children.map(item => {
            if (item.Name.toLowerCase().indexOf(search) >= 0) {
                foundItems[item.Id] = true;
            }
            if (item.children && item.children.length > 0) {
                item.children.map(x => {
                    if (x.Name.toLowerCase().indexOf(search) >= 0) {
                        foundItems[x.Id] = true;
                        foundItems[item.Id] = true;
                    }

                    if (x.children && x.children.lengh > 0) {
                        x.children.map(y => {
                            if (y.Name.toLowerCase().indexOf(search) >= 0) {
                                foundItems[y.Id] = true;
                                foundItems[x.Id] = true;
                                foundItems[item.Id] = true;
                            }
                        });
                    }
                });
            }
        });

        if (Object.keys(foundItems).length > 0) {
            var copy = { ...expandedItems };
            Object.keys(foundItems).map(x => {
                copy[x] = true;
            });

            copy[root.Id] = true;
            setExpandedItems(copy);
        }
    }, [search]);

    useEffect(() => {
    }, [expandedItems]);

    useEffect(() => {
        if (value === undefined) {
            return;
        }

        if (value === null) {
            if (props.field.nullable) {
                props.onChange(props.field, null);
            }
            return;
        }

        props.onChange(props.field, value);
    }, [value]);


    const toggleOpenNode = (item) => {
        var copy = { ...expandedItems };
        if (copy[item.Id]) {
            delete copy[item.Id];
        } else {
            copy[item.Id] = true;
        }
        setExpandedItems(copy);
    };



    if (!root) return <></>;
    if (!ready) return <></>;


    const renderItem = (item) => {
        var found = expandedItems[item.Id];
        return <React.Fragment key={item.Id}>
            <item className={`${found ? 'found' : ''} ${!item.Enabled ? 'passive' : null}${selected == item ? ' selected' : ''}`}>
                <flex className='gap-10'>
                    <flex className='gap-5' onClick={() => {
                        select(item);
                    }}>
                        {selected == item && <Icon icon='circle-check' type='fa-light' />}
                        {selected !== item && <Icon icon='circle' type='fa-light' />}
                        <label>{item.Id}</label>
                        <span>{item.Name}</span>
                    </flex>
                    {item.children && item.children.length > 0 && <flex onClick={() => toggleOpenNode(item)} className='align-right'><Icon icon={expandedItems[item.Id] ? 'square-minus' : 'square-plus'} type='fa-light' /></flex>}
                </flex>
            </item>
            {
                item.children && expandedItems[item.Id] && item.children.length > 0 && <items>{item.children.map(childItem => {
                    return renderItem(childItem);
                })
                }</items>
            }
        </React.Fragment>;
    };



    return <flex className='gap-10 retail-tree-finder'>
        <flex className='gap-10'>
            <input type='text' onFocus={() => {
                setOpen(true);
            }} onChange={(e) => {
                setSearch(e.target.value);
            }} />
            {selected && <flex className='gap-10'>
                <flex className='gap-5'>
                    <span>{selected.Name}</span>
                    <Icon icon='times' className='clickable' onClick={() => {
                        select(null);
                    }} />
                </flex>
            </flex>
            }
        </flex>
        {open && <fluxList className='fx-borders fx-shadow fx-radius flux-tree' ref={ref}>
            <items>
                {renderItem(root)}
            </items>
        </fluxList>}
    </flex>;
};

TreeInput.propTypes = {
    field: PropTypes.object,
    data: PropTypes.object,
    onChange: PropTypes.func
};
