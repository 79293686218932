/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { PromotionEndpoints } from '../../../lib/API/Endpoints';
import { InputTypes } from '../../../components/form/InputTypes';
import { API } from '../../../lib/API/Api';
import { Table } from '../../../components/table/Table';
import { FluxCard } from '../../../components/card/FluxCard';
import { Icon } from '../../../components/icon/Icon';
import { lang } from '../../../lib/Localization/language';
import { Button } from '../../../components/button/Button';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { ActionTypes } from '../../../lib/ActionManager/FluxActions';
import { Modal } from '../../../components/application/FluxApp';
import { ConfirmationModal } from '../../../components/modal/ConfirmationModal/ConfirmationModal';
import { FluxForm } from '../../../components/form/FluxForm';



export const CampaignResultModel = {
    fields: [
        {
            name: 'EntryDate',
            title: 'Created',
            type: InputTypes.DateTime
        },
        {
            name: 'Name',
            title: 'Name'
        },
        {
            name: 'Promotion',
            title: 'Promotion',
            visible: false
        },
        {
            name: 'Provider',
            title: 'Provider'
        },
        {
            name: 'Game',
            title: 'Game'
        },
        {
            name: 'Currency',
            title: 'Currency'
        },
        {
            name: 'Bet',
            title: 'Bet'
        },
        {
            name: 'FreeSpinCount',
            title: 'Free Spin Count'
        },
        {
            name: 'Admin',
            title: 'Admin'
        },
        {
            name: 'DateStart',
            title: 'Start',
            type: InputTypes.DateTime
        },
        {
            name: 'DateEnd',
            title: 'End',
            type: InputTypes.DateTime
        }
    ]
};



export const SearchFreespinCampaigns = (props) => {
    const { promotion, onSelect } = { ...props };
    const [campaigns, setCampaigns] = useState(null);

    useEffect(() => {
        if (!promotion) return;
        var request = {
            fields: [
                {
                    name: 'Enabled',
                    value: true,
                    exact: true,
                    and: true
                },
                {
                    name: 'PromotionId',
                    value: promotion.Id,
                    exact: true,
                    and: true
                }
            ]
        };
        API.post(PromotionEndpoints.ListCampaigns, request, 'please wait', 'unable to fetch campaigns').then((result) => {
            setCampaigns(result.result);
        });
    }, []);

    const onActionClicked = (action, item, context) => {
        var data = null;

        switch (action.type) {
            case ActionTypes.ManagePromotions:
                var copy = { ...item };

                var dateStart = copy.DateStart;
                var dateEnd = copy.DateEnd;


                copy.DateStart = dateStart.split('.')[0];
                copy.DateEnd = dateEnd.split('.')[0];

                Modal.open(<ConfirmationModal title={lang('Edit Campaign #%1', [item.Id])} onConfirm={() => {
                    data.Id = copy.Id;
                    API.post(PromotionEndpoints.UpdateCampaign, data, 'Please wait, updating campaign!', 'Unable to update campaign!').then((result => {
                        var data = context.tableContext.getData();
                        var found = data.find(x => x.Id == result.result.id);
                        found.Enabled = result.result.enabled;
                        found.Name = result.result.name;
                        found.PromotionId = result.result.promotionId;
                        found.Promotion = result.result.promotion;
                        found.DateStart = result.result.dateStart;
                        found.DateEnd = result.result.dateEnd;
                        context.tableContext.setData(data);
                    }));
                }}>
                    <FluxForm data={copy} columns={2} model={{
                        fields: [
                            {
                                name: 'Id',
                                readonly: true,
                                visible: false
                            },
                            {
                                name: 'Name',
                                title: 'Name'
                            },
                            {
                                name: 'Promotion',
                                title: 'Promotion',
                                readonly: true,
                            },
                            {
                                name: 'FreeSpinCount',
                                title: 'Free Spin Count',
                                type: InputTypes.Number,
                                readonly: true,
                            },
                            {
                                name: 'DateStart',
                                title: 'Start',
                                type: InputTypes.DateTime
                            },
                            {
                                name: 'DateEnd',
                                title: 'End',
                                type: InputTypes.DateTime
                            },
                            {
                                name: 'Bet',
                                title: 'Bet',
                                readonly: true
                            },
                            {
                                name: 'Enabled',
                                title: 'Enabled',
                                type: InputTypes.Bool
                            }
                        ]
                    }} buttons={null} onChange={(d) => data = d} confirmTitle='Update' />
                </ConfirmationModal>);
                break;
            default:
                console.log(action.type);
                break;
        }
    };


    if (!promotion) {
        return <FluxSearch title='Campaigns'
            actions={ActionTypes.FreeSpinCampaigns}
            model={{
                name: 'campaigns',
                fields: [
                    {
                        name: 'PromotionId',
                        title: 'Promotion',
                        type: InputTypes.Select,
                        nullable: true,
                        data: {
                            source: PromotionEndpoints.List,
                            key: 'Id',
                            title: ['Name'],
                            payload: {
                                fields: [
                                    {
                                        name: 'Type',
                                        value: 5,
                                        exact: true,
                                        and: true
                                    }
                                ]
                            }
                        }
                    },
                    {
                        name: 'Enabled',
                        title: 'Enabled',
                        type: InputTypes.Bool,
                        value: true
                    }
                ]
            }}
            resultModel={CampaignResultModel}
            onPrepareResultModel={(m) => {
                m.fields.find(x => x.name == 'Promotion').visible = true;
                return m;
            }}
            endpoint={PromotionEndpoints.ListCampaigns}
            buttons={onSelect ? (row) => {
                return <Button title='Select' className='success' onClick={() => {
                    if (!onSelect) return;
                    onSelect(row);
                }} />
            } : null}
            onActionClicked={onActionClicked.bind(this)}
        />
    }

    if (!campaigns) return <></>;

    return <FluxCard title={<React.Fragment>
        <span>{promotion.Name}</span >
        <Icon icon='angle-right' type='fa-light' />
        <span>{lang('Campaigns')}</span>
    </React.Fragment>}
        className='no-padding'>
        <Table model={CampaignResultModel} data={campaigns ?? []} buttons={(row) => {
            return <Button title='Select' onClick={() => {
                if (onSelect) return;
                onSelect(row);
            }} className='success' />
        }} />
    </FluxCard>;
};
