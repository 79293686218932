import './_website-configurator.scss';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from '../../../components/application/FluxApp';
import { Button } from '../../../components/button/Button';
import { FluxCard } from '../../../components/card/FluxCard';
import { ConfirmationModal } from '../../../components/modal/ConfirmationModal/ConfirmationModal';
import { FluxNotice } from '../../../components/notification/FluxNotice';
import { NotificationType } from '../../../components/notification/FluxNotification';
import { Popper } from '../../../components/notification/FluxPopper';
import { API } from '../../../lib/API/Api';
import { CMSEndpoints, DataEndpoints } from '../../../lib/API/Endpoints';
import { GamingPageConfigurator } from './ContentTypes/GamingPageConfigurator';
import { HomePageConfigurator } from './ContentTypes/HomePageConfigurator';
import { RacingPageConfigurator } from './ContentTypes/RacingPageConfigurator';
import { SportsPageConfigurator } from './ContentTypes/SportsPageConfigurator';

export const WebsiteConfigurator = () => {
    const user = useSelector((state) => state.user.loginInformation);
    const operator = user.UserPrivileges.Operators.find(x => x.Id == user.UserPrivileges.PointOfView);
    const defaultLanguage = (user.features?.defaultLanguage ?? 'en-En').split('-')[0];

    const [languages, setAvailableLanguages] = useState(null);
    const [pages, setPages] = useState(null);
    const [page, selectPage] = useState(null);
    const [saveComplete, onSaveComplete] = useState(null);

    useEffect(() => {
        API.post(DataEndpoints.AvailableLanguages, {}).then((result) => {
            var sorted = result.result.sort((a, b) => {

                if (a.key.toLowerCase() == defaultLanguage) return -1;
                return 1;
            });

            setAvailableLanguages(sorted);
        });
    }, []);

    useEffect(() => {
        if (!languages) return;
        API.post(`${CMSEndpoints.Contents}/true`, { type: 'website' }).then((result) => {
            var pagesRaw = result.result.find(x => x.key === 'Pages');
            var pagesConverted = JSON.parse(pagesRaw.content);
            pagesConverted.map(x => {
                if (x.app === 'homepage') {
                    x.key = 'home';
                }
            })

            pagesConverted = pagesConverted.filter(x => x.key !== 'lotto');
            pagesConverted = pagesConverted.filter(x => x.key !== 'strongman');
            setPages(pagesConverted);
        });
    }, [languages]);

    useEffect(() => {
        if (!pages) return;
        if (!page) {
            selectPage(pages[0]);
        }
    }, [pages]);

    useEffect(() => {
    }, [page]);


    const save = (p) => {
        var copy = [...pages];
        var index = copy.findIndex(x => x.key == p.key);
        copy[index] = p;
        setPages(copy);

        try {
            API.post(CMSEndpoints.CMSSaveContent, { type: 'website', key: 'pages', content: JSON.stringify(copy) }).then(() => {
                Popper.pop(NotificationType.Information, 'Page saved', 'Please dont forget to publish.');
                onSaveComplete(saveComplete + 1);
            });
        } catch (err) {
            console.log(err);
        }
    };

    const updateConfiguration = (c) => {
        var copy = { ...page };
        copy.configuration = c;
        selectPage(copy);
    };

    const renderPage = () => {
        switch (page.app) {
            case 'homepage':
                return <HomePageConfigurator page={page} prefix={operator?.Prefix} languages={languages} defaultLanguage={defaultLanguage} updateConfiguration={(c) => updateConfiguration(c)} save={(p) => save(p)} onSaveComplete={saveComplete} />;
            case 'sports':
                return <SportsPageConfigurator page={page} prefix={operator?.Prefix} languages={languages} defaultLanguage={defaultLanguage} updateConfiguration={(c) => updateConfiguration(c)} save={(p) => save(p)} onSaveComplete={saveComplete} />;
            case 'racing':
                return <RacingPageConfigurator page={page} prefix={operator?.Prefix} languages={languages} defaultLanguage={defaultLanguage} updateConfiguration={(c) => updateConfiguration(c)} save={(p) => save(p)} onSaveComplete={saveComplete} />;
            case 'gaming':
                return <GamingPageConfigurator key={page.key} page={page} prefix={operator?.Prefix} languages={languages} defaultLanguage={defaultLanguage} updateConfiguration={(c) => updateConfiguration(c)} save={(p) => save(p)} onSaveComplete={saveComplete} />;
            default:
                console.log(page.app);
                return;
        }
    };


    if (!languages) return <></>;
    if (!pages) return <></>;


    return <flex className='website-configurator overflow-hidden gap-10 fit-children'>
        <FluxCard title='Pages' className={'page-list no-padding overflow-hidden'} buttons={<flex className='align-right'>
            <Button title='Publish' className='success' onClick={() => {
                Modal.open(<ConfirmationModal title='Confirm Publish' onConfirm={() => {
                    API.post(CMSEndpoints.CMSPublishPages, {}, 'publishing', 'unable to publish').then(() => {
                        Popper.pop(NotificationType.Information, 'Published', 'Changes have been published.');
                    });
                }}>
                    <FluxNotice type={NotificationType.Information} title='Publish changes?' description={'Please confirm publishing changes.'} />
                </ConfirmationModal>)
            }} />
        </flex>}>
            <fluxList>
                <items>
                    {
                        pages.map((x) => {
                            return <item className={page == x ? 'active' : ''} onClick={() => {
                                selectPage(x);
                            }}>
                                <span>{x.title}</span>
                            </item>;
                        })
                    }
                </items>
            </fluxList>
        </FluxCard>
        <flex className='vertical gap-10 overflow-hidden'>
            {!page && <FluxNotice type={NotificationType.Information} title='Page not selected' description={'Please select a page from the list.'} />}
            {page && <FluxCard title='Page Configuration'>

            </FluxCard>}
            {page && renderPage()}
        </flex>
    </flex>;
};