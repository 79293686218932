import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FluxPage } from '../../components/page/FluxPage';
import { ActionTypes } from '../../lib/ActionManager/FluxActions';
import { MainDashboard } from './Dashboards/MainDashboard';
import { AffiliateDashboard } from '../Affiliates/AffiliateDashboard/AffiliateDashboard';
import { API } from '../../lib/API/Api';
import { AffiliateEndpoints } from '../../lib/API/Endpoints';
import { toPascalCase } from '../../../components/Centrum/helpers';
import { POSUserHome } from '../POS/POSUserHome/POSUserHome';



export const HomePage = () => {
    const user = useSelector((state) => state.user.loginInformation);
    const [ready, setReady] = useState(false);
    const [affiliate, setAffiliate] = useState(null);
    const isAffiliate = user.AffiliateId > 0;
    const isPOSUser = user.UserGroupId == 23;


    useEffect(() => {
        if (isAffiliate) {
            API.post(`${AffiliateEndpoints.GetAffiliate}/${user.AffiliateId}`).then((result) => {
                setAffiliate(toPascalCase(result.result));
                setReady(true);
            });
        } else {
            setReady(true);
        }
    }, []);


    if (!ready) return <></>;

    return <FluxPage action={ActionTypes.Home}>
        {isAffiliate == true && <AffiliateDashboard affiliate={affiliate} />}
        {isAffiliate == false && !isPOSUser && <MainDashboard />}
        {isPOSUser && <POSUserHome />}
    </FluxPage>;
};
