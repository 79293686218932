import React, { useState } from 'react';
import { DataEndpoints } from '../../lib/API/Endpoints';
import { FluxForm } from '../form/FluxForm';
import { InputTypes } from '../form/InputTypes';




export const SelectGameProviders = (props) => {
    const { filter, onChange } = { ...props };

    const [providers, setProviders] = useState(null);
    const model = {
        fields: [
            {
                name: 'ProviderId',
                title: 'Provider',
                type: InputTypes.Select,
                data: {
                    source: DataEndpoints.GameProviders,
                    key: 'id',
                    title: ['display'],
                    filter: filter,
                    sort: (a, b) => a.display.toLowerCase() > b.display.toLowerCase() ? 1 : -1,
                    onParseComplete: (data) => {
                        setProviders(data);
                    }
                }
            }
        ]
    };

    return <FluxForm
        model={model}
        buttons={null}
        onChange={(data) => {
            onChange(providers?.find(x => x.id == data.ProviderId));
        }} />;
};