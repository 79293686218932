import './_tip.scss';
import React from 'react';
import { Icon } from '../icon/Icon';
import { lang } from '../../lib/Localization/language';

export const Tip = (props) => {
    const { tip, className } = { ...props };
    return <tip className={className}>
        <Icon icon='info-circle' type='fa-light' />
        <span>{lang(tip)}</span>
    </tip>;
};