import React from 'react';
import { ActionTypes } from '../../lib/ActionManager/FluxActions';
import { FluxAction } from '../../components/application/FluxAction';
import { FluxPage } from '../../components/page/FluxPage';
import { SearchPlayerGameHistory } from '../Players/SearchPlayerGameHistory/SearchPlayerGameHistory';
import { SearchPlayerGameSessions } from '../Players/SearchPlayerGameSessions/SearchPlayerGameSessions';
import { CasinoLimits } from './Configurator/CasinoLimits';

export const CasinoPage = () => {
    return <FluxPage action={ActionTypes.Casino}>
        <FluxAction action={ActionTypes.SearchPlayerGameHistory}><SearchPlayerGameHistory /></FluxAction>
        <FluxAction action={ActionTypes.SearchPlayerGameSessions}><SearchPlayerGameSessions /></FluxAction>
        <FluxAction action={ActionTypes.RiskManagementConfigureGeneralLimits}><CasinoLimits /></FluxAction>
    </FluxPage >;
};
