import './_busy-loader.scss';

import React from 'react';
import PropTypes from 'prop-types';

export const BusyOverlay = (props) => {
    return <overlay className='padding surface low busy-loader'>
        <flex className='gap-10'>
            <span className="loader"></span>
            {props.title} {props.children}
        </flex>
    </overlay>;
};

BusyOverlay.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node
};
