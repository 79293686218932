import React from 'react';
import { FluxTab } from '../../../components/tab/FluxTab';
import { GeneralSettings } from './Components/GeneralSettings';
import { PayoutSettings } from './Components/PayoutSettings';
import { InsuranceBonusSettings } from './Components/InsuranceBonusSettings';

export const InsuranceBonus = (props) => {
    var buttons = [
        {
            title: 'General Settings'
        },
        {
            title: 'Payout Settings'
        },
        {
            title: 'Rules'
        }
    ];

    return <FluxTab buttons={buttons} className='gap-10 fx-shadow fx-radius rounded-buttons'>
        <GeneralSettings {...props} />
        <PayoutSettings {...props} />
        <InsuranceBonusSettings {...props} />
    </FluxTab>;
};