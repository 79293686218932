import React, { useEffect, useState } from 'react';
import { API } from '../../../../../../lib/API/Api';
import { SportEndpoints } from '../../../../../../lib/API/Endpoints';
import ReactSelect from 'react-select';
import { Table } from '../../../../../table/Table';
import { lang } from '../../../../../../lib/Localization/language';
import { Button } from '../../../../../button/Button';


const defaultFocus = (e) => {
    e.target.setAttribute('data-value', e.target.value);
    e.target.value = '';
};

const defaultBlur = (e) => {
    if (e.target.value == '') {
        e.target.value = e.target.getAttribute('data-value') ?? '';
    }
};

const model = {
    fields: [
        {
            name: 'sport',
            title: 'Sport',
            render: (val) => {
                return val?.name;
            }
        },
        {
            name: 'market',
            title: 'Market',
            render: (val) => {
                return val?.name;
            }
        },
        {
            name: 'Stake',
            title: 'Stake',
            render: (val, row, field, context) => <input type='number' name={field.name} defaultValue={val} value={val} onBlur={defaultBlur} onFocus={defaultFocus} onChange={(e) => context.update(e, row)} />
        }
    ]
};


export const MarketSelection = (props) => {
    const { onMarketAdd } = { ...props };

    const [sports, setSports] = useState(null);
    const [selectedSport, selectSport] = useState(null);
    const [markets, setMarkets] = useState(null);
    const [market, selectMarket] = useState(null);

    useEffect(() => {
        API.post(SportEndpoints.Categories, null).then((result) => {
            var sorted = result.result.sort((a, b) => a.weight - b.weight);
            setSports(sorted.map(x => {
                return {
                    label: x.name,
                    value: x.id,
                    data: x
                };
            }));
        });
    }, []);


    useEffect(() => {
        if (!sports) return;
        selectSport(sports[0]);
    }, [sports]);

    useEffect(() => {
    }, [markets]);

    useEffect(() => {
        if (!selectedSport) return;
        API.post(`${SportEndpoints.Markets}/${selectedSport.value}`).then((result) => {
            setMarkets(result.result);
        });
    }, [selectedSport]);


    if (!sports) return <></>;
    if (!selectedSport) return <></>;

    return <flex className='gap-20 padding'>
        {selectedSport && <flex className='gap-10'>
            <span className='bold'>{lang('Sport')}</span>
            <ReactSelect
                className='react-select market-selection-sport-select'
                defaultValue={selectedSport}
                options={sports}
                onChange={(e) => {
                    selectSport(e);
                }}
                hideSelectedOptions={false}
                closeMenuOnSelect={true}
                isClearable={false}
                isSearchable={true}
            />
        </flex>
        }
        {markets && markets.length && <flex className='gap-10'>
            <span className='bold'>{lang('Market')}</span>
            <ReactSelect
                className='react-select market-selection-market-select'
                options={markets.map(x => {
                    return {
                        label: x.name,
                        value: x.id,
                        data: x
                    };
                })}
                onChange={(e) => {
                    selectMarket(e);
                }}
                hideSelectedOptions={false}
                closeMenuOnSelect={true}
                isClearable={false}
                isSearchable={true}
            />
        </flex>}
        {market && <flex className='align-right'>
            <Button title='Add' className={'success'} onClick={() => {
                onMarketAdd(selectedSport.data, market.data);
            }} />
        </flex>}
    </flex>;
};


export const MarketLiabilitySettings = (props) => {
    const { className } = { ...props };

    const [data, setData] = useState({});

    const context = {
        update: (e, row) => {
            var copy = { ...data };
            var value = e.target.value;
            if (e.target.value == row[e.target.name]) return;
            if (e.target.type == 'number') {
                value = parseFloat(value);
            }
            row[e.target.name] = value;

            setData(copy);
        }
    };


    return <flex className={`gap-10 vertical ${className}`}>
        <MarketSelection onMarketAdd={(sport, market) => {
            var copy = { ...data };
            var key = `${sport.id}_${market.id}`;
            if (!copy[key]) {
                copy[key] = {
                    sport: sport,
                    market: market,
                    stake: 0,
                };
            }
            setData(copy);
        }} />
        <Table data={Object.values(data)} model={model} context={context} buttons={(row) => {
            return <flex>
                <Button title='Remove' className='warning' onClick={() => {
                    var copy = { ...data };
                    var key = `${row.sport.id}_${row.market.id}`;
                    delete copy[key];
                    setData(copy);
                }} />
            </flex>;
        }} />
    </flex>;
};
