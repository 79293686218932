import './_flux-list.scss';

import React, { useEffect, useState } from 'react';
import { API } from '../../lib/API/Api';

export const FluxList = (props) => {
    const { className, children, type, selected, data, endpoint, sort, onFilter, isActive, onFetchComplete, onReady, onSelected, onRender } = { ...props };

    const [values, setValues] = useState(data);
    const [ready, setReady] = useState(false);
    const [selectedItem, select] = useState(selected);

    useEffect(() => {
        if (endpoint) {
            API.post(endpoint.source.indexOf('/') == 0 ? endpoint.source : `/api/data/${endpoint.source}`, {}, null, null, false, null, true).then((result) => {
                if (onFetchComplete) {
                    onFetchComplete(result.result);
                }
                if (endpoint.parser) {
                    setValues(endpoint.parser(result));
                    setReady(true);
                    return;
                }
                var dataValues = [];
                result.result.map(x => {
                    var title = [];
                    endpoint.title.map(y => {
                        title.push(x[y]);
                    });
                    dataValues.push({ value: x[endpoint.key], title: title.join(' '), data: x });
                });
                setValues(sort ? dataValues.sort((a, b) => sort(a, b)) : dataValues);
                setReady(true);
            });
            return;
        }
        if (Array.isArray(values)) {
            setValues(values);
            setReady(true);
            return;
        }
        var keyValuePair = [];
        Object.keys(values).map(x => {
            keyValuePair.push({ value: x, title: values[x] });
        });
        setValues(keyValuePair);
        setReady(true);
    }, []);


    useEffect(() => {
        if (!selectedItem || selectedItem != selected) {
            select(selected);
        }
    }, [selected]);

    useEffect(() => {
        if (onSelected) onSelected(selectedItem);
    }, [selectedItem]);

    useEffect(() => {
        if (ready) {
            if (onReady) {
                onReady(values);
            }
            if (type == 'select') {
                select(values[0]);
            }
        }
    }, [ready]);

    useEffect(() => {
        if (data) {
            if (Array.isArray(data)) {
                setValues(sort ? data.sort((a, b) => sort(a, b)) : data);
                setReady(true);
                return;
            }
            var keyValuePair = [];
            Object.keys(data).map(x => {
                keyValuePair.push({ value: x, title: data[x] });
            });
            setValues(sort ? keyValuePair.sort((a, b) => sort(a, b)) : keyValuePair);
            setReady(true);
        }
    }, [data]);

    useEffect(() => {

    }, [values]);

    if (!ready) return <></>;
    if (!values) return <></>;
    if (type == 'select') {
        return <fluxList className={className} type={'select'}>
            <select onChange={(e) => {
                select(values.find(x => x.value.toString() == e.target.value));
            }}>
                {
                    values.map(x => {
                        return <option key={x} value={x.value}>{x.title}</option>;
                    })
                }
            </select>
        </fluxList>;
    }
    return <fluxList className={className} key={values}>
        {children}
        <items>
            {values.filter((x) => onFilter ? onFilter(x) : true).map((x, index) => {
                return <item key={x} className={(selectedItem == x || selected == index || (isActive ? isActive(x) : false)) ? 'active' : ''} onClick={() => select(x)}>{onRender ? onRender(x) : <span>{x.title}</span>}</item>;
            })}
        </items>
    </fluxList >;
};
