import './_sport-selection.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../../../components/button/Button';
import { SportEndpoints } from '../../../../../lib/API/Endpoints';
import { Icon } from '../../../../../components/icon/Icon';
import { FluxCard } from '../../../../../components/card/FluxCard';
import { FluxList } from '../../../../../components/list/FluxList';


export const SportSelection = (props) => {
    const [sports, setSports] = useState(null);
    const [configuration, setConfiguration] = useState(props.configuration?.Configuration?.sport?.enabledSports ?? {});

    useEffect(() => {
        if (!configuration) return;
        if (!props.configuration) return;
        if (!props.configuration.Configuration) return;
        var copy = { ...props.configuration };
        if (!copy.Configuration.sport) {
            copy.Configuration.sport = { enabledSports: {} };
        }

        copy.Configuration.sport.enabledSports = configuration;
        props.onChange(copy);
    }, [configuration]);

    const selectAll = () => {
        var copy = { ...configuration };
        sports.map(x => {
            copy[x.id] = {
                id: x.id,
                name: x.name
            }
        });

        setConfiguration(copy);
    };

    const unselectAll = () => {
        setConfiguration({});
    };

    return <FluxCard title='Select Sports' className={'promotion-sport-selection-rules no-padding'} buttons={<flex className='align-right gap-5'>
        <Button title='Select All' className='success' onClick={() => {
            selectAll();
        }} />
        <Button title='Remove All' className='success' onClick={() => {
            unselectAll();
        }} />
    </flex>}>
        <FluxList className='overflow-hidden overflow-y-auto list-sports'
            onFetchComplete={(v) => {
                setSports(v);
            }}
            endpoint={{ source: SportEndpoints.Categories, key: 'id', title: ['name'] }}
            sort={(a, b) => {
                return a.data.weight - b.data.weight;
            }}
            onRender={(x) => {
                return <flex className={`gap-10 ${configuration[x.value] ? ' selected' : ''}`} key={x} onClick={() => {
                    var copy = { ...configuration };
                    if (copy.hasOwnProperty(x.value)) {
                        delete copy[x.value];
                    } else {
                        copy[x.value] = {
                            id: x.value,
                            name: x.title
                        };
                    }
                    setConfiguration(copy);
                }}>
                    <Icon icon={configuration[x.value] ? 'check-square' : 'square'} type='far' />
                    <span>{x.title}</span>
                </flex>;
            }}>
        </FluxList>
    </FluxCard>;
};

SportSelection.propTypes = {
    configuration: PropTypes.object,
    onChange: PropTypes.func
};



