import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { QR } from '../../../components/QR/QR';
import { lang } from '../../lib/Localization/language';
import { changeUserPassword } from '../../actions/UserActions';
import { Modal } from '../../components/application/FluxApp';
import { Button } from '../../components/button/Button';
import { FluxCard } from '../../components/card/FluxCard';
import { FluxForm } from '../../components/form/FluxForm';
import { ConfirmationModal } from '../../components/modal/ConfirmationModal/ConfirmationModal';
import { FluxNotice } from '../../components/notification/FluxNotice';
import { NotificationType } from '../../components/notification/FluxNotification';
import { Popper } from '../../components/notification/FluxPopper';
import { API } from '../../lib/API/Api';
import { AuthenticationEndpoints } from '../../lib/API/Endpoints';
import { MyUserActivities } from '../AdministrationPage/MyUserActivities';


const EnableDisable2FA = (props) => {
    const { enable, qr } = { ...props };
    const dispatch = useDispatch();
    const [code, setCode] = useState(0);

    return <ConfirmationModal title={enable ? 'Enable 2FA' : 'Disable 2FA'}
        confirmTitle={enable ? 'Activate' : 'Disable'}
        onConfirm={() => {
            API.post(`${AuthenticationEndpoints.Validate2FA}/${code}/${enable ? 'enable' : 'disable'}`, null).then((result) => {
                if (result.result) {
                    Popper.pop('information', '2FA Enabled', 'Your security level is increased.');
                    dispatch({ type: enable ? 'ENABLE_2FA' : 'DISABLE_2FA', payload: null });
                }
            });
        }}>
        <flex className='vertical gap-20'>
            <label className='medium'>{lang('Use Google Authenticator application on your mobile device to scan the following QR code and enter the generated 6 digit code.')}</label>
            {enable && <flex className='center'><QR text={qr} width='200px' height='200px' /></flex>}
            <flex className='vertical'>
                <label className='medium'>{lang('6 digit code')}</label>
                <input type='number' onKeyUp={(e) => {
                    if (e.target.value.length > 6) {
                        e.preventDefault();
                        return false;
                    }
                    setCode(e.target.value);
                }} maxLength={6} />
            </flex>
        </flex>
    </ConfirmationModal>;
};

export const MyProfile = () => {
    const user = useSelector((state) => state.user.loginInformation);

    const enable2FA = () => {
        API.post(AuthenticationEndpoints.Enable2FA, {}).then((result) => {
            Modal.open(<EnableDisable2FA qr={result.result} enable />);
        });
    };

    const disable2FA = () => {
        Modal.open(<EnableDisable2FA disable />);
    };

    return <flex className='vertical gap-10'>
        <flex className='gap-10 fit-children'>
            <FluxCard title='My Profile' footer={<flex className='gap-5'>
                <Button title='Change Password' onClick={() => {
                    changeUserPassword(user, () => {
                        Popper.pop('information', 'Password changed', 'Your password has been updated.');
                    });
                }} />
            </flex>}>
                <FluxForm
                    columns={2}
                    model={{
                        fields: [
                            {
                                name: 'Email',
                                title: 'Email',
                                readonly: true
                            },
                            {
                                name: 'Username',
                                title: 'Username',
                                readonly: true
                            },
                            {
                                name: 'UserGroupName',
                                title: 'Group',
                                readonly: true
                            }
                        ]
                    }} data={user} buttons={null} />
            </FluxCard>
            <FluxCard title='Two factor Authentication' className={'fit align-self-stretch'} footer={<flex>
                {user.AuthType == 0 && <Button title='Enable Two factor Authentication' className='success' onClick={() => enable2FA()} />}
                {user.AuthType == 1 && <Button title='Disable Two factor Authentication' className='warning' onClick={() => disable2FA()} />}
            </flex>}>
                {user.AuthType == 0 && <FluxNotice type={NotificationType.Warning} title='Two factor Authentication is disabled.' description={'Please enable two factor authentication for your security.'} />}
                {user.AuthType == 1 && <FluxNotice type={NotificationType.Success} title='Two factor Authentication is enabled.' description={'Your security level is increased.'} />}
            </FluxCard>
        </flex>
        <MyUserActivities user={user} title='Audit Log' searchOnStart />
    </flex>;
};