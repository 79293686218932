import './_sport-limits.scss';

import React, { useEffect, useState } from 'react';
import { API } from '../../../../lib/API/Api';
import { DataEndpoints, HorseLimitsEndpoints } from '../../../../lib/API/Endpoints';
import { FluxCard } from '../../../card/FluxCard';
import { PlayerGroupList } from '../../../player/player-groups/PlayerGroupList';
import { HorseLimitConfigurator } from './HorseLimitConfigurator';

export const HorseLimitsWithRiskLevels = (props) => {
    const { horse } = { ...props };

    const [group, selectGroup] = useState(null);
    const [limits, setLimits] = useState(null);
    const [currencies, setCurrencies] = useState(null);

    useEffect(() => {
        API.post(DataEndpoints.EnabledCurrencies).then((result) => {
            setCurrencies(result.result);
            API.post(HorseLimitsEndpoints.Get).then((result) => {
                var converted = null;
                try {
                    converted = JSON.parse(result.result);
                } catch (err) {
                    //
                    converted = {
                        general: {},
                        groups: {}
                    };
                }

                setLimits(converted ?? {
                    general: {},
                    groups: {}
                });
            });
        });
    }, []);

    if (!currencies) return <></>;
    if (!limits) return <></>;


    return <flex className='vertical gap-10 fit-children overflow-hidden'>
        <flex className='gap-10 overflow-hidden fit-children'>
            <FluxCard title='Configuration' className={'overflow-hidden align-self-stretch no-padding sport-limits-configuration-type'}>
                <PlayerGroupList
                    onSelectGroup={(data) => selectGroup(data)}
                ></PlayerGroupList>
            </FluxCard>
            <HorseLimitConfigurator limits={limits} group={group} currencies={currencies} key={group?.value}
                onUpdate={(riskLevel, s) => {
                    var copy = { ...limits };
                    if (group) {
                        if (!copy.groups[group.value]) {
                            copy.groups[group.value] = {};
                        }
                        copy.groups[group.value][riskLevel] = s;
                    } else {
                        copy.general[riskLevel] = s;
                    }
                    setLimits(copy);
                }} />
        </flex>
    </flex>;
};