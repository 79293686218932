import React from 'react';
import { FluxAction } from '../../components/application/FluxAction';
import { FluxPage } from '../../components/page/FluxPage';
import { ActionTypes } from '../../lib/ActionManager/FluxActions';
import { LanguageConfigurator } from './Language/LanguageConfigurator';
import { TopEvents } from './Sports/TopEvents/TopEvents';
import { SportTopLeagues } from './Sports/TopLeagues/SportTopLeagues';
import { WebsiteConfigurator } from './WebConfiguration/WebsiteConfigurator';

import { SetupCategories } from './Sports/Fixture/SetupCategories';
import { SetupRegions } from './Sports/Fixture/SetupRegions';
import { SetupLeagues } from './Sports/Fixture/SetupLeagues';
import { PromotionsCMS } from './WebConfiguration/PromotionsCMS';
import { GameConfigurations } from './GameConfigurations/GameConfigurations';
import { SetupMarketGroups } from './Sports/MarketGroups/SetupMarketGroups';
import { ProviderConfigurations } from './Providers/ProviderConfigurations';
import { SetupOverviewMarkets } from './Sports/SetupOverviewMarkets/SetupOverviewMarkets';


export const CmsPage = () => {
    return <FluxPage action={ActionTypes.CMS}>
        <FluxAction action={ActionTypes.SportsbookTopLeagues}><SportTopLeagues /></FluxAction>
        <FluxAction action={ActionTypes.SportsbookTopEvents}><TopEvents /></FluxAction>
        <FluxAction action={ActionTypes.ConfigureLanguages}><LanguageConfigurator /></FluxAction>
        <FluxAction action={ActionTypes.CMSPages}><WebsiteConfigurator /></FluxAction>
        <FluxAction action={ActionTypes.CMSPromotions}><PromotionsCMS /></FluxAction>
        <FluxAction action={ActionTypes.GetMyCMSProviders}><ProviderConfigurations /></FluxAction>
        <FluxAction action={ActionTypes.GetMyCMSGames}><GameConfigurations /></FluxAction>


        <FluxAction action={ActionTypes.SportsbookCategories}><SetupCategories /></FluxAction>
        <FluxAction action={ActionTypes.SportsbookRegions}><SetupRegions /></FluxAction>
        <FluxAction action={ActionTypes.SportsbookLeagues}><SetupLeagues /></FluxAction>
        <FluxAction action={ActionTypes.SetupMarketGroups}><SetupMarketGroups /></FluxAction>
        <FluxAction action={ActionTypes.SetupOverviewMarkets}><SetupOverviewMarkets /></FluxAction>
    </FluxPage>;
};
