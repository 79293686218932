import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DashboardEndponts } from '../../../../v2/Lib/Api/Api';
import { FluxCard } from '../../../components/card/FluxCard';
import { Currency } from '../../../components/currency/Currency';
import { Table } from '../../../components/table/Table';
import { ChainRequest } from '../../../lib/API/Api';
import { SportsTicketTypes } from '../../../lib/Enums/BettingEnums';
import { lang } from '../../../lib/Localization/language';
import { TicketId } from '../../../lib/Models/TicketModels';
import { GeneralKPI } from './GeneralKPI/GeneralKPI';
import { KPIRanges, KPITitle } from './KPISelector/KPISelector';


export const MainDashboard = () => {
    const [data, setData] = useState({});
    const user = useSelector((state) => state.user.loginInformation);
    const currency = user.features.currency;
    const [selectedKPI, onSelectedKPI] = useState(KPIRanges.Today);
    const [selectedCurrency, onSelectedCurrency] = useState(currency);
    const [convertCurrency, toggleConvertCurrency] = useState(false);

    useEffect(() => {
        var payload = {
            RangeType: selectedKPI,
            SelectedCurrency: selectedCurrency,
            ConvertCurrency: convertCurrency,
            timeZoneOffset: window.user.timeZoneConverted * 60,
        };

        var requests = [
            { endpoint: DashboardEndponts.GameSessions, key: 'GameSessions', payload: payload },
            { endpoint: DashboardEndponts.MostPlayedGames, key: 'MostPlayedGames', payload: payload },
            { endpoint: DashboardEndponts.MostProfitableGames, key: 'MostProfitableGames', payload: payload },
            { endpoint: DashboardEndponts.MostLosingGames, key: 'MostLosingGames', payload: payload },
            { endpoint: DashboardEndponts.TopSportTicketBets, key: 'TopSportTicketBets', payload: payload },
            { endpoint: DashboardEndponts.TopSportWins, key: 'TopSportWins', payload: payload },
            { endpoint: DashboardEndponts.NewTickets, key: 'NewTickets', payload: payload },
            { endpoint: DashboardEndponts.OpenTickets, key: 'OpenTickets', payload: payload },
            { endpoint: DashboardEndponts.TopSportPlayers, key: 'TopSportPlayers', payload: payload }
        ];

        ChainRequest(requests, (d) => {
            setData(d);
        });
    }, [convertCurrency, selectedCurrency, selectedKPI]);

    const completeToNRows = (target, max) => {
        var left = max - target.length;
        if (left == 0) return target;
        for (var i = 0; i < left; i++) {
            target.push({});
        }

        return target;
    };

    return <flex className='vertical gap-10'>
        <GeneralKPI
            onSelectedCurrency={(currency) => onSelectedCurrency(currency)}
            onSelectedKPI={(kpi) => onSelectedKPI(kpi)}
            onToggleConvertCurrency={(convert) => toggleConvertCurrency(convert)}
        />
        <flex className='gap-10 columns-2'>
            <flex className='gap-5 vertical'>
                <FluxCard title={lang('Casino Game Sessions Summary %1', [lang(KPITitle[selectedKPI])])}>
                    <flex className='gap-10 columns-2'>
                        <flex className='vertical gap-10'>
                            <flex className='vertical'>
                                <label className='medium'>{lang('Live Casino Games')}</label>
                                <span>{data.GameSessions?.live}</span>
                            </flex>
                            <flex className='vertical'>
                                <label className='medium'>{lang('GGR')}</label>
                                <span><Currency amount={data.GameSessions?.liveGGR} sign={selectedCurrency} /></span>
                            </flex>
                        </flex>
                        <flex className='vertical gap-10'>
                            <flex className='vertical'>
                                <label className='medium'>{lang('Slot Games')}</label>
                                <span>{data.GameSessions?.slots}</span>
                            </flex>
                            <flex className='vertical'>
                                <label className='medium'>{lang('GGR')}</label>
                                <span><Currency amount={data.GameSessions?.slotsGGR} sign={selectedCurrency} /></span>
                            </flex>
                        </flex>
                    </flex>
                </FluxCard>
                <FluxCard title={lang('Most Profitable Casino Games %1', [lang(KPITitle[selectedKPI])])} className={'no-padding'}>
                    <Table model={{
                        fields: [
                            {
                                name: 'totalHands',
                                title: 'Total Hands'
                            },
                            {
                                name: 'gameName',
                                title: 'Game'
                            },
                            {
                                name: 'bet',
                                title: 'Bet',
                                render: (val, row) => {
                                    return <Currency amount={val} sign={row.currency} />;
                                }
                            },
                            {
                                name: 'win',
                                title: 'Win',
                                render: (val, row) => {
                                    return <Currency amount={val} sign={row.currency} />;
                                }
                            },
                            {
                                name: 'ggr', title: 'GGR',
                                render: (val, row) => {
                                    return <Currency amount={val} sign={row.currency} />;
                                }
                            }
                        ]
                    }}
                        data={data.MostProfitableGames ?? []} />
                </FluxCard>
                <FluxCard title={lang('Most Losing Casino Games %1', [lang(KPITitle[selectedKPI])])} className={'no-padding'}>
                    <Table model={{
                        fields: [
                            {
                                name: 'totalHands',
                                title: 'Total Hands'
                            },
                            {
                                name: 'gameName',
                                title: 'Game'
                            },
                            {
                                name: 'bet',
                                title: 'Bet',
                                render: (val, row) => {
                                    return <Currency amount={val} sign={row.currency} />;
                                }
                            },
                            {
                                name: 'win',
                                title: 'Win',
                                render: (val, row) => {
                                    return <Currency amount={val} sign={row.currency} />;
                                }
                            },
                            {
                                name: 'ggr', title: 'GGR',
                                render: (val, row) => {
                                    return <Currency amount={val} sign={row.currency} />;
                                }
                            }
                        ]
                    }}
                        data={data.MostLosingGames ?? []} />
                </FluxCard>
                <FluxCard title={lang('Most Played Casino Games %1', [lang(KPITitle[selectedKPI])])} className={'no-padding'}>
                    <Table model={{
                        fields: [
                            {
                                name: 'totalHands',
                                title: 'Total Hands'
                            },
                            {
                                name: 'gameName',
                                title: 'Game'
                            },
                            {
                                name: 'bet',
                                title: 'Bet',
                                render: (val, row) => {
                                    return <Currency amount={val} sign={row.currency} />;
                                }
                            },
                            {
                                name: 'win',
                                title: 'Win',
                                render: (val, row) => {
                                    return <Currency amount={val} sign={row.currency} />;
                                }
                            },
                            {
                                name: 'ggr', title: 'GGR',
                                render: (val, row) => {
                                    return <Currency amount={val} sign={row.currency} />;
                                }
                            }
                        ]
                    }}
                        data={data.MostPlayedGames ?? []} />
                </FluxCard>
            </flex>
            <flex className='vertical gap-5'>
                <FluxCard title={lang('Sports %1', [lang('Today')])}>
                    <flex className='gap-10 columns-2'>
                        <flex className='vertical gap-10'>
                            <flex className='vertical'>
                                <label className='medium'>{lang('New Tickets')}</label>
                                <span>{data.NewTickets?.total}</span>
                            </flex>

                            <flex className='gap-10 columns-2'>
                                <flex className='vertical'>
                                    <label className='medium'>{lang('Stake')}</label>
                                    <span><Currency amount={data.NewTickets?.bet} sign={currency} /></span>
                                </flex>
                                <flex className='vertical'>
                                    <label className='medium'>{lang('Possible Win')}</label>
                                    <span><Currency amount={data.NewTickets?.possibleWin} sign={currency} /></span>
                                </flex>
                            </flex>
                        </flex>
                        <flex className='vertical gap-10'>
                            <flex className='vertical'>
                                <label className='medium'>{lang('Open Tickets')}</label>
                                <span>{data.OpenTickets?.total}</span>
                            </flex>
                            <flex className='gap-10 columns-2'>
                                <flex className='vertical'>
                                    <label className='medium'>{lang('Stake')}</label>
                                    <span><Currency amount={data.OpenTickets?.bet} sign={currency} /></span>
                                </flex>
                                <flex className='vertical'>
                                    <label className='medium'>{lang('Possible Win')}</label>
                                    <span><Currency amount={data.OpenTickets?.possibleWin} sign={currency} /></span>
                                </flex>
                            </flex>
                        </flex>
                    </flex>
                </FluxCard>
                <FluxCard title='Top 5 Sport Betting Highest Stakes' className={'no-padding'}>
                    <Table
                        model={{
                            fields: [
                                {
                                    name: 'externalTicketId',
                                    title: 'Ticket Id',
                                    render: (val, row) => {
                                        if (!val) return '-';
                                        return <TicketId id={val} />;
                                    }
                                },
                                {
                                    name: 'username', title: 'Username'
                                },
                                {
                                    name: 'ticketType', title: 'Ticket Type',
                                    render: (val) => {
                                        if (!val) return '-';
                                        return SportsTicketTypes[val];
                                    }
                                },
                                {
                                    name: 'bet', title: 'Bet',
                                    render: (val, row) => {
                                        if (!val) return '-';
                                        return <Currency amount={val} sign={row.currency} />;
                                    }
                                },
                                {
                                    name: 'odds', title: 'Odds',
                                    render: (val) => {
                                        if (!val) return '-';
                                        return val.toFixed(2);
                                    }
                                },
                                {
                                    name: 'possibleWin', title: 'Possible Win',
                                    render: (val, row) => {
                                        if (!val) return '-';
                                        return <Currency amount={val} sign={row.currency} />;
                                    }
                                }
                            ]
                        }}
                        data={data.TopSportTicketBets ? completeToNRows(data.TopSportTicketBets, 5) : completeToNRows([], 5)}
                    />
                </FluxCard>
                <FluxCard title='Top 5 Sport Betting Winners' className={'no-padding'}>
                    <Table
                        model={{
                            fields: [
                                {
                                    name: 'externalTicketId', title: 'Ticket Id',
                                    render: (val) => {
                                        if (!val) return '-';
                                        return <TicketId id={val} />
                                    }
                                },
                                {
                                    name: 'username', title: 'Username'
                                },
                                {
                                    name: 'ticketType', title: 'Ticket Type', render: (val) => {
                                        if (!val) return '-';
                                        return SportsTicketTypes[val];
                                    }
                                },
                                {
                                    name: 'bet', title: 'Bet',
                                    render: (val, row) => {
                                        if (!val) return '-';
                                        return <Currency amount={val} sign={row.currency} />;
                                    }
                                },
                                {
                                    name: 'odds', title: 'Odds',
                                    formatter: (val) => {
                                        if (!val) return '-';
                                        return val.toFixed(2);
                                    }
                                },
                                {
                                    name: 'win', title: 'Win',
                                    render: (val, row) => {
                                        if (!val) return '-';
                                        return <Currency amount={val} sign={row.currency} />;
                                    }
                                }

                            ]
                        }}
                        data={data.TopSportWins ? completeToNRows(data.TopSportWins, 5) : completeToNRows([], 5)}
                    />
                </FluxCard>
                <FluxCard title='Top 10 Sports Betting Players' className={'no-padding'}>
                    <Table
                        model={{
                            fields: [
                                {
                                    name: 'username', title: 'Username'
                                },

                                {
                                    name: 'total-tickets', title: 'Tickets', render: (val, row) => row.total
                                },
                                {
                                    name: 'bet', title: 'Bet',
                                    render: (val, row) => {
                                        if (!val) return '-';
                                        return <Currency amount={val} sign={row.currency} />;
                                    }
                                },
                                {
                                    name: 'possibleWin', title: 'Possible Win',
                                    render: (val, row) => {
                                        if (!val) return '-';
                                        return <Currency amount={val} sign={row.currency} />;
                                    }
                                },
                                {
                                    name: 'payout', title: 'Payout',
                                    render: (val, row) => {
                                        if (!val) return '-';
                                        return <Currency amount={val} sign={row.currency} />;
                                    }
                                }

                            ]
                        }}
                        data={data.TopSportPlayers ? completeToNRows(data.TopSportPlayers, 10) : completeToNRows([], 10)}
                    />
                </FluxCard>
            </flex>
        </flex>
    </flex>;
};