/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { lang } from '../../../../../lib/Localization/language';
import { Button } from '../../../../button/Button';
import { FluxCard } from '../../../../card/FluxCard';
import { Table } from '../../../../table/Table';
import { API } from '../../../../../lib/API/Api';
import { HorseRiskLevelEndpoints } from '../../../../../lib/API/Endpoints';
import { MarketLevels } from '../HorseRiskLevelsConfiguration';


const model = {
    fields: [
        {
            name: 'id',
            title: 'Id'
        },
        {
            name: 'sport',
            title: 'Sport'
        },
        {
            name: 'name',
            title: 'Market',
            render: (val, row) => {
                return renderMarketTitle({ id: row.id, type: row.id, name: row.name }, { home: { name: 'Home' }, away: { name: 'Away' } });
            }
        },
        {
            name: 'low',
            title: '',
            render: (val, row, field, context) => {
                const selected = row.level == MarketLevels.Low;
                return <div className={`Low${selected ? ' selected' : ' passive'}`} onClick={() => context.update(row.sportId + ':' + row.key, MarketLevels.Low)}>Low</div>;
            }
        },
        {
            name: 'medium',
            title: '',
            render: (val, row, field, context) => {
                const selected = row.level == MarketLevels.Medium;
                return <div className={`Medium${selected ? ' selected' : ' passive'}`} onClick={() => context.update(row.sportId + ':' + row.key, MarketLevels.Medium)}>Medium</div>;
            }
        },
        {
            name: 'high',
            title: '',
            render: (val, row, field, context) => {
                const selected = row.level == MarketLevels.High;
                return <div className={`High${selected ? ' selected' : ' passive'}`} onClick={() => context.update(row.sportId + ':' + row.key, MarketLevels.High)}>High</div>;
            }
        }
    ]
};



export const renderMarketTitle = (market) => {
    var title = market.name;
    const homeRegex = /{\$competitor1}/ig;
    const awayRegex = /{\$competitor2}/ig;
    title = title.replace(homeRegex, '[Home]');
    title = title.replace(awayRegex, '[Away]');

    return title;
};





export const HorseRiskLevelMarkets = (props) => {
    const { sports } = { ...props };
    const [selectedSport, selectSport] = useState(null);


    const [levels, setLevels] = useState({ default: MarketLevels.High, markets: {} });
    const [markets, setMarkets] = useState([]);
    const [saving, setSaving] = useState(false);


    const marketRef = React.createRef();

    const context = {
        levels: levels,
        update: (key, level) => {
            var copy = JSON.parse(JSON.stringify(levels));
            copy.markets[key].level = level;
            setLevels(copy);
        }
    };

    useEffect(() => {
        API.post(HorseRiskLevelEndpoints.Markets).then((market) => {
            let config = market.result ? JSON.parse(market.result) : { default: MarketLevels.High, markets: {} };
            setLevels(config);
        });
        selectSport(sports[0]);
    }, []);

    useEffect(() => {
        if (!selectedSport) return;

        setMarkets([
            {
                id: 1,
                type: 1,
                name: 'Normal Price'
            },
            {
                id: 2,
                type: 2,
                name: 'Early Price'
            },
            {
                id: 3,
                type: 3,
                name: 'Starting Price'
            },
            {
                id: 4,
                type: 4,
                name: 'Forecast'
            },
            {
                id: 5,
                type: 5,
                name: 'Tricast'
            }
        ]);
    }, [selectedSport]);

    const save = () => {
        setSaving(true);
        API.post(HorseRiskLevelEndpoints.SaveMarkets, levels).then(() => {
            setSaving(false);
        });
    };

    const setDefault = (level) => {
        var copy = { ...levels };
        copy.default = level;
        setLevels(copy);
    };

    const remove = (row) => {
        var copy = { ...levels };
        delete copy.markets[row.sportId + ':' + row.key];
        copy.updated = Date.now();
        setLevels(copy);
    };

    const add = () => {
        var marketId = parseInt(marketRef.current.value);
        var market = null;
        market = markets.find(x => x.id == marketId);
        if (!market) {
            return;
        }

        var copy = { ...levels };
        var key = market.id;
        copy.markets[selectedSport.id + ':' + key] = {
            id: market.id,
            key: key,
            name: market.name,
            sportId: selectedSport.id,
            sport: selectedSport.name,
            level: MarketLevels.High
        };
        copy.updated = Date.now();
        setLevels(copy);
    };


    return <flex className={`risk-level-limits${saving ? ' disabled' : ''} vertical gap-10`}>
        <FluxCard>
            <Button title='Save' className='success align-left' onClick={() => save()} />
        </FluxCard>
        <FluxCard>
            <flex className='flex gap-5'>
                <flex className='flex gap-10'>
                    <select defaultValue={selectedSport?.id} onChangeCapture={(e) => {
                        selectSport(sports.find(x => x.id == parseInt(e.target.value)));
                    }}>

                        {
                            sports.map(x => {
                                return <option key={x.id} value={x.id}>{x.name}</option>;
                            })
                        }
                    </select>
                    <select ref={marketRef}>

                        {
                            markets.sort((a, b) => a.id - b.id).map((x, index) => {
                                return <option key={index} value={x.id}>[{x.id}] {renderMarketTitle(x, { home: { name: 'Home' }, away: { name: 'Away' } })}</option>;
                            })
                        }
                    </select>
                    <Button title='Add' onClick={() => add()} />
                </flex>
                <label className='align-right'>{lang('Default level for markets')}</label>
                <flex className='gap-5'>
                    <div className={`clickable Low${levels.default == 'Low' ? ' selected' : ' passive'}`} onClick={() => setDefault(MarketLevels.Low)}>{MarketLevels.Low}</div>
                    <div className={`clickable Medium${levels.default == 'Medium' ? ' selected' : ' passive'}`} onClick={() => setDefault(MarketLevels.Medium)}>{MarketLevels.Medium}</div>
                    <div className={`clickable High${levels.default == 'High' ? ' selected' : ' passive'}`} onClick={() => setDefault(MarketLevels.High)}>{MarketLevels.High}</div>
                </flex>
            </flex>
        </FluxCard>
        <FluxCard className='overflow-hidden no-padding'>
            <Table model={model} data={Object.values(levels.markets)} context={context} buttons={(row) => {
                return <Button title='Remove' className='warning' onClick={() => remove(row)} />;
            }} />
        </FluxCard>
    </flex>;
};