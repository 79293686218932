export const SportsBetTypes = {
    0: 'Prematch',
    1: 'Live',
    2: 'Pre & Live'
};

export const SportsTicketTypes = {
    0: 'Single',
    1: 'Multi',
    2: 'System',
    3: 'SGP',
    4: 'SGP Combination'
};



export const HorseTicketTypes = {
    0: 'Single',
    3: 'Doubles',
    4: 'Trebles',
    5: 'Trixie',
    6: 'Patent',
    7: 'Yankee',
    8: 'Lucky15',
    9: 'SuperYankee',
    10: 'Lucky31',
    11: 'Heinz',
    12: 'Lucky63',
    13: 'SuperHeinz',
    14: 'Quads', // 4
    15: 'Quints', // 5
    16: 'Sexts', // 6
    17: 'Septs'// 7
};

export const SportsTicketStatus = {
    0: 'Open',
    1: 'Won',
    2: 'Lost',
    3: 'Cashout',
    4: 'Cancel',
    5: 'Cashback',
    6: 'W/Return',
    7: 'L/Return',
    9: 'Bet Cancel',
    10: 'Pending For Manual Settlement',
    11: 'Investigated'
};

export const SportsTicketResolvedStatus = {
    1: 'Won',
    2: 'Lost',
    3: 'Cashout',
    4: 'Cancel',
    5: 'Cashback',
    6: 'L/Return',
    7: 'W/Return',
    9: 'Bet Cancel'
};

export const TicketFlags = {
    0x00400000: 'Manual Ticket Win',
    0x00800000: 'Manual Ticket Lost',
    0x02000000: 'Manual Ticket Cancel',
    0x04000000: 'Bet Cancel',
    0x08000000: 'Rollback Bet Cancel',
    0x10000000: 'Rollback Win',
    0x20000000: 'Rollback Lost'
};

export const BettingFlags = {
    None: 0x00000001,
    Single: 0x00000002,
    Combination: 0x00000004,
    System: 0x00000008,
    Forecast: 0x00000010,
    Tricast: 0x00000020,
    StartingPrice: 0x00000040,
    Horse: 0x00000080,
    Greyhound: 0x00000100,
    Harness: 0x00000200,
    Virtual: 0x00000400,
    ForecastRFC: 0x00040000,
    ForecastCFC: 0x00080000,
    TricastCTC: 0x00100000,
    EachWay: 0x00200000,
    SingleEvent: 0x00000800,
    Prematch: 0x00001000,
    Live: 0x00002000,
    PrematchAndLive: 0x00004000,
    BookedBet: 0x01000000
};

export const RacingTicketTypes = {
    0: 'Single',
    1: 'Forecast',
    2: 'Tricast',
    3: 'Doubles',
    4: 'Triples',
    5: 'Trixie',
    6: 'Patent',
    7: 'Yankee',
    8: 'Lucky15',
    9: 'SuperYankee',
    10: 'Lucky31',
    11: 'Heinz',
    12: 'Lucky63',
    13: 'SuperHeinz',
    14: 'Quads',
    15: 'Quints',
    16: 'Sexts',
    17: 'Septs'
};





export const EventStatus = {
    1: 'Prematch',
    2: 'Live',
    3: 'Ended',
    4: 'Cancelled',
    5: 'Postponed',
    6: 'Interrupted',
    7: 'Abandoned',
    8: 'CoverageLost',
    9: 'AboutToStart',
};

