import './_confirmation-modal.scss';

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FluxModal } from '../FluxModal';
import { Button } from '../../button/Button';
import { Modal } from '../../application/FluxApp';

export const ConfirmationModal = (props) => {
    const close = (e) => {
        if (e.code == 'Escape') Modal.close();
    };

    useEffect(() => {
        window.addEventListener('keyup', close);
        return () => window.removeEventListener('keyup', close);
    }, []);

    return <FluxModal title={props.title} className={`confirmation-modal ${props.className}`} footer={<buttons>
        <Button title={props.confirmTitle ?? 'Save'} className='success' onClick={() => {
            props.onConfirm();
            Modal.close();
        }} />
        <Button title='Cancel' onClick={() => {
            if (props.onCancel) {
                props.onCancel();
                return;
            }
            Modal.close();
        }} />
    </buttons>}>
        {props.children}
    </FluxModal>;
};

ConfirmationModal.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    footer: PropTypes.node,
    className: PropTypes.string,
    confirmTitle: PropTypes.string,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
};
