import { InputTypes } from '../../components/form/InputTypes';

export const CurrencyFilterField = {
    name: 'Currency',
    type: InputTypes.Select,
    exact: true,
    nullable: true,
    data: {
        source: 'enabledCurrencies',
        key: 'CurrencyCode',
        customTitle: (row) => {
            return `(${row.CurrencyCode}) - ${row.Name}`;
        },
    }
};


export const CurrencyModalField = {
    name: 'Currency',
    type: InputTypes.Select,
    required: true,
    data: {
        source: 'enabledCurrencies',
        key: 'CurrencyCode',
        title: ['Name'],
        customTitle: (row) => {
            return `(${row.CurrencyCode}) - ${row.Name}`;
        },
    }
};