/* eslint-disable react/display-name */

import React, { useEffect, useState } from 'react';

import { ActionButton, FluxCard } from '../../../../components/card/FluxCard';
import { PromotionEndpoints } from '../../../../lib/API/Endpoints';
import { API } from '../../../../lib/API/Api';
import { lang } from '../../../../lib/Localization/language';
import ReactSelect from 'react-select';
import { FluxInput } from '../../../../components/form/Components/FluxInput';
import { InputTypes } from '../../../../components/form/InputTypes';
import { Table } from '../../../../components/table/Table';
import { Button } from '../../../../components/button/Button';


export const FirstTimeFreeBetSettings = (props) => {
    const { promotion, onChange, currencies } = { ...props };
    const [configuration, setConfiguration] = useState(null);
    const [selectedCurrency, selectCurrency] = useState(currencies[0]);

    const [freebets, setFreeBets] = useState(null);

    useEffect(() => {
        API.post(PromotionEndpoints.List, {}).then((result) => {
            var p = result.result.filter(x => x.Type == 17 || x.Type == 13).sort((a, b) => a.Name > b.Name ? 1 : -1);
            setFreeBets(p);
        });
    }, []);


    useEffect(() => {
        if (!freebets) return;
        var c = { ...promotion.Configuration };
        if (!c.Award) {
            c.Award = {
                Promotion: {
                    Id: 0,
                    Type: 0,
                    Amount: 0,
                    Expires: 0
                },
                Limits: {}
            };
        }

        if (!c.Award.Limits) {
            c.Award.Limits = {}
        }

        setConfiguration(c);
    }, [freebets]);


    useEffect(() => {
        if (!configuration) return;
        var copy = { ...promotion };
        copy.Configuration = configuration;
        onChange(copy);
    }, [configuration]);


    const addNewLimit = () => {
        var copy = { ...configuration };
        if (Array.isArray(configuration.Award.Limits)) {
            configuration.Award.Limits = {};
        }
        if (!configuration.Award.Limits) {
            configuration.Award.Limits = {};
        }
        if (!configuration.Award.Limits[selectedCurrency.CurrencyCode]) {
            configuration.Award.Limits[selectedCurrency.CurrencyCode] = [];
        }

        configuration.Award.Limits[selectedCurrency.CurrencyCode].push({
            from: 0,
            to: 0,
            percentage: 0
        });

        setConfiguration(copy);
    };


    const removeLimit = (row) => {
        var copy = { ...configuration };
        configuration.Award.Limits[selectedCurrency.CurrencyCode] = configuration.Award.Limits[selectedCurrency.CurrencyCode].filter(x => x !== row);
        setConfiguration(copy);
    };

    const updateLimit = (row, e) => {
        var copy = { ...configuration };
        if (!configuration.Award.Limits) {
            configuration.Award.Limits = {};
        }
        if (!configuration.Award.Limits[selectedCurrency.CurrencyCode]) {
            configuration.Award.Limits[selectedCurrency.CurrencyCode] = [];
        }

        var limit = configuration.Award.Limits[selectedCurrency.CurrencyCode].find(x => x === row);
        if (!limit) {
            limit = { ...row };
            configuration.Award.Limits[selectedCurrency.CurrencyCode].push(limit);
        }
        var value = parseFloat(e.target.value);
        if (isNaN(value)) value = 0;
        if (e.target.name == 'percentage') {
            e.target.value = value;
        }
        limit[e.target.name] = value;
        if (!row.freeBet && configuration.Award == 17) {
            if (freebetOptions[0]) {
                limit.freeBet = freebetOptions[0].value;
                limit.promotionType = freebets.find(x => x.Id == freebetOptions[0].value).Type;
            }
        }
        if (e.target.name == 'freeBet') {
            limit.promotionType = freebets.find(x => x.Id.toString() == e.target.value.toString()).Type;
        }
        setConfiguration(copy);
    };

    if (!configuration) return <></>;
    if (!freebets) return <></>;


    var freebetOptions = freebets.map(x => {
        return {
            label: x.Name,
            value: x.Id
        };
    });


    var selected = freebets.find(x => x.Id == configuration.Award.Promotion.Id);
    var selectedFreeBetOption = selected ? freebetOptions.find(x => x.value == selected.Id) : null;
    return <>

        <FluxCard>
            <flex className='vertical gap-10' key={configuration.Award.Promotion.Id}>
                <flex className='vertical'>

                    <span>{lang('Please select a free bet for award')}</span>
                    <ReactSelect
                        className='react-select'
                        styles={{ menuPortal: base => ({ ...base, zIndex: 100 }) }}
                        menuPortalTarget={document.body}
                        defaultValue={selectedFreeBetOption}
                        options={freebetOptions}
                        onChange={(e) => {
                            var promotion = freebets.find(x => x.Id == e.value);
                            var copy = { ...configuration };
                            copy.Award.Promotion = {
                                Id: promotion.Id,
                                Type: promotion.Type,
                                Amount: 0,
                                Expires: 0,
                                AutoTrigger: false
                            };
                            setConfiguration(copy);
                        }}
                        name={'freebet'}
                        hideSelectedOptions={false}
                        closeMenuOnSelect={true}
                        isClearable={false}
                        isSearchable={false}
                    />
                </flex>
                <flex className='gap-20'>
                    <flex className='vertical'>
                        <span>{lang('Amount')}</span>
                        <input type='number' defaultValue={configuration.Award.Promotion.Amount} onChange={(e) => {
                            var copy = { ...configuration };
                            let value = parseInt(e.target.value);
                            copy.Award.Promotion = {
                                Id: copy.Award.Promotion.Id,
                                Type: copy.Award.Promotion.Type,
                                Amount: value,
                                Expires: copy.Award.Promotion.Expires,
                                AutoTrigger: copy.Award.Promotion.AutoTrigger
                            };
                            setConfiguration(copy);
                        }} />
                    </flex>
                    <flex className='vertical'>
                        <span>{lang('Expire promotion after (n) days')}</span>
                        <input type='number' defaultValue={configuration.Award.Promotion.Expires} onChange={(e) => {
                            var copy = { ...configuration };
                            let value = parseInt(e.target.value);
                            copy.Award.Promotion = {
                                Id: copy.Award.Promotion.Id,
                                Type: copy.Award.Promotion.Type,
                                Amount: copy.Award.Promotion.Amount,
                                Expires: value,
                                AutoTrigger: copy.Award.Promotion.AutoTrigger
                            };
                            setConfiguration(copy);
                        }} />
                    </flex>
                    <flex className='vertical'>
                        <span>{lang('Automatically Trigger Award')}</span>
                        <FluxInput
                            field={{
                                type: InputTypes.Bool,
                                name: 'AutoTrigger',
                                value: configuration?.Award?.Promotion?.AutoTrigger ?? false,
                                data: {
                                    AutoTrigger: configuration?.Award?.Promotion?.AutoTrigger ?? false
                                }
                            }} onChange={(field, value) => {
                                var copy = { ...configuration };
                                copy.Award.Promotion = {
                                    Id: copy.Award.Promotion.Id,
                                    Type: copy.Award.Promotion.Type,
                                    Amount: copy.Award.Promotion.Amount,
                                    Expires: copy.Award.Promotion.Expires,
                                    AutoTrigger: value
                                };
                                setConfiguration(copy);
                            }} />
                    </flex>
                </flex>
            </flex>
        </FluxCard>
        {configuration.Award.Promotion.Type == 13 &&
            <FluxCard title='Split Free Bet Percentage Limits' buttons={<ActionButton title='Add' icon='plus-square' onClick={() => addNewLimit()} />}>
                <div className='flex vertical'>
                    <flex className='gap-10 padding'>
                        <span>{lang('Currency')}</span>
                        <select onChangeCapture={(e) => {
                            const val = e.target.value;
                            selectCurrency(currencies[parseInt(val)]);
                        }}>
                            {
                                currencies.map((key, index) => {
                                    return <option key={key} value={index}>{`(${key.CurrencyCode}) - ${key.Name}`}</option>;
                                })
                            }
                        </select>
                    </flex>
                    <Table className='fx-borders fx-shadow'
                        key={selectedCurrency.CurrencyCode}
                        model={{
                            fields: [
                                {
                                    name: 'from',
                                    title: 'From',
                                    render: (val, row, field) => {
                                        return <input type='number' name={field.name}
                                            defaultValue={val}
                                            onFocus={(e) => {
                                                e.target.setAttribute('data', e.target.value);
                                                e.target.value = '';
                                            }}
                                            onBlur={(e) => {
                                                if (e.target.value == '') {
                                                    e.target.value = e.target.getAttribute('data') ?? 0;
                                                }
                                            }}
                                            onChangeCapture={(e) => {
                                                updateLimit(row, e);
                                            }} />
                                    }
                                },
                                {
                                    name: 'to',
                                    title: 'To',
                                    render: (val, row, field) => {
                                        return <input type='number' name={field.name}
                                            defaultValue={val}
                                            onFocus={(e) => {
                                                e.target.setAttribute('data', e.target.value);
                                                e.target.value = '';
                                            }}
                                            onBlur={(e) => {
                                                if (e.target.value == '') {
                                                    e.target.value = e.target.getAttribute('data') ?? 0;
                                                }
                                            }}
                                            onChangeCapture={(e) => {
                                                updateLimit(row, e);
                                            }} />
                                    }
                                },
                                {
                                    name: 'percentage',
                                    title: 'Percentage',
                                    render: (val, row, field) => {
                                        return <input type='number' name={field.name}
                                            defaultValue={val}
                                            max={100}
                                            onFocus={(e) => {
                                                e.target.setAttribute('data', e.target.value);
                                                e.target.value = '';
                                            }}
                                            onBlur={(e) => {
                                                if (e.target.value == '') {
                                                    e.target.value = e.target.getAttribute('data') ?? 0;
                                                }
                                            }}
                                            onChangeCapture={(e) => {
                                                updateLimit(row, e);
                                            }} />
                                    }
                                }

                            ]
                        }} data={(configuration.Award.Limits[selectedCurrency.CurrencyCode]) ? configuration.Award.Limits[selectedCurrency.CurrencyCode] : []} buttons={(row) => {
                            return <Button title='Remove' className={'warning'} onClick={() => removeLimit(row)} />;
                        }} />
                </div>
            </FluxCard>
        }
        {configuration.Award.Promotion.Type == 17 &&
            <FluxCard title='Free Bet Percentage Limits' buttons={<ActionButton title='Add' icon='plus-square' onClick={() => addNewLimit()} />}>
                <div className='flex vertical'>
                    <flex className='gap-10 padding'>
                        <span>{lang('Currency')}</span>
                        <select onChangeCapture={(e) => {
                            const val = e.target.value;
                            selectCurrency(currencies[parseInt(val)]);
                        }}>
                            {
                                currencies.map((key, index) => {
                                    return <option key={key} value={index}>{`(${key.CurrencyCode}) - ${key.Name}`}</option>;
                                })
                            }
                        </select>
                    </flex>
                    <Table className='fx-borders fx-shadow'
                        key={selectedCurrency.CurrencyCode}
                        model={{
                            fields: [
                                {
                                    name: 'from',
                                    title: 'From',
                                    render: (val, row, field) => {
                                        return <input type='number' name={field.name}
                                            defaultValue={val}
                                            onFocus={(e) => {
                                                e.target.setAttribute('data', e.target.value);
                                                e.target.value = '';
                                            }}
                                            onBlur={(e) => {
                                                if (e.target.value == '') {
                                                    e.target.value = e.target.getAttribute('data') ?? 0;
                                                }
                                            }}
                                            onChangeCapture={(e) => {
                                                updateLimit(row, e);
                                            }} />
                                    }
                                },
                                {
                                    name: 'to',
                                    title: 'To',
                                    render: (val, row, field) => {
                                        return <input type='number' name={field.name}
                                            defaultValue={val}
                                            onFocus={(e) => {
                                                e.target.setAttribute('data', e.target.value);
                                                e.target.value = '';
                                            }}
                                            onBlur={(e) => {
                                                if (e.target.value == '') {
                                                    e.target.value = e.target.getAttribute('data') ?? 0;
                                                }
                                            }}
                                            onChangeCapture={(e) => {
                                                updateLimit(row, e);
                                            }} />
                                    }
                                },
                                {
                                    name: 'percentage',
                                    title: 'Percentage',
                                    render: (val, row, field) => {
                                        return <input type='number' name={field.name}
                                            defaultValue={val}
                                            max={100}
                                            onFocus={(e) => {
                                                e.target.setAttribute('data', e.target.value);
                                                e.target.value = '';
                                            }}
                                            onBlur={(e) => {
                                                if (e.target.value == '') {
                                                    e.target.value = e.target.getAttribute('data') ?? 0;
                                                }
                                            }}
                                            onChangeCapture={(e) => {
                                                updateLimit(row, e);
                                            }} />
                                    }
                                },
                                {
                                    name: 'freeBet',
                                    title: 'Free Bet',
                                    hidden: configuration.Award != 17,
                                    render: (val, row) => {
                                        var selectedFreeBetOption = freebetOptions[0];
                                        if (val) {
                                            selectedFreeBetOption = freebetOptions.find(x => x.value.toString() == val);
                                        }

                                        return <ReactSelect
                                            className='react-select'
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 100 }) }}
                                            menuPortalTarget={document.body}
                                            defaultValue={selectedFreeBetOption}
                                            options={freebetOptions}
                                            onChange={(e) => {
                                                updateLimit(row, {
                                                    target: {
                                                        name: 'freeBet',
                                                        value: e.value
                                                    }
                                                });
                                            }}
                                            name={'freebet'}
                                            hideSelectedOptions={false}
                                            closeMenuOnSelect={true}
                                            isClearable={false}
                                            isSearchable={false}
                                        />
                                    }
                                }

                            ]
                        }} data={(configuration.Award.Limits[selectedCurrency.CurrencyCode]) ? configuration.Award.Limits[selectedCurrency.CurrencyCode] : []} buttons={(row) => {
                            return <Button title='Remove' className={'warning'} onClick={() => removeLimit(row)} />;
                        }} />
                </div>
            </FluxCard>
        }</>
};