/* eslint-disable react/display-name */

import React from 'react';
import { FluxTab } from '../../../../components/tab/FluxTab';
import { PaymentProviderWhiteListGroups } from './PaymentProviderWhiteListGroups';
import { PaymentProviderWhiteListPlayers } from './PaymentProviderWhiteListPlayers';


export const PaymentProviderWhiteLists = () => {
    const tabButtons = [
        {
            name: 'groups',
            title: 'Player Groups'
        },
        {
            name: 'players',
            title: 'Players'
        }
    ];

    return <flex className='vertical gap-10 overflow-hidden'>
        <FluxTab buttons={tabButtons} className='overflow-hidden gap-10 fx-shadow fx-radius rounded-buttons'>
            <PaymentProviderWhiteListGroups/>
            <PaymentProviderWhiteListPlayers/>
        </FluxTab>
    </flex>;
};