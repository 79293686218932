import React from 'react';
import { FluxTab } from '../../../components/tab/FluxTab';
import { CasinoSettings } from './Components/CasinoSettings';
import { GeneralSettings } from './Components/GeneralSettings';

export const FreespinBonus = (props) => {
    var buttons = [
        {
            title: 'General Settings'
        },
        {
            title: 'Game Settings'
        }
    ];

    return <FluxTab buttons={buttons} className='gap-10 fx-shadow fx-radius rounded-buttons'>
        <GeneralSettings disableBonusWallet {...props} />
        <CasinoSettings slotsEnabled liveCasinoDisabled {...props} />
    </FluxTab>;
};