import './_login-page.scss';

import React, { useState } from 'react';
import { FluxCard } from '../../components/card/FluxCard';
import { FluxForm } from '../../components/form/FluxForm';
import { InputTypes } from '../../components/form/InputTypes';
import { Button } from '../../components/button/Button';
import { API } from '../../lib/API/Api';
import { AuthenticationEndpoints } from '../../lib/API/Endpoints';
import { FluxNotice } from '../../components/notification/FluxNotice';
import { NotificationType } from '../../components/notification/FluxNotification';
import { toPascalCase } from '../../../components/Centrum/helpers';
import { fluxVersion } from '../../components/menu/Menu';
export const LoginPage = () => {
    const [data, setData] = useState({});
    const [formEnabled, setFormEnabled] = useState(false);
    const [error, setError] = useState(null);
    const [busy, setBusy] = useState(false);

    const submit = () => {
        setBusy(true);
        API.post(AuthenticationEndpoints.Login, data).then((result) => {
            let loginInformation = {
                token: result.result.token,
                lastIp: result.result.lastIp,
                lastLogin: result.result.lastLogin
            };
            document.cookie = 'backend-portal-auth=' + loginInformation.token + ';expires=Fri, 31 Dec 9999 23:59:59 GMT;path=/';
            var data = toPascalCase(result.result);
            data.UserPrivileges = toPascalCase(data.UserPrivileges);
            window.location.href = '/';

        }).catch((err) => {
            setError(err?.error?.message ?? 'general-error');
            setBusy(false);
        });
    };

    const renderError = () => {
        if (!error) return;
        var title = 'Login failed';
        var message = error;
        if (error == 'general-error') {
            message = 'Service not available, please try again later.';
        }

        return <FluxNotice type={NotificationType.Error} title={title} description={message} />;
    };

    return <flex className={`flux-login-page${busy ? ' disabled' : ''}`}>
        <FluxCard title='Login' className='login-card' footer={<flex className='center'><Button title='Login' className={formEnabled ? 'success' : 'disabled'} onClick={() => submit()} /></flex>} >
            {renderError()}
            <FluxForm
                onSaveEnabled={(s) => setFormEnabled(s)}
                onChange={(data) => setData(data)}
                model={{
                    fields: [
                        {
                            name: 'Email',
                            title: 'Email',
                            required: true
                        },
                        {
                            name: 'Password',
                            title: 'Password',
                            type: InputTypes.Password,
                            required: true
                        }
                    ]
                }} buttons={null} />
        </FluxCard>
        <span className='info'>{fluxVersion}</span>
    </flex>;
};