export const ProviderGameTypes = {
    0: 'Undefined',
    1: 'Card Game',
    2: 'Table Game',
    3: 'Casual Game',
    4: 'Classic Slot',
    5: 'Video Poker',
    6: 'Scratch Game',
    7: 'Video Slots',
    8: 'Virtual Sport',
    9: 'Lottery',
    10: 'Black Jack',
    11: 'Roulette',
    12: 'Baccarat',
    13: 'Casino Holdem',
    14: 'Keno',
    15: 'Live Slots',
    16: 'MagicCard',
    17: 'Tombala',
    18: 'TombalaSlot',
    19: 'Casino Over Under Live',
    20: 'CasinoWar Live',
    21: 'ProvablyFair',
    22: 'Esport',
    23: 'Sicbo',
    24: 'Wheel Games',
    25: 'Lobby',
    26: 'Poker',
    27: 'Craps',
    28: 'Bingo',
    29: 'Caribbean Stud Poker',
    30: 'Texas Holdem',
    31: 'Dream Catcher',
    32: 'American Roulette',
    33: 'Football Studio',
    34: 'Dragon',
    35: 'Auto Roulette',
    36: 'Oasis Poker',
    37: 'Monopoly',
    38: 'Andar bahar',
    39: 'Pachinko',
    40: 'Teen Patti',
    41: 'Crash Games',
    42: 'Candy',
    43: 'Dice',
    44: 'HiLo',
    45: 'Mine',
    46: 'Plinko',
    47: 'Scratch Cards',
    48: 'Sette E Mezzo',
    49: 'Show',
    50: 'Instant Win Games',
    51: 'Fruits',

    3000: 'Live.Roulette',
    3001: 'Live.AutoRoulette',
    3002: 'Live.Baccarat',
    3003: 'Live.BlackJack',
    3004: 'Live.Casino Holdem',
    3005: 'Live.Poker',
    3006: 'Live.Keno',
    3007: 'Live.SicBo',
    3008: 'Live.ScratchCards',
    3009: 'Live.MegaWheel',
    3010: 'Live.GameShow',
    3011: 'Live.DragonTiger',
    3012: 'Live.CoinFlip',

    4000: 'Animal Fights - Cockfight'
};