import './_search-events.scss';

import React, { useEffect, useState } from 'react';
import { ActionButton, FluxCard } from '../../../components/card/FluxCard';
import { SportsTree } from '../../../components/sports/SportsTree/SportsTree';
import { API } from '../../../lib/API/Api';
import { SportEndpoints } from '../../../lib/API/Endpoints';
import { lang } from '../../../lib/Localization/language';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { Icon } from '../../../../components/Controls/Icon/Icon';
import { Button } from '../../../components/button/Button';
import { Event } from '../../../components/sports/SportEvent/Event';
import { appHistory } from '../../../..';


const EventFinder = (props) => {
    const { categories, setSearchType, onEventSelected } = { ...props };
    const [regions, setRegions] = useState(null);
    const [leagues, setLeagues] = useState(null);
    const [events, setEvents] = useState(null);

    const [category, selectCategory] = useState(null);
    const [region, selectRegion] = useState(null);
    const [league, selectLeague] = useState(null);

    const [eventDates, setEventDates] = useState(null);
    const [selectedEvent, selectEvent] = useState(null);

    const [searchingEventId, setSearchEventId] = useState(null);

    const [event, setEvent] = useState(null);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = parseFloat(urlParams.get('id'));
        if (!isNaN(id) && (!event || event?.id !== id)) {
            setSearchEventId(id);
        }

        selectCategory(categories[0]);
    }, []);

    useEffect(() => {
        if (!category) return;

        API.post(`${SportEndpoints.Regions}/${category.id}`, null).then((result) => {
            if (result.result.length) {
                if (event != null) {
                    selectRegion(result.result.find(x => x.id == event.regionId));
                } else {
                    selectRegion(result.result[0]);
                }
            } else {
                selectRegion(null);
            }
            setRegions(result.result);
        });
    }, [category]);


    useEffect(() => {
        if (!region) return;
        API.post(`${SportEndpoints.Leagues}/${category.id}/${region.id}`).then((result) => {
            var l = result.result;
            setLeagues(l);
            if (event) {
                selectLeague(result.result.find(x => x.id == event.leagueId));
            } else {
                selectLeague(l.length > 0 ? l[0] : null);
            }
        });

    }, [region]);

    useEffect(() => {
        if (!league) return;


        const urlParams = new URLSearchParams(window.location.search);
        const id = parseFloat(urlParams.get('id'));
        if (!isNaN(id) && (!event || event?.id !== id)) {
            searchEventById(id);
            return;
        }

        API.post(SportEndpoints.Events, {
            sportId: category.id,
            regionId: region.id,
            leagueId: league.id
        }).then((result) => {
            var l = result.result.sort((a, b) => new Date(a.date) - new Date(b.date));
            setEvents(l);
        });
    }, [league]);

    useEffect(() => {
        if (!event) return;

        if (category.id !== event.sportId) {
            selectCategory(categories.find(x => x.id == event.sportId));
        } else {
            if (region?.id !== event.regionId) {
                selectRegion(regions.find(x => x.id == event.regionId));
            } else {
                if (league?.id !== event.leagueId) {
                    selectLeague(leagues.find(x => x.id == event.leagueId));
                }
            }
        }

        onEventSelected(event);
    }, [event]);

    useEffect(() => {
        if (!events) {
            setEventDates({});
        } else {
            var dates = {};
            events.map(event => {
                if (event.outright) return;

                var date = new Date(event.date.indexOf('Z') > 0 ? event.date : event.date + 'Z');
                if (date < new Date()) return;
                var data = event.name.split(' vs ');
                var home = data[0];
                var away = data[1];
                var formatted = DateHelpers.getFormatedDateWithMonth(date);

                var key = `${formatted.month} ${formatted.year}, ${formatted.day}`;

                if (!dates[key]) {
                    dates[key] = [];
                }

                dates[key].push({
                    home: home,
                    away: away,
                    formatted: formatted,
                    data: event
                });
            });

            setEventDates(dates);
        }
    }, [events]);

    const renderEvents = () => {
        return <items>
            {Object.keys(eventDates).map(dateData => {
                var dateValues = eventDates[dateData];
                return <React.Fragment key={dateData}>
                    <item><label className='bold'>{dateData}</label></item>
                    <items>
                        {
                            dateValues.map(x => {
                                var selected = x.data == selectedEvent;
                                return <item key={x}>
                                    <flex className={`${selected ? ' selected' : ''} gap-10 align-items-start`}
                                        onClick={() => {
                                            if (onEventSelected) {
                                                onEventSelected(x.data);
                                            }
                                            if (selectedEvent == x) {
                                                selectedEvent(null);
                                                return;
                                            }
                                            selectEvent(x.data);
                                        }}>
                                        <span>{x.formatted.time}</span>
                                        <flex className='vertical'>
                                            <label className='medium'>{x.data.id}</label>
                                            <span>{x.home}</span>
                                            <span>{x.away}</span>
                                        </flex>
                                        <flex className='align-right gap-5'>
                                            {selected && <Icon icon='chevron-right' size='xs' className='toggle-icon' />}
                                        </flex>
                                    </flex>
                                </item>;
                            })
                        }
                    </items>
                </React.Fragment>;

            })}
        </items>;
    };

    const searchEventById = (eId) => {
        API.post(`${SportEndpoints.Event}/${eId}`).then((result) => {
            if (result.result && result.result.id == eId) {
                setEvent(result.result);
            }
        });
    };

    if (!category) return <></>;
    return <flex className='vertical gap-10  browser'>
        <FluxCard title='Browse' className={''} buttons={<ActionButton title='Event Tree' icon='list-tree' onClick={() => setSearchType('tree')} />}>
            <flex className='vertical gap-10'>
                <flex className='vertical'>
                    <label className='medium'>{lang('Sport')}</label>
                    <select value={category?.id} onChangeCapture={(e) => {
                        var categoryId = parseInt(e.target.value);
                        selectCategory(categories.find(x => x.id == categoryId));
                    }}>
                        {categories.map(x => {
                            return <option key={x} value={x.id}>{x.name}</option>;
                        })}
                    </select>
                </flex>
                {regions && <flex className='vertical'>
                    <label className='medium'>{lang('Region')}</label>
                    <select value={region?.id} onChangeCapture={(e) => {
                        var regionId = parseInt(e.target.value);
                        selectRegion(regions.find(x => x.id == regionId));
                    }}>
                        {regions.map(x => {
                            return <option key={x} value={x.id}>{x.name}</option>;
                        })}
                    </select>
                </flex>
                }
                {leagues && <flex className='vertical'>
                    <label className='medium'>{lang('Leagues')}</label>
                    <select value={league?.id}
                        onChangeCapture={(e) => {
                            var leagueId = parseInt(e.target.value);
                            selectLeague(leagues.find(x => x.id == leagueId));
                        }}>
                        {leagues.map(x => {
                            return <option key={x} value={x.id}>{x.name}</option>;
                        })}
                    </select>
                </flex>
                }
            </flex>
        </FluxCard>
        <FluxCard title='Search by event id'>
            <flex className='gap-5 fit-children'>
                <input type='number' value={searchingEventId} onChange={(e) => {
                    var id = parseFloat(e.target.value);
                    if (isNaN(id)) {
                        id = 0;
                    }

                    if (e.target.value.length < 5) id = 0;
                    setSearchEventId(id);
                }} />
                <Button title='Search' className={`success ${searchingEventId ? '' : 'disabled'}`} onClick={() => {
                    searchEventById(searchingEventId);
                }} />
            </flex>
        </FluxCard>
        {events && <FluxCard title='Events' className={'overflow-hidden no-padding'}>
            <fluxList>
                <items>
                    {renderEvents()}
                </items>
            </fluxList>
        </FluxCard>}
    </flex>;
};

export const SearchEvents = () => {
    const [searchType, setSearchType] = useState('browser');
    const [categories, setCategories] = useState(null);

    const [event, selectEvent] = useState(null);

    useEffect(() => {
        API.post(SportEndpoints.Categories, null).then((result) => {
            var c = result.result.sort((a, b) => a.weight - b.weight);
            setCategories(c);
        });
    }, []);


    useEffect(() => {
        if (!event) return;
        appHistory.push(`/sports/events?&id=${event.id}`);
    }, [event]);

    if (!categories) return <></>;

    return <flex className='search-browse-events vertical gap-10 fit-children overflow-hidden'>
        <flex className='gap-10 fit-children overflow-hidden no-padding'>
            {searchType == 'tree' && <FluxCard title='Browse' className={'browser no-padding'} buttons={<ActionButton title='Search Event' icon='search' onClick={() => setSearchType('browser')} />}>
                <SportsTree
                    categories={categories}
                    regions leagues events />
            </FluxCard>
            }
            {searchType == 'browser' && <EventFinder setSearchType={setSearchType} categories={categories} onEventSelected={(e) => selectEvent(e)} />}

            <Event event={event} key={event} />
        </flex>
    </flex>;
};