/* eslint-disable react/display-name */
import './_player-last-depositsNwithdraws.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { FluxCard } from '../../../components/card/FluxCard';
import { Button } from '../../../components/button/Button';
import { lang } from '../../../lib/Localization/language';
import { Table } from '../../../components/table/Table';
import { InputTypes } from '../../../components/form/InputTypes';
import { Icon } from '../../../components/icon/Icon';
import { rollbackDeposit } from '../../../actions/TransactionActions';
import { useSelector } from 'react-redux';
import { currency } from '../../../components/currency/Currency';


const transactionModel = {
    fields: [
        {
            name: 'id',
            title: 'Id',
            render: (val, row, field, context) => {
                return <a href='#' onClick={(e) => {
                    e.preventDefault();
                    context.showTransactions(val);
                }}>{val}</a>;
            }
        },
        {
            name: 'date', title: 'Date', type: InputTypes.DateTime, multiLine: true
        },
        {
            name: 'source',
            title: 'Source',
            render: (val) => {
                if (val) {
                    var splitted = val.split('/#185$/');
                    if (splitted && splitted.length > 1) {
                        return <flex className='vertical'>
                            <div>{lang(splitted[0])}</div>
                            <div>{lang(splitted[1])}</div>
                        </flex>;
                    }
                }
                return lang(val);
            }
        },
        {
            name: 'Bonus',
            render: (val, row) => {
                var bonus = row.bonus || row.source == 'Promotion';

                return <div className='vertical'>
                    {bonus && <div className='flex'><span>{lang('Bonus')}</span><Icon icon='check-circle' size='1x' className='align-right' /></div>}
                    {row.bonusWallet && <div className='flex'><span>{lang('Bonus Wallet')}</span><Icon icon='check-circle' size='1x' className='align-right' /></div>}
                </div>;
            }
        },
        {
            name: 'amount', title: 'Amount',
            render: (val, row) => {
                return currency(val, 2, row.currency);
            }
        },
        { name: 'admin', title: 'Admin' }
    ]
};


export const PlayerLastDeposits = (props) => {
    const player = props.player;

    const user = useSelector((state) => state.user.loginInformation);
    const isAffiliate = user.AffiliateId > 0;

    return <FluxCard
        className={'no-padding'}
        title={<React.Fragment>
            <Icon icon='inbox-in' type='fa-regular' />
            <span>{lang('Last deposits')}</span>
        </React.Fragment>}
        buttons={
            <div className='flex gap-10 align-right padding-right'>
                <div className='flex gap-5 small'>
                    <label>{lang('Total')}</label>
                    <span>{player.depositCount}</span>
                </div>
                <div className='flex gap-5 small'>
                    <label>{lang('Amount')}</label>
                    <span>{currency(player.totalDeposit, 2, player.currency)}</span>
                </div>
            </div>
        }>
        {player.deposits && <Table key={player.deposits} data={player.deposits}
            className={'player-last-depositsNwithdraws-table'}
            context={props.context}
            model={transactionModel} buttons={(row) => {
                if (row.status == 2) {
                    return <span>{lang('Cancelled')}</span>;
                }
                if (isAffiliate) return <></>;
                return <Button className='warning' title='Cancel' onClick={() => {
                    rollbackDeposit(row, () => {
                        props.onTransactionComplete();
                    });
                }} />;
            }} />}
    </FluxCard>;
};

PlayerLastDeposits.propTypes = {
    player: PropTypes.object,
    context: PropTypes.object,
    onTransactionComplete: PropTypes.func
};
