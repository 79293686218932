import React from 'react';
import { ActionTypes } from '../../lib/ActionManager/FluxActions';
import { FluxAction } from '../../components/application/FluxAction';
import { FluxPage } from '../../components/page/FluxPage';
import { ReportCasinoRevenues } from './Revenues/ReportCasinoRevenues';
import { ReportCasinoProviders } from './Revenues/ReportCasinoProviders';
import { ReportDailyRevenues } from './Revenues/ReportDailyRevenues';
import { ReportGameRevenues } from './Revenues/ReportGameRevenues';
import { ReportPlayerCasinoRevenues } from './Revenues/ReportPlayerCasinoRevenues';
import { ReportPlayerGroupsCasinoRevenues } from './Revenues/ReportPlayerGroupsCasinoRevenues';
import { ReportCasinoProviderCommision } from './Revenues/ReportCasinoProviderCommision';
import { ReportSportRevenues } from './Revenues/ReportSportRevenues';
import { ReportSportPlayerRevenues } from './Revenues/ReportSportPlayerRevenues';
import { ReportSportPlayerGroupRevenues } from './Revenues/ReportSportPlayerGroupRevenues';
import { ReportCategoriesSportBetting } from './SportBetting/ReportCategoriesSportBetting';
import { ReportRegionsSportBetting } from './SportBetting/ReportRegionsSportBetting';
import { ReportLeaguesSportBetting } from './SportBetting/ReportLeaguesSportBetting';
import { ReportEventsSportBetting } from './SportBetting/ReportEventsSportBetting';
import { ReportMarketsSportBetting } from './SportBetting/ReportMarketsSportBetting';
import { ReportSelectionsSportBetting } from './SportBetting/ReportSelectionsSportBetting';
import { ReportPlayersSportBetting } from './SportBetting/ReportPlayersSportBetting';
import { ReportTicketsSportBetting } from './SportBetting/ReportTicketsSportBetting';
import { ReportPlayerWithoutBetsSportBetting } from './SportBetting/ReportPlayerWithoutBetsSportBetting';
import { ReportEventSummary } from './SportBetting/ReportEventSummary';
import { ReportRegionsHorseGreyhound } from './HorseGreyhound/ReportRegionsHorseGreyhound';
import { ReportVenuesHorseGreyhound } from './HorseGreyhound/ReportVenuesHorseGreyhound';
import { ReportDistanceHorseGreyhound } from './HorseGreyhound/ReportDistanceHorseGreyhound';
import { ReportPlayersHorseGreyhound } from './HorseGreyhound/ReportPlayersHorseGreyhound';
import { ReportTicketsHorseGreyhound } from './HorseGreyhound/ReportTicketsHorseGreyhound';
import { ReportPlayerRevenuesSummary } from './PlayerSummary/ReportPlayerRevenuesSummary';
import { ReportPlayerGamesSummary } from './PlayerSummary/ReportPlayerGamesSummary';
import { ReportAccountSummaryTransactions } from './Transactions/ReportAccountSummaryTransactions';
import { ReportProvidersTransactions } from './Transactions/ReportProvidersTransactions';
import { ReportPlayersTransactions } from './Transactions/ReportPlayersTransactions';
import { ReportFirstTransactions } from './Transactions/ReportFirstTransactions';
import { ReportAccountSummaryRetails } from './Retails/ReportAccountSummaryRetails';
import { ReportTransactionsRetails } from './Retails/ReportTransactionsRetails';
import { ReportPlayerTransactionsRetails } from './Retails/ReportPlayerTransactionsRetails';
import { ReportBonusTransactions } from './Bonus/ReportBonusTransactions';
import { ReportBonusPlayers } from './Bonus/ReportBonusPlayers';
import { ReportBonusPlayerSummary } from './Bonus/ReportBonusPlayerSummary';
import { ReportBonusCompletedPayouts } from './Bonus/ReportBonusCompletedPayouts';
import { ReportBonusCompletedPayoutsSummary } from './Bonus/ReportBonusCompletedPayoutsSummary';
import { ReportBonusRemovedBonuses } from './Bonus/ReportBonusRemovedBonuses';
import { ReportHorseGreyhoundRevenues } from './HorseGreyhound/ReportHorseGreyhoundRevenues';
import { ReportSportBettingMargins } from './SportBetting/ReportSportBettingMargins';
import { ReportDeductedBonusAmountsReport } from './Bonus/ReportDeductedBonusAmountsReport';
import { ReportBettingOperational } from './Revenues/ReportBettingOperational';

export const ReportsPage = () => {
    return <FluxPage action={ActionTypes.Reports}>
        {/* Revenues */}
        <FluxAction action={ActionTypes.DailyRevenuesSummaryReport}><ReportDailyRevenues /></FluxAction>
        <FluxAction action={ActionTypes.CasinoRevenuesReport}><ReportCasinoRevenues /></FluxAction>
        <FluxAction action={ActionTypes.CasinoProviderRevenuesReport}><ReportCasinoProviders /></FluxAction>
        <FluxAction action={ActionTypes.GameRevenuesReport}><ReportGameRevenues /></FluxAction>
        <FluxAction action={ActionTypes.PlayerCasinoRevenuesReport}><ReportPlayerCasinoRevenues /></FluxAction>
        <FluxAction action={ActionTypes.CasinoPlayerGroupsRevenuesReport}><ReportPlayerGroupsCasinoRevenues /></FluxAction>
        <FluxAction action={ActionTypes.CasinoProviderCommisionReport}><ReportCasinoProviderCommision /></FluxAction>
        <FluxAction action={ActionTypes.SportRevenues}><ReportSportRevenues /></FluxAction>
        <FluxAction action={ActionTypes.SportPlayerRevenues}><ReportSportPlayerRevenues /></FluxAction>
        <FluxAction action={ActionTypes.SportPlayerGroupRevenues}><ReportSportPlayerGroupRevenues /></FluxAction>
        <FluxAction action={ActionTypes.BettingOperationalReport}><ReportBettingOperational /></FluxAction>
        {/* Sport Betting */}
        <FluxAction action={ActionTypes.SportBettingMarginReports}><ReportSportBettingMargins /></FluxAction>
        <FluxAction action={ActionTypes.SportCategoryRevenueSummary}><ReportCategoriesSportBetting /></FluxAction>
        <FluxAction action={ActionTypes.SportRegionRevenueSummary}><ReportRegionsSportBetting /></FluxAction>
        <FluxAction action={ActionTypes.SportLeagueReportsSummary}><ReportLeaguesSportBetting /></FluxAction>
        <FluxAction action={ActionTypes.SportEventReportsSummary}><ReportEventsSportBetting /></FluxAction>
        <FluxAction action={ActionTypes.SportMarketReportsSummary}><ReportMarketsSportBetting /></FluxAction>
        <FluxAction action={ActionTypes.SportSelectionReportsSummary}><ReportSelectionsSportBetting /></FluxAction>
        <FluxAction action={ActionTypes.SportPlayerReportsSummary}><ReportPlayersSportBetting /></FluxAction>
        <FluxAction action={ActionTypes.SportBettingTickets}><ReportTicketsSportBetting /></FluxAction>
        <FluxAction action={ActionTypes.SportEventRevenueReport}><ReportEventSummary /></FluxAction>
        <FluxAction action={ActionTypes.SportBettingPlayersWithoutBets}><ReportPlayerWithoutBetsSportBetting /></FluxAction>
        {/* Horse / Greyhound */}
        <FluxAction action={ActionTypes.HorseGreyhoundSummaryReport}><ReportHorseGreyhoundRevenues /></FluxAction>
        <FluxAction action={ActionTypes.HorseGreyHoundRegions}><ReportRegionsHorseGreyhound /></FluxAction>
        <FluxAction action={ActionTypes.HorseGreyHoundVenues}><ReportVenuesHorseGreyhound /></FluxAction>
        <FluxAction action={ActionTypes.HorseGreyHoundDistance}><ReportDistanceHorseGreyhound /></FluxAction>
        <FluxAction action={ActionTypes.HorseGreyHoundPlayers}><ReportPlayersHorseGreyhound /></FluxAction>
        <FluxAction action={ActionTypes.HorseGreyHoundTickets}><ReportTicketsHorseGreyhound /></FluxAction>
        {/* Player Summary */}
        <FluxAction action={ActionTypes.PlayerReports}><ReportPlayerRevenuesSummary /></FluxAction>
        <FluxAction action={ActionTypes.ReportByPlayerGame}><ReportPlayerGamesSummary /></FluxAction>
        {/* Transactions */}
        <FluxAction action={ActionTypes.SearchTransactionsAccountSummary}><ReportAccountSummaryTransactions /></FluxAction>
        <FluxAction action={ActionTypes.ReportByFinancialOperatorTransaction}><ReportProvidersTransactions /></FluxAction>
        <FluxAction action={ActionTypes.ReportByFinancialManualTransaction}><ReportPlayersTransactions /></FluxAction>
        <FluxAction action={ActionTypes.FirstTransactionReport}><ReportFirstTransactions /></FluxAction>
        {/* Retails */}
        <FluxAction action={ActionTypes.RetailAccountSummary}><ReportAccountSummaryRetails /></FluxAction>
        <FluxAction action={ActionTypes.RetailTransactionsReport}><ReportTransactionsRetails /></FluxAction>
        <FluxAction action={ActionTypes.RetailPlayerTransactionsReport}><ReportPlayerTransactionsRetails /></FluxAction>
        {/* Bonus */}
        <FluxAction action={ActionTypes.BonusTransactions}><ReportBonusTransactions /></FluxAction>
        <FluxAction action={ActionTypes.PlayerBonusReports}><ReportBonusPlayers /></FluxAction>
        <FluxAction action={ActionTypes.PlayerBonusSummary}><ReportBonusPlayerSummary /></FluxAction>
        <FluxAction action={ActionTypes.SearchCompletedBonusPayouts}><ReportBonusCompletedPayouts /></FluxAction>
        <FluxAction action={ActionTypes.CompletedBonusPayoutsSummary}><ReportBonusCompletedPayoutsSummary /></FluxAction>
        <FluxAction action={ActionTypes.RemovedBonusReports}><ReportBonusRemovedBonuses /></FluxAction>
        <FluxAction action={ActionTypes.DeductedBonusReports}><ReportDeductedBonusAmountsReport /></FluxAction>
    </FluxPage >;
};
