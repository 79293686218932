/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { API } from '../../../../lib/API/Api';
import { CMSEndpoints, SportEndpoints } from '../../../../lib/API/Endpoints';
import { ActionButton, FluxCard } from '../../../../components/card/FluxCard';
import { Button } from '../../../../components/button/Button';
import { lang } from '../../../../lib/Localization/language';
import { Table } from '../../../../components/table/Table';
import { Icon } from '../../../../components/icon/Icon';
import { FluxTab } from '../../../../components/tab/FluxTab';
import { Modal } from '../../../../components/application/FluxApp';
import { ConfirmationModal } from '../../../../components/modal/ConfirmationModal/ConfirmationModal';
import { ErrorModal } from '../../../../components/modal/ErrorModal/ErrorModal';
import { Popper } from '../../../../components/notification/FluxPopper';
import { FluxInput } from '../../../../components/form/Components/FluxInput';
import { InputTypes } from '../../../../components/form/InputTypes';
import { convertLSportIdToBetradarSportId } from '../../../../lib/Helpers/LsportConversationTools';
import './_setup-market-groups.scss';
import { FluxModal } from '../../../../components/modal/FluxModal';

export const lsMarketGroups = {
    1: [
        { name: 'main', title: 'Main', markets: [1, 2, 3, 4, 5, 7] },
        {
            name: 'total & goals', title: 'Total & Goals', markets: [
                2, 5
            ]
        },
        {
            name: 'half', title: 'Halves', markets: [
                41, 42
            ]
        },
        {
            name: 'score', title: 'Score', markets: [
                6, 9, 16, 17, 22, 23,
                59,
                711, 712, 713,
                714, 715,
                1065, 1066,
                1067, 1068
            ]
        },
        {
            name: 'card & corners', title: 'Cards & Corners', markets: [
                19, 157
            ]
        }
    ],
    2: [
        { name: 'main', title: 'Main', markets: [1, 226, 51] },
        { name: 'total', title: 'Total', markets: [51, 12, 62, 77, 220, 221] },
        { name: 'handicap', title: 'Handicap', markets: [3, 13, 53, 61, 64, 65, 66, 67, 283, 342, 386, 468, 1691, 1692, 1693, 1694] },
        { name: 'period', title: 'Period', markets: [21, 41, 42, 43, 44, 45, 46, 47, 64, 65, 66, 67, 70, 72, 73, 74, 75, 153, 154, 155, 156, 202, 203, 204, 205, 222, 223, 224, 225, 242, 243, 287, 288, 289, 291, 292, 293, 317, 318, 319, 320, 329, 330, 331, 332, 456, 457, 1691, 1692, 1693, 1694] },
        { name: 'points', title: 'Points', markets: [1050, 1069, 1075, 1090, 1092, 1093] },
        { name: 'player', title: 'Player', markets: [1069, 1070, 1071, 1072, 1073, 1074, 1075, 1089, 1091, 1092, 1093] }
    ],
    3: [
        { name: 'main', title: 'Main', markets: [1, 2, 52, 226, 51] }
    ],
    5: [
        { name: 'main', title: 'Main', markets: [52, 2] },
        { name: 'sets', title: 'Sets', markets: [202, 202, 199, 199, 207, 196, 196, 192, 193] },
        { name: 'games', title: 'Games', markets: [189, 190, 191, 198] }
    ],
    23: [
        { name: 'main', title: 'Main', markets: [52, 2] },
        { name: 'sets', title: 'Sets', markets: [202, 202, 202, 202, 202, 310, 309] },
    ],
    default: [
        { name: 'main', title: 'Main', markets: [1, 2, 3, 4, 5, 6, 7, 58, 51, 52, 226] },
    ]
};

export const SetupMarketGroups = () => {
    const [categories, setCategories] = useState(null);
    const [groupName, setGroupName] = useState({
        name: null
    });
    const [selectedGroup, selectGroup] = useState(null);
    const [category, selectCategory] = useState(null);
    const [market, selectMarket] = useState(1);
    const [markets, setMarkets] = useState([]);
    const [selectedMarkets, setSelectedMarkets] = useState(null);
    const [changeOrderTab, toggleChangeOrderTab] = useState(false);
    const [tabButtons, setTabButtons] = useState([
        {
            name: 'all',
            title: 'All'
        },
        {
            name: 'favourites',
            title: 'Favourites'
        },
        {
            name: 'main',
            title: 'Main',
            markets: []
        }
    ]);

    const [marketGroups, setMarketGroups] = useState({ ...lsMarketGroups });

    useEffect(() => {
        API.post(SportEndpoints.Categories, {}, 'please wait loading categories', 'unable to load categories').then((result) => {
            setCategories(result.result.sort((a, b) => a.weight - b.weight));
        });

    }, []);

    useEffect(() => {
        if (!categories) return;

        API.post(CMSEndpoints.GetSportMarketGroups, {}).then((result) => {
            var converted = {};
            try {
                converted = JSON.parse(result.result);
            } catch (err) {
                converted = { ...lsMarketGroups };
            }
            if (!converted) {
                converted = { ...lsMarketGroups };
            }
            //converted = { ...lsMarketGroups };

            setMarketGroups(converted);
            API.post(`${CMSEndpoints.GetSportMarkets}/${window.user.features.feedType}`, {}).then((result) => {
                var csv = result.result;
                var data = csv.split('\n');
                var converted = data.map(x => {
                    var p = x.split(',');
                    //if (p[8] != 'True') return null;
                    return {
                        sport: p[0],
                        sportName: p[1],
                        id: p[2],
                        name: p[3],
                        selections: p[4],
                        inPlaySettlements: p[9] == 'True'
                    };
                });
                converted = converted.filter(x => x != null);
                setMarkets(converted);
                var c = categories.filter(x => x.enabled);
                selectCategory(c[0]);
                selectGroup(c[0]);
            });
        });
    }, [categories]);

    useEffect(() => {
        if (!category) return;
        var categoryId = category.id.toString();
        var sportMarkets = markets.filter(x => {
            return x.sport.toString() == categoryId.toString();
        });
        if (categoryId == 'default') {
            sportMarkets = markets;
        }
        setSelectedMarkets(sportMarkets);
        var copy = [
            {
                name: 'all',
                title: 'All'
            },
            {
                name: 'favourites',
                title: 'Favourites'
            }
        ];
        if (categoryId != 'default') {
            var marketGroupNames = marketGroups[convertLSportIdToBetradarSportId(category.id)];
            if (marketGroupNames) {
                marketGroupNames.map(x => {
                    copy.push({ name: x.name, title: x.title });
                });
            }
        }

        copy.forEach(x => {
            x.title = x.title.charAt(0).toUpperCase()
                + x.title.slice(1);
        });
        if (!copy.find(x => x.name == 'main')) {
            copy.push({ name: 'main', title: 'Main', markets: [1, 2, 3, 4, 5, 6, 7, 58, 51, 52, 226] });
        }
        setTabButtons(copy);
    }, [category]);

    const save = () => {
        API.post(CMSEndpoints.SaveSportMarketGroups, marketGroups, 'please wait', 'unable to save market groups!').then(() => {
            Popper.pop('information', 'Market grups saved!');
        });
    };

    var newGroupName = null;
    var newGroupTitle = null;

    const addNew = (group) => {
        newGroupName = group?.name ?? null;
        newGroupTitle = group?.title ?? null;
        Modal.open(<ConfirmationModal title={'Group Name'} onConfirm={() => {
            var errors = [];
            if (!group && newGroupName.toLowerCase() == 'all') {
                errors.push('Invalid group name, please do not use the following names, All, Favourites');
            }
            if (!group && newGroupName.toLowerCase() == 'favourites') {
                errors.push('Invalid group name, please do not use the following names, All, Favourites');
            }
            var copy = [...tabButtons];
            if (!group && copy.find(x => x.name.toLowerCase() == newGroupName)) {
                errors.push('Invalid group name, please do not use the following names, All, Favourites');
            }


            if (errors.length) {
                Modal.open(<ErrorModal title='Invalid parameters'>
                    <div className='flex padding'>
                        {errors.map(y => {
                            return <div className='padding'>{y}</div>;
                        })}
                    </div>
                </ErrorModal>);
                return;
            }

            var sportMarketsCopy = { ...marketGroups };
            var convertedId = convertLSportIdToBetradarSportId(category.id);
            var sportMarkets = marketGroups[convertedId] ?? [{ name: 'main', title: 'Main', markets: [1, 52, 226] }];
            var marketGroup = sportMarkets.find(x => x.name.toLowerCase() == selectedGroup.name.toLowerCase());

            if (group) {
                var e = copy.find(x => x.name.toLowerCase() == group.name);
                e.title = newGroupTitle;
                marketGroup.title = newGroupTitle;
            } else {
                copy.push({
                    name: newGroupName,
                    title: newGroupTitle
                });
                sportMarkets.push({
                    name: newGroupName.toLowerCase(),
                    title: newGroupTitle,
                    markets: []
                })
            }
            setTabButtons(copy);
            setMarketGroups(sportMarketsCopy);
        }}>
            {!group && <flex className=' vertical gap-10'>
                <flex className='vertical gap-5'>
                    <label className='medium'>{lang('Group Name')}</label>
                    <input onChange={(e) => {
                        var copy = { ...groupName };
                        copy.name = e.target.value;
                        newGroupName = e.target.value;
                        setGroupName(copy);
                    }} defaultValue={group?.name} />
                </flex>
            </flex>
            }
            <flex className='vertical gap-10'>
                <flex className='vertical'>
                    <label className='medium'>{lang('Group Title')}</label>
                    <input onChange={(e) => {
                        var copy = { ...groupName };
                        copy.title = e.target.value;
                        newGroupTitle = e.target.value;
                        setGroupName(copy);
                    }} defaultValue={group?.title} />
                </flex>
            </flex>
        </ConfirmationModal>);
    };

    const deleteGroup = () => {
        Modal.open(<ConfirmationModal title='Please confirm' onConfirm={() => {
            var copy = [...tabButtons];
            copy = copy.filter(x => x.name != selectedGroup.name);
            var sportMarketsCopy = { ...marketGroups };
            var convertedId = convertLSportIdToBetradarSportId(category.id);
            sportMarketsCopy[convertedId] = sportMarketsCopy[convertedId].filter(x => x.name.toLowerCase() != selectedGroup.name.toLowerCase());
            selectGroup(copy[0]);
            setTabButtons(copy);
            setMarketGroups(sportMarketsCopy);
        }}>
            <p>{lang('Do you want to delete this group?')}</p>
        </ConfirmationModal>)
    };

    const renderGroup = () => {
        if (!marketGroups) return;
        if (!category) return;
        var convertedId = convertLSportIdToBetradarSportId(category.id);
        if (!marketGroups[convertedId]) return;
        var sportMarkets = marketGroups[convertedId];
        var data = sportMarkets.find(x => x.name.toLowerCase() == selectedGroup.name.toLowerCase())?.markets ?? [];

        if (selectedGroup.name == 'all') {
            sportMarkets.map(x => {
                if (x.markets && x.markets.length > 0) {
                    data = data.concat(x.markets);
                }
            });
            selectedMarkets.map(x => {
                const marketId = parseInt(x.id);
                if (!data.find(x => x == marketId)) {
                    data.push(marketId);
                }
            })
        }

        var marketList = markets.filter(x => {
            return x.sport.toString() == category.id.toString();
        });

        if (data) {
            var groupModel = {
                fields: [
                    {
                        name: 'id',
                        title: 'Id',
                        render: (val, row) => {
                            return row;
                        }
                    },
                    {
                        name: 'marketName',
                        title: 'Name',
                        render: (val, row) => {
                            var market = marketList.find(x => x.id == row);
                            return <div>{market?.name}</div>;
                        }
                    },
                    {
                        name: 'selections',
                        title: 'Selections',
                        render: (val, row) => {
                            var market = marketList.find(x => x.id == row);
                            return <div>{market?.selections}</div>;
                        }
                    },
                    {
                        name: 'inplay',
                        title: 'In Play Settlements',
                        render: (val, row) => {
                            var market = marketList.find(x => x.id == row);
                            return <div>{market?.inPlaySettlements == true ? <Icon icon='check' /> : <Icon icon='times' />}</div>;
                        }
                    }
                ]
            };

            if (selectedGroup.name == 'all') {
                data = data.filter((item, index) => data.indexOf(item) === index);
            }

            return <flex className='gap-20 vertical'>
                <Table
                    className={'fx-borders fx-shadow'}
                    model={groupModel} key={data} data={data}
                    options={{
                        draggable: {
                            enabled: selectedGroup.name !== 'all'
                        }
                    }}
                    buttons={(row) => {
                        return <Button className='warning' title='delete' onClick={() => {
                            var copy = { ...marketGroups };
                            if (selectedGroup.name == 'all') {
                                copy[convertedId].map(x => {
                                    x.markets = x.markets.filter(y => y != row);
                                });
                            } else {
                                copy[convertedId].find(x => x.name.toLowerCase() == selectedGroup.name.toLowerCase()).markets = copy[convertedId].find(x => x.name.toLowerCase() == selectedGroup.name.toLowerCase()).markets.filter(x => x != row);
                            }
                            setMarketGroups(copy);
                        }} />;
                    }}

                    onDataChange={(row, index, value) => {
                        //setTopLeagues(Object.assign([], value));
                        var copy = { ...marketGroups };
                        copy[convertedId].find(x => x.name.toLowerCase() == selectedGroup.name.toLowerCase()).markets = value;
                        setMarketGroups(copy);
                    }}

                    onDataChange2={(data) => {
                        var copy = { ...marketGroups };
                        copy[convertedId].find(x => x.name.toLowerCase() == selectedGroup.name.toLowerCase()).markets = data;
                        setMarketGroups(copy);
                    }} />
            </flex>;
        }

    };

    const addMarket = () => {
        if (!market) return;
        var copy = { ...marketGroups };
        var convertedId = convertLSportIdToBetradarSportId(category.id);
        if (!copy.hasOwnProperty(convertedId)) {
            copy[convertedId] = [
                { name: 'main', title: 'Main', markets: [1, 2, 3, 4, 5, 6, 7, 58, 51, 52, 226] }
            ];
            tabButtons.map(x => {
                if (x.name == 'all') return;
                if (x.name == 'favourites') return;
                copy[convertedId].find(y => y.name.toLowerCase() == x.name.toLowerCase()).markets = [];
            });
        }
        var value = parseInt(market);
        if (copy[convertedId].find(x => x.name.toLowerCase() == selectedGroup.name.toLowerCase()).markets.find(x => x == value)) {
            return;
        }

        copy[convertedId].find(x => x.name.toLowerCase() == selectedGroup.name.toLowerCase()).markets.push(value);
        setMarketGroups(copy);
    };

    const changeOrder = () => {
        toggleChangeOrderTab(!changeOrderTab);
    };

    if (!categories) return <div></div>;
    if (!category) return <div />;
    if (!selectedGroup) return <div />;

    const getCategoriesDic = () => {
        const res = {};
        for (let i = 0; i < categories.length; i++) {
            const element = categories[i];
            res[element.id] = element.name;
        }
        return res;
    }

    const getMarketDic = () => {
        const res = {};
        for (let i = 0; i < selectedMarkets.length; i++) {
            const element = selectedMarkets[i];
            res[element.id] = `${element.id} | ${element.name}`
        }
        return res;
    }
    return <flex className={'vertical'}>
        {changeOrderTab && <FluxCard title={'Change Orders'}
            buttons={<Button className='align-right' title='Return' onClick={() => changeOrder()} />}>
            <Table
                className={'fx-borders fx-shadow'}
                model={{
                    fields: [
                        {
                            name: 'title',
                            title: 'Name'
                        }
                    ]
                }}
                data={tabButtons.filter(x => {
                    if (x.name == 'all') return false;
                    if (x.name == 'favourites') return false;
                    return true;
                })}
                key={tabButtons}
                options={{
                    draggable: {
                        enabled: true
                    }
                }}
                onDataChange={(row, index, data) => {
                    var a = [{
                        name: 'all',
                        title: 'All'
                    },
                    {
                        name: 'favourites',
                        title: 'Favourites'
                    }];
                    a = a.concat(data);
                    setTabButtons(a);
                    var sportMarketGroupsCopy = { ...marketGroups };
                    var convertedId = convertLSportIdToBetradarSportId(category.id);
                    if (!sportMarketGroupsCopy[convertedId]) return;
                    var copy = [];
                    data.map(x => {
                        copy.push(sportMarketGroupsCopy[convertedId].find(y => y.name == x.name));
                    });

                    sportMarketGroupsCopy[convertedId] = copy;
                    setMarketGroups(sportMarketGroupsCopy);
                }} />
        </FluxCard>}
        <flex className={'vertical gap-10 setup-market-groups' + (changeOrderTab ? ' hidden' : '')}>
            <FluxCard title='Change Market Groups' buttons={<Button className='success align-right' title='Save' onClick={() => save()} />}>
                <flex className='vertical gap-10'>
                    <flex className='gap-20 category-select'>
                        <label className='medium'>{lang('Sports')}</label>
                        <FluxInput
                            data={{}}
                            field={{
                                name: 'SportsCategory',
                                title: '.',
                                type: InputTypes.Select,
                                required: true,
                                value: [category.id],
                                values: getCategoriesDic()
                            }}
                            onChange={(a, b) => {
                                selectCategory(categories.find(x => x.id == b));
                            }}>
                        </FluxInput>
                    </flex>
                    <div className='flex align-right hidden'>
                        <Button title='Change Default' onClick={() => {
                            selectCategory({ id: 'default', name: 'default' });
                        }} />
                    </div>
                </flex>
            </FluxCard >
            <FluxTab buttons={tabButtons}
                className='gap-10 fx-shadow fx-radius rounded-buttons'
                action={() => {
                    return <flex className='align-right gap-5'>
                        <ActionButton title='Add new' icon='plus' onClick={() => {
                            addNew();
                        }} />
                        <ActionButton title='Change Orders' icon='bring-forward' onClick={() => {
                            changeOrder();
                        }} />
                    </flex>
                }}
                onChange={(index) => {
                    selectGroup(tabButtons[index]);
                }} />
            <FluxCard>
                <flex className='vertical gap-10'>
                    <div className='flex gap-20 fit'>
                        <div className='flex gap-20 align-items-center'>
                            <div className='flex gap-5'>
                                {selectedGroup.name != 'all' && selectedGroup.name != 'favourites' && <Button onClick={() => {
                                    setGroupName(selectedGroup);
                                    addNew(selectedGroup);
                                }}>
                                    <span className='no-wrap'>{lang('Change group title')}</span>
                                </Button>}
                            </div>
                            {selectedGroup.name != 'all' && selectedGroup.name != 'favourites' &&
                                <div className='flex gap-5 stretch'>
                                    <flex className='gap-20 market-select'>
                                        <span>{lang('Market')}</span>
                                        <FluxInput
                                            data={{}}
                                            field={{
                                                name: 'Markets',
                                                title: '.',
                                                type: InputTypes.Select,
                                                required: true,
                                                value: [market],
                                                values: getMarketDic()
                                            }}
                                            onChange={(a, b) => {
                                                selectMarket(b);
                                            }}>
                                        </FluxInput>
                                    </flex>
                                    <Button className='success' onClick={() => addMarket()}><span className='no-wrap'>{lang('Add Market')}</span></Button>
                                </div>
                            }
                        </div>

                        {
                            selectedGroup.name != 'all' && selectedGroup.name != 'favourites' && <div className='flex gap-5 align-right'><Button className='align-right warning' onClick={() => deleteGroup()} >
                                <span className='no-wrap'>{lang('Delete Group')}</span>
                            </Button>
                            </div>
                        }
                    </div>
                </flex>
            </FluxCard>
            <FluxCard title='Markets'>
                {renderGroup()}
            </FluxCard>
        </flex >
    </flex>;
};
