import React from 'react';
import { addUser, changeUserPassword, editUser } from '../../actions/UserActions';
import { Button } from '../../components/button/Button';
import { FluxCard } from '../../components/card/FluxCard';
import { InputTypes } from '../../components/form/InputTypes';
import { FluxSearch } from '../../components/search/FluxSearch';
import { Tip } from '../../components/tip/Tip';
import { ActionTypes } from '../../lib/ActionManager/FluxActions';
import { AuthenticationEndpoints, ManagementEndpoints } from '../../lib/API/Endpoints';
import { UserGroups } from '../../lib/Enums/UserEnums';
import { API } from '../../lib/API/Api';
import { Modal } from '../../components/application/FluxApp';
import { ConfirmationModal } from '../../components/modal/ConfirmationModal/ConfirmationModal';
import { FluxNotice } from '../../components/notification/FluxNotice';
import { NotificationType } from '../../components/notification/FluxNotification';
import { Popper } from '../../components/notification/FluxPopper';


const Manage2FA = (props) => {
    const { user } = { ...props };
    return <flex className='vertical gap-10'>
        {user.AuthType == 1 ? <FluxNotice type={NotificationType.Warning} title={'User has 2FA Enabled!'} description='Please click YES if you want to disable 2FA for this user!' /> :
            <FluxNotice type={NotificationType.Information} title={'User does not have 2FA Enabled!'} description='Please click YES to close this window!' />}
    </flex>;
};

export const MyUsers = () => {
    const model = {
        fields: [
            {
                name: 'Username',
                display: 'Username'
            },
            {
                name: 'Group',
                display: 'User Group',
                type: InputTypes.Select,
                multiple: true,
                exact: true,
                values: UserGroups,
                required: true,
                value: [2, 3, 4, 5, 6]
            },
            {
                name: 'Email',
                type: InputTypes.Text,
            },
            {
                name: 'Name',
                type: InputTypes.Text
            },
            {
                name: 'Surname',
                type: InputTypes.Text
            },
            {
                name: 'Phone',
                type: InputTypes.Text
            },
            {
                name: 'Enabled',
                type: InputTypes.Bool,
                value: true
            }
        ]
    };

    const resultModel = {
        fields: [
            {
                name: 'Id'
            },
            {
                name: 'UserGroupId',
                title: 'User Group',
                render: (val) => UserGroups[val]
            },
            {
                name: 'Username',
                display: 'Username'
            },
            {
                name: 'Email',
                type: InputTypes.Text
            },
            {
                name: 'Name',
                type: InputTypes.Text
            },
            {
                name: 'Surname',
                type: InputTypes.Text
            },
            {
                name: 'AuthType',
                title: 'Authentication',
                render: (val) => {
                    switch (val) {
                        case 1:
                            return '2FA Enabled';
                    }
                }
            },
            {
                name: 'Phone',
            },
            {
                name: 'Enabled',
                type: InputTypes.Bool
            }
        ]
    };

    const manageUser2FA = (user) => {
        Modal.open(<ConfirmationModal title='Manage 2FA' onConfirm={() => {
            if (user.AuthType == 0) {
                return;
            }
            if (user.AuthType == 1) {
                API.post(AuthenticationEndpoints.Disable2FA(user.Id)).then(() => {
                    Popper.pop('information', 'Saved', 'Operator user 2FA removed!');
                });
            }
        }}>
            <Manage2FA user={user} />
        </ConfirmationModal>);
    };

    const onActionClicked = (action, user) => {
        switch (action.type) {
            case ActionTypes.EditMyUser:
                editUser(user.Id, (result, data) => {
                    user.Enabled = data.Enabled;
                });
                break;
            case ActionTypes.ChangeMyUserPassword:
                changeUserPassword(user);
                break;
            case ActionTypes.Manage2FA:
                manageUser2FA(user);
                break;
            default:
                console.log(action.type);
                break;
        }
    };

    return <flex className='vertical gap-10'>
        <FluxCard>
            <flex className='gap-20'>
                <Button className='align-left success' title='Add new' onClick={() => addUser(() => {

                })} />
                <Tip className='align-right' tip='Create Users and assign roles using group types.' />
            </flex>
        </FluxCard>
        <FluxSearch model={model} resultModel={resultModel} endpoint={ManagementEndpoints.SearchUser} searchOnStart actions={ActionTypes.SearchMyUsers} onActionClicked={onActionClicked.bind(this)} />
    </flex>;
};