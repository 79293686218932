import React from 'react';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { InputTypes } from '../../../components/form/InputTypes';
import { InventoryEndpoints } from '../../../lib/API/Endpoints';




export const PlayerInventory = (props) => {
    const { player } = { ...props };
    var model = {
        fields: [
            {
                name: 'PlayerId',
                display: 'PlayerId',
                value: player.id,
                type: InputTypes.Hidden
            },
            {
                name: 'Status',
                type: InputTypes.Select,
                values: {
                    0: 'Not used',
                    1: 'Used'
                }
            }
        ]
    };

    const resultModel = {
        fields: [
            {
                name: 'Id'
            },
            {
                name: 'EntryDate',
                ttle: 'Entry Date',
                type: InputTypes.DateTime
            },
            {
                name: 'ConsumedDate',
                ttle: 'Consumed Date',
                type: InputTypes.DateTime
            },

            {
                name: 'Name',
            },

            {
                name: 'Source',
            },
            /*
            {
                name: 'TriggeredBy',
                title: 'Triggered By'
            },
            */

            {
                name: 'Currency',
            },

            {
                name: 'Amount',
            },
            {
                name: 'Status',
                type: InputTypes.Select,
                values: {
                    0: 'Not used',
                    1: 'Used'
                }
            }
        ]
    };

    return <FluxSearch model={model} resultModel={resultModel} endpoint={InventoryEndpoints.GetPlayerInventory} searchOnStart />;
};
