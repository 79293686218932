import React, { useEffect, useState } from 'react';
import { Icon } from '../../../components/icon/Icon';
import { Button } from '../../../components/button/Button';
import { FluxCard } from '../../../components/card/FluxCard';
import { FluxForm } from '../../../components/form/FluxForm';
import { InputTypes } from '../../../components/form/InputTypes';
import { Popper } from '../../../components/notification/FluxPopper';
import { Table } from '../../../components/table/Table';
import { Tip } from '../../../components/tip/Tip';
import { API } from '../../../lib/API/Api';
import { AffiliateEndpoints } from '../../../lib/API/Endpoints';
import { getHistory } from '../../../../routers/AppRouterFlux';


const groupModel = {
    fields: [
        {
            name: 'Id'
        },
        {
            name: 'Name',
            title: 'Name'
        },
        {
            name: 'Description',
            title: 'Description'
        },
        {
            name: 'Type',
            title: 'Type',
            render: (val) => {
                if (val == 0) return 'GGR';
                return null;
            }
        },
        {
            name: 'Commission',
            title: 'Commission',
            render: (val) => {
                if (!val) return;
                return `%${val}`;
            }
        },
        {
            name: 'ExcludeBonus',
            title: 'Exclude Bonus',
            type: InputTypes.Bool
        },
        {
            name: 'ExcludePaymentGatewayComissions',
            title: 'Exclude Payment Gateway Commisions',
            type: InputTypes.Bool
        }
    ]
};

const newGroup = {
    sections: {
        'General': {
            icon: <Icon icon='address-card' type='fa-light' />,
            fields: [
                {
                    name: 'Name',
                    required: true
                },
                {
                    name: 'Description',
                    type: InputTypes.Textarea,
                    required: true
                }
            ]
        },
        'Agreement': {
            icon: <Icon icon='handshake-simple' type='fa-light' />,
            fields: [
                {
                    name: 'Type',
                    title: 'Commission Type',
                    type: InputTypes.Select,
                    values: {
                        0: 'GGR',
                        1: 'Deposit'
                    },
                    required: true
                },
                {
                    name: 'Commission',
                    type: InputTypes.Number,
                    required: true
                },
                {
                    name: 'ExcludeBonus',
                    title: 'Exclude Bonus',
                    type: InputTypes.Bool
                },
                {
                    name: 'ExcludePaymentGatewayComissions',
                    title: 'Exclude Payment Gateway Commissions',
                    type: InputTypes.Bool
                },
                {
                    name: 'Enabled',
                    title: 'Enabled',
                    type: InputTypes.Bool
                }
            ]
        }

    }
};

export const AffiliateGroups = () => {
    const [location, setLocation] = useState(null);
    const [edit, setEdit] = useState(undefined);
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        loadGroups();
    }, []);

    useEffect(() => {
        if (edit === undefined) return;
        if (edit && edit.Id) {
            getHistory().push(`/affiliates/groups/?&id=${edit.Id}`);
        } else {
            getHistory().push('/affiliates/groups/');
        }
    }, [edit]);

    useEffect(() => {
        if (!groups) return;
        if (!groups.length) return;
        if (location !== window.location.search) {
            setLocation(window.location.search);
        }
    });

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = parseFloat(urlParams.get('id'));

        var group = groups.find(x => x.Id == id);
        if (group) {
            setEdit(group);
        }
    }, [location])

    const loadGroups = () => {
        API.post(AffiliateEndpoints.ListGroups).then((result) => {
            setGroups(result.result);
            setLocation(window.location.search);
        });
    };

    if (edit != null) {
        return <FluxCard title='Add new group'>
            <FluxForm model={newGroup} endpoint={AffiliateEndpoints.SaveGroup} confirm confirmMessage={edit ? 'Do you want to update Affiliate Group information?' : 'Do you want to create new Affiliate Group?'}
                onSubmitComplete={() => {
                    Popper.pop('information', !edit ? 'New Affiliate Group created.' : 'Affiliated Group updated.');
                    setEdit(null);
                    loadGroups();
                }}
                onCancel={() => setEdit(null)} data={edit ?? null} />
        </FluxCard>;
    }
    return <flex className='vertical gap-10'>
        <FluxCard>
            <flex className='gap-20'>
                <Button className='align-left success' title='Add new' onClick={() => setEdit(0)} />
                <Tip className='align-right' tip='Groups are used for configuring different commission models between affilliates.' />
            </flex>
        </FluxCard>
        <FluxCard title='Groups' className={'no-padding'}>
            <Table model={groupModel} data={groups} buttons={(row) => <Button title='Edit' onClick={() => setEdit(row)} />} />
        </FluxCard>
    </flex>;
};
