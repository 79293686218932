import React, { useEffect, useState } from 'react';
import { FluxCard } from '../../../../components/card/FluxCard';
import { API } from '../../../../lib/API/Api';
import { DataEndpoints } from '../../../../lib/API/Endpoints';
import { FluxNotice } from '../../../../components/notification/FluxNotice';
import { NotificationType } from '../../../../components/notification/FluxNotification';
import ReactSelect from 'react-select';


export const PromotionGroupSettings = (props) => {
    const { promotion, onChange } = { ...props };
    const [configuration, setConfiguration] = useState(null);
    const [groups, setGroups] = useState(null);

    useEffect(() => {
        API.post(DataEndpoints.Groups).then((result) => {
            setGroups(result.result.sort((a, b) => a.Retail == b.Retail ? a.Name > b.Name : a.Retail ? 1 : -1));

            var c = { ...promotion.Configuration };

            if (!c.Groups) {
                c.Groups = {
                    Enabled: {},
                    Disabled: {}
                };
            }

            setConfiguration(c);
        });
    }, []);


    useEffect(() => {
        if (!configuration) return;

        var copy = { ...promotion };
        copy.Configuration = configuration;
        onChange(copy);
    }, [configuration]);


    const getOptions = (target) => {
        var res = [];
        Object.values(configuration.Groups[target]).map(x => {
            var g = groups.find(y => y.Id == parseInt(x));
            if (g) {
                res.push({ label: g.Name, value: g.Id, data: g });
            }
        });


        return res;
    };


    var options = [];

    groups?.map(x => {
        options.push({ label: x.Name, value: x.Id, data: x });
    });


    if (!configuration) return <></>;
    if (!groups) return <></>;

    var enabledGroups = getOptions('Enabled');
    var disabledGroups = getOptions('Disabled');


    return <flex className='vertical gap-10'>
        <FluxCard title={'Enabled groups'} className={'no-overflow'}>
            <flex className='vertical gap-10'>
                <FluxNotice type={NotificationType.Information} title='If no group enabled specifically all groups are enabled by default' />
                <ReactSelect
                    className='react-select'
                    defaultValue={enabledGroups}
                    options={options}
                    onChange={(e) => {
                        var newElements = {};
                        e.map(x => {
                            newElements[x.value] = x.value;
                        });
                        var copy = { ...configuration };
                        copy.Groups.Enabled = newElements;
                        setConfiguration(copy);
                    }}
                    name={'enabled-groups'}
                    hideSelectedOptions={true}
                    closeMenuOnSelect={true}
                    isClearable={true}
                    isMulti={true}
                    isSearchable={true}
                />
            </flex>
        </FluxCard>
        <FluxCard title={'Disabled groups'} className={'no-overflow'}>
            <flex className='vertical gap-10'>
                <FluxNotice type={NotificationType.Information} title='If no group disabled specifically all groups are enabled by default' />
                <ReactSelect
                    className='react-select'
                    defaultValue={disabledGroups}
                    options={options}
                    onChange={(e) => {
                        var newElements = {};
                        e.map(x => {
                            newElements[x.value] = x.value;
                        });
                        var copy = { ...configuration };
                        copy.Groups.Disabled = newElements;
                        setConfiguration(copy);
                    }}
                    name={'enabled-groups'}
                    hideSelectedOptions={true}
                    closeMenuOnSelect={true}
                    isClearable={true}
                    isMulti={true}
                    isSearchable={true}
                />
            </flex>
        </FluxCard>
    </flex>;
};