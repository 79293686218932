import './_form-modal.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FluxModal } from '../FluxModal';
import { Button } from '../../button/Button';
import { Modal } from '../../application/FluxApp';
import { FluxForm } from '../../form/FluxForm';

export const FormModal = (props) => {
    const [updatedData, setData] = useState(props.data);
    const close = (e) => {
        if (e.code == 'Escape') Modal.close();
    };

    useEffect(() => {
        window.addEventListener('keyup', close);
        return () => window.removeEventListener('keyup', close);
    }, []);

    return <FluxModal title={props.title} className='form-modal' footer={<buttons>
        <Button title={props.confirmTitle ?? 'Save'} className='success' onClick={() => {
            props.onConfirm(updatedData);
            Modal.close();
        }} />
        <Button title='Cancel' onClick={() => {
            if (props.onCancel) props.onCancel();
            Modal.close();
        }} />
    </buttons>}>
        <FluxForm model={props.model} data={props.data} buttons={null} onChange={(data) => {
            setData(data);
        }} />
        {props.children}
    </FluxModal>;
};

FormModal.propTypes = {
    title: PropTypes.string,
    data: PropTypes.object,
    model: PropTypes.object,
    children: PropTypes.node,
    footer: PropTypes.node,
    confirmTitle: PropTypes.string,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
};
