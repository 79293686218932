import React from 'react';
import { FluxCard } from '../../../../components/card/FluxCard';
import { SliderConfigurator } from '../Components/Slider/SliderConfigurator';

export const RacingPageConfigurator = (props) => {
    const { page, save } = { ...props };

    const updateSlider = (configuration) => {
        var copy = { ...page };
        copy.configuration.slider = configuration;
        save(copy);
    };


    return <FluxCard title='Racing Page Slider' className={'overflow-hidden'}>
        <SliderConfigurator configuration={page.configuration?.slider ?? {}} {...props} onUpdate={(configuration) => updateSlider(configuration)} />
    </FluxCard>;
};