class CtrlKeyManager {
    constructor() {
        this.isCtrlPressed = false;
        this.listenersAdded = false;
        this.init(); 
    }

    init() {
        if (!this.listenersAdded) {
            window.addEventListener('keydown', this.handleKeyDown.bind(this));
            window.addEventListener('keyup', this.handleKeyUp.bind(this));
            this.listenersAdded = true;
        }
    }

    handleKeyDown(event) {
        if (event.key === 'Control') {
            this.isCtrlPressed = true;
        }
    }

    handleKeyUp(event) {
        if (event.key === 'Control') {
            this.isCtrlPressed = false;
        }
    }

    getCtrlKeyStatus() {
        return this.isCtrlPressed;
    }
}

const ctrlKeyManager = new CtrlKeyManager();
export default ctrlKeyManager;