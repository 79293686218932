import './_random-number-input.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isNullOrEmpty } from '../../../lib/Helpers/StringHelper';
import { Icon } from '../../icon/Icon';
import { Button } from '../../button/Button';
import { lang } from '../../../lib/Localization/language';



// Function to generate OTP
function generateOTP(size, suffix) {

    // Declare a digits variable
    // which stores all digits 
    let digits = '0123456789';
    let OTP = '';
    for (let i = 0; i < size; i++) {
        OTP += digits[Math.floor(Math.random() * 10)];
    }
    return `${OTP}${suffix}`;
}

export const RandomNumberInput = (props) => {
    const [value, setValue] = useState(null);
    const field = props.field;

    const inputRef = React.createRef();


    const [focused, setFocused] = useState(false);

    useEffect(() => {
        if (props.data && props.data[field.name]) {
            setValue(props.data[field.name]);
            return;
        } else if (field.value) {
            setValue(field.value);
        }

        if (isNullOrEmpty(props.field.value)) setValue(props.field.value);
    }, [props.data]);


    if (field.readonly) {
        return <input autoComplete='off' type='text' value={value} readOnly />;
    }


    return <flex className='gap-10 random-number-input'>
        <input ref={inputRef} autoComplete='off' className='flux-input' type={field.type} defaultValue={value}
            onFocus={(e) => {
                setFocused(true);
                var value = e.target.value;
                if (props.field.suffix !== undefined) {
                    value = value.split(props.field.suffix);
                    e.target.value = value[0];
                }
            }}
            onBlur={(e) => {
                setFocused(false);
                if (props.field.suffix !== undefined) {
                    e.target.value += props.field.suffix;
                }
                props.onChange(field, inputRef.current.value);
            }}
            onKeyUp={field.onKeyUp ? (e) => field.onKeyUp(e) : null} />
        {!focused &&
            <Button icon='pi' onClick={() => {
                inputRef.current.value = generateOTP(props.field.size, props.field.suffix);
                props.onChange(field, inputRef.current.value);
            }} >
                <flex className='gap-5'>
                    <Icon icon='pi' type='fa-light' /><span>{lang('Generate')}</span>
                </flex>
            </Button>
        }
        {focused &&
            <Button title='Save Code' className='success' onClick={() => {
                console.log(inputRef.current.value);
                props.onChange(field, inputRef.current.value);
            }} />
        }
    </flex>;

};

RandomNumberInput.propTypes = {
    field: PropTypes.object,
    focused: PropTypes.bool,
    data: PropTypes.object,
    onChange: PropTypes.func
};
