import React from 'react';
import { AffiliateEndpoints, API } from '../../v2/Lib/Api/Api';
import { lang } from '../lib/Localization/language';
import { Modal } from '../components/application/FluxApp';
import { FluxForm } from '../components/form/FluxForm';
import { InputTypes } from '../components/form/InputTypes';
import { ConfirmationModal } from '../components/modal/ConfirmationModal/ConfirmationModal';
import { ErrorModal } from '../components/modal/ErrorModal/ErrorModal';
import { FluxModal } from '../components/modal/FluxModal';
import { FluxNotice } from '../components/notification/FluxNotice';
import { Popper } from '../components/notification/FluxPopper';
import { currency } from '../components/currency/Currency';


export const removePlayerFromAffiliate = (player, handler) => {
    Modal.open(<ConfirmationModal title='Remove player from affiliate' onConfirm={() => {
        API.post(`${AffiliateEndpoints.RemovePlayer}/${player.Id}/${player.AffiliateId}`, null).then((result) => {
            Modal.close();
            Popper.pop('information', 'Player removed from affiliate.');
            if (handler) {
                handler(result.result);
            }
        }).catch((error) => {
            Modal.open(<ErrorModal title='Error'><p>{lang(error?.error?.message ?? 'API Error')}</p></ErrorModal>)
        });
    }}>

        <flex className='vertical gal-10'>
            {
                player.Balance > 0 && <FluxNotice type='warning' title='Player account is not empty' description={lang('%1 will be withdrawn from player balance.', [currency(player.Balance, 2, player.Currency), player.Affiliate])} />
            }
            {player.Balance == 0 && <span>{lang('Please confirm removing player from affiliate %1.', [player.Affiliate])}</span>}
        </flex>
    </ConfirmationModal>);
};



export const movePlayerToAffiliate = (player, handler) => {
    Modal.open(<FluxModal title='Move Player to Affiliate'>
        <div className='padding'>
            {
                player.Balance > 0 && <FluxNotice type='warning' title='Player account is not empty' description={lang('%1 will be withdrawn from player balance.', [currency(player.Balance, 2, player.Currency), player.Affiliate])} />
            }
            <FluxForm
                onSubmit={(data) => {
                    API.post(`${AffiliateEndpoints.MovePlayer}/${player.Id}/${data.AffiliateId}`, null).then((result) => {
                        Modal.close();
                        Popper.pop('information', 'Player moved.');
                        if (handler) {
                            handler(result.result);
                        }
                    }).catch((error) => {
                        Modal.open(<ErrorModal title='Error'><p>{lang(error?.error?.message ?? 'API Error')}</p></ErrorModal>)
                    });
                }}
                onCancel={() => Modal.close()}
                model={{
                    fields: [
                        {
                            name: 'AffiliateId',
                            title: 'Affiliates',
                            type: InputTypes.Select,
                            data: {
                                source: 'myaffiliates',
                                key: 'Id',
                                title: ['Name']
                            }
                        }
                    ]
                }} />
        </div>
    </FluxModal>);
};
