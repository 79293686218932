import './_page.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../icon/Icon';
import { FluxPageFooter } from './FluxPageFooter';
import { lang } from '../../lib/Localization/language';
import { useDispatch, useSelector } from 'react-redux';
import { applicationMenu } from '../../lib/ActionManager/FluxActions';
import { LocalStorage } from '../../lib/Helpers/LocalStorage/LocalStorageHelper';


export const FluxPage = (props) => {
    const [location, setLocation] = useState(null);
    const busy = useSelector((state) => state.data.busy);

    const [action, setAction] = useState(null);
    const [activeAction, setActiveAction] = useState(null);
    const [title, setTitle] = useState(null);

    const favouriteActions = useSelector((state) => state.flux).settings?.favouriteActions;

    const dispatch = useDispatch();
    useEffect(() => {
        setLocation(window.location.pathname);
    }, []);

    useEffect(() => {
        if (location != window.location.pathname) {
            setLocation(window.location.pathname);
            return;
        }
    });

    useEffect(() => {
        if (!location) return;
        var current = null;
        var titles = [];
        var locationData = window.location.pathname.split('/');
        locationData.map(x => {
            if (x != '') {
                if (!current) {
                    current = applicationMenu.children.find(y => y.route == x);
                } else {
                    if (current?.children) {
                        current = current.children.find(y => y.route == x);
                    }
                }

                if (current) {
                    titles.push(current.title);
                }
            }
        });
        var res = titles.map((x, index) => {
            return <React.Fragment key={x}>
                <span key={x}>{lang(x)}</span>
                {index < titles.length - 1 && <Icon icon='chevron-right' size='xs' />}
            </React.Fragment>;
        });


        setTitle(res);
        setActiveAction(current);
        setAction(applicationMenu.children.find(x => x.type == props.action));
    }, [location]);

    const addToFavourites = () => {
        if (!activeAction) return;
        var storage = LocalStorage.get();

        var route = window.location.pathname + window.location.search;
        if (storage.settings.favouriteActions[route]) {
            delete storage.settings.favouriteActions[route];
        } else {
            storage.settings.favouriteActions[route] = {
                title: activeAction.title,
                icon: activeAction.icon
            };
        }

        LocalStorage.save(storage);
        dispatch({ type: 'FAVOURITE_ACTION_ADDED', payload: route });
    };

    if (!action) return <></>;
    var route = window.location.pathname + window.location.search;

    return <div className='flux-page'>
        <header>
            <div className='page-icon'>
                <Icon icon={action.icon} size='1x' type='fa-light' />
            </div>
            <div className='page-title'>
                {title}
            </div>
            <div className='add-to-fav' onClick={() => addToFavourites()}>
                {favouriteActions && favouriteActions[route] ? <Icon icon='star-sharp' type='fa-solid' /> : <Icon icon='star' type='fa-light' />}
                {favouriteActions && favouriteActions[route] ? <label className='medium' >{lang('Remove from favourites')}</label> : <label className='medium'>{lang('Add to favourites')}</label>}
            </div>
        </header>
        <div className={`content${busy ? ' busy' : ''}`}>
            {props.children}
        </div>
        {props.footer && <FluxPageFooter>{props.footer}</FluxPageFooter>}
    </div >;
};

FluxPage.propTypes = {
    action: PropTypes.object,
    children: PropTypes.node,
    footer: PropTypes.node
};