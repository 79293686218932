import React from 'react';
import { ActionTypes } from '../../lib/ActionManager/FluxActions';
import { FluxAction } from '../../components/application/FluxAction';
import { FluxPage } from '../../components/page/FluxPage';
import { MyUsers } from './MyUsers';
import { MyUserActivities } from './MyUserActivities';
import { ManageUserRights } from './ManageUserRights/ManageUserRights';

export const AdministrationPage = () => {
    return <FluxPage action={ActionTypes.Management}>
        <FluxAction action={ActionTypes.SearchMyUsers}><MyUsers /></FluxAction>
        <FluxAction action={ActionTypes.ManageUserRights}><ManageUserRights/></FluxAction>
        <FluxAction action={ActionTypes.SearchMyUserActivities}><MyUserActivities /></FluxAction>
    </FluxPage >;
};
