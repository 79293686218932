/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { Modal } from '../../../../components/application/FluxApp';
import { Button } from '../../../../components/button/Button';
import { FluxCard } from '../../../../components/card/FluxCard';
import { InputTypes } from '../../../../components/form/InputTypes';
import { ConfirmationModal } from '../../../../components/modal/ConfirmationModal/ConfirmationModal';
import { FluxNotice } from '../../../../components/notification/FluxNotice';
import { NotificationType } from '../../../../components/notification/FluxNotification';
import { Popper } from '../../../../components/notification/FluxPopper';
import { ToggleButton } from '../../../../components/toggleButton/ToggleButton';
import { API } from '../../../../lib/API/Api';
import { FixtureEndpoints, SportEndpoints } from '../../../../lib/API/Endpoints';
import { Table } from '../../../../../flux/components/table/Table';

export const SetupCategories = () => {
    const [categories, setCategories] = useState(null);
    const [weights, setWeights] = useState(null);


    useEffect(() => {
        API.post(FixtureEndpoints.SetupSportCategories).then((weightResult) => {
            var w = {};
            try {
                w = JSON.parse(weightResult.result);
            } catch (err) {
                //
            }

            API.post(SportEndpoints.Categories).then((result) => {

                var copy = [...result.result];
                copy.map((x, index) => {
                    if (w.hasOwnProperty(x.id)) {
                        x.weight = w[x.id].weight;
                        x.enabled = w[x.id].enabled;
                    } else {
                        x.enabled = true;
                        x.weight = copy.length - index;
                    }
                });

                var sorted = Object.assign([], copy).sort((a, b) => { return b.weight - a.weight; });


                setCategories(sorted);
                setWeights(w);
            });

        });

    }, []);



    const sortNames = () => {
        var copy = [...categories];
        copy = copy.sort((a, b) => {
            var textA = a.name.toUpperCase();
            var textB = b.name.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        copy.key = Date.now();
        setCategories(copy);
    };

    const sortTypes = () => {
        var copy = [...categories];
        copy = copy.sort((a, b) => {
            return a.id - b.id;
        });
        copy.key = Date.now();
        setCategories(copy);
    };


    const save = () => {
        var copy = {};
        categories.map((x, index) => {
            x.weight = categories.length - index;
            x.enabled = weights[x.id] ? weights[x.id].enabled : x.enabled;
            copy[x.id] = x;
        });

        Modal.open(<ConfirmationModal title='Confirm operation' onConfirm={() => {
            API.post(FixtureEndpoints.SaveSetupSportCategories, copy).then(() => {
                Popper.pop(NotificationType.Information, 'Fixture Saved!', 'Categories saved, all changes are effective.');
            });
        }}>
            <FluxNotice type={NotificationType.Information} title='Please confirm save!' description={'The order of categories and enabled properties will be effective active save.'} />
        </ConfirmationModal>);
    };

    if (!weights) return <div></div>;
    if (!categories) return <div></div>;

    return <FluxCard title='Manage Categories' buttons={
        <flex className='align-right gap-5'>
            <Button title='Sort Types' onClick={() => sortTypes()} />
            <Button title='Sort Names' onClick={() => sortNames()} />
            <Button title='Save' className={'success'} onClick={() => save()} />
        </flex>
    }>
        <Table
            className={'fx-borders fx-shadow'}
            data={categories}
            context={{
                toggleEnabled: (row, value) => {
                    var copy = { ...weights };
                    if (!copy[row.id]) {
                        copy[row.id] = row;
                    }
                    copy[row.id].enabled = value;
                    setWeights(copy);
                }
            }}
            model={{
                fields: [
                    {
                        name: 'id',
                        title: 'Id'
                    },
                    {
                        name: 'name',
                        title: 'Name'
                    },
                    {
                        name: 'enabled',
                        title: 'Enabled',
                        type: InputTypes.Bool,
                        render: (val, row, field, context) => {
                            return <ToggleButton key={`${row.id}_${field.name}`} value={row.enabled} onChange={(value) => {
                                if (row.enabled === value) return;
                                context.toggleEnabled(row, value);
                            }} />;
                        }
                    }
                ]
            }}
            options={{
                data: {
                    delete: {
                        enabled: false,
                        header: false
                    }
                },
                draggable: {
                    enabled: true
                }
            }}
            onSortEnd={(o, n, v) => {
                var copy = [...v];
                copy.map((x, i) => {
                    x.weight = copy.length - i;
                });
                setCategories(copy);
            }}
        />
    </FluxCard>;
};