/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { lang } from '../../../../../lib/Localization/language';
import { Button } from '../../../../../components/button/Button';


export const CurrencyMinMaxRow = (props) => {
    const { currency, data, updateStakeSettings, deleteCurrencySetting } = { ...props };
    const [minAmount, setMinAmount] = useState((data && data[currency.CurrencyCode] && data[currency.CurrencyCode][0] ? data[currency.CurrencyCode][0].Min : 0) ?? 0);
    const [maxAmount, setMaxAmount] = useState((data && data[currency.CurrencyCode] && data[currency.CurrencyCode][0] ? data[currency.CurrencyCode][0].Max : 0) ?? 0);


    const updateMinAmount = (e) => {
        setMinAmount(e.target.value);
    };


    const updateMaxAmount = (e) => {
        setMaxAmount(e.target.value);
    };

    useEffect(() => {
        updateStakeSettings(currency.CurrencyCode, {
            Min: minAmount,
            Max: maxAmount
        });
    }, [minAmount]);

    useEffect(() => {
        updateStakeSettings(currency.CurrencyCode, {
            Min: minAmount,
            Max: maxAmount
        });
    }, [maxAmount]);


    if (!data) return <></>;
    return <flex className='gap-20 border radius fx-shaddow columns-3 fit-children' key={currency}>
        <flex className='vertical padding'>
            <label className='medium'>{currency.CurrencyCode}</label>
            <span>{currency.Name}</span>
        </flex>
        <flex className='padding fx-border-left justify-content-center'>
            <flex className='vertical'>
                <label className='medium'>{lang('Min')}</label>
                <input name={currency.CurrencyCode} defaultValue={data[currency.CurrencyCode] ? data[currency.CurrencyCode][0].Min : 0}
                    onFocus={(e) => {
                        e.target.value = '';
                    }}
                    onBlur={(e) => {
                        if (e.target.value === '') e.target.value = minAmount;
                    }}
                    value={minAmount} className='center' onChange={(e) => {
                        updateMinAmount(e);
                    }} />
            </flex>
        </flex>
        <flex className='padding fx-border-right justify-content-center'>
            <flex className='vertical'>
                <label className='medium'>{lang('Max')}</label>
                <input name={currency.CurrencyCode} defaultValue={data[currency.CurrencyCode] ? data[currency.CurrencyCode][0].Max : 0}
                    onFocus={(e) => {
                        e.target.value = '';
                    }}
                    onBlur={(e) => {
                        if (e.target.value === '') e.target.value = maxAmount;
                    }}
                    value={maxAmount} className='center' onChange={(e) => {
                        updateMaxAmount(e);
                    }} />
            </flex>
        </flex>
        <flex className='padding align-right justify-content-center'>
            <Button title='Delete' className={'warning'} onClick={() => {
                setMinAmount(0);
                setMaxAmount(0);
                deleteCurrencySetting(currency.CurrencyCode);
            }} />
        </flex>
    </flex>;
};