import { API } from "../../../lib/API/Api";

export const fieldDataParser = (field, onReady) => {
    API.post(field.data.source.indexOf('/') == 0 ? field.data.source : `/api/data/${field.data.source}`, field.data.payload ?? {}, null, null, false, null, true).then((result) => {
        if (field.data.filter) {
            result.result = result.result.filter(x => field.data.filter(x));
        }
        if (field.data.sort) {
            result.result = result.result.sort((a, b) => field.data.sort(a, b));
        }
        if (field.data.onParseComplete) {
            field.data.onParseComplete(result.result);
        }
        if (field.data.parser) {
            onReady(field.data.parser(result));
            return;
        }
        var dataValues = [];
        result.result.map(x => {
            var title = [];
            if (field.data.customTitle) {
                title.push(field.data.customTitle(x));
            } else {
                field.data.title.map(y => {
                    title.push(x[y]);
                });
            }
            dataValues.push({ value: x[field.data.key], title: title.join(' ') });
        });
        onReady(dataValues);
    });
};