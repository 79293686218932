// IMPORT PACKAGES
import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import { ContextMenuManager } from './ContextMenu/ContextMenu';

// IMPORT STORE


// IMPORT COMPONENTS

import { AppRouterFlux } from '../routers/AppRouterFlux';

//const AppRouterFlux = null;

export const App = ({ history, store }) => {
    return <Provider store={store}>
        <div className="container">
            <AppRouterFlux history={history} />
        </div>
    </Provider>;
};

new ContextMenuManager();

App.propTypes = {
    history: PropTypes.object,
    store: PropTypes.object
};

