import './_form-input.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { InputTypes } from '../InputTypes';
import { DefaultInput } from './DefaultInput';
import { SelectInput } from './SelectInput';
import { BoolInput } from './BoolInput';
import { DateTimeInput } from './DateTimeInput';
import { MultipleSelectInput } from './MultipleSelectInput';
import { TreeInput } from './TreeInput';
import { RandomNumberInput } from './RandomNumberInput';

export const FluxInput = (props) => {
    const field = props.field;
    switch (field.type) {
        case InputTypes.Select:
            return <SelectInput {...props} />;
        case InputTypes.Bool:
            return <BoolInput {...props} />;
        case InputTypes.Date:
        case InputTypes.DateTime:
            return <DateTimeInput {...props} />;
        case InputTypes.MultipleSelect:
            return <MultipleSelectInput {...props} />;
        case InputTypes.Tree:
            return <TreeInput {...props} />;
        case InputTypes.Custom:
            return field.component;
        case InputTypes.RandomNumber:
            return <RandomNumberInput {...props} />;
        case InputTypes.Text:
        default:
            return <DefaultInput {...props} />;
    }
};

FluxInput.propTypes = {
    field: PropTypes.object,
    focused: PropTypes.bool,
    data: PropTypes.object,
    onChange: PropTypes.func
};
