import React from 'react';
import PropTypes from 'prop-types';

import { FluxSearch } from '../../../components/search/FluxSearch';
import { InputTypes } from '../../../components/form/InputTypes';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { SportsTicketStatus, HorseTicketTypes } from '../../../lib/Enums/BettingEnums';
import { ModelHorseTicketResult } from '../../../lib/Models/TicketModels';
import { Icon } from '../../../components/icon/Icon';
import { lang } from '../../../lib/Localization/language';
import { useSelector } from 'react-redux';
import { HorsePendingTickets, TicketEndpoints } from '../../../lib/API/Endpoints';
import { ActionTypes } from '../../../lib/ActionManager/FluxActions';
import { confirmPendingTicket, rejectPendingTicket } from '../../../actions/PendingTicketActions';

const model = {
    fields: [
        {
            name: 'OperatorId',
            title: 'Operator',
            type: InputTypes.Select,
            exact: true,
            nullable: true,
            data: {
                source: 'operators',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'EntryDate',
            title: 'Date',
            type: InputTypes.DateTime,
            between: true,
            value: {
                start: DateHelpers.yesterday(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'ExternalTicketId',
            title: 'Ticket No',
            type: InputTypes.Number
        },
        {
            name: 'EventId',
            title: 'Event Id'
        },
        {
            name: 'Group',
            title: 'Player Group',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'myPlayerGroups',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'PlayerId',
            title: 'Player Id',
            type: InputTypes.Number
        },
        {
            name: 'Username',
            exact: false
        },
        {
            name: 'TicketType',
            title: 'Ticket Type',
            type: InputTypes.Select,
            values: HorseTicketTypes,
            nullable: true
        },
        {
            name: 'Status',
            title: 'Ticket Status',
            type: InputTypes.Select,
            values: SportsTicketStatus,
            nullable: true
        },
        {
            name: 'PromotionId',
            title: 'Promotion',
            type: InputTypes.Select,
            nullable: true,
            multiple: true,
            data: {
                source: 'promotion',
                key: 'Id',
                title: ['Name']
            }
        }
    ]
};


export const SearchPlayerHorseGreyhoundHistory = (props) => {
    const user = useSelector((state) => state.user.loginInformation);
    const singleOperator = user.UserPrivileges.Operators.length == 1;

    const title = () => {
        if (props.retail) {
            return <React.Fragment><span>{lang('Retail')}</span><Icon icon='chevron-right' type='fa-light' /><span>{props.retail.Name}</span><Icon icon='chevron-right' type='fa-light' /><span>{lang('Search in Player Bet History')}</span></React.Fragment>;
        }
        if (props.affiliate) {
            return <React.Fragment><span>{lang('Affiliate')}</span><Icon icon='chevron-right' type='fa-light' /><span>{props.affiliate.Name}</span><Icon icon='chevron-right' type='fa-light' /><span>{lang('Search in Player Bet History')}</span></React.Fragment>;
        }
        return 'Search in Player Bet History';
    };


    const onActionClicked = (action, ticket, selection) => {
        var data = selection.tableContext.getData();
        var ticketId = ticket.Id;
        var operatorId = ticket.OperatorId;

        switch (action.type) {
            case ActionTypes.ConfirmTransaction:
                confirmPendingTicket(HorsePendingTickets.confirm, operatorId, ticketId, ticket.Win, () => {
                    data = data.filter(x => x.Id != ticketId);
                    selection.tableContext.setData(data);
                });
                break;
            case ActionTypes.RejectTransaction:
                rejectPendingTicket(HorsePendingTickets.reject, operatorId, ticketId, ticket.Win, () => {
                    data = data.filter(x => x.ExternalTicketId != ticketId);
                    selection.tableContext.setData(data);
                });
                break;
            default:
                console.log(action.type);
                break;
        }
    };

    return <FluxSearch
        {...props}
        title={title()}
        model={model}
        resultModel={ModelHorseTicketResult}
        actions={props.pending ? ActionTypes.HorseGreyHoundPendingTickets : null}
        endpoint={props.resolved ? TicketEndpoints.SearchHorseTicketsResolved : TicketEndpoints.SearchHorseTickets}
        searchOnStart
        onActionClicked={onActionClicked.bind(this)}
        onPrepareModel={(model) => {
            var copy = { ...model };
            copy.name = 'player-bet-history-operator';
            copy.fields = copy.fields.filter(x => x.name != 'PostponePayment');

            if (props.pending) {
                var statusField = copy.fields.find(x => x.name == 'Status');
                statusField.hidden = true;
                statusField.value = 1;
                statusField.type = 'number';

                copy.name = 'player-bet-history-operator-postponed';
                copy.fields.push({
                    name: 'PostponePayment',
                    title: 'Investigated',
                    readonly: true,
                    type: 'hidden',
                    value: true
                });

                return copy;
            }

            if (user.UserPrivileges.AvailableOperators.length == 1) {
                copy.fields.find(x => x.name == 'OperatorId').hidden = true;
            }
            if (props.retail) {
                copy.name = 'player-bet-history-of-retail';
                copy.fields.push({ name: 'RetailId', value: props.retail.Id, exact: true, and: true, hidden: true });
                return copy;
            }

            if (!props.playerId) return model;
            var playerField = copy.fields.find(x => x.name == 'PlayerId');
            playerField.hidden = true;
            playerField.value = props.playerId;
            copy.fields.find(x => x.name == 'Username').hidden = true;
            copy.fields.find(x => x.name == 'Group').hidden = true;

            copy.name = 'player-bet-history';
            if (props.resolved) {
                copy.fields = copy.fields.filter(x => x.name != 'Status');
            }
            return copy;
        }}
        onPrepareResultModel={(copy) => {
            if (singleOperator) {
                copy.fields.find(x => x.name == 'Operator').hidden = true;
            }

            if (props.affiliate || props.retail) {
                copy.name = 'search-player-bet-history-opeartor-' + (props.retail ?? props.affiliate);
                copy.fields.find(x => x.name == 'RetailName').hidden = true;
                copy.fields.find(x => x.name == 'Group').hidden = true;
                copy.fields.find(x => x.name == 'Operator').hidden = true;
            }

            if (!props.playerId) return copy;
            copy.name = 'search-player-bet-history-opeartor-' + props.playerId;
            copy.fields.find(x => x.name == 'PlayerId').hidden = true;
            copy.fields.find(x => x.name == 'Username').hidden = true;
            copy.fields.find(x => x.name == 'RetailName').hidden = true;
            copy.fields.find(x => x.name == 'Group').hidden = true;
            copy.fields.find(x => x.name == 'Operator').hidden = true;
            return copy;
        }}
    />;
};


SearchPlayerHorseGreyhoundHistory.propTypes = {
    playerId: PropTypes.number,
    resolved: PropTypes.bool,
    affiliate: PropTypes.object,
    retail: PropTypes.object,
    pending: PropTypes.bool
};