import React from 'react';
import { FluxTab } from '../../../../../components/tab/FluxTab';
import { PromotionGeneralSettings } from '../PromotionGeneralSettings';
import { PromotionGroupSettings } from '../PromotionGroupSettings';
import { PromotionCodeSettings } from '../PromotionCodeSettings';
import { RefillSettings } from '../RefillSettings';



export const RefillBonusMoney = (props) => {
    var buttons = [
        {
            title: 'General Settings'
        },
        {
            title: 'Group Settings'
        },
        {
            title: 'Code'
        },
        {
            title: 'Re-Fill Amounts'
        }
    ];

    return <FluxTab buttons={buttons} className='gap-10 fx-shadow fx-radius rounded-buttons campaign-settings'>
        <PromotionGeneralSettings {...props} />
        <PromotionGroupSettings {...props} />
        <PromotionCodeSettings {...props} />
        <RefillSettings  {...props} />
    </FluxTab>;
};