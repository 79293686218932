import React from 'react';
import PropTypes from 'prop-types';
import { FluxInput } from '../../form/Components/FluxInput';
import { InputTypes } from '../../form/InputTypes';

export const EditableColumn = (props) => {
    const field = props.field;
    const value = props.row[field.name];

    return (
        <td className={`flux-table-body-column column-editable column-${field.name.toLowerCase()}`}>
            <FluxInput type={field.type} value={value} onChange={(f, value) => {
                if (!props.onChange) return;
                if (field.type == InputTypes.Number) value = parseFloat(value);
                if (isNaN(value)) return;
                props.onChange(field, props.row, value);
            }} />
        </td>
    );
};


EditableColumn.propTypes = {
    field: PropTypes.object,
    row: PropTypes.object,
    onChange: PropTypes.func
};