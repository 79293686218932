import './_campaign-settings.scss';

import React, { useState } from 'react';
import { FluxCard } from '../../../../../components/card/FluxCard';
import { lang } from '../../../../../lib/Localization/language';
import { Button } from '../../../../../components/button/Button';
import { CashCampaignSettings } from './Components/CashCampaignSettings';



const CampaignPayoutTypes = {
    'cash': 'cash',
    'wager': 'wager',
    'freebet': 'freebet',
    'freespin': 'freespin'
};

const Cards = [
    {
        type: CampaignPayoutTypes.cash,
        title: 'Cash Bonus',
        icon: 'fa-circle-dollar fa-2x',
        description: 'Set up cash bonuses for players upon deposit and play. Define the bonus amount and eligibility criteria to reward players with immediate funds.'
    },
    {
        type: CampaignPayoutTypes.wager,
        title: 'Wager bonus',
        icon: 'fa-dice fa-2x',
        description: 'Configure bonuses for players who meet specific wagering requirements. Specify bonus amounts and conditions to encourage higher betting activity.'
    },
    {
        type: CampaignPayoutTypes.freebet,
        title: 'Free bet',
        icon: 'fa-ticket fa-2x',
        description: 'Provide free bet credits to players upon signing up or depositing. Define the amount and eligible sports events to attract and engage players.'
    },
    {
        type: CampaignPayoutTypes.freespin,
        title: 'Free spin',
        icon: 'fa-slot-machine fa-2x',
        description: 'Offer free spins on selected slot games for deposits or play. Set the number of free spins and requirements to enhance the gaming experience.'
    }
];

export const CampaignTypeSettings = () => {
    const [type, selectType] = useState(CampaignPayoutTypes.wager);
    return <flex className='vertical gap-10'>
        <FluxCard>
            <flex className='vertical gap-20'>
                <h4>{lang('Please select a campaign type')}</h4>
                <flex className='gap-20 columns-4'>
                    {Cards.map(x => {
                        return <FluxCard title={x.title} key={x.title} buttons={<i className={`align-right fa-solid ${x.icon} fa-2x`} />} className={type == x.type ? 'selected-payout-type campaign-type-card' : 'campaign-type-card'} footer={
                            type != x.type ? <Button title='Select' className={'success center'} onClick={() => selectType(x.type)} /> : <i className='fa-solid fa-check-circle fa-2x center' />
                        }>
                            <p>{lang(x.description)}</p>
                        </FluxCard>;
                    })}
                </flex>
            </flex>
        </FluxCard>
        {type == CampaignPayoutTypes.cash && <CashCampaignSettings />}
    </flex>;
};