import './_game-configuration.scss';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FluxCard } from '../../../components/card/FluxCard';
import { FluxList } from '../../../components/list/FluxList';
import { API } from '../../../lib/API/Api';
import { CMSEndpoints } from '../../../lib/API/Endpoints';
import { Button } from '../../../components/button/Button';
import { lang } from '../../../lib/Localization/language';
import { Icon } from '../../../components/icon/Icon';
import { ToggleButton } from '../../../components/toggleButton/ToggleButton';
import { Modal } from '../../../components/application/FluxApp';
import { ConfirmationModal } from '../../../components/modal/ConfirmationModal/ConfirmationModal';
import { FluxNotice } from '../../../components/notification/FluxNotice';
import { CasinoImageComposer } from './Components/CasinoImageComposer';
import { appHistory } from '../../../..';



const tagTitles = {
    'editor': 'Editor\'s Pick'
};


const defaultTags = 'slot,live,roulette,baccarat,blackjack,table game,jackpot,lobby,virtual sport,free spin,buy free spin,poker,crash,skill,editor';

const SortingType = {
    Weight: 1,
    Name: 2
};

const TagEditor = (props) => {
    const { game, onConfirm } = { ...props };

    const [gameTags, setTags] = useState(null);

    useEffect(() => {
        var tags = {};

        defaultTags.split(',').map(x => {
            tags[x] = false;
        });

        if (!game.tag) {
            game.tag = '';
        }

        var copy = game.tag.split(',');

        if (game.tag != '') {
            copy.map(x => {
                tags[x] = true;
            });
        }

        setTags(tags);
    }, [])


    if (!gameTags) return <></>;

    return <ConfirmationModal title='Edit Tags' onConfirm={() => onConfirm(gameTags)}>
        <flex className='vertical gap-10'>
            {Object.keys(gameTags).sort((a, b) => a > b ? 1 : -1).map(x => {
                return <flex className='gap-10 clickable' key={x} onClick={() => {
                    var copy = { ...gameTags };
                    copy[x] = !copy[x];
                    setTags(copy);
                }}>
                    <i className={`${gameTags[x] ? 'fa-square-check fa-solid' : 'fa-square fa-light'}`} />
                    <span className='lowercase'>{lang(tagTitles[x] ?? x)}</span>
                </flex>;
            })}
        </flex>
    </ConfirmationModal>;
}


export const GameConfigurations = () => {
    const user = useSelector((state) => state.user);
    const [providers, setProviders] = useState(null);
    const [games, setGames] = useState(null);
    const [filtered, setFiltered] = useState(null);
    const [max, setMax] = useState(50);
    const [sortingType, setSortingType] = useState(SortingType.Weight);
    const [provider, selectProvider] = useState(null);

    const [filterNonComposed, setFilterNonComposed] = useState(false);
    const [filterComposed, setFilterComposed] = useState(false);
    const [filterNewGames, setFilterNewGames] = useState(false);
    const [filterPopularGames, setFilterPopularGames] = useState(false);
    const [filterDisabled, setFilterDisabled] = useState(false);
    const [liveOnly, setLiveOnly] = useState(false);

    const [searchQuery, setSearchQuery] = useState(null);

    const [selectedTags, setTags] = useState(null);

    const searchInputRef = React.createRef();

    const editorEnabled = user.loginInformation.OperatorId == 38 || user.loginInformation.OperatorId == 44
        || user.loginInformation.OperatorId == 17 || user.loginInformation.OperatorId == 18;

    /*
    const [editingImage, setEditingImage] = useState({
        "gameId": 27896,
        "providerId": 191249,
        "providerName": "Pragmatic Play",
        "providerGameId": 15791,
        "providerEnabled": true,
        "name": "Gates of Valhalla",
        "providerGameName": "pgp_gatesofvalhalla",
        "type": 7,
        "liveDealer": false,
        "data": null,
        "enabled": true,
        "online": true,
        "hasFreeSpin": true,
        "weight": 90,
        "tag": "rng,slot,video slot",
        "featured": false,
        "new": false,
        "imageName": "27896.jpg"
    });
    */

    const [editingImage, setEditingImage] = useState(null);



    useEffect(() => {
        API.post(CMSEndpoints.GetGames, {}, 'fetching games').then((result) => {
            var p = {};
            result.result.map(x => {
                p[x.providerId] = {
                    title: x.providerName,
                    value: x.providerId,
                    type: x.type,
                    liveDealer: x.liveDealer
                };
            });

            setProviders(p);
            setGames(result.result);
        });
    }, []);

    useEffect(() => {
        if (!providers) return;
        var copy = {};
        defaultTags.split(',').map(x => {
            copy[x] = false;
        });

        setTags(copy);
    }, [providers]);

    useEffect(() => {
        if (!selectedTags) return;
        updateList();
    }, [selectedTags]);

    useEffect(() => {
        if (!games) return;
        updateList();
    }, [games]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const thumbId = parseInt(urlParams.get('thumb'));
        var thumblink = !isNaN(thumbId) ? `&thumb=${thumbId}` : '';

        if (provider == null || provider == undefined) {
            appHistory.push(`?${thumblink}`);
        } else {
            appHistory.push(`?${thumblink}&provider=${provider}`);
        }
        updateList();
    }, [provider]);

    useEffect(() => {
        if (!sortingType) return;
        updateList();
    }, [sortingType]);


    useEffect(() => {
        updateList();
    }, [filterNonComposed]);


    useEffect(() => {
        updateList();
    }, [filterComposed]);


    useEffect(() => {
        updateList();
    }, [filterNewGames]);

    useEffect(() => {
        updateList();
    }, [filterPopularGames]);

    useEffect(() => {
        updateList();
    }, [filterDisabled]);

    useEffect(() => {
        if (searchQuery == undefined) return;
        updateList();
    }, [searchQuery]);


    const toggleEnableDisableGame = (gameData) => {
        Modal.open(<ConfirmationModal title={gameData.enabled ? 'Disable game' : 'Enable game'} onConfirm={() => {
            let copy = { ...filtered };
            copy.games.find(x => x.gameId == gameData.gameId).enabled = !gameData.enabled;

            copy.key = Date.now();

            API.post(CMSEndpoints.SaveGame, gameData, 'saving game').then(() => {
                setFiltered(copy);
            });


        }}>
            <FluxNotice type='information' title={'Please confirm'} description={lang('%1 game will be %2', [gameData.name, gameData.enabled ? 'disabled' : 'enabled'])} />
        </ConfirmationModal>)
    };

    const updateList = () => {
        if (!games) return;
        var list = [...games];
        if (provider) {
            list = list.filter(x => x.providerId == provider);
        }
        if (searchQuery) {
            list = list.filter(x => x.name.toLowerCase().indexOf(searchQuery) >= 0);
        }

        if (filterNewGames && !filterPopularGames) {
            list = list.filter(x => x.new);
        }
        if (!filterNewGames && filterPopularGames) {
            list = list.filter(x => x.featured);
        }
        if (filterNewGames && filterPopularGames) {
            list = list.filter(x => x.new || x.featured);
        }

        if (filterDisabled) {
            list = list.filter(x => !x.enabled);
        }

        if (filterNonComposed) {
            list = list.filter(x => !x.frontendData);
        }

        if (filterComposed) {
            list = list.filter(x => x.frontendData);
        }

        const urlParams = new URLSearchParams(window.location.search);
        const thumbId = parseInt(urlParams.get('thumb'));
        const providerId = parseInt(urlParams.get('provider'));

        if (!isNaN(thumbId)) {
            var img = games.find(x => x.gameId == thumbId);
            if (img) {
                setEditingImage(img);
            }
        }

        if (!isNaN(providerId) && providerId > 0) {
            selectProvider(providers[providerId].value);
        }

        var currentTags = {};
        Object.keys(selectedTags).map(x => {
            if (selectedTags[x]) {
                currentTags[x] = true;
            }
        });

        if (Object.values(currentTags).length > 0 && Object.keys(currentTags).length != defaultTags.split(',').length) {
            list = list.filter((x) => {
                if (!x.tag) return false;
                var copy = x.tag.split(',');
                for (var i = 0; i < copy.length; i++) {
                    var y = copy[i];
                    if (currentTags[y]) {
                        return true;
                    }
                }
                return false;
            });
        }
        switch (sortingType) {
            case SortingType.Weight:
                setFiltered({ games: list.sort((b, a) => a.weight > b.weight ? 1 : -1), key: Date.now() });
                break;
            case SortingType.Name:
                setFiltered({ games: list.sort((a, b) => a.name > b.name ? 1 : -1), key: Date.now() });
                break;
        }
    };

    const saveGame = (game) => {
        API.post(CMSEndpoints.SaveGame, game, 'saving game').then(() => {
        });
    };

    const renderTags = (game) => {
        if (!game.tag) {
            return;
        }

        var tags = game.tag.split(',');
        return <flex className='gap-5 tags'>
            {tags.map(x => {
                return <span key={x} className='lowercase'>{lang(tagTitles[x] ?? x)}</span>
            })}
        </flex>;
    }

    const editTags = (game) => {
        Modal.open(<TagEditor game={game} onConfirm={(newTags) => {
            if (game.tag == newTags) return;
            var copy = [...games];
            try {
                var tags = [];
                Object.keys(newTags).map(x => {
                    if (newTags[x]) {
                        tags.push(x);
                    }
                });

                tags = tags.join(',');
                game.tag = tags;
                copy.find(y => y.gameId == game.gameId).tag = tags;


                API.post(CMSEndpoints.SaveGameTag, game, 'saving game tag').then(() => {
                    setGames(copy);
                });
            } catch (err) {
                console.log(err);
            }
        }} />);
    };

    if (!selectedTags) return <></>;

    return <flex className='vertical gap-10 game-configuration-container'>
        {editingImage && editorEnabled && <CasinoImageComposer game={editingImage} key={editingImage?.gameId} onUpdate={(g) => {
            var copy = [...filtered];
            copy.find(x => x.gameId == editingImage.gameId).frontendData = g.frontendData;
            setFiltered(copy);
        }} onClose={() => setEditingImage(null)} />}

        <flex className='gap-10 overflow-hidden fit-children game-configurations'>

            <FluxCard title='Providers' className={'no-padding provider-list'}>
                <flex className='vertical padding'>
                    <Button title='Live Casino' className={`${liveOnly ? 'success' : ''}`} onClick={() => {
                        setLiveOnly(!liveOnly);
                    }} />
                </flex>
                <hr />
                <FluxList key={providers} data={providers ? Object.values(providers).sort((a, b) => a.title > b.title ? 1 : -1).filter(x => {
                    if (liveOnly) {
                        if (x.type != 6 && x.type != 4 && !x.liveDealer) return false;
                    }
                    return true;
                }) : []} onSelected={(item) => {
                    if (!item) return;
                    selectProvider(item.value);
                }} />
            </FluxCard>
            <FluxCard title='Games' className={'games-container'}
                buttons={<flex className='game-sortings fit'>
                    <flex className='gap-10 center'>
                        <flex className='gap-20 padding-right'>
                            {provider != null && <flex className='gap-5' onClick={() => {
                                selectProvider(null);
                            }}>
                                <span>{providers[provider].title}</span>
                                <Icon icon='times' type='fa-light' />
                            </flex>}
                            <flex className={`sorting-type gap-5 clickable ${sortingType == SortingType.Weight ? 'selected' : ''}`} onClick={() => {
                                setSortingType(SortingType.Weight);
                            }}>
                                <span>{lang('Sort by weight')}</span>
                                <Icon icon='arrow-down-1-9' type='fa-light' />
                            </flex>
                            <flex className={`sorting-type gap-5 clickable ${sortingType == SortingType.Name ? 'selected' : ''}`} onClick={() => {
                                setSortingType(SortingType.Name);
                            }}>
                                <span>{lang('Sort by name')}</span>
                                <Icon icon='arrow-down-a-z' type='fa-light' />
                            </flex>
                        </flex>
                        <flex className='padding-left border-left gap-20'>
                            {editorEnabled && <flex className={`sorting-type gap-5 clickable ${filterNonComposed ? 'selected' : ''}`} >
                                <span>{lang('Show without thumbnails')}</span>
                                <Icon icon='image' type='fa-light' />
                                <ToggleButton value={filterNonComposed} onChange={(value) => {
                                    if (filterNonComposed == value) return;
                                    setFilterNonComposed(value);
                                }} />
                            </flex>
                            }
                            {editorEnabled && <flex className={`sorting-type gap-5 clickable ${filterComposed ? 'selected' : ''}`} >
                                <span>{lang('Show with thumbnails')}</span>
                                <Icon icon='image' type='fa-light' />
                                <ToggleButton value={filterComposed} onChange={(value) => {
                                    if (filterComposed == value) return;
                                    setFilterComposed(value);
                                }} />
                            </flex>
                            }
                            <flex className={`sorting-type gap-5 clickable ${filterNewGames ? 'selected' : ''}`} >
                                <span>{lang('Show new games')}</span>
                                <Icon icon='sparkle' type='fa-light' />
                                <ToggleButton value={filterNewGames} onChange={(value) => {
                                    if (filterNewGames == value) return;
                                    setFilterNewGames(value);
                                }} />
                            </flex>
                            <flex className={`sorting-type gap-5 clickable ${filterPopularGames ? 'selected' : ''}`} >
                                <span>{lang('Show popular')}</span>
                                <Icon icon='heart' type='fa-light' />
                                <ToggleButton value={filterPopularGames} onChange={(value) => {
                                    if (filterPopularGames == value) return;
                                    setFilterPopularGames(value);
                                }} />
                            </flex>
                            <flex className={`sorting-type gap-5 clickable ${filterDisabled ? 'selected' : ''}`} >
                                <span>{lang('Show disabled')}</span>
                                <Icon icon='ban' type='fa-light' />
                                <ToggleButton value={filterDisabled} onChange={(value) => {
                                    if (filterDisabled == value) return;
                                    setFilterDisabled(value);
                                }} />
                            </flex>
                        </flex>
                        <flex className='align-right gap-5'>
                            <input ref={searchInputRef} />
                            {!searchQuery && <Button className='success' title='Search' onClick={() => setSearchQuery(searchInputRef.current.value.toLowerCase())} />}
                            {searchQuery && <Button title='Clear' onClick={() => setSearchQuery(null)} />}
                        </flex>
                    </flex>
                </flex>
                }
                footer={< flex className='center' >
                    <span>{lang('Showing %1 of total %2 games.', [filtered?.games?.length ?? 0, games?.length ?? 0])}</span>
                </flex >}>
                <flex className='vertical gap-10'>
                    <flex className='gap-10 show-tags align-items-center'>
                        <span>{lang('Tags')}</span>
                        <flex className='tags'>
                            {
                                Object.keys(selectedTags).sort((a, b) => a > b ? 1 : -1).map(x => {
                                    return <span className={`tag ${selectedTags[x] ? 'selected' : ''} lowercase`} key={x} onClick={() => {
                                        var copy = { ...selectedTags }
                                        copy[x] = !copy[x];
                                        setTags(copy);
                                    }}>{lang(tagTitles[x] ?? x)}</span>;
                                })
                            }
                        </flex>
                        <Button title='Clear' className={'hidden'} onClick={() => {
                            var copy = { ...selectedTags };
                            Object.keys(copy).map(x => {
                                copy[x] = false;
                            });

                            setTags(copy);
                        }} />
                    </flex>
                    <div className='game-list' key={filtered?.key}>
                        {filtered?.games?.map((gameData, index) => {
                            if (index > max) return;
                            var color = 'transparent';
                            var imageName = `http://d.tentangle.com/game/${gameData.imageName}`;
                            if (gameData.frontendData) {
                                imageName = gameData.frontendData.image;
                                color = gameData.frontendData.color;
                            }
                            return <flex className={`vertical gap-5 game fx-borders fx-shadow fx-radius ${gameData.enabled ? '' : ' game-disabled'}`} key={gameData.gameId}>
                                <header>
                                    <flex className='gap-10'>
                                        <flex className='gap-5'>
                                            <span className=''>#{gameData.gameId}</span>
                                            <span className='bold'>{gameData.name}</span>
                                        </flex>
                                    </flex>
                                </header>
                                <flex className='body'>
                                    <span className='provider-name'>{gameData.providerName}</span>
                                    <flex className='toggle-new-favourite-holder gap-10'>
                                        <flex className='gap-5'>
                                            <Icon icon='hashtag' type={`${gameData.new ? 'fa-solid' : 'fa-light'}`} className={`${gameData.new ? 'selected' : ''}`} onClick={() => {
                                                var copy = [...games];
                                                var g = copy.find(x => x.gameId == gameData.gameId);
                                                g.new = !g.new;
                                                setGames(copy);

                                                saveGame(g);
                                            }} />
                                            <span>{lang('New')}</span>
                                        </flex>
                                        <flex className='gap-5'>
                                            <Icon icon='heart' type={`${gameData.featured ? 'fa-solid' : 'fa-light'}`} className={`${gameData.featured ? 'selected' : ''}`} onClick={() => {
                                                var copy = [...games];
                                                var g = copy.find(x => x.gameId == gameData.gameId);
                                                g.featured = !g.featured;
                                                setGames(copy);

                                                saveGame(g);
                                            }} />
                                            <span>{lang('Popular')}</span>
                                        </flex>
                                    </flex>
                                    <img src={`${imageName}`}
                                        style={{ borderRadius: '6px', border: `3px solid ${color}` }}
                                        onError={(e) => {

                                            if (!e.target.classList.contains('.error-image')) {
                                                e.target.classList.add('.error-image');
                                                e.target.src = `http://d.tentangle.com/game/${gameData.id}.jpg`;
                                            }
                                        }}
                                        onClick={() => {
                                            var card = document.getElementsByClassName('content')[0];
                                            card.scrollTo(0, 0);

                                            const urlParams = new URLSearchParams(window.location.search);
                                            const providerId = parseInt(urlParams.get('provider'));
                                            var providerLnk = (!isNaN(providerId) && providerId > 0) ? `&provider=${providerId}` : '';

                                            appHistory.push(`?${providerLnk}&thumb=${gameData.gameId}`);
                                            setEditingImage(gameData);
                                        }} />
                                </flex>
                                <footer>
                                    <flex className='gap-10 padding-left padding-right align-items-center' onClick={() => editTags(gameData)}>
                                        <i className='fa-light fa-tag' />
                                        {renderTags(gameData)}
                                    </flex>
                                    <flex className='gap-5 padding-left padding-right border-top padding-top'>
                                        <span className='bold'>{lang('Weight')}</span>
                                        <input type='number' defaultValue={gameData.weight} className='game-weight' />
                                        <Button title={'Change'} className='success btn-change-weight' onClick={(e) => {
                                            var input = e.target.parentNode.getElementsByTagName('input')[0];
                                            var value = input.value;
                                            var converted = parseFloat(value);
                                            if (!isNaN(converted)) {
                                                if (gameData.weight == converted) return;
                                                var copy = [...games];
                                                try {
                                                    gameData.weight = converted;
                                                    copy.find(y => y.gameId == gameData.gameId).weight = converted;
                                                    setGames(copy);
                                                    API.post(CMSEndpoints.SaveGameWeight, gameData, 'saving game').then(() => { });
                                                } catch (err) {
                                                    console.log(err);
                                                }
                                            }
                                        }} />
                                    </flex>
                                    <flex className='padding border-top'>
                                        <Button title={gameData.enabled ? 'Disable' : 'Enable'} className={gameData.enabled ? 'warning btn-toggle-enable-disable' : 'success btn-toggle-enable-disable'} onClick={() => {
                                            toggleEnableDisableGame(gameData);
                                        }} />
                                    </flex>
                                </footer>
                            </flex>;
                        })}
                    </div>
                    {
                        games != null && <flex className='center padding'>
                            <Button title='Load More' className={'success'} onClick={() => {
                                setMax(max + 50);
                            }} />
                        </flex>
                    }
                </flex>
            </FluxCard>
        </flex>
    </flex>;
};