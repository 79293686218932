import { InputTypes } from '../../components/form/InputTypes';
import { UserGroups } from '../Enums/UserEnums';

export const NewUserModel = {
    fields: [
        {
            name: 'UserGroupId',
            title: 'User Group',
            type: InputTypes.Select,
            values: UserGroups
        },
        {
            name: 'Username',
            type: InputTypes.Text,
            required: true
        },
        {
            name: 'Email',
            type: InputTypes.Text,
            required: true
        },
        {
            name: 'Name',
            type: InputTypes.Text,
            required: true
        },
        {
            name: 'Surname',
            type: InputTypes.Text,
            required: true
        },
        {
            name: 'Phone',
            type: InputTypes.Text,
            required: false,
            placeholder: '+44123456789'
        },
        {
            name: 'Password',
            type: InputTypes.Password,
            required: true
        },
        {
            name: 'Enabled',
            type: InputTypes.Bool,
            required: true
        }
    ]
};
