/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../components/button/Button';
import { ActionButton, FluxCard } from '../../../components/card/FluxCard';
import { Table } from '../../../components/table/Table';
import { Modal } from '../../../components/application/FluxApp';
import { FluxModal } from '../../../components/modal/FluxModal';
import { SelectGames } from '../../../components/dataControls/SelectGames';


export const RetailDisabledGames = (props) => {
    const [configuration, setConfiguration] = useState(props.configuration);

    const model = {
        fields: [
            {
                name: 'providerName',
                title: 'Provider'
            },
            {
                name: 'name',
                title: 'Game'
            }
        ]
    };

    useEffect(() => {
        props.onUpdate(configuration);
    }, [configuration]);

    const removeGame = (id) => {
        var copy = { ...configuration };
        copy.casino.disabledGames = copy.casino.disabledGames.filter(x => x.id !== id);
        setConfiguration(copy);
    };


    const addDisabledGame = () => {
        var selectedItems = null;
        var selectedGames = {};
        configuration.casino.disabledGames.map(x => {
            selectedGames[x.id] = x;
        });
        Modal.open(<FluxModal title='Select Provider' footer={<flex className='gap-10 center'>
            <Button className='success' title={'Save'} onClick={() => {
                Modal.close();
                var copy = { ...configuration };
                if (Object.values(selectedItems).length == 0) {
                    copy.casino.disabledGames = [];
                } else {
                    Object.values(selectedItems).map(x => {
                        copy.casino.disabledGames.push({
                            name: x.name,
                            id: x.id,
                            providerId: x.providerId,
                            providerName: x.providerName
                        });
                    });
                }
                setConfiguration(copy);
            }} />
            <Button title='Cancel' onClick={() => Modal.close()} />
        </flex>}>
            <SelectGames selectedGames={selectedGames} onChange={(selected) => {
                selectedItems = selected;
            }} />
        </FluxModal>);
    };

    return <FluxCard title='Disabled Games' className={configuration.casino?.enabled ? 'no-padding' : 'disabled no-padding'} buttons={<ActionButton title='Add' icon='plus-square' onClick={() => addDisabledGame(configuration, setConfiguration)} />}>
        <Table model={model} data={configuration.casino.disabledGames} buttons={(row) => {
            return <Button title='Remove' className={'warning'} onClick={() => removeGame(row.id)} />;
        }} />
    </FluxCard>;
};

RetailDisabledGames.propTypes = {
    onUpdate: PropTypes.func,
    configuration: PropTypes.object
};