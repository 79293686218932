/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { FluxCard } from '../../../../components/card/FluxCard';
import { FluxForm } from '../../../../components/form/FluxForm';
import { InputTypes } from '../../../../components/form/InputTypes';
import { PromotionEndpoints } from '../../../../lib/API/Endpoints';

export const FirstTimeDepositCasinoFreespinSettings = (props) => {
    let { promotion, onChange, disabled, disabledReason } = { ...props };
    const [data, setData] = useState(promotion.Configuration.deposit ?? {});

    useEffect(() => {
        if (!data) return;
        promotion.Configuration.deposit = data;
        onChange({ ...promotion });
    }, [data]);

    const renderDisabled = () => {
        return <flex className='vertical gap-10'>
            {disabledReason}
        </flex>;
    }

    return <flex className='vertical gap-10'>
        <FluxCard>
            <FluxForm data={data} model={{
                fields: [
                    {
                        name: 'prizeType',
                        title: 'Prize Type',
                        type: InputTypes.Select,
                        nullable: true,
                        values: {
                            'FreeSpin': 'Give players a free spin directly upon deposit.'
                        }
                    }
                ]
            }} columns={2} buttons={null} onChange={(d) => setData(d)} />
        </FluxCard>
        {disabled && renderDisabled()}
        {!disabled && <FluxCard>
            <FluxForm data={data} model={{
                fields: [
                    {
                        name: 'Campaign',
                        title: 'Campaign',
                        type: InputTypes.Select,
                        required: true,
                        data: {
                            source: PromotionEndpoints.ListCampaigns,
                            key: 'Id',
                            title: ['Name', 'Promotion', 'Provider']
                        }
                    }
                ]
            }} columns={2} buttons={null} onChange={(d) => setData(d)} />
        </FluxCard>}
    </flex>;
};