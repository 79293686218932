import React from 'react';
import { toPascalCase } from '../../components/Centrum/helpers';
import { lang } from '../lib/Localization/language';
import { Modal } from '../components/application/FluxApp';
import { FluxForm } from '../components/form/FluxForm';
import { InputTypes } from '../components/form/InputTypes';
import { FluxModal } from '../components/modal/FluxModal';
import { NotificationType } from '../components/notification/FluxNotification';
import { Popper } from '../components/notification/FluxPopper';
import { API } from '../lib/API/Api';
import { ManagementEndpoints } from '../lib/API/Endpoints';
import { NewUserModel } from '../lib/Models/UserModels';

export const editUser = (id, handler) => {
    API.post(ManagementEndpoints.SearchUser, { fields: [{ name: 'Id', value: id, exact: true, and: true }] }).then((result) => {
        Modal.open(<FluxModal title='Edit User'>
            <FluxForm
                data={toPascalCase(result.result[0])}
                columns={2}
                endpoint={ManagementEndpoints.Update}
                model={NewUserModel}
                onCancel={() => Modal.close()}
                onSubmitComplete={(result, data) => {
                    if (handler) {
                        handler(result, data);
                    }
                    Modal.close();
                    Popper.pop(NotificationType.Information, null, lang('User profile updated.'));
                }}
            />
        </FluxModal>);
    });
};


export const addUser = (handler) => {
    Modal.open(<FluxModal title='Add User'>
        <FluxForm
            columns={2}
            endpoint={ManagementEndpoints.Save}
            model={NewUserModel}
            onCancel={() => Modal.close()}
            onSubmitComplete={(result, data) => {
                if (handler) {
                    handler(result, data);
                }
                Modal.close();
                Popper.pop(NotificationType.Information, null, lang('User profile created.'));
            }}
        />
    </FluxModal>);
};


export const changeUserPassword = (user, handler) => {
    Modal.open(<FluxModal title='Change password'>
        <FluxForm
            data={{ password: '' }}
            endpoint={`${ManagementEndpoints.ChangePassword}/${user.Id}`}
            model={{
                fields: [
                    {
                        name: 'password',
                        title: 'Password',
                        type: InputTypes.Text,
                        required: true
                    }
                ]
            }}
            onCancel={() => Modal.close()}
            onSubmitComplete={(result, data) => {
                if (handler) {
                    handler(result, data);
                }
                Modal.close();
                Popper.pop(NotificationType.Information, null, lang('User password changed.'));
            }}
        />
    </FluxModal>);
};





