import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../components/button/Button';
import { lang } from '../../../lib/Localization/language';
import { FluxForm } from '../../../components/form/FluxForm';
import { Modal } from '../../../components/application/FluxApp';
import { ConfirmationModal } from '../../../components/modal/ConfirmationModal/ConfirmationModal';

import { Table } from '../../../components/table/Table';
import { InputTypes } from '../../../components/form/InputTypes';
import { FluxCard } from '../../../components/card/FluxCard';
import { API } from '../../../lib/API/Api';
import { PlayerEndpoints } from '../../../lib/API/Endpoints';
import './_player-notes.scss';

const model = {
    fields: [
        {
            name: 'notes',
            title: 'Notes',
            type: InputTypes.Text
        }
    ]
};


const playerNotesModel = {
    fields: [
        {
            name: 'entryDate',
            type: InputTypes.DateTime
        },
        {
            name: 'notes',
            title: 'Notes'
        }
    ]
};

export const PlayerNotes = (props) => {
    const [notes, setNotes] = useState(null);

    useEffect(() => {
        loadNotes();
    }, []);

    const loadNotes = () => {
        API.post(`${PlayerEndpoints.Notes}/${props.player.id}`, null, 'please wait', 'unable to load player notes').then((result) => {
            setNotes(result.result);
        });
    };

    if (!notes) return <></>;
    return <FluxCard>
        <Table className={'player-notes-table'}  data={notes} model={playerNotesModel} buttons={(row) => {
            return <div className='flex gap-5'>
                <Button title='Update' className={'success'} onClick={() => {
                    var noteData = row.notes;
                    Modal.open(<ConfirmationModal title='Confirm' onConfirm={() => {
                        API.post(`${PlayerEndpoints.UpdatePlayerNote}/${row.id}`, {
                            notes: noteData
                        }).then(() => {
                            row.notes = noteData;
                            var copy = [...notes];
                            setNotes(copy);
                        });
                    }}>
                        <textarea onChange={(e) => {
                            noteData = e.target.value;
                        }} >{row.notes}</textarea>
                    </ConfirmationModal>);
                }} />
                <Button title='Delete' className={'warning'} onClick={() => {
                    Modal.open(<ConfirmationModal title='Confirm' onConfirm={() => {
                        API.post(`${PlayerEndpoints.RemovePlayerNote}/${row.id}`).then(() => {
                            var copy = notes.filter(x => x.id != row.id);
                            setNotes(copy);
                        });
                    }}>
                        <p>{lang('Do you want to remove this note?')}</p>
                    </ConfirmationModal>);
                }} />
            </div>;
        }} />
        <FluxForm model={model} endpoint={`${PlayerEndpoints.AddPlayerNote}/${props.player.id}`} onSubmitComplete={() => {
            loadNotes();
        }} />
    </FluxCard>;
};

PlayerNotes.propTypes = {
    player: PropTypes.object
};
