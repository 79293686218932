import './_application.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FluxPopper } from '../notification/FluxPopper';


export var Modal = {
    open: () => { },
    close: () => { }
};

export var Overlay = {
    open: () => { },
    close: () => { }
};

export const FluxApp = (props) => {
    const [modal, setModal] = useState(null);
    const [overlay, setOverlay] = useState(null);
    const modalContext = {
        open: (component) => {
            setModal(component);
        },
        close: () => {
            setModal(null);
        }
    };


    const overlayContext = {
        open: (component) => {
            setOverlay(component);
        },
        close: () => {
            setOverlay(null);
        }
    };

    useEffect(() => {
        Modal = modalContext;
        Overlay = overlayContext;
    }, []);
    return <div className='main-switch user flux-app-holder'>
        {props.children}
        {modal && <modals onClick={() => {
            /*
            if (e.target.nodeName != 'MODALS') return;
            Modal.close();
            */
        }}>{modal}</modals>}
        {overlay && <overlays>{overlay}</overlays>}
        <FluxPopper />
    </div>;
};

FluxApp.propTypes = {
    children: PropTypes.node
};