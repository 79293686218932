
/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { Icon } from '../../../icon/Icon';
import { Modal } from '../../../application/FluxApp';
import { API } from '../../../../lib/API/Api';
import { RiskLevelEndpoints } from '../../../../lib/API/Endpoints';
import { ConfirmationModal } from '../../../modal/ConfirmationModal/ConfirmationModal';
import { Button } from '../../../button/Button';
import { lang } from '../../../../lib/Localization/language';
import { FluxCard } from '../../../card/FluxCard';
import { Tip } from '../../../tip/Tip';
import { Table } from '../../../table/Table';
import { ToggleButton } from '../../../toggleButton/ToggleButton';

export const riskLevelLookup = [
    ['Low', 'Low', 'Low', 'Low'],
    ['Low', 'Low', 'Low', 'Medium'],
    ['Low', 'Low', 'Low', 'High'],
    ['Low', 'Low', 'Medium', 'Low'],
    ['Low', 'Low', 'Medium', 'Medium'],
    ['Low', 'Low', 'Medium', 'High'],
    ['Low', 'Low', 'High', 'Low'],
    ['Low', 'Low', 'High', 'Medium'],
    ['Low', 'Low', 'High', 'High'],
    ['Low', 'Low2', 'Low', 'Low'],
    ['Low', 'Low2', 'Low', 'Medium'],
    ['Low', 'Low2', 'Low', 'High'],
    ['Low', 'Low2', 'Medium', 'Low'],
    ['Low', 'Low2', 'Medium', 'Medium'],
    ['Low', 'Low2', 'Medium', 'High'],
    ['Low', 'Low2', 'High', 'Low'],
    ['Low', 'Low2', 'High', 'Medium'],
    ['Low', 'Low2', 'High', 'High'],
    ['Low', 'Medium', 'Low', 'Low'],
    ['Low', 'Medium', 'Low', 'Medium'],
    ['Low', 'Medium', 'Low', 'High'],
    ['Low', 'Medium', 'Medium', 'Low'],
    ['Low', 'Medium', 'Medium', 'Medium'],
    ['Low', 'Medium', 'Medium', 'High'],
    ['Low', 'Medium', 'High', 'Low'],
    ['Low', 'Medium', 'High', 'Medium'],
    ['Low', 'Medium', 'High', 'High'],
    ['Low', 'Medium2', 'Low', 'Low'],
    ['Low', 'Medium2', 'Low', 'Medium'],
    ['Low', 'Medium2', 'Low', 'High'],
    ['Low', 'Medium2', 'Medium', 'Low'],
    ['Low', 'Medium2', 'Medium', 'Medium'],
    ['Low', 'Medium2', 'Medium', 'High'],
    ['Low', 'Medium2', 'High', 'Low'],
    ['Low', 'Medium2', 'High', 'Medium'],
    ['Low', 'Medium2', 'High', 'High'],
    ['Low', 'High', 'Low', 'Low'],
    ['Low', 'High', 'Low', 'Medium'],
    ['Low', 'High', 'Low', 'High'],
    ['Low', 'High', 'Medium', 'Low'],
    ['Low', 'High', 'Medium', 'Medium'],
    ['Low', 'High', 'Medium', 'High'],
    ['Low', 'High', 'High', 'Low'],
    ['Low', 'High', 'High', 'Medium'],
    ['Low', 'High', 'High', 'High'],
    ['Low', 'High2', 'Low', 'Low'],
    ['Low', 'High2', 'Low', 'Medium'],
    ['Low', 'High2', 'Low', 'High'],
    ['Low', 'High2', 'Medium', 'Low'],
    ['Low', 'High2', 'Medium', 'Medium'],
    ['Low', 'High2', 'Medium', 'High'],
    ['Low', 'High2', 'High', 'Low'],
    ['Low', 'High2', 'High', 'Medium'],
    ['Low', 'High2', 'High', 'High'],
    ['Low', 'High3', 'Low', 'Low'],
    ['Low', 'High3', 'Low', 'Medium'],
    ['Low', 'High3', 'Low', 'High'],
    ['Low', 'High3', 'Medium', 'Low'],
    ['Low', 'High3', 'Medium', 'Medium'],
    ['Low', 'High3', 'Medium', 'High'],
    ['Low', 'High3', 'High', 'Low'],
    ['Low', 'High3', 'High', 'Medium'],
    ['Low', 'High3', 'High', 'High'],
    ['Low', 'High4', 'Low', 'Low'],
    ['Low', 'High4', 'Low', 'Medium'],
    ['Low', 'High4', 'Low', 'High'],
    ['Low', 'High4', 'Medium', 'Low'],
    ['Low', 'High4', 'Medium', 'Medium'],
    ['Low', 'High4', 'Medium', 'High'],
    ['Low', 'High4', 'High', 'Low'],
    ['Low', 'High4', 'High', 'Medium'],
    ['Low', 'High4', 'High', 'High'],
    ['Medium', 'Low', 'Low', 'Low'],
    ['Medium', 'Low', 'Low', 'Medium'],
    ['Medium', 'Low', 'Low', 'High'],
    ['Medium', 'Low', 'Medium', 'Low'],
    ['Medium', 'Low', 'Medium', 'Medium'],
    ['Medium', 'Low', 'Medium', 'High'],
    ['Medium', 'Low', 'High', 'Low'],
    ['Medium', 'Low', 'High', 'Medium'],
    ['Medium', 'Low', 'High', 'High'],
    ['Medium', 'Low2', 'Low', 'Low'],
    ['Medium', 'Low2', 'Low', 'Medium'],
    ['Medium', 'Low2', 'Low', 'High'],
    ['Medium', 'Low2', 'Medium', 'Low'],
    ['Medium', 'Low2', 'Medium', 'Medium'],
    ['Medium', 'Low2', 'Medium', 'High'],
    ['Medium', 'Low2', 'High', 'Low'],
    ['Medium', 'Low2', 'High', 'Medium'],
    ['Medium', 'Low2', 'High', 'High'],
    ['Medium', 'Medium', 'Low', 'Low'],
    ['Medium', 'Medium', 'Low', 'Medium'],
    ['Medium', 'Medium', 'Low', 'High'],
    ['Medium', 'Medium', 'Medium', 'Low'],
    ['Medium', 'Medium', 'Medium', 'Medium'],
    ['Medium', 'Medium', 'Medium', 'High'],
    ['Medium', 'Medium', 'High', 'Low'],
    ['Medium', 'Medium', 'High', 'Medium'],
    ['Medium', 'Medium', 'High', 'High'],
    ['Medium', 'Medium2', 'Low', 'Low'],
    ['Medium', 'Medium2', 'Low', 'Medium'],
    ['Medium', 'Medium2', 'Low', 'High'],
    ['Medium', 'Medium2', 'Medium', 'Low'],
    ['Medium', 'Medium2', 'Medium', 'Medium'],
    ['Medium', 'Medium2', 'Medium', 'High'],
    ['Medium', 'Medium2', 'High', 'Low'],
    ['Medium', 'Medium2', 'High', 'Medium'],
    ['Medium', 'Medium2', 'High', 'High'],
    ['Medium', 'High', 'Low', 'Low'],
    ['Medium', 'High', 'Low', 'Medium'],
    ['Medium', 'High', 'Low', 'High'],
    ['Medium', 'High', 'Medium', 'Low'],
    ['Medium', 'High', 'Medium', 'Medium'],
    ['Medium', 'High', 'Medium', 'High'],
    ['Medium', 'High', 'High', 'Low'],
    ['Medium', 'High', 'High', 'Medium'],
    ['Medium', 'High', 'High', 'High'],
    ['Medium', 'High2', 'Low', 'Low'],
    ['Medium', 'High2', 'Low', 'Medium'],
    ['Medium', 'High2', 'Low', 'High'],
    ['Medium', 'High2', 'Medium', 'Low'],
    ['Medium', 'High2', 'Medium', 'Medium'],
    ['Medium', 'High2', 'Medium', 'High'],
    ['Medium', 'High2', 'High', 'Low'],
    ['Medium', 'High2', 'High', 'Medium'],
    ['Medium', 'High2', 'High', 'High'],
    ['Medium', 'High3', 'Low', 'Low'],
    ['Medium', 'High3', 'Low', 'Medium'],
    ['Medium', 'High3', 'Low', 'High'],
    ['Medium', 'High3', 'Medium', 'Low'],
    ['Medium', 'High3', 'Medium', 'Medium'],
    ['Medium', 'High3', 'Medium', 'High'],
    ['Medium', 'High3', 'High', 'Low'],
    ['Medium', 'High3', 'High', 'Medium'],
    ['Medium', 'High3', 'High', 'High'],
    ['Medium', 'High4', 'Low', 'Low'],
    ['Medium', 'High4', 'Low', 'Medium'],
    ['Medium', 'High4', 'Low', 'High'],
    ['Medium', 'High4', 'Medium', 'Low'],
    ['Medium', 'High4', 'Medium', 'Medium'],
    ['Medium', 'High4', 'Medium', 'High'],
    ['Medium', 'High4', 'High', 'Low'],
    ['Medium', 'High4', 'High', 'Medium'],
    ['Medium', 'High4', 'High', 'High'],
    ['High', 'Low', 'Low', 'Low'],
    ['High', 'Low', 'Low', 'Medium'],
    ['High', 'Low', 'Low', 'High'],
    ['High', 'Low', 'Medium', 'Low'],
    ['High', 'Low', 'Medium', 'Medium'],
    ['High', 'Low', 'Medium', 'High'],
    ['High', 'Low', 'High', 'Low'],
    ['High', 'Low', 'High', 'Medium'],
    ['High', 'Low', 'High', 'High'],
    ['High', 'Low2', 'Low', 'Low'],
    ['High', 'Low2', 'Low', 'Medium'],
    ['High', 'Low2', 'Low', 'High'],
    ['High', 'Low2', 'Medium', 'Low'],
    ['High', 'Low2', 'Medium', 'Medium'],
    ['High', 'Low2', 'Medium', 'High'],
    ['High', 'Low2', 'High', 'Low'],
    ['High', 'Low2', 'High', 'Medium'],
    ['High', 'Low2', 'High', 'High'],
    ['High', 'Medium', 'Low', 'Low'],
    ['High', 'Medium', 'Low', 'Medium'],
    ['High', 'Medium', 'Low', 'High'],
    ['High', 'Medium', 'Medium', 'Low'],
    ['High', 'Medium', 'Medium', 'Medium'],
    ['High', 'Medium', 'Medium', 'High'],
    ['High', 'Medium', 'High', 'Low'],
    ['High', 'Medium', 'High', 'Medium'],
    ['High', 'Medium', 'High', 'High'],
    ['High', 'Medium2', 'Low', 'Low'],
    ['High', 'Medium2', 'Low', 'Medium'],
    ['High', 'Medium2', 'Low', 'High'],
    ['High', 'Medium2', 'Medium', 'Low'],
    ['High', 'Medium2', 'Medium', 'Medium'],
    ['High', 'Medium2', 'Medium', 'High'],
    ['High', 'Medium2', 'High', 'Low'],
    ['High', 'Medium2', 'High', 'Medium'],
    ['High', 'Medium2', 'High', 'High'],
    ['High', 'High', 'Low', 'Low'],
    ['High', 'High', 'Low', 'Medium'],
    ['High', 'High', 'Low', 'High'],
    ['High', 'High', 'Medium', 'Low'],
    ['High', 'High', 'Medium', 'Medium'],
    ['High', 'High', 'Medium', 'High'],
    ['High', 'High', 'High', 'Low'],
    ['High', 'High', 'High', 'Medium'],
    ['High', 'High', 'High', 'High'],
    ['High', 'High2', 'Low', 'Low'],
    ['High', 'High2', 'Low', 'Medium'],
    ['High', 'High2', 'Low', 'High'],
    ['High', 'High2', 'Medium', 'Low'],
    ['High', 'High2', 'Medium', 'Medium'],
    ['High', 'High2', 'Medium', 'High'],
    ['High', 'High2', 'High', 'Low'],
    ['High', 'High2', 'High', 'Medium'],
    ['High', 'High2', 'High', 'High'],
    ['High', 'High3', 'Low', 'Low'],
    ['High', 'High3', 'Low', 'Medium'],
    ['High', 'High3', 'Low', 'High'],
    ['High', 'High3', 'Medium', 'Low'],
    ['High', 'High3', 'Medium', 'Medium'],
    ['High', 'High3', 'Medium', 'High'],
    ['High', 'High3', 'High', 'Low'],
    ['High', 'High3', 'High', 'Medium'],
    ['High', 'High3', 'High', 'High'],
    ['High', 'High4', 'Low', 'Low'],
    ['High', 'High4', 'Low', 'Medium'],
    ['High', 'High4', 'Low', 'High'],
    ['High', 'High4', 'Medium', 'Low'],
    ['High', 'High4', 'Medium', 'Medium'],
    ['High', 'High4', 'Medium', 'High'],
    ['High', 'High4', 'High', 'Low'],
    ['High', 'High4', 'High', 'Medium'],
    ['High', 'High4', 'High', 'High']
];

var lookupKeys = null;

export const getLookupKey = (key) => {
    if (lookupKeys == null) {
        lookupKeys = {};
        riskLevelLookup.map(x => {
            lookupKeys[x.join('')] = x;
        });
    }

    return lookupKeys[key];
};

export const PrepareRiskCombinations = (data) => {
    const categories = ['Sport', 'League', 'Bet Type', 'User'];
    const levels = ['Low', 'Medium', 'High'];
    let keys = {};
    let arr = [];


    categories.map(x => {
        x;
        arr.push(levels);
    });

    riskLevelLookup.map(x => {
        var s = [];
        x.map(y => {
            s.push(y);
        });

        const key = s.join('');
        keys[key] = data[key] ?? {
            sport: x[0],
            league: x[1],
            betType: x[2],
            player: x[3],
            key: key,
            liability: 0,
            maxWin: 0,
            maxStake_0_1: 0,
            maxStake_1_2: 0,
            maxStake_3_4: 0,
            maxStake_5_10: 0,
            maxStake_11_0: 0,
            maxWin_0_1: 0,
            maxWin_1_2: 0,
            maxWin_3_4: 0,
            maxWin_5_10: 0,
            maxWin_11_0: 0,
            offer: false
        };



        /*
        keys[key].liability = 50;
        keys[key].maxWin = 50;
        keys[key].maxStake_0_1 = 50;
        keys[key].maxStake_1_2 = 50;
        keys[key].maxStake_3_4 = 50;
        keys[key].maxStake_5_10 = 50;
        keys[key].maxStake_11_0 = 50;
        keys[key].maxWin_0_1 = 50;
        keys[key].maxWin_1_2 = 50;
        keys[key].maxWin_3_4 = 50;
        keys[key].maxWin_5_10 = 50;
        keys[key].maxWin_11_0 = 50;
        */
    });
    return keys;
};

const focus = (e) => {
    if (e.target.value == '0') {
        e.target.value = '';
    }
};

const blur = (e) => {
    if (e.target.value == '') {
        e.target.value = '0';
    }
};

const model = {
    fields: [
        {
            name: 'sport',
            title: 'Sport',
            render: (val, row) => {
                return <div className={row.sport} >{row.sport}</div>;
            }
        },
        {
            name: 'league',
            title: 'League',
            render: (val, row) => {
                return <div className={row.league} >{row.league}</div>;
            }
        },
        {
            name: 'betType',
            title: 'Bet Type',
            render: (val, row) => {
                return <div className={row.betType} >{row.betType}</div>;
            }
        },
        {
            name: 'player',
            title: 'Player',
            render: (val, row) => {
                return <div className={row.player} >{row.player}</div>;
            }
        },

        {
            name: 'liability',
            title: 'Company Liability',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className=''>Company Liab.</span>
                    <span className='border-top'>per Event</span>
                </div>;
            },
            render: (val, row, field, context) => {
                return <input type='number' key={`${row.sport}${row.league}${row.betType}${row.player}liability`} defaultValue={row.liability} onFocus={(e) => focus(e)} onChange={(e) => context.update(row, e.target.value, field)} onBlur={(e) => blur(e)} />;
            }
        },
        {
            name: 'maxWin',
            title: 'Max Win',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span>Max Win / Event</span>
                    <span className='border-top'>per Player</span>
                </div>;
            },
            render: (val, row, field, context) => {
                return <input type='number' key={`${row.sport}${row.league}${row.betType}${row.player}maxWin`} defaultValue={row.maxWin} onFocus={(e) => focus(e)} onChange={(e) => context.update(row, e.target.value, field)} onBlur={(e) => blur(e)} />;
            }
        },
        {
            name: 'maxStake_0_1',
            title: 'Max Stake 1 Selection',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span>Max Stake</span>
                    <span className='border-top'>1 Selection</span>
                </div>;
            },
            render: (val, row, field, context) => {
                return <input type='number' key={`${row.sport}${row.league}${row.betType}${row.player}maxStake_0_1`} defaultValue={row.maxStake_0_1} onFocus={(e) => focus(e)} onChange={(e) => context.update(row, e.target.value, field)} onBlur={(e) => blur(e)} />;
            }
        },
        {
            name: 'maxStake_1_2',
            title: 'Max Stake 2 Selections',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span>Max Stake</span>
                    <span className='border-top'>2 Selection</span>
                </div>;
            },
            render: (val, row, field, context) => {
                return <input type='number' key={`${row.sport}${row.league}${row.betType}${row.player}maxStake_1_2`} defaultValue={row.maxStake_1_2} onFocus={(e) => focus(e)} onChange={(e) => context.update(row, e.target.value, field)} onBlur={(e) => blur(e)} />;
            }
        },
        {
            name: 'maxStake_3_4',
            title: 'Max Stake 3-4 Selection',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span>Max Stake</span>
                    <span className='border-top'>3-4 Selection</span>
                </div>;
            },
            render: (val, row, field, context) => {
                return <input type='number' key={`${row.sport}${row.league}${row.betType}${row.player}maxStake_3_4`} defaultValue={row.maxStake_3_4} onFocus={(e) => focus(e)} onChange={(e) => context.update(row, e.target.value, field)} onBlur={(e) => blur(e)} />;
            }
        },
        {
            name: 'maxStake_5_10',
            title: 'Max Stake 5-10 Selection',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span>Max Stake</span>
                    <span className='border-top'>5-10 Selection</span>
                </div>;
            },
            render: (val, row, field, context) => {
                return <input type='number' key={`${row.sport}${row.league}${row.betType}${row.player}maxStake_5_10`} defaultValue={row.maxStake_5_10} onFocus={(e) => focus(e)} onChange={(e) => context.update(row, e.target.value, field)} onBlur={(e) => blur(e)} />;
            }
        },
        {
            name: 'maxStake_11_0',
            title: 'Max Stake 11+ Selection',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span>Max Stake</span>
                    <span className='border-top'>11+ Selection</span>
                </div>;
            },
            render: (val, row, field, context) => {
                return <input type='number' key={`${row.sport}${row.league}${row.betType}${row.player}maxStake_11_0`} defaultValue={row.maxStake_11_0} onFocus={(e) => focus(e)} onChange={(e) => context.update(row, e.target.value, field)} onBlur={(e) => blur(e)} />;
            }
        },
        {
            name: 'maxWin_0_1',
            title: 'Max Win 1 Selection',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span>Max Win</span>
                    <span className='border-top'>1 Selection</span>
                </div>;
            },
            render: (val, row, field, context) => {
                return <input type='number' key={`${row.sport}${row.league}${row.betType}${row.player}maxWin_0_1`} defaultValue={row.maxWin_0_1} onFocus={(e) => focus(e)} onChange={(e) => context.update(row, e.target.value, field)} onBlur={(e) => blur(e)} />;
            }
        },
        {
            name: 'maxWin_1_2',
            title: 'Max Win 2 Selections',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span>Max Win</span>
                    <span className='border-top'>2 Selection</span>
                </div>;
            },
            render: (val, row, field, context) => {
                return <input type='number' key={`${row.sport}${row.league}${row.betType}${row.player}maxWin_1_2`} defaultValue={row.maxWin_1_2} onFocus={(e) => focus(e)} onChange={(e) => context.update(row, e.target.value, field)} onBlur={(e) => blur(e)} />;
            }
        },
        {
            name: 'maxWin_3_4',
            title: 'Max Win 3-4 Selection',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span>Max Win</span>
                    <span className='border-top'>3-4 Selection</span>
                </div>;
            },
            render: (val, row, field, context) => {
                return <input type='number' key={`${row.sport}${row.league}${row.betType}${row.player}maxWin_3_4`} defaultValue={row.maxWin_3_4} onFocus={(e) => focus(e)} onChange={(e) => context.update(row, e.target.value, field)} onBlur={(e) => blur(e)} />;
            }
        },
        {
            name: 'maxWin_5_10',
            title: 'Max Win 5-10 Selection',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span>Max Win</span>
                    <span className='border-top'>5-10 Selection</span>
                </div>;
            },
            render: (val, row, field, context) => {
                return <input type='number' key={`${row.sport}${row.league}${row.betType}${row.player}maxWin_5_10`} defaultValue={row.maxWin_5_10} onFocus={(e) => focus(e)} onChange={(e) => context.update(row, e.target.value, field)} onBlur={(e) => blur(e)} />;
            }
        },
        {
            name: 'maxWin_11_0',
            title: 'Max Win 11+ Selection',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span>Max Win</span>
                    <span className='border-top'>11+ Selection</span>
                </div>;
            },
            render: (val, row, field, context) => {
                return <input type='number' key={`${row.sport}${row.league}${row.betType}${row.player}maxWin_11_0`} defaultValue={row.maxWin_11_0} onFocus={(e) => focus(e)} onChange={(e) => context.update(row, e.target.value, field)} onBlur={(e) => blur(e)} />;
            }
        },
        {
            name: 'offer',
            title: 'Offer',
            renderHeader: () => {
                return <div className='flex '>
                    <span className='center'>Ask Offer</span>
                </div>;
            },
            render: (val, row, field, context) => {
                return <div className='flex' onClick={() => context.update(row, !row.offer, field)}>
                    <ToggleButton value={row.offer} onChange={(v) => context.update(row, v, field)} />
                </div>;
            }
        }
    ]
};

export const RiskLevelLimitsConfiguration = () => {
    const [updateKey, setUpdateKey] = useState(0);
    const [riskLevels, setRiskLevels] = useState(null);
    const [saving, setSaving] = useState(false);
    const [page, setPage] = useState(null);
    const [pagination, setPagination] = useState(null);
    const itemsPerPage = 72;

    const context = {
        update: (row, value, field) => {
            var key = [row.sport, row.league, row.betType, row.player].join('');
            const target = field.name;
            var copy = { ...riskLevels };
            copy[key][target] = (value == true || value == false) ? value : parseFloat(value);
            setRiskLevels(copy);
        }
    };

    useEffect(() => {
        setSaving(true);
        API.post(RiskLevelEndpoints.Get).then((result) => {
            var levels = result.result ? JSON.parse(result.result) : {};
            var elements = PrepareRiskCombinations(levels);
            setRiskLevels(elements);
            setPage(0);
            setSaving(false);
        });
    }, []);

    useEffect(() => {
        //setUpdateKey(Date.now());
    }, [riskLevels]);

    useEffect(() => {
        if (!riskLevels) return;
        let current = page;
        let totalItems = Object.values(riskLevels).length;
        let totalPages = Math.ceil(totalItems / itemsPerPage);


        if (current > totalItems) {
            current = (totalPages - 1) * itemsPerPage;
        }

        let start = current * itemsPerPage;
        var pages = [];
        for (var i = 0; i < totalPages; i++) {
            pages.push({ index: i });
        }


        setPagination({
            start: current * itemsPerPage,
            end: start + itemsPerPage,
            total: totalPages,
            pages: pages,
        });
    }, [page]);


    const save = () => {
        setSaving(true);
        var copy = { ...riskLevels };
        delete copy['key'];
        Object.values(copy).map(x => {
            x.maxStake = x.maxStake_0_1;
        });
        API.post(RiskLevelEndpoints.Save, copy).then(() => {
            setSaving(false);
        });
    };

    const importCVS = () => {
        const ref = React.createRef();

        function handleFileSelect(e) {
            var files = e.target.files;
            if (files.length < 1) {
                alert('select a file...');
                return;
            }
            var file = files[0];
            var reader = new FileReader();
            reader.onload = onFileLoaded;
            reader.readAsText(file);
        }

        function onFileLoaded(e) {
            var data = e.target.result.split('\n');
            var copy = { ...riskLevels };
            for (var i = 0; i < data.length; i++) {
                var row = data[i].split(',');
                var sport = row[0];
                var league = row[1];
                var betType = row[2];
                var player = row[3];
                var level = sport + league + betType + player;
                var riskLevel = copy[level];
                var multiplier = 1;
                try {
                    riskLevel.liability = parseFloat(row[4]) * multiplier;
                    riskLevel.maxWin = parseFloat(row[5]) * multiplier;
                    riskLevel.maxStake_0_1 = parseFloat(row[6]) * multiplier;
                    riskLevel.maxStake_1_2 = parseFloat(row[7]) * multiplier;
                    riskLevel.maxStake_3_4 = parseFloat(row[8]) * multiplier;
                    riskLevel.maxStake_5_10 = parseFloat(row[9]) * multiplier;
                    riskLevel.maxStake_11_0 = parseFloat(row[10]) * multiplier;
                    riskLevel.maxWin_0_1 = parseFloat(row[11]) * multiplier;
                    riskLevel.maxWin_1_2 = parseFloat(row[12]) * multiplier;
                    riskLevel.maxWin_3_4 = parseFloat(row[13]) * multiplier;
                    riskLevel.maxWin_5_10 = parseFloat(row[14]) * multiplier;
                    riskLevel.maxWin_11_0 = parseFloat(row[15]) * multiplier;
                } catch (err) {
                    console.log(err);
                }
            }

            setUpdateKey(Date.now());
            setRiskLevels(copy);
            Modal.close();
        }



        Modal.open(<ConfirmationModal title='Import'>
            <flex className='gap-10'>
                <input type='file' ref={ref} onChange={handleFileSelect} className='align-right' />
                <Button title='Select file' onClick={() => ref.current.click()} />
            </flex>
        </ConfirmationModal>);
    };

    if (!riskLevels) return <></>;
    if (!pagination) return <></>;

    const data = Object.values(riskLevels).slice(pagination.start, pagination.end);

    return <flex className={`risk-level-limits${saving ? ' passive' : ''} vertical gap-10`}>
        <FluxCard>
            <flex className='gap-10'>
                <Button title='Save' className='success' onClick={() => save()} />
                <Button title='Import' onClick={() => importCVS()} className='align-right' />
                <Tip tip='Import risk levels using csv file.' />
            </flex>
        </FluxCard>
        <FluxCard className='overflow-hidden no-padding'>
            <Table
                model={model} data={data}
                key={updateKey}
                context={context}
                className='sticky' />
        </FluxCard>
        <FluxCard>
            <div className='search-pagination gap-10 flex gap-10'>
                <flex className={`pagination-button radius fx-shadow clickable${page == 0 ? ' disabled' : ''}`} onClick={() => setPage(page - 1)}>
                    <Icon icon='chevron-left' size='xs' type='fa-regular' />
                    <span>{lang('Previous')}</span>
                </flex>
                <flex onClick={() => setPage(0)} className={`pagination-button radius fx-shadow${page == 0 ? ' disabled' : ''}`}>
                    <Icon icon={'step-backward'} size='xs' type='fa-regular' />
                    <span>{lang('First Page')}</span>
                </flex>

                <flex className='gap-5 justify-content-center center'>
                    {
                        pagination.pages.map(p => {
                            return <flex key={p.index} className={`pagination-button radius fx-shadow${(page === p.index ? ' active' : '')}`} onClick={() => setPage(p.index)}>
                                <span>{p.index + 1}</span>
                            </flex>;
                        })
                    }
                </flex>

                <flex onClick={() => setPage(pagination.total - 1)} className={`pagination-button radius fx-shadow${page == pagination.total || pagination.total <= 1 ? ' disabled' : ''}`}>
                    <span>{lang('Last Page')}</span>
                    <Icon icon={'step-forward'} size='xs' type='fa-regular' />
                </flex>
                <flex className={`pagination-button radius fx-shadow clickable${page == pagination.total - 1 || pagination.total <= 1 ? ' disabled' : ''}`} onClick={() => setPage(page + 1)}>
                    <span>{lang('Next')}</span>
                    <Icon icon='chevron-right' size='xs' type='fa-regular' />
                </flex>
            </div>
        </FluxCard>
    </flex>;
};