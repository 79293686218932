import React from 'react';
import { Icon } from '../../../components/icon/Icon';
import { lang } from '../../../lib/Localization/language';
import { changeUserPassword, editUser } from '../../../actions/UserActions';
import { InputTypes } from '../../../components/form/InputTypes';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { ActionTypes } from '../../../lib/ActionManager/FluxActions';
import { RetailEndpoints } from '../../../lib/API/Endpoints';
import { PhysicalRetailUserTypes, RetailTypes, VirtualRetailUserTypes } from '../../../lib/Enums/RetailEnums';

export const SearchRetailUsers = (props) => {
    const { retail } = { ...props };

    const model = {
        fields: [
            {
                name: 'RetailId',
                value: retail.Id,
                hidden: true
            },
            {
                name: 'Username',
                display: 'Username'
            },
            {
                name: 'Group',
                display: 'User Group',
                type: InputTypes.Select,
                multiple: true,
                exact: true,
                values: retail.Type == RetailTypes.Virtual ? VirtualRetailUserTypes : PhysicalRetailUserTypes,
                required: true,
                value: Object.keys(retail.Type == RetailTypes.Virtual ? VirtualRetailUserTypes : PhysicalRetailUserTypes)
            },
            {
                name: 'Email',
                type: InputTypes.Text,
            },
            {
                name: 'Name',
                type: InputTypes.Text
            },
            {
                name: 'Surname',
                type: InputTypes.Text
            },
            {
                name: 'Enabled',
                type: InputTypes.Bool,
                value: true
            }
        ]
    };

    const resultModel = {
        fields: [
            {
                name: 'Id'
            },
            {
                name: 'UserGroupId',
                title: 'User Group',
                render: (val) => {
                    return (retail.Type == RetailTypes.Virtual ? VirtualRetailUserTypes : PhysicalRetailUserTypes)[val];
                }
            },
            {
                name: 'Username',
                display: 'Username'
            },
            {
                name: 'Email',
                type: InputTypes.Text
            },
            {
                name: 'Name',
                type: InputTypes.Text
            },
            {
                name: 'Surname',
                type: InputTypes.Text
            },
            {
                name: 'Enabled',
                type: InputTypes.Bool
            }
        ]
    };


    const onActionClicked = (action, user) => {
        switch (action.type) {
            case ActionTypes.EditMyUser:
                editUser(user.Id, (result, data) => {
                    user.Enabled = data.Enabled;
                });
                break;
            case ActionTypes.ChangeMyUserPassword:
                changeUserPassword(user);
                break;
            default:
                console.log(action.type);
                break;
        }
    };

    return <FluxSearch
        title={<React.Fragment><span>{lang('Retail')}</span><Icon icon='chevron-right' type='fa-light' /><span>{retail.Name}</span><Icon icon='chevron-right' type='fa-light' /><span>{lang('Search Users')}</span></React.Fragment>}
        model={model} resultModel={resultModel}
        endpoint={`${RetailEndpoints.ListRetailUsers}/${retail.Id}`}
        actions={ActionTypes.SearchMyUsers}
        onActionClicked={onActionClicked.bind(this)} />;
};