import './_toggle-button.scss';

import React, { useEffect, useState } from 'react';

export const ToggleButton = (props) => {
    const { title, value, disabled, onChange } = { ...props };
    const [active, setActive] = useState(value === true);

    const inputRef = React.createRef();
    useEffect(() => {
        if (onChange) onChange(active);
        if (inputRef.current) {
            if (active) {
                inputRef.current.checked = true;
                inputRef.current.setAttribute('checked', true);
            } else {
                inputRef.current.checked = false;
                inputRef.current.setAttribute('checked', false);
            }
        }
    }, [active]);

    return <flex className={`gap-5 toggle-button${disabled ? ' disabled' : ''}`} onClick={disabled ? null : () => setActive(!active)}>
        {title}
        <switch className>
            <input type='checkbox' checked={active} ref={inputRef} />
            <span className='slider round'></span>
        </switch>
    </flex>;
};