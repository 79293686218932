/* eslint-disable react/display-name */
/* eslint-disable react/react-in-jsx-scope */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { FluxSearch } from '../../../components/search/FluxSearch';
import { InputTypes } from '../../../components/form/InputTypes';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { TransactionTypes } from '../../../lib/Enums/TransactionEnums';
import { AffiliateEndpoints, PlayerPromotionEndpoints, TransactionEndpoints } from '../../../lib/API/Endpoints';
import { ActionTypes } from '../../../lib/ActionManager/FluxActions';
import { appHistory } from '../../../..';
import { Modal } from '../../../components/application/FluxApp';
import { FluxModal } from '../../../components/modal/FluxModal';
import { Button } from '../../../components/button/Button';
import { copyDataToClipboard } from '../../../lib/Helpers/StringHelper';
import { confirmTransaction, rejectTransction } from '../../../actions/TransactionActions';
import { FluxNotice } from '../../../components/notification/FluxNotice';
import { currency } from '../../../components/currency/Currency';
import { toSnakeCase } from '../../../../components/Centrum/helpers';
import { API } from '../../../lib/API/Api';


const model = {
    fields: [
        {
            name: 'PlayerId',
            title: 'Player Id'
        },
        {
            name: 'Username',
            title: 'Username'
        },
        {
            name: 'TransactionDate',
            title: 'Date',
            type: InputTypes.DateTime,
            between: true,
            value: {
                start: DateHelpers.yesterday(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'TransactionId',
            title: 'Transaction Id',
            information: 'Our record'
        },
        {
            name: 'ExternalTransactionId',
            title: 'External Transaction Id',
            information: 'Transaction id recorded by provider'
        },
        {
            name: 'ProviderId',
            title: 'Provider',
            type: InputTypes.Select,
            nullable: true,
            //value: [191341, 191327],
            data: {
                source: 'myProviders',
                key: 'id',
                title: ['display'],
                parser: (result) => {
                    var results = [];
                    var group = 'Gaming';
                    result.result.map(x => {
                        x = toSnakeCase(x);
                        if (x.type == 128) group = 'Payment Provider';
                        results.push({
                            value: x.id, title: x.display, group: {
                                id: group,
                                title: group
                            }
                        });
                    });
                    return results;
                }
            }
        },
        {
            name: 'Type',
            title: 'Type',
            type: InputTypes.Select,
            nullable: true,
            multiple: true,
            exact: true,
            value: [0, 1],
            values: {
                0: 'Deposit',
                1: 'Withdraw',
                3: 'Bet',
                2: 'Win',
                4: 'Rollback'
            }
        },
        {
            name: 'Status',
            title: 'Status',
            type: InputTypes.Select,
            nullable: true,
            exact: true,
            value: 0,
            values: {
                0: 'Pending',
                1: 'Confirmed',
                2: 'Cancelled',
            }
        }
    ]
};

const resultTransactionInformation = {
    fields: [
        {
            name: 'TransactionDate',
            title: 'Time',
            type: InputTypes.DateTime
        },
        {
            name: 'Status',
            title: 'Status',
            type: InputTypes.Select,
            values: {
                0: 'Pending',
                1: 'Confirmed',
                2: 'Cancelled',
            }
        },
        {
            name: 'ExternalTransactionId',
            title: 'External Transaction Id'
        },
        {
            name: 'Type',
            title: 'Type',
            type: InputTypes.Select,
            values: TransactionTypes
        },
        {
            name: 'Source',
            title: 'Source'
        },
        {
            name: 'Provider',
            title: 'Provider'
        },
        {
            name: 'Username'
        },
        {
            name: 'Affiliate'
        },
        {
            name: 'RetailName',
            title: 'Retail'
        },
        {
            name: 'Notes',
            title: 'Notes',
            render: (val) => {
                return <span className='transaction-notes'>{val}</span>;
            },
            optional: true
        },
        {
            name: 'Amount',
            title: 'Amount',
            type: InputTypes.Number,
            render: (val, row) => {
                return currency(val, 2, row.Currency);
            }
        },
        {
            name: 'BalanceAfter',
            title: 'Balance',
            type: InputTypes.Number,
            render: (val, row) => {
                return currency(val, 2, row.Currency);
            }
        }
    ],
    onRenderClassName: (row, className) => {
        if (row.Status == 1) {
            return 'transaction-confirmed';
        }
        if (row.Status == 2) {
            return 'transaction-cancel';
        }
        if (row.Type == 4) {
            return 'transaction-rollback';
        }
        return className;
    }
};

export const SearchPendingPSPTransactions = (props) => {
    const title = 'Payment Provider Transactions';
    const [actions, setActions] = useState(null);
    useEffect(() => {
        setActions(ActionTypes.SearchPSPTransactions);
    }, []);


    const endpoint = () => {
        if (props.affiliate) {
            return `${AffiliateEndpoints.Transactions}/${props.affiliate.Id}`;
        }
        return TransactionEndpoints.SearchPSP;
    };

    const onActionClicked = (action, transaction, selection) => {
        var data = selection.tableContext.getData();
        switch (action.type) {
            case ActionTypes.ShowTransactionsData:
                var transactionData = <pre>{transaction.Data}</pre>;
                if (transaction.Data && transaction.Data.indexOf('{') == 0) {
                    try {
                        var converted = JSON.parse(transaction.Data);
                        var elements = Object.keys(converted).map(x => {
                            return <flex className='vertical' key={x}>
                                <span className='bold'>{x}</span>
                                <span className=''>{converted[x].toString()}</span>
                            </flex>;
                        });
                        transactionData = <flex className='vertical gap-5'>
                            {elements}
                        </flex>;
                    } catch (err) {
                        //
                    }
                }

                if (!transaction.Data) {
                    transactionData = <FluxNotice type='information' title='No information' description='There is no information for this transaction' />;
                }
                Modal.open(<FluxModal title='Transaction information' footer={<flex className='center gap-10'>
                    {transaction.Data && <Button title='Copy to clipboard' onClick={() => copyDataToClipboard(transaction.Data)} />}
                    <Button title='Close' className={'success'} onClick={() => Modal.close()} />
                </flex>}>
                    {transactionData}
                </FluxModal>);
                break;
            case ActionTypes.PlayerProfile:
                appHistory.push(`/players/search?&id=${transaction.PlayerId}`);
                break;
            case ActionTypes.ConfirmTransaction:
                API.post(PlayerPromotionEndpoints.GetPlayerPromotion(transaction.UserId)).then((res) => {
                    confirmTransaction({ id: transaction.Id, amount: transaction.Amount, data: transaction.Data, promotion: res.result, playerId: transaction.UserId, currency: transaction.Currency }, transaction.Type, () => {
                        data = data.filter(x => x.Id != transaction.Id);
                        selection.tableContext.setData(data);
                    });
                });
                break;
            case ActionTypes.RejectTransaction:
                rejectTransction({ id: transaction.Id, amount: transaction.Amount, data: transaction.Data, currency: transaction.Currency }, transaction.Type, () => {
                    data = data.filter(x => x.Id != transaction.Id);
                    selection.tableContext.setData(data);
                });
                break;
            default:
                console.log(action.type);
                break;
        }
    };


    if (!actions) return <></>;

    return <FluxSearch
        title={title}
        model={model}
        resultModel={resultTransactionInformation}
        endpoint={endpoint()}
        searchOnStart={props.pending}
        actions={actions}
        onActionClicked={onActionClicked.bind(this)}
        onPrepareModel={(model) => {
            var copy = { ...model };
            if (props.payment) {
                var providerField = copy.fields.find(x => x.name == 'ProviderId');
                providerField.data.source = 'providers/payment';
            }
            if (props.playerId) {
                var playerField = copy.fields.find(x => x.name == 'PlayerId');
                playerField.hidden = true;
                playerField.value = props.playerId;
                copy.fields.find(x => x.name == 'Username').hidden = true;
                copy.name = 'transactions-with-player-id';
            }

            if (props.affiliate) {
                copy.name = 'transactions-of-affiliate';
                return copy;
            }


            if (props.deposits) {
                copy.fields.find(x => x.name == 'Type').value = '0';
                copy.fields.find(x => x.name == 'Type').hidden = true;
                copy.fields = copy.fields.filter(x => x.name != 'TransactionDate');

                copy.name = `${props.pending ? 'pending' : ''}deposit-transactions`;
            }
            if (props.withdraws) {
                copy.fields.find(x => x.name == 'Type').value = 1;
                copy.fields.find(x => x.name == 'Type').hidden = true;
                copy.fields = copy.fields.filter(x => x.name != 'TransactionDate');

                copy.name = `${props.pending ? 'pending' : ''}withdraw-transactions`;
            }
            return copy;
        }}
    />;
};


SearchPendingPSPTransactions.propTypes = {
    affiliate: PropTypes.object,
    playerId: PropTypes.number,
    payment: PropTypes.bool,
    pending: PropTypes.bool,
    deposits: PropTypes.bool,
    withdraws: PropTypes.bool
};