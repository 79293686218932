import React from 'react';
import { FluxAction } from '../../components/application/FluxAction';
import { FluxPage } from '../../components/page/FluxPage';
import { ActionTypes } from '../../lib/ActionManager/FluxActions';
import { ExchangeRates } from './ExchangeRates/ExchangeRates';
import { PaymentProvidersPage } from './PaymentProviders/PaymentProvidersPage';
import { SearchTransactionLimits } from './TransactionLimits/SearchTransactionLimits';
import { TransactionLimitModify } from './TransactionLimits/TransactionLimitModify';
import { PaymentProviderWhiteLists } from './PaymentProviders/PaymentProviderWhiteLists/PaymentProviderWhiteLists';

export const SetupPage = () => {
    return <FluxPage action={ActionTypes.Setup}>
        <FluxAction action={ActionTypes.ListExchangeRates}><ExchangeRates /></FluxAction>
        <FluxAction action={ActionTypes.ConfigurePaymentProviders}><PaymentProvidersPage /> </FluxAction>
        <FluxAction action={ActionTypes.PaymentProviderWhiteLists}><PaymentProviderWhiteLists /> </FluxAction>
        <FluxAction action={ActionTypes.SearchTransactionLimits}><SearchTransactionLimits /></FluxAction>
        <FluxAction action={ActionTypes.TransactionLimitsModify}><TransactionLimitModify /></FluxAction>
    </FluxPage>;
};
