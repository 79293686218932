import React, { useEffect } from 'react';
import { BetMonitorSocket } from '../../Sports/BetMonitor/MonitorTickets/BetmonitorSocket';
import { FluxTab } from '../../../components/tab/FluxTab';
import { BetTicker } from '../BetTicker/BetTicker';

export const HorseGreyhoundMonitor = () => {
    useEffect(() => {
    }, []);

    var tabButtons = [
        {
            name: 'ticker',
            title: 'Bet Ticker'
        }
    ];

    
    return <BetMonitorSocket endpoint2='ht{operatorId}ws.appslnd.net/ws' endpoint3='localhost:2149/ws' type='horse'>
        <FluxTab buttons={tabButtons} className='gap-10 fx-shadow fx-radius rounded-buttons radius justify-content-center overflow-hidden'>
            <BetTicker />
        </FluxTab>
    </BetMonitorSocket>;
};