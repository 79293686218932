import './_retail-center.scss';

import React, { useEffect, useState } from 'react';
import { ActionButton, FluxCard } from '../../../components/card/FluxCard';
import { RetailTree } from '../RetailTree/RetailTree';
import { Retail } from '../Retail/Retail';
import { appHistory } from '../../../..';
import { Icon } from '../../../components/icon/Icon';
import { Button } from '../../../components/button/Button';
import { FluxModal } from '../../../components/modal/FluxModal';
import { Modal } from '../../../components/application/FluxApp';
import { FluxForm } from '../../../components/form/FluxForm';
import { RetailEndpoints } from '../../../lib/API/Endpoints';
import { Popper } from '../../../components/notification/FluxPopper';
import { NotificationType } from '../../../components/notification/FluxNotification';
import { lang } from '../../../lib/Localization/language';
import { InputTypes } from '../../../components/form/InputTypes';
import { useSelector } from 'react-redux';
import { operatorSettings } from '../../../../config'


export const RetailCenter = () => {
    const user = useSelector((state) => state.user.loginInformation);
    const [minimized, minimize] = useState(false);
    const [retail, selectRetail] = useState();
    const [retails, setRetails] = useState(null);

    const [update, setUpdate] = useState(null);

    useEffect(() => {
        if (retail == null) {
            //appHistory.push('/retails/retail-center');
        } else {
            appHistory.push(`/retails/retail-center?&id=${retail.id}`);
        }
    }, [retail]);

    useEffect(() => {
        if (!retails) return;
        const urlParams = new URLSearchParams(window.location.search);
        const id = parseFloat(urlParams.get('id'));
        if (id && !isNaN(id)) {
            selectRetail(retails.find(x => x.id == id));
        }
    }, [retails]);
    const defaultRetailConfig = (subRetail) => {
        return `{"subRetails":${!!subRetail},"enableTransferFundsToAgents":true,"sport":{"enabled":false,"revenueType":0,"percentage":0,"deductBonus":false},"casino":{"enabled":false,"revenueType":0,"percentage":0,"deductBonus":false,"providerLimits":[],"revenueLimits":[{"type":0,"minBet":0,"maxBet":0,"percentage":0,"enabled":false},{"type":1,"minBet":0,"maxBet":0,"percentage":0,"enabled":false}],"gameLimits":[],"disabledProviders":[],"disabledGames":[]},"horse":{"enabled":false,"revenueType":0,"percentage":0,"deductBonus":false},"payment":{"enabled":false},"subUsers":false}`
    };
    const getConfigurationDefaultValue = () => {
        if (!!operatorSettings?.subRetail?.enabled) {
            if (operatorSettings?.subRetail?.enabled.find(x => x == window.user.UserPrivileges.PointOfView)) {
                return defaultRetailConfig(true);
            }
        }
        return defaultRetailConfig(false);
    }
    function findElementById(tree, id) {
        for (const node of tree) {
            if (node.Id === id) {
                return node; // If found, return the node
            }
            if (!!node.children && node.children.length > 0) {
                const foundInChildren = findElementById(node.children, id);
                if (foundInChildren) return foundInChildren; // Recursively search in children
            }
        }
        return null; // Return null if not found
    }

    // Function to search in multiple root nodes
    function findElementByIdInMultipleRoots(roots, id) {
        for (const root of roots) {
            const found = findElementById([root], id); // Pass root as an array
            if (found) return found; // If found in any root, return it
        }
        return null; // Return null if not found in any root
    }
    const addNewRetail = () => {
        const retailModel = {
            sections: {
                'General': {
                    icon: <Icon icon='info' type='fa-light' />,
                    fields: [
                        {
                            name: 'ParentRetailId',
                            title: 'Parent Retail',
                            type: InputTypes.Select,
                            nullable: true,
                            data: {
                                source: 'myRetails',
                                key: 'Id',
                                parser: (result) => {
                                    var results = [];
                                    var retails = {};
                                    result.result.map(x => {
                                        retails[x.Id] = x.Name;
                                    });

                                    result.result.map(x => {
                                        var group = retails[x.ParentRetailId] ?? 'Root';
                                        results.push({
                                            value: x.Id,
                                            title: x.Name,
                                            group: {
                                                id: group,
                                                title: group
                                            }
                                        });
                                    });

                                    return results;
                                },
                                title: ['Code', 'Name']
                            }
                        },
                        {
                            name: 'Code',
                            title: 'Code'
                        },
                        {
                            name: 'Type',
                            title: 'Type',
                            type: InputTypes.Select,
                            values: {
                                0: 'Agent'
                            }
                        },
                        {
                            name: 'Name',
                            title: 'Name',
                            required: true
                        },
                        {
                            name: 'Description',
                            title: 'Description',
                            type: InputTypes.Textarea,
                            required: true
                        }
                    ]
                },
                'Owner': {
                    icon: <Icon icon='user' type='fa-light' />,
                    fields: [
                        {
                            name: 'OwnerName',
                            title: 'Owner Name',
                            required: true
                        },
                        {
                            name: 'OwnerSurname',
                            title: 'Owner Surmame',
                            required: true
                        },
                        {
                            name: 'OwnerEmail',
                            title: 'Owner Email',
                            required: true
                        },
                        {
                            name: 'OwnerUsername',
                            title: 'Owner Username',
                            required: true
                        },
                        {
                            name: 'OwnerPassword',
                            title: 'Owner Password',
                            type: InputTypes.Password,
                            required: true
                        }
                    ]
                },
                'Settings': {
                    icon: <Icon icon='cogs' type='fa-light' />,
                    fields: [
                        {
                            name: 'DefaultCurrency',
                            title: 'Currency',
                            type: InputTypes.Select,
                            data: {
                                source: 'enabledCurrencies',
                                key: 'CurrencyCode',
                                title: ['Name']
                            },
                            value: user.features.currency
                        },
                        {
                            name: 'Configuration',
                            value: getConfigurationDefaultValue(),
                            hidden: true
                        },
                        {
                            name: 'Enabled',
                            title: 'Enabled',
                            type: InputTypes.Bool,
                            value: true
                        }
                    ]
                }
            }
        };

        Modal.open(<FluxModal title='New Retail'>
            <FluxForm
                columns={2}
                endpoint={RetailEndpoints.Save}
                model={retailModel}
                onCancel={() => Modal.close()}
                onBeforeSubmit={(data) => {
                    var config = defaultRetailConfig(false);
                    if (data.ParentRetailId) {
                        const result = findElementByIdInMultipleRoots(retails, data.ParentRetailId);
                        if (!!result) {
                            var json = JSON.parse(result.Configuration)
                            if (!!json && json['subRetails'] === true) {
                                config = defaultRetailConfig(true);
                            }
                        }
                    }

                    var copy = {
                        ParentRetailId: data.ParentRetailId,
                        Code: data.Code,
                        Type: data.Type,
                        Name: data.Name,
                        Description: data.Description,
                        User: {
                            email: data.OwnerEmail,
                            name: data.OwnerName,
                            surname: data.OwnerSurname,
                            username: data.OwnerUsername,
                            password: data.OwnerPassword,
                            userGroupId: 9
                        },
                        DefaultCurrency: data.DefaultCurrency,
                        Configuration: !!data.ParentRetailId ? config : data.Configuration,
                        Enabled: data.Enabled
                    };
                    return copy;
                }}
                onSubmitComplete={(result) => {
                    Modal.close();
                    Popper.pop(NotificationType.Information, null, lang('Retail saved.'));
                    selectRetail(result.result);
                    setUpdate(Date.now());
                }}
            />
        </FluxModal>);
    };

    return <flex className='gap-10 retail-center fit-children'>
        <FluxCard title='Retails' className={`no-padding retail-selection${minimized ? ' minimized' : ''}`}
            footer={<flex className='fit'>
                <Button title='Add new retail' className={'success align-left'} onClick={() => {
                    addNewRetail();
                }} />
                <flex className='align-right'>
                    <ActionButton title='Refresh' icon='refresh' onClick={() => {
                        setUpdate(Date.now());
                    }} />
                </flex>
            </flex>}
            buttons={<flex className='align-right' onClick={() => minimize(!minimized)}><Icon icon={minimized ? 'right-to-line' : 'left-to-line'} type='fa-light' /></flex>}>
            <RetailTree onReady={(r) => setRetails(r)} onSelected={(item) => selectRetail(item)} key={update} />
        </FluxCard>
        <Retail id={retail?.id} key={retail?.id} />
    </flex>;
};