import React, { useEffect, useState } from 'react';
import { FluxForm } from '../../../components/form/FluxForm';
import { InputTypes } from '../../../components/form/InputTypes';



const filterModel = {
    fields: [
        {
            name: 'Regions',
            title: 'Regions',
            type: InputTypes.Select,
            multiple: true,
            nullable: true,
            values: []
        },
        {
            name: 'Venues',
            title: 'Venues',
            type: InputTypes.Select,
            multiple: true,
            nullable: true,
            values: []
        },
        {
            name: 'Events',
            title: 'Events',
            type: InputTypes.Select,
            multiple: true,
            nullable: true,
            values: []
        },
        {
            name: 'BetType',
            title: 'Bet Type',
            type: InputTypes.Select,
            multiple: false,
            nullable: true,
            values: [
                {
                    title: 'Prematch',
                    value: 0,
                },
                {
                    title: 'Live',
                    value: 1,
                }
            ]
        },
        {
            name: 'TicketType',
            title: 'Ticket Types',
            type: InputTypes.Select,
            multiple: false,
            nullable: true,
            values: [
                {
                    title: 'Single',
                    value: 0,
                },
                {
                    title: 'Multi',
                    value: 1,
                }
            ]
        },
        {
            name: 'Stake',
            title: 'Stake',
            type: InputTypes.Number,
            between: true,
            value: {
                start: 0,
                end: 0
            }
        },
        {
            name: 'PossibleWin',
            title: 'Potential Win',
            type: InputTypes.Number,
            between: true,
            value: {
                start: 0,
                end: 0
            }
        },
        {
            name: 'Odds',
            title: 'Odds',
            type: InputTypes.Number,
            between: true,
            value: {
                start: 0,
                end: 0
            }
        },
        {
            name: 'Legs',
            title: 'Number of legs',
            type: InputTypes.Number,
            between: true,
            value: {
                start: 0,
                end: 0
            }
        }
    ]
};


export const TickerFilters = (props) => {
    const { visible, regions, venues, events, onFilter } = { ...props };

    const [model, setModel] = useState(null);

    useEffect(() => {
        var copy = { ...filterModel };

        copy.fields.find(x => x.name == 'Regions').values = Object.keys(regions).map(x => { return { title: x.split(':').reverse()[0], value: x.split(':')[0] }; });
        copy.fields.find(x => x.name == 'Venues').values = Object.keys(venues).map(x => { return { title: x.split(':').reverse()[0], value: x.split(':')[0] }; });
        copy.fields.find(x => x.name == 'Events').values = Object.keys(events).map(x => { return { title: x.split(':').reverse()[0], value: x.split(':')[0] }; });
        setModel(copy);
    }, []);

    if (!visible) return <></>;

    if (!model) return <></>;
    return <flex className='vertical gap-10'>
        <FluxForm model={model} className='horizontal' columns={5} onSubmit={(data) => {
            onFilter(data);
        }} />
    </flex>;
};