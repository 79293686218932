import React, { useEffect, useState } from 'react';
import { toPascalCase } from '../../../../components/Centrum/helpers';
import { Icon } from '../../../components/icon/Icon';
import { lang } from '../../../lib/Localization/language';
import { addCredit, editRetail, removeCredit } from '../../../actions/RetailActions';
import { Button } from '../../../components/button/Button';
import { FluxCard } from '../../../components/card/FluxCard';
import { Currency } from '../../../components/currency/Currency';
import { FluxTab } from '../../../components/tab/FluxTab';
import { API } from '../../../lib/API/Api';
import { RetailEndpoints } from '../../../lib/API/Endpoints';
import { SearchPlayerBetHistory } from '../../Players/SearchPlayerBetHistory/SearchPlayerBetHistory';
import { SearchPlayerGameHistory } from '../../Players/SearchPlayerGameHistory/SearchPlayerGameHistory';
import { SearchPlayers } from '../../Players/SearchPlayers/SearchPlayers';
import { RetailCasinoReportSummary } from '../../Reports/Retails/ReportRetailCasinoSummary';
import { RetailConfiguration } from '../RetailConfiguration/RetailConfiguration';
import { SearchRetailTransactions } from '../SearchRetailTransactions/SearchRetailTransactions';
import { SearchRetailUsers } from './SearchRetailUsers';

export const Retail = (props) => {
    const { id } = { ...props };

    const [retail, setRetail] = useState(null);

    useEffect(() => {
        if (!id) return;
        API.post(`${RetailEndpoints.Get}/${id}`).then((result) => {
            setRetail(toPascalCase(result.result));
        });
    }, []);


    var tabButtons = [
        {
            title: 'Users'
        },
        {
            title: 'Players'
        },
        {
            title: 'Retail Transactions'
        },
        {
            title: 'Game History'
        },
        {
            title: 'Bet History'
        },
        {
            title: 'Casino Revenues'
        },
        {
            title: 'Settlements'
        },
        {
            title: 'Configuration'
        }
    ];


    if (!retail) return <></>;
    return <flex className='vertical gap-10 overflow-hidden overflow-y-auto'>
        <flex className='gap-5 columns-3'>
            <FluxCard title='Retail' buttons={
                <flex className={`align-right gap-5 padding-left clickable`} onClick={() => editRetail(retail.Id, (result) => {
                    setRetail(result);
                })}>
                    <Icon icon='ellipsis-vertical' type='fa-light' />
                    <Icon icon='pen' type='fa-light' />
                    <span>{lang('Edit')}</span>
                </flex>
            }>
                <flex className='vertical gap-20'>
                    <flex className='gap-10 columns-2 fit'>
                        <flex className='vertical'>
                            <label className='medium bolder'>{lang('Id')}</label>
                            <span>{retail.Id}</span>
                        </flex>
                        <flex className='vertical'>
                            <label className='medium bolder'>{lang('Code')}</label>
                            <span>{retail.Code}</span>
                        </flex>
                    </flex>
                    <flex className='gap-10 columns-2 fit'>
                        <flex className='vertical'>
                            <label className='medium bolder'>{lang('Name')}</label>
                            <span>{retail.Name}</span>
                        </flex>
                    </flex>
                    <flex className='gap-10 fit'>
                        <flex className='vertical'>
                            <label className='medium bolder'>{lang('Description')}</label>
                            <span>{retail.Description ?? 'no description'}</span>
                        </flex>
                    </flex>
                </flex>
            </FluxCard>
            <FluxCard title={lang('Balance')}
                footer={
                    <flex className='gap-10 center'>
                        <Button title='Deposit' onClick={() => {
                            addCredit(retail, (data, result) => {
                                var copy = { ...retail };
                                copy.Balance = result.result.balance;
                                setRetail(copy);
                            })
                        }} />
                        <Button title='Withdraw' onClick={() => {
                            removeCredit(retail, (data, result) => {
                                var copy = { ...retail };
                                copy.Balance = result.result.balance;
                                setRetail(copy);
                            })
                        }} />
                    </flex>
                }>
                <h2 className='center'>{<Currency amount={retail.Balance} sign={retail.DefaultCurrency} />}</h2>
            </FluxCard>
            <FluxCard title='Settlement'>
                <flex className='gap-10 columns-2'>
                    <flex className='vertical'>
                        <label className='medium'>{lang('Last Settlement Date')}</label>
                        <span>{retail.LastSettlementAmount ? new Date(retail.LastSettlement).toLocaleDateString('en-Gb', { hours12: false }) : lang('no settlement yet')}</span>
                    </flex>
                    <flex className='vertical'>
                        <label className='medium'>{lang('Last Settlement')}</label>
                        <span>{retail.LastSettlementAmount ? <Currency amount={retail.LastSettlementAmount} sign={retail.DefaultCurrency} /> : '-'}</span>
                    </flex>
                </flex>
            </FluxCard>
        </flex>
        <FluxTab buttons={tabButtons} className='gap-10 fx-shadow fx-radius rounded-buttons'>
            <SearchRetailUsers retail={retail} />
            <SearchPlayers retail={retail} />
            <SearchRetailTransactions retail={retail} />
            <SearchPlayerGameHistory retail={retail} />
            <SearchPlayerBetHistory retail={retail} />
            <RetailCasinoReportSummary retail={retail} />
            <flex></flex>
            <RetailConfiguration retail={retail} />
        </FluxTab>
    </flex>;
};