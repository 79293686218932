import React from 'react';

export const DateTime = (props) => {
    const { date, time, seconds, vertical, className } = { ...props };
    var converted = new Date(date.indexOf('Z') == '-1' ? date + 'Z' : date);
    var localeDate = converted.toLocaleDateString('en-Gb');
    var localeTime = converted.toLocaleTimeString('en-Gb');
    var localeTimeWithoutSeconds = converted.toLocaleTimeString('en-Gb');
    if (!seconds) {
        var data = localeTimeWithoutSeconds.split(':');
        localeTimeWithoutSeconds = data[0] + ':' + data[1];
    }

    if (time) {
        return <flex className={`${vertical ? 'vertical' : 'gap-5'} ${className ?? ''}`}>
            <span>{localeDate}</span>
            {seconds && <span>{localeTime}</span>}
            {!seconds && <span>{localeTimeWithoutSeconds}</span>}
        </flex>;
    }

    return <flex className={`${vertical ? 'vertical' : 'gap-5'} ${className ?? ''}`}>
        <span>{localeDate}</span>
    </flex>;
};