import React from 'react';
import { FluxAction } from '../../components/application/FluxAction';
import { FluxPage } from '../../components/page/FluxPage';
import { ActionTypes } from '../../lib/ActionManager/FluxActions';
import { SearchPlayerBetHistory } from '../Players/SearchPlayerBetHistory/SearchPlayerBetHistory';
import { SearchEvents } from './SearchEvents/SearchEvents';
import { BetMonitorRiskLevels } from './BetMonitor/BetMonitorRiskLevels';
import { RiskLimitsPage } from '../../components/sports/RiskManagement/RiskLimits/RiskLimitsPage';
import { SportLimitsWithRiskLevels } from '../../components/sports/RiskManagement/SportsLimits/SportLimitsWithRiskLevels';
import { SportPlayerLimitsWithRiskLevels } from '../../components/sports/RiskManagement/SportsLimits/SportPlayerLimitsWithRiskLevels';
import { SearchRejectedTickets } from '../Players/SearchRejectedTickets/SearchRejectedTickets';
import { SearchSportSettlementReport } from '../Players/SearchSportSettlementReport/SearchSportSettlementReport';
import { EventLiabilitiesMonitorRiskLevels } from './BetMonitor/EventLiabilitiesMonitorRiskLevels';
import { UnsettledTicketsMonitorRiskLevels } from './BetMonitor/UnsettledTicketsMonitorRiskLevels';
import { TraderToolsPage } from '../../components/sports/TraderTools/TraderToolsPage';

export const SportsPage = () => {
    return <FluxPage action={ActionTypes.Sports}>
        <FluxAction action={ActionTypes.SportBettingTickets}><SearchPlayerBetHistory searchOnStart /></FluxAction>
        <FluxAction action={ActionTypes.RiskManagementConfigureSportGeneralPlayerLimits}> <SportPlayerLimitsWithRiskLevels /></FluxAction>
        <FluxAction action={ActionTypes.RiskManagementConfigureSportLimits}> <SportLimitsWithRiskLevels /></FluxAction>
        <FluxAction action={ActionTypes.SportsbookEvents}><SearchEvents /></FluxAction>
        <FluxAction action={ActionTypes.RiskLevelsConfiguration}><RiskLimitsPage /></FluxAction>
        <FluxAction action={ActionTypes.SportPendingTickets}><SearchPlayerBetHistory searchOnStart pending /></FluxAction>
        <FluxAction action={ActionTypes.RiskManagementBetMonitor}><BetMonitorRiskLevels /></FluxAction>
        <FluxAction action={ActionTypes.RiskManagementEventLiabilities}><EventLiabilitiesMonitorRiskLevels /></FluxAction>
        <FluxAction action={ActionTypes.RiskManagementUnsettledTickets}><UnsettledTicketsMonitorRiskLevels /></FluxAction>
        <FluxAction action={ActionTypes.SportRejectedTickets}><SearchRejectedTickets providerId={42000} /></FluxAction>
        <FluxAction action={ActionTypes.SportResettlementReports}><SearchSportSettlementReport /></FluxAction>
        <FluxAction action={ActionTypes.TraderToolsEventBrowser}><TraderToolsPage /></FluxAction>
    </FluxPage>;
};
