import './_date-input.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { addSeconds } from 'date-fns';
import { Button } from '../../button/Button';
import { lang } from '../../../lib/Localization/language';
import { InputTypes } from '../InputTypes';

const Ranges = {
    Today: 1,
    Yesterday: 2,
    ThisWeek: 3,
    LastWeek: 4,
    ThisMonth: 5,
    LastMonth: 6,
    Tomorrow: 7
};


export const DateRange = (props) => {
    const onChange = (converted) => {
        var start = null;
        var end = null;
        switch (converted) {
            case Ranges.Today:
                start = DateHelpers.today();
                end = DateHelpers.tonight();
                break;
            case Ranges.Yesterday:
                start = DateHelpers.addDay(DateHelpers.today(), -1);
                end = addSeconds(DateHelpers.today(), -1);
                break;
            case Ranges.ThisWeek:
                start = DateHelpers.startOfWeek();
                end = DateHelpers.tonight();
                break;
            case Ranges.LastWeek:
                start = DateHelpers.addDay(DateHelpers.startOfWeek(), -7);
                end = addSeconds(DateHelpers.startOfWeek(), -1);
                break;
            case Ranges.ThisMonth:
                start = DateHelpers.startOfThisMonth();
                end = DateHelpers.tonight();
                break;
            case Ranges.LastMonth:
                start = DateHelpers.addMonth(DateHelpers.startOfThisMonth(), -1);
                end = addSeconds(DateHelpers.startOfThisMonth(), -1);
                break;
            case Ranges.Tomorrow:
                start = addSeconds(DateHelpers.tonight(), 1);
                end = DateHelpers.addDay(DateHelpers.tonight(), 1);
                break;
            default:
                console.log(converted);
                break;
        }

        if (props.onChange) {
            var startField = { ...props.startField };
            startField.value = start;

            var endField = { ...props.endField };
            endField.value = end;

            props.onChange([startField, endField]);
        }
    };

    if ((props?.startField?.type !== 'date' && props?.startField?.type !== 'datetime')
        || (props?.startField?.type !== 'date' && props?.startField?.type !== 'datetime')
    ) {
        return <></>;
    }

    return <flex className='between-date-selector'>
        <Button className='small' title={lang('Today')} onClick={() => onChange(Ranges.Today)} />
        {props.tomorrow && <Button className='small' title={lang('Tomorrow')} onClick={() => onChange(Ranges.Tomorrow)} />}
        <Button className='small' title={lang('Yesterday')} onClick={() => onChange(Ranges.Yesterday)} />
        <Button className='small' title={lang('This week')} onClick={() => onChange(Ranges.ThisWeek)} />
        <Button className='small' title={lang('Last week')} onClick={() => onChange(Ranges.LastWeek)} />
        <Button className='small' title={lang('This month')} onClick={() => onChange(Ranges.ThisMonth)} />
        <Button className='small' title={lang('Last month')} onClick={() => onChange(Ranges.LastMonth)} />
    </flex>;
};

DateRange.propTypes = {
    startField: PropTypes.object,
    selected: PropTypes.string,
    endField: PropTypes.object,
    onChange: PropTypes.func,
    tomorrow: PropTypes.bool
};



export const NumberField = (props) => {
    const { defaultValue, min, max, onChange, padding, className, maxLength } = { ...props };
    const [value, setValue] = useState(defaultValue);

    const ref = React.createRef();

    useEffect(() => {
        var converted = parseInt(defaultValue);
        if (!isNaN(converted)) {
            converted = padding ? converted.toString().padStart(max.toString().length, '0') : converted;
        } else {
            //converted = padding ? '0'.padStart(max.toString().length, '0') : '0';
            converted = defaultValue;
        }

        if (!isNaN(converted) && min) {
            //
        }
        if (converted.length > maxLength) {
            converted = converted.substring(0, maxLength);
        }


        setValue(converted);
    }, [defaultValue]);

    /*
    const validate = (v) => {
        var converted = parseInt(v);
        if (isNaN(v)) return value;
        if (converted > max) return value;
        if (converted < min) {
            converted = value;
        }
        return converted;
    };
    */

    useEffect(() => {
        if (value === undefined) return;
        if (isNaN(parseFloat(value))) return;
        if (onChange) {
            onChange(value);
        }
    }, [value]);

    return <input className={`flux-input ${className}`} type='number'
        defaultValue={defaultValue}
        value={value}
        ref={ref}
        onChange={(e) => {
            setValue(e.target.value);
        }}
        onBlur={(e) => {
            setValue(e.target.value);
        }} />;
};


export const DateTimeInput = (props) => {
    const [dateValue, setDateValue] = useState(null);
    const field = props.field;


    useEffect(() => {
        if (dateValue) {
            var currentValue = props.data ? props.data[field.name] : null;
            if (currentValue == null) {
                //
            } else {

                try {
                    if (new Date(currentValue).toISOString() == dateValue.converted.toISOString()) {
                        return;
                    }
                } catch (err) {
                    //
                }
            }
        }

        var converted = field.value;
        if (props.data && props.data[field.name]) {
            converted = props.data[field.name];
        }


        try {
            converted = new Date(converted);
        } catch (err) {
            //
        }

        if (typeof converted == 'object') {
            try {
                if (converted == 'Invalid Date') {
                    return;
                }

                var year = converted.getFullYear();
                var month = (converted.getMonth() + 1).toString().padStart(2, '0');
                var day = converted.getDate().toString().padStart(2, '0');
              
                var hour = converted.getHours().toString().padStart(2, '0');
                var minute = converted.getMinutes().toString().padStart(2, '0');

                var copy = {
                    day: day,
                    month: month,
                    year: year,
                    hours: hour,
                    minutes: minute
                };

                copy.converted = setDate(copy);

                setDateValue(copy);
            } catch (err) {
                console.log(err);
            }
        } else {
            //
        }


    }, [props.data]);


    useEffect(() => {
        if (!dateValue) return;
        var converted = new Date(dateValue.converted);

        if (converted == 'Invalid Date') {
            return;
        }

        if (field.value?.toString() == dateValue.converted?.toString()) return;
        props.onChange(field, dateValue.converted);
    }, [dateValue]);

    const setDate = (copy) => {
        return new Date(`${copy.year.toString().padStart(4, '0')}-${copy.month.toString().padStart(2, '0')}-${copy.day.toString().padStart(2, '0')}T${copy.hours.toString().padStart(2, '0')}:${copy.minutes.toString().padStart(2, '0')}:00Z`);
    };

    if (!dateValue) return <></>;


    return <flex className={`gap-5 ${field.name}`}>
        <flex className={'date-inputs'}>
            <NumberField className='input-day' maxLength={2} min={1} max={31} value={dateValue.day} defaultValue={dateValue.day} onChange={(v) => {
                if (isNaN(v)) return;
                var copy = { ...dateValue };
                copy.day = parseFloat(v);
                copy.converted = setDate(copy);
                setDateValue(copy);
            }} padding />
            <span>/</span>
            <NumberField className='input-month' maxLength={2} min={1} max={12} value={dateValue.month} defaultValue={dateValue.month} onChange={(v) => {
                if (isNaN(v)) return;
                var copy = { ...dateValue };
                copy.month = v;
                copy.converted = setDate(copy);
                setDateValue(copy);

            }} padding />
            <span>/</span>
            <NumberField className='input-year' maxLength={4} min={1900} max={2048} value={dateValue.year} defaultValue={dateValue.year} onChange={(v) => {
                if (isNaN(v)) return;
                var copy = { ...dateValue };
                copy.year = v;
                copy.converted = setDate(copy);
                setDateValue(copy);

            }} padding />
        </flex>
        {
            field.type == InputTypes.DateTime && <flex className='time-inputs'>
                <NumberField className='input-hours' maxLength={2} min={0} max={23} value={dateValue.hours} defaultValue={dateValue.hours} onChange={(v) => {
                    if (isNaN(v)) return;
                    var copy = { ...dateValue };
                    copy.hours = v;
                    copy.converted = setDate(copy);
                    setDateValue(copy);
                }} padding />
                <span>:</span>
                <NumberField className='input-minutes' maxLength={2} min={0} max={59} value={dateValue.minutes} defaultValue={dateValue.minutes} onChange={(v) => {
                    if (isNaN(v)) return;
                    var copy = { ...dateValue };
                    copy.minutes = v;
                    copy.converted = setDate(copy);
                    setDateValue(copy);
                }} padding />
            </flex>
        }
    </flex >;
};

DateTimeInput.propTypes = {
    field: PropTypes.object,
    data: PropTypes.object,
    onChange: PropTypes.func
};
