import React from 'react';

import { FluxCard } from '../../../../../components/card/FluxCard';
import { FluxForm } from '../../../../../components/form/FluxForm';
import { InputTypes } from '../../../../../components/form/InputTypes';
import { NotificationType } from '../../../../../components/notification/FluxNotification';
import { Popper } from '../../../../../components/notification/FluxPopper';
import { PromotionEndpoints } from '../../../../../lib/API/Endpoints';
import { lang } from '../../../../../lib/Localization/language';
import { CurrencyModalField } from '../../../../../lib/Models/CurrencyModel';
import { BulkGeneratedPromotionCodes } from './BulkGeneratedPromotionCodes';


const model = (promoName, promotionId) => {
    var fourLetter = '';
    if (promoName && promoName.length >= 4) {
        fourLetter = promoName.substr(0, 4).toUpperCase();
    }
    return {
        fields: [
            {
                name: 'Quantity',
                title: 'Code Quantity',
                type: InputTypes.Number,
                max: 1000,
                min: 1,
                required: true
            },
            {
                name: 'IncludeFourLetter',
                title: `Include 4 letter (${fourLetter})`,
                type: InputTypes.Bool
            },
            {
                name: 'Prefix',
                title: 'Code Prefix',
                type: InputTypes.Text,
                maxLength: 4,
                placeholder: 'Optional - 4 letter'
            },
            {
                name: 'Amount',
                title: 'Amount',
                type: InputTypes.Number,
                required: true
            },
            CurrencyModalField,
            {
                name: 'PromotionId',
                hidden: true,
                value: promotionId,
                type: InputTypes.Number,
                required: true,
            },
            {
                name: 'PromotionName',
                hidden: true,
                value: promoName,
                required: true,
            }
        ]
    };
};


export const BulkPromotionCodeSettings = (props) => {
    const { promotion } = { ...props };


    if (!promotion || promotion.Id == 0) return <></>;
    return <flex className='vertical gap-20'>
        <FluxCard title={'Auto Code Generator'}>
            <flex className='vertical gap-10'>
                <FluxForm
                    model={model(promotion.Name, promotion.Id)}
                    columns={3}
                    endpoint={PromotionEndpoints.AutoGeneratePromotionCodes}
                    confirm={true}
                    confirmTitle={'Please confirm'}
                    confirmMessage={'Confirm generating codes'}
                    onSubmitComplete={() => {
                        Popper.pop(NotificationType.Success, lang('Codes generated.'));
                    }}
                />
            </flex>
        </FluxCard>
        <BulkGeneratedPromotionCodes {...props} />
    </flex>;
};