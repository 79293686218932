/* eslint-disable react/display-name */

import React, { useEffect, useState } from 'react';
import { FluxCard } from '../../../../components/card/FluxCard';
import { Table } from '../../../../components/table/Table';
import { Button } from '../../../../components/button/Button';


export const SportsTournamentRules = (props) => {
    const { promotion, onChange } = { ...props };
    const [configuration, setConfiguration] = useState(null);

    useEffect(() => {
        var c = { ...promotion.Configuration };

        if (!c.Rules) {
            c.Rules = {
                Ranges: []
            };
        }

        setConfiguration(c);
    }, []);


    useEffect(() => {
        if (!configuration) return;

        var copy = { ...promotion };
        copy.Configuration = configuration;
        onChange(copy);
    }, [configuration]);


    const deleteRow = (index) => {
        var copy = { ...configuration };
        var row = copy.Rules.Ranges[index];
        copy.Rules.Ranges = copy.Rules.Ranges.filter(x => x != row);

        copy.key = Date.now();
        setConfiguration(copy);

    };

    const addNewRow = () => {
        var copy = { ...configuration };
        var last = configuration.Rules.Ranges.reverse()[0];
        var lastRange = last ? last.To : 1.1;
        copy.Rules.Ranges.push({
            From: lastRange,
            To: 0,
            Points: 0
        });

        setConfiguration(copy);
    };


    const onChangeConfig = (e, row) => {
        var index = configuration.Rules.Ranges.findIndex(x => x == row);
        var copy = { ...configuration };
        row[e.target.name] = parseFloat(e.target.value);
        copy.Rules.Ranges[index] = row;
        setConfiguration(copy);
    };


    if (!configuration) return <></>;

    return <flex className='vertical gap-10'>
        <FluxCard title={'Tournament Rules'} className={'no-overflow'}>
            <flex className='vertical gap-10'>
                <flex>
                    <Button title='Add new range' className={'success'} onClick={() => {
                        addNewRow();
                    }} />
                </flex>
                <Table
                    data={configuration.Rules.Ranges.sort((a, b) => a.From > b.From ? 1 : -1)}
                    key={configuration.key}
                    model={{
                        fields: [
                            {
                                name: 'From',
                                title: 'Odds starting from',
                                render: (val, row) => {
                                    return <input type='number' name='From' defaultValue={val} onChange={(e) => onChangeConfig(e, row)} />;
                                }
                            },
                            {
                                name: 'To',
                                title: 'Max',
                                render: (val, row) => {
                                    return <input type='number' name='To' defaultValue={val} onChange={(e) => onChangeConfig(e, row)} />;
                                }
                            },
                            {
                                name: 'Points',
                                title: 'Points',
                                render: (val, row) => {
                                    return <input type='number' name='Points' defaultValue={val} onChange={(e) => onChangeConfig(e, row)} />;
                                }
                            },
                            {
                                name: 'buttons',
                                title: '',
                                render: (val, row) => {
                                    return <Button title='Remove' className='warning' onClick={() => {
                                        deleteRow(configuration.Rules.Ranges.findIndex(x => x.From == row.From && x.To == row.To && x.Points == row.Points));
                                    }} />;
                                }
                            }
                        ]
                    }} />
            </flex>
        </FluxCard>
    </flex>;
};