export const convertLSportIdToBetradarSportId = (id) => {
    switch (id) {
        case 6046: return 1;
        case 48242: return 2;
        case 154914: return 3;
        case 35232: return 4;
        case 530129: return 24;
        case 54094: return 5;
        case 35709: return 6;
        case 35706: return 7;
        case 687894: return 8;
        case 687889: return 9;
        case 154919: return 10;
        case 1149093: return 11;
        case 274791: return 12;
        case 46957: return 15;
        case 131506: return 16;
        case 1149099: return 17;
        case 262622: return 19;
        case 265917: return 20;
        case 452674: return 21;
        case 154923: return 22;
        case 154830: return 23;
        case 388764: return 26;
        case 1149100: return 27;
        case 307126: return 28;
        case 687887: return 29;
        case 1149121: return 30;
        case 1149113: return 32;
        case 687897: return 33;
        case 621569: return 34;
        case 1149097: return 35;
        case 1149103: return 36;
        case 1149104: return 37;
        case 1149120: return 39;
        case 1149094: return 40;
        case 261354: return 43;
        case 1149101: return 44;
        case 1149118: return 48;
        case 1149122: return 54;
        case 687888: return 55;
        case 687893: return 58;
        case 274792: return 59;
        case 687896: return 67;
        case 1149095: return 70;
        case 1149125: return 71;
        case 1149115: return 81;
        case 1149098: return 90;
        case 1149107: return 99;
        case 1149108: return 1149108;
        case 687890: return 107;
        case 1149112: return 108;
        case 1149119: return 129;
        case 687895: return 131;
        case 1149117: return 136;
        case 1149123: return 138;
        case 1149105: return 155;
        case 1149102: return 190;
        case 291987: return 291987;
        case 389537: return 389537;
        case 1149096: return 1149096;
        case 1149109: return 1149109;
        case 1149110: return 1149110;
        case 1149111: return 1149111;
        case 1149114: return 1149114;
        case 1149124: return 1149124;
        case 1149126: return 1149126;
        default:
            return id;
    }
};


export const convertBetradarIdToLSSportId = (id) => {
    switch (id) {
        case 1: return 6046;
        case 2: return 48242;
        case 3: return 154914;
        case 4: return 35232;
        case 24: return 530129;
        case 5: return 54094;
        case 6: return 35709;
        case 7: return 35706;
        case 8: return 687894;
        case 9: return 687889;
        case 10: return 154919;
        case 11: return 1149093;
        case 12: return 274791;
        case 15: return 46957;
        case 16: return 131506;
        case 17: return 1149099;
        case 19: return 262622;
        case 20: return 265917;
        case 21: return 452674;
        case 22: return 154923;
        case 23: return 154830;
        case 26: return 388764;
        case 27: return 1149100;
        case 28: return 307126;
        case 29: return 687887;
        case 30: return 1149121;
        case 32: return 1149113;
        case 33: return 687897;
        case 34: return 621569;
        case 35: return 1149097;
        case 36: return 1149103;
        case 37: return 1149104;
        case 39: return 1149120;
        case 40: return 1149094;
        case 43: return 261354;
        case 44: return 1149101;
        case 48: return 1149118;
        case 54: return 1149122;
        case 55: return 687888;
        case 58: return 687893;
        case 59: return 274792;
        case 67: return 687896;
        case 70: return 1149095;
        case 71: return 1149125;
        case 81: return 1149115;
        case 90: return 1149098;
        case 99: return 1149107;
        case 1149108: return 1149108;
        case 107: return 687890;
        case 108: return 1149112;
        case 129: return 1149119;
        case 131: return 687895;
        case 136: return 1149117;
        case 138: return 1149123;
        case 155: return 1149105;
        case 190: return 1149102;
        case 291987: return 291987;
        case 389537: return 389537;
        case 1149096: return 1149096;
        case 1149109: return 1149109;
        case 1149110: return 1149110;
        case 1149111: return 1149111;
        case 1149114: return 1149114;
        case 1149124: return 1149124;
        case 1149126: return 1149126;
        default:
            return id;
    }
};

