import './_assign-freespin.scss';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from '../../../components/application/FluxApp';
import { Button } from '../../../components/button/Button';
import { ActionButton, FluxCard } from '../../../components/card/FluxCard';
import { Icon } from '../../../components/icon/Icon';
import { FluxList } from '../../../components/list/FluxList';
import { FluxModal } from '../../../components/modal/FluxModal';
import { FluxNotice } from '../../../components/notification/FluxNotice';
import { FluxTab } from '../../../components/tab/FluxTab';
import { Table } from '../../../components/table/Table';
import { API } from '../../../lib/API/Api';
import { CMSEndpoints, DataEndpoints, PromotionEndpoints } from '../../../lib/API/Endpoints';
import { ProviderGameTypes } from '../../../lib/Enums/GameEnums';
import { lang } from '../../../lib/Localization/language';
import { SearchFreespinCampaigns } from '../SearchFreespinCampaigns/SearchFreespinCampaigns';
import { SearchPlayersForFreeSpin } from '../SearchPlayersForFreeSpin/SearchPlayersForFreeSpin';
import { CreateFreespinsWithCampaign } from './CreateFreespinsWithCampaign';
import { assignFreeSpinToSelectedPlayers, createFreeSpinCampaign } from './freespin-actions';



export const PromotionCasinoConfigurationSummary = (props) => {
    const { promotion } = { ...props };
    const [configuration, setConfiguration] = useState({});
    useEffect(() => {
        var c = {};
        try {
            c = JSON.parse(promotion.Configuration);
        } catch (err) {

        }

        setConfiguration(c?.games ?? {});
    }, []);

    const renderEnabledProviders = () => {
        if (!configuration.providers || Object.keys(configuration.providers).length == 0) {
            return lang('All Providers');
        }

        return <flex className='gap-5'>{Object.values(configuration.providers).map(x => {
            return <flex className='gap-5' key={x.id}>
                <span>{x.name}</span>
            </flex>;
        })
        }</flex>;
    };


    const renderEnabledGameTypes = () => {
        if (!configuration.gameTypes || Object.keys(configuration.gameTypes).length == 0) {
            return lang('All Game Types');
        }

        return <flex className='gap-5'>{Object.values(configuration.gameTypes).map(x => {
            return <flex className='gap-5' key={x.type}>
                <span>{ProviderGameTypes[x.type]}</span>
            </flex>;
        })
        }</flex>;
    };


    const renderDisabledGames = () => {
        if (!configuration.disabledGames || Object.keys(configuration.disabledGames).length == 0) {
            return lang('All games are enabled.');
        }

        /*
        return <flex className='gap-5 wrap'>{Object.values(configuration.disabledGames).map(x => {
            return <flex className='gap-5' key={x.gameId}>
                <span>{x.name}</span>
            </flex>;
        })
        }</flex>;
        */

        return <flex className='vertical'>
            <span>{lang('%1 games disabled', [Object.keys(configuration.disabledGames).length])}</span>
            <flex>
                <Button title='View' className='success' onClick={() => {
                    Modal.open(<FluxModal title='Disabled games'>
                        <Table model={{
                            fields: [
                                {
                                    name: 'Name',
                                    title: 'Name'
                                }
                            ]
                        }} data={Object.values(configuration.disabledGames).map(x => {
                            return {
                                Id: x.gameId,
                                Name: x.name
                            };
                        })} />
                    </FluxModal>);
                }} />
            </flex>
        </flex>
    };



    return <flex className='vertical gap-10' key={promotion?.id}>
        <flex className='vertical'>
            <label className='medium'>{lang('Enabled Game Types')}</label>
            {renderEnabledGameTypes()}
        </flex>
        <flex className='vertical'>
            <label className='medium'>{lang('Enabled Providers')}</label>
            {renderEnabledProviders()}
        </flex>
        <flex className='vertical'>
            <label className='medium'>{lang('Disabled Games')}</label>
            {renderDisabledGames()}
        </flex>
    </flex>

};

export const AssignFreeSpin = () => {
    const user = useSelector((state) => state.user.loginInformation);

    const [promotions, setPromotions] = useState(null);
    const [providers, setProviders] = useState(null);
    const [provider, selectProvider] = useState(null);
    const [promotion, selectPromotion] = useState(null);
    const [games, setGames] = useState(null);
    const [filteredGames, setFilteredGames] = useState(null);

    const [selectedCurrency, selectCurrency] = useState(null);
    const [currencies, setCurrencies] = useState(null);
    const [game, selectGame] = useState(null);


    const [availableFreeSpinConfigurations, setAvailableFreeSpinConfigurations] = useState(null)
    const [freespinGameConfiguration, selectFreespinGameConfiguration] = useState(null);
    const [campaign, selectCampaign] = useState(null);

    const [selectedPlayers, setPlayers] = useState(null);

    useEffect(() => {
        API.post(PromotionEndpoints.List, {
            fields: [
                {
                    name: 'Type',
                    value: 5,
                    exact: true,
                    and: true
                }
            ]
        }).then((result) => {
            setPromotions(result.result.sort((a, b) => a.Name > b.Name ? 1 : -1));

            API.post(CMSEndpoints.GetGames, {}, 'fetching games').then((result) => {
                var p = {};
                result.result.map(x => {
                    p[x.providerId] = {
                        title: x.providerName,
                        value: x.providerId
                    };
                });

                setProviders(p);
                setGames(result.result.filter(x => x.hasFreeSpin));

                API.post(DataEndpoints.EnabledCurrencies).then((currencyResult) => {
                    setCurrencies(currencyResult.result);
                });
            });
        });
    }, []);

    useEffect(() => {
        if (!promotion) return;
        prepareGameList();
    }, [promotion]);


    useEffect(() => {
        if (!game) {
            selectCurrency(null);
        } else {
            selectCurrency(currencies[0]);
        }
    }, [game]);

    useEffect(() => {
        if (!game) return;
        if (!selectedCurrency) return;

        API.post(`${PromotionEndpoints.FreeSpinConfiguration}/${game.gameId}/${selectedCurrency.CurrencyCode}`, null, 'please wait', 'Unable to fetch configuration').then((result) => {
            setAvailableFreeSpinConfigurations(result.result.sort((a, b) => a.bet > b.bet ? 1 : -1));
        });
    }, [selectedCurrency]);


    useEffect(() => {
        if (!games) return;
        var list = [...games];
        if (provider) {
            list = list.filter(x => {
                if (x.providerId == provider.value) return true;
                return false;
            });
        }
        prepareGameList();
    }, [provider]);

    const prepareGameList = () => {
        var c = null;
        try {
            c = JSON.parse(promotion.Configuration);
        } catch (err) {
            //
        }
        if (c) {
            var list = [...games].filter(x => x.hasFreeSpin);
            if (c.games?.gameTypes && Object.keys(c.games.gameTypes).length) {
                list = list.filter(x => {
                    if (!c.games.gameTypes[x.type]) return false;
                    return true;
                });
            }
            if (c.games?.providers && Object.keys(c.games.providers).length) {
                list = list.filter(x => {
                    if (!c.games.providers[x.providerId]) return false;
                    return true;
                });
            }

            if (c.games?.disabledGames && Object.keys(c.games.disabledGames).length) {
                list = list.filter(x => {
                    if (!c.games.disabledGames[x.gameId]) return true;
                    return false;
                });
            }


            if (provider) {
                list = list.filter(x => {
                    if (provider?.value != x.providerId) return false;
                    return true;
                });
            }



            var p = {};
            list.map(x => {
                p[x.providerId] = {
                    title: x.providerName,
                    value: x.providerId
                };
            });

            setProviders(p);
            setFilteredGames(list);
        }
    };


    const renderPlayerSelections = () => {
        return <flex className='gap-5 fit-children'>
            <FluxCard title='Selected Players' className={'no-padding'}
                footer={(!selectedPlayers || Object.values(selectedPlayers).length == 0) ? null : <flex className='center'>
                    <Button title='Assign' className='success' onClick={() => {
                        assignFreeSpinToSelectedPlayers(
                            campaign ? campaign.Currency : selectedCurrency.CurrencyCode,
                            campaign ? campaign.GameId : game.gameId,
                            campaign ? campaign.PromotionId : promotion,
                            selectedPlayers,
                            freespinGameConfiguration,
                            campaign);
                    }} />
                </flex>}
                buttons={<flex className='align-right'>
                    <ActionButton title='Clear' icon='times' onClick={() => {
                        setPlayers(null);
                    }} />
                </flex>}>
                <Table model={{
                    fields: [
                        {
                            name: 'Id',
                            title: 'Id'
                        },
                        {
                            name: 'Username',
                            title: 'Username'
                        }
                    ]
                }} data={selectedPlayers ? Object.values(selectedPlayers) : []} buttons={(row) => {
                    return <ActionButton title='Remove' icon='times' onClick={() => {
                        var copy = { ...selectedPlayers };
                        delete copy[row.Id];
                        setPlayers(copy);
                    }} />
                }} />
            </FluxCard>
            {(freespinGameConfiguration || campaign) && <SearchPlayersForFreeSpin operator={user.OperatorId} currency={campaign ? campaign.Currency : selectedCurrency.CurrencyCode} selectedPlayers={selectedPlayers} onSelectPlayers={(p) => {
                setPlayers(p);
            }} />}
            {(!freespinGameConfiguration && !campaign) && <FluxNotice type='information' title='Settings not selected.' description={'Please select a free spin configration settings from the list!'} />}
        </flex>;
    };



    if (campaign) {
        return <CreateFreespinsWithCampaign campaign={campaign} onCancel={() => {
            selectCampaign(null)
        }} >
            {renderPlayerSelections()}
        </CreateFreespinsWithCampaign>;
    }
    if (game && selectedCurrency) {
        return <flex className='vertical gap-10'>
            <FluxCard title={<React.Fragment>
                <span>{game.providerName}</span>
                <Icon icon='angle-right' type='fa-light' />
                <span>{game.name}</span>
            </React.Fragment>}
                className='no-padding'
                buttons={<ActionButton title='Cancel' icon='times' onClick={() => {
                    selectGame(null);
                }} />} footer={<flex className='align-left'>
                    <Button title='Create Campaign Using this Configuration' className={`success ${freespinGameConfiguration ? '' : 'disabled'}`} onClick={() => {
                        createFreeSpinCampaign(promotion.Id, selectedCurrency.CurrencyCode, game.gameId, game.providerId, game.name, freespinGameConfiguration);
                    }} />
                </flex>}>
                <flex className='vertical fx-border-bottom'>
                    <flex className='gap-10 padding'>
                        <span>{lang('Currency')}</span>
                        <select onChangeCapture={(e) => {
                            const val = e.target.value;
                            selectCurrency(currencies[parseInt(val)]);
                        }}>
                            {
                                currencies.map((key, index) => {
                                    return <option key={key} value={index}>{`(${key.CurrencyCode}) - ${key.Name}`}</option>;
                                })
                            }
                        </select>
                    </flex>
                    <flex className='padding vertical overflow-hidden overflow-y-auto max-height-400'>
                        <Table className='fx-shadow fx-borders sticky' model={{
                            fields: [
                                {
                                    name: 'bet',
                                    title: 'Bet'
                                },
                                {
                                    name: 'betCount',
                                    title: 'Bet Count',
                                    render: (val, row) => {
                                        if (row.bonusBuy) {
                                            return 'Will be defined by provider.';
                                        }
                                        return val ? val : '1 - 20';
                                    }
                                },
                                {
                                    name: 'freeBetId',
                                    title: 'Free Spin Id'
                                },
                            ]
                        }} data={availableFreeSpinConfigurations ?? []} buttons={(row) => {
                            if (freespinGameConfiguration == row) return <Button title='Selected' className={'disabled'} />;
                            return <Button title='Select' className='success' onClick={() => {
                                selectFreespinGameConfiguration(row);
                            }} />
                        }} />
                    </flex>
                </flex>
            </FluxCard>
            {renderPlayerSelections()}
        </flex >;
    }


    return <flex className={`${!games ? 'disabled assign-freespin' : ''}vertical gap-5 assign-freespin`}>
        <FluxTab className='gap-10 rounded-buttons overflow-hidden overflow-y-auto fx-radius' buttons={[
            {
                title: 'Promotions'
            },
            {
                title: 'Campaigns'
            }
        ]}>
            <flex className='gap-5 overflow-hidden fit-children'>
                <FluxCard title='Promotions' className={'no-padding promotions-list'}>
                    {promotions && <FluxList data={promotions ? promotions.filter(x => x.Enabled).map(x => {
                        return {
                            title: x.Name,
                            description: x.Description,
                            value: x.Id
                        };
                    }) : []} onSelected={(item) => item ? selectPromotion(promotions.find(x => x.Id == item.value)) : null} onRender={(x) => {
                        return <flex className='gap-10'>
                            <label className='medium'>{x.value}</label>
                            <flex className='vertical'>
                                <label className='medium'>{x.title}</label>
                                <span>{x.description}</span>
                            </flex>
                        </flex>;
                    }} />}
                </FluxCard>
                <FluxCard title='Games'>
                    <flex className='gap-10 vertical'>
                        {!promotion && <FluxNotice key={promotion?.id} type='information' title='Promotion not selected' description='Please select a promotion for the show enabled games.' />}
                        {promotion && <FluxCard key={promotion?.id} title={lang('%1 promotion configuration for games', [promotion?.Name])}>
                            <PromotionCasinoConfigurationSummary promotion={promotion} key={promotion.Id} />
                        </FluxCard>}
                        <flex className={`${!promotion ? 'disabled' : ''} gap-5 vertical`}>
                            <flex className='gap-5 padding-top padding-bottom'>
                                <label className='medium'>{lang('Game List')}</label>
                                <flex className='align-right'>
                                    {providers && <select value={provider?.value} defaultValue={provider?.value} onChange={(e) => {
                                        selectProvider(providers[parseInt(e.target.value)]);
                                    }}>
                                        <option>{lang('All')}</option>
                                        {Object.values(providers).sort((a, b) => a.title > b.title ? 1 : -1).map(x => {
                                            return <option key={x.value} value={x.value}>{x.title}</option>
                                        })}
                                    </select>
                                    }
                                </flex>
                            </flex>
                            <Table className={'fx-borders fx-shadow'} model={{
                                fields: [
                                    {
                                        name: 'gameId',
                                        title: 'Id'
                                    },
                                    {
                                        name: 'providerName',
                                        title: 'Provider'
                                    },
                                    {
                                        name: 'name',
                                        title: 'Game'
                                    },
                                    {
                                        name: 'type',
                                        title: 'Type',
                                        render: (val) => ProviderGameTypes[val]
                                    }
                                ]
                            }} data={filteredGames ?? []} buttons={(row) => {
                                return <Button title='Select' className={'success'} onClick={() => {
                                    selectGame(games.find(x => x.gameId == row.gameId));
                                }} />
                            }} />
                        </flex>
                    </flex>
                </FluxCard>
            </flex>
            <SearchFreespinCampaigns promotions={promotions} onSelect={(c) => {
                selectCampaign(c);
            }} />
        </FluxTab>
    </flex>;
};