import React, { useEffect, useState } from 'react';
import { ActionButton, FluxCard } from '../../../components/card/FluxCard';
import { API } from '../../../lib/API/Api';
import { PromotionEndpoints, SportTournamentEndpoints } from '../../../lib/API/Endpoints';
import { BonusType } from '../../../lib/Enums/BonusEnums';
import { Button } from '../../../components/button/Button';
import { appHistory } from '../../../..';
import { Currency, currency } from '../../../components/currency/Currency';
import { FluxTab } from '../../../components/tab/FluxTab';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { ActionTypes } from '../../../lib/ActionManager/FluxActions';
import { InputTypes } from '../../../components/form/InputTypes';
import { SearchPlayerBetHistory } from '../../Players/SearchPlayerBetHistory/SearchPlayerBetHistory';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';


const TournamentPlayerSearchFilters = {
    name: 'search-players',
    fields: [
        {
            name: 'Group',
            title: 'Player Group',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'myPlayerGroups',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'Id',
            max: 5,
            type: InputTypes.Number
        },
        {
            name: 'Code',
            type: InputTypes.Text,
            exact: true,
        },
        {
            name: 'Username',
            type: InputTypes.Text,
            exact: false
        },
        {
            name: 'Name',
            type: InputTypes.Text,
            exact: false
        },
        {
            name: 'Surname',
            type: InputTypes.Text,
            exact: false
        },
        {
            name: 'IdNumber',
            title: 'Id / Passport',
            type: InputTypes.Text,
            hidden: true,
            exact: true
        },
        {
            name: 'Email',
            title: 'Email',
            type: InputTypes.Text,
            hidden: true,
            exact: true
        },
        {
            name: 'Tag',
            title: 'Tags',
            type: InputTypes.Text,
            hidden: true
        },
        {
            name: 'LastLoginIp',
            title: 'Last Login Ip',
            type: InputTypes.Text,
            hidden: true,
        }
    ]
};


const TournamentPlayerResultModel = {
    fields: [

        {
            name: 'Id',
            type: InputTypes.Number,
            identity: true,
        },
        {
            name: 'PlayerGroup',
            title: 'Player Group'
        },
        {
            name: 'Code',
            type: InputTypes.Text,
        },
        {
            name: 'Created',
            title: 'Register Date',
            type: InputTypes.DateTime
        },
        {
            name: 'TournamentEntryDate',
            title: 'Tournament Register Date',
            type: InputTypes.DateTime
        },
        {
            name: 'RetailName',
            title: 'Retail Name'
        },
        {
            name: 'Affiliate',
            title: 'Affiliate'
        },
        {
            name: 'Username',
            type: InputTypes.Text
        },
        {
            name: 'Name',
            type: InputTypes.Text
        },
        {
            name: 'Surname',
            type: InputTypes.Text
        },
        {
            name: 'Tag',
            title: 'Tags',
            hidden: true
        },
        {
            name: 'LastLogin',
            title: 'Last Login Date',
            type: InputTypes.DateTime
        },
        {

            name: 'BonusBalance',
            title: 'Bonus Balance',
            type: InputTypes.Number,
            render: (val, data) => {
                return <Currency amount={val} sign={data.Currency} />;
            }
        },
        {

            name: 'Balance',
            type: InputTypes.Number,
            render: (val, data) => {
                return <Currency amount={val} sign={data.Currency} />;
            }
        }
    ]
};



export const Tournament = (props) => {
    const { tournament, onClose } = { ...props };
    const [stats, setStats] = useState(null);
    const [tab, selectTab] = useState(0);
    useEffect(() => {
        API.post(SportTournamentEndpoints.GetStats(tournament.Id), null).then((result) => {
            console.log(result.result);
            setStats(result.result);
        });
    }, []);

    /*
      totalPlayers: 5,
            totalTickets: 150,
            totalStake: 120,
            openTickets: 5,
            winTickets: 150,
    */
    let tabButtons = [
        {
            name: 'players',
            title: 'Players'
        },
        {
            name: 'leaderBoard',
            title: 'Leader Board'
        },
        {
            name: 'leaderBoard2',
            title: 'Daily Leader Board'
        },
        {
            name: 'Tickets',
            title: 'Tickets'
        }
    ];


    const onActionClicked = (action, player) => {
        switch (action.type) {
            case ActionTypes.PlayerProfile:
                appHistory.push(`/players/search?&id=${player.Id}`);
                break;

            default:
                console.log(action.type);
                break;
        }
    };


    return <flex className='vertical gap-10'>
        <FluxCard title={tournament.Name} buttons={<ActionButton title='Close' icon='times' onClick={() => onClose()} />}>
            <flex className='gap-10 fit-children'>
                <FluxCard title='Total Players'>
                    <span className='medium'>{stats?.totalPlayers}</span>
                </FluxCard>
                <FluxCard title='Total Tickets'>
                    <span className='medium'>{stats?.totalTickets}</span>
                </FluxCard>
                <FluxCard title='Open Tickets'>
                    <span className='medium'>{stats?.openTickets}</span>
                </FluxCard>
                <FluxCard title='Total Stake'>
                    <span className='medium'>{currency(stats?.totalStake, 2, 'EUR', false)}</span>
                </FluxCard>
                <FluxCard title='Win Tickets'>
                    <span className='medium'>{currency(stats?.winTickets, 2, 'EUR', false)}</span>
                </FluxCard>
                <FluxCard title='GGR'>
                    <span className='medium'>{currency(stats?.totalStake - stats?.winTickets, 2, 'EUR', false)}</span>
                </FluxCard>
            </flex>
        </FluxCard>
        <FluxTab buttons={tabButtons} onChange={(index) => {
            selectTab(index);
        }} className='overflow-hidden gap-10 fx-shadow fx-radius rounded-buttons'>
            {tab == 0 && <FluxSearch model={TournamentPlayerSearchFilters} resultModel={TournamentPlayerResultModel} endpoint={SportTournamentEndpoints.GetPlayers(tournament.Id)} searchOnStart actions2={ActionTypes.SearchPlayer} onActionClicked={onActionClicked.bind(this)} />}
            {tab == 1 && <FluxSearch filters={false} model={{ fields: [] }} resultModel={{
                fields: [
                    {
                        name: 'Username',
                        title: 'Username'
                    },
                    {
                        name: 'TotalSlipCounts',
                        title: 'Tickets'
                    },
                    {
                        name: 'TotalPoints',
                        title: 'Points'
                    }
                ]
            }} endpoint={SportTournamentEndpoints.GetLeaderboard(tournament.Id)} searchOnStart actions2={ActionTypes.SearchPlayer} onActionClicked={onActionClicked.bind(this)} />}
            {tab == 2 && <FluxSearch model={{
                fields: [
                    {
                        name: 'CloseDate',
                        title: 'Date',
                        type: InputTypes.DateTime,
                        between: true,
                        value: {
                            start: DateHelpers.yesterday(),
                            end: DateHelpers.tonight()
                        }
                    },
                ]
            }} resultModel={{
                fields: [
                    {
                        name: 'Username',
                        title: 'Username'
                    },
                    {
                        name: 'TotalSlipCounts',
                        title: 'Tickets'
                    },
                    {
                        name: 'TotalPoints',
                        title: 'Points'
                    }
                ]
            }} endpoint={SportTournamentEndpoints.GetLeaderboardBetweenDates(tournament.Id)} actions2={ActionTypes.SearchPlayer} onActionClicked={onActionClicked.bind(this)} />}
            {tab == 3 && <SearchPlayerBetHistory searchOnStart tournament={tournament} />}
        </FluxTab>
    </flex>;
}


export const TournamentCenter = () => {
    const [tournaments, setTournaments] = useState();
    const [promotion, selectPromotion] = useState();

    const [listenHistory, setListenHistory] = useState(null);

    useEffect(() => {
        setListenHistory(appHistory.listen(onHistoryChange));
        API.post(PromotionEndpoints.List, {}).then((result) => {
            setTournaments(result.result.filter(x => x.Type == 15));
        });

        return () => {
            if (listenHistory) listenHistory();
        };
    }, []);

    useEffect(() => {
        if (!tournaments) return;
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id'); // retail login
        if (!isNaN(parseFloat(id))) {
            selectPromotion(tournaments.find(x => x.Id == parseFloat(id)));
        }
    }, [tournaments]);

    useEffect(() => {
        if (promotion === undefined) return;
        if (!promotion) {
            appHistory.push('/promotions/tournament-center?&id=0');
            return;
        }
        appHistory.push(`/promotions/tournament-center?&id=${promotion.Id}`);
    }, [promotion]);

    const onHistoryChange = () => {
        console.log('a');
    };


    if (promotion) {
        return <Tournament tournament={promotion} onClose={() => selectPromotion(null)} />;
    }

    return <flex className='vertical gap-10 manage-promotions'>
        <flex className='vertical gap-20'>
            <div className='tournament-cards'>
                {tournaments?.filter((x) => x.Enabled).sort((a, b) => a.Type > b.Type ? 1 : -1).map(x => {
                    return <FluxCard title={x.Name} className={`${x.Enabled ? '' : 'not-enabled'}`} buttons={<span className={'align-right small'}>{BonusType[x.Type]}</span>} key={x.Id} footer={<flex className='gap-5'>
                        <Button title='Select' className={'success'} onClick={() => {
                            selectPromotion(x);
                        }} /></flex>}>
                        <span>{x.Description}</span>
                    </FluxCard>;
                })}
                {tournaments?.filter((x) => !x.Enabled).sort((a, b) => a.Type > b.Type ? 1 : -1).map(x => {
                    return <FluxCard title={x.Name} className={`${x.Enabled ? '' : 'not-enabled'}`} buttons={<span className={'align-right small'}>{BonusType[x.Type]}</span>} key={x.Id} footer={<flex className='gap-5'>
                        <Button title='Select' className={'success'} onClick={() => {
                            selectPromotion(x);
                        }} /></flex>}>
                        <span>{x.Description}</span>
                    </FluxCard>
                })}
            </div>
        </flex>
    </flex>
};