import React, { useEffect, useState } from 'react';
import { toSnakeCase } from '../../components/Centrum/helpers';
import { Icon } from '../components/icon/Icon';

import { lang } from '../lib/Localization/language';
import { Modal } from '../components/application/FluxApp';
import { currency } from '../components/currency/Currency';
import { FluxForm } from '../components/form/FluxForm';
import { InputTypes } from '../components/form/InputTypes';
import { ConfirmationModal } from '../components/modal/ConfirmationModal/ConfirmationModal';
import { FluxModal } from '../components/modal/FluxModal';
import { FluxNotice } from '../components/notification/FluxNotice';
import { NotificationType } from '../components/notification/FluxNotification';
import { API } from '../lib/API/Api';
import { PlayerEndpoints, PlayerPromotionEndpoints, PromotionEndpoints } from '../lib/API/Endpoints';
import { BonusType } from '../lib/Enums/BonusEnums';




const cashBonusModel = {
    fields: [
        {
            name: 'BonusId',
            title: 'Bonus',
            type: InputTypes.Select,
            required: true,
            data: {
                source: '/api/promotions/list',
                parameters: {},
                parser: (result) => {
                    var results = [];
                    result.result.filter(x => x.Enabled && x.Type == 8).map(x => {
                        results.push({ title: x.Name, value: x.Id });
                    });
                    return results;
                }
            }
        },
        {
            name: 'Amount',
            title: 'Amount',
            required: true,
            type: InputTypes.Number
        },
        {
            name: 'Notes',
            title: 'Notes',
            type: InputTypes.Textarea
        }
    ]
};







export const cashBonus = (player, handler) => {
    const confirmed = (data) => {
        var transactionData = { ...data };
        transactionData.PlayerId = player.id;
        transactionData.TransactionType = 0;
        transactionData.Currency = player.currency;

        API.post(PlayerPromotionEndpoints.AssignBonus, transactionData, 'please wait', 'unable to create transaction').then((result) => {
            Modal.close();
            handler(transactionData, result.result);
        });
    };
    Modal.open(<FluxModal title={'Cash Bonus'} onConfirm={confirmed}>
        <FluxForm className='tab-next' model={cashBonusModel}
            onSubmit={(data) => {
                confirmed(data);
            }}
            onCancel={() => Modal.close()}>
        </FluxForm>
    </FluxModal>
    );
};


const SelectBonus = (props) => {
    const { selectedPromotion, onSubmit } = { ...props };
    const [promotions, setPromotions] = useState(null);
    const [promotion, setPromotion] = useState(null);
    const [bonusData, setBonusData] = useState({});

    useEffect(() => {
        API.post(PromotionEndpoints.List, {}).then((result) => {
            var results = [];
            result.result.filter(x => x.Enabled).map(x => {
                if (x.Type !== 1 && x.Type !== 2 && x.Type !== 3 &&
                    x.Type !== 8 &&
                    x.Type !== 13 &&
                    x.Type !== 17 &&
                    x.Type != 18) return false;

                if (selectedPromotion && selectedPromotion.Id !== x.Id) {
                    return false;
                }
                results.push({
                    title: x.Name,
                    value: x.Id,
                    group: {
                        id: x.Type,
                        title: BonusType[x.Type],
                        data: x
                    },
                    configuration: JSON.parse(x.Configuration)
                });
            });

            setPromotions(results);
        });
    }, []);

    useEffect(() => {
        if (!promotions) return;

        setPromotion(promotions[0]);
    }, [promotions]);


    if (!promotions) return <></>;
    if (!promotion) return <></>;

    return <flex className='vertical gap-10'>
        <FluxForm className='tab-next'
            buttons={null}
            model={{
                fields: [
                    {
                        name: 'BonusId',
                        title: 'Bonus',
                        type: InputTypes.Select,
                        values: promotions,
                        value: promotion.value
                    }
                ]
            }} onChange={(data) => {
                var p = promotions.find(x => x.value == data.BonusId);
                setPromotion(p);

                var multiplier = parseFloat(p.configuration?.turnover?.multiplier);
                if (isNaN(multiplier)) {
                    multiplier = 'multiplier is not configured!';
                } else {
                    multiplier = `${multiplier}x`;
                }
                setBonusData({
                    BonusId: data.BonusId,
                    TurnoverMultiplier: multiplier,
                    key: Date.now()
                });
            }}>
        </FluxForm>
        <FluxForm className='tab-next'
            data={bonusData}
            key={bonusData.key}
            model={{
                fields: [
                    {
                        name: 'TurnoverMultiplier',
                        title: 'Turnover Multiplier',
                        value: bonusData.TurnoverMultiplier,
                        readonly: true
                    },
                    {
                        name: 'Amount',
                        title: 'Amount',
                        required: true,
                        type: InputTypes.Number
                    },
                    {
                        name: 'Notes',
                        title: 'Notes',
                        type: InputTypes.Textarea
                    }
                ]
            }}
            onSubmit={(data) => {
                var copy = { ...bonusData };
                copy.TurnoverMultiplier = copy.TurnoverMultiplier.replace('x');
                copy.TurnoverMultiplier = parseFloat(copy.TurnoverMultiplier);
                copy.Amount = data.Amount;
                copy.Notes = data.Notes;
                copy.DeductBonusAmount = promotion.configuration?.turnover?.deductDepositAmount;

                if (isNaN(copy.TurnoverMultiplier)) copy.TurnoverMultiplier = 0;
                onSubmit(copy);
            }}
            onCancel={() => Modal.close()}>
        </FluxForm>
    </flex>;
};

export const giveBonus = (player, handler) => {

    const confirmed = (data) => {
        var transactionData = { ...data };
        transactionData.PlayerId = player.id;
        transactionData.TransactionType = 0;
        transactionData.Currency = player.currency;

        API.post(PlayerPromotionEndpoints.AssignBonus, transactionData, 'please wait', 'unable to create transaction').then((result) => {
            Modal.close();
            handler(transactionData, result.result);
        });
    };

    Modal.open(<FluxModal title={'Give bonus to player'} onConfirm={confirmed}>
        <SelectBonus player={player} selectedPromotion={player.selectedPromotion} handler={handler} onSubmit={confirmed} />
    </FluxModal>
    );
};




export const completeBonus = (consumedPromotionInformation, handler) => {
    var bonus = toSnakeCase(consumedPromotionInformation.promotion);
    var payout = 0;


    var player = {
        playerId: consumedPromotionInformation.playerId,
        balance: consumedPromotionInformation.balance,
        bonusBalance: consumedPromotionInformation.bonusBalance,
        currency: consumedPromotionInformation.currency,
        consumedPromotionId: consumedPromotionInformation.consumedPromotionId,
    };
    const confirmed = (data) => {
        API.post(`${PlayerPromotionEndpoints.CompleteBonus}/${player.playerId}`, { amount: data.Amount, notes: data.Notes }).then(() => {
            if (handler) {
                Modal.close();
                handler();
            }
        });
    };


    var currencySign = consumedPromotionInformation.currency;

    var bet = 0;
    var discountSettings = null;
    var balance = consumedPromotionInformation.bonusWallet ? player.bonusBalance : player.balance;
    var configurationNotes = [];
    var isReadonly = false;
    var maxPayout = 0;


    try {
        var configuration = toSnakeCase(JSON.parse(bonus.configuration));
        if (configuration.sport && configuration.sport.enabled) {
            bet = consumedPromotionInformation.totalBet;

            if (configuration.turnover) {
                var multiplier = parseInt(configuration.turnover.multiplier);

                if (!isNaN(multiplier)) {
                    if (consumedPromotionInformation.totalBet > multiplier * consumedPromotionInformation.amount) {
                        payout = balance;
                        configurationNotes.push({ type: 'check', payload: `Player must have placed bet amount of ${multiplier}x${currency(consumedPromotionInformation.amount, 0, currencySign)}. Total bets placed is ${consumedPromotionInformation.totalBet}.` });
                        if (configuration.turnover.DeductBonusAmountWithdrawMultiplier) {
                            payout = balance - consumedPromotionInformation.amount;
                            configurationNotes.push({ type: 'check', payload: `Given bonus amount ${consumedPromotionInformation.amount} is deducted. ` });
                        }

                        payout = payout.toFixed(2);
                    }
                } else {
                    configurationNotes.push({ type: 'times', payload: `Player must have placed bet amount of ${multiplier}x${consumedPromotionInformation.amount}. Total bets placed is ${consumedPromotionInformation.totalBet}.` });
                }
            }
        }

        if (configuration.discountSettings && !configuration.discountSettings.useFixedAmount) {
            discountSettings = configuration.discountSettings && configuration.discountSettings.settings ? configuration.discountSettings.settings[currencySign] : null;


            if (discountSettings) {
                discountSettings = discountSettings.reverse().find(x => bet >= x.From);
                var maxPayoutCap = discountSettings.MaxPayoutCap;
                if (maxPayoutCap && balance > maxPayoutCap) {
                    balance = maxPayoutCap;
                    configurationNotes.push({ type: 'check', payload: `Discount settings max payout cap is ${maxPayoutCap}, players balance is ${balance} so I can only pay ${maxPayoutCap}.` });
                }
            }
        }



        if (configuration.payout?.payoutBonusAmount) {
            payout = player.bonusBalance;
            isReadonly = true;
            configurationNotes.push({ type: 'check', payload: 'Bonus is configured for paying the total bonus amount of players earnings.' });
        } else {
            payout = consumedPromotionInformation.amount;
            configurationNotes.push({ type: 'check', payload: 'Bonus is configured for paying the given bonus amount.' });
        }

        if (configuration.turnover?.deductDepositAmount) {
            payout = player.bonusBalance - consumedPromotionInformation.amount;
            configurationNotes.push({ type: 'check', payload: 'Given bonus amount will be deducted.' });
        }

        if (configuration.payout && configuration.payout.amount[consumedPromotionInformation.currency]) {
            if (configuration.payout.amount[consumedPromotionInformation.currency][0].Amount && parseFloat(configuration.payout.amount[consumedPromotionInformation.currency][0].Amount) > 0) {
                if (parseFloat(payout) > parseFloat(configuration.payout.amount[consumedPromotionInformation.currency][0].Amount)) {
                    maxPayout = configuration.payout.amount[consumedPromotionInformation.currency][0].Amount;
                    isReadonly = true;
                    configurationNotes.push({ type: 'times', payload: `Maximum payout amout is ${maxPayout}.` });
                }
            }
        }

        if (maxPayout > 0 && payout > maxPayout) {
            payout = maxPayout;
        }
    } catch (e) {
        //
    }

    var description = <flex className='vertical gap-5'>
        {
            configurationNotes.map((x, index) => {
                return <flex className='gap-5' key={index}>
                    <Icon icon={x.type} />
                    <span>{x.payload}</span>
                </flex>;
            })
        }
    </flex>;
    //consumedPromotionInformation.currency
    Modal.open(<FluxModal title={'Complete Bonus'} onConfirm={confirmed}>
        <FluxNotice type='information' title='Bonus rules' description={description} />
        <FluxForm className='tab-next' model={{
            fields: [
                {
                    name: 'Amount',
                    title: 'Amount',
                    type: InputTypes.Number,
                    value: payout,
                    required: true,
                    readonly: isReadonly
                },
                {
                    name: 'Notes',
                    title: 'Notes',
                    type: InputTypes.Textarea,
                    required: false
                }
            ]
        }} onSubmit={(data) => {
            confirmed(data);
        }} onCancel={() => Modal.close()}>
        </FluxForm>

    </FluxModal>
    );
};


export const bulkRemovePromotion = (items, handler) => {
    var players = [];
    items.map(x => {
        players.push(x.PlayerId);
    });

    var data = {};
    const confirmed = (d) => {


        API.post(PlayerPromotionEndpoints.BulkRemovePromotion, {
            players: players,
            notes: d.Notes
        }, 'please wait', 'unable to create transaction').then((result) => {
            handler(result.result);
        });
    };

    Modal.open(<ConfirmationModal title={'Bulk remove promotion'} onConfirm={() => confirmed(data)}>
        <flex className='vertical gap-10'>
            <FluxNotice type={NotificationType.Information} title={lang('Please confirm removing player bonus.')} description={lang('Given bonus amount will be removed from players wallet.')} />
            <FluxForm className='tab-next horizontal-fields'
                buttons={null}
                model={{
                    fields: [
                        {
                            name: 'Notes',
                            title: 'Notes',
                            type: InputTypes.Textarea,
                            required: true
                        }
                    ]
                }} onChange={(d) => data = d} onSubmit={(data) => {
                    confirmed(data);
                }} onCancel={() => Modal.close()}>
            </FluxForm>
        </flex>
    </ConfirmationModal >);
};



export const rejectPendingPromotion = (items, handler) => {
    var players = [];
    items.map(x => {
        players.push(x.PlayerId);
    });

    var data = {};
    const confirmed = (d) => {
        API.post(PlayerEndpoints.RejectPendingPromotions, {
            Players: players,
            Notes: d.Notes
        }, 'please wait', 'unable to create transaction').then((result) => {
            handler(result.result);
        });
    };

    Modal.open(<ConfirmationModal title={'Bulk remove pending promotions'} onConfirm={() => confirmed(data)}>
        <flex className='vertical gap-10'>
            <FluxNotice type={NotificationType.Information} title={lang('Please confirm removing pending promotions.')} />
            <FluxForm className='tab-next horizontal-fields'
                buttons={null}
                model={{
                    fields: [
                        {
                            name: 'Notes',
                            title: 'Notes',
                            type: InputTypes.Textarea
                        }
                    ]
                }} onChange={(d) => data = d} onSubmit={(data) => {
                    confirmed(data);
                }} onCancel={() => Modal.close()}>
            </FluxForm>
        </flex>
    </ConfirmationModal >);
};

export const removeBonus = (player, handler) => {

    var data = {};
    const confirmed = (d) => {
        var transactionData = { ...d };
        transactionData.PlayerId = player.id;
        transactionData.Currency = player.currency;

        API.post(`${PlayerPromotionEndpoints.RemoveBonus}/${player.id}`, transactionData, 'please wait', 'unable to create transaction').then((result) => {
            Modal.close();
            handler(transactionData, result.result);
        });
    };
    Modal.open(<FluxModal title={'Remove Bonus'} onConfirm={() => confirmed(data)}>
        <flex className='vertical gap-10'>
            <FluxNotice type={NotificationType.Information} title={lang('Please confirm removing player bonus.')} description={lang('Given bonus amount will be removed from players wallet.')} />
            <FluxForm className='tab-next horizontal-fields' model={{
                fields: [
                    {
                        name: 'Notes',
                        title: 'Notes',
                        type: InputTypes.Textarea,
                        required: true
                    }
                ]
            }} onChange={(d) => data = d} onSubmit={(data) => {
                confirmed(data);
            }} onCancel={() => Modal.close()}>
            </FluxForm>
        </flex>
    </FluxModal>
    );
};

export const openBonusBets = () => {

};