import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Icon } from '../../../../components/icon/Icon';
import { KPIEndpoints } from '../../../../../v2/Lib/Api/Api';
import { KPICard } from '../KPICard/KPICard';
import { KPIRanges, KPISelector } from '../KPISelector/KPISelector';
import PropTypes from 'prop-types';

export const GeneralKPI = (props) => {
    const [selectedKPI, selectKPI] = useState(KPIRanges.Today);
    const defaultCurrency = useSelector((state) => state.user.loginInformation.features.currency);
    const [selectedCurrency, selectCurrency] = useState(defaultCurrency);
    const [convertCurrency, toggleConvertCurrency] = useState(false);

    return <flex className={'vertical gap-5'}>
        <KPISelector range={selectedKPI}
            defaultCurrency={defaultCurrency}
            onSelectedKPI={(kpi) => {
                selectKPI(kpi);
                props.onSelectedKPI(kpi);
            }}
            onSelectedCurrency={(currency) => {
                selectCurrency(currency);
                props.onSelectedCurrency(currency);
            }}
            onToggleConvertCurrency={(toggle) => {
                toggleConvertCurrency(toggle);
                props.onToggleConvertCurrency(toggle);
            }}
        >
            <flex className='columns-5 gap-5'>
                <KPICard
                    name='players'
                    icon={<Icon icon='user' type='fa-light' />}
                    title='Players'
                    range={selectedKPI}
                    endpoint={KPIEndpoints.Players}
                    indicators={['Players']} />
                <KPICard
                    name='resolved-betting'
                    icon={<Icon icon='futbol' type='fa-light' />}
                    title='Betting (resolved)'
                    range={selectedKPI}
                    endpoint={KPIEndpoints.BettingGGR}
                    selectedCurrency={selectedCurrency}
                    convertCurrency={convertCurrency}
                    indicators={['Tickets', 'Stake', 'Win', 'GGR']}
                    indicatorTypes={['', 'currency', 'currency', 'currency']} />
                <KPICard
                    name='casino'
                    icon={<Icon icon='slot-machine' type='fa-light' />}
                    title='Casino'
                    range={selectedKPI}
                    endpoint={KPIEndpoints.CasinoGGR}
                    selectedCurrency={selectedCurrency}
                    convertCurrency={convertCurrency}
                    indicators={['Hands', 'Bet', 'Win', 'GGR']}
                    indicatorTypes={['', 'currency', 'currency', 'currency']} />
                <KPICard
                    name='deposits'
                    icon={<Icon icon='up-from-line' type='fa-light' />}
                    title='Deposits'
                    range={selectedKPI}
                    endpoint={KPIEndpoints.Deposits}
                    selectedCurrency={selectedCurrency}
                    convertCurrency={convertCurrency}
                    indicators={['Deposit']}
                    indicatorTypes={['currency']} />
                <KPICard
                    name='withdraws'
                    icon={<Icon icon='down-from-line' type='fa-light' />}
                    title='Withdraws'
                    range={selectedKPI}
                    endpoint={KPIEndpoints.Withdraws}
                    selectedCurrency={selectedCurrency}
                    convertCurrency={convertCurrency}
                    indicators={['Withdraw']}
                    indicatorTypes={['currency']} />
            </flex>
        </KPISelector>
    </flex>;
};


GeneralKPI.propTypes = {
    onSelectedKPI: PropTypes.func,
    onSelectedCurrency: PropTypes.func,
    onToggleConvertCurrency: PropTypes.func,
};