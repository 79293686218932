import './_image.scss';

import React, { useEffect, useState } from 'react';
import { lang } from '../../../../../lib/Localization/language';
import { Modal } from '../../../../../components/application/FluxApp';
import { Button } from '../../../../../components/button/Button';
import { ConfirmationModal } from '../../../../../components/modal/ConfirmationModal/ConfirmationModal';
import { ErrorModal } from '../../../../../components/modal/ErrorModal/ErrorModal';
import { FluxNotice } from '../../../../../components/notification/FluxNotice';
import { NotificationType } from '../../../../../components/notification/FluxNotification';
import { CMSEndpoints } from '../../../../../lib/API/Endpoints';
import { ImageUpload } from '../ImageUpload/ImageUpload';
import { Icon } from '../../../../../components/icon/Icon';



export const ImageContent = (props) => {
    let { image, prefix, page, onUpdate, fileExtension } = { ...props };
    const [targetFileExtension, setFileExtension] = useState(props.fileExtension);
    const [imageKey, setImageKey] = useState(null);

    useEffect(() => {
        const key = image ?? Date.now();
        setImageKey(key);
    }, []);


    const source = `content/${page}`;
    const prepareUpload = () => {
        var imageData = null;
        Modal.open(<ConfirmationModal title='Upload Image' confirmTitle='Upload' onConfirm={() => {
            var newKey = Date.now();
            const payload = {
                image: imageData.base64,
                path: `content/${page}`,
                name: `${newKey}.${fileExtension}`
            };

            return new Promise((resolve, reject) => {
                fetch(CMSEndpoints.CMSUploadImage, {
                    method: 'POST',
                    credentials: 'same-origin',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    processData: false,
                    body: JSON.stringify(payload)
                }).then(res => res.json())
                    .then((result) => {
                        if (result && result.result) {
                            Modal.close();
                            console.log(targetFileExtension);
                            setFileExtension(targetFileExtension);
                            onUpdate(`${newKey}.${targetFileExtension}`);
                            resolve(result);
                            setImageKey(newKey);
                        } else {
                            if (result && result.result?.error) {
                                throw result.result?.error?.message ?? 'Unable to upload image, service error. Please check the size of uploaded image and try again later.';
                            }
                            throw 'Unable to upload image, service error. Please check the size of uploaded image and try again later.';
                        }
                    }).catch(e => {
                        Modal.open(<ErrorModal title={'Image save failed'}><p>{lang(e)}</p></ErrorModal>);
                        reject(e);
                    });
            });
        }}>
            <ImageUpload onImageData={(data) => {
                try {
                    imageData = data;
                } catch (err) {
                    //
                    console.log(err);
                }
            }} />
        </ConfirmationModal>);
    };

    const removeImage = () => {
        Modal.open(<ConfirmationModal title='Remove Image?' onConfirm={() => {
            onUpdate(null);
        }}>
            <FluxNotice type={NotificationType.Warning} title='Please confirm removing image.' description={'Removed images can not be reverted and will be deleted from the website.'} />
        </ConfirmationModal>)
    };

    return <div className='image-content'>
        <div className='image'>
            {imageKey && <img src={`//cdn-${prefix}.tentangle.com/${source}/${imageKey}.${fileExtension}`} onLoad={() => { }} />}
        </div>
        <flex className='gap-5'>
            <flex className='gap-5' onClick={() => setFileExtension(targetFileExtension == 'png' ? 'jpg' : 'png')}><Icon icon={targetFileExtension == 'png' ? 'square-check' : 'square'} /><span>{lang('PNG')}</span></flex>
            <Button className='success' title='Upload Image' onClick={() => prepareUpload()} />
            {image && <Button className='warning' title='Remove Image' onClick={() => removeImage()} />}
        </flex>
    </div>;
};