import React from 'react';
import { toPascalCase } from '../../components/Centrum/helpers';
import { GenderSources } from '../../components/Centrum/_models/ModelEnums';
import { Icon } from '../components/icon/Icon';
import { HistoryEndpoints } from '../../v2/Lib/Api/Api';
import { lang } from '../lib/Localization/language';
import { Modal } from '../components/application/FluxApp';
import { FluxForm } from '../components/form/FluxForm';
import { InputTypes } from '../components/form/InputTypes';
import { FluxModal } from '../components/modal/FluxModal';
import { FluxNotice } from '../components/notification/FluxNotice';
import { NotificationType } from '../components/notification/FluxNotification';
import { Popper } from '../components/notification/FluxPopper';
import { API } from '../lib/API/Api';
import { PlayerRiskLevel } from '../lib/Enums/RiskEnums';
import { PlayerEndpoints } from '../lib/API/Endpoints';



export const sendMessageToPlayer = (player, handler) => {
    const confirmed = (data) => {
        var messageData = { ...data };
        messageData.PlayerId = player.id;
        messageData.Currency = player.currency;

        API.post(PlayerEndpoints.NotifyPlayer, messageData, 'please wait', 'unable to create transaction').then((result) => {
            Modal.close();
            handler(messageData, result.result);
        });
    };
    Modal.open(<FluxModal title={'Send Message to Player'} onConfirm={confirmed}>
        <FluxForm className='tab-next' model={{
            fields: [
                {
                    name: 'Title',
                    title: 'Title',
                    required: true
                },
                {
                    name: 'Message',
                    title: 'Message',
                    type: InputTypes.Textarea,
                    required: true
                }
            ]
        }} onSubmit={(data) => {
            confirmed(data);
        }} onCancel={() => Modal.close()}>
        </FluxForm>
    </FluxModal>
    );
};


export const editPlayer = (id, handler) => {
    const editPlayerModel = {
        sections: {
            'General': {
                icon: <Icon icon='cogs' type='fa-light' />,
                fields: [
                    {
                        name: 'Id',
                        hidden: true
                    },
                    {
                        name: 'Username',
                        type: InputTypes.Text,
                        readonly: true
                    },
                    {
                        name: 'Code',
                        title: 'Code'
                    },
                    {
                        name: 'Group',
                        title: 'Player Group',
                        type: InputTypes.Select,
                        data: {
                            source: 'myPlayerGroups',
                            key: 'Id',
                            title: ['Name']
                        },
                        required: true
                    },
                    {
                        name: 'Tag',
                        title: 'Tags',
                        type: InputTypes.Text
                    },
                    {
                        name: 'Language',
                        type: InputTypes.Select,
                        data: {
                            source: 'languages/available',
                            key: 'iso3166',
                            title: ['name']
                        }
                    }
                ]
            },
            'Personal Information': {
                icon: <Icon icon='user-pen' type='fa-light' />,
                fields: [
                    {
                        name: 'IdNumber',
                        type: InputTypes.Text,
                        title: 'Id Number',
                        access: 'UpdatePlayerDetails'
                    },
                    {
                        name: 'Name',
                        type: InputTypes.Text,
                        required: true
                    },
                    {
                        name: 'Surname',
                        type: InputTypes.Text,
                        required: true
                    },
                    {
                        name: 'BirthDay',
                        title: 'Birthday',
                        type: InputTypes.Date
                    },
                    {
                        name: 'Gender',
                        type: InputTypes.Select,
                        values: GenderSources
                    }
                ]
            },
            'Contact Information': {
                icon: <Icon icon='address-card' type='fa-light' />,
                fields: [
                    {
                        name: 'Email',
                        type: InputTypes.Text,
                        required: true
                    },
                    {
                        name: 'Phone',
                        type: InputTypes.Text,
                        required: true
                    },
                    {
                        name: 'City',
                        type: InputTypes.Text,
                        required: true
                    },
                    {
                        name: 'Address',
                        type: InputTypes.Text,
                        required: true
                    },
                    {
                        name: 'Country',
                        type: InputTypes.Select,
                        required: true,
                        data: {
                            source: 'EnabledCountries',
                            key: 'CountryCode',
                            title: ['Name']
                        }
                    }
                ]
            }
        }
    };

    API.post(`${PlayerEndpoints.Get}/${id}`).then((result) => {
        Modal.open(<FluxModal title='Edit Player'>
            <FluxForm
                data={toPascalCase(result.result)}
                columns={2}
                endpoint={PlayerEndpoints.Update}
                model={editPlayerModel}
                onCancel={() => Modal.close()}
                onSubmitComplete={(result, data) => {
                    if (handler) {
                        handler(result, data);
                    }
                    Modal.close();
                    Popper.pop(NotificationType.Information, null, lang('Player profile updated.'));
                }}
            />
        </FluxModal>);
    });
};




export const changePlayerRiskLevel = (player, handler) => {
    const confirmed = (data) => {
        API.post(`${PlayerEndpoints.ChangePlayerRisk}/${player.id}/${data.riskLevel}`, null, 'please wait', 'Unable to change Player Risk Level.').then((result) => {
            Modal.close();
            handler(data.riskLevel, result);
        });
    };
    Modal.open(<FluxModal title={'Change Player Risk Level'} onConfirm={confirmed}>
        <flex className='vertical gap-10'>
            <FluxNotice type={NotificationType.Information} title='Player Current Risk Level' description={PlayerRiskLevel[player.riskLevel]} />
            <FluxForm
                columns={2}
                model={{
                    fields: [
                        {
                            name: 'riskLevel',
                            title: 'New Risk Level',
                            type: InputTypes.MultipleSelect,
                            value: player.riskLevel,
                            values: {
                                'Low': 0,
                                'Medium': 1,
                                'High': 2
                            }
                        }
                    ]
                }}
                onSubmit={(data) => {
                    confirmed(data);
                }} onCancel={() => Modal.close()}
            />
        </flex>
    </FluxModal>
    );
};



export const openPlayerHandDetails = (id) => {
    API.post(`${HistoryEndpoints.CasinoHandDetailsWithHistoryId}/${id}`, null, 'please wait', 'unable to fetch game history').then((result) => {
        if (result.result.url) {
            window.open(result.result.url);
        }
    });
};