import React from 'react';
import PropTypes from 'prop-types';
import { ActionButton, FluxCard } from '../../../../components/card/FluxCard';
import { lang } from '../../../../lib/Localization/language';
import { currency } from '../../../../components/currency/Currency';
import { Button } from '../../../../components/button/Button';
import { appHistory } from '../../../../..';
import { useSelector } from 'react-redux';

export const PlayerPendingWithdraws = (props) => {
    const user = useSelector((state) => state.user.loginInformation);
    const player = props.player;

    return <FluxCard title={'Pending Withdraws'} buttons={player.playerStats?.pendingWithdraw > 0 && <ActionButton title='Show' icon='circle-info' onClick={() => {
        appHistory.push('/transactions/pending-withdraws');
    }} />}>
        {player.playerStats?.pendingWithdraw > 0 &&
            <flex className='gap-10 vertical fit fit-children'>
                <flex className='columns-2'>
                    <label className='medium bolder'>{lang('Amount')}</label>
                    <span>{currency(player.playerStats?.pendingWithdraw, 2, player.currency)}</span>
                </flex>
                <flex className='columns-2'>
                    <label className='medium bolder'>{lang('Date')}</label>
                    <span>{new Date(player.playerStats?.pendingWithdrawDate).toLocaleString('en-Gb', { hours12: false })}</span>
                </flex>
            </flex>
        }
        {
            player.playerStats?.pendingWithdraw == 0 && user.AffiliateId == 0 && <flex className='vertical gap-10 center'>
                <span className='center medium bold'>{lang('No pending withdraw found!')}</span>
                <Button title='Open transactions' className={'success'} onClick={() => {
                    appHistory.push('/transactions/pending-withdraws');
                }} />
            </flex>
        }
    </FluxCard>;
};

PlayerPendingWithdraws.propTypes = {
    player: PropTypes.object,
    onTransactionComplete: PropTypes.func
};
