import React, { useEffect, useState } from 'react';
import { ActionTypes } from '../../lib/ActionManager/FluxActions';
import { FluxAction } from '../../components/application/FluxAction';
import { FluxPage } from '../../components/page/FluxPage';
import { AffiliateGroups } from './Groups/AffiliateGroups';
import { Affiliates } from './Affiliates/Affiliates';
import { useSelector } from 'react-redux';
import { SearchPlayers } from '../Players/SearchPlayers/SearchPlayers';
import { SearchPlayerTransactions } from '../Players/SearchPlayerTransactions/SearchPlayerTransactions';
import { SearchPlayerGameHistory } from '../Players/SearchPlayerGameHistory/SearchPlayerGameHistory';
import { SearchPlayerBetHistory } from '../Players/SearchPlayerBetHistory/SearchPlayerBetHistory';
import { PlayerCenter } from '../Players/PlayerShortcuts/PlayerCenter';
import { SearchPlayerBonusTransactions } from '../Players/SearchPlayerBonusTransactions/SearchPlayerBonusTransactions';
import { SearchPlayerTransactionsSummary } from '../Players/SearchPlayerTransactionsSummary/SearchPlayerTransactionsSummary';

export const AffiliatesPage = () => {
    const user = useSelector((state) => state.user.loginInformation);
    const isAffiliate = user.AffiliateId > 0;

    const [playerId, setPlayerId] = useState(null);

    useEffect(() => {
        if (!isAffiliate) return;
        const urlParams = new URLSearchParams(window.location.search);
        const id = parseFloat(urlParams.get('id'));
        if (isNaN(id)) {
            setPlayerId(null);
            return;
        }
        setPlayerId(id);

    }, [window.location.search]);

    if (isAffiliate) {
        const affiliate = {
            Id: user.AffiliateId,
            Name: user.Name
        };

        return <FluxPage action={ActionTypes.AffiliatePlayers}>
            {playerId && <PlayerCenter />}
            {!playerId && <React.Fragment>
                <FluxAction action={ActionTypes.ListAffiliatePlayers}><SearchPlayers affiliate={affiliate} /></FluxAction>
                <FluxAction action={ActionTypes.SearchAffiliateTransactions}><SearchPlayerTransactions affiliate={affiliate} /></FluxAction>
                <FluxAction action={ActionTypes.SearchAffiliateTransactionsSummary}><SearchPlayerTransactionsSummary affiliate={affiliate} /></FluxAction>
                <FluxAction action={ActionTypes.SearchAffiliateBonus}><SearchPlayerBonusTransactions affiliate={affiliate} /></FluxAction>
                <FluxAction action={ActionTypes.SearchAffiliatePlayerGameHistory}><SearchPlayerGameHistory affiliate={affiliate} /></FluxAction>
                <FluxAction action={ActionTypes.SearchAffiliatePlayerOpenTickets}><SearchPlayerBetHistory affiliate={affiliate} /></FluxAction>
            </React.Fragment>
            }
        </FluxPage >;
    }
    return <FluxPage action={ActionTypes.Affiliates}>
        <FluxAction action={ActionTypes.ListAffiliateGroups}><AffiliateGroups /></FluxAction>
        <FluxAction action={ActionTypes.ListAffiliates}><Affiliates /></FluxAction>
    </FluxPage >;
};