/* eslint-disable react/display-name */

import React, { useEffect, useState } from 'react';

import { FluxCard } from '../../../../components/card/FluxCard';
import { Table } from '../../../../components/table/Table';
import { FluxForm } from '../../../../components/form/FluxForm';
import { InputTypes } from '../../../../components/form/InputTypes';


export const RefillSettings = (props) => {
    const { promotion, currencies, onChange } = { ...props };
    const [configuration, setConfiguration] = useState(null);


    useEffect(() => {

        var c = { ...promotion.Configuration };
        if (!c.RefillSettings) {
            c.RefillSettings = {
                BonusWallet: false,
                Prize: {}
            };
        }

        currencies.map(x => {
            if (!c.RefillSettings.Prize[x.CurrencyCode]) {
                c.RefillSettings.Prize[x.CurrencyCode] = {
                    Currency: x.CurrencyCode,
                    Prize: 0
                };
            }
        });

        setConfiguration(c);

    }, []);

    useEffect(() => {
        if (!configuration) return;
        var copy = { ...promotion };
        copy.Configuration = configuration;
        onChange(copy);
    }, [configuration]);


    const updateRow = (e, currency) => {
        var value = parseFloat(e.target.value);
        var name = e.target.name;
        var copy = { ...configuration };
        copy.RefillSettings.Prize[currency][name] = value;
        setConfiguration(copy);
    };

    if (!configuration) return <></>;
    return <FluxCard>
        <flex className='vertical gap-10'>
            <FluxForm data={configuration?.RefillSettings} model={{
                fields: [
                    {
                        name: 'BonusWallet',
                        title: 'Bonus Wallet',
                        type: InputTypes.Bool
                    }
                ]
            }} columns={2} buttons={null} onChange={(data) => {
                var copy = { ...configuration };
                copy.RefillSettings.BonusWallet = data.BonusWallet;
                setConfiguration(copy);
            }} />
            <Table className='free-bet-prize-settings' model={{
                fields: [
                    {
                        name: 'Currency',
                        title: 'Currency'
                    },
                    {
                        name: 'Amount',
                        title: 'Amount',
                        render: (val, row) => {
                            return <input type='number' name={'Amount'} defaultValue={val} onChange={(e) => updateRow(e, row.Currency)} />;
                        }
                    }
                ]
            }} data={Object.values(configuration.RefillSettings.Prize)} />
        </flex>
    </FluxCard>;
};