import React from 'react';
import { FluxTab } from '../../../../../components/tab/FluxTab';
import { CampaignTypeSettings } from './CampaignTypeSettings';
import { CampaignSettings } from './Components/CampaignSettings';



export const Campaign = (props) => {
    var buttons = [
        {
            title: 'General Settings'
        },
        {
            title: 'Payout Settings'
        }
    ];

    return <FluxTab buttons={buttons} className='gap-10 fx-shadow fx-radius rounded-buttons campaign-settings'>
        <CampaignSettings {...props} />
        <CampaignTypeSettings {...props} />
    </FluxTab>;
};