import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { FluxSearch } from '../../../components/search/FluxSearch';
import { InputTypes } from '../../../components/form/InputTypes';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { lang } from '../../../lib/Localization/language';
import { Icon } from '../../../components/icon/Icon';
import { AffiliateEndpoints, TransactionEndpoints } from '../../../lib/API/Endpoints';
import { ActionTypes } from '../../../lib/ActionManager/FluxActions';
import { appHistory } from '../../../..';
import { Modal } from '../../../components/application/FluxApp';
import { FluxModal } from '../../../components/modal/FluxModal';
import { Button } from '../../../components/button/Button';
import { copyDataToClipboard } from '../../../lib/Helpers/StringHelper';
import { confirmTransaction, rejectTransction } from '../../../actions/TransactionActions';
import { FluxNotice } from '../../../components/notification/FluxNotice';
import { currency } from '../../../components/currency/Currency';


const model = {
    fields: [
        {
            name: 'UserId',
            title: 'Player Id'
        },
        {
            name: 'Username',
            title: 'Username'
        },
        {
            name: 'TransactionDate',
            title: 'Date',
            type: InputTypes.DateTime,
            between: true,
            value: {
                start: DateHelpers.yesterday(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'Id',
            title: 'Transaction Id',
            information: 'Our record'
        },
        {
            name: 'Type',
            title: 'Type',
            type: InputTypes.Select,
            nullable: true,
            multiple: true,
            exact: true,
            value: [0, 1],
            values: {
                0: 'Bonus Deposit',
                1: 'Bonus Withdraw'
            }
        }
    ]
};

const resultTransactionInformation = {
    fields: [
        {
            name: 'PlayerId',
            title: 'Player Id'
        },
        {
            name: 'Username'
        },
        {
            name: 'PromotionName',
            title: 'Promotion'
        },
        {
            name: 'Currency'
        },
        {
            name: 'Type',
            title: 'Type',
            type: InputTypes.Select,
            nullable: true,
            multiple: true,
            exact: true,
            value: [0, 1],
            values: {
                0: 'Bonus Deposit',
                1: 'Bonus Withdraw'
            }
        },
        {
            name: 'TotalAmount',
            title: 'Total Amount',
            type: InputTypes.Number,
            render: (val, row) => {
                return currency(val, 2, row.Currency);
            }
        }
    ]
};

export const SearchPlayerBonusTransactions = (props) => {
    const [title, setTitle] = useState('Search player transactions');
    const [actions, setActions] = useState(null);
    useEffect(() => {
        if (props.affiliate) {
            setActions([]);
            setTitle(<React.Fragment><span>{lang('Affiliate')}</span><Icon icon='chevron-right' type='fa-light' /><span>{props.affiliate.Name}</span><Icon icon='chevron-right' type='fa-light' /><span>{lang('Search player transactions')}</span></React.Fragment>);
            return;
        }
        setActions(ActionTypes.SearchTransactions);
    }, []);


    const endpoint = () => {
        if (props.affiliate) {
            return `${AffiliateEndpoints.PlayerBonusTransactions}/${props.affiliate.Id}`;
        }
        return TransactionEndpoints.Search;
    };



    const onActionClicked = (action, transaction, selection) => {
        var data = selection.tableContext.getData();
        switch (action.type) {
            case ActionTypes.ShowTransactionsData:
                var transactionData = <pre>{transaction.Data}</pre>;
                if (transaction.Data && transaction.Data.indexOf('{') == 0) {
                    try {
                        var converted = JSON.parse(transaction.Data);
                        var elements = Object.keys(converted).map(x => {
                            return <flex className='vertical' key={x}>
                                <span className='bold'>{x}</span>
                                <span className=''>{converted[x].toString()}</span>
                            </flex>;
                        });
                        transactionData = <flex className='vertical gap-5'>
                            {elements}
                        </flex>;
                    } catch (err) {
                        //
                    }
                }

                if (!transaction.Data) {
                    transactionData = <FluxNotice type='information' title='No information' description='There is no information for this transaction' />;
                }
                Modal.open(<FluxModal title='Transaction information' footer={<flex className='center gap-10'>
                    {transaction.Data && <Button title='Copy to clipboard' onClick={() => copyDataToClipboard(transaction.Data)} />}
                    <Button title='Close' className={'success'} onClick={() => Modal.close()} />
                </flex>}>
                    {transactionData}
                </FluxModal>);
                break;
            case ActionTypes.PlayerProfile:
                appHistory.push(`/players/search?&id=${transaction.UserId}`);
                break;
            case ActionTypes.ConfirmTransaction:
                confirmTransaction({ id: transaction.Id, amount: transaction.Amount, data: transaction.Data }, transaction.Type, () => {
                    data = data.filter(x => x.Id != transaction.Id);
                    selection.tableContext.setData(data);
                });
                break;
            case ActionTypes.RejectTransaction:
                rejectTransction({ id: transaction.Id, amount: transaction.Amount, data: transaction.Data }, transaction.Type, () => {
                    data = data.filter(x => x.Id != transaction.Id);
                    selection.tableContext.setData(data);
                });
                break;
            default:
                console.log(action.type);
                break;
        }
    };


    if (!actions) return <></>;

    return <FluxSearch
        title={title}
        model={model}
        resultModel={resultTransactionInformation}
        endpoint={endpoint()}
        actions={actions}
        onActionClicked={onActionClicked.bind(this)}
        onPrepareModel={(model) => {
            var copy = { ...model };
            if (props.playerId) {
                var playerField = copy.fields.find(x => x.name == 'UserId');
                playerField.hidden = true;
                playerField.value = props.playerId;
                copy.fields.find(x => x.name == 'Username').hidden = true;
                copy.name = 'transactions-with-player-id';
            }

            if (props.affiliate) {
                copy.name = 'transactions-of-affiliate';
                return copy;
            }


            return copy;
        }}
    />;
};


SearchPlayerBonusTransactions.propTypes = {
    affiliate: PropTypes.object,
    playerId: PropTypes.number
};