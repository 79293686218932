import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { InputTypes } from '../../form/InputTypes';

const changeData = function (data, filter) {
    let sj = data,
        f = filter,
        re = [];
    Array.isArray(data)
        ? (function () {
            f
                ? (function () {
                    sj.forEach(function (obj) {
                        let one = [];
                        filter.forEach(function (no) {
                            one.push(obj[no]);
                        });
                        re.push(one);
                    });
                })()
                : (function () {
                    sj.forEach(function (obj) {
                        let col = Object.keys(obj);
                        let one = [];
                        col.forEach(function (no) {
                            one.push(obj[no]);
                        });
                        re.push(one);
                    });
                })();
        })()
        : (function () {
            re = sj;
        })();
    return re;
};


const sheetChangeData = function (data) {
    let ws = {};
    let range = {
        s: {
            c: 10000000,
            r: 10000000,
        },
        e: {
            c: 0,
            r: 0,
        },
    };
    for (let R = 0; R != data.length; ++R) {
        for (let C = 0; C != data[R].length; ++C) {
            if (range.s.r > R) range.s.r = R;
            if (range.s.c > C) range.s.c = C;
            if (range.e.r < R) range.e.r = R;
            if (range.e.c < C) range.e.c = C;
            let cell = {
                v: data[R][C],
            };

            if (cell.v == null) continue;

            let cell_ref = XLSX.utils.encode_cell({
                c: C,
                r: R,
            });

            if (typeof cell.v === 'number') cell.t = 'n';
            else if (typeof cell.v === 'boolean') cell.t = 'b';
            else if (cell.v instanceof Date) {
                cell.t = 'n';
                cell.z = XLSX.SSF._table[14];
                cell.v = datenum(cell.v);
            } else if (/^hyperlink:/.test(cell.v)) {
                cell.l = {
                    Target: cell.v.replace(/^hyperlink:/, ''),
                };
                cell.v = cell.v.replace(/^hyperlink:/, '');
            }
            else cell.t = 's';
            ws[cell_ref] = cell;
        }
    }
    if (range.s.c < 10000000) ws['!ref'] = XLSX.utils.encode_range(range);
    return ws;
};

export const s2ab = function (s) {
    let buf = new ArrayBuffer(s.length);
    let view = new Uint8Array(buf);
    for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
};
const datenum = function (v, date1904) {
    if (date1904) v += 1462;
    let epoch = Date.parse(v);
    return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
};

const columnwidths = function (columnWidths) {
    let out = [];
    out = columnWidths.map(function (item) {
        return { width: item ? parseInt(256 * (Number(item) / 100)) : 20 };
    });
    return out;
};

export const exportExcel = function (_options) {
    const instance = {
        saveExcel: function () {
            let wb = _options.workbook;

            _options.datas.forEach(function (data, index) {
                let sheetHeader = data.sheetHeader || null;
                let sheetData = data.sheetData;
                let sheetName = data.sheetName || 'sheet' + (index + 1);
                let sheetFilter = data.sheetFilter || null;
                let columnWidths = data.columnWidths || [];

                sheetData = changeData(sheetData, sheetFilter);

                if (sheetHeader) {
                    sheetData.unshift(sheetHeader);
                }

                let ws = sheetChangeData(sheetData);

                ws['!merges'] = [];

                ws['!cols'] = columnwidths(columnWidths);

                wb.SheetNames.push(sheetName);
                wb.Sheets[sheetName] = ws;
            });

            let wbout = XLSX.write(wb, {
                bookType: 'xlsx',
                bookSST: false,
                type: 'binary',
            });


            saveAs(
                new Blob([s2ab(wbout)], {
                    type: 'application/octet-stream',
                }),
                _options.fileName
            );
        },
    };

    return instance;
};

const filterNonNullProps = (array) => {
    return array.map(obj => {
        const filteredObj = Object.keys(obj)
            .filter(key => obj[key] !== null && obj[key] !== '')
            .reduce((acc, key) => {
                acc[key] = obj[key];
                return acc;
            }, {});

        return filteredObj;
    }).filter(obj => Object.keys(obj).length > 0);
};

export const SaveSearchResultsAsExcel = (model, exportAllData, listerResult, onRenderExcel) => {
    var option = {};
    var headers = [];

    option.fileName = 'export.xlsx';

    var data = [];
    var sheetFields = [];

    var copyFields = [...model.fields];
    listerResult.result = filterNonNullProps(listerResult.result);
    if (exportAllData) {
        var row0 = listerResult.result[0];
        Object.keys(row0).map(fieldName => {
            var field = model.fields.find(x => x.name == fieldName);
            if (!field) {
                copyFields.push({
                    name: fieldName
                });
            }
        });
    }

    model.fields.map(field => {
        if (field.notExportable) {
            return;
        }

        var row = {};

        if (field.between) {
            row[(field.title ? field.title : field.name)] = field.title ? field.title : field.name;
            row['Min'] = 'Start';
            row[(field.title ? field.title : field.name) + ' Start'] = field.min;
            console.log(field.min);
            row['Max'] = 'End';
            row[(field.title ? field.title : field.name) + ' End'] = field.max;
        } else {
            row[(field.title ? field.title : field.name) + '_Name'] = field.title ? field.title : field.name;
            row[field.title ? field.title : field.name] = field.value;
        }
        sheetFields.push(row);
    });

    sheetFields.push({});
    var timeDiff = new Date().getTimezoneOffset() / 60;
    sheetFields.push({ 'GMT': 'GMT', 'diff': timeDiff });

    copyFields.map(x => {
        if (x.notExportable) {
            return;
        }
        headers.push(x.title ? x.title : x.name);
    });

    if (onRenderExcel) onRenderExcel(data);

    listerResult.result.map(x => {
        var row = {};
        copyFields.map(field => {
            if (field.notExportable) {
                return;
            }
            var val = x[field.name];

            if (field.export) {
                val = field.export(val, x, field);
            } else if (field.type == InputTypes.DateTime && val) {
                val = new Date(val + 'Z').toLocaleString('en-Gb', { hours12: false });
            } else if (field.type == InputTypes.Date && val) {
                val = new Date(val + 'Z').toLocaleDateString('en-Gb', { hours12: false });
            } else if (field.render) {
                var renderRes = field.render(val, x);
                // This condition checks if renderRes is a React element
                if (typeof renderRes === 'object' && renderRes !== null && renderRes.$$typeof) {
                    const componentType = renderRes.type;
                    if (componentType.displayName === 'Currency' || componentType.name === 'Currency') {
                        const { amount } = renderRes.props;
                        val = amount;
                    } else if (renderRes.type === 'input' && renderRes.props && renderRes.props.value !== undefined) {
                        val = renderRes.props.value;
                    } else {
                        console.log('Unable to extract value from the React element');
                    }
                } else {
                    // This condition assumes renderRes is a primitive value
                    val = renderRes;
                }
            }

            row[field.title ? field.title : field.name] = val ?? '';
        });
        data.push(row);
    });


    try {
        if (listerResult.footer) {
            const footer = listerResult.footer;
            var row = {};
            var rowCount = 0;

            var multipleRows = {};
            copyFields.map(field => {
                var val = footer[field.name];
                var name = field.title ? field.title : field.name;
                if (!val) return row[name] = '';
                if (field.exportFooter) {
                    val = field.exportFooter(val, footer, field, null);
                }
                if (val instanceof Array) {
                    if (rowCount < val.length) rowCount = val.length;
                    multipleRows[name] = true;
                    row[name] = val[0];
                } else if (val instanceof Object) {
                    if (typeof val === 'object' && val !== null && val.$$typeof) {
                        const componentType = val.type;
                        if (componentType.displayName === 'Currency' || componentType.name === 'Currency') {
                            const { amount } = val.props;
                            row[name] = amount;
                        } else if (val.type === 'input' && val.props && val.props.value !== undefined) {
                            row[name] = val.props.value;
                        } else {
                            row[name] = '';
                            console.log('Unable to extract value from the React element');
                        }
                    } else {
                        // This condition assumes renderRes is a primitive value
                        row[name] = Object.values(val)[0];
                    }
                } else {
                    row[name] = val;
                }
            });
            data.push(Object.assign({}, row));
            if (rowCount > 1) {
                for (var i = 1; i < rowCount; i++) {
                    row = {};
                    copyFields.map(field => {
                        var val = footer[field.name];
                        var name = field.title ? field.title : field.name;
                        if (!val) return row[name] = '';

                        if (multipleRows[name]) {
                            val = field.exportFooter(val, footer, field);
                            val = val[i];
                            row[name] = val ?? '';
                        } else {
                            if (!(val instanceof Object)) {
                                return row[name] = '';
                            }
                        }
                    });
                    data.push(row);
                }
            }
        }



        option.datas = [
            {
                sheetData: data,
                sheetName: 'report',
                sheetHeader: headers
            },
            {
                sheetData: sheetFields,
                sheetName: 'information',
                sheetHeader: null
            }
        ];
        option.workbook = {
            SheetNames: [],
            Sheets: {},
        };

        var excel = new exportExcel(option);
        excel.saveExcel();
    } catch (err) {
        console.log(err);
    }
};