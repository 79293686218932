export const isNullOrEmpty = (value) => {
    return value === null || value === undefined || value === '';
};


export const copyDataToClipboard = (data) => {

    const el = document.createElement('textarea');
    el.value = data;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    navigator.clipboard.writeText(el.value);
    document.body.removeChild(el);
};


export const replaceSpace = (name) => {
    if (!name) return name;
    name = name.replace(/ /g, '-');
    name = name.replace(/\//g, '-');
    name = name.toLowerCase();
    return name;
};

export const replaceSpaceAndNonAlphaNumerics = (name) => {
    if (!name) return name;
    name = name.replace(/[^a-zA-Z0-9]/g, '_');
    name = name.replace('!', '_');
    return name.toLowerCase();
};


export const toSpaceCase = (text) => {
    const result = text.replace(/([A-Z])/g, ' $1');
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
};