import EventEmitter from 'events';
import React from 'react';



export const getCurrentLanguage = () => {
    var lang = localStorage.getItem('portal_backend_admin_language');
    if (lang === null || lang === undefined) {
        lang = 'en';
    }

    return lang;
};




export const defaultLanguage = 'Tr'; // Tr, En
export const showErrors = false;

let user = {};

const appEvents = new EventEmitter();
appEvents.setMaxListeners(1000);

export { appEvents };

export function setUser(data) {
    if (data.token === null || data.token === undefined) {
        throw data;
    }

    user = data;
    document.cookie = 'auth=' + getToken() + ';expires=Fri, 31 Dec 9999 23:59:59 GMT';
}


export function logoutUser() {
    document.cookie = 'auth= ;expires=Fri, 31 Dec 1970 23:59:59 GMT';
}

export function getToken() {
    return user.token;
}


var settingsObject = {

};

export const createSettings = (store) => {
    settingsObject = {
        socket: store.socket
    };
};


export const ApplicationSettings = () => {
    return settingsObject;
};




const recaptchaRef = React.createRef();
export { recaptchaRef };


export const operatorSettings = {
    subRetail: {
        enabled: [15, 16, 19]
    }
};