import React from 'react';
import PropTypes from 'prop-types';
import { FluxCard } from '../../../../components/card/FluxCard';
import { lang } from '../../../../lib/Localization/language';
import { currency } from '../../../../components/currency/Currency';

export const PlayerConsumedBonusInformation = (props) => {
    const player = props.player;

    return <FluxCard title={'Player bonus history'}>
        <flex className='gap-10 vertical fit fit-children'>
            <flex className='columns-2'>
                <label className='medium bolder'>{lang('Total Bonus Given')}</label>
                <span>{player.playerStats?.totalBonusAdded}</span>
            </flex>
            <flex className='columns-2'>
                <label className='medium bolder'>{lang('Total Bonus Completed')}</label>
                <span>{player.playerStats?.totalBonusCompleted}</span>
            </flex>
            <flex className='columns-2'>
                <label className='medium bolder'>{lang('Completed Amount')}</label>
                <span>{currency(player.playerStats?.totalBonusCompleteAmount, 2, player.currency)}</span>
            </flex>
        </flex>
    </FluxCard>;
};

PlayerConsumedBonusInformation.propTypes = {
    player: PropTypes.object,
    onTransactionComplete: PropTypes.func
};
