import cc from 'currency.js'

const formats = {
    ARS: { symbol: 'AR$', decimal: ',', separator: '.', pattern: `# !`, negativePattern: `-# !` },
    USD: { symbol: '$', decimal: ',', separator: '.' },
    CAD: { symbol: 'C$', decimal: ',', separator: '.' },
    GBP: { symbol: '£', decimal: ',', separator: '.' },
    EUR: { symbol: '€', decimal: ',', separator: '.' },
    CHF: { symbol: 'Fr.', decimal: ',', separator: '.' },
    SEK: { symbol: 'kr', decimal: ',', separator: '.' },
    JPY: { symbol: '¥', decimal: ',', separator: '.' },
    CNY: { symbol: '¥', decimal: ',', separator: '.' },
    INR: { symbol: '₹', decimal: ',', separator: '.' },
    RUB: { symbol: '₽', decimal: ',', separator: '.' },
    AUD: { symbol: 'A$', decimal: ',', separator: '.' },
    IQD: { symbol: 'IQD', decimal: ',', separator: '.' },
    HKD: { symbol: 'HK$', decimal: ',', separator: '.' },
    SGD: { symbol: 'S$', decimal: ',', separator: '.' },
    TWD: { symbol: 'NT$', decimal: ',', separator: '.' },
    BRL: { symbol: 'R$', decimal: ',', separator: '.' },
    KRW: { symbol: '₩', decimal: ',', separator: '.' },
    ZAR: { symbol: 'R', decimal: ',', separator: '.' },
    MYR: { symbol: 'RM', decimal: ',', separator: '.' },
    IDR: { symbol: 'Rp', decimal: ',', separator: '.' },
    NZD: { symbol: 'NZ$', decimal: ',', separator: '.' },
    MXN: { symbol: 'MX$', decimal: ',', separator: '.' },
    PHP: { symbol: '₱', decimal: ',', separator: '.' },
    DKK: { symbol: 'kr.', decimal: ',', separator: '.' },
    PLN: { symbol: 'zł', decimal: ',', separator: '.' },
    AED: { symbol: 'DH', decimal: ',', separator: '.' },
    MNT: { symbol: '₮', decimal: ',', separator: '.' },
    CLP: { symbol: 'CLP', decimal: ',', separator: '.' },
    CZK: { symbol: 'Kč', decimal: ',', separator: '.' },
    HUF: { symbol: 'Ft', decimal: ',', separator: '.' },
    ILS: { symbol: '₪', decimal: ',', separator: '.' },
    KWD: { symbol: 'KD', decimal: ',', separator: '.' },
    LKR: { symbol: 'Rs', decimal: ',', separator: '.' },
    NOK: { symbol: 'kr', decimal: ',', separator: '.' },
    PKR: { symbol: '₨', decimal: ',', separator: '.' },
    SAR: { symbol: 'SR', decimal: ',', separator: '.' },
    THB: { symbol: '฿', decimal: ',', separator: '.' },
    ETB: { symbol: 'ብር', decimal: ',', separator: '.' },
    TRY: { symbol: '₺', decimal: '.', separator: ',', pattern: `# !`, negativePattern: `-# !` },
    TTD: { symbol: 'TT$', decimal: '.', separator: ',' },
    UAH: { symbol: '₴', decimal: '.', separator: ',' },
    JOD: { symbol: 'د.ا', decimal: '.', separator: ',' },
    SSP: { symbol: 'SSP', decimal: ',', separator: '.' },
    UGX: { symbol: 'UGX', decimal: '.', separator: ',' },
    BTC: { symbol: '₿', decimal: ',', separator: '.' },
    LTC: { symbol: 'LTC', decimal: ',', separator: '.' },
    ETH: { symbol: 'Ξ', decimal: ',', separator: '.' },
    TTC: { symbol: '₺', decimal: '.', separator: ',' },
    XAU: { symbol: 'XAU', decimal: ',', separator: '.' },
    XAG: { symbol: 'XAG', decimal: ',', separator: '.' },
    XDR: { symbol: 'XDR', decimal: ',', separator: '.' },
    GOLD: { symbol: 'G', decimal: ',', separator: '.' },
    CNS: { symbol: 'CNS', decimal: ',', separator: '.' },
    SWP: { symbol: 'SWP', decimal: ',', separator: '.' },
    HTG: { symbol: 'G', decimal: '.', separator: ',' },
    EMPTY: { symbol: '', decimal: ',', separator: '.' }
};


export const currency = (amount, places = 2, symbol, showSymbol = true) => {
    try {
        amount = parseFloat(amount);
        if (isNaN(amount)) amount = 0;
        var formatFound = formats[symbol];
        var format = null;
        if (!formatFound) {
            format = { symbol: symbol, decimal: ',', separator: '.' };
        } else {
            format = !showSymbol ? formats.EMPTY : (formats[symbol] ?? formats.USD);
        }
        format.precision = places;
        return cc(amount, format).format();
    } catch (err) {
        if (amount) return amount.toString();
        return '';
    }
};


export const Currency = (props) => {
    const { amount, sign, places, showSign } = { ...props };
    return currency(amount, places ?? 2, sign, showSign);
};