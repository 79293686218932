/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../components/button/Button';
import { ActionButton, FluxCard } from '../../../components/card/FluxCard';
import { Table } from '../../../components/table/Table';
import { Modal } from '../../../components/application/FluxApp';
import { FluxModal } from '../../../components/modal/FluxModal';
import { SelectGameProviders } from '../../../components/dataControls/SelectGameProviders';


export const RetailCasinoDisabledProviders = (props) => {
    const [configuration, setConfiguration] = useState(props.configuration);

    const model = {
        fields: [
            {
                name: 'id',
                hidden: true
            },
            {
                name: 'name',
                title: 'Provider',
                readonly: true
            }
        ]
    };

    useEffect(() => {
        props.onUpdate(configuration);
    }, [configuration]);


    const removeProvider = (id) => {
        var copy = { ...configuration };
        copy.casino.disabledProviders = copy.casino.disabledProviders.filter(x => x.id !== id);
        setConfiguration(copy);
    };


    const addProviderLimit = () => {
        var provider = null;
        Modal.open(<FluxModal title='Select Provider' footer={<flex className='gap-10 center'>
            <Button className='success' title='Add' onClick={() => {
                Modal.close();
                var copy = { ...configuration };
                copy.casino.disabledProviders.push({
                    id: provider.id,
                    name: provider.name
                });
                setConfiguration(copy);
            }} />
            <Button title='Cancel' onClick={() => Modal.close()} />
        </flex>}>
            <SelectGameProviders filter={(x) => {
                if (configuration.casino.disabledProviders.find(y => y.id == x.id)) {
                    return false;
                }
                return true;
            }} onChange={(data) => provider = data} />
        </FluxModal>);
    };

    return <FluxCard title='Disabled providers' className={configuration.casino?.enabled ? 'no-padding' : 'disabled no-padding'} buttons={<ActionButton title='Add' icon='plus-square' onClick={() => addProviderLimit(configuration, setConfiguration)} />}>
        <Table model={model} data={configuration.casino.disabledProviders} buttons={(row) => {
            return <Button title='Remove' className={'warning'} onClick={() => removeProvider(row.id)} />;
        }} />
    </FluxCard >;
};

RetailCasinoDisabledProviders.propTypes = {
    onUpdate: PropTypes.func,
    configuration: PropTypes.object
};