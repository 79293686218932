import React from 'react';

import { Modal } from '../components/application/FluxApp';
import { FluxForm } from '../components/form/FluxForm';
import { FluxModal } from '../components/modal/FluxModal';
import { InputTypes } from '../components/form/InputTypes';
import { API } from '../lib/API/Api';


const transactionModel = {
    fields: [
        {
            name: 'amount',
            title: 'Amount',
            required: true,
            type: InputTypes.Number
        },
        {
            name: 'notes',
            title: 'Notes',
            type: InputTypes.Textarea
        }
    ]
};

const rejectModel = {
    fields: [
        {
            name: 'notes',
            title: 'Notes',
            type: InputTypes.Textarea
        }
    ]
};


export const confirmPendingTicket = (endpoint, operatorId, ticketId, amount, handler) => {
    const confirmed = (data) => {
        var transactionData = { ...data };
        transactionData.ticketId = ticketId;
        transactionData.operatorId = operatorId;
        transactionData.amount = amount;

        API.post(endpoint, transactionData, 'please wait', 'unable to create transaction').then((result) => {
            Modal.close();
            handler(transactionData, result.result);
        });
    };
    Modal.open(
        <FluxModal title={'Confirm'} onConfirm={confirmed}>
            <FluxForm className='tab-next'
                data={{
                    amount: amount
                }}
                submitButtonTitle='Confirm'
                model={transactionModel}
                onSubmit={(data) => {
                    confirmed(data);
                }}
                onCancel={() => Modal.close()}>
            </FluxForm>
        </FluxModal>
    );
};




export const rejectPendingTicket = (endpoint, operatorId, ticketId, amount, handler) => {
    const confirmed = (data) => {
        var transactionData = { ...data };
        transactionData.ticketId = ticketId;
        transactionData.operatorId = operatorId;
        transactionData.amount = amount;

        API.post(endpoint, transactionData, 'please wait', 'unable to create transaction').then((result) => {
            Modal.close();
            handler(transactionData, result.result);
        });
    };
    Modal.open(
        <FluxModal title={'Reject'} onConfirm={confirmed}>
            <FluxForm className='tab-next'
                submitButtonTitle='Reject'
                model={rejectModel}
                onSubmit={(data) => {
                    confirmed(data);
                }}
                onCancel={() => Modal.close()}>
            </FluxForm>
        </FluxModal>
    );
};
