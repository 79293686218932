import { getStore } from '../../..';


export const lang = (key, p) => {
    var value = (window.__languageData && window.__languageData[key]) ? window.__languageData[key][window.__l] : key;
    value = value ? value : key;
    if (window.__languageData && !window.__languageData[key]) {
        window.langErrors[key] = key;
        value = key;
    }
    return p && p.length ? replaceAll(value, p) : value;
};

const replaceAll = (value, p) => {
    var result = value;
    p.map((x, index) => {
        result = result.replace(new RegExp('%' + (index + 1), 'g'), x);
    });
    return result;
};



var defaultLanguage = {
    'Contact Information': {
        'tr': 'Kontak Bilgileri'
    },
    'New Announcement': {
        'tr': 'Yeni Duyuru'
    },
    'Past Announcements': {
        'tr': 'Geçmiş Duyurular'
    },
    'Message': {
        'tr': 'Mesaj'
    },
    'Published': {
        'tr': 'Yayınlanma'
    },
    'Title': {
        'tr': 'Başlık'
    },
    'Re Publish': {
        'tr': 'Tekrar Yayınla '
    },
    'User Id': {
        'tr': 'Kullanıcı No '
    },
    'Last Login IP': {
        'tr': 'Son Giriş IP'
    },
    'Same IP': {
        'tr': 'Aynı IP'
    },
    'Game Types': {
        'tr': 'Oyun Türleri'
    },
    'Min Stake': {
        'tr': 'Min Bahis'
    },
    'Max Stake': {
        'tr': 'Max Bahis'
    },
    'Bet Stop': {
        'tr': 'Bahis Durdur'
    },
    'Clear Config': {
        'tr': 'Ayar Sil'
    },
    'Default settings selected!': {
        'tr': 'Varsayilan ayarlar seçildi!'
    },
    'No group selected, settings will apply to all groups without specific configuration.': {
        'tr': 'Hiçbir grup seçilmedi; ayarlar, belirli bir yapılandırma olmaksızın tüm gruplara uygulanacaktır.'
    },
    'Settings used if no specific group has configuration.': {
        'tr': 'Herhangi bir özel grup ayarı yoksa bu ayarlar geçerli olur.'
    },
    'Select provider': {
        'tr': 'Sağlayıcı seçin'
    },
    'Favourite': {
        'tr': 'Favori'
    },
    'Popular': {
        'tr': 'Popüler'
    },
    'Change': {
        'tr': 'Değiştir'
    },
    'Sort by weight': {
        'tr': 'Ağırlığa göre sırala'
    },
    'Sort by name': {
        'tr': 'İsime göre sırala'
    },
    'Show new games': {
        'tr': 'Yeni oyunları göster'
    },
    'Show popular': {
        'tr': 'Popüler oyunları göster'
    },
    'Show disabled': {
        'tr': 'Yasaklı oyunları göster'
    },
    'Most profitable casino games today': {
        'tr': 'Bugün oynanan en karlı casino oyunları'
    },
    'Most losing casino games today': {
        'tr': 'Bugün oynanan en kayıp veren casino oyunları'
    },
    'Show Details': {
        'tr': 'Detayları göster'
    },
    'Deducted Bonus Amounts on Withdraw': {
        'tr': 'Çekimde Kesilen Bonus Tutarları'
    },
    'Balance Before Bonus': {
        'tr': 'Bonus Öncesi Bakiye'
    },
    'Deducted Amount': {
        'tr': 'Kesilen Tutar'
    },
    'Given By': {
        'tr': 'Tarafından Verildi'
    },
    'Deducted By': {
        'tr': 'Tarafından Kesildi'
    },
    'Provider Deposits': {
        'tr': 'Sağlayıcı Yatırımları'
    },
    'Provider Withdraws': {
        'tr': 'Sağlayıcı Çekimleri'
    },
    'Manual Deposits': {
        'tr': 'Manuel Yatırımlar'
    },
    'Manual Withdraws': {
        'tr': 'Manuel Çekimler'
    },
    'Total Paid': {
        'tr': 'Toplam Ödenen'
    },
    'Open Stakes': {
        'tr': 'Açık Bahisler'
    },
    'Open Possible Win': {
        'tr': 'Açıkta Olan Olası Kazanma'
    },
    'Status': {
        'tr': 'Durum'
    },
    'Total Amount': {
        'tr': 'Toplam Miktar'
    },
    'Bonus Deposit': {
        'tr': 'Bonus Yatırım'
    },
    'Bonus Withdraw': {
        'tr': 'Bonus Çekim'
    },
    'Transactions Summary': {
        'tr': 'İşlem Özetleri'
    },
    'Bonus Transactions': {
        'tr': 'Bonus İşlemleri'
    },
    'Manage filters': {
        'tr': 'Filtreleri Ayarla'
    },
    'Arrange result columns': {
        'tr': 'Sonuç sütunlarını düzenle'
    },
    'Transaction Id': {
        'tr': 'İşlem No'
    },
    'Date': {
        'tr': 'Tarih'
    },
    'Editor\'s Pick': {
        'tr': 'Sizin için seçtiklerimiz'
    },
    'Approve': {
        'tr': 'Onayla'
    },
    'Reject': {
        'tr': 'Reddet'
    },
    'Withdraw transaction will be rejected.': {
        'tr': 'Para çekme işlemi reddedilecektir.'
    },
    'Please confirm, %1 will be deposit back to players wallet.': {
        'tr': 'Lütfen onaylayın, %1 oyuncunun cüzdanına geri yatırılacaktır.'
    },
    'Edit Tags': {
        'tr': 'Etiketleri düzenle'
    },
    'Game Hand History': {
        'tr': 'Oyuncu El Geçmişi'
    },
    'Game Transaction History': {
        'tr': 'Oyuncu İşlem Geçmişi'
    },
    'Activities': {
        'tr': 'Aktiviteler'
    }
};

export const updateLanguage = (data) => {
    window.__languageData = Object.assign(defaultLanguage, data);
    getStore().dispatch({
        type: '_languageData_FULFILLED',
        payload: data
    });
};
