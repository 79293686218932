import React from 'react';
import { FluxTab } from '../../../tab/FluxTab';
import { RiskLimits } from './Components/RiskLimits/RiskLimits';
import { RiskTemplates } from './Components/RiskTemplates/RiskTemplates';
import { RiskTemplateStakeFactoring } from './Components/RiskTemplateStakeFactoring/RiskTemplateStakeFactoring';
import './_risk-limits-page.scss';
import { RiskMarketLimits } from './Components/RiskMarketLimits/RiskMarketLimits';

export const RiskLimitsPage = () => {
    var tabButtons = [
        {
            title: 'Limits'
        },
        {
            title: 'Templates'
        },
        {
            title: 'Stake Factoring'
        },
        {
            title: 'Market Limits'
        },
    ];

    return <flex className='risk-limits vertical gap-20 overflow-hidden'>
        <FluxTab buttons={tabButtons} className='gap-10 fx-shadow fx-radius rounded-buttons overflow-hidden'>
            <RiskLimits />
            <RiskTemplates />
            <RiskTemplateStakeFactoring />
            <RiskMarketLimits />
        </FluxTab>
    </flex>;
};