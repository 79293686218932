import './_card.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../icon/Icon';
import { lang } from '../../lib/Localization/language';
import { hasAccess } from '../../lib/ActionManager/FluxActions';

export const ActionButton = (props) => {
    const { onClick, icon, title, action, className } = { ...props };
    if (action && !hasAccess(action)) return <></>;
    return <flex className={`flux-action-button align-right gap-5 padding-left clickable ${className}`} onClick={(e) => onClick(e)}>
        <Icon icon='ellipsis-vertical' type='fa-light' />
        <Icon icon={icon} type='fa-light' />
        <span>{lang(title)}</span>
    </flex>
};

export const FluxCard = (props) => {
    const [hidden, setHidden] = useState(props.hideBody);
    return <card className={props.className ?? ''}>
        {(props.title || props.buttons || props.toggleBody) && <header>{typeof props.title == 'string' ? <span>{lang(props.title)}</span> : props.title}{props.buttons}{props.toggleBody && <flex className='align-right' onClick={() => setHidden(!hidden)}><Icon icon={hidden ? 'angle-up' : 'angle-down'} /></flex>}</header>}
        <body className={hidden ? 'hidden' : ''}>
            {props.children}
        </body>
        {props.footer && <footer>{props.footer}</footer>}
    </card>;
};

FluxCard.propTypes = {
    className: PropTypes.string,
    title: PropTypes.any,
    toggleBody: PropTypes.bool,
    hideBody: PropTypes.bool,
    buttons: PropTypes.node,
    children: PropTypes.node,
    footer: PropTypes.node
};
