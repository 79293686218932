import './_form-input.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ToggleButton } from '../../toggleButton/ToggleButton';

export const BoolInput = (props) => {
    const [active, setActive] = useState(null);

    useEffect(() => {
        const field = props.field;
        if (props.data && props.data[field.name]) {
            setActive(props.data[field.name]);
        } else if (active === null) {
            setActive(field.value ?? false);
        }
    }, [props.data]);


    useEffect(() => {
        if (active === null || active === undefined) return;
        props.onChange(props.field, active);
    }, [active]);

    if (active === null || active === undefined) return <></>;

    return <flex className='input-bool'>
        <ToggleButton disabled={props.field.readonly || props.field.readOnly} value={active} onChange={(val) => setActive(val)} />
    </flex>;
};

BoolInput.propTypes = {
    field: PropTypes.object,
    data: PropTypes.object,
    onChange: PropTypes.func
};
