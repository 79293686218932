import './_modals.scss';

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../icon/Icon';
import { Modal } from '../application/FluxApp';
import { lang } from '../../lib/Localization/language';

export const FluxModal = (props) => {

    const close = (e) => {
        if (e.code == 'Escape') Modal.close();
    };

    useEffect(() => {
        window.addEventListener('keyup', close);
        return () => window.removeEventListener('keyup', close);
    }, []);

    return <modal className={props.className ?? ''}>
        <card className={props.className}>
            {(props.title || props.closeButton) &&
                <header>
                    {props.title && <span>{lang(props.title)}</span>}
                    {props.closeButton}
                </header>
            }
            <body>{props.children}</body>
            {props.footer && <footer>{props.footer}</footer>}
        </card>
    </modal>;
};

FluxModal.defaultProps = {
    closeButton: <flex className='align-right gap-5 clickable' onClick={() => {
        Modal.close();
    }}>
        <Icon icon='times' type='fa-solid' />
    </flex>
};

FluxModal.propTypes = {
    className: PropTypes.string,
    closeButton: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.node,
    footer: PropTypes.node,
    onClose: PropTypes.func
};
