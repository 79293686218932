/* eslint-disable react/display-name */
import React from 'react';
import { useSelector } from 'react-redux';
import { ReportEndpoints } from '../../../../v2/Lib/Api/Api';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { SearchModelHorseBettingReport } from '../../../lib/Models/HorseModels';
import { ModelHorseTicketResult } from '../../../lib/Models/TicketModels';

export const ReportTicketsHorseGreyhound = () => {
    const user = useSelector((state) => state.user.loginInformation);
    const singleOperator = user.UserPrivileges.Operators.length == 1;

    
    return <FluxSearch
        model={SearchModelHorseBettingReport}
        endpoint={ReportEndpoints.HorseGreyHoundTicketsReport}
        resultModel={ModelHorseTicketResult}
        onPrepareResultModel={(model) => {
            var copy = { ...model };

            if (singleOperator) {
                model.fields.find(x => x.name == 'Operator').hidden = true;
            }
            return copy;
        }}
    />;
};
