import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { getHistory } from '../../../../routers/AppRouterFlux';
import { Icon } from '../../../components/icon/Icon';
import { lang } from '../../../lib/Localization/language';
import { Button } from '../../../components/button/Button';
import { FluxCard } from '../../../components/card/FluxCard';
import { FluxForm } from '../../../components/form/FluxForm';
import { InputTypes } from '../../../components/form/InputTypes';
import { Popper } from '../../../components/notification/FluxPopper';
import { Table } from '../../../components/table/Table';
import { Tip } from '../../../components/tip/Tip';
import { API } from '../../../lib/API/Api';
import { AffiliateEndpoints } from '../../../lib/API/Endpoints';
import { randomString } from '../../../lib/Helpers/PasswordHelpers/PasswordHelper';
import { Affiliate } from '../Affiliate/Affiliate';
import { NotificationType } from '../../../components/notification/FluxNotification';


const groupModel = {
    fields: [
        {
            name: 'Name',
            title: 'Name'
        },
        {
            name: 'GroupName',
            title: 'Group',
            render: (val) => {
                return <NavLink to='/affiliates/groups/'>{val}</NavLink>;
            }
        },
        {
            name: 'Link',
            title: 'Link'
        },
        {
            name: 'InviteCode',
            title: 'Invite Code'
        },
        {
            name: 'Promotion',
            title: 'Promotion'
        },
        {
            name: 'LastSettlementDate',
            title: 'Last Settlement'
        },
        {
            name: 'LastSettlementAmount',
            title: 'Amount'
        },
        {
            name: 'Balance',
            title: 'Balance'
        },
        {
            name: 'TotalPlayers',
            title: 'Total Players'
        }
    ]
};

const newAffiliate = {
    sections: {
        'General': {
            icon: <Icon icon='address-card' type='fa-light' />,
            fields: [
                {
                    name: 'Name',
                    required: true
                },
                {
                    name: 'Description',
                    type: InputTypes.Textarea,
                    required: true
                },
                {
                    name: 'Group',
                    type: InputTypes.Select,
                    required: true,
                    data: {
                        source: AffiliateEndpoints.ListGroups,
                        key: 'Id',
                        title: ['Name']
                    }
                },
                {
                    name: 'Link',
                    value: randomString(6)
                },
                {
                    name: 'InviteCode',
                    value: 'AF' + randomString(4)
                }
            ]
        },
        'Account Information': {
            icon: <Icon icon='key' type='fa-light' />,
            fields: [
                {
                    name: 'OwnerName',
                    title: 'Name',
                    required: true,
                },
                {
                    name: 'OwnerSurname',
                    title: 'Surname',
                    required: true,
                },
                {
                    name: 'OwnerUsername',
                    title: 'Username',
                    required: true,
                },
                {
                    name: 'OwnerEmail',
                    title: 'Email',
                    required: true,
                },
                {
                    name: 'OwnerPassword',
                    title: 'Password',
                    required: true,
                },
                {
                    name: 'Enabled',
                    title: 'Enabled',
                    value: true,
                    type: InputTypes.Bool
                }
            ]
        }

    }
};

const editAffiliate = {
    fields: [
        {
            name: 'Name',
            required: true
        },
        {
            name: 'Description',
            type: InputTypes.Textarea,
            required: true
        },
        {
            name: 'Group',
            type: InputTypes.Select,
            required: true,
            data: {
                source: AffiliateEndpoints.ListGroups,
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'Link',
            readonly: true
        },
        {
            name: 'InviteCode',
            readonly: true
        },
        {
            name: 'Enabled',
            type: InputTypes.Bool
        }
    ]
};

export const Affiliates = () => {
    const [location, setLocation] = useState(null);
    const [edit, setEdit] = useState(undefined);
    const [affiliates, setAffiliates] = useState([]);
    const [showDisabled, setShowDisabled] = useState(false);

    const [affiliate, selectAffiliate] = useState(null);

    useEffect(() => {
    }, []);

    useEffect(() => {
        loadAffiliates();
    }, [showDisabled]);

    useEffect(() => {
        if (edit === undefined) return;
        if (edit && edit.Id) {
            getHistory().push(`/affiliates/affiliate-center/?&id=${edit.Id}`);
        } else {
            getHistory().push('/affiliates/affiliate-center/');
        }
    }, [edit]);

    useEffect(() => {
        if (!affiliate) return;
        getHistory().push(`/affiliates/affiliate-center/?&affiliateId=${affiliate.Id}`);
    }, [affiliate]);

    useEffect(() => {
        if (!affiliates) return;
        if (!affiliates.length) return;
        if (location !== window.location.search) {
            setLocation(window.location.search);
        }
    });

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = parseFloat(urlParams.get('id'));
        const affiliateId = parseFloat(urlParams.get('affiliateId'));
        if (!isNaN(affiliateId)) {
            const urlParams = new URLSearchParams(window.location.search);
            const id = parseFloat(urlParams.get('affiliateId'));
            var affiliateExists = affiliates.find(x => x.Id == id);
            if (affiliateExists) {
                selectAffiliate(affiliateExists);
            }
            return;
        } else {
            selectAffiliate(null);
        }

        var editingAffiliate = affiliates.find(x => x.Id == id);
        if (editingAffiliate) {
            setEdit(editingAffiliate);
        }
    }, [location])

    const loadAffiliates = (page) => {
        API.post(AffiliateEndpoints.ListAffiliates, {
            currentPage: page ?? 0,
            recordsPerPage: 1000,
            fields: [
                {
                    name: 'Enabled',
                    value: showDisabled ? 'false' : 'true'
                }
            ]
        }).then((result) => {
            setAffiliates(result.result);
            setLocation(window.location.search);
        });
    };

    if (!affiliates) return <></>;

    if (affiliate != null) {
        return <Affiliate affiliate={affiliate} />;
    }
    if (edit != null) {
        return <FluxCard title={edit.Id ? 'Edit Affiliate' : 'Add new affiliate'}>
            <FluxForm model={edit?.Id ? editAffiliate : newAffiliate} endpoint={AffiliateEndpoints.SaveAffiliate} confirm confirmMessage={edit ? 'Do you want to update Affiliate information?' : 'Do you want to create new Affiliate?'}
                onSubmitComplete={() => {
                    Popper.pop('information', !edit ? 'New Affiliate created.' : 'Affiliated updated.');
                    setEdit(null);
                    loadAffiliates();
                }}
                onChange={(x => {
                    if (!x.Id) {
                        if (!x.InviteCode.startsWith('AF')) {
                            Popper.pop(NotificationType.Warning, 'Invite code must start with AF');
                        }
                        if (x.InviteCode.length != 6) {
                            Popper.pop(NotificationType.Warning, 'Invite code must be 6 character');
                        }
                    }
                })}
                onCancel={() => setEdit(null)} data={edit ?? null} />
        </FluxCard>;
    }

    return <flex className='vertical gap-10'>
        <FluxCard>
            <flex className='gap-20'>
                <Button className='align-left success' title='Add new' onClick={() => setEdit(0)} />
                <Tip className='align-right' tip='Create Affiliates using group configurations.' />
            </flex>
        </FluxCard>
        <FluxCard title={showDisabled ? 'Passive Affiliates' : 'Active Affiliates'}
            buttons={
                <flex className={`align-right gap-5 padding-left clickable`} onClick={() => setShowDisabled(!showDisabled)}>
                    <Icon icon='ellipsis-vertical' type='fa-light' />
                    <Icon icon={showDisabled ? 'lightbulb' : 'lightbulb-slash'} type='fa-light' />
                    <span>{lang(showDisabled ? 'Show active Affiliates' : 'Show passive Affiliates')}</span>
                </flex>
            } className={'no-padding'}>
            <Table model={groupModel} data={affiliates} buttons={(row) => <flex className='gap-5'>
                <Button title='Edit' className='success' onClick={() => setEdit(row)} />
                <Button title='Profile' onClick={() => selectAffiliate(row)} />
            </flex>} />
        </FluxCard>
    </flex>;
};
