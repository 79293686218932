import React, { useEffect, useState } from 'react';
import { FluxCard } from '../../components/card/FluxCard';
import { FluxForm } from '../../components/form/FluxForm';
import { Popper } from '../../components/notification/FluxPopper';
import { API } from '../../lib/API/Api';
import { ProviderEndpoints } from '../../lib/API/Endpoints';


export const PaymentProviderSettings = () => {
    const [providers, setProviders] = useState();
    const [filtered, setFiltered] = useState(null);


    useEffect(() => {
        API.post(ProviderEndpoints.Get, {}).then((result) => {
            setProviders(result.result);
        });
    }, []);

    useEffect(() => {
        if (!providers) return;
        var copy = copy.filter(x => x.Type >= 128);
        setFiltered(copy);
    }, [providers]);


    if (!filtered) return <></>;
    return <flex className='vertical gap-10 overflow-hidden overflow-y-auto'>
        {filtered.map(x => {
            return <FluxCard title={
                <flex className='gap-10'>
                    <span>{x.AggregatorName}</span>
                    <span>{x.Display}</span>
                </flex>
            } key={x.Id}>
                <FluxForm
                    endpoint={ProviderEndpoints.Update}
                    onSubmitComplete={() => {
                        Popper.pop('information', 'Provider', 'Information updated!');
                    }}
                    model={{
                        fields: [
                            {
                                name: 'Name',
                                title: 'Name'
                            },
                            {
                                name: 'Display',
                                title: 'Display'
                            }
                        ]
                    }} data={x}>

                </FluxForm>
            </FluxCard>;
        })}
    </flex>;
};