import './_header.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { API } from '../../../v2/Lib/Api/Api';
import { Endpoints } from '../../../components/Application/Application';
import { DateHelpers } from '../../lib/Helpers/DateHelpers';
import { getCurrentLanguage } from '../../../config';
import { lang } from '../../lib/Localization/language';



const SelectPointOfView = () => {
    const user = useSelector((state) => state.user);

    if (user.loginInformation.UserPrivileges.Operators.length < 2) return <></>;

    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === 'POVchange') {
                window.location.reload();
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const changePointOfView = (value) => {
        API.post(`${Endpoints.ChangePointOfView}?&operatorId=${value}`).then(() => {
            localStorage.setItem('POVchange', value);
            window.location.reload();
        });
    };

    return <div className='flex gap-5'>
        <select defaultValue={user.loginInformation.UserPrivileges.PointOfView}
            onChange={(e) => {
                changePointOfView(e.target.value);
            }}>
            {
                user.loginInformation.UserPrivileges.Operators.map(operator => {
                    return <option key={operator.Id} value={operator.Id}>{operator.Name}</option>;
                })
            }
        </select>
    </div>;
};




const LanguageSelector = () => {
    const languages = {
        'en': 'En',
        'ru': 'Ru',
        'es': 'Es',
        'pt': 'Pt',
        'tr': 'Tr',
        'fr': 'Fr',
        'ht': 'Ht'
    };

    const changeLanguage = (lang) => {
        localStorage.setItem('portal_backend_admin_language', lang);
        window.location.href = window.location.href;
    };

    return <div className='flex gap-5'>
        <select defaultValue={getCurrentLanguage()}
            onChange={(e) => {
                changeLanguage(e.target.value);
            }}>
            {
                Object.keys(languages).map(l => {
                    return <option key={l} value={l}>{languages[l]}</option>;
                })
            }
        </select>
    </div>;
};


const GMTSelector = () => {
    const [zones, setZones] = useState([]);
    const [zone, setZone] = useState(null);

    useEffect(() => {
        var copy = [];
        var i = 0;
        for (i = 0; i < 10; i++) {
            copy.push({
                title: 'GMT +' + (i.toString().padStart(2, '0')) + ':00',
                value: i
            });
        }

        for (i = 1; i < 10; i++) {
            copy.push({
                title: 'GMT -' + ((i).toString().padStart(2, '0')) + ':00',
                value: -i
            });
        }


        window.user.timeZoneConverted = DateHelpers.offset();
        setZone(window.user.timeZoneConverted);
        setZones(copy);
    }, []);


    if (!zones.length) return <></>;
    if (!zone) return <></>;
    return <div className='flex gap-5 padding'>
        <label className='light-color'>{lang('Time zone')}</label>
        <select defaultValue={zone} onChangeCapture={(e) => {
            window.user.timeZoneConverted = parseInt(e.target.value);
        }}>{
                zones.map(x => {
                    return <option key={x} value={x.value}>{x.title}</option>;
                })
            }</select>
    </div>;
};

export const Header = () => {
    const user = useSelector((state) => state.user);
    const operator = user.loginInformation.UserPrivileges.Operators.find(x => x.Id == user.loginInformation.UserPrivileges.PointOfView);

    var prefix = user.loginInformation.SuperUser ? 'd' : operator?.Prefix;
    var logo = `//cdn-${prefix}.tentangle.com/${user.loginInformation.SuperUser ? 'providers/Alteria.png' : 'brand/brand-new.png'}`;

    const brand = () => {
        return <flex className='brand'>
            <div className='brand-logo'>
                <img src={logo} />
            </div>
            <flex className='vertical gap-2'>
                <span className='highlight'>{user.loginInformation.UserGroupName}</span>
                <span>{user.loginInformation.Email}</span>
            </flex>
        </flex>;
    };
    return <div className='app-header'>
        {brand()}
        <flex className='align-right gap-5'>
            <GMTSelector />
            <SelectPointOfView />
            <LanguageSelector />
        </flex>
    </div>;
};

Header.propTypes = {
    onSelected: PropTypes.func
};