export const ProviderTypes = {
    1: 'Undefined',
    2: 'Rng Games',
    4: 'Live Dealer',
    8: 'Virtual Games',
    16: 'Skill Games',
    32: 'Esport',
    64: 'Sports Book',
    128: 'Payment Gateway'
};
