/* eslint-disable react/display-name */
import './_risk-market-limits.scss';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { API } from '../../../../../../lib/API/Api';
import { CMSEndpoints, RiskLimitEndpoints, SportEndpoints } from '../../../../../../lib/API/Endpoints';
import { lang } from '../../../../../../lib/Localization/language';
import { Button } from '../../../../../button/Button';
import { FluxCard } from '../../../../../card/FluxCard';
import { FluxInput } from '../../../../../form/Components/FluxInput';
import { InputTypes } from '../../../../../form/InputTypes';
import { FluxNotice } from '../../../../../notification/FluxNotice';
import { NotificationType } from '../../../../../notification/FluxNotification';
import { Popper } from '../../../../../notification/FluxPopper';
import { Table } from '../../../../../table/Table';
import { Modal } from '../../../../../application/FluxApp';
import { FluxModal } from '../../../../../modal/FluxModal';
import { FluxForm } from '../../../../../form/FluxForm';


export const RiskMarketLimits = () => {
    const user = useSelector((state) => state.user);
    const [categories, setCategories] = useState(null);
    const [category, selectCategory] = useState(null);
    const [markets, setMarkets] = useState([]);
    const [search, setSearch] = useState(null);
    const [updateKey, setUpdateKey] = useState(0);

    useEffect(() => {
        API.post(SportEndpoints.Categories, {}, 'please wait loading categories', 'unable to load categories').then((result) => {
            setCategories(result.result.sort((a, b) => a.weight - b.weight));
        });

    }, []);

    useEffect(() => {
        if (!categories) return;

        API.post(`${CMSEndpoints.GetSportMarkets}/${window.user.features.feedType}`, {}).then((sportMarketsResult) => {

            API.post(RiskLimitEndpoints.GetMarketLimits(user.loginInformation.UserPrivileges.PointOfView)).then((stakeResults) => {
                var csv = sportMarketsResult.result;
                var data = csv.split('\n');
                var converted = data.map(x => {
                    try {
                        var p = x.split(',');
                        const sport = p[0];
                        const id = p[2];
                        var stakeFactor = null;
                        if (stakeResults.result) {
                            const stakeKey = Object.keys(stakeResults.result).find(x => x == `${sport}:${id}`);
                            if (stakeKey) {
                                stakeFactor = stakeResults.result[stakeKey];
                            }
                        }
                        return {
                            sport: sport,
                            sportName: p[1],
                            id: id,
                            name: p[3],
                            selections: p[4],
                            inPlaySettlements: p[9] == 'True',
                            stakeFactor: stakeFactor
                        };
                    } catch (error) {
                        console.log('🚀 ~ error:', error);
                        return null;
                    }
                });
                converted = converted.filter(x => x != null);
                setMarkets(converted);
            });
        });
    }, [categories]);

    const save = () => {
        const data = markets.map(x => { return { id: x.id, sport: x.sport, stakeFactor: x.stakeFactor }; });
        API.post(RiskLimitEndpoints.SaveMarketLimits(user.loginInformation.UserPrivileges.PointOfView), data, 'please wait', 'unable to save market limits!').then(() => {
            Popper.pop('information', 'Market limits saved!');
        });
    };

    const bulkUpdate = () => {
        const update = (id, value) => {
            var copy = markets;
            var field = copy.find(x => x.id == id.toString() && x.sport == category.id.toString());
            if (!field) return;
            if (value == field['stakeFactor']) return;
            value = roundToHundredth(parseFloat(value));
            field['stakeFactor'] = value;
            setMarkets(copy);
        };
        const confirmed = (data) => {
            const stakeFactor = data.StakeFactor;

            if (!category) return;
            var sportMarkets = markets.filter(x => x.sport == category.id.toString());

            if (sportMarkets) {
                if (search && search.length > 2) {
                    const lower = search.toLowerCase();
                    sportMarkets = sportMarkets.filter(x => x.id?.includes(lower)
                        || x.name?.toLowerCase()?.includes(lower)
                        || x.selections?.toLowerCase()?.includes(lower));
                }
                sportMarkets.forEach(element => {
                    update(element.id, stakeFactor);
                });
                Popper.pop('information', 'Market limits updated!');
                setUpdateKey(Date.now());
            }
        };
        Modal.open(<FluxModal title={'Bulk Update'} onConfirm={confirmed}>
            <flex className='vertical gap-10'>
                <FluxNotice
                    type={NotificationType.Warning}
                    title={lang('Bulk update markets')}
                    description={lang('You can update all markets of this sport, NOTE: if you have search filter it will only update search result!')} />
                <FluxForm className='tab-next horizontal-fields'
                    submitButtonTitle='Bulk Update'
                    model={{
                        fields: [
                            {
                                name: 'StakeFactor',
                                title: 'Stake Factor',
                                type: InputTypes.Number,
                                nullable: true,
                            },
                        ]
                    }} onSubmit={(data) => {
                        confirmed(data);
                    }} onCancel={() => Modal.close()}>
                </FluxForm>
            </flex>
        </FluxModal>
        );
    };

    const roundToHundredth = (value) => {
        if (isNaN(value)) return null;
        if (value >= 0.001 && value <= 0.009) {
            return 0.01;
        }
        return Math.round(value * 100) / 100;
    };

    const context = {
        update: (e, row) => {
            var copy = markets;
            var field = copy.find(x => x.id == row.toString() && x.sport == category.id.toString());
            if (!field) return;
            var value = e.target.value;
            if (e.target.value == field[e.target.name]) return;
            if (e.target.type == 'number') {
                value = roundToHundredth(parseFloat(value));
                e.target.value = value;
            }
            field[e.target.name] = value;
            setMarkets(copy);
        }
    };

    const renderGroup = () => {
        if (!category) return;
        var sportMarkets = markets.filter(x => x.sport == category.id.toString());

        if (sportMarkets) {
            if (search && search.length > 2) {
                const lower = search.toLowerCase();
                sportMarkets = sportMarkets.filter(x => x.id?.includes(lower)
                    || x.name?.toLowerCase()?.includes(lower)
                    || x.selections?.toLowerCase()?.includes(lower));
            }

            var groupModel = {
                fields: [
                    {
                        name: 'id',
                        title: 'Id',
                    },
                    {
                        name: 'name',
                        title: 'Name',
                    },
                    {
                        name: 'selections',
                        title: 'Selections',
                    },
                    {
                        name: 'stakeFactor',
                        title: 'Stake Limit',
                        render: (val, row, field, context) => {
                            const fieldData = markets.find(x => x.id == row.id && x.sport == category.id.toString());
                            return <flex key={row.id + '_' + category.id} className="gap-5">
                                <input type='number'
                                    placeholder='Empty, 0, 100 => Disabled'
                                    name={field.name}
                                    min={0}
                                    step={0.01}
                                    defaultValue={fieldData?.[field.name]}
                                    onChange={(e) => context.update(e, row.id)} />
                                <span>% Limit</span>
                            </flex>;
                        }
                    }
                ]
            };

            return <flex className='gap-20 vertical' key={category.id + '-' + updateKey}>
                <Table
                    className={'fx-borders fx-shadow risk-market-limit-table'}
                    model={groupModel}
                    context={context}
                    data={sportMarkets}
                />
            </flex>;
        }

    };

    if (!categories) return <div></div>;

    return <flex className='vertical gap-10 risk-market-limits'>
        <FluxCard
            className='scroll-enable'
            title={<flex className='gap-10 fit'>
                <span className='bold title'>{lang('Setup Overview Markets')}</span>
                <flex className='center sport-selector'>
                    <FluxInput
                        field={{
                            name: 'Sport',
                            title: 'Sport',
                            type: InputTypes.Select,
                            required: false,
                            nullable: true,
                            data: {
                                source: SportEndpoints.Categories,
                                parameters: {},
                                parser: (result) => {
                                    var results = [];
                                    result.result.sort((a, b) => a.weight - b.weight).filter(x => x.enabled).map(x => {
                                        results.push({ title: `${x.id} - ${x.name}`, value: x.id });
                                    });
                                    return results;
                                }
                            }
                        }}
                        onChange={(field, value) => {
                            selectCategory(categories.find(x => x.id == value));
                        }}
                        data={{}}
                    ></FluxInput>
                </flex>
                <flex className='align-right'>
                    <flex className='gap-5'>
                        <FluxInput
                            field={{
                                placeholder: 'Type to search',
                                name: 'Search',
                                title: 'Search',
                            }}
                            onChange={(field, value) => {
                                setSearch(value);
                            }}
                            data={{}}
                        ></FluxInput>
                        {category && <Button className='align-right' title='Bulk Update' onClick={() => bulkUpdate()} />}
                        <Button className='success align-right' title='Save' onClick={() => save()} />
                    </flex>
                </flex>
            </flex>
            }>
            {!category && < FluxNotice type={NotificationType.Information}
                title={'Choose Sport from above'}
                description='Please choose a sport from above to continue' />
            }
            {category && renderGroup()}
        </FluxCard>
    </flex >;
};
