import './_league-selection.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ActionButton, FluxCard } from '../../../../../components/card/FluxCard';
import { SportsTree } from '../../../../../components/sports/SportsTree/SportsTree';
import { Button } from '../../../../../components/button/Button';
import { lang } from '../../../../../lib/Localization/language';
import { Icon } from '../../../../../components/icon/Icon';


export const LeagueSelection = (props) => {
    const [league, selectLeague] = useState(null);
    const [leagues, setLeagues] = useState(props.configuration?.Configuration?.sport?.enabledLeagues ?? {});

    useEffect(() => {
        var copy = { ...props.configuration };
        if (!copy.Configuration.sport) {
            copy.Configuration.sport = {
                enabledLeagues: {}
            };
        }
        copy.Configuration.sport.enabledLeagues = leagues;
        props.onChange(copy);
    }, [leagues]);


    const add = () => {
        var copy = { ...leagues };
        copy[league.id] = {
            id: league.id,
            name: league.name,
            region: {
                id: league.regionId,
                name: league.regionName
            },
            sport: {
                id: league.sportId,
                name: league.sportName
            }
        };
        setLeagues(copy);
    };

    const remove = (l) => {
        var copy = { ...leagues };
        delete copy[l.id];
        setLeagues(copy);
    };


    return <flex className='gap-10 promotion-sport-rules-league-selection align-items-stretch fit-children'>
        <FluxCard title='Select League' className={'league-selection-card no-padding'}>
            <SportsTree regions leagues onRenderLeagueOptions={(x) => {
                if (leagues[x.id]) {
                    return <ActionButton title='Remove' icon='x' onClick={() => remove(x)} />;
                }
            }} onLeagueSelected={(x) => {
                selectLeague(x);
            }} />
        </FluxCard>
        <FluxCard className={'promotion-sport-selection-rules no-padding'} title='Selected Leagues'>
            <flex className='vertical gap-10'>
                {
                    league &&
                    <flex className='padding gap-5 border-bottom'>
                        <flex className='gap-5'>
                            <label className='medium'>{league.sportName}</label>
                            <Icon icon='chevron-right' size='xs' />
                            <label className='medium'>{league.regionName}</label>
                            <Icon icon='chevron-right' size='xs' />
                            <label className='medium'>{league.name}</label>
                        </flex>
                        <Button title='Add' className={'align-right success'} onClick={() => add()} />
                    </flex>
                }
                {
                    !league &&
                    <flex className='padding gap-5 border-bottom'>
                        <label className='medium'>{lang('Please select a league from the tree')}</label>
                        <Button title='Add' className={'align-right disabled'} />
                    </flex>
                }
                <flex className='padding'>
                    <label className='medium'>{lang('Selected Leagues')}</label>
                    {Object.keys(leagues).length > 0 &&
                        <flex className='align-right'>
                            <label>{lang('%1 leagues selected', [Object.keys(leagues).length])}</label>
                        </flex>
                    }
                </flex>
                <fluxList>
                    <items>
                        {Object.values(leagues).map(x => {
                            return <item key={x}>
                                <flex className='gap-10'>
                                    <span>{x.sport.name}</span>
                                    <Icon icon='chevron-right' size='xs' />
                                    <span>{x.region.name}</span>
                                    <Icon icon='chevron-right' size='xs' />
                                    <span>{x.name}</span>
                                    <flex className='align-right'>
                                        <Button title='Remove' onClick={() => remove(x)} />
                                    </flex>
                                </flex>
                            </item>;
                        })}
                    </items>
                </fluxList>
            </flex>
        </FluxCard>
    </flex>;
};

LeagueSelection.propTypes = {
    configuration: PropTypes.object,
    onChange: PropTypes.func
};



