import React, { useEffect, useState } from 'react';
import { GenderSources } from '../../../../components/Centrum/_models/ModelEnums';
import { Icon } from '../../../components/icon/Icon';
import { API } from '../../../../v2/Lib/Api/Api';
import { Button } from '../../../components/button/Button';
import { FluxCard } from '../../../components/card/FluxCard';
import { FluxForm } from '../../../components/form/FluxForm';
import { InputTypes } from '../../../components/form/InputTypes';
import { FluxNotice } from '../../../components/notification/FluxNotice';
import { NotificationType } from '../../../components/notification/FluxNotification';
import { gotoProfile } from '../../../lib/Helpers/NavigationHelper';
import { DataEndpoints, PlayerEndpoints } from '../../../lib/API/Endpoints';
import { CurrencyModalField } from '../../../lib/Models/CurrencyModel';


export const CreatePlayers = () => {
    const [model, setModel] = useState(null);
    const [saved, setSaved] = useState(null);

    useEffect(() => {
        API.post(DataEndpoints.AvailableLanguages, {}).then((result) => {
            var availableLanguages = result.result;
            var languageKeys = [];
            availableLanguages.map(x => {
                languageKeys.push({ value: x.key, title: x.name });
            });

            setModel({
                sections: {
                    'General': {
                        icon: <Icon icon='cogs' type='fa-light' />,
                        fields: [
                            {
                                name: 'Code',
                                title: 'Code'
                            },
                            {
                                name: 'Group',
                                title: 'Player Group',
                                type: InputTypes.Select,
                                data: {
                                    source: 'PlayerGroups',
                                    key: 'Id',
                                    title: ['Name']
                                },
                                required: true
                            },
                            CurrencyModalField,
                            {
                                name: 'Language',
                                type: InputTypes.Select,
                                values: languageKeys,
                                required: true
                            }
                        ]
                    },
                    'Personal Information': {
                        icon: <Icon icon='user-pen' type='fa-light' />,
                        fields: [
                            {
                                name: 'IdNumber',
                                type: InputTypes.Text,
                                title: 'Id Number'
                            },
                            {
                                name: 'Name',
                                type: InputTypes.Text,
                                required: true
                            },
                            {
                                name: 'Surname',
                                type: InputTypes.Text,
                                required: true
                            },
                            {
                                name: 'BirthDay',
                                title: 'Birthday',
                                type: InputTypes.Date,
                                value: '2000-01-01',
                                required: true
                            },
                            {
                                name: 'Gender',
                                type: InputTypes.Select,
                                values: GenderSources,
                                required: true
                            }
                        ]
                    },
                    'Contact Information': {
                        icon: <Icon icon='address-card' type='fa-light' />,
                        fields: [
                            {
                                name: 'Email',
                                type: InputTypes.Email,
                                required: true
                            },
                            {
                                name: 'Phone',
                                type: InputTypes.Text,
                            },
                            {
                                name: 'City',
                                type: InputTypes.Text,
                            },
                            {
                                name: 'Address',
                                type: InputTypes.Text,
                            },
                            {
                                name: 'Country',
                                type: InputTypes.Select,
                                required: true,
                                data: {
                                    source: 'EnabledCountries',
                                    key: 'CountryCode',
                                    title: ['Name']
                                }
                            }
                        ]
                    },
                    'User': {
                        icon: <Icon icon='user-secret' type='fa-light' />,
                        fields: [
                            {
                                name: 'Username',
                                type: InputTypes.Text,
                                required: true
                            },
                            {
                                name: 'Password',
                                type: InputTypes.Text,
                                required: true
                            },
                            {
                                name: 'Enabled',
                                type: InputTypes.Bool,
                                value: true
                            }
                        ]
                    }
                }
            });
        });

    }, []);

    if (!model) return <></>;
    if (saved) {
        return <div className='flex vertical gap-10'>
            <FluxCard footer={<flex className='gap-10'>
                <Button className='success' onClick={() => {
                    gotoProfile(saved.id);
                }} title='Goto Profile' />
                <Button title='Create Another' onClick={() => {
                    setSaved(null);
                }} />
            </flex>
            }>
                <FluxNotice type={NotificationType.Information} title='Player Created.' description={''} />
            </FluxCard>
        </div>;
    }
    return <div className='flex vertical gap-10'>
        <FluxCard >
            <FluxForm
                model={model}
                columns={2}
                endpoint={PlayerEndpoints.Create}
                confirm={true}
                confirmTitle='Create new player'
                confirmMessage='Please confirm before creating the new player'
                onSubmitComplete={(data) => {
                    setSaved(data.result);
                }} />
        </FluxCard>
    </div>;
};
