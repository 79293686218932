/* eslint-disable react/display-name */
import React from 'react';
import { changeUserPassword, editUser } from '../../../actions/UserActions';
import { InputTypes } from '../../../components/form/InputTypes';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { ActionTypes } from '../../../lib/ActionManager/FluxActions';
import { POSEndpoints } from '../../../lib/API/Endpoints';
import { depositPOSUser, setupPOSBindings, withdrawPOSUser } from '../../../actions/POSTransactionActions';


export const SearchPOSUsers = () => {
    const model = {
        fields: [
            {
                name: 'Username',
                display: 'Username'
            },
            {
                name: 'Email',
                type: InputTypes.Text,
            },
            {
                name: 'Name',
                type: InputTypes.Text
            },
            {
                name: 'Surname',
                type: InputTypes.Text
            },
            {
                name: 'Phone',
                type: InputTypes.Text
            },
            {
                name: 'Enabled',
                type: InputTypes.Bool,
                value: true
            }
        ]
    };

    const resultModel = {
        fields: [
            {
                name: 'Id'
            },
            {
                name: 'Username',
                display: 'Username'
            },
            {
                name: 'Email',
                type: InputTypes.Text
            },
            {
                name: 'Name',
                type: InputTypes.Text
            },
            {
                name: 'Surname',
                type: InputTypes.Text
            },
            {
                name: 'AuthType',
                title: 'Authentication',
                render: (val) => {
                    switch (val) {
                        case 1:
                            return '2FA Enabled';
                    }
                }
            },
            {
                name: 'Phone',
            },
            {
                name: 'Bindings',
                render: (val) => {
                    if (!val) return;
                    try {
                        var converted = JSON.parse(val);
                        var paymentSolutions = converted.providers;
                        return <flex className='tags'>{
                            paymentSolutions.map(x => {
                                return <span key={x.id} className='tag'>{x.display}</span>;
                            })
                        }</flex>;
                    } catch (err) {
                        //
                    }
                }
            },
            {
                name: 'Currency',
            },

            {
                name: 'Balance',
            },
            {
                name: 'Enabled',
                type: InputTypes.Bool
            }
        ]
    };


    const onActionClicked = (action, user, context) => {
        var data = context.tableContext.getData();
        switch (action.type) {
            case ActionTypes.EditMyUser:
                editUser(user.Id, (result, data) => {
                    user.Enabled = data.Enabled;
                });
                break;
            case ActionTypes.ChangeMyUserPassword:
                changeUserPassword(user);
                break;
            case ActionTypes.POSDeposit:
                depositPOSUser(user, (a) => {
                    data.find(x=>x.Id==user.Id).Balance = a.balanceAfter;
                    context.tableContext.setData(data);
                });
                break;
            case ActionTypes.POSWithdraw:
                withdrawPOSUser(user, (a) => {
                    data.find(x=>x.Id==user.Id).Balance = a.balanceAfter;
                    context.tableContext.setData(data);
                });
                break;
            case ActionTypes.POSProviderBindings:
                setupPOSBindings(user, (bindings) => {
                    data.find(x=>x.Id==user.Id).Bindings = bindings;
                    context.tableContext.setData(data);
                });
                break;
            default:
                console.log(action.type);
                break;
        }
    };

    return <FluxSearch model={model} resultModel={resultModel} endpoint={POSEndpoints.SearchUsers} searchOnStart actions={ActionTypes.POSUsers} onActionClicked={onActionClicked.bind(this)} />;
};