/* eslint-disable react/display-name */

import './_payment-provider-white-list.scss';

import React, { useEffect, useState } from 'react';
import { CheckBox } from '../../../../../v2/Components/Controls/Inputs/CheckBox/CheckBox';
import { Button } from '../../../../components/button/Button';
import { FluxCard } from '../../../../components/card/FluxCard';
import { FluxInput } from '../../../../components/form/Components/FluxInput';
import { InputTypes } from '../../../../components/form/InputTypes';
import { FluxNotice } from '../../../../components/notification/FluxNotice';
import { NotificationType } from '../../../../components/notification/FluxNotification';
import { Popper } from '../../../../components/notification/FluxPopper';
import { FluxSearch } from '../../../../components/search/FluxSearch';
import { Table } from '../../../../components/table/Table';
import { API } from '../../../../lib/API/Api';
import { PaymentProviders, PlayerEndpoints } from '../../../../lib/API/Endpoints';
import { useSelector } from 'react-redux';

export const PaymentProviderWhiteListPlayers = () => {
    const user = useSelector((state) => state.user);
    const [selectedProvider, selectProvider] = useState(null);
    const [players, setPlayers] = useState([]);

    useEffect(() => {
        fetchAllDatas();
    }, []);

    const getData = () => {
        if (!selectedProvider) return [];
        var res = players.filter(x => x.ProviderId == selectedProvider);

        return res;
    };

    const fetchAllDatas = () => {
        API.post(PaymentProviders.WhiteListsPlayersGet).then((res) => {
            setPlayers(res.result);
        });
    };

    const save = () => {
        //reduce cost of payload
        const data = players.map(x => { return { playerId: x.PlayerId, providerId: x.ProviderId, disabled: x.Disabled, enabled: x.Enabled }; });

        API.post(PaymentProviders.WhiteListsPlayersSave, data)
            .then(() => {
                Popper.pop('information', 'Player Whitelist saved!');
            });
    };

    const context = {
        toggleEnabled: (row) => {
            var copy = [...players];
            var field = copy.find(x => x.ProviderId == row.ProviderId && x.PlayerId == row.PlayerId);
            if (!field) return;
            field['Enabled'] = !row.Enabled;

            setPlayers(copy);
        },
        toggleDisabled: (row) => {
            var copy = [...players];
            var field = copy.find(x => x.ProviderId == row.ProviderId && x.PlayerId == row.PlayerId);
            if (!field) return;
            field['Disabled'] = !row.Disabled;

            setPlayers(copy);
        }
    };

    const AddPlayer = (playerId, username) => {
        var copy = [...players];
        copy.push({ PlayerId: playerId, Username: username, ProviderId: selectedProvider });
        setPlayers(copy);
    };

    const RemovePlayer = (playerId) => {
        setPlayers(players.filter(x => !(x.PlayerId == playerId && x.ProviderId == selectedProvider)));
    };

    return <FluxCard className='scroll-enable payment-provider-white-list'
        title={<flex className='gap-10 fit'>
            <div></div>
            <flex className='center provider-selector'>
                <FluxInput
                    field={{
                        name: 'Provider',
                        title: 'Provider',
                        type: InputTypes.Select,
                        required: false,
                        nullable: true,
                        data: {
                            source: PaymentProviders.Get,
                            parameters: {},
                            parser: (result) => {
                                var results = [];
                                result.result.sort((a, b) => a.weight - b.weight).map(x => {
                                    results.push({ title: `${x.id} - ${x.display}`, value: x.id });
                                });
                                return results;
                            }
                        }
                    }}
                    onChange={(field, value) => {
                        selectProvider(value);
                    }}
                    data={{}}
                ></FluxInput>
            </flex>
        </flex>
        }>
        {!selectedProvider && < FluxNotice type={NotificationType.Information}
            title={'Choose Provider from above'}
            description='Please choose a provider from above to continue' />
        }
        {selectedProvider && <flex className='gap-5 player-list'>
            <FluxCard className={'player-search'} title='Player Whitelist'
                buttons={<flex className='gap-5 align-right'>
                    <Button className={'success marginRight'} title='Save' onClick={() => save()} />
                </flex>}>
                <Table
                    className={'fx-borders fx-shadow limits-table fit-children'}
                    model={{
                        fields: [
                            { name: 'PlayerId', },
                            { name: 'Username', },
                            {
                                name: 'Enabled',
                                title: 'Enabled-Disabled',
                                render: (val, row, field, context) => {
                                    return <flex className='vertical gap-5'>
                                        <flex className='gap-5'>
                                            <span>Enabled</span>
                                            <CheckBox
                                                key={row.ProviderId + '_' + row.PlayerId + '_Enabled'}
                                                checked={row.Enabled}
                                                onClick={() => context.toggleEnabled(row)}
                                            />
                                        </flex>
                                        <flex className='gap-5'>
                                            <span>Disabled</span>
                                            <CheckBox
                                                key={row.ProviderId + '_' + row.PlayerId + '_Disabled'}
                                                checked={row.Disabled}
                                                onClick={() => context.toggleDisabled(row)}
                                            />
                                        </flex>
                                    </flex>;
                                }
                            },
                        ]
                    }}
                    context={context}
                    buttons={(row) => {
                        return <flex className='gap-10'>
                            <Button title='Remove' className='warning' onClick={() => {
                                RemovePlayer(row.PlayerId);
                            }} />
                        </flex>;
                    }}
                    data={getData()} />
            </FluxCard>
            <FluxSearch
                className={'player-search-table'}
                model={{
                    name: 'search-players-payment-p-whitelist',
                    fields: [
                        {
                            name: 'Id',
                            max: 6,
                            type: InputTypes.Number
                        },
                        {
                            name: 'Username',
                            type: InputTypes.Text,
                            exact: false
                        },
                        {
                            name: 'OperatorId',
                        },
                    ]
                }}
                resultModel={{
                    fields: [
                        {
                            name: 'Id',
                            type: InputTypes.Number,
                            identity: true,
                        },
                        {
                            name: 'Username',
                            title: 'Username',
                            type: InputTypes.Text
                        },
                        {
                            name: 'Name',
                            type: InputTypes.Text
                        },
                        {
                            name: 'Surname',
                            type: InputTypes.Text
                        },
                    ]
                }}
                endpoint={PlayerEndpoints.Search}
                onPrepareModel={(model) => {
                    var copy = { ...model };

                    model.fields.find(x => x.name == 'OperatorId').value = user.loginInformation.UserPrivileges.PointOfView;
                    model.fields.find(x => x.name == 'OperatorId').hidden = true;
                    return copy;
                }}
                buttons={(row) => {
                    const exist = players.findIndex(x => x.PlayerId == row.Id && x.ProviderId == selectedProvider);
                    return <flex className='gap-10'>
                        {exist == -1 ?
                            <Button title='Add' className='success' onClick={() => {
                                AddPlayer(row.Id, row.Username);
                            }} /> :
                            <Button title='Remove' className='warning' onClick={() => {
                                RemovePlayer(row.Id);
                            }} />}
                    </flex>;
                }}
                title={'Search players'}
                exportToExcel={false}
                exportAllData={false} />
        </flex>
        }
    </FluxCard>;
};