import React from 'react';
import { ActionTypes } from '../../lib/ActionManager/FluxActions';
import { FluxAction } from '../../components/application/FluxAction';
import { FluxPage } from '../../components/page/FluxPage';
import { SearchPlayerTransactions } from '../Players/SearchPlayerTransactions/SearchPlayerTransactions';
import { SearchPOSUsers } from './SearchPOSUsers/SearchPOSUsers';
import { SearchPOSUserTransactions } from './SearchPOSUserTransactions/SearchPOSUserTransactions';

export const POSPage = () => {
    return <FluxPage action={ActionTypes.POS}>
        <FluxAction action={ActionTypes.POSUsers}><SearchPOSUsers /></FluxAction>
        <FluxAction action={ActionTypes.POSTransactions}><SearchPOSUserTransactions /></FluxAction>
        <FluxAction action={ActionTypes.SearchPlayerTransactions}><SearchPlayerTransactions payment /></FluxAction>
    </FluxPage >;
};
